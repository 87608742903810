import { reduxForm } from 'redux-form';

import { ReduxInput } from 'shared';

import { labelMaker } from 'shared/utils';
import { reduxFieldRequired, reduxFieldmaxLength, reduxFieldminLength } from 'shared/validators';
import { lessThan } from 'shared/normalizers';

const formLabel = labelMaker('auth.loginForm');

export const options = {
  captcha: {
    component: ReduxInput,
    labelId: formLabel('captcha'),
    name: 'captcha',
    normalize: lessThan(10),
    //validate: reduxFieldRequired,
  },
  newPasswordCaptcha: {
    component: ReduxInput,
    labelId: formLabel('captcha'),
    name: 'newPasswordCaptcha',
    normalize: lessThan(10),
    validate: reduxFieldRequired,
  },
  login: {
    component: ReduxInput,
    labelId: formLabel('login'),
    name: 'login',
    validate: reduxFieldRequired,
  },
  password: {
    component: ReduxInput,
    labelId: formLabel('password'),
    name: 'password',
    type: 'password',
    validate: reduxFieldRequired,
  },
  newPassword: {
    component: ReduxInput,
    labelId: formLabel('newPassword'),
    name: 'newPassword',
    type: 'password',
    validate: [reduxFieldRequired, reduxFieldmaxLength(1024), reduxFieldminLength(8)],
    statusElement: true,
  },
  confirmedNewPassword: {
    component: ReduxInput,
    labelId: formLabel('repeatNewPassword'),
    name: 'confirmedNewPassword',
    type: 'password',
    validate: [reduxFieldRequired, reduxFieldmaxLength(1024), reduxFieldminLength(8)],
    statusElement: true,
  },
  scratchCardIdToBind: {
    component: ReduxInput,
    labelId: formLabel('scratchCardIdToBind'),
    name: 'scratchCardIdToBind',
    type: 'number',
    validate: reduxFieldRequired,
  },
  scratchCardIdNew: {
    component: ReduxInput,
    labelId: formLabel('scratchCardIdNew'),
    name: 'scratchCardIdNew',
    type: 'number',
    validate: reduxFieldRequired,
  },
};

const initialValues = {
  login: '',
  password: '',
  newPassword: '',
  confirmedNewPassword: '',
  scratchCardIdToBind: '',
  scratchCardIdNew: '',
};
const form = 'authentication';

const formInitializer = reduxForm({
  form,
  initialValues,
});

export default formInitializer;
