import React, { PureComponent, Fragment } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { Button, Translation } from 'shared';
import { labelMaker, getTranslatedField2 } from 'shared/utils';

import styles from './Step2.module.scss';

const label = labelMaker('transfers');

class Step1 extends PureComponent {
  formatBanks = banks =>
    Array.isArray(banks)
      ? banks.map(bank => ({
          label: getTranslatedField2(bank, 'text', { locale: this.props.locale }),
          value: bank.title,
          payload: bank,
        }))
      : [];

  render() {
    const {
      transfers,
      cardsOptions,
      options,
      renderPaymentAccountLabel,
      renderPaymentAccountOption,
    } = this.props;

    const isFieldDisabled = transfers.transferForm.disabled || transfers.transferForm.isLoading;

    const banksOptions = this.formatBanks(transfers.banks.banks);

    return (
      <Fragment>
        <div className={styles.InputRow}>
          <Field {...options.senderPhone} disabled />
        </div>
        <div className={styles.InputRow}>
          <Translation.Div
            className={styles.InputLabel}
            translateId={label('labels.transferFrom')}
          />
          <Field
            {...options.cards}
            options={cardsOptions}
            renderLabel={renderPaymentAccountLabel}
            renderOption={renderPaymentAccountOption}
            disabled
          />
        </div>

        <div className={styles.InputRow}>
          <Field {...options.phone} disabled />
        </div>

        <div className={styles.InputRow}>
          <Translation.Div
            className={styles.InputLabel}
            translateId={label('labels.selectBeneficiaryBank')}
          />
          <Field {...options.beneficiaryBank} options={banksOptions} />
        </div>
        <div className={styles.InputRow}>
          <Field {...options.payment} />
          <Translation.Div
            className={styles.InputFootnote}
            translateId={label('footnotes.noFee')}
          />
        </div>
        <div className={styles.InputRow}>
          <Field {...options.purpose} />
        </div>
        <div className={styles.ButtonContainer}>
          <Button type="submit" translateId={label('btns.continue')} disabled={isFieldDisabled} />
        </div>
      </Fragment>
    );
  }
}

Step1.propTypes = {
  cardsOptions: PropTypes.array.isRequired,
  transfers: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  renderPaymentAccountLabel: PropTypes.func.isRequired,
  renderPaymentAccountOption: PropTypes.func.isRequired,
};

export default Step1;
