import { createReducer } from 'redux-act';
import moment from 'moment';

import {
  getChatEventsSuccess,
  closeChatStart,
  openChatStart,
  minimizeChat,
  expandChat,
  initializeChatStart,
  initializeChatWithGreetingSuccess,
  initializeChatWithHistorySuccess,
  initializeChatFailure,
  scheduleMessageSuccess,
  abortScheduledMessage,
  loadHistoryPageStart,
  loadHistoryPageSuccess,
  loadHistoryPageFailure,
  setScrollToLastMessageFlag,
  resetScrollToLastMessageFlag,
  resetChat,
  addMessageToHistory,
  addMessagesToHistory,
  incrementEventsNotifications,
} from 'actions/chat';

const defaultState = {
  clientId: '',
  questionId: '',
  needScrollBottom: false,
  isLoading: true,
  isDisabled: false,

  history: {
    startDate: moment()
      .toDate()
      .valueOf(),
    pageSize: '',
    pageNum: '',
    total: '',

    isLoading: false,
    messages: null,
  },

  ts: moment()
    .toDate()
    .valueOf(),

  settings: {
    isOpen: false,
    isMinimize: false,
    notifications: 0,
    hadGreetingRequest: true,
    wasOpened: false,
  },
};

const chat = createReducer(
  {
    [getChatEventsSuccess]: (state, ts) => {
      return {
        ...state,

        ts,
      };
    },

    [scheduleMessageSuccess]: (state, message) => {
      const { history } = state;
      let messages = [];

      if (Array.isArray(history.messages)) {
        messages = [...history.messages, message];
      } else {
        messages = [message];
      }

      return {
        ...state,
        history: {
          ...history,
          messages,
        },
      };
    },
    [abortScheduledMessage]: (state, message) => {
      const { history } = state;
      let messages = history.messages;

      if (Array.isArray(history.messages)) {
        const indexOfMessage = messages.findIndex(m => m.message.timestamp === message.timestamp);

        if (indexOfMessage >= 0) {
          messages = [
            ...messages.slice(0, indexOfMessage),
            { ...messages[indexOfMessage], isError: true },
            ...messages.slice(indexOfMessage + 1),
          ];
        }
      }

      return {
        ...state,

        history: {
          ...history,

          messages,
        },
      };
    },

    [initializeChatStart]: state => {
      return {
        ...state,

        isLoading: true,
      };
    },
    [initializeChatWithGreetingSuccess]: (state, { message }) => {
      return {
        ...state,

        history: {
          ...state.history,

          messages: [...state.history.messages, message],
        },
        isLoading: false,
      };
    },
    [initializeChatWithHistorySuccess]: (state, { history, pageNum, pageSize }) => {
      const currMessages = Array.isArray(state.history.messages) ? state.history.messages : [];
      const messages = [...history.messages.reverse(), ...currMessages];

      return {
        ...state,

        isLoading: false,
        history: {
          ...state.history,
          pageNum,
          pageSize,
          messages,
        },
      };
    },
    [initializeChatFailure]: state => {
      return {
        ...state,

        isLoading: false,
        isDisabled: true,
      };
    },

    [addMessageToHistory]: (state, message) => {
      const { history } = state;
      let messages = [];

      if (Array.isArray(history.messages)) {
        messages = history.messages;

        const indexOfMessage = messages.findIndex(
          m =>
            m.message.questionId === message.message.questionId && message.message.questionId !== ''
        );

        if (indexOfMessage >= 0) {
          messages = [
            ...messages.slice(0, indexOfMessage),
            message,
            ...messages.slice(indexOfMessage + 1),
          ];
        } else {
          messages = [...messages, message];
        }
      } else {
        messages = [message];
      }

      return {
        ...state,

        history: {
          ...history,

          messages,
        },
      };
    },
    [addMessagesToHistory]: (state, history) => {
      const { messages } = history;

      const currMessages = Array.isArray(history.messages) ? history.messages : [];

      return {
        ...state,

        history: {
          ...state.history,
          ...history,

          pageNum: history.pageNumber,
          messages: [...messages, ...currMessages],
        },
      };
    },

    [resetChat]: () => {
      return defaultState;
    },
    [openChatStart]: state => {
      return {
        ...state,

        settings: {
          ...state.settings,

          isOpen: true,
          wasOpened: true,
          notifications: 0,
        },
      };
    },
    [incrementEventsNotifications]: state => {
      const { settings } = state;
      const incrementStep = 1;

      const nexNumOfNotifications = settings.notifications + incrementStep;

      return {
        ...state,

        settings: {
          ...settings,

          notifications: nexNumOfNotifications,
        },
      };
    },

    [closeChatStart]: state => {
      return {
        ...state,

        settings: {
          ...state.settings,

          isOpen: false,
        },
      };
    },

    [minimizeChat]: state => {
      return {
        ...state,

        settings: {
          ...state.settings,

          isMinimize: true,
        },
      };
    },
    [expandChat]: state => {
      return {
        ...state,

        settings: {
          ...state.settings,

          isMinimize: false,
        },
      };
    },

    [setScrollToLastMessageFlag]: state => {
      return {
        ...state,
        needScrollBottom: true,
      };
    },
    [resetScrollToLastMessageFlag]: state => {
      return {
        ...state,
        needScrollBottom: false,
      };
    },

    [loadHistoryPageStart]: state => {
      return {
        ...state,

        history: {
          ...state.history,

          isLoading: true,
        },
      };
    },
    [loadHistoryPageSuccess]: (state, payload) => {
      const { messages: newMessages, pageNumber: pageNum, pageSize } = payload;
      const { history } = state;

      const currMessages = Array.isArray(history.messages) ? history.messages : [];
      const messages = [...newMessages.reverse(), ...currMessages];

      return {
        ...state,

        history: {
          ...history,

          isLoading: false,
          pageNum,
          pageSize,
          messages,
        },
      };
    },
    [loadHistoryPageFailure]: (state, err) => {
      return {
        ...state,

        history: {
          ...state.history,

          err,
          isLoading: false,
        },
      };
    },
  },
  defaultState
);

export default chat;
