import React from 'react';
import PropTypes from 'prop-types';
import { goTo } from 'actions/routes';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Translate } from 'react-localize-redux';

import styles from './Link.module.scss';

const Link = props => {
  const { goTo, to, className, style, translateId, children } = props;
  const linkClassName = cn(styles.Link, { [className]: className });
  const onClick = () => goTo(to);

  return (
    <div className={linkClassName} styles={style} onClick={onClick}>
      {translateId ? <Translate id={translateId} /> : children}
    </div>
  );
};

Link.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  goTo: PropTypes.func.isRequired,
  style: PropTypes.object,
  to: PropTypes.string,
  translateId: PropTypes.string,
};

Link.defaultProps = {
  style: {},
  to: '/',
};

const mapDispatchToProps = dispatch => ({
  goTo: to => dispatch(goTo(to)),
});

export default connect(
  null,
  mapDispatchToProps
)(Link);
