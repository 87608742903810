import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Translate } from 'react-localize-redux';

import loaderWhite from '../../assets/icons/loader-white.png';
import loaderBlue from '../../assets/icons/loader-blue.png';

import styles from './Button.module.scss';

const getButtonClass = props => {
  const { theme = 'blue', round = false, className } = props;

  return cn(styles.Button, {
    [className]: !!className,
    [styles.Round]: round,
    [styles.Blue]: theme === 'blue',
    [styles.Green]: theme === 'green',
    [styles.White]: theme === 'white',
  });
};

const Button = props => {
  const {
    children,
    disabled = false,
    onClick = () => {},
    showLoader = false,
    style,
    type = 'submit',
    translateId,
    theme,
  } = props;

  return (
    <button
      type={type}
      className={getButtonClass(props)}
      onClick={onClick}
      disabled={disabled || showLoader}
      styles={style}
    >
      <div className={styles.Content}>
        {showLoader && !disabled ? (
          <img
            className={styles.Rotating}
            src={theme === 'blue' ? loaderWhite : loaderBlue}
            alt=""
          />
        ) : (
          <span data-translate-id={translateId || ''}>
            {translateId ? <Translate id={translateId} /> : children}
          </span>
        )}
      </div>
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  round: PropTypes.bool,
  showLoader: PropTypes.bool,
  style: PropTypes.object,
  translateId: PropTypes.string,
  theme: PropTypes.string,
  type: PropTypes.string,
};

Button.defaultProps = {
  theme: 'blue',
};

export default Button;
