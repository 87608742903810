import { createReducer } from 'redux-act';
import moment from 'moment';

import {
  prolongeSession,
  setProlongationRequire,
  resetProlongationRequire,
} from 'actions/session/index';
import { sessionSettings } from 'settings/settings';

const defaultState = {
  lastProlongation: moment(),

  requireProlongation: false,
  updatingInterval: sessionSettings.updatingInterval,
};

const session = createReducer(
  {
    [prolongeSession]: state => {
      return {
        ...state,

        lastProlongation: moment(),
      };
    },

    [setProlongationRequire]: state => {
      return {
        ...state,

        requireProlongation: true,
      };
    },
    [resetProlongationRequire]: state => {
      return {
        ...state,

        requireProlongation: false,
      };
    },
  },
  defaultState
);

export default session;
