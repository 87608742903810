import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getTranslate } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import { Button, ProgressBar } from 'shared';
import { formatNumber, labelMaker, setCreditImage, isCreditHolidays as checkCreditHolidays } from 'shared/utils';

import { startPrepayment, changeLoanName } from 'actions/products';
import { setPaymentAmount } from 'actions/newPayment/index';
import { goTo } from 'actions/routes';

import editIcon from 'assets/icons/edit-icon.png';
import disabledEditIcon from 'assets/icons/disabled-edit-icon.png';
import styles from './Credit.module.scss';

const label = labelMaker('products.credit');

const formatToLocale = (date, format) =>
  moment(date)
    .format(format)
    .toLocaleString();

class Credit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      name: props.credit.name,
      isCreditHolidays: false,
    };
  }

  componentDidMount() {
    const { udf } = this.props;
    if (udf && udf.length) {
      this.checkCreditHolidays(udf);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { udf } = this.props;
    if (!isEqual(nextProps.udf, udf)) {
      this.checkCreditHolidays(nextProps.udf);
    }
  }

  checkCreditHolidays(udf) {
    const isCreditHolidays = udf.some(el => checkCreditHolidays(el));
    this.setState({ isCreditHolidays });
  }

  onNameChange = e => {
    if (e.target.value && e.target.value.length > 80) {
      return this.setState({
        name: e.target.value.substr(0, 80),
      });
    }
    this.setState({
      name: e.target.value,
    });
  };

  onEarlyPayment = () => {
    const { match, goTo, startPrepayment } = this.props;
    const { id } = match.params;

    startPrepayment();
    goTo(`/products/loans/${id}/prepayment`);
  };

  onPayment = amount => () => {
    const { credit, setPaymentAmount, goTo } = this.props;
    const { contractRef } = credit;

    setPaymentAmount(amount);
    goTo(`/products/loans/${contractRef}/payments`);
  };

  renderOutstanding = credit => {
    const lastPastDue =
      Array.isArray(credit.pastdue) && credit.pastdue.length
        ? credit.pastdue[credit.pastdue.length - 1]
        : {};

    const payedAmount =
      parseFloat(credit.originalPrincipal) > parseFloat(lastPastDue.outstandingPrincipal)
        ? parseFloat(credit.originalPrincipal) - parseFloat(lastPastDue.outstandingPrincipal)
        : 0;

    const progress = (payedAmount * 100) / parseFloat(credit.originalPrincipal);

    const outstandingDateLabel = `${this.props.translate(label('creditUntilDate'))} ${moment(
      credit.maturityDate
    ).format('DD.MM.YYYY')}`;

    return (
      <div className={styles.Outstanding}>
        <span className={styles.RestSum}>
          {formatNumber(credit.totalRemainSum, { currency: credit.ccy })}
        </span>
        &nbsp;
        <span className={styles.RestDate}>{outstandingDateLabel}</span>
        <ProgressBar className={styles.Progress} progress={progress} />
      </div>
    );
  };

  handleEditBtn = (isNameEditing, isCreditEditing, newCreditName) => () => {
    if (isNameEditing) {
      return null;
    }

    if (isCreditEditing) {
      return this.props.changeLoanName(this.props.credit.contractRef, newCreditName).then(() => {
        this.setState({
          isEditing: false,
        });
      });
    }

    return this.setState({
      isEditing: true,
    });
  };

  submitNameForm = event => {
    event.preventDefault();
    this.props.changeLoanName(this.props.credit.contractRef, this.state.name).then(() => {
      this.setState({
        isEditing: false,
      });
    });
  };

  render() {
    const { translate } = this.props;
    const {
      name,
      productCategory,
      nextPaymentDate,
      annuity,
      ccy,
      isNameEditing,
      pastdue,
      principal,
    } = this.props.credit;
    const { isCreditHolidays } = this.state;

    const dateFormat = 'D MMMM, YYYY';

    const style = setCreditImage(productCategory);

    const pastDueDays = pastdue && pastdue[0] && parseFloat(pastdue[0].numOfDaysPastDueAmount);

    const depositBtnLabel = `${this.props.translate(label('depositBtn'))} ${formatNumber(annuity, {
      currency: ccy,
    })}`;

    const endPaymentDateStr = pastDueDays
      ? translate(label('pastDueDays'), {
          date: formatToLocale(moment().subtract(pastDueDays, 'days'), dateFormat),
        })
      : translate(label('until'), {
          date: formatToLocale(nextPaymentDate, dateFormat),
        });

    return (
      <div className={styles.Credit}>
        <div className={styles.Info}>
          <div className={styles.Description}>
            <div className={styles.Image} style={style} />
            <div className={styles.TitleContainer}>
              <div className={styles.NameContainer}>
                {this.state.isEditing ? (
                  <Fragment>
                    <form onSubmit={this.submitNameForm}>
                      <input
                        onChange={this.onNameChange}
                        value={this.state.name}
                        disabled={isNameEditing}
                      />
                    </form>
                    <img
                      src={isNameEditing ? disabledEditIcon : editIcon}
                      className={styles.EditBtn}
                      alt="edit"
                      onClick={
                        isNameEditing || this.state.name.length === 0 ? null : this.submitNameForm
                      }
                    />
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className={styles.Name}>{name}</div>
                    <img
                      src={editIcon}
                      className={styles.EditBtn}
                      alt="edit"
                      onClick={this.handleEditBtn(
                        isNameEditing,
                        this.state.isEditing,
                        this.state.name
                      )}
                    />
                  </Fragment>
                )}
              </div>
              <div className={styles.DesktopOutstanding}>
                {this.renderOutstanding(this.props.credit)}
              </div>
            </div>
          </div>

          <div className={styles.MobileOutstanding}>
            {this.renderOutstanding(this.props.credit)}
          </div>
        </div>
        <div className={styles.Actions}>
          <div>
            <Button round className={styles.ActionButton} onClick={this.onPayment(annuity)}>
              {depositBtnLabel}
            </Button>
            <div className={pastDueDays ? styles.PastDueDays : styles.EndPayDate}>
              {endPaymentDateStr}
            </div>
          </div>
          <div>
            <Button
              round
              className={styles.ActionButton}
              onClick={this.onEarlyPayment}
              theme="white"
              translateId={label('repayEarlyBtn')}
              disabled={isCreditHolidays && principal === '0'} // UN-3175 with principal
            />
          </div>
        </div>
      </div>
    );
  }
}

Credit.propTypes = {
  changeLoanName: PropTypes.func.isRequired,
  setPaymentAmount: PropTypes.func.isRequired,
  startPrepayment: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  credit: PropTypes.object,
  udf: PropTypes.array,
};

const mapStateToProps = ({ products, localize }) => ({
  credit: products.credit,
  translate: getTranslate(localize),
  udf: (products.credit.udf || []),
});

const mapDispatchToProps = {
  setPaymentAmount,
  startPrepayment,
  changeLoanName,
  goTo,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Credit)
);
