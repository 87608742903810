import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Overlay.module.scss';

const Overlay = ({ isActive }) =>
  isActive ? (
    <div className={styles.Overlay}>
      <div className={styles.Wrapper}>
        <div className={cn(styles.Spinner, styles.Spinner4)}>
          <div />
          <div />
        </div>
      </div>
    </div>
  ) : null;

const mapStateToProps = state => ({
  isActive: state.overlay.isActive,
});

Overlay.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Overlay);
