import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';

import { labelMaker, formatNumber } from 'shared/utils';
import { Process, Translation } from '..';

import { ReactComponent as PaymentImage } from '../../assets/icons/icon-operation-document.svg';
import styles from './PaymentsHistory.module.scss';

const lPaymentList = labelMaker('shared.paymentList');

const getLocaleDateFormat = locale =>
  ({
    en: 'MMMM DD YYYY',
    ru: 'DD MMMM YYYY',
  }[locale]);

const PaymentsHistory = props => {
  const { locale } = props;
  const dateFormat = getLocaleDateFormat(locale);
  const renderTransition = (payment, key) => {
    if (payment) {
      const { status, counterparty, dateTime, cbAccount, amount } = payment;
      const date = moment(dateTime, 'DD.MM.YYYY hh:mm:ss');

      return (
        <div className={styles.PaymentCard} key={key}>
          <div className={styles.InfoRowBlock}>
            <div className={styles.Mask}>
              <PaymentImage
                className={cn(styles.PaymentImage, { [styles.MirrorHorizontal]: amount < 0 })}
              />
            </div>
            <div className={styles.Info}>
              <div className={styles.Name}>{`${counterparty.identity} ${counterparty.pam}`}</div>
              {/* <div className={styles.Card}>{`${counterparty.identity} ${counterparty.pam}`}</div> */}
              <div className={styles.Date}>{cbAccount}</div>
              <div className={styles.Date}>{moment(date, 'DD.MM.YYYY').format(dateFormat)}</div>
            </div>
          </div>
          <div className={styles.InfoColBlock}>
            <div className={styles.Sum}>{formatNumber(amount, { currency: 'RUR' })}</div>
            <div className={styles.Status}>
              <Process status={status} />
            </div>
          </div>
        </div>
      );
    } else {
      return <div key={key} />;
    }
  };

  const { transitions, type } = props;

  return (
    <div className={styles.List}>
      {transitions && transitions.length ? (
        transitions.map(renderTransition)
      ) : (
        <div className={styles.EmptyTransitionsBlock}>
          <div className={styles.EmptyMask}>
            <PaymentImage className={styles.PaymentImage} />
          </div>
          <Translation.Span
            className={styles.EmptyInfo}
            translateId={
              type === 'operationsHistory'
                ? lPaymentList('emptyOperationsHistory')
                : lPaymentList('emptyListInfo')
            }
          />
        </div>
      )}
    </div>
  );
};

PaymentsHistory.propTypes = {
  type: PropTypes.string,
  locale: PropTypes.string,
  transitions: PropTypes.array,
};

PaymentsHistory.defaultProps = {
  type: PropTypes.string,
  transitions: PropTypes.array,
  locale: PropTypes.string,
  toggleApplication: () => {},
};

export default PaymentsHistory;
