import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { reduxForm, Field, reset, getFormValues } from 'redux-form';

import { Button, SmoothDropDown } from 'shared';
import { labelMaker } from 'shared/utils';
import { atmsFilterSelector, branchesFilterSelector } from 'store/selectors/atms';
import {
  setAtmsAndBranchesFilter,
  clearAtmsAndBranchesFilter,
} from 'actions/atmsAndBranches/index';

import options, { initialValues } from './formOptions';

import styles from './AtmsAdvancedSearch.module.scss';

const lAtmsSearch = labelMaker('atms.search');

class AtmsAdvancedSearch extends Component {
  state = { isOpen: false };

  toggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  applyFilter = () => {
    const { filterValues } = this.props;
    for (let key in filterValues) {
      // if at least one filter is selected
      if (filterValues[key] === true) {
        this.props.setAtmsAndBranchesFilter(filterValues);
        return;
      }
    }
    this.props.clearAtmsAndBranchesFilter();
  };

  resetFilter = () => {
    const { dispatch, clearAtmsAndBranchesFilter } = this.props;
    clearAtmsAndBranchesFilter();
    dispatch(reset('atms/advancedSearch'));
  };

  render() {
    const { atms, branches } = this.props;
    const totalItems = atms.length + branches.length;

    return (
      <Fragment>
        <Link to="#" className={styles.ToggleLink} onClick={this.toggleOpen}>
          <Translate id={lAtmsSearch('advancedSearch')} />
          <span
            className={cn(styles.ToggleArrow, { [styles.ToggleArrowOpen]: this.state.isOpen })}
          />
        </Link>
        <SmoothDropDown
          className={cn(styles.DropdownWrapper, { [styles.DropdownWrapperOpen]: true })}
          isOpen={this.state.isOpen}
        >
          <div className={styles.Dropdown}>
            <div className={styles.Body}>
              <div className={styles.Content}>
                <div className={styles.Col}>
                  <div className={styles.ColTitle}>
                    <Translate id={lAtmsSearch('officesAndAtms')} />
                  </div>
                  <ul className={styles.List}>
                    <li className={styles.ListItem}>
                      <Field {...options.unicreditBranches} />
                    </li>
                    <li className={styles.ListItem}>
                      <Field {...options.unicreditAtms} />
                    </li>
                    <li className={styles.ListItem}>
                      <Field {...options.partnersAtms} />
                    </li>
                    <li className={styles.ListItem}>
                      <Field {...options.unicreditPrimeClub} />
                    </li>
                    <li className={styles.ListItem}>
                      <Field {...options.depository} />
                    </li>
                  </ul>
                </div>
                <div className={styles.Col}>
                  <div className={styles.ColTitle}>
                    <Translate id={lAtmsSearch('workingHours')} />
                  </div>
                  <ul className={styles.List}>
                    <li className={styles.ListItem}>
                      <Field {...options.openAlways} />
                    </li>
                  </ul>
                </div>
                <div className={styles.Col}>
                  <div className={styles.ColTitle}>
                    <Translate id={lAtmsSearch('cashWithdrawal')} />
                  </div>
                  <ul className={styles.List}>
                    <li className={styles.ListItem}>
                      <Field {...options.rubleWithdraw} />
                    </li>
                    <li className={styles.ListItem}>
                      <Field {...options.dollarWithdraw} />
                    </li>
                    <li className={styles.ListItem}>
                      <Field {...options.euroWithdraw} />
                    </li>
                  </ul>
                </div>
              </div>
              <div className={styles.Footer}>
                <div>
                  <Translate id={lAtmsSearch('foundedAddresses')} />
                  {totalItems}
                </div>
                <Button
                  className={styles.FooterButton}
                  round
                  theme="white"
                  translateId={lAtmsSearch('reset')}
                  onClick={this.resetFilter}
                />
                <Button
                  className={styles.FooterButton}
                  round
                  translateId={lAtmsSearch('apply')}
                  onClick={this.applyFilter}
                />
              </div>
            </div>
          </div>
        </SmoothDropDown>
      </Fragment>
    );
  }
}

AtmsAdvancedSearch.propTypes = {
  dispatch: PropTypes.func.isRequired,
  atms: PropTypes.array.isRequired,
  branches: PropTypes.array.isRequired,
  filterValues: PropTypes.object.isRequired,
  setAtmsAndBranchesFilter: PropTypes.func.isRequired,
  clearAtmsAndBranchesFilter: PropTypes.func.isRequired,
};

const mapState = state => ({
  atms: atmsFilterSelector(state),
  branches: branchesFilterSelector(state),
  filterValues: getFormValues('atms/advancedSearch')(state),
});

const mapDispatch = {
  setAtmsAndBranchesFilter,
  clearAtmsAndBranchesFilter,
};

export default reduxForm({
  form: 'atms/advancedSearch',
  initialValues,
})(
  connect(
    mapState,
    mapDispatch
  )(AtmsAdvancedSearch)
);
