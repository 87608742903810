import { createAction } from 'redux-act';

import apiServicesConstructor from 'services/apiService';

// import { hideNotification, showWarnNotification } from './notification';

import { buildUrlParams, encodeDataURI, isObject, isError } from 'shared/utils';
import { showOverlay, hideOverlay } from './overlay';
import { showWarnNotification } from './notification';

// export const setPaymentAmount = createAction('SET_PAYMENT_AMOUNT');
export const clearPaymentParams = createAction('CLEAR_PAYMENT_PARAMS');

export const getPaymentSignatureStart = createAction('GET_PAYMENT_SIGNATURE_START');
export const getPaymentSignatureSuccess = createAction('GET_PAYMENT_SIGNATURE_SUCCESS');
export const getPaymentSignatureError = createAction('GET_PAYMENT_SIGNATURE_ERROR');

export const getPaymentSignature = () => (dispatch, getState) => {
  dispatch(showOverlay());
  dispatch(getPaymentSignatureStart());

  const { payment, auth, localize, products, accounts } = getState();

  const { abscustid: client_ref, fullname: fio } = auth.user;
  const { credit } = products;
  const { drsettlementacc } = credit;

  const amount = Number(payment.amount);
  const email = 'test@test.ru';
  const lang = localize.languages.filter(lang => lang.active)[0].code;

  const defaultCbAccountNo =
    Array.isArray(accounts.data) && accounts.data.length ? accounts.data[0].cbAccountNo : '';

  const account = accounts.data.find(acc => acc.accountNo === drsettlementacc);
  const credit_number = account ? account.cbAccountNo : defaultCbAccountNo;
  const acc_number = account ? account.cbAccountNo : defaultCbAccountNo;

  const apiServices = apiServicesConstructor(dispatch, getState);

  const data = {
    amount,
    credit_number,
    client_ref,
    email,
    fio,
  };
  const params = {
    url: '/webapi-1.0/payment-signature',
    data,
    headers: { 'Content-Type': 'application/json' },
  };

  return apiServices
    .post(params)
    .then(res => {
      const { err, code, sector, signature } = res;

      if (err) {
        throw err;
      }

      const urlParams = buildUrlParams(
        encodeDataURI({
          sector,
          code,
          acc_number,
          fio,
          amount: amount * 100,
          email,
          client_ref,
          lang,
          signature,
        })
      );
      dispatch(getPaymentSignatureSuccess(urlParams));
      dispatch(hideOverlay());
    })
    .catch(err => {
      const errMessage = isObject(err) || isError(err) ? err.message : err;

      dispatch(getPaymentSignatureError());
      dispatch(showWarnNotification(errMessage));
      dispatch(hideOverlay());
    });
};
