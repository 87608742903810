import { createAction } from 'redux-act';
import { getTranslate } from 'react-localize-redux';
import { get, getErrorMessage, labelMaker } from 'shared/utils';
import {
  pwdRecoveryStep1Req,
  pwdRecoveryStep2Req,
  pwdRecoveryStep3Req,
} from './pwdRecoveryRequests';

import { showWarnNotification, hideNotification } from '../notification';
import { setTempPasswordRequired, clearUserCache, setLoginStep } from '../auth';
import { goTo } from '../routes';
import { resetSignature } from 'actions/signature';

const label = labelMaker('pwdRecovery');
const lError = labelMaker('errorsGeneral');

export const passwordRecoverySendUserInfoStart = createAction(
  'PASSWORD_RECOVERY_SEND_USER_INFO_START'
);
export const passwordRecoverySendUserInfoSuccess = createAction(
  'PASSWORD_RECOVERY_SEND_USER_INFO_SUCCESS'
);
export const passwordRecoverySendUserInfoFailure = createAction(
  'PASSWORD_RECOVERY_SEND_USER_INFO_FAILURE'
);

export const passwordRecoveryGetOTPStart = createAction('PASSWORD_RECOVERY_GET_OTP_START');
export const passwordRecoveryGetOTPSuccess = createAction('PASSWORD_RECOVERY_GET_OTP_SUCCESS');
export const passwordRecoveryGetOTPFailure = createAction('PASSWORD_RECOVERY_GET_OTP_FAILURE');

export const passwordRecoverySendOTPStart = createAction('PASSWORD_RECOVERY_SEND_OTP_START');
export const passwordRecoverySendOTPSuccess = createAction('PASSWORD_RECOVERY_SEND_OTP_SUCCESS');
export const passwordRecoverySendOTPFailure = createAction('PASSWORD_RECOVERY_SEND_OTP_FAILURE');

export const passwordRecoveryCancel = createAction('PASSWORD_RECOVERY_CANCEL');

export const passwordRecoverySendUserInfo = userData => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(passwordRecoverySendUserInfoStart());
    const response = await dispatch(pwdRecoveryStep1Req(userData));

    const error = get(response, 'error.message');

    if (error) {
      throw error;
    }
    // all ok
    const data = get(response, 'response');
    if (data) {
      return dispatch(passwordRecoverySendUserInfoSuccess(data));
    }
    // unhandled
    throw translate(label('errors.serviceUnavailable'));
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(passwordRecoverySendUserInfoFailure());
    dispatch(showWarnNotification(errMessage));
  }
};

export const passwordRecoveryGetOTP = controlData => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(resetSignature());
    dispatch(passwordRecoveryGetOTPStart());
    const response = await dispatch(pwdRecoveryStep2Req(controlData));
    const error = get(response, 'error.message');
    if (error) {
      throw error;
    }
    const data = get(response, 'response');
    if (data) {
      dispatch(passwordRecoveryGetOTPSuccess(data));
      dispatch(hideNotification());
      return;
    }
    throw translate(label('errors.serviceUnavailable'));
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(passwordRecoveryGetOTPFailure());
    dispatch(showWarnNotification(errMessage));
  }
};

export const passwordRecoverySendOTP = smsData => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(passwordRecoverySendOTPStart());
    const response = await dispatch(pwdRecoveryStep3Req(smsData));
    const error = get(response, 'error.message');

    if (error) {
      throw error;
    }

    const data = get(response, 'response');

    if (data) {
      dispatch(passwordRecoverySendOTPSuccess());
      dispatch(hideNotification());
      dispatch(setTempPasswordRequired());
      dispatch(clearUserCache());
      dispatch(setLoginStep(1));
      dispatch(resetSignature());
      dispatch(goTo('/login'));
      return;
    }
    throw translate(label('errors.serviceUnavailable'));
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(passwordRecoverySendOTPFailure());
    dispatch(showWarnNotification(errMessage));
    dispatch(resetSignature());
  }
};
