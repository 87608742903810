import React from 'react';
import PropTypes from 'prop-types';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import cn from 'classnames';

import NoPaymentTable from '../../components/NoPaymentTable/NoPaymentTable';

import { CalendarSmall, Translation } from 'shared';

import { formatNumber, labelMaker } from 'shared/utils';

import styles from './paymentTable.module.scss';

const label = labelMaker('products.schedule');

class PaymentTable extends React.Component {
  renderTableHeader = () => {
    return (
      <div className={styles.HeaderRow}>
        <Translation.Div
          className={cn(styles.RowCell, styles.RowHeaderCell, styles.RowCell_1)}
          translateId={label('paymentDay')}
        />
        <Translation.Div
          className={cn(styles.RowCell, styles.RowHeaderCell, styles.RowCell_2)}
          translateId={label('paymentSum')}
        />
        <Translation.Div
          className={cn(styles.RowCell, styles.RowHeaderCell, styles.RowCell_1)}
          translateId={label('repaymentOfPrincipal')}
        />
        <Translation.Div
          className={cn(styles.RowCell, styles.RowHeaderCell, styles.RowCell_1)}
          translateId={label('interestPayment')}
        />
        {/* <Translation.Div
          className={cn(styles.RowCell, styles.RowHeaderCell, styles.RowCell_1)}
          translateId={label('debtBalance')}
        /> */}
      </div>
    );
  };

  renderTableRow = schedule => {
    const { paymentDate, repayAmount, repayInterest, dealCCY /*totalRemainSum*/ } = schedule;
    const amount = parseFloat(repayAmount) + parseFloat(repayInterest);
    return (
      <div className={styles.Row} key={paymentDate}>
        <div className={cn(styles.RowCell, styles.RowCell_1)}>
          <CalendarSmall date={paymentDate} />
        </div>
        <div className={cn(styles.RowCell, styles.RowCellBig, styles.RowCell_2)}>
          {formatNumber(amount, { currency: dealCCY })}
        </div>
        <div className={cn(styles.RowCell, styles.RowCell_1)}>
          {formatNumber(repayAmount, { currency: dealCCY })}
        </div>
        <div className={cn(styles.RowCell, styles.RowCell_1)}>
          {formatNumber(repayInterest, { currency: dealCCY })}
        </div>
        {/* <div className={cn(styles.RowCell, styles.RowCell_1)}>
          {formatNumber(totalRemainSum, { currency: dealCCY })}
        </div> */}
      </div>
    );
  };

  render() {
    const { schedule } = this.props.credit;

    if (!schedule.length) {
      return <NoPaymentTable />;
    }

    return (
      <div className={styles.TableWrapper}>
        <div className={styles.Table}>
          {this.renderTableHeader()}
          {schedule.map(s => this.renderTableRow(s))}
        </div>
      </div>
    );
  }
}

PaymentTable.propTypes = {
  range: PropTypes.object,
  credit: PropTypes.object,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  credit: state.products.credit,
  translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(PaymentTable);
