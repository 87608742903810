import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getActiveLanguage, getLanguages } from 'react-localize-redux';
import { change } from 'redux-form';

import withLoginForm from './formOptions';
import Steps from './Steps';

import { getBSSLanguage } from 'shared/utils';

import { setActiveLanguage } from 'actions/profile';
import { resetTempCaptchaRequire } from 'actions/captcha';
import {
  /*login,*/ newLogin,
  newLoginSend2FA,
  changeLoginPagePassword,
  authChangeScratchCard,
} from 'actions/auth';

import logoRu from 'assets/logo/logo-ru.svg';
import styles from './Form.module.scss';

class LoginProfileForm extends Component {
  onSubmit = fields => {
    const {
      auth,
      captcha,
      changeLoginPagePassword,
      localize,
      login: loginUser,
      authChangeScratchCard,
      newLoginSend2FA,
    } = this.props;
    const {
      captcha: captchaValue,
      newPasswordCaptcha,
      login,
      password,
      newPassword,
      confirmedNewPassword,
      scratchCardIdToBind,
      scratchCardIdNew,
    } = fields;

    const bssLanguage = getBSSLanguage(getActiveLanguage(localize).code);

    const loginArgs = {
      login,
      password,
      language: bssLanguage,
    };

    switch (auth.formControl.loginStep) {
      case 1: {
        if (!login) {
          const persistedUser = JSON.parse(localStorage.getItem('auth/login'));
          loginArgs.login = persistedUser.login;
        }
        if (captchaValue) {
          Object.assign(loginArgs, { captchaId: captcha.id, captchaValue });
        }

        return loginUser({ ...loginArgs, type: 'web_lk' });
      }
      case 2: {
        return changeLoginPagePassword({
          ...loginArgs,

          newPassword,
          confirmedNewPassword,
          captcha: newPasswordCaptcha,
        });
      }
      case 3: {
        return newLoginSend2FA();
      }

      case 4: {
        return authChangeScratchCard({ scratchCardIdToBind, scratchCardIdNew });
      }
      default:
        break;
    }
  };

  setActiveLanguage = () => {
    const { languages, setActiveLanguage, activeLanguage } = this.props;

    const language = languages.find(lang => lang.code !== activeLanguage.code);
    setActiveLanguage(language.code);
  };

  componentDidMount() {
    const persistedUser = JSON.parse(localStorage.getItem('auth/login'));

    if (persistedUser) {
      const { login } = persistedUser;
      change('authentication', 'login', login);
    }
  }

  componentWillUnmount() {
    this.props.resetTempCaptchaRequire();
  }

  render() {
    const { languages, handleSubmit, auth } = this.props;

    const language = languages
      .find(lang => lang.code !== this.props.activeLanguage.code)
      .name.toUpperCase();

    return (
      <Fragment>
        <header className={styles.Header}>
          <div className={styles.TitleContainer}>
            <div className={styles.LogoContainer}>
              <img className={styles.Logo} src={logoRu} alt="logo" />
              <span className={styles.BetaLink}>Beta</span>
            </div>
            <div className={styles.Localization} onClick={this.setActiveLanguage}>
              {language}
            </div>
          </div>
          <div className={styles.TitleInfo} />
        </header>

        <div className={styles.Form}>
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <Steps step={auth.formControl.loginStep} submit={handleSubmit(this.onSubmit)} />
          </form>
        </div>
        <footer className={styles.Footer}>
          {/* <Translation.Div className={styles.FooterContainer} translateId="auth.tryDemo" /> */}
        </footer>
      </Fragment>
    );
  }
}

LoginProfileForm.propTypes = {
  activeLanguage: PropTypes.object,
  auth: PropTypes.shape({
    isChangeTempPasswordRequired: PropTypes.bool,
    isChangePasswordRequired: PropTypes.bool,
    isRequestPending: PropTypes.bool,
    formControl: PropTypes.shape({
      loginStep: PropTypes.number,
    }),
    persistedUser: PropTypes.shape({
      login: PropTypes.string,
      username: PropTypes.string,
    }),
  }),
  captcha: PropTypes.object,
  localize: PropTypes.object.isRequired,
  changeLoginPagePassword: PropTypes.func.isRequired,
  resetTempCaptchaRequire: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  languages: PropTypes.array,
  setActiveLanguage: PropTypes.func.isRequired,
  change: PropTypes.func,
  authChangeScratchCard: PropTypes.func.isRequired,
  newLoginSend2FA: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, captcha, localize }) => ({
  activeLanguage: getActiveLanguage(localize),
  auth,
  captcha,
  languages: getLanguages(localize),
  localize: localize,
});

const mapDispatchToProps = {
  login: newLogin,
  resetTempCaptchaRequire,
  setActiveLanguage,
  changeLoginPagePassword,
  authChangeScratchCard,
  change,
  newLoginSend2FA,
};

export default withLoginForm(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LoginProfileForm)
);
