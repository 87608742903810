import React, { PureComponent, Fragment } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { Translation, Button, SmsInput } from 'shared';
import { labelMaker } from 'shared/utils';

import styles from '../DefaultBankForm.module.scss';

const label = labelMaker('profile');

class Step2 extends PureComponent {
  render() {
    const {
      transfers,
      cards,
      options,
      setDefaultBank,
      renderPaymentAccountLabel,
      renderPaymentAccountOption,
      formatCards,
    } = this.props;

    const cardsOptions = formatCards(cards.data);

    return (
      <Fragment>
        <Translation.Div
          className={styles.Title}
          translateId={label('titles.receivingTransfersByPhone')}
        />
        <Translation.Div
          className={styles.Description}
          translateId={label('descriptions.chooseAccountForFPS')}
        />
        <Field
          className={styles.Input}
          {...options.cards}
          isLoading={transfers.defaultBankAccountForm.isDefaultAccountLoading}
          renderLabel={renderPaymentAccountLabel}
          renderOption={renderPaymentAccountOption}
          options={cardsOptions}
          disabled
        />
        <div className={styles.OTP}>
          <SmsInput
            isLoading={transfers.defaultBankAccountForm.isLoading}
            resetOTP={setDefaultBank}
            numOfFields={6}
          />
        </div>
        <div className={styles.OTPButtonsContainer}>
          <div className={styles.Button}>
            <Button
              onClick={this.props.destroyDefaultBank}
              translateId={label('btns.cancel')}
              showLoader={transfers.defaultBankAccountForm.isLoading}
              disabled={transfers.defaultBankAccountForm.disabled}
            />
          </div>
          <div className={styles.Button}>
            <Button
              type="submit"
              translateId={label('btns.continue')}
              showLoader={transfers.defaultBankAccountForm.isLoading}
              disabled={transfers.defaultBankAccountForm.disabled}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

Step2.propTypes = {
  cards: PropTypes.object.isRequired,
  formatCards: PropTypes.func.isRequired,
  transfers: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  setDefaultBank: PropTypes.func.isRequired,
  renderPaymentAccountLabel: PropTypes.func.isRequired,
  renderPaymentAccountOption: PropTypes.func.isRequired,
  destroyDefaultBank: PropTypes.func.isRequired,
};

export default Step2;
