import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withLocalize, getActiveLanguage } from 'react-localize-redux';
import cn from 'classnames';

import HeaderMenu from './HeaderMenu';
import LangSwitch from 'shared/LangSwitch/LangSwitch';
import { Link, Translation } from 'shared';
import { logout } from 'actions/auth/index';
import headerTranslations from './header.json';

import { ReactComponent as ProfileIcon } from 'assets/icons/icon-profile.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import TextLogoBlack from 'assets/logo/logo-ru.svg';
import styles from './Header.module.scss';

import { labelMaker, transliterate } from 'shared/utils';

const lHeader = labelMaker('header');

const menu = [
  { name: 'products', notifications: 0 },
  //{ name: 'payments', notifications: 0 },
  { name: 'history', notifications: 0 },
  // { name: 'atms', notifications: 0 },
  { name: 'bonuses', notifications: 0 },
  { name: 'transfers', notifications: 0 },
  //{ name: 'newProduct', notifications: 0 },
];

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: '1440px',
      isMenuOpen: false,
      isSubMenuOpen: false,
    };

    props.addTranslation(headerTranslations);
  }

  componentDidMount() {
    window.addEventListener('resize', this.trackWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.trackWidth);
  }

  trackWidth = () => {
    const { width } = this.state;
    let newWidth = document.documentElement.offsetWidth;

    if (width !== newWidth) {
      this.setState({ width: newWidth });
    }
  };

  toggleUserMenu = () => {
    const { isSubMenuOpen } = this.state;

    this.setState({ isSubMenuOpen: !isSubMenuOpen });
  };

  switchStateMenu = () => {
    const { isMenuOpen } = this.state;

    this.setState({ isMenuOpen: !isMenuOpen });
  };

  // renderMenu = (menu, path) =>
  //   menu.map((menuItem, i) => (
  //     <div className={cn(styles.Tab, { [styles.ActiveTab]: path.includes(menuItem.name) })} key={i}>
  //       <Link
  //         className={styles.Link}
  //         to={`/${menuItem.name}`}
  //         translateId={lHeader(menuItem.name)}
  //       />
  //       {menuItem.notifications ? (
  //         <div className={styles.TabCount}>{menuItem.notifications}</div>
  //       ) : null}
  //     </div>
  //   ));

  render() {
    const { auth, match, logout, locale } = this.props;
    const { width, isMenuOpen, isSubMenuOpen } = this.state;
    const { Div } = Translation;
    const { path } = match;
    const {
      user: { fullname = '' },
    } = auth;

    const userName = fullname ? fullname.split(' ')[1] || '' : '';

    const localizedUserName = (locale === 'en' ? transliterate(userName) : userName).toLowerCase();
    return (
      <div className={styles.Header}>
        <div className={styles.BurgerButton} onClick={this.switchStateMenu}>
          <div />
          <div />
          <div />
        </div>

        <Link to="/products" className={styles.LogoLink}>
          <img className={styles.Logo} src={TextLogoBlack} alt="logo" />
          <span>Beta</span>
        </Link>

        <div
          className={cn(
            styles.Shadow,
            { [styles.ShadowStart]: isMenuOpen },
            { [styles.ShadowClear]: !isMenuOpen }
          )}
          onClick={this.switchStateMenu}
        />

        <div
          className={cn(
            styles.Menu,
            { [styles.MenuOpen]: isMenuOpen },
            { [styles.MenuClose]: !isMenuOpen }
          )}
        >
          <Link to="/products" className={styles.MenuLogoLink}>
            <img className={styles.MenuLogo} src={TextLogoBlack} alt="logo" />
            <span>Beta</span>
          </Link>

          <div className={styles.Navigation}>
            {menu
              .filter(menuItem => {
                // show menu based on settings
                return !(
                  (menuItem.name === 'transfers' || menuItem.name === 'history') &&
                  !this.props.settings.isModuleVisible[locale]
                );
              })
              .map((menuItem, i) => {
                return (
                  <div
                    className={cn(styles.Tab, { [styles.ActiveTab]: path.includes(menuItem.name) })}
                    key={i}
                  >
                    <Link
                      className={styles.Link}
                      to={`/${menuItem.name}`}
                      translateId={lHeader(menuItem.name)}
                    />
                    {menuItem.notifications ? (
                      <div className={styles.TabCount}>{menuItem.notifications}</div>
                    ) : null}
                  </div>
                );
              })}
          </div>

          <div
            className={styles.UserContainer}
            onMouseDown={width < 1024 ? null : this.toggleUserMenu}
          >
            <div className={styles.UserInfo}>
              <ProfileIcon className={styles.UserPhoto} />
              <div className={styles.UserName}>{localizedUserName}</div>
              <div className={cn(styles.UserName, styles.FullName)}>{localizedUserName}</div>
            </div>

            <div className={styles.MenuButton}>
              <div className={cn(styles.Arrow, { [styles.ArrowUp]: isSubMenuOpen })} />
              {isSubMenuOpen ? (
                <div className={styles.MenuContainer}>
                  <HeaderMenu outsideClick={this.toggleUserMenu} />
                </div>
              ) : null}
            </div>

            <Link
              to="/profile/personal"
              className={styles.Link}
              translateId={lHeader('personal')}
            />
            <Link
              to="/profile/security"
              className={styles.Link}
              translateId={lHeader('security')}
            />

            <LangSwitch flag={false} />
          </div>

          <div className={styles.LogoutLink} onClick={logout}>
            <Translation.Span translateId={lHeader('exit')} />
          </div>
        </div>

        <div className={styles.LogoutContainer} onClick={logout}>
          <LogoutIcon />
          <Div translateId={lHeader('exit')} />
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  addTranslation: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = ({ auth, settings, localize }) => ({
  auth,
  settings,
  locale: getActiveLanguage(localize).code,
});

const mapDispatchToProps = {
  logout,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withLocalize(Header))
);
