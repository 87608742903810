import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate, getActiveLanguage } from 'react-localize-redux';
import { Field } from 'redux-form';
import moment from 'moment';

import { Button, Captcha, Translation, Link, ReduxInput } from 'shared';
import { options } from '../../formOptions';

import { clearUserCache } from 'actions/auth';
import { hideNotification } from 'actions/notification';
import { labelMaker, isNumberInRange, transliterate, get } from 'shared/utils';
import { reduxFieldRequired } from 'shared/validators';

import { ReactComponent as ProfileIcon } from 'assets/icons/icon-profile.svg';
import styles from './Step1.module.scss';

const label = labelMaker('auth');
const formLabel = labelMaker('auth.loginForm');

class Step1 extends Component {
  renderCaptchaBlock = () => {
    const { captcha, auth } = this.props;
    const { isRequestPending } = auth;

    return captcha.isCaptchaRequired || captcha.isTempCaptchaRequired ? (
      <div className={styles.CaptchaContainer}>
        <div className={styles.CaptchaRow}>
          <Captcha />
        </div>
        <div className={styles.CaptchaRow}>
          <Field
            {...options.captcha}
            disabled={isRequestPending}
            onFocus={this.hideNotificationMessage}
          />
        </div>
      </div>
    ) : null;
  };

  hideNotificationMessage = () => {
    const { notification, hideNotification } = this.props;
    if (get(notification, 'notification.visible')) {
      hideNotification();
    }
  };

  render() {
    const { auth, clearUserCache, phoneNumber, locale } = this.props;
    const { Div, Span } = Translation;

    const hours = moment().format('HH');
    const id = isNumberInRange({ from: 0, to: 5 })(hours)
      ? label('greetingNight')
      : isNumberInRange({ from: 6, to: 11 })(hours)
        ? label('greetingMorning')
        : isNumberInRange({ from: 12, to: 17 })(hours)
          ? label('greetingDay')
          : label('greetingEvening');

    const userName =
      (auth.persistedUser &&
        auth.persistedUser.username &&
        auth.persistedUser.username[0].toUpperCase() +
        auth.persistedUser.username.slice(1).toLowerCase()) ||
      '';

    const localizedUserName = locale === 'en' && userName ? transliterate(userName) : userName;
    // const restoreLinkLocale = locale === 'en' ? 'ENGLISH' : 'RUSSIAN';
    // const restoreLink = `https://enter.unicredit.ru/v2/cgi/bsi.dll?t=RT_2RecoveryPass.RECOVERYPASS_FRAME&L=${restoreLinkLocale}`

    return (
      <div className={styles.Step}>
        <div className={styles.HeaderContainer}>
          <Div className={styles.HeaderTitle} translateId={label('login')} />
          <Link className={styles.SignupLink} to="/signup" translateId={label('signUp')} />
        </div>
        {!localizedUserName ? (
          <Fragment>
            {auth.isChangePasswordRequired ? (
              <div className={styles.PhoneSendingNotice}>
                <Translate id={label('passwordOnPhone')} />
                {` ${phoneNumber}`}
              </div>
            ) : null}
            <div className={styles.InputContainer}>
              <Field
                {...options.login}
                disabled={auth.isRequestPending}
                onFocus={this.hideNotificationMessage}
              />
            </div>
            <div className={styles.InputContainer}>
              <Field
                {...options.password}
                disabled={auth.isRequestPending}
                onFocus={this.hideNotificationMessage}
              />
            </div>
          </Fragment>
        ) : (
            <Fragment>
              <div className={styles.UserContainer}>
                <ProfileIcon className={styles.ProfileIcon} />
                <div className={styles.UserMessage}>
                  <div className={styles.UserGreeting}>
                    <Translate id={id} />
                    {', '}
                    {localizedUserName}!
                </div>
                  <Span
                    className={styles.ChangeUserLink}
                    onClick={clearUserCache}
                    translateId={label('changeUser')}
                  />
                </div>
              </div>
              <Field
                component={ReduxInput}
                disabled={auth.isRequestPending}
                labelId={formLabel('password')}
                name="password"
                type="password"
                validate={reduxFieldRequired}
                onFocus={this.hideNotificationMessage}
              />
            </Fragment>
          )}

        {this.renderCaptchaBlock()}

        <div className={styles.ButtonContainer}>
          <Button
            round
            showLoader={auth.isRequestPending}
            translateId={label('signinBtn')}
            type="submit"
          />
        </div>
        <div className={styles.PwdRecoveryContainer}>
          <Link
            className={styles.PwdRecoveryLink}
            translateId={label('restorePassword')}
            to="/restore-password"
          />
          {/* <a target="_blank" rel='noopener noreferrer' href={restoreLink}
            className={styles.PwdRecoveryLink}
          >
            <Translate id={label('restorePassword')} />
          </a> */}
        </div>
      </div>
    );
  }
}

Step1.propTypes = {
  auth: PropTypes.object.isRequired,
  captcha: PropTypes.object.isRequired,
  clearUserCache: PropTypes.func,
  phoneNumber: PropTypes.string,
  locale: PropTypes.string.isRequired,
  notification: PropTypes.shape({
    notification: PropTypes.shape({
      visible: PropTypes.bool,
    }),
  }),
  hideNotification: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, notification, pwdRecovery, captcha, localize }) => ({
  auth,
  phoneNumber: pwdRecovery.smsPhone,
  captcha,
  notification,
  locale: getActiveLanguage(localize).code,
});

const mapDispatchToProps = {
  clearUserCache,
  hideNotification,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step1);
