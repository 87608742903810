import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Actions from '../components/Actions/Actions';
import CreditDetails from './components/CreditDetails/';
import { Translation, withErrorBoundary } from 'shared';
import { accountsSelector } from 'store/selectors/accounts';

import SetVisibilityBlock from './components/SetVisibilityBlock';

import {
  getRequisitesInfo,
  downloadRequisites,
  printRequisites,
  downloadCreditInfo,
  printCreditInfo,
  sendPdfFileToEmail,
  setProductVisibility,
} from 'actions/products';

import { labelMaker } from 'shared/utils';

import styles from './Account.module.scss';

const label = labelMaker('products.account');

class Account extends Component {
  state = {
    requisitesShown: false,
  };

  handleInfo = () =>
    this.setState({
      requisitesShown: false,
    });

  handleRequisites = () =>
    this.setState({
      requisitesShown: true,
    });

  onChange = () => {
    const { credit, setProductVisibility } = this.props;
    const visible = credit.visibilityRule >= 2;
    setProductVisibility(credit.id, !visible, 'LOAN');
  };

  componentDidMount() {
    const { getRequisitesInfo } = this.props;

    getRequisitesInfo();
  }

  render() {
    const { Div } = Translation;
    const {
      credit: { contractRef, visibilityRule },
      printRequisites,
      downloadRequisites,
      printCreditInfo,
      downloadCreditInfo,
      sendPdfFileToEmail,
      isVisibilityPending,
    } = this.props;
    const { requisitesShown } = this.state;

    return (
      this.props.accounts && (
        <Fragment>
          <div className={styles.Body}>
            <div className={styles.Content}>
              <div className={styles.About}>
                <div className={styles.ProductColumn}>
                  <Div className={styles.Title} translateId={label('paymentSchedule')} />
                  <div className={styles.DisplaySettings}>
                    <CreditDetails
                      {...{
                        requisitesShown,
                        handleInfo: this.handleInfo,
                        handleRequisites: this.handleRequisites,
                      }}
                    />
                  </div>
                  <SetVisibilityBlock
                    isVisibilityPending={isVisibilityPending}
                    visibilityRule={visibilityRule}
                    onChange={this.onChange}
                  />
                </div>
                <div className={styles.ActionsColumn}>
                  <Actions
                    titleTranslateId="products.actions.accountTitle"
                    onSave={() =>
                      requisitesShown
                        ? downloadRequisites(contractRef)
                        : downloadCreditInfo(contractRef)
                    }
                    onPrint={() =>
                      requisitesShown ? printRequisites(contractRef) : printCreditInfo(contractRef)
                    }
                    onSend={() =>
                      requisitesShown
                        ? sendPdfFileToEmail({ section: 'requisites', contractRef })
                        : sendPdfFileToEmail({ section: 'account', contractRef })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )
    );
  }
}

Account.propTypes = {
  accounts: PropTypes.array,
  getRequisitesInfo: PropTypes.func.isRequired,
  credit: PropTypes.object.isRequired,
  downloadRequisites: PropTypes.func.isRequired,
  printRequisites: PropTypes.func.isRequired,
  downloadCreditInfo: PropTypes.func.isRequired,
  printCreditInfo: PropTypes.func.isRequired,
  isVisibilityPending: PropTypes.bool.isRequired,
  sendPdfFileToEmail: PropTypes.func.isRequired,
  setProductVisibility: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  accounts: accountsSelector(state),
  credit: state.products.credit,
  isVisibilityPending: state.products.isVisibilityPending,
});

const mapDispatchToProps = {
  getRequisitesInfo,
  downloadRequisites,
  printRequisites,
  downloadCreditInfo,
  printCreditInfo,
  sendPdfFileToEmail,
  setProductVisibility,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withErrorBoundary(Account, 'Products/Loans/Account'))
);
