import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'react-text-mask';
import cn from 'classnames';

import { Translation, InputError } from '../..';

import styles from './PhoneInput.module.scss';

import { calculateWidth } from 'shared/utils';

class PhoneInput extends Component {
  state = {
    hasFocus: false,
    phoneCode: '+7 ',
  };

  onFocus = () => {
    this.setState({ hasFocus: true });
  };

  onBlur = e => {
    this.setState({
      hasFocus: false,
    });
    this.onChange(e, true);
  };

  onChange = ({ target }, isError) => {
    const { onChange } = this.props;
    const { name, value } = target;

    onChange({ value, name }, isError);
  };

  render() {
    const {
      disabled,
      errorId,
      isError,
      labelId,
      mask,
      name,
      placeholderChar,
      placeholder,
      touched,
      value,
    } = this.props;
    const { hasFocus, phoneCode } = this.state;

    const isErrorVisible = touched && errorId && !disabled && isError;
    const isInputFocused = hasFocus && !disabled;

    const error = isErrorVisible ? errorId : null;

    const externalBorderClassName = cn(styles.ExternalBorder, {
      [styles.DisabledExternalBorder]: disabled,
      [styles.FocusedExternalBorder]: isInputFocused,
      [styles.ErrorExternalBorder]: isErrorVisible,
    });
    const internalBorderClassName = cn(styles.InternalBorder, {
      [styles.FocusedInternalBorder]: isInputFocused,
      [styles.ErrorInternalBorder]: isErrorVisible,
    });
    const labelClassName = cn(styles.Label, {
      [styles.FocusedLabel]: isInputFocused || value,
      [styles.DisabledLabel]: disabled,
    });
    const PhoneCodeClassName = cn(styles.PhoneCode, {
      [styles.DisabledPhoneCode]: disabled,
    });

    const isPlaceholderVisible = !value && isInputFocused;
    const isContentVisible = value || isInputFocused;

    const newPlaceholder = isPlaceholderVisible ? placeholder : '';
    const inputLeftPadding = calculateWidth(phoneCode);

    return (
      <div className={styles.PhoneInput}>
        <Translation.Div className={labelClassName} translateId={labelId} />
        <div className={externalBorderClassName}>
          <div className={internalBorderClassName}>
            {isContentVisible ? <div className={PhoneCodeClassName}>{phoneCode}</div> : null}
            <Input
              autoComplete="off"
              className={styles.Input}
              disabled={disabled}
              guide={false}
              mask={mask}
              name={name}
              onChange={this.onChange}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              placeholder={newPlaceholder}
              placeholderChar={placeholderChar}
              value={value}
              style={{ paddingLeft: inputLeftPadding + 20 }}
            />
          </div>
        </div>
        <InputError errorId={error} />
      </div>
    );
  }
}

PhoneInput.propTypes = {
  disabled: PropTypes.bool,
  errorId: PropTypes.string,
  isError: PropTypes.bool,
  labelId: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholderChar: PropTypes.string,
  placeholder: PropTypes.string,
  touched: PropTypes.bool,
  value: PropTypes.any,
  mask: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
};

PhoneInput.defaultProps = {
  onChange: () => {},
  value: '',
};

export default PhoneInput;
