import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import { Button, Translation } from 'shared';

import styles from './Step3.module.scss';

const isEqualToNewPassword = (value, values) =>
  value !== values.password ? 'shared.errors.notEqualPasswords' : undefined;

const Step3 = props => {
  const { auth, signup, options, renderCaptchaBlock } = props;

  const { isRequestPending: isSignupRequestPending, isRegistered } = signup;
  const { isRequestPending: isAuthRequestPending } = auth;

  const disabled = isSignupRequestPending || isAuthRequestPending;

  return (
    <Fragment>
      <Translation.Div className={styles.Text} translateId="signup.comeUpLoginAndCardOrAccount" />
      <div className={styles.InputRow}>
        <Field {...options.login} disabled={disabled || isRegistered} />
      </div>
      <div className={styles.InputRow}>
        <Field {...options.password} disabled={disabled || isRegistered} />
      </div>
      <div className={styles.InputRow}>
        <Field
          {...options.confirmedPassword}
          disabled={disabled || isRegistered}
          validate={[...options.confirmedPassword.validate, isEqualToNewPassword]}
        />
      </div>
      <Translation.Div className={styles.PasswordTip} translateId="signup.passwordRule" />

      {renderCaptchaBlock(disabled)}

      <div className={styles.ButtonContainer}>
        <Button type="submit" showLoader={disabled} translateId="signup.continueBtn" />
      </div>
    </Fragment>
  );
};

Step3.propTypes = {
  signup: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  renderCaptchaBlock: PropTypes.func.isRequired,
};

const mapState = ({ signup, auth }) => ({
  signup,
  auth,
});

export default connect(mapState)(Step3);
