import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { NumericInput, Button } from 'shared';

import { paymentNextStep } from 'actions/newPayment/index';
import { labelMaker, getProps as buildGetProps } from 'shared/utils';

import { validateSubmit, isSubmitValid } from 'shared/validators';

import styles from './Step1.module.scss';

const label = labelMaker('payments');

class Step1 extends Component {
  handleSubmit = () => {
    const { fields, submitFields } = this.props;
    const fieldsForValidation = ['sum'];

    if (isSubmitValid(fields, fieldsForValidation)) {
      const { paymentNextStep } = this.props;

      paymentNextStep();
    } else {
      validateSubmit(fields, fieldsForValidation, submitFields);
    }
  };

  render() {
    const { fields, accountToAccount, onSumChange, maxSum, products } = this.props;
    const { isLoading } = accountToAccount;

    const sumInputProps = buildGetProps({ fields, onChange: onSumChange })('sum');

    return (
      <>
        <div className={styles.InputContainer}>
          <NumericInput
            currency={products.credit.ccy}
            max={maxSum}
            {...sumInputProps}
            disabled={isLoading}
          />
        </div>

        <div className={styles.SubmitContainer}>
          <Button
            translateId={label('continueBtn')}
            onClick={this.handleSubmit}
            showLoader={isLoading}
          />
        </div>
      </>
    );
  }
}

Step1.propTypes = {
  products: PropTypes.object.isRequired,
  maxSum: PropTypes.number,
  submitFields: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSumChange: PropTypes.func.isRequired,
  fields: PropTypes.shape().isRequired,
  paymentNextStep: PropTypes.func.isRequired,
  accountToAccount: PropTypes.object.isRequired,
};

const mapStateToProps = ({ products }) => ({
  accountToAccount: products.payment.accountToAccount,
  products: products,
});

const mapDispatchToProps = {
  paymentNextStep,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step1);
