import React, { Component } from 'react';
import { addressCompletionReq } from 'actions/dadata/dadataRequests';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Input from '../RoundInput/Input';
import Menu from './Menu';

import styles from './LocationInput.module.scss';

import { debounce } from 'shared/utils';

class LocationInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locations: [],

      isMenuOpen: false,
      isMenuLoading: false,
    };
    this.loadHints = debounce(this.asyncLocationLoad, 300);
  }

  toggleMenuView = option => {
    const { isMenuOpen } = this.state;

    this.setState({
      isMenuOpen: !isMenuOpen,
    });
  };
  openMenuView = () => {
    this.setState({
      isMenuOpen: true,
    });
  };
  hideMenuView = () => {
    this.setState({
      isMenuOpen: false,
    });
  };

  asyncLocationLoad = reqValue => {
    const { addressCompletionReq, value } = this.props;
    addressCompletionReq(reqValue)
      .then(data => {
        const locations = data.data.suggestions;

        if (value === reqValue) {
          this.setState({
            isMenuLoading: false,
            locations,
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  onChange = ({ name, value }) => {
    const { value: currValue } = this.props;
    const { isMenuOpen } = this.state;

    if (currValue !== value) {
      this.loadHints(value);
      this.setState({
        isMenuLoading: true,
      });

      if (!isMenuOpen) {
        this.openMenuView();
      }
    } else if (currValue === value && isMenuOpen) {
      this.hideMenuView();
    }

    this.props.onChange({ name, value });
  };

  selectOption = option => () => {
    const { name } = this.props;

    this.props.onChange({ name, value: option.value });
    this.hideMenuView();
  };

  render() {
    const { locations, isMenuOpen, isMenuLoading } = this.state;
    const { dadata } = this.props;

    return (
      <div className={styles.LocationInputComponent}>
        <Input {...this.props} onChange={this.onChange} />
        {dadata.apiKey ? (
          <Menu
            isOpen={isMenuOpen}
            locations={locations}
            isLoading={isMenuLoading}
            selectOption={this.selectOption}
            onClickOutside={this.hideMenuView}
          />
        ) : null}
      </div>
    );
  }
}

LocationInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  addressCompletionReq: PropTypes.func.isRequired,
  dadata: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
};

const mapStateToProps = ({ dadata }) => ({
  dadata,
});

const mapDispatchToProps = dispatch => ({
  addressCompletionReq: args => dispatch(addressCompletionReq(args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationInput);
