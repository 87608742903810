import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchCard2CardSignature,
  clearCard2CardSignature,
  fetchCard2CardPage,
} from 'actions/transfers';
import PropTypes from 'prop-types';

class CardTransfer extends Component {
  formRef = React.createRef();

  componentDidMount() {
    this.props.fetchCard2CardSignature();
  }

  componentWillUnmount() {
    this.props.clearCard2CardSignature();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.signature && this.props.signature) {
      this.formRef.current.submit();
    }
  }

  render() {
    const { signature } = this.props;
    return (
      <>
        {signature && (
          <form
            action="https://unicreditbank-p2p-test.intervale.ru"
            method="post"
            target="card2card_iframe"
            ref={this.formRef}
          >
            <input type="hidden" name="IDCLIENT" value={signature.IDCLIENT} />
            <input type="hidden" name="ORDER" value={signature.ORDER} />
            <input type="hidden" name="MERCH_NAME" value={signature.MERCH_NAME} />
            <input type="hidden" name="MERCH_URL" value={signature.MERCH_URL} />
            <input type="hidden" name="MERCHANT" value={signature.MERCHANT} />
            <input type="hidden" name="TERMINAL" value={signature.TERMINAL} />
            <input type="hidden" name="TIMESTAMP" value={signature.TIMESTAMP} />
            <input type="hidden" name="NONCE" value={signature.NONCE} />
            <input type="hidden" name="BACKREF" value={signature.BACKREF} />
            <input type="hidden" name="TRTYPE" value={signature.TRTYPE} />
            <input type="hidden" name="P_SIGN" value={signature.P_SIGN} />
            <input type="hidden" name="LANG" value={signature.LANG} />
            <input type="hidden" name="DESC" value={signature.DESC} />
            <input type="hidden" name="COUNTRY" value={signature.COUNTRY} />
            <input type="hidden" name="MERCH_GMT" value={signature.MERCH_GMT} />
            <input type="hidden" name="ISMOBILE" value="0" />
          </form>
        )}
        <iframe
          name="card2card_iframe"
          title="Перевод с карты на карту"
          width="100%"
          height="950"
          frameBorder="0"
          style={{ marginLeft: -100 }}
        ></iframe>
      </>
    );
  }
}

const mapStateToProps = state => ({
  signature: state.transfers.card2CardSignature,
});

const mapDispatchToProps = {
  fetchCard2CardSignature,
  clearCard2CardSignature,
  fetchCard2CardPage,
};

CardTransfer.propTypes = {
  signature: PropTypes.object,
  fetchCard2CardPage: PropTypes.func.isRequired,
  fetchCard2CardSignature: PropTypes.func.isRequired,
  clearCard2CardSignature: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardTransfer);
