import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'react-text-mask';
import cn from 'classnames';

import { Translation, InputError } from '../..';

import styles from './PhoneInput.module.scss';

import { calculateWidth } from 'shared/utils';

class PhoneInput extends Component {
  state = {
    phoneCode: '+7 ',
  };

  componentDidUpdate(prevProps) {
    const { meta: currMeta, disabled: currDisabled } = this.props;
    const { disabled: prevDisabled } = prevProps;

    // If we disable focused input and undisable it, meta active
    // is true, but focus is not on element. We need to focus it.
    if (prevDisabled && !currDisabled && currMeta.active && this.input) {
      this.input.focus();
    }
  }

  render() {
    const { disabled, labelId, meta, mask, placeholderChar, placeholder, input } = this.props;
    const { active, touched, error } = meta;
    const { phoneCode } = this.state;

    const isErrorVisible = touched && error && !disabled;
    const isInputFocused = active && !disabled;

    const err = touched ? error : null;

    const externalBorderClassName = cn(styles.ExternalBorder, {
      [styles.DisabledExternalBorder]: disabled,
      [styles.FocusedExternalBorder]: isInputFocused,
      [styles.ErrorExternalBorder]: isErrorVisible,
    });
    const internalBorderClassName = cn(styles.InternalBorder, {
      [styles.FocusedInternalBorder]: isInputFocused,
      [styles.ErrorInternalBorder]: isErrorVisible,
    });
    const labelClassName = cn(styles.Label, {
      [styles.FocusedLabel]: active || input.value,
      [styles.DisabledLabel]: disabled,
    });
    const PhoneCodeClassName = cn(styles.PhoneCode, {
      [styles.DisabledPhoneCode]: disabled,
    });

    const isPlaceholderVisible = !input.value && isInputFocused;
    const isContentVisible = input.value || isInputFocused;

    const newPlaceholder = isPlaceholderVisible ? placeholder : '';
    const inputLeftPadding = calculateWidth(phoneCode);

    return (
      <div className={styles.PhoneInput}>
        <Translation.Div className={labelClassName} translateId={labelId} />
        <div className={externalBorderClassName}>
          <div className={internalBorderClassName}>
            {isContentVisible ? <div className={PhoneCodeClassName}>{phoneCode}</div> : null}
            <Input
              autoComplete="off"
              className={styles.Input}
              disabled={disabled}
              guide={false}
              mask={mask}
              onChange={this.onChange}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              placeholder={newPlaceholder}
              placeholderChar={placeholderChar}
              value={input.value}
              {...input}
              style={{ paddingLeft: inputLeftPadding + 20 }}
            />
          </div>
        </div>
        <InputError errorId={err} />
      </div>
    );
  }
}

PhoneInput.propTypes = {
  disabled: PropTypes.bool,
  labelId: PropTypes.string,
  placeholderChar: PropTypes.string,
  placeholder: PropTypes.string,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  mask: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
};

PhoneInput.defaultProps = {
  onChange: () => {},
  value: '',
};

export default PhoneInput;
