import React from 'react';
import PropTypes from 'prop-types';

import { Translation } from '..';

// Anchor has to enforce the rule laid out here
// https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-target-blank.md
const Anchor = ({ href, children, translateId, ...rest }) => (
  <a href={href} rel="noreferrer noopener" target="_blank" {...rest}>
    {translateId ? <Translation.Span translateId={translateId} /> : null}
    {children}
  </a>
);

Anchor.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string.isRequired,
  translateId: PropTypes.string,
};

export default Anchor;
