import { createReducer } from 'redux-act';

import {
  fetchCaptchaIdStart,
  fetchCaptchaIdSuccess,
  fetchCaptchaIdFailure,
  fetchCaptchaImageStart,
  fetchCaptchaImageSuccess,
  fetchCaptchaImageFailure,
  setTempCaptchaRequire,
  resetTempCaptchaRequire,
  resetCaptcha,
  setCaptchaId,
} from 'actions/captcha/index';

const defaultState = {
  // Global variable response for using captcha
  isCaptchaRequired: false,

  // Temp captcha for exceptional situations
  isTempCaptchaRequired: false,

  captchaScr: '',
  id: null,
  isLoading: false,
};

const captcha = createReducer(
  {
    [setCaptchaId]: (state, captchaId) => {
      return {
        ...state,

        id: captchaId,
      };
    },

    [fetchCaptchaIdStart]: state => {
      return {
        ...state,

        captchaScr: '',
        id: null,
        isLoading: true,
      };
    },
    [fetchCaptchaIdSuccess]: (state, id) => {
      return {
        ...state,

        id,
        isLoading: false,
      };
    },
    [fetchCaptchaIdFailure]: (state, id) => {
      return {
        ...state,

        isLoading: false,
      };
    },

    [fetchCaptchaImageStart]: (state, id) => {
      return {
        ...state,
        id,
        captchaScr: '',
        isLoading: true,
      };
    },
    [fetchCaptchaImageSuccess]: (state, captchaScr) => {
      return {
        ...state,

        captchaScr,
        isLoading: false,
      };
    },
    [fetchCaptchaImageFailure]: state => {
      return {
        ...state,

        isLoading: false,
      };
    },

    [resetCaptcha]: () => {
      return {
        ...defaultState,
      };
    },

    [setTempCaptchaRequire]: state => {
      return {
        ...state,

        isTempCaptchaRequired: true,
      };
    },
    [resetTempCaptchaRequire]: state => {
      return {
        ...state,

        isTempCaptchaRequired: false,
      };
    },
  },
  defaultState
);

export default captcha;
