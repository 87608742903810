import { createAction } from 'redux-act';
import { getTranslate } from 'react-localize-redux';
import { getErrorMessage, labelMaker } from 'shared/utils';

import { showWarnNotification } from '../notification';
import { fetchCardsReq, fetchCardsCatalogReq } from './cardsRequests';

const label = labelMaker('errorsGeneral');

export const fetchCardsStart = createAction('FETCH_CARDS_START');
export const fetchCardsSuccess = createAction('FETCH_CARDS_SUCCESS');
export const fetchCardsFailure = createAction('FETCH_CARDS_FAILURE');

export const fetchCardsCatalogStart = createAction('FETCH_CARDS_CATALOG_START');
export const fetchCardsCatalogSuccess = createAction('FETCH_CARDS_CATALOG_SUCCESS');
export const fetchCardsCatalogFailure = createAction('FETCH_CARDS_CATALOG_FAILURE');

export const fetchCards = () => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(fetchCardsStart());
    const response = await dispatch(fetchCardsReq());

    if (Array.isArray(response)) {
      dispatch(fetchCardsSuccess(response));
      return Promise.resolve(response);
    } else {
      throw translate(label('serviceUnavailable'));
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(label('serviceUnavailable')));
    dispatch(fetchCardsFailure());
    dispatch(showWarnNotification(errMessage));
    return Promise.reject(errMessage);
  }
};

export const fetchCardsCatalog = () => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(fetchCardsCatalogStart());
    const response = await dispatch(fetchCardsCatalogReq());

    if (Array.isArray(response)) {
      dispatch(fetchCardsCatalogSuccess(response));
      return Promise.resolve(response);
    } else {
      throw translate(label('serviceUnavailable'));
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(label('serviceUnavailable')));
    dispatch(fetchCardsCatalogFailure());
    dispatch(showWarnNotification(errMessage));
    return Promise.reject(errMessage);
  }
};
