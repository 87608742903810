import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { CodeInput, Translation } from '..';
import { resetSignature, signatureValueChange } from 'actions/signature';

import styles from './ScratchCardInput.module.scss';

import { labelMaker } from 'shared/utils';

const lScratch = labelMaker('shared.scratch');

class ScratchCardInput extends Component {
  onChange = field => {
    const { onChange } = this.props;
    const { value } = field;

    onChange(value);
  };

  componentWillUnmount() {
    this.props.resetSignature();
  }

  render() {
    const { Div } = Translation;
    const { scratchCode, numOfFields, onSubmit, signature, children } = this.props;
    const { error, value, isLoading } = signature;

    return (
      <div className={styles.ScratchCardInput}>
        <div className={styles.Text}>
          <Translate id={lScratch('enterCode')} />
          &nbsp;
          {scratchCode}
          &nbsp;
          <Translate id={lScratch('fromScratch')} />
        </div>
        <Div className={styles.CodeTitle} translateId={lScratch('codeNumber')} />
        <div className={styles.CodeContainer}>
          <CodeInput
            onChange={this.onChange}
            value={value}
            disabled={isLoading}
            onSubmit={onSubmit}
            error={error}
            fields={numOfFields}
          />
          {children}
        </div>
      </div>
    );
  }
}

ScratchCardInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  scratchCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  numOfFields: PropTypes.number,

  onChange: PropTypes.func.isRequired,
  signature: PropTypes.object.isRequired,
  resetSignature: PropTypes.func.isRequired,
};

ScratchCardInput.defaultProps = {
  onSubmit: () => {},
  numOfFields: 4,
};

const mapStateToProps = ({ signature }) => ({
  signature,
});

const mapDispatchToProps = {
  onChange: signatureValueChange,
  resetSignature,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScratchCardInput);
