/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { getActiveLanguage } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import qs from 'qs';

import { labelMaker } from 'shared/utils';
import { RoundSelect, Translation, DateRangeFilter, withErrorBoundary } from 'shared';

import {
  fetchHistoryList,
  fetchHistoryDetails,
  clearHistoryData,
  fetchNSPCHistory,
  fetchOperationCatalog,
  fetchMaskedCardNumber,
} from 'actions/operationsHistory';
import { operationsHistorySelector } from 'store/selectors/operationsHistory';
// import Actions from './components/Actions/Actions';

import HistoryList from './components/HistoryList';

import styles from './OperationsHistory.module.scss';
import historyOptions from './options';

const lHistory = labelMaker('operationsHistory.history');

const dateFormat = 'YYYY-MM-DD';
const type = { 1: false, 2: true };

class OperationsHistory extends Component {
  state = {
    periodFilter: 'period',
    periodType: historyOptions.periodType.data[1].value,
  };

  onChange = ({ value, name }) => {
    this.setState({
      [name]: value,
    });
  };

  // Changing by calendar period
  onManualRangeChange = ({ startDate, endDate }) => {
    const startDateValue = moment(startDate).format(dateFormat);
    const endDateValue = moment(endDate).format(dateFormat);

    const params = qs.stringify({
      startDate: startDateValue,
      endDate: endDateValue,
    });
 
    this.props.push(`?${params}`);
  };

  // Changing by value
  onRangeChange = ({ name, value }) => {
    this.onChange({ name, value });

    // For period reloading responsible onManualRangeChange
    if (value === 'period') {
      return;
    }

    let startDate;
    let endDate = moment().format(dateFormat);

    switch (value) {
      case 'year':
        startDate = moment()
          .subtract(1, 'year')
          .format(dateFormat);
        break;
      default:
        break;
    }

    this.props.history.push(`?${qs.stringify({ startDate, endDate })}`);
  };

  onTypeChange = ({ name, value }) => {
    const queryParams = this.composeQueryParams(this.props.location.search);
    const typeParams = this.composeTypeParams(value);
    const { startDate, endDate } = this.isQueryParamsValid(queryParams)
      ? queryParams
      : this.composeDefaultQueryParams();

    this.reloadHistory({ startDate, endDate, ...typeParams });
    this.onChange({ name, value });
  }

  composeTypeParams = periodType => {
    if ([1, 2].includes(periodType)) {
      return { isOnlineBank: type[periodType] };
    } else {
      return { type: 'C2CPush', direction: '' };
    }
  }

  composeQueryParams = search => {
    const { startDate, endDate } = search[0] === '?' ? qs.parse(search.substr(1)) : qs.parse(search);

    return {
      startDate: moment(startDate, dateFormat).format(dateFormat),
      endDate: moment(endDate, dateFormat).format(dateFormat),
    };
  };

  isQueryParamsValid = ({ startDate, endDate }) => {
    const formattedStartDate = moment(startDate, dateFormat);
    const formattedEndDate = moment(endDate, dateFormat);

    return (
      formattedStartDate.isValid() &&
      formattedEndDate.isValid() &&
      formattedEndDate.diff(formattedStartDate, 'days' >= 0)
    );
  };

  composeDefaultQueryParams = () => ({
    startDate: moment()
      .subtract(3, 'day')
      .format(dateFormat),
    endDate: moment().format(dateFormat),
  });

  isParamsByYear = ({ startDate, endDate }) => {
    const lastYearStartDate = moment()
      .subtract(1, 'year')
      .format(dateFormat);
    const today = moment().format(dateFormat);

    return lastYearStartDate === startDate && today === endDate;
  };

  reloadHistory = ({ startDate, endDate, ...params }) => {
    this.props.clearHistoryData();
    if (params.type && params.type === 'C2CPush') {
      return this.props.fetchNSPCHistory({ dateFrom: startDate, dateTo: endDate, ...params });
    }
    this.props.fetchHistoryList({ ...this.getDateParams({ startDate, endDate }), ...params });
  };

  getDateParams = ({ startDate, endDate }) => {
    return {
      dateFrom: startDate,
      dateTo: endDate,
    }
  }

  componentDidMount() {
    const { location, history, fetchOperationCatalog } = this.props;
    const queryParams = this.composeQueryParams(location.search);
    const typeParams = this.composeTypeParams(this.state.periodType);
    fetchOperationCatalog()
    if (this.isQueryParamsValid(queryParams)) {
      // Setting filter in accordance with query params
      if (this.isParamsByYear(queryParams)) {
        this.setState({
          periodFilter: 'year',
        });
      } else {
        this.setState({
          periodFilter: 'period',
        });
      }
      this.reloadHistory({ ...queryParams, sync: true });
      this.reloadHistory({ ...queryParams, ...typeParams });
    } else {
      const defaultHistoryParams = this.composeDefaultQueryParams();
      // Default history params equal last 3 days
      this.setState({
        periodFilter: 'period',
      });
      history.push(`?${qs.stringify(defaultHistoryParams)}`);
      this.reloadHistory({ ...defaultHistoryParams, sync: true });
      this.reloadHistory({ ...defaultHistoryParams, ...typeParams });
    }
  }

  componentDidUpdate(prevProps) {
    const { location: currLocation } = this.props;
    const { location: prevLocation } = prevProps;

    const queryParams = this.composeQueryParams(currLocation.search);
    const typeParams = this.composeTypeParams(this.state.periodType);
    // Tracking location search changes to fetch history
    if (
      prevLocation.search &&
      currLocation.search !== prevLocation.search &&
      this.isQueryParamsValid(queryParams)
    ) {
      this.reloadHistory({ ...queryParams, ...typeParams });
    }
  }

  render() {
    const {
      locale,
      operations,
      operationDetails,
      operationsTotal,
      operationsLimit,
      fetchHistoryList,
      fetchHistoryDetails,
      location,
      isLoading,
      detailsLoading,
      detailsError,
      catalog,
      loadingDetailId,
      fetchMaskedCardNumber,
    } = this.props;
    const {
      periodType,
    } = this.state;

    // In case change manual changing range params

    const queryParams = this.composeQueryParams(location.search);
    const range = this.isQueryParamsValid(queryParams)
      ? this.composeQueryParams(location.search)
      : this.composeDefaultQueryParams();

    return (
      <div className={styles.Body}>
        <div className={styles.Content}>
          <div className={styles.History}>
            <div className={styles.HistoryHeader}>
              <Translation.Div
                className={styles.HistoryTitle}
                translateId={lHistory('historyTitle')}
              />
              <div className={styles.FilterContainer}>
                <RoundSelect
                  name="periodType"
                  {...historyOptions.periodType}
                  value={this.state.periodType}
                  onChange={this.onTypeChange}
                  lang={locale}
                />
              </div>
              <div className={styles.FilterContainer}>
                <DateRangeFilter
                  name="periodFilter"
                  {...historyOptions.periodFilter}
                  value={this.state.periodFilter}
                  onManualRangeChange={this.onManualRangeChange}
                  onChange={this.onRangeChange}
                  range={{
                    startDate: moment(range.startDate, dateFormat).toDate(),
                    endDate: moment(range.endDate, dateFormat).toDate(),
                  }}
                  lang={locale}
                />
              </div>
              {/* <div className={styles.FilterContainer}>
                <RoundSelect
                  {...getProps('typeFilter')}
                  data={[
                    {
                      labelId: lHistory('allOperationTypes'),
                      value: '1',
                    },
                    // { labelId: lHistory('payments'), value: '2' },
                    // { labelId: lHistory('transfers'), value: '3' },
                  ]}
                />
              </div> */}
            </div>
            <div className={styles.HistoryContainer}>
              <HistoryList
                {...{
                  location,
                  locale,
                  operations,
                  operationDetails,
                  operationsTotal,
                  operationsLimit,
                  fetchHistoryList,
                  fetchHistoryDetails,
                  detailsLoading,
                  detailsError,
                  dateRange: this.composeQueryParams(location.search),
                  typeParams: this.composeTypeParams(this.state.periodType),
                  isLoading,
                  periodType,
                  catalog,
                  loadingDetailId,
                  fetchMaskedCardNumber,
                }}
              />
            </div>
          </div>
          <div className={styles.HistoryActions}>
            {/* <Actions titleTranslateId={lHistory('historyActionsTitle')} /> */}
          </div>
        </div>
      </div>
    );
  }
}

OperationsHistory.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  operations: PropTypes.array,
  operationDetails: PropTypes.object,
  operationsTotal: PropTypes.number,
  operationsLimit: PropTypes.number,
  fetchHistoryList: PropTypes.func.isRequired,
  fetchHistoryDetails: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  clearHistoryData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  detailsLoading: PropTypes.bool,
  detailsError: PropTypes.bool,
};

const mapState = state => ({
  locale: getActiveLanguage(state.localize).code,
  operations: operationsHistorySelector(state),
  operationsTotal: state.operationsHistory.dataTotal,
  operationsLimit: state.operationsHistory.dataLimit,
  operationDetails: state.operationsHistory.details,
  detailsError: state.operationsHistory.detailsError,
  detailsLoading: state.operationsHistory.detailsLoading,
  isLoading: state.operationsHistory.isLoading,
  catalog: state.operationsHistory.catalog,
  loadingDetailId: state.operationsHistory.loadingDetailId,
});

const mapDispatch = {
  fetchHistoryList,
  fetchHistoryDetails,
  push,
  clearHistoryData,
  fetchNSPCHistory,
  fetchOperationCatalog,
  fetchMaskedCardNumber,
};

export default withRouter(
  connect(
    mapState,
    mapDispatch
  )(withErrorBoundary(OperationsHistory, 'OperationsHistory'))
);
