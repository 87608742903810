import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import styles from './CreditDetails.module.scss';

import { getLocaleDateFormat, formatNumber } from 'shared/utils';
import Details from './Details';

const Information = ({ credit, locale, createRowData }) => {
  const dateFormat = getLocaleDateFormat(locale);
  const {
    maturityDate,
    originalPrincipal,
    dealDate,
    totalRemainSum,
    totalRemainInterestSum,
    payments,
    schedulesCount,
    ccy,
    pastdue,
    loanHolidaysInfo
  } = credit;

  const dateDiff = (fromDate, toDate) => {
    const start = moment(fromDate);
    const end = moment(toDate);

    try {
      var diff = moment
        .duration(end.diff(start))
        .asDays()
        .toFixed(0);
    } catch (err) {
      console.error('Error: ', err);
    }

    return diff;
  };

  const loanTermInDate = dateDiff(dealDate, maturityDate);
  const lastPayment =
    Array.isArray(payments) && payments.length ? payments[payments.length - 1] : {};
  const { currentInterestRate, lastPaymentDate } = lastPayment;
  const interestToday =
    Array.isArray(pastdue) && pastdue.length ? pastdue[0].totalInterestAmount : 0;
  const remainPayment = parseFloat(totalRemainSum) + parseFloat(interestToday);

  const infoDetails = [
    createRowData(
      'amount',
      formatNumber(parseFloat(originalPrincipal).toFixed(2), { currency: ccy })
    ),
    createRowData('interestRate', currentInterestRate),
    createRowData('remainingPayments', parseInt(schedulesCount, 10)),
    createRowData('loanTermInDay', parseInt(loanTermInDate, 10)),
    createRowData('dealDate', moment(dealDate).format(dateFormat)),
    createRowData('lastPaymentDate', moment(lastPaymentDate).format(dateFormat)),
    createRowData(
      'amountToPay',
      formatNumber(parseFloat(remainPayment).toFixed(2), {
        currency: ccy,
      })
    ),
    createRowData(
      'estimatedPayment',
      formatNumber((parseFloat(totalRemainSum) + parseFloat(totalRemainInterestSum)).toFixed(2), {
        currency: ccy,
      })
    ),
  ];

  return (
    <div className={styles.Details}>
      {loanHolidaysInfo?.messageShort && <h4 style={{marginBottom:20}}>{loanHolidaysInfo?.messageShort}</h4>}
      <Details details={infoDetails} />
    </div>
  );
};

Information.propTypes = {
  credit: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  createRowData: PropTypes.func.isRequired,
};

export default Information;
