import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getActiveLanguage } from 'react-localize-redux';
import moment from 'moment';

import { minRepaySelector, getMinPrepayDateSelector } from 'store/selectors/products';

import styles from './Form.module.scss';

import { prepaymentBackStep } from 'actions/products';
import { setPaymentAmount } from 'actions/newPayment/index';
import { goTo } from 'actions/routes';
import { firstStepOptions, secondStepOptions } from './Steps/options';
import Steps from './Steps';

import { Translation } from 'shared';
import { labelMaker, get } from 'shared/utils';
import {
  shapeFields,
  setFieldValidators,
  enDate,
  ruDate,
  required,
  getValidationErrorId,
} from 'shared/validators';

const label = labelMaker('products.prepayment');

class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: shapeFields({
        ...firstStepOptions,
        ...secondStepOptions,
      }),
    };
  }

  getLocaleDateFormat = locale =>
    ({
      en: 'MM.DD.YYYY',
      ru: 'DD.MM.YYYY',
    }[locale]);

  dateFormat = this.getLocaleDateFormat(this.props.lang);

  onChange = ({ value, name }, showError) => {
    const { fields } = this.state;
    const { lang } = this.props;
    const field = fields[name];

    const { isError } = field;

    const newField = { ...field, value, touched: true, isError: showError || isError };
    const errorId = getValidationErrorId(newField);

    this.setState({
      fields: {
        ...fields,
        [name]: {
          ...newField,
          errorId,
          locale: lang,
        },
      },
    });
  };

  onSumInputChange = ({ value: newSum }, showError) => {
    const { fields } = this.state;
    const {
      products: { credit },
      minRepay
    } = this.props;

    const fullRepayPercent = parseFloat(get(credit, 'fullRepayPercent', 100));
    const maxValue = parseFloat(get(credit, 'principal', '0'));
    const minValue = parseFloat(minRepay);

    const { value: sum } = fields.sum;

    const newEnteredSum = parseFloat(newSum);
    const currentEnteredSum = parseFloat(sum);
    let value = 0;

    if (newEnteredSum >= currentEnteredSum && newEnteredSum !== 0) {
      if (newEnteredSum > maxValue && newEnteredSum !== maxValue) {
        value = maxValue;
      } else if (newEnteredSum >= maxValue * (fullRepayPercent / 100)) {
        value = maxValue;
      } else if (newEnteredSum < minValue) {
        value = minValue;
      } else {
        value = newSum;
      }
    } else {
      if (newEnteredSum > maxValue) {
        value = maxValue;
      } else {
        value = newSum;
      }
    }

    this.onChange({
      name: 'sum',
      value: parseFloat(value),
    });
  };

  onSumRangeChange = ({ value: newSum }, showError) => {
    const {
      products: { credit },
    } = this.props;

    const newEnteredSum = parseFloat(newSum);
    const maxValue = parseFloat(get(credit, 'principal', 0));

    const fullRepayPercent = parseFloat(get(credit, 'fullRepayPercent', 100));

    if (Math.floor(newEnteredSum) >= Math.floor(maxValue) && newEnteredSum !== maxValue) {
      this.onChange(
        {
          name: 'sum',
          value: maxValue,
        },
        showError
      );
    } else if (newEnteredSum >= maxValue * (fullRepayPercent / 100)) {
      this.onChange(
        {
          name: 'sum',
          value: maxValue,
        },
        showError
      );
    } else {
      this.onChange(
        {
          name: 'sum',
          value: parseFloat(newSum),
        },
        showError
      );
    }
  };

  isDayOff = value => {
    const { days } = this.props.products.prepayment.daysOff;
    if (days) {
      return !days.find(
        dayOff => dayOff.date === moment(value, this.dateFormat).format('YYYY-MM-DD')
      );
    }
    return true;
  };

  resetDate = () => {
    const { products, minPrepayDate, envInfo, localize, lang } = this.props;
    const { days } = products.prepayment.daysOff;

    let day = 0;
    const today = moment();
    const dateFormat = this.getLocaleDateFormat(getActiveLanguage(localize).code);

    const isEarlyDate = (minDate) => (value) => {
      return !moment(moment(minDate).format('YYYY-MM-DD')).isAfter(moment(value, dateFormat, true).format('YYYY-MM-DD'));
    }

    const dateValidators = lang === 'en'
    ? [{ enDate }, { required }, { isDayOff: this.isDayOff }]
    : [{ ruDate }, { required }, { isDayOff: this.isDayOff }]
    
    if (envInfo === 'prod') {dateValidators.push({ isEarlyDate: isEarlyDate(minPrepayDate) })}

    while (true) {
      if (this.isTileDisabled(days, today.add(day, 'days'))) {
        day++;
      } else {
        return this.setState({
          fields: shapeFields({
            ...{
              ...firstStepOptions,

              date: {
                ...firstStepOptions.date,
                value: moment(minPrepayDate).format(this.dateFormat),
                validators: dateValidators
              },
            },
            ...secondStepOptions,
          }),
        });
      }
    }
  };

  componentDidUpdate(prevProps) {
    const { daysOff: currDaysOff } = this.props.products.prepayment;
    const { daysOff: prevDaysOff } = prevProps.products.prepayment;

    if (!prevDaysOff.days && currDaysOff.days) {
      this.resetDate();
    }

    if (prevProps.lang !== this.props.lang) {
      this.dateFormat = this.getLocaleDateFormat(this.props.lang);
      this.setState({
        fields: {
          ...this.state.fields,
          date: {
            ...this.state.fields.date,
            value: moment(
              this.state.fields.date.value,
              this.getLocaleDateFormat(prevProps.lang)
            ).format(this.getLocaleDateFormat(this.props.lang)),
          },
        },
      });
    }
  }

  isTileDisabled = (days, date) => {
    if (days) {
      return days.find(dayOff => dayOff.date === date.format('YYYY-MM-DD'));
    }
    return false;
  };

  setValidators = () => {
    const { fields } = this.state;
    const { lang, envInfo, minPrepayDate, localize } = this.props;

    const isEarlyDate = (minDate) => (value) => {
      const dateFormat = this.getLocaleDateFormat(getActiveLanguage(localize).code);
      return !moment(moment(minDate).format('YYYY-MM-DD')).isAfter(moment(value, dateFormat, true).format('YYYY-MM-DD'));
    }

    const dateValidators = lang === 'en'
      ? [{ enDate }, { required }, { isDayOff: this.isDayOff }]
      : [{ ruDate }, { required }, { isDayOff: this.isDayOff }];

    if (envInfo === 'prod') {dateValidators.push({ isEarlyDate: isEarlyDate(minPrepayDate) })}

    this.setState({
      fields: {
        ...fields,
        date: {
          ...setFieldValidators(fields, 'date', dateValidators),
        },
      },
    });
  };

  onPayment = amount => () => {
    const {
      products: { credit },
      setPaymentAmount,
      goTo,
    } = this.props;
    const { contractRef } = credit;

    setPaymentAmount(amount);
    goTo(`/products/loans/${contractRef}/payments`);
  };

  submitFields = newFields => {
    this.setState({
      fields: {
        ...this.state.fields,
        ...newFields,
      },
    });
  };

  componentDidMount = () => {
    this.setValidators();
  };

  render() {
    const { products, prepaymentBackStep, minPrepayDate, isCreditHolidays } = this.props;
    const { fields } = this.state;
    const { Div } = Translation;

    const { step } = products.prepayment;

    return (
      <Fragment>
        <div className={styles.NavigationContainer}>
          <div className={styles.PrevArrow} />
          <Div
            className={styles.NavigationTitle}
            translateId={label('backBtn')}
            onClick={prepaymentBackStep}
          />
        </div>
        <Steps
          step={step}
          onChange={this.onChange}
          fields={fields}
          submitFields={this.submitFields}
          onSumRangeChange={this.onSumRangeChange}
          onSumInputChange={this.onSumInputChange}
          onPayment={this.onPayment}
          resetDate={this.resetDate}
          dateFormat={this.dateFormat}
          minDate={minPrepayDate}
          isCreditHolidays={isCreditHolidays}
        />
      </Fragment>
    );
  }
}

Form.propTypes = {
  goTo: PropTypes.func.isRequired,
  setPaymentAmount: PropTypes.func.isRequired,
  prepaymentBackStep: PropTypes.func.isRequired,
  localize: PropTypes.object.isRequired,
  products: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  minRepay: PropTypes.number.isRequired,
  minPrepayDate: PropTypes.instanceOf(Date).isRequired,
  envInfo: PropTypes.oneOf(['inta', 'intc', 'pp', 'prod', 'test']).isRequired,
  isCreditHolidays: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  lang: getActiveLanguage(state.localize).code,
  localize: state.localize,
  products: state.products,
  minPrepayDate: getMinPrepayDateSelector(state),
  minRepay: minRepaySelector(state),
  envInfo: get(state, `settings.envInfo.${[getActiveLanguage(state.localize).code]}`, 'prod'),
});

const mapDispatchToProps = {
  prepaymentBackStep,
  setPaymentAmount,
  goTo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form);
