import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Translate } from 'react-localize-redux';

import styles from './Stepper.module.scss';

const Stepper = props => {
  // const whiteSpaces = 20 * (steps - 1);
  return (
    <div className={styles.Stepper}>
      <div className={styles.Title}>
        <Translate id="signup.step">Шаг</Translate> {props.step}{' '}
        <Translate id="signup.of">из</Translate> {props.steps}
      </div>
      <div className={styles.StepsContainer}>
        {Array.from(Array(props.steps)).map((step, i) => (
          <div
            key={i}
            className={cn([styles.Step, { [styles.ActiveStep]: i === props.step - 1 }])}
          />
        ))}
      </div>
    </div>
  );
};

Stepper.propTypes = {
  steps: PropTypes.number.isRequired,
  step: PropTypes.number,
};

export default Stepper;
