import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  updateSession,
  setProlongationRequire,
  resetProlongationRequire,
} from 'actions/session/index';

const withNotification = Content => {
  class Page extends Component {
    timeoutIds = [];

    updateSession = () => {
      const { session, updateSession: updateSessionAction, resetProlongationRequire } = this.props;
      const minutesDiff = 7;
      if (!session.requireProlongation) {
        this.timeoutIds.forEach(id => clearInterval(id));
        return;
      }

      if (moment().diff(session.lastProlongation, 'minutes') > minutesDiff) {
        updateSessionAction().catch(err => resetProlongationRequire());
      }

      const updatingTimer = setTimeout(this.updateSession, 30000);
      this.timeoutIds.push(updatingTimer);
    };

    componentDidMount() {
      const { session, setProlongationRequire } = this.props;

      setProlongationRequire();
      const updatingTimer = setTimeout(this.updateSession, session.updatinginterval);
      this.timeoutIds.push(updatingTimer);
    }

    componentWillUnmount() {
      const { resetProlongationRequire } = this.props;

      resetProlongationRequire();
      this.timeoutIds.forEach(id => clearInterval(id));
    }

    render() {
      return <Content {...this.props} />;
    }
  }

  Page.propTypes = {
    session: PropTypes.object.isRequired,
    updateSession: PropTypes.func.isRequired,
    setProlongationRequire: PropTypes.func.isRequired,
    resetProlongationRequire: PropTypes.func.isRequired,
  };

  const mapStateToProps = ({ session }) => ({
    session,
  });

  const mapDispatchToProps = {
    updateSession,
    setProlongationRequire,
    resetProlongationRequire,
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Page);
};

export default withNotification;
