import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'react-text-mask';
import cn from 'classnames';

import { Translation, InputError } from '../../';

import styles from './MaskedInput.module.scss';

class MaskedInput extends Component {
  componentDidUpdate(prevProps) {
    const { meta: currMeta, disabled: currDisabled } = this.props;
    const { disabled: prevDisabled } = prevProps;

    // If we disable focused input and undisable it, meta active
    // is true, but focus is not on element. We need to focus it.
    if (prevDisabled && !currDisabled && currMeta.active && this.input) {
      this.input.focus();
    }
  }

  render() {
    const { disabled, labelId, meta, mask, placeholderChar, placeholder, input } = this.props;
    const { active, touched, error } = meta;

    const isErrorVisible = touched && error && !disabled;
    const isInputFocused = active && !disabled;

    const err = touched ? error : null;

    const externalBorderClassName = cn(styles.ExternalBorder, {
      [styles.DisabledExternalBorder]: disabled,
      [styles.FocusedExternalBorder]: isInputFocused,
      [styles.ErrorExternalBorder]: isErrorVisible,
    });
    const internalBorderClassName = cn(styles.InternalBorder, {
      [styles.FocusedInternalBorder]: isInputFocused,
      [styles.ErrorInternalBorder]: isErrorVisible,
    });
    const labelClassName = cn(styles.Label, {
      [styles.FocusedLabel]: isInputFocused || input.value,
      [styles.DisabledLabel]: disabled,
    });

    const isPlaceholderVisible = !input.value && isInputFocused;
    const newPlaceholder = isPlaceholderVisible ? placeholder : '';

    return (
      <div className={styles.MaskedInput}>
        <Translation.Div className={labelClassName} translateId={labelId} />
        <div className={externalBorderClassName}>
          <div className={internalBorderClassName}>
            {mask ? (
              <Input
                autoComplete="off"
                className={styles.Input}
                disabled={disabled}
                guide={false}
                mask={mask}
                placeholder={newPlaceholder}
                placeholderChar={placeholderChar}
                {...input}
              />
            ) : null}
          </div>
        </div>
        <InputError errorId={err} />
      </div>
    );
  }
}

MaskedInput.propTypes = {
  disabled: PropTypes.bool,
  labelId: PropTypes.string,
  placeholderChar: PropTypes.string,
  placeholder: PropTypes.string,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  mask: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
};

export default MaskedInput;
