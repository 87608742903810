import { ReduxCheckbox } from 'shared';
import { labelMaker } from 'shared/utils';

const formLabel = labelMaker('atms.search');

export const options = {
  unicreditBranches: {
    component: ReduxCheckbox,
    labelId: formLabel('unicreditBranches'),
    name: 'unicreditBranches',
    defaultValue: false,
  },
  unicreditAtms: {
    component: ReduxCheckbox,
    labelId: formLabel('unicreditAtms'),
    name: 'unicreditAtms',
    defaultValue: false,
  },
  partnersAtms: {
    component: ReduxCheckbox,
    labelId: formLabel('partnersAtms'),
    name: 'partnersAtms',
    defaultValue: false,
  },
  unicreditPrimeClub: {
    component: ReduxCheckbox,
    labelId: formLabel('unicreditPrimeClub'),
    name: 'unicreditPrimeClub',
    defaultValue: false,
  },
  depository: {
    component: ReduxCheckbox,
    labelId: formLabel('depository'),
    name: 'depository',
    defaultValue: false,
  },
  openAlways: {
    component: ReduxCheckbox,
    labelId: formLabel('openAlways'),
    name: 'openAlways',
    defaultValue: false,
  },
  rubleWithdraw: {
    component: ReduxCheckbox,
    labelId: formLabel('RUR'),
    name: 'rubleWithdraw',
    defaultValue: false,
  },
  dollarWithdraw: {
    component: ReduxCheckbox,
    labelId: formLabel('USD'),
    name: 'dollarWithdraw',
    defaultValue: false,
  },
  euroWithdraw: {
    component: ReduxCheckbox,
    labelId: formLabel('EUR'),
    name: 'euroWithdraw',
    defaultValue: false,
  },
};

export const initialValues = Object.keys(options).reduce((values, key) => {
  values[key] = options[key].defaultValue;
  return values;
}, {});

export default options;
