import { timeout } from 'shared/utils';

export const responses = {
  fetchLoanResponse: {
    id: '2c77b4a3-1aca-478d-8e68-2a0b2298eaf6',
    name: 'Автокредит',
    contractRef: '01000064RURRA10000',
    repayAmount: '12099.4',
    repayInterest: '10762.49',
    remainingPayment: '663637.77',
    valueDate: '2017-08-15T00:00:00',
    nextPaymentDate: '2019-09-17T00:00:00',
    maturityDate: '2025-08-01T00:00:00',
    dealDate: '2018-08-02T00:00:00',
    principal: '662000',
    ccy: 'RUR',
    drsettlementacc: '01000064RURPROC101',
    productCode: 'RC10',
    productCategory: 'CAR_LOANS',
    payments: [
      {
        originalPrincipal: '396000',
        interestRate: '19.9',
        lastPaymentDate: '2018-07-16T00:00:00',
        nextPaymentDate: '2019-08-15T00:00:00',
        interestRateType: 'X',
        currentInterestRate: '19.9',
        interestRateVariableComponent: '19.9',
        currentOutstanding: '354018.65',
      },
    ],
    pastdue: [
      {
        pastdueAmount: '12000',
        penaltyPrincipal: '0',
        penaltyInt: '0',
        penaltyDueInterest: '0',
        numOfDaysPastDueAmount: '11',
        totalInterestAmount: '4572.48',
        outstandingPrincipal: '349446.17',
        pastduePrincipal: '0',
        pastdueInterest: '0',
        interestOnPastduePrincipal: '0',
      },
    ],
    annuity: 8722.0,
    totalRemainSum: 325402.93,
    totalRemainInterestSum: 320241.07,
    lastPaymentSum: 10470.0,
    schedulesCount: 62,
    allowPrepayDate: '2018-05-06T00:00:00',
    createDate: '2019-08-06T07:33:10',
    updateDate: '2019-08-06T07:59:21',
    visibilityRule: 3,
    fullRepayPercent: 95,
    buyBack: true,
  },
  fetchLoansResponse: [
    {
      id: '2c77b4a3-1aca-478d-8e68-2a0b2298eaf6',
      name: 'Автокредит',
      contractRef: '01000064RURRA10000',
      repayAmount: '12099.4',
      repayInterest: '10762.49',
      remainingPayment: '663637.77',
      nextPaymentDate: '2019-09-17T00:00:00',
      maturityDate: '2025-08-01T00:00:00',
      dealDate: '2018-08-02T00:00:00',
      principal: '662000',
      ccy: 'RUR',
      productCategory: 'CAR_LOANS',
      createDate: '2019-08-06T07:33:10',
      updateDate: '2019-08-06T07:33:10',
      visibilityRule: 3,
      pastdue: [
        {
          pastdueAmount: '12000',
          penaltyPrincipal: '0',
          penaltyInt: '0',
          penaltyDueInterest: '0',
          numOfDaysPastDueAmount: '11',
          totalInterestAmount: '4572.48',
          outstandingPrincipal: '349446.17',
          pastduePrincipal: '0',
          pastdueInterest: '0',
          interestOnPastduePrincipal: '0',
        },
      ],
    },
    {
      id: 'bd46ef58-587b-422d-9599-508b1e5fd8ad',
      name: 'Автокредит',
      contractRef: '01000064RURRA10001',
      repayAmount: '1245.51',
      repayInterest: '10762.49',
      remainingPayment: '663637.77',
      nextPaymentDate: '2019-09-17T00:00:00',
      maturityDate: '2025-08-01T00:00:00',
      dealDate: '2018-08-02T00:00:00',
      principal: '662000',
      ccy: 'RUR',
      productCategory: 'CONSUMER_LOANS',
      createDate: '2019-08-06T07:33:10',
      updateDate: '2019-08-06T07:33:10',
      visibilityRule: 3,
    },
    {
      id: '95a4be48-1b44-4063-b86e-0fdf329ba193',
      name: 'Автокредит',
      contractRef: '01000064RURRA10002',
      repayAmount: '1245.51',
      repayInterest: '10762.49',
      remainingPayment: '663637.77',
      nextPaymentDate: '2019-09-17T00:00:00',
      maturityDate: '2025-08-01T00:00:00',
      dealDate: '2018-08-02T00:00:00',
      principal: '662000',
      ccy: 'RUR',
      productCategory: 'CONSUMER_LOANS',
      createDate: '2019-08-06T07:33:10',
      updateDate: '2019-08-06T07:33:10',
      visibilityRule: 3,
    },
    {
      id: '80b12a97-5dff-4810-ac24-5af9dafc9e26',
      name: 'Автокредит',
      contractRef: '01000064RURRA10003',
      repayAmount: '1245.51',
      repayInterest: '10762.49',
      remainingPayment: '663637.77',
      nextPaymentDate: '2019-09-17T00:00:00',
      maturityDate: '2025-08-01T00:00:00',
      dealDate: '2018-08-02T00:00:00',
      principal: '662000',
      ccy: 'RUR',
      productCategory: 'CONSUMER_LOANS',
      createDate: '2019-08-06T07:33:10',
      updateDate: '2019-08-06T07:33:10',
      visibilityRule: 3,
    },
  ],
  fetchPrepayApplications: {
    content: [
      {
        id: '09fc4298-f7ba-4cb6-a921-b0ea36ebd5c0',
        loanAccount: '01000064RURRC10004',
        customerName: 'ШАРАФУТДИНОВ АЙРАТ АГЗАМОВИЧ',
        operationType: 'FullRepayment',
        loanContractDetails: {
          loanNumber: '01000064RURRC10004',
          monthlyPaymentDay: '15',
          new: {
            newAnnuity: '10466',
            newFullLoanAmount: '21.81',
            lastPaymentCalc: '11084.22',
            newInterestRate: '19.9',
            newInterestRateDate: '2018-03-16',
          },
          issueDate: '2017-08-15T00:00:00',
          current: {
            principalExpected: 367126.54,
            interestExpected: 0,
            lastPaymentControl: 11,
            interestRate: 19.9,
            total: 373531.62,
            currentRemain: 367126.54,
            currentAnnuity: 10470,
            lastPaymentSum: 10470,
            expectedPaymentsCount: 53,
            nextPaymentDate: '2018-04-16T00:00:00',
            lastPaymentDate: '2022-08-15T00:00:00',
          },
        },
        repayment: { account: '01000064RURRC10004', date: '2018-03-16', amount: 1 },
        signtype: '2',
        signdate: '2019-04-26',
        status: 'RJT',
        statusDesc: 'OK',
        applicationDate: '2018-03-16',
        branch: '40817810850660014196',
        accountNumber: '01000064RURPRCA101',
        currency: 'RUR',
      },
      {
        id: 'eb101b5a-73d8-4cc0-a4b1-1cfad716076a',
        loanAccount: '01000064RURRC10004',
        customerName: 'ШАРАФУТДИНОВ АЙРАТ АГЗАМОВИЧ',
        operationType: 'PartRepayment',
        loanContractDetails: {
          loanNumber: '01000064RURRC10004',
          monthlyPaymentDay: '15',
          new: {
            newAnnuity: '10430',
            newFullLoanAmount: '21.81',
            lastPaymentCalc: '11119',
            newInterestRate: '19.9',
            newInterestRateDate: '2018-03-16',
          },
          issueDate: '2017-08-15T00:00:00',
          current: {
            principalExpected: 367126.54,
            interestExpected: 0,
            lastPaymentControl: 11,
            interestRate: 19.9,
            total: 373510.78,
            currentRemain: 367126.54,
            currentAnnuity: 10470,
            lastPaymentSum: 10470,
            expectedPaymentsCount: 53,
            nextPaymentDate: '2018-04-16T00:00:00',
            lastPaymentDate: '2022-08-15T00:00:00',
          },
        },
        repayment: { account: '01000064RURRC10004', date: '2018-03-16', amount: 1234 },
        signtype: '2',
        signdate: '2019-04-24',
        status: 'RJT',
        statusDesc:
          'OK. Данная заявка была автоматически отменена, так как с сайта/портала была получена более новая заявка на досрочное погашение',
        applicationDate: '2018-03-16',
        branch: '40817810850660014196',
        accountNumber: '01000064RURPRCA101',
        currency: 'RUR',
      },
      {
        id: '3bf008cd-b37b-4cfa-ad9a-96e38b203d03',
        loanAccount: '01000064RURRC10004',
        customerName: 'ШАРАФУТДИНОВ АЙРАТ АГЗАМОВИЧ',
        operationType: 'PartRepayment',
        loanContractDetails: {
          loanNumber: '01000064RURRC10004',
          monthlyPaymentDay: '15',
          new: {
            newAnnuity: '10465',
            newFullLoanAmount: '21.81',
            lastPaymentCalc: '11155.17',
            newInterestRate: '19.9',
            newInterestRateDate: '2018-03-16',
          },
          issueDate: '2017-08-15T00:00:00',
          current: {
            principalExpected: 367126.54,
            interestExpected: 0,
            lastPaymentControl: 11,
            interestRate: 19.9,
            total: 373531.54,
            currentRemain: 367126.54,
            currentAnnuity: 10470,
            lastPaymentSum: 10470,
            expectedPaymentsCount: 53,
            nextPaymentDate: '2018-04-16T00:00:00',
            lastPaymentDate: '2022-08-15T00:00:00',
          },
        },
        repayment: { account: '01000064RURRC10004', date: '2018-03-16', amount: 6 },
        signtype: '2',
        signdate: '2019-04-26',
        status: 'CHK',
        applicationDate: '2018-03-16',
        branch: '40817810850660014196',
        accountNumber: '01000064RURPRCA101',
        currency: 'RUR',
      },
      {
        id: '67a83565-061a-47f1-a157-d6f4b0792d04',
        loanAccount: '01000064RURRC10004',
        customerName: 'ШАРАФУТДИНОВ АЙРАТ АГЗАМОВИЧ',
        operationType: 'PartRepayment',
        loanContractDetails: {
          loanNumber: '01000064RURRC10004',
          monthlyPaymentDay: '15',
          new: {
            newAnnuity: '10465',
            newFullLoanAmount: '21.81',
            lastPaymentCalc: '11159.87',
            newInterestRate: '19.9',
            newInterestRateDate: '2018-03-16',
          },
          issueDate: '2017-08-15T00:00:00',
          current: {
            principalExpected: 367126.54,
            interestExpected: 0,
            lastPaymentControl: 11,
            interestRate: 19.9,
            total: 373531.57,
            currentRemain: 367126.54,
            currentAnnuity: 10470,
            lastPaymentSum: 10470,
            expectedPaymentsCount: 53,
            nextPaymentDate: '2018-04-16T00:00:00',
            lastPaymentDate: '2022-08-15T00:00:00',
          },
        },
        repayment: { account: '01000064RURRC10004', date: '2018-03-16', amount: 4 },
        signtype: '2',
        signdate: '2019-04-26',
        status: 'ACK',
        statusDesc: 'OK',
        applicationDate: '2018-03-16',
        branch: '40817810850660014196',
        accountNumber: '01000064RURPRCA101',
        currency: 'RUR',
      },
      {
        id: '6e373a6e-1471-4505-91ac-d5eecce69f4b',
        loanAccount: '01000064RURRC10004',
        customerName: 'ШАРАФУТДИНОВ АЙРАТ АГЗАМОВИЧ',
        operationType: 'PartRepayment',
        loanContractDetails: {
          loanNumber: '01000064RURRC10004',
          monthlyPaymentDay: '15',
          new: {
            newAnnuity: '10465',
            newFullLoanAmount: '21.81',
            lastPaymentCalc: '11162.28',
            newInterestRate: '19.9',
            newInterestRateDate: '2018-03-16',
          },
          issueDate: '2017-08-15T00:00:00',
          current: {
            principalExpected: 367126.54,
            interestExpected: 0,
            lastPaymentControl: 11,
            interestRate: 19.9,
            total: 373531.59,
            currentRemain: 367126.54,
            currentAnnuity: 10470,
            lastPaymentSum: 10470,
            expectedPaymentsCount: 53,
            nextPaymentDate: '2018-04-16T00:00:00',
            lastPaymentDate: '2022-08-15T00:00:00',
          },
        },
        repayment: { account: '01000064RURRC10004', date: '2018-03-16', amount: 3 },
        signtype: '2',
        signdate: '2019-04-26',
        status: 'CHK',
        applicationDate: '2018-03-16',
        branch: '40817810850660014196',
        accountNumber: '01000064RURPRCA101',
        currency: 'RUR',
      },
      {
        id: '62dd9dca-d9e4-4acb-8064-77af178cc8e9',
        loanAccount: '01000064RURRC10004',
        customerName: 'ШАРАФУТДИНОВ АЙРАТ АГЗАМОВИЧ',
        operationType: 'PartRepayment',
        loanContractDetails: {
          loanNumber: '01000064RURRC10004',
          monthlyPaymentDay: '15',
          new: {
            newAnnuity: '10465',
            newFullLoanAmount: '21.81',
            lastPaymentCalc: '11164.73',
            newInterestRate: '19.9',
            newInterestRateDate: '2018-03-16',
          },
          issueDate: '2017-08-15T00:00:00',
          current: {
            principalExpected: 367126.54,
            interestExpected: 0,
            lastPaymentControl: 11,
            interestRate: 19.9,
            total: 373531.61,
            currentRemain: 367126.54,
            currentAnnuity: 10470,
            lastPaymentSum: 10470,
            expectedPaymentsCount: 53,
            nextPaymentDate: '2018-04-16T00:00:00',
            lastPaymentDate: '2022-08-15T00:00:00',
          },
        },
        repayment: { account: '01000064RURRC10004', date: '2018-03-16', amount: 2 },
        signtype: '2',
        signdate: '2019-04-26',
        status: 'CHK',
        applicationDate: '2018-03-16',
        branch: '40817810850660014196',
        accountNumber: '01000064RURPRCA101',
        currency: 'RUR',
      },
      {
        id: '22ab4511-f493-448a-be2a-a5ff9cc06d62',
        loanAccount: '01000064RURRC10004',
        customerName: 'ШАРАФУТДИНОВ АЙРАТ АГЗАМОВИЧ',
        operationType: 'PartRepayment',
        loanContractDetails: {
          loanNumber: '01000064RURRC10004',
          monthlyPaymentDay: '15',
          new: {
            newAnnuity: '10304',
            newFullLoanAmount: '21.81',
            lastPaymentCalc: '11123.59',
            newInterestRate: '19.9',
            newInterestRateDate: '2018-04-03',
          },
          issueDate: '2017-08-15T00:00:00',
          current: {
            principalExpected: 367126.54,
            interestExpected: 0,
            lastPaymentControl: 11,
            interestRate: 19.9,
            total: 373491.57,
            currentRemain: 367126.54,
            currentAnnuity: 10470,
            lastPaymentSum: 10470,
            expectedPaymentsCount: 53,
            nextPaymentDate: '2018-04-16T00:00:00',
            lastPaymentDate: '2022-08-15T00:00:00',
          },
        },
        repayment: { account: '01000064RURRC10004', date: '2018-04-03', amount: 5653 },
        signtype: '2',
        signdate: '2019-04-11',
        status: 'ACK',
        statusDesc: 'OK',
        applicationDate: '2018-04-03',
        branch: '40817810850660014196',
        accountNumber: '01000064RURPRCA101',
        currency: 'RUR',
      },
      {
        id: 'bee0a789-9a20-4089-b233-9a29a2775088',
        loanAccount: '01000064RURRC10004',
        customerName: 'ШАРАФУТДИНОВ АЙРАТ АГЗАМОВИЧ',
        operationType: 'PartRepayment',
        loanContractDetails: {
          loanNumber: '01000064RURRC10004',
          monthlyPaymentDay: '15',
          new: {
            newAnnuity: '9429',
            newFullLoanAmount: '21.81',
            lastPaymentCalc: '10479.25',
            newInterestRate: '19.9',
            newInterestRateDate: '2018-05-11',
          },
          issueDate: '2017-08-15T00:00:00',
          current: {
            principalExpected: 367126.54,
            interestExpected: 0,
            lastPaymentControl: 11,
            interestRate: 19.9,
            total: 368722.94,
            currentRemain: 367126.54,
            currentAnnuity: 10470,
            lastPaymentSum: 10470,
            expectedPaymentsCount: 53,
            nextPaymentDate: '2018-04-16T00:00:00',
            lastPaymentDate: '2022-08-15T00:00:00',
          },
        },
        repayment: { account: '01000064RURRC10004', date: '2018-05-11', amount: 36247 },
        signtype: '2',
        signdate: '2019-04-15',
        status: 'RJT',
        statusDesc:
          'OK. Данная заявка была автоматически отменена, так как с сайта/портала была получена более новая заявка на досрочное погашение',
        applicationDate: '2018-05-11',
        accountNumber: '01000064RURPRCA101',
        currency: 'RUR',
      },
      {
        id: '36563a1c-1e59-421a-8af8-fe2a4851faa5',
        loanAccount: '01000064RURRC10004',
        customerName: 'ШАРАФУТДИНОВ АЙРАТ АГЗАМОВИЧ',
        operationType: 'PartRepayment',
        loanContractDetails: {
          loanNumber: '01000064RURRC10004',
          monthlyPaymentDay: '15',
          new: {
            newAnnuity: '9485',
            newFullLoanAmount: '21.81',
            lastPaymentCalc: '10031.01',
            newInterestRate: '19.9',
            newInterestRateDate: '2018-05-15',
          },
          issueDate: '2017-08-15T00:00:00',
          current: {
            principalExpected: 367126.54,
            interestExpected: 0,
            lastPaymentControl: 11,
            interestRate: 19.9,
            total: 368801.99,
            currentRemain: 367126.54,
            currentAnnuity: 10470,
            lastPaymentSum: 10470,
            expectedPaymentsCount: 53,
            nextPaymentDate: '2018-04-16T00:00:00',
            lastPaymentDate: '2022-08-15T00:00:00',
          },
        },
        repayment: { account: '01000064RURRC10004', date: '2018-05-15', amount: 33587 },
        signtype: '2',
        signdate: '2019-04-19',
        status: 'RJT',
        statusDesc:
          'OK. Данная заявка была автоматически отменена, так как с сайта/портала была получена более новая заявка на досрочное погашение',
        applicationDate: '2018-05-15',
        branch: '40817810850660014196',
        accountNumber: '01000064RURPRCA101',
        currency: 'RUR',
      },
    ],
    totalElements: 9,
    totalPages: 1,
    pageNumber: 0,
    pageSize: 10,
  },
  calculateCredit: {
    loanAccount: '0100064RURRC1004',
    checkMessage: 'OK',
    customerName: 'АЙРАТ',
    principal: {
      principalExpected: '367126.54',
      principalOverdue: '0',
    },
    interest: { interestExpected: '6405.1', interestOverdue: '0' },
    penalty: {},
    interestOnOverduePrincipalAccrued: '0',
    expectedPaymentsCount: '52',
    total: '373531.64',
  },
};

export const fetchLoanReqStub = async withError => {
  const fetchLoanResponse = responses.fetchLoanResponse;

  const fetchLoanError = {
    code: 500,
    message: 'Какая-то ошибка',
  };

  await timeout(1000);
  if (!withError) {
    console.info('fetchLoanReqStub: ', fetchLoanResponse);
    return Promise.resolve(fetchLoanResponse);
  } else {
    console.info('fetchLoanReqStub: ', fetchLoanError);
    return Promise.reject(fetchLoanError);
  }
};

export const fetchLoansReqStub = async withError => {
  const fetchLoansResponse = responses.fetchLoansResponse;

  const fetchLoansError = {
    code: 500,
    message: 'Какая-то ошибка',
  };

  await timeout(1000);
  if (!withError) {
    console.info('fetchLoansReqStub: ', fetchLoansResponse);
    return Promise.resolve(fetchLoansResponse);
  } else {
    console.info('fetchLoansReqStub: ', fetchLoansError);
    return Promise.reject(fetchLoansError);
  }
};

export const fetchNextPaymentReqStub = async withError => {
  const fetchNextPaymentResponse = { nextPayment: 15851.77, numberOfNextPayments: 61, savesum: 0 };

  const fetchNextPaymentError = {
    code: 500,
    message: 'Какая-то ошибка',
  };

  await timeout(1000);
  if (!withError) {
    console.info('fetchNextPaymentReqStub: ', fetchNextPaymentResponse);
    return Promise.resolve(fetchNextPaymentResponse);
  } else {
    console.info('fetchNextPaymentReqStub: ', fetchNextPaymentError);
    return Promise.reject(fetchNextPaymentError);
  }
};

export const sendFileReqStub = async withError => {
  const sendFileResponse = { result: 'success' };

  const sendFileError = {
    code: 500,
    message: 'Какая-то ошибка',
  };

  await timeout(1000);
  if (!withError) {
    console.info('sendFileReqStub: ', sendFileResponse);
    return Promise.resolve(sendFileResponse);
  } else {
    console.info('sendFileReqStub: ', sendFileError);
    return Promise.reject(sendFileError);
  }
};

export const fetchPrepayApplicationsReqStub = async withError => {
  const fetchPrepayApplicationsResponse = responses.fetchPrepayApplications;

  const fetchPrepayApplicationsError = {
    code: 500,
    message: 'Какая-то ошибка',
  };

  await timeout(1000);
  if (!withError) {
    console.info('fetchPrepayApplicationsReqStub: ', fetchPrepayApplicationsResponse);
    return Promise.resolve(fetchPrepayApplicationsResponse);
  } else {
    console.info('fetchPrepayApplicationsReqStub: ', fetchPrepayApplicationsError);
    return Promise.reject(fetchPrepayApplicationsError);
  }
};

export const calculateCreditReqStub = async withError => {
  const calculateCreditResponse = responses.calculateCredit;

  const calculateCreditError = {
    code: 500,
    message: 'Какая-то ошибка',
  };

  await timeout(1000);
  if (!withError) {
    console.info('calculateCreditReqStub: ', calculateCreditResponse);
    return Promise.resolve(calculateCreditResponse);
  } else {
    console.info('calculateCreditReqStub: ', calculateCreditError);
    return Promise.reject(calculateCreditError);
  }
};
