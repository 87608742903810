import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import TransitionTemplate from '../../../TransitionTemplate/TransitionTemplate';

import { Button, Translation } from 'shared';
import { labelMaker, formatNumber, get } from 'shared/utils';

import { ReactComponent as SuccessIcon } from 'assets/icons/icon-success-blue.svg';
import styles from './Step5.module.scss';

const label = labelMaker('transfers');

const getLocaleDateFormat = locale =>
  ({
    en: 'MMMM DD YYYY',
    ru: 'DD MMMM YYYY',
  }[locale]);

class Step1 extends PureComponent {
  render() {
    const { form, locale } = this.props;

    const amount = get(form, 'transfers.values.payment');
    const title = get(form, 'transfers.values.purpose');

    const transition = {
      date: moment().format(getLocaleDateFormat(locale)),
      amount: `-${formatNumber(amount, { currency: 'RUR' })}`,
      title,
      status: 'done',
    };

    return (
      <Fragment>
        <div className={styles.TitleRow}>
          <Translation.Div
            className={styles.Title}
            translateId={label('titles.transferApproved')}
          />
          <SuccessIcon />
        </div>

        <div className={styles.TransitionContainer}>
          <TransitionTemplate transition={transition} isDebit />
        </div>

        <div className={styles.ButtonContainer}>
          <Button translateId={label('btns.complete')} />
        </div>
      </Fragment>
    );
  }
}

Step1.propTypes = {
  form: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
};

export default Step1;
