import React, { Component, Fragment } from 'react';
import { PropTypes } from 'prop-types';

import { Button, DateRange } from '../';

class DateRangeCalendar extends Component {
  state = {
    range: {
      startDate: this.props.range.startDate,
      endDate: this.props.range.endDate,
      key: 'selection',
    },
  };

  onChange = ({ range }) => {
    this.setState({ range: range });
  };

  setRange = () => {
    const { onChangeRange } = this.props;
    const { range } = this.state;

    onChangeRange({ range });
  };

  render() {
    const { range } = this.state;

    return (
      <Fragment>
        <DateRange showDateDisplay={false} onChange={this.onChange} range={range} />
        <Button
          theme="white"
          round
          style={{ height: 40, marginTop: 25 }}
          translateId="general.buttons.selectBtn"
          onClick={this.setRange}
        />
      </Fragment>
    );
  }
}

DateRangeCalendar.propTypes = {
  setRange: PropTypes.func.isRequired,
  onChangeRange: PropTypes.func,
  value: PropTypes.object,
  range: PropTypes.object,
  name: PropTypes.string,
};

DateRangeCalendar.defaultProps = {
  setRange: () => {},
};

export default DateRangeCalendar;
