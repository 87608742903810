import { createAction } from 'redux-act';
import axios from 'axios';
import moment from 'moment';
import { change } from 'redux-form';
import {
  setActiveLanguage as setActiveLanguageNative,
  getTranslate,
  getActiveLanguage,
} from 'react-localize-redux';
import { hideNotification, showWarnNotification, showKYCNotification } from '../notification';
import { showOverlay, hideOverlay } from '../overlay';
import {
  get,
  getErrorMessage,
  buildUrlParams,
  encodeDataURI,
  labelMaker,
  getWebapiLanguage,
  getLocaleDateFormat,
} from 'shared/utils';

import apiServicesConstructor from 'services/apiService';

import {
  fetchKYCStatusReq,
  changeEmailReq,
  changePhoneReq,
  verifyEmailReq,
  verifyPhoneReq,
} from './profileRequests';

import { initializePersonal } from '../personal';

export const changeLanguageError = createAction('CHANGE_LANGUAGE_ERROR');
export const changeLanguageStart = createAction('CHANGE_LANGUAGE_START');
export const changeLanguageSuccess = createAction('CHANGE_LANGUAGE_SUCCESS');
export const fetchKYCStatusError = createAction('FETCH_KYC_STATUS_ERROR');
export const fetchKYCStatusStart = createAction('FETCH_KYC_STATUS_START');
export const fetchKYCStatusSuccess = createAction('FETCH_KYC_STATUS_SUCCESS');
export const persistProfileLanguage = createAction('PERSIST_PROFILE_LANGUAGE');
export const updateKYCDataError = createAction('KYC_DATA_UPDATE_ERROR');
export const updateKYCDataStart = createAction('KYC_DATA_UPDATE_START');
export const updateKYCDataSuccess = createAction('KYC_DATA_UPDATE_SUCCESS');
export const changeEmailCancel = createAction('CHANGE_EMAIL_CANCEL');
export const changeEmailCheckCodeError = createAction('CHANGE_EMAIL_CHECK_CODE_ERROR');
export const changeEmailCheckCodeStart = createAction('CHANGE_EMAIL_CHECK_CODE_START');
export const changeEmailCheckStart = createAction('CHANGE_EMAIL_CHECK_START');
export const changeEmailCheckSuccess = createAction('CHANGE_EMAIL_CHECK_SUCCESS');
export const changeEmailError = createAction('CHANGE_EMAIL_ERROR');
export const changeEmailSuccess = createAction('CHANGE_EMAIL_SUCCESS');
export const changePhoneCancel = createAction('CHANGE_PHONE_CANCEL');
export const changePhoneCheckCodeError = createAction('CHANGE_PHONE_CHECK_CODE_ERROR');
export const changePhoneCheckCodeStart = createAction('CHANGE_PHONE_CHECK_CODE_START');
export const changePhoneCheckStart = createAction('CHANGE_PHONE_CHECK_START');
export const changePhoneCheckSuccess = createAction('CHANGE_PHONE_CHECK_SUCCESS');
export const changePhoneError = createAction('CHANGE_PHONE_ERROR');
export const changePhoneSuccess = createAction('CHANGE_PHONE_SUCCESS');
export const toggleChangeEmailBlock = createAction('TOGGLE_CHANGE_EMAIL_BLOCK');
export const toggleChangePhoneBlock = createAction('TOGGLE_CHANGE_PHONE_BLOCK');

const label = labelMaker('kyc');
const labelPersonal = labelMaker('personal');
const lError = labelMaker('errorsGeneral');

export const setActiveLanguage = code => {
  return dispatch => {
    dispatch(setActiveLanguageNative(code));
    moment.locale(code);
    localStorage.setItem('profile/language', code);
    dispatch(persistProfileLanguage(code));
  };
};

export const updateKYCData = () => {
  return (dispatch, getState) => {
    dispatch(showOverlay());
    dispatch(updateKYCDataStart());

    const data = buildUrlParams({
      T: 'RT_json_1Common.kycupdatesave',
      console: 'json',
    });

    return axios({
      url: `/v2/cgi/bsi.dll`,
      method: 'POST',
      headers: { Accept: 'application/json' },
      data,
    })
      .then(res => res.data)
      .then(res => {
        if (res.error) {
          return dispatch(showWarnNotification(res.error.message));
        }
        dispatch(hideOverlay());
        const data = get(res, 'data.userdata');
        if (data) {
          dispatch(updateKYCDataSuccess(data));
        }
      })
      .catch(error => {
        dispatch(hideOverlay());
        dispatch(updateKYCDataError());
        throw error;
      });
  };
};

export const fetchKYCStatus = () => async (dispatch, getState) => {
  try {
    dispatch(fetchKYCStatusStart());

    const response = await dispatch(fetchKYCStatusReq());
    const { localize, auth } = getState();
    const locale = getActiveLanguage(localize).code;
    const dateFormat = getLocaleDateFormat(locale);

    const translate = getTranslate(localize);

    const status = get(response, 'status', '').toLowerCase();

    const firstName = auth.user.firstName[locale];
    const middleName = auth.user.middleName[locale];

    let message, link;

    if (status === 'ok' || status === 'old') {
      return;
    }

    if (status === 'renew') {
      const renewDate = moment(response.date).add(30, 'days').format(dateFormat);

      message = translate(label('status.renew'), { firstName, middleName, date: renewDate });
      // link = {
      //   translateId: label('confirmLink'),
      //   route: 'profile/kyc',
      // };
    }

    if (status === 'okmon') {
      const okmonDate = moment(response.date).add(7, 'days').format(dateFormat);

      message = translate(label('status.okmon'), { firstName, middleName, date: okmonDate });
      // link = {
      //   translateId: label('confirmLink'),
      //   route: 'profile/kyc',
      // };
    }

    if (status === 'restr') {
      message = translate(label('status.restr'), { firstName, middleName });
      // link = {
      //   translateId: label('confirmLink'),
      //   route: 'profile/kyc',
      // };
    }

    if (status === 'arc') {
      message = translate(label('status.arc'), { firstName, middleName });
    }

    if (message) {
      const data = {
        message: message,
        // link: link,
      };

      dispatch(showKYCNotification(data));
    }
  } catch (err) {
    console.error(err);
  }
};

export const changeLanguage = code => (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  dispatch(showOverlay());
  dispatch(changeLanguageStart());

  const apiServices = apiServicesConstructor(dispatch, getState);

  const data = { language: getWebapiLanguage(code) };

  const params = {
    url: '/webapi-1.0/users/lang',
    data: buildUrlParams(encodeDataURI(data)),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  };

  return apiServices
    .put(params)
    .then(() => {
      dispatch(changeLanguageSuccess());
      dispatch(hideNotification());
      dispatch(hideOverlay());

      dispatch(setActiveLanguage(code));
    })
    .catch(err => {
      const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
      dispatch(changeLanguageError());
      dispatch(showWarnNotification(errMessage));
      dispatch(hideOverlay());
    });
};

export const changeEmail = email => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(changeEmailCheckStart());

    const response = await dispatch(changeEmailReq(email));

    if (response.success === true) {
      return dispatch(changeEmailCheckSuccess());
    }

    if (response.success === false) {
      throw translate(label('errors.serviceUnavailable'));
    }

    throw translate(lError('serviceUnavailable'));
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(changeEmailError());
    dispatch(hideOverlay());
  }
};

export const verifyEmail = code => async (dispatch, getState) => {
  const { localize, form } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(changeEmailCheckCodeStart());

    const response = await dispatch(verifyEmailReq(code));

    if (response.success === true) {
      const newEmail = get(form, 'changePersonal.values.newEmail');
      dispatch(changeEmailSuccess());
      dispatch(emailCancel());
      dispatch(change('changePersonal', 'email', newEmail));
      dispatch(initializePersonal());
      return;
    }

    if (response.success === false) {
      if (Number(response.seconds) === 0) {
        throw translate(labelPersonal('codeIsExpired'));
      } else {
        dispatch(showWarnNotification(translate(labelPersonal('codeIsWrong'))));
        dispatch(changeEmailCheckCodeError());
        dispatch(change('changePersonal', 'emailCode', ''));
        return;
      }
    }

    throw translate(lError('serviceUnavailable'));
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(changeEmailError());
    dispatch(hideOverlay());
  }
};

export const emailCancel = () => dispatch => {
  dispatch(changeEmailCancel());
  dispatch(change('changePersonal', 'newEmail', ''));
  dispatch(change('changePersonal', 'emailCode', ''));
};

export const changePhone = phone => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(changePhoneCheckStart());
    const response = await dispatch(changePhoneReq(phone));

    if (response.success === true) {
      return dispatch(changePhoneCheckSuccess());
    }

    if (response.success === false) {
      throw translate(labelPersonal('phoneCodeAlreadySent'));
    }

    throw translate(lError('serviceUnavailable'));
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(changePhoneError());
    dispatch(hideOverlay());
  }
};

export const verifyPhone = code => async (dispatch, getState) => {
  const { localize, form } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(changePhoneCheckCodeStart());

    const response = await dispatch(verifyPhoneReq(code));

    if (response.success === true) {
      const newPhone = get(form, 'changePersonal.values.newPhone');
      dispatch(changePhoneSuccess());
      dispatch(phoneCancel());
      dispatch(change('changePersonal', 'phone', newPhone));
      dispatch(initializePersonal());
      return;
    }

    if (response.success === false) {
      if (Number(response.seconds) === 0) {
        throw translate(labelPersonal('codeIsExpired'));
      }

      dispatch(showWarnNotification(translate(labelPersonal('codeIsWrong'))));
      dispatch(changePhoneCheckCodeError());
      dispatch(change('changePersonal', 'phoneCode', ''));
      return;
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(changePhoneError());
    dispatch(hideOverlay());
  }
};

export const phoneCancel = () => dispatch => {
  dispatch(change('changePersonal', 'newPhone', ''));
  dispatch(change('changePersonal', 'phoneCode', ''));
};
