import { ReduxSelect, ReduxPhoneInput1 } from 'shared';

import { reduxFieldRequired, reduxFieldPhone1 } from 'shared/validators';
import { phoneNormalizer, phoneFormatter } from 'shared/normalizers';
import { labelMaker } from 'shared/utils';

const formLabel = labelMaker('profile.labels');

export const options = {
  receiverPhone: {
    component: ReduxPhoneInput1,
    labelId: formLabel('receiverPhone'),
    name: 'receiverPhone',
    normalize: phoneNormalizer,
    format: phoneFormatter,
    validate: [reduxFieldRequired, reduxFieldPhone1],
  },
  cards: {
    component: ReduxSelect,
    name: 'cards',
    options: [],
    validate: [reduxFieldRequired],
  },
};

export default options;
