import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';

import { Button, DateInput, Input, Radio, Select, SmoothDropDown, Translation } from 'shared';
import { confirmChangeProtection, setProtection, reqOTPOnChangeProtection } from 'actions/security';

import styles from './Step2.module.scss';

import { isSubmitValid, validateSubmit } from 'shared/validators';
import { getProps as buildProps, labelMaker, getLocalizedControlQuestions } from 'shared/utils';

const label = labelMaker('profile.security.protection');

class Step2 extends Component {
  handleContinue = () => {
    const {
      user: { sign },
      reqOTPOnChangeProtection,
      confirmChangeProtection,
    } = this.props;

    if (sign === '4') {
      reqOTPOnChangeProtection();
    } else {
      confirmChangeProtection();
    }
  };

  handleChangeProtection = () => {
    const { setProtection, fields, submitFields } = this.props;
    const protectionValue = fields.protection.value;

    const answer =
      protectionValue === 'controlQuestion'
        ? fields.answer.value
        : protectionValue === 'controlWord'
          ? fields.controlWord.value
          : fields.controlDate.value;
    const question = fields.questions.value;

    const fieldsForValidation = [];

    if (protectionValue === 'controlQuestion') {
      fieldsForValidation.push('answer');
    }

    if (protectionValue === 'controlWord') {
      fieldsForValidation.push('controlWord');
    }

    if (protectionValue === 'controlDate') {
      fieldsForValidation.push('controlDate');
    }

    if (isSubmitValid(fields, fieldsForValidation)) {
      setProtection({
        type: protectionValue,
        answer,
        question,
      });
    } else {
      validateSubmit(fields, fieldsForValidation, submitFields);
    }
  };

  render() {
    const { security, fields, onChange, onRadioChange, resetForm, locale } = this.props;
    const { Div } = Translation;

    const protectionValue = fields.protection.value;

    const {
      controlQuestion: { questions },
    } = security;

    const formattedQuestions = questions
      ? questions.map(question => ({
        label: getLocalizedControlQuestions({ question: question.question, locale }),
        value: question.id,
      }))
      : [];

    const getProps = buildProps({ fields, onChange: onChange });
    const radioProps = buildProps({ fields, onChange: onRadioChange })('protection');

    return (
      <Fragment>
        <div className={styles.RadioBlock}>
          <Radio {...radioProps} activeValue="controlQuestion">
            <Div className={styles.RadioLabel} translateId={label('controlQuestionRadioLabel')} />
          </Radio>
        </div>
        <SmoothDropDown isOpen={protectionValue === 'controlQuestion'}>
          <div className={styles.InputContainer}>
            <Select {...getProps('questions')} options={formattedQuestions} />
          </div>
          <div className={styles.InputContainer}>
            <Input {...getProps('answer')} />
          </div>
          <Translation.Div
            className={styles.SubDescription}
            translateId={label('protectionRecommendation')}
          />
        </SmoothDropDown>
        <div className={styles.RadioBlock}>
          <Radio {...radioProps} activeValue="controlWord">
            <Div className={styles.RadioLabel} translateId={label('controlWordRadioLabel')} />
          </Radio>
        </div>
        <SmoothDropDown isOpen={protectionValue === 'controlWord'}>
          <div className={styles.InputContainer}>
            <Input {...getProps('controlWord')} />
          </div>
          <Translation.Div
            className={styles.SubDescription}
            translateId={label('protectionRecommendation')}
          />
        </SmoothDropDown>
        <div className={styles.RadioBlock}>
          <Radio {...radioProps} activeValue="controlDate">
            <Div className={styles.RadioLabel} translateId={label('controlDateRadioLabel')} />
          </Radio>
        </div>
        <SmoothDropDown isOpen={protectionValue === 'controlDate'}>
          <div className={styles.InputContainer}>
            <DateInput {...getProps('controlDate')} labelId={label('controlDate')} />
          </div>
          <Translation.Div
            className={styles.SubDescription}
            translateId={label('protectionRecommendation')}
          />
        </SmoothDropDown>
        <div className={styles.ButtonsContainer}>
          <div className={styles.Button}>
            <Button round translateId={label('cancel')} theme="white" onClick={resetForm} />
          </div>
          <div className={styles.Button}>
            <Button round translateId={label('continue')} onClick={this.handleContinue} />
          </div>
        </div>
      </Fragment>
    );
  }
}

Step2.propTypes = {
  confirmChangeProtection: PropTypes.func.isRequired,
  onRadioChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  security: PropTypes.object.isRequired,
  reqOTPOnChangeProtection: PropTypes.func.isRequired,
  setProtection: PropTypes.func.isRequired,
  submitFields: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  resetForm: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = ({ profile, auth, localize }) => ({
  security: profile.security,
  user: auth.user,
  locale: getActiveLanguage(localize).code,
});

const mapDispatchToProps = {
  confirmChangeProtection,
  reqOTPOnChangeProtection,
  setProtection,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step2);
