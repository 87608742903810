import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, getActiveLanguage } from 'react-localize-redux';
import cn from 'classnames';
import { Field } from 'redux-form';

import { options } from '../../formOptions';

import { Button } from 'shared';
import { reduxFieldRequired, reduxFieldBirthdayEn, reduxFieldBirthdayRu } from 'shared/validators';

import styles from './styles.module.scss';

const Step1 = props => {
  const { pwdRecovery, renderCaptchaBlock, locale } = props;

  const birthValidators = [
    reduxFieldRequired,
    locale === 'ru' && reduxFieldBirthdayRu,
    locale === 'en' && reduxFieldBirthdayEn,
  ].filter(Boolean);

  return (
    <Fragment>
      <div className={styles.StepDescription}>
        <Translate id="pwdRecovery.passportAndBirthday" />
      </div>

      <div className={styles.Row}>
        <div className={cn(styles.Col, styles.ColLeft)}>
          <Field {...options.passportSeries} disabled={pwdRecovery.isRequestPending} />
        </div>
        <div className={cn(styles.Col, styles.ColRight)}>
          <Field {...options.passportNumber} disabled={pwdRecovery.isRequestPending} />
        </div>
      </div>

      <div className={styles.Row}>
        <Field
          {...options.birthday}
          disabled={pwdRecovery.isRequestPending}
          validate={birthValidators}
        />
      </div>

      {renderCaptchaBlock()}

      <div className={styles.SubmitContainer}>
        <Button showLoader={pwdRecovery.isRequestPending} type="submit">
          <Translate id="pwdRecovery.continueBtn" />
        </Button>
      </div>
    </Fragment>
  );
};

Step1.propTypes = {
  renderCaptchaBlock: PropTypes.func.isRequired,
  pwdRecovery: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = ({ pwdRecovery, localize }) => ({
  pwdRecovery,
  locale: getActiveLanguage(localize).code,
});

export default connect(mapStateToProps)(Step1);
