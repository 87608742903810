import { createAction } from 'redux-act';
import { dadataApiKeyReq } from './dadataRequests';

export const fetchDadataApiKeyStart = createAction('FETCH_DADATA_API_KEY_START');
export const fetchDadataApiKeySuccess = createAction('FETCH_DADATA_API_KEY_SUCCESS');
export const fetchDadataApiKeyFailure = createAction('FETCH_DADATA_API_KEY_FAILURE');

export const fetchDadataApiKey = () => async (dispatch, getState) => {
  try {
    dispatch(fetchDadataApiKeyStart());

    const apiKey = await dispatch(dadataApiKeyReq());

    dispatch(fetchDadataApiKeySuccess(apiKey));
  } catch (err) {
    dispatch(fetchDadataApiKeyFailure());
  }
};
