import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// import styles from './Form.module.scss';

import { paymentBackStep, resetPaymentAmount, resetPaymentFormEnd } from 'actions/newPayment';
import { firstStepOptions } from './Steps/options';
import Steps from './Steps';

import { shapeFields, getValidationErrorId } from 'shared/validators';

class Form extends Component {
  constructor(props) {
    super(props);

    const { annuity } = props.credit;
    const { amount } = props.payment;

    this.state = {
      fields: shapeFields({
        ...firstStepOptions,

        sum: {
          ...firstStepOptions.sum,

          value: amount ? amount : annuity,
        },
      }),
    };
  }

  onChange = ({ value, name }, showError) => {
    const { fields } = this.state;
    const field = fields[name];

    const { isError } = field;

    const newField = { ...field, value, touched: true, isError: showError || isError };
    const errorId = getValidationErrorId(newField);

    this.setState({
      fields: {
        ...fields,
        [name]: {
          ...newField,
          errorId,
        },
      },
    });
  };

  componentDidUpdate(prevProps) {
    const { payment: currPayment } = this.props;
    const { payment: prevPayment } = prevProps;
    const { needReset: currNeedReset } = currPayment;
    const { needReset: prevNeedReset } = prevPayment;

    if (prevPayment.amount !== currPayment.amount && currPayment.amount) {
      const { fields } = this.state;

      this.setState({
        fields: {
          ...fields,

          sum: {
            ...fields.sum,
            value: currPayment.amount,
          },
        },
      });
    }
    if (!prevNeedReset && currNeedReset) {
      this.setState(
        {
          fields: shapeFields({
            ...firstStepOptions,
          }),
        },
        () => this.props.resetPaymentFormEnd()
      );
    }
  }

  onSumChange = ({ value, name }, showError) => {
    const { maxSum } = this.props;

    if (parseFloat(value) >= parseFloat(maxSum.replace(/ /g, ''))) {
      this.onChange({ value: parseFloat(maxSum.replace(/ /g, '')), name }, showError);
    } else {
      this.onChange({ value, name }, showError);
    }
  };

  submitFields = newFields => {
    this.setState({
      fields: {
        ...this.state.fields,
        ...newFields,
      },
    });
  };

  componentWillUnmount() {
    this.props.resetPaymentAmount();
  }

  render() {
    const { payment, paymentAccount, maxSum } = this.props;
    const { fields } = this.state;
    const { step } = payment;

    return (
      <Fragment>
        <Steps
          step={step}
          submitFields={this.submitFields}
          fields={fields}
          onChange={this.onChange}
          onSumChange={this.onSumChange}
          maxSum={parseFloat(maxSum.replace(/\s/g, ''))}
          paymentAccount={paymentAccount}
        />
      </Fragment>
    );
  }
}

Form.propTypes = {
  paymentBackStep: PropTypes.func.isRequired,
  resetPaymentAmount: PropTypes.func.isRequired,
  resetPaymentFormEnd: PropTypes.func.isRequired,
  payment: PropTypes.object.isRequired,
  credit: PropTypes.object.isRequired,
  maxSum: PropTypes.string.isRequired,
  paymentAccount: PropTypes.string.isRequired,
};

const mapStateToProps = ({ products }) => ({
  payment: products.payment,
  credit: products.credit,
});

const mapDispatchToProps = {
  paymentBackStep,
  resetPaymentAmount,
  resetPaymentFormEnd,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form);
