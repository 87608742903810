import { createReducer } from 'redux-act';

import {
  fetchHistoryListStart,
  fetchHistoryListSuccess,
  fetchHistoryListError,
  fetchHistoryDetailsStart,
  fetchHistoryDetailsSuccess,
  fetchHistoryDetailsError,
  clearHistoryData,
  fetchOperationsCatalogSuccess,
} from 'actions/operationsHistory';

const defaultState = {
  data: [],
  dataTotal: 0,
  dataLimit: 10, // constant
  details: {},
  isLoading: false,
  detailsError: false,
  detailsLoading: false,
  catalog: [],
  loadingDetailId: null,
};

const operationsHistory = createReducer(
  {
    [fetchHistoryListStart]: state => {
      return {
        ...state,
        isLoading: true,
      };
    },

    [fetchHistoryListSuccess]: (state, history = {}) => {
      return {
        ...state,
        data: [...state.data, ...(history.total ? history.items : [])],
        dataTotal: history.total || 0,
        isLoading: false,
      };
    },

    [fetchHistoryListError]: (state, error) => {
      return {
        ...state,
        isLoading: false,
      };
    },
    [fetchHistoryDetailsSuccess]: (state, { id, details }) => ({
      ...state,
      details: {
        ...state.details,
        [id]: details.historyListDetails,
      },
      detailsError: false,
      detailsLoading: false,
      loadingDetailId: null,
    }),
    [fetchHistoryDetailsError]: (state) => ({
      ...state,
      details: {},
      detailsError: true,
      detailsLoading: false,
      loadingDetailId: null,
    }),
    [fetchHistoryDetailsStart]: (state, id) => ({
      ...state,
      details: {},
      detailsError: false,
      detailsLoading: true,
      loadingDetailId: id,
    }),
    [clearHistoryData]: state => ({
      ...state,
      data: [],
      dataTotal: null,
      isLoading: false,
    }),
    [fetchOperationsCatalogSuccess]: (state, catalog) => ({
      ...state,
      catalog,
    }),
  },
  defaultState
);

export default operationsHistory;
