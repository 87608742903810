import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';

import OperationsHistoryComponent from 'components/OperationsHistory/OperationsHistory';
import {
  Layout,
  Page404,
  withNotification,
  requireAuthentication,
  withKYCNotification,
} from 'shared';

import operationsHistoryTranslations from 'components/OperationsHistory/operationsHistory.json';

class OperationsHistoryPage extends Component {
  constructor(props) {
    super(props);

    props.addTranslation(operationsHistoryTranslations);
  }

  render() {
    return (
      <Layout.Authenticated>
        <Switch>
          <Route exact path="/history" component={OperationsHistoryComponent} />
          <Route exact path="/history/404" component={Page404} />
          <Redirect to={'/history/404'} />
        </Switch>
      </Layout.Authenticated>
    );
  }
}

OperationsHistoryPage.propTypes = {
  auth: PropTypes.object.isRequired,
  addTranslation: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => ({
  auth,
});

export default connect(
  mapStateToProps,
  null
)(
  requireAuthentication(withKYCNotification(withNotification(withLocalize(OperationsHistoryPage))))
);
