import { required, float, isZero } from 'shared/validators';
import { date as dateMask } from 'shared/masks';
import { labelMaker } from 'shared/utils';

const label = labelMaker('products.prepayment');

const firstStepOptions = {
  date: {
    label: 'Дата',
    labelId: label('date'),
    mask: dateMask,
  },
  sum: {
    label: '',
    value: 0,
    validators: [{ required }, { float }, { isZero }],
  },
};

export default firstStepOptions;
