import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { closeChangeProtectionSettings } from 'actions/security';
import { resetSignature } from 'actions/signature';

import Form from './Form/Form';

class ChangeProtection extends Component {
  componentWillUnmount() {
    const { closeChangeProtectionSettings, resetSignature } = this.props;

    closeChangeProtectionSettings();
    resetSignature();
  }

  render() {
    return <Form />;
  }
}

ChangeProtection.propTypes = {
  closeChangeProtectionSettings: PropTypes.func.isRequired,
  resetSignature: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  closeChangeProtectionSettings,
  resetSignature,
};

export default connect(
  null,
  mapDispatchToProps
)(ChangeProtection);
