import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, getActiveLanguage } from 'react-localize-redux';
import moment from 'moment';
import { Field } from 'redux-form';

import withPasswordRecoveryForm, { options } from './formOptions';

import { goTo } from 'actions/routes';
import { Captcha } from 'shared';
import {
  passwordRecoverySendUserInfo,
  passwordRecoveryGetOTP,
  passwordRecoveryCancel,
  passwordRecoverySendOTP,
} from 'actions/pwdRecovery';
import Steps from './Steps';

import { getLocaleDateFormat } from 'shared/utils';

import styles from './Form.module.scss';

class Form extends Component {
  componentWillUnmount = () => {
    this.props.passwordRecoveryCancel();
  };

  handleBackButton = () => this.props.goTo('/login');

  onSubmit = values => {
    const { passportSeries, passportNumber, birthday, captcha, answer } = values;
    const {
      pwdRecovery,
      captcha: captchaData,
      localize,
      passwordRecoverySendUserInfo,
      passwordRecoveryGetOTP,
      passwordRecoverySendOTP,
      signature,
    } = this.props;

    switch (pwdRecovery.step) {
      case 1: {
        const dateFormat = getLocaleDateFormat(getActiveLanguage(localize).code);

        passwordRecoverySendUserInfo({
          passportSeries,
          passportNumber,
          birthDate: moment(birthday, dateFormat).format('DD.MM.YYYY'),
          captchaId: captchaData.id,
          captcha,
        });
        break;
      }
      case 2: {
        const { cid, aid, conSecId } = pwdRecovery;

        passwordRecoveryGetOTP({ answer, cid, aid, conSecId });
        break;
      }
      case 3: {
        const { cid, aid, conSecId } = pwdRecovery;

        passwordRecoverySendOTP({
          sms: signature.value,
          cid,
          aid,
          conSecId,
        });
        break;
      }
      default:
        break;
    }
  };

  renderCaptchaBlock = () => {
    const { pwdRecovery } = this.props;

    return (
      <div className={styles.CaptchaContainer}>
        <div className={styles.CaptchaRow}>
          <Captcha />
        </div>
        <div className={styles.CaptchaRow}>
          <Field {...options.captcha} disabled={pwdRecovery.isRequestPending} />
        </div>
      </div>
    );
  };

  render() {
    const { step } = this.props.pwdRecovery;

    return (
      <div className={styles.Form}>
        <div className={styles.Header}>
          <div className={styles.BackButton} onClick={this.handleBackButton}>
            <div className={styles.ArrowBack} />
            <Translate id="pwdRecovery.backBtn" />
          </div>

          <div className={styles.HeaderTitle}>
            <Translate id="pwdRecovery.pwdRecovery" />
          </div>
        </div>

        <div className={styles.Body}>
          <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <Steps renderCaptchaBlock={this.renderCaptchaBlock} step={step} />
          </form>
        </div>
      </div>
    );
  }
}

Form.propTypes = {
  goTo: PropTypes.func.isRequired,
  passwordRecoverySendUserInfo: PropTypes.func.isRequired,
  passwordRecoveryCancel: PropTypes.func.isRequired,
  passwordRecoveryGetOTP: PropTypes.func.isRequired,
  passwordRecoverySendOTP: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  pwdRecovery: PropTypes.object.isRequired,
  captcha: PropTypes.object.isRequired,
  localize: PropTypes.object.isRequired,
  signature: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
};

const mapStateToProps = ({ localize, pwdRecovery, captcha, auth, signature }) => {
  const lang = localize.languages.filter(l => l.active)[0].code;
  return {
    auth,
    pwdRecovery: pwdRecovery,
    localize,
    captcha,
    lang,
    signature,
  };
};

const mapDispatchToProps = {
  goTo,
  passwordRecoverySendUserInfo,
  passwordRecoveryCancel,
  passwordRecoveryGetOTP,
  passwordRecoverySendOTP,
};

export default withPasswordRecoveryForm(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form)
);
