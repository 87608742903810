import React from 'react';
import styles from './CreditDetails.module.scss';
import Div from 'shared/Translation/Div';
import PropTypes from 'prop-types';

const Details = ({ details }) =>
  details.map((detail, index) => {
    return (
      <div className={styles.Detail} key={index}>
        <Div className={styles.Label} translateId={detail.labelId} />
        <div className={styles.Value}>{detail.value}</div>
      </div>
    );
  });

Details.propTypes = {
  details: PropTypes.array,
};

export default Details;
