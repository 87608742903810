import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';

import TransitionTemplate from '../TransitionTemplate/TransitionTemplate';
import { labelMaker, formatNumber, getLocaleDateFormat, isArrayEmpty } from 'shared/utils';
import { Translation } from 'shared';

import { ReactComponent as PaymentImage } from 'assets/icons/icon-operation-document-empty.svg';
import styles from './HistoryList.module.scss';

const label = labelMaker('shared.paymentList');
const { Span } = Translation;

class PaymentsHistory extends Component {
  renderEmptyList = () => {
    return (
      <div className={styles.EmptyTransitionsBlock}>
        <div className={styles.EmptyMask}>
          <PaymentImage className={styles.PaymentImage} />
        </div>
        <Span className={styles.EmptyInfo} translateId={label('emptyOperationsHistory')} />
      </div>
    );
  };

  invertAmount = amount => -amount;

  render() {
    const { transitions, locale } = this.props;

    const dateFormat = getLocaleDateFormat(locale);

    const transitionsList = transitions.filter(t => t !== null).map(transition => {
      const { description, valueDate, amount } = transition.transaction;
      const { corCcy, corAccountNo } = transition.payment;

      return {
        title: description,
        date: moment(valueDate, 'YYYY.MM.DD').format(dateFormat),
        amount: formatNumber(this.invertAmount(amount), { currency: corCcy }),
        reverseIcon: amount > 0,
        corAccountNo,
        // details,
      };
    });

    return (
      <div className={styles.List}>
        {isArrayEmpty(transitionsList)
          ? this.renderEmptyList()
          : transitionsList.map((transition, key) => (
              <TransitionTemplate key={key} transition={transition} />
            ))}
      </div>
    );
  }
}

PaymentsHistory.propTypes = {
  locale: PropTypes.string,
  transitions: PropTypes.array,
};

PaymentsHistory.defaultProps = {
  transitions: PropTypes.array,
  locale: PropTypes.string,
};

const mapStateToProps = ({ localize }) => ({
  locale: getActiveLanguage(localize).code,
});

export default connect(mapStateToProps)(PaymentsHistory);
