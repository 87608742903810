import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { reduxForm, Field } from 'redux-form';

import { goTo } from 'actions/routes';
import {
  fetchRegistrationSettings,
  setStep,
  sendRegistrationSmsCode,
  checkRegistrationSmsCode,
  registerUser,
  setUserProtection,
  cancelRegistration,
} from 'actions/signup';
import { newLogin, fetchTechNotify } from 'actions/auth';
import { Stepper, Link, Captcha } from 'shared';
import Steps from './Steps';

import options from './formOptions';

import styles from './Form.module.scss';

class LoginProfileForm extends Component {
  async componentDidMount() {
    await this.props.fetchTechNotify();
    this.props.fetchRegistrationSettings();
  }

  componentWillUnmount() {
    this.props.cancelRegistration();
  }

  componentDidUpdate(prevProps, prevState) {
    const { auth, signup, setStep } = this.props;
    const { auth: prevAuth } = prevProps;

    if (!prevAuth.isAuthenticated && auth.isAuthenticated) {
      if (signup.step === 3) {
        setStep(4);
      }
    }
  }

  handleBackButton = () => this.props.goTo('/login');

  renderCaptchaBlock = disabled => {
    const { captcha } = this.props;

    return captcha.isCaptchaRequired ? (
      <div className={styles.CaptchaContainer}>
        <div className={styles.CaptchaRow}>
          <Captcha />
        </div>
        <div className={styles.CaptchaRow}>
          <Field {...options.captcha} disabled={disabled} />
        </div>
      </div>
    ) : null;
  };

  resendOTPRequest = () => {
    const { sendRegistrationSmsCode, form } = this.props;
    const { phone, cardOrAccount } = form.registration.values;

    const purePhone = phone.replace(/-/g, '').substr(-4);
    const pureCardOrAccount = cardOrAccount.replace(/\s/g, '');

    sendRegistrationSmsCode({ phone: purePhone, cardOrAccount: pureCardOrAccount });
  };

  onSubmit = args => {
    const {
      phone,
      cardOrAccount,
      login,
      password,
      confirmedPassword,
      controlQuestionAnswer,
      controlWord,
      controlDate,
      controlQuestion,
      protection,
      protectionStatus,
      captcha: captchaValue,
    } = args;

    const { signup, checkRegistrationSmsCode, signature, captcha } = this.props;

    const purePhone = phone ? phone.replace(/-/g, '').substr(-4) : '';
    const pureCardOrAccount = cardOrAccount ? cardOrAccount.replace(/\s/g, '') : '';

    const {
      cid,
      confirmedPasswordMapId,
      confirmedPasswordMap,
      egp4,
      passwordMapId,
      passwordMap,
      sms,
      isRegistered,
    } = signup;

    switch (signup.step) {
      case 1:
        this.props.sendRegistrationSmsCode({
          phone: purePhone,
          cardOrAccount: pureCardOrAccount,
        });
        break;
      case 2: {
        checkRegistrationSmsCode({ sms: signature.value, cid, egp4, captcha });
        break;
      }
      case 3: {
        const registerArgs = {
          cid,
          egp4,
          cardOrAccount: cardOrAccount.replace(/\s/g, ''),
          sms,
          phone: purePhone,
          login,
          password,
          confirmedPassword,
          passwordMapId,
          passwordMap,
          confirmedPasswordMapId,
          confirmedPasswordMap,
        };
        if (captcha.isCaptchaRequired) {
          Object.assign(registerArgs, { captchaId: captcha.id, captchaValue });
        }

        if (isRegistered) {
          this.props.loginUser({ ...registerArgs, without2Fa: true });
        } else {
          this.props.registerUser(registerArgs);
        }
        break;
      }
      case 4: {
        let answer = '';

        if (!protectionStatus) {
          this.props.goTo('/products');
        } else {
          if (protection === 'controlQuestion') {
            answer = controlQuestionAnswer;
          }

          if (protection === 'controlWord') {
            answer = controlWord;
          }

          if (protection === 'controlDate') {
            answer = controlDate;
          }

          this.props.setUserProtection({
            login,
            password,
            type: protection,
            answer,
            question: controlQuestion,
          });
        }

        break;
      }
      default:
        return;
    }
  };

  render() {
    const { signup, handleSubmit } = this.props;

    return (
      <div className={styles.Form}>
        <div className={styles.Header}>
          <div className={styles.BackButton} onClick={this.handleBackButton}>
            <div className={styles.ArrowBack} />
            <Translate id="signup.backBtn" />
          </div>

          <div className={styles.TitleContainer}>
            <Translate id="signup.signIn" />
            <Link className={styles.LinkBlue} to="/login" translateId="signup.signInBtn" />
          </div>
        </div>

        <div className={styles.StepperContainer}>
          <Stepper steps={4} step={signup.step} />
        </div>

        <form onSubmit={handleSubmit(this.onSubmit)}>
          <Steps
            step={signup.step}
            options={options}
            form={this.props.form}
            resendOTPRequest={this.resendOTPRequest}
            renderCaptchaBlock={this.renderCaptchaBlock}
          />
        </form>
      </div>
    );
  }
}

LoginProfileForm.propTypes = {
  fetchRegistrationSettings: PropTypes.func.isRequired,
  sendRegistrationSmsCode: PropTypes.func.isRequired,
  checkRegistrationSmsCode: PropTypes.func.isRequired,
  registerUser: PropTypes.func.isRequired,
  setUserProtection: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  cancelRegistration: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  captcha: PropTypes.object.isRequired,
  signup: PropTypes.object.isRequired,
  setStep: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  signature: PropTypes.object.isRequired,
  fetchTechNotify: PropTypes.func
};

const mapStateToProps = ({ localize, signup, auth, form, signature, captcha }) => {
  const lang = localize.languages.filter(l => l.active)[0].code;
  return {
    signup,
    auth,
    lang,
    form,
    signature,
    captcha,
  };
};

const mapDispatchToProps = {
  fetchRegistrationSettings,
  sendRegistrationSmsCode,
  checkRegistrationSmsCode,
  registerUser,
  setUserProtection,
  cancelRegistration,
  setStep,
  loginUser: newLogin,
  goTo,
  fetchTechNotify,
};

const form = 'registration';

export default reduxForm({
  form,
  initialValues: {
    protection: 'controlQuestion',
    controlQuestion: 1,
    protectionStatus: true,
  },
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LoginProfileForm)
);
