import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { fetchBranchesByCoordinates, fetchAtmsByCoordinates } from 'actions/atmsAndBranches';
import { labelMaker } from 'shared/utils';
import AtmsMenu from '../AtmsMenu/AtmsMenu';
import AtmsFilters from '../AtmsFilters/AtmsFilters';
import AtmsAdvancedSearch from '../AtmsAdvancedSearch/AtmsAdvancedSearch';

import styles from './AtmsContentHeader.module.scss';

import options, { initialValues } from './formOptions';

const lAtms = labelMaker('atms');

class AtmsContentHeader extends Component {
  state = { isFilterOpen: false };

  moveAndZoom = zoom => ({ coords, corners }) => {
    const path = this.props.location.pathname;
    const component = path.substr(path.lastIndexOf('/') + 1);
    if (component === 'list') {
      this.props.fetchBranchesByCoordinates(corners);
      this.props.fetchAtmsByCoordinates(corners);
    }
    this.props.moveAndZoom(coords, zoom);
  };

  render() {
    return (
      <Fragment>
        <div className={styles.Header}>
          <div className={styles.HeaderBody}>
            <div className={styles.Title}>
              <Translate id={lAtms('officesAndAtms')} />
            </div>
            <div className={styles.Actions}>
              <div className={styles.CityFilterContainer}>
                <Field
                  {...options.city}
                  placeholder="atms.search.city"
                  round
                  moveOn={this.moveAndZoom(12)}
                />
              </div>
              <div className={styles.searchContainer}>
                <Field
                  {...options.metro}
                  placeholder="atms.search.placeholder"
                  round
                  moveOn={this.moveAndZoom(15)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.Toolbar}>
          <div className={styles.ToolbarBody}>
            <div className={styles.AdvancedSearch}>
              <AtmsAdvancedSearch />
            </div>
            <div className={styles.Filters}>
              <AtmsFilters options={options} />
            </div>
            <div className={styles.Menu}>
              <AtmsMenu />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

AtmsContentHeader.propTypes = {
  moveAndZoom: PropTypes.func.isRequired,
  fetchBranchesByCoordinates: PropTypes.func.isRequired,
  fetchAtmsByCoordinates: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapDispatch = { fetchBranchesByCoordinates, fetchAtmsByCoordinates };

export default reduxForm({
  form: 'atmsAndBranches/mapItemsFilter',
  initialValues,
})(
  connect(
    null,
    mapDispatch
  )(withRouter(AtmsContentHeader))
);
