import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { SmsInput, Button } from 'shared';
import { passwordRecoveryGetOTP } from 'actions/pwdRecovery';

import { labelMaker } from 'shared/utils';

import styles from './styles.module.scss';

const label = labelMaker('pwdRecovery');

class Step3 extends Component {
  onResend = () => {
    const { pwdRecovery, passwordRecoveryGetOTP, form } = this.props;
    const { answer } = form.passwordRecovery.values;
    const { cid, aid, conSecId } = pwdRecovery;

    passwordRecoveryGetOTP({ answer, cid, aid, conSecId });
  };

  render() {
    const { pwdRecovery } = this.props;

    return (
      <div>
        <SmsInput
          phoneNumber={pwdRecovery.smsPhone}
          onResend={this.onResend}
          isLoading={pwdRecovery.isRequestPending}
        />
        <div className={styles.SubmitContainer}>
          <Button
            type="submit"
            translateId={label('send')}
            showLoader={pwdRecovery.isRequestPending}
          />
        </div>
      </div>
    );
  }
}

Step3.propTypes = {
  pwdRecovery: PropTypes.object.isRequired,
  passwordRecoveryGetOTP: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

const mapState = ({ pwdRecovery, form }) => ({
  pwdRecovery,
  form,
});

const mapDispatch = {
  passwordRecoveryGetOTP,
};

export default connect(
  mapState,
  mapDispatch
)(Step3);
