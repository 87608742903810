import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getTranslate } from 'react-localize-redux';

import { Anchor, Translation } from 'shared';

import TextLogoBlack from 'assets/logo/logo-ru-white.svg';
import imgLogoRed from 'assets/icons/icon-app.svg';
import Store from 'assets/buttons/button-store.svg';
import Play from 'assets/buttons/button-play.svg';
import SocialTwitter from 'assets/social/icon-social-twitter.svg';
import SocialVk from 'assets/social/icon-social-vk.svg';
import SocialYoutube from 'assets/social/icon-social-youtube.svg';
import SocialBankiru from 'assets/social/icon-social-bankiru.svg';

import styles from './Footer.module.scss';

const linkVk = 'http://vkontakte.ru/unicreditbank';
const linkTwitter = 'https://twitter.com/unicreditrussia';
const linkYoutube = 'http://www.youtube.com/unicreditbankrussia';
const linkBankiRu = 'http://www.banki.ru/friends/group/unicreditbank/';

const linkOffices = 'https://www.unicreditbank.ru/ru/branch-finder.html';
const exchangeRate =
  'https://www.unicreditbank.ru/ru/personal/all-currencies.html?_ga=2.184529442.565698707.1530520420-2105066617.1523435635';
const linkAboutUs =
  'https://www.unicreditbank.ru/ru/about.html?_ga=2.184529442.565698707.1530520420-2105066617.1523435635';
const linkBankNews =
  'https://www.unicreditbank.ru/ru/about/press_media/press-releases.html?_ga=2.113204608.565698707.1530520420-2105066617.1523435635';
const linkAppStore = 'https://itunes.apple.com/ru/app/mobile.unicredit-2.0/id665861209';
const linkGooglePlay = 'https://play.google.com/store/apps/details?id=ru.ucb.android';
const linkCurrentVersion = 'https://enter.unicredit.ru';

const SocialItem = ({ src }) => (
  <div className={styles.SocialItem}>
    <img src={src} alt="" />
  </div>
);

SocialItem.propTypes = {
  src: PropTypes.string.isRequired,
};

const MobileApp = () => (
  <div className={styles.MobileApp}>
    <img src={imgLogoRed} alt="" />
    <Translation.Div className={styles.MobileAppInfo} translateId="footer.mobileApp" />
  </div>
);

const Footer = props => {
  const copyrightYear = `© ${new Date().getFullYear()}, `;

  return (
    <div className={styles.FooterContainer}>
      <footer className={styles.Footer}>
        <div className={styles.InfoRow}>
          <Anchor className={styles.Anchor} href={linkOffices} translateId="footer.offices" />
          <Anchor className={styles.Anchor} href={linkBankNews} translateId="footer.news" />
          <Anchor className={styles.Anchor} href={exchangeRate} translateId="footer.exRates" />
          <Anchor className={styles.Anchor} href={linkAboutUs} translateId="footer.aboutUs" />
        </div>

        <div className={styles.MiddleContainer}>
          <div className={styles.InfoContainer}>
            <div className={styles.MobileContainer}>
              <h2 className={styles.Mobile}>8 800 700 10 20</h2>
              <Translation.Div className={styles.MobileDescription} translateId="footer.tollFree" />
            </div>

            <div className={styles.CurrentVersionContainer}>
              <Anchor
                className={styles.CurrentVersionLink}
                href={linkCurrentVersion}
                translateId="footer.currentVersion"
              >
                <br />
                <span className={styles.CurrentVersionLink}>ENTER.UNICREDIT</span>
              </Anchor>
            </div>
          </div>

          <div className={styles.MobileAppContainer}>
            <MobileApp />
            <div className={styles.AppsContainer}>
              <div className={styles.AppItem}>
                <Anchor href={linkAppStore}>
                  <img src={Store} alt="App Store" />
                </Anchor>
              </div>
              <div className={styles.AppItem}>
                <Anchor href={linkGooglePlay}>
                  <img src={Play} alt="Google Play" />
                </Anchor>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.Line} />

        <div className={styles.BottomContainer}>
          <div className={styles.LeftContainer}>
            <div className={styles.TextLogoWhite}>
              <img src={TextLogoBlack} alt="" />
            </div>
            <div className={styles.CopyRightBlock}>
              {copyrightYear}
              <Translation.Span translateId="footer.copyright" />
            </div>
          </div>
          <div className={styles.RightContainer}>
            <div className={styles.Social}>
              <Anchor href={linkVk}>
                <SocialItem src={SocialVk} />
              </Anchor>
              <Anchor href={linkTwitter}>
                <SocialItem src={SocialTwitter} />
              </Anchor>
              <Anchor href={linkYoutube}>
                <SocialItem src={SocialYoutube} />
              </Anchor>
              <Anchor href={linkBankiRu}>
                <SocialItem src={SocialBankiru} />
              </Anchor>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

Footer.propTypes = {
  translate: PropTypes.func.isRequired,
};

const mapState = ({ localize }) => ({
  translate: getTranslate(localize),
});

export default connect(mapState)(Footer);
