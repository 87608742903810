import React from 'react';
import PropTypes from 'prop-types';

import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import Step3 from './Step3/Step3';

const Steps = props => {
  const { step, ...rest } = props;

  switch (step) {
    case 1:
      return <Step1 {...rest} />;
    case 2:
      return <Step2 {...rest} />;
    case 3:
      return <Step3 {...rest} />;
    default:
      return null;
  }
};

Steps.propTypes = {
  step: PropTypes.number.isRequired,
};

export default Steps;
