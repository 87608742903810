import React from 'react';
import Slider from 'react-rangeslider';
import PropTypes from 'prop-types';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { labelMaker, formatNumber, getCurrencySymbol } from 'shared/utils';

import './InputRange.scss';
import styles from './InputRange.module.scss';

const lInputRang = labelMaker('shared.inputRange');

const InputRange = props => {
  const { translate, min: minVal, max: maxVal, afterMax, currency } = props;

  const onChange = value => {
    const { name, step: stepVal } = props;
    const step = stepVal.toString();

    const fix = step.length - step.indexOf('.');
    const newValue = value.toFixed(fix - 1);

    if (props.onChange) {
      props.onChange({ value: newValue, name });
    }
  };

  const renderFromLabel = minValue =>
    renderLabel(
      `${translate(lInputRang('from'), 'От')} ${formatNumber(minValue, { round: 0 })} ${
        currency ? getCurrencySymbol(currency) : ''
      }`
    );

  const renderToLabel = maxValue => (
    <div className={styles.ToLabelContainer}>
      {renderLabel(
        `${translate(lInputRang('to'), 'До')} ${formatNumber(maxValue, { round: 0 })} ${
          currency ? getCurrencySymbol(currency) : ''
        }`
      )}
      {afterMax()}
    </div>
  );

  const renderLabel = label => {
    return <span className={styles.Label}>{label}</span>;
  };

  const min = parseFloat(minVal);
  const max = parseFloat(maxVal);

  return (
    <div className={styles.InputRange}>
      <Slider min={min} max={max} value={parseInt(props.value, 10)} onChange={onChange} />
      <div className={styles.LabelsContainer}>
        {renderFromLabel(min)}
        {renderToLabel(max)}
      </div>
    </div>
  );
};

InputRange.propTypes = {
  renderFromLabel: PropTypes.func,
  afterMax: PropTypes.func,
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  translate: PropTypes.func.isRequired,
  step: PropTypes.number,
  name: PropTypes.string,
  currency: PropTypes.string,
};

InputRange.defaultProps = {
  afterMax: () => null,
  min: 0,
  max: 1,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(InputRange);
