import React from 'react';
import PropTypes from 'prop-types';
import { Translation } from 'shared';

import styles from './CreditDetails.module.scss';

import { labelMaker, transliterate } from 'shared/utils';
import Details from './Details';

const lDetails = labelMaker('products.creditDetails');

const Requisites = ({ accounts, credit, auth, requisites, currenciesModule, createRowData }) => {
  const { contractRef, ccy } = credit;

  const account = accounts.find(account => account.accountNo === credit.drsettlementacc);
  if (account) {
    const { cbAccountNo } = account;
    const localizedFullName =
      ccy === 'RUR' ? auth.user.fullname : transliterate(auth.user.fullname);

    const RURModule = Array.isArray(currenciesModule)
      ? currenciesModule.find(module => module.title === 'RUR')
      : '';
    const ccyModule = Array.isArray(currenciesModule)
      ? currenciesModule.find(module => module.title === ccy)
      : '';
    const beneficiaryBank = RURModule && RURModule.textEn.bank;
    const intermediaryBank = ccyModule && ccyModule.textEn.corbank;
    const beneficiarySwiftCode = RURModule && RURModule.textEn.bank_swift_code;
    const intermediarySwiftCode = ccyModule && ccyModule.textEn.corbank_swift_code;

    let details = [
      createRowData('currency', ccy || ''),
      createRowData('recipient', localizedFullName || ''),
      createRowData('repaymentAccountNumber', cbAccountNo || ''),
      createRowData('loanAgreementNumber', contractRef || ''),
      ccy === 'RUR' && createRowData('bic', requisites.bic || ''),
      ccy === 'RUR' && createRowData('corAccountNumber', requisites.account || ''),
      ccy !== 'RUR' && createRowData('beneficiaryBank', beneficiaryBank || ''),
      ccy !== 'RUR' && createRowData('swiftCode', beneficiarySwiftCode || ''),
      ccy !== 'RUR' && createRowData('intermediaryBank', intermediaryBank || ''),
      ccy !== 'RUR' && createRowData('corSwiftCode', intermediarySwiftCode || ''),
    ];

    details = details.filter(detail => detail);
    return <div className={styles.Details}>{requisites && <Details details={details} />}</div>;
  }

  return <Translation.Div className={styles.NoData} translateId={lDetails('noData')} />;
};

Requisites.propTypes = {
  credit: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  requisites: PropTypes.object.isRequired,
  accounts: PropTypes.array,
  currenciesModule: PropTypes.array,
  createRowData: PropTypes.func.isRequired,
};

export default Requisites;
