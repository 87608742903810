import React from 'react';
import PropTypes from 'prop-types';

import { Translation } from 'shared';

import styles from './styles.module.scss';

const { Div } = Translation;

const SecurityHeader = ({ titleId, src }) => {
  return (
    <div className={styles.Container}>
      <Div className={styles.Title} translateId={titleId} />
      <img className={styles.Icon} src={src} alt="icon" />
    </div>
  );
};

SecurityHeader.propTypes = {
  titleId: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

export default SecurityHeader;
