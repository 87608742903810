import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, getTranslate } from 'react-localize-redux';

import PaymentsList from '../../../../components/PaymentsList/PaymentsList';
import { Button, Translation } from 'shared';
import { labelMaker, formatNumber, print } from 'shared/utils';
import { accountsSelector } from 'store/selectors/accounts';
import styles from './Step3.module.scss';

import { finishPrepayment } from 'actions/products';

const label = labelMaker('products.prepayment');

class Step3 extends Component {
  render() {
    const {
      incomingApplication,
      finishPrepayment,
      onPayment,
      products,
      accounts,
      translate,
    } = this.props;
    const { ccy, drsettlementacc } = products.credit;

    const creditAccount = accounts.find(acc => acc.accountNo === drsettlementacc);
    const defaultCbAccountNo = accounts[0].cbAccountNo;
    const defaultTotalBalance = accounts[0].CurrentBalance;

    const accNumber = creditAccount ? creditAccount.cbAccountNo : defaultCbAccountNo;
    const currentBalance = creditAccount ? creditAccount.CurrentBalance : defaultTotalBalance;

    const fullPaymentDescriptionTitleTemplate = translate(label('fullRepaymentApplicationCreated'));
    const partPaymentDescriptionTitleTemplate = translate(label('partRepaymentApplicationCreated'));

    const paymentAccountSumTemplate = translate(label('repaymentAccountSum'));
    const paymentAccountSumTitle = print(
      paymentAccountSumTemplate,
      `<b>${accNumber}</b>`,
      `<b>${formatNumber(incomingApplication.repayment.amount, { currency: ccy })}</b>`
    );

    const accountDifference =
      parseFloat(currentBalance) - parseFloat(incomingApplication.repayment.amount);
      // let accountDifferenceAmount = Math.abs(Math.ceil(accountDifference));

    const isReplenishmentRequired = accountDifference < 0;

    const totalBalanceTemplate = translate(label('balance'));
    const totalBalance = print(
      totalBalanceTemplate,
      `<b>${formatNumber(currentBalance, { currency: ccy })}</b>`
    );

    const { Div } = Translation;

    return (
      <div className={styles.Step}>
        <div className={styles.StepTitleContainer}>
          <Div className={styles.StepTitle} translateId={label('earlyPaymentCreated')} />
          <div className={styles.StepImage} />
        </div>
        <div>
          <div className={styles.Text}>
            {incomingApplication.paymentType === 'FullRepayment'
              ? fullPaymentDescriptionTitleTemplate
              : partPaymentDescriptionTitleTemplate}
          </div>
          <div
            className={styles.Text}
            dangerouslySetInnerHTML={{ __html: paymentAccountSumTitle }}
          />
          <div className={styles.Text} dangerouslySetInnerHTML={{ __html: totalBalance }} />
        </div>
        <div className={styles.PaymentsListContainer}>
          <PaymentsList payments={incomingApplication} canDropDown={false} />
        </div>
        <div className={styles.FillActionButton}>
          <Button
            className={styles.actionBtnLeft}
            onClick={finishPrepayment}
            theme="white"
            translateId={label('completeBtn')}
          />
          <Button
            className={styles.actionBtnRight}
            onClick={onPayment(!isReplenishmentRequired ? incomingApplication.repayment.amount : '0')}
          >
            <Translate id={label('deposit')} />
            {!isReplenishmentRequired &&
              ` ${formatNumber(Math.abs(parseFloat(incomingApplication.repayment.amount)), { currency: ccy })}`}
          </Button>
        </div>
      </div>
    );
  }
}

Step3.propTypes = {
  finishPrepayment: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onPayment: PropTypes.func.isRequired,
  incomingApplication: PropTypes.object.isRequired,
  products: PropTypes.object.isRequired,
  accounts: PropTypes.array,
};

const mapStateToProps = ({ products, localize, ...state }) => ({
  incomingApplication: products.prepayment.incomingApplication,
  translate: getTranslate(localize),
  products,
  accounts: accountsSelector(state),
});

const mapDispatchToProps = {
  finishPrepayment,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step3);
