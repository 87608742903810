import React, { Fragment } from 'react';

import Translation from '../Translation';
import styles from './Page404.module.scss';

import { labelMaker } from 'shared/utils';
import { Link } from 'shared';

const lHeader = labelMaker('header');

const { Div } = Translation;
const tabs = {
  products: 'Мои финансы',
  history: 'История операций',
  // bonuses: 'Бонусы',
};

const Page404 = () => (
  <Fragment>
    <div className={styles.CardContainer}>
      <div className={styles.Card}>
        <div className={styles.ErrorContainer}>
          <div className={styles.ErrorCode}>404</div>
          <Div className={styles.ErrorMessage} translateId="404.notFound" />
          <Div className={styles.ErrorSuggestion} translateId="404.tryOtherMenu" />
          <div className={styles.Tabs}>
            {Object.keys(tabs).map((tab, i) => (
              <Link key={i} to={`/${tab}`} className={styles.Tab} translateId={lHeader(tab)} />
            ))}
          </div>
        </div>
      </div>
    </div>
    <div className={styles.FooterContainer}>
      <div className={styles.Footer}>
        <div className={styles.InfoColumn}>
          <Div className={styles.Title} translateId="footer.mobileApp" />
          <Div className={styles.Subtitle} translateId="404.adSubtitle" />
          <Div className={styles.AdText} translateId="404.adText" />
        </div>
        <div className={styles.Image}>
          <div className={styles.ImageShadow} />
        </div>
      </div>
    </div>
  </Fragment>
);

export default Page404;
