import { createReducer } from 'redux-act';

import {
  setOffers,
  selectOffer,
  initializeOffersError,
  setOperationsHistory,
  initializeBonusesPageStart,
  initializeBonusesPageEnd,
} from 'actions/bonuses/index';

const defaultState = {
  offers: null,
  offer: null,

  operations: {
    history: null,

    err: '',
  },

  isLoading: false,
  isLoaded: false,
  err: '',
};

const bonuses = createReducer(
  {
    [setOffers]: (state, offers) => {
      return {
        ...state,

        offers,
      };
    },
    [initializeOffersError]: (state, err) => {
      return {
        ...state,

        err,
      };
    },

    [selectOffer]: (state, id) => {
      const { offers } = state;
      const offer = offers.find(offer => (offer.id = id));

      return {
        ...state,

        offer,
      };
    },

    [setOperationsHistory]: (state, history) => {
      return {
        ...state,

        operations: {
          ...state.operations,
          history,
        },
      };
    },

    [initializeBonusesPageStart]: state => {
      return {
        ...state,

        isLoading: true,
      };
    },
    [initializeBonusesPageEnd]: state => {
      return {
        ...state,

        isLoading: false,
        isLoaded: true,
      };
    },
  },
  defaultState
);

export default bonuses;
