import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { Translation } from 'shared';
import { labelMaker } from 'shared/utils';

import styles from './AtmsFilters.module.scss';

const lAtms = labelMaker('atms');

class AtmsFilters extends Component {
  render() {
    const { options } = this.props;

    return (
      <Fragment>
        <div className={styles.FilterItem}>
          <Translation.Div className={styles.FilterName} translateId={lAtms('offices')} />
          <Field {...options.branches} small red />
        </div>
        <div className={styles.FilterItem}>
          <Translation.Div className={styles.FilterName} translateId={lAtms('atms')} />
          <Field {...options.atms} small />
        </div>
      </Fragment>
    );
  }
}

AtmsFilters.propTypes = {
  options: PropTypes.object.isRequired,
};

export default AtmsFilters;
