import React from 'react';
import PropTypes from 'prop-types';
import { Translation, Toggle, Spinner } from 'shared';
import { labelMaker } from 'shared/utils';

import styles from './SetVisibilityBlock.module.scss';

const label = labelMaker('products.account');

const SetVisibilityBlock = ({ isVisibilityPending, visibilityRule, onChange }) => {
  return (
    <div className={styles.Visibility}>
      <Translation.Div className={styles.Title} translateId={label('productDisplay')} />
      <div className={styles.SubtitleContainer}>
        <div>
          {isVisibilityPending ? (
            <Spinner className={styles.LoadingSpinner} />
          ) : (
            <Translation.Div
              className={styles.Subtitle}
              translateId={visibilityRule >= 2 ? label('enabled') : label('disabled')}
            />
          )}

          <Translation.Div
            className={styles.SubDescription}
            translateId={label('productDisplayDescription')}
          />
        </div>
        <Toggle value={visibilityRule >= 2} onChange={onChange} disabled={isVisibilityPending} />
      </div>
    </div>
  );
};

SetVisibilityBlock.propTypes = {
  isVisibilityPending: PropTypes.bool.isRequired,
  visibilityRule: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SetVisibilityBlock;
