import React, { Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

import fieldsOption from '../../options';

import { Input, Translation, Button } from 'shared';
import { labelMaker } from 'shared/utils';

import styles from '../../Security.module.scss';

const lSecurity = labelMaker('profile.security');
const actionId = lSecurity('CHANGE');

const ChangeLogin = ({
  login,
  toggleChangeLoginBlock,
  cancelChangeLogin,
  handleSubmit,
  showChangeLoginBlock,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Translation.Div className={styles.BlockTitle} translateId={lSecurity('login')} />
      <Translation.Div className={styles.InputLabel} translateId={lSecurity('loginTitle')} />
      <div className={styles.InputContainer}>
        <Input
          {...fieldsOption.login}
          value={login}
          actionId={!showChangeLoginBlock ? actionId : null}
          handleAction={toggleChangeLoginBlock}
          disabled
        />
      </div>
      {showChangeLoginBlock && (
        <Fragment>
          <div className={styles.InputContainer}>
            <Field {...fieldsOption.newLogin} />
          </div>
          <div className={styles.ButtonsContainer}>
            <div className={styles.Button}>
              <Button
                round
                translateId={lSecurity('cancel')}
                theme="white"
                onClick={cancelChangeLogin}
              />
            </div>
            <div className={styles.Button}>
              <Button type="submit" round translateId={lSecurity('save')} />
            </div>
          </div>
        </Fragment>
      )}
    </form>
  );
};

ChangeLogin.propTypes = {
  login: PropTypes.string.isRequired,
  showChangeLoginBlock: PropTypes.bool.isRequired,
  cancelChangeLogin: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggleChangeLoginBlock: PropTypes.func.isRequired,
};

const form = 'securityChangeLoginForm';

export default reduxForm({
  form,
})(ChangeLogin);
