import { buildUrlParams, encodeDataURI } from 'shared/utils';
import apiServicesConstructor from 'services/apiService';

export const fetchCaptchaIdReq = () => async (dispatch, getState) => {
  const reqData = {
    T: 'laCaptcha.ReloadCaptcha',
    console: 'json',
  };

  const reqParams = {
    url: '/v2/cgi/bsi.dll',
    data: buildUrlParams(encodeDataURI(reqData)),
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchCaptchaImageReq = id => async (dispatch, getState) => {
  const reqParams = {
    url: `/webapi-1.0/bss/captcha/${id}`,
    responseType: 'blob',
  }

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};
