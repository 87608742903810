import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import styles from './Layout.module.scss';

class Authenticated extends Component {
  render() {
    const { children } = this.props;

    return (
      <Fragment>
        <Header />
        <div className={styles.Layout}>{children}</div>
        <Footer />
      </Fragment>
    );
  }
}

Authenticated.propTypes = {
  children: PropTypes.node,
};

export default Authenticated;
