import { createAction } from 'redux-act';
import { getTranslate } from 'react-localize-redux/lib/index';
import { showWarnNotification } from '../notification';
import { buildUrlParams, encodeDataURI, labelMaker, getErrorMessage } from 'shared/utils';
import apiServicesConstructor from 'services/apiService';
import { showOverlay, hideOverlay } from '../overlay';

import { fetchHistoryListReq, fetchHistoryDetailsReq, fetchNSPCHHistoryReq } from './operationsHistoryReq';

const lError = labelMaker('errorsGeneral');

const label = labelMaker('shared');

export const fetchHistoryListStart = createAction('FETCH_OPERATIONS_HISTORY_LIST_START');
export const fetchHistoryListSuccess = createAction('FETCH_OPERATIONS_HISTORY_LIST_SUCCESS');
export const fetchHistoryListError = createAction('FETCH_OPERATIONS_HISTORY_LIST_ERROR');

export const fetchHistoryDetailsStart = createAction('FETCH_OPERATIONS_HISTORY_DETAILS_START');
export const fetchHistoryDetailsSuccess = createAction('FETCH_OPERATIONS_HISTORY_DETAILS_SUCCESS');
export const fetchHistoryDetailsError = createAction('FETCH_OPERATIONS_HISTORY_DETAILS_ERROR');

export const fetchOperationsCatalogStart = createAction('FETCH_OPERATIONS_CATALOG_START');
export const fetchOperationsCatalogSuccess = createAction('FETCH_OPERATIONS_CATALOG_SUCCESS');

export const clearHistoryData = createAction('CLEAR_OPERATIONS_HISTORY_DATA');

export const fetchHistoryList = ({ dateFrom, dateTo, startAt = 0, limit = 10, isOnlineBank, sync }) => async (
  dispatch,
  getState
) => {
  dispatch(fetchHistoryListStart());
  dispatch(showOverlay());

  const { localize } = getState();
  const translate = getTranslate(localize);

  try {
    const history = await dispatch(fetchHistoryListReq({ dateTo, dateFrom, startAt, limit, sync, isOnlineBank }));

    dispatch(fetchHistoryListSuccess(history));
    dispatch(hideOverlay());
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(label('errors.historyLoad')));
    dispatch(showWarnNotification(errMessage));
    dispatch(fetchHistoryListError());
    dispatch(hideOverlay());
  }
};

export const fetchNSPCHistory = (reqData) => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);

  dispatch(fetchHistoryListStart());
  dispatch(showOverlay());
  try {
    const history = await dispatch(fetchNSPCHHistoryReq(reqData));

    dispatch(fetchHistoryListSuccess(history));
    dispatch(hideOverlay());
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(label('errors.historyLoad')));
    dispatch(showWarnNotification(errMessage));
    dispatch(hideOverlay());
  }
}

export const fetchHistoryDetails = (id, reqData) => async (dispatch, getState) => {
  dispatch(fetchHistoryDetailsStart(id));

  const { localize } = getState();
  const translate = getTranslate(localize);

  try {
    const details = await dispatch(fetchHistoryDetailsReq(id, reqData));
    dispatch(fetchHistoryDetailsSuccess({ id, details }));
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(fetchHistoryDetailsError(id));
  }
};

export const downloadFile = args => (dispatch, getState) => {
  const params = {
    url: `/webapi-1.0/render/pdf/operations-history?${buildUrlParams(encodeDataURI(args))}`,
    responseType: 'blob',
  };

  const { localize } = getState();
  const translate = getTranslate(localize);

  const apiServices = apiServicesConstructor(dispatch, getState);

  apiServices
    .get(params)
    .then(res => {
      const blob = new Blob([res], { type: 'text/csv;charset=utf-8' });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, 'operations-history.pdf');
      } else {
        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(blob);
        link.download = 'operations-history.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })
    .catch(() => {
      dispatch(showWarnNotification(translate(label('errors.fileDownloading'))));
    });
};

export const fetchOperationCatalog = () => (dispatch, getState) => {
  const params = {
    url: `/webapi-1.0/translate?module=26`,
  }

  const { localize } = getState();
  const translate = getTranslate(localize);
  dispatch(fetchOperationsCatalogStart()); // do nothing

  const apiServices = apiServicesConstructor(dispatch, getState);
  apiServices
    .get(params)
    .then(res => dispatch(fetchOperationsCatalogSuccess(res)))
    .catch(() => {
      dispatch(showWarnNotification(translate(lError('serviceUnavailable'))));
    });
}

export const fetchMaskedCardNumber = (id, onSuccess) => (dispatch, getState) => {
  const params = {
    url: `/webapi-1.0/card2/${id}`,
  }
  const { localize } = getState();
  const translate = getTranslate(localize);

  const apiServices = apiServicesConstructor(dispatch, getState);
  apiServices
    .get(params)
    .then(res => dispatch(onSuccess(res.cardMask)))
    .catch(() => {
      dispatch(showWarnNotification(translate(lError('serviceUnavailable'))));
    });
}

export const printOperationsHistory = args => (dispatch, getState) => {
  const apiServices = apiServicesConstructor(dispatch, getState);
  apiServices
    .get({ url: `/webapi-1.0/render/html/operations-history?${buildUrlParams(encodeDataURI(args))}` })
    .then(res => {
      const WinPrint = window.open('', '_blank', 'left=50,top=50,width=800,height=640,toolbar=0,scrollbars=1,status=0');
      WinPrint.document.write(res);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.onload = () => {
        WinPrint.print();
      };
    })
};
