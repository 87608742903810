/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';
import { ReactComponent as PaymentImage } from '../../../../assets/icons/icon-operation-document.svg';
import { formatNumber, labelMaker, get } from 'shared/utils';
import { SmoothDropDown, Translation, Spinner } from 'shared';

import styles from './HistoryListItem.module.scss';

const label = labelMaker('operationsHistory.details');

const getStatus = (status) => {
  switch(status) {
    case 'M': return label('statusDone');
    case 'O': return label('statusInProgress');
    default: return label('statusDenied');
  }
}

class HistoryListItem extends React.Component {
  state = { isOpen: false, maskedCardNumber: null };

  toggleHistoryDetail = id => () => {
    if (!this.state.isOpen && !this.props.details) {
      this.props.fetchHistoryDetails(id);
    }
    this.setState({ isOpen: !this.state.isOpen });
  };

  failedDetailsHandler = () => {
    const { detailsError, detailsLoading } = this.props;
    if (detailsLoading) {
      return (
        <div className={styles.DescriptionTable}>
          <Spinner />
        </div>
      );
    }
    if (detailsError) {
      return (
        <Translation.Div className={styles.EmptyPaymentDetails} translateId={label('emptyPaymentDetails')} />
      );
    }
    return '';
  };

  renderAllDetail = () => {
    const { item, catalog, locale } = this.props;
    const { allHistoryDetails } = item;
    const mccCategory = get(allHistoryDetails, 'mccDetails.categories');
    const status = get(allHistoryDetails, 'status');
    const name = get(allHistoryDetails, 'name');
    const { image, catId } = (mccCategory || [{}])[0];
    return (
      <table className={styles.DescriptionTable}>
        <tbody>
          {mccCategory && <tr className={styles.Tr}>
            <Translation.Td className={styles.TdInfo} translateId={label('category')} />
            <td className={styles.TdResult}>
              {image && <img alt="DetailItemImage" className={styles.DetailItemImage} src={window.location.host + image} />}
              {catId && (catalog.find(obj => obj.title === catId) || {})[`text${locale}`]}
            </td>
          </tr>}
          {status && <tr className={styles.Tr}>
            <Translation.Td
              className={styles.TdInfo}
              translateId={label('status')}
            />
            <Translation.Td className={styles.TdResult}translateId={getStatus(status)} />
          </tr>}
          {name && <tr className={styles.Tr}>
            <Translation.Td
              className={styles.TdInfo}
              translateId={label('title')}
            />
            <td className={styles.TdResult}>{name}</td>
          </tr>}
        </tbody>
      </table>
    )
  }

  renderOnlineDetail = () => {
    const { historyListDetails } = this.props.item;
    const { payerAccount, payerCard, comission, docName, currencyCode } = (historyListDetails || {});
    return (
      <table className={styles.DescriptionTable}>
        <tbody>
          {(payerAccount || payerCard) && <tr className={styles.Tr}>
            <Translation.Td className={styles.TdInfo} translateId={label('cardPayment')} />
            <td className={styles.TdResult}>
              {payerAccount || payerCard}
            </td>
          </tr>}
          {comission && <tr className={styles.Tr}>
            <Translation.Td
              className={styles.TdInfo}
              translateId={label('comission')}
            />
            <Translation.Td className={styles.TdResult}translateId={formatNumber(comission, { currency: currencyCode })} />
          </tr>}
          {docName && <tr className={styles.Tr}>
            <Translation.Td
              className={styles.TdInfo}
              translateId={label('descriptopns')}
            />
            <td className={styles.TdResult}>{docName}</td>
          </tr>}
        </tbody>
      </table>
    )
  }

  renderNSPCDetail = () => {
    const { maskedCardNumber } = this.state;
    const { item } = this.props;
    const { cbAccount, commission, cardId, narrative, counterparty } = (item || {});
    if (!cbAccount && cardId && !maskedCardNumber) {
      this.props.fetchMaskedCardNumber(cardId, (data) => this.setState({ maskedCardNumber: data }))
    }
    return (
      <table className={styles.DescriptionTable}>
        <tbody>
          {(cbAccount || cardId) && <tr className={styles.Tr}>
            <Translation.Td className={styles.TdInfo} translateId={label('cardPayment')} />
            <td className={styles.TdResult}>
              {cbAccount || maskedCardNumber}
            </td>
          </tr>}
          {commission && <tr className={styles.Tr}>
            <Translation.Td
              className={styles.TdInfo}
              translateId={label('commission')}
            />
            <div className={styles.TdResult}>{formatNumber(commission.amount, { currency: commission.currency })}</div>
          </tr>}
          {counterparty && counterparty.pam && <tr className={styles.Tr}>
            <Translation.Td
              className={styles.TdInfo}
              translateId={counterparty.direction === 'O' ? label('payee') : label('payer')}
            />
            <td className={styles.TdResult}>{counterparty.pam}</td>
          </tr>}
          {narrative && <tr className={styles.Tr}>
            <Translation.Td
              className={styles.TdInfo}
              translateId={label('descriptopns')}
            />
            <td className={styles.TdResult}>{narrative}</td>
          </tr>}
        </tbody>
      </table>
    )
  }

  renderDetails = () => {
    const { periodType } = this.props;
    switch(periodType) {
      case 1:
        return this.renderAllDetail();
      case 2:
        return this.renderOnlineDetail();
      case 3:
        return this.renderNSPCDetail();
      default:
        return  null;
    }
  }

  render() {
    const { dateFormat, date, id, amount, currency, details } = this.props;
    return (
      <div className={styles.HistoryItemContainer} onClick={this.toggleHistoryDetail(id)}>
        <div className={this.state.isOpen ? styles.HistoryDetailsOpen : styles.HistoryDetailsClose}>
          <div className={styles.InfoContainer}>
            <div className={styles.Mask}>
              <PaymentImage
                className={cn(styles.HistoryItemImage, { [styles.MirrorHorizontal]: amount < 0 })}
              />
            </div>
            <div className={styles.Info}>
              <div className={styles.Date}>{moment(date, 'YYYY-MM-DD').format(dateFormat)}</div>
            </div>
          </div>
          <div className={styles.InfoColBlock}>
            <div className={styles.Sum}>{formatNumber(amount, { currency })}</div>
          </div>
        </div>
        <SmoothDropDown isOpen={this.state.isOpen}>
          {details ? this.renderDetails() : this.failedDetailsHandler()}
        </SmoothDropDown>
      </div>
    );
  }
}

HistoryListItem.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  amount: PropTypes.number,
  currency: PropTypes.string,
  fetchHistoryDetails: PropTypes.func.isRequired,
  details: PropTypes.object,
  detailsLoading: PropTypes.bool,
  detailsError: PropTypes.bool,
};

export default HistoryListItem;
