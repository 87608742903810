import { timeout } from 'shared/utils';

export const fetchKYCStatusReqStub = async withError => {
  const fetchKYCStatusResponse = { status: 'RENEW', date: '2018-08-02T00:00:00.000Z' };

  const fetchKYCStatusError = {
    code: 500,
    message: 'Какая-то ошибка',
  };

  await timeout(1000);
  if (!withError) {
    console.info('fetchKYCStatusReqStub: ', fetchKYCStatusResponse);
    return Promise.resolve(fetchKYCStatusResponse);
  } else {
    console.info('fetchKYCStatusReqStub: ', fetchKYCStatusError);
    return Promise.reject(fetchKYCStatusError);
  }
};

export const changeEmailReqStub = async withError => {
  const changeEmailResponse = {
    success: true,
    seconds: 180,
  };

  const changeEmailError = {
    code: 500,
    message: 'Какая-то ошибка',
  };

  await timeout(1000);
  if (!withError) {
    console.info('changeEmailReqStub: ', changeEmailResponse);
    return Promise.resolve(changeEmailResponse);
  } else {
    console.info('changeEmailReqStub: ', changeEmailError);
    return Promise.reject(changeEmailError);
  }
};

export const verifyEmailReqStub = async withError => {
  const verifyEmailResponse = {
    success: true,
    seconds: 60,
  };

  const verifyEmailError = {
    code: 500,
    message: 'Какая-то ошибка',
  };

  await timeout(1000);
  if (!withError) {
    console.info('verifyEmailReqStub: ', verifyEmailResponse);
    return Promise.resolve(verifyEmailResponse);
  } else {
    console.info('verifyEmailReqStub: ', verifyEmailError);
    return Promise.reject(verifyEmailError);
  }
};

export const changePhoneReqStub = async withError => {
  const changePhoneResponse = {
    success: true,
    seconds: 180,
  };

  const changePhoneError = {
    code: 500,
    message: 'Какая-то ошибка',
  };

  await timeout(1000);
  if (!withError) {
    console.info('changePhoneReqStub: ', changePhoneResponse);
    return Promise.resolve(changePhoneResponse);
  } else {
    console.info('changePhoneReqStub: ', changePhoneError);
    return Promise.reject(changePhoneError);
  }
};

export const verifyPhoneReqStub = async withError => {
  const verifyPhoneResponse = {
    success: true,
    seconds: 60,
  };

  const verifyPhoneError = {
    code: 500,
    message: 'Какая-то ошибка',
  };

  await timeout(1000);
  if (!withError) {
    console.info('verifyPhoneReqStub: ', verifyPhoneResponse);
    return Promise.resolve(verifyPhoneResponse);
  } else {
    console.info('verifyPhoneReqStub: ', verifyPhoneError);
    return Promise.reject(verifyPhoneError);
  }
};
