import { createAction } from 'redux-act';
import { fetchAccountsReq } from './accountsRequests';
import { getErrorMessage } from 'shared/utils';

export const fetchAccountsStart = createAction('FETCH_ACCOUNTS_GLOBAL_START');
export const fetchAccountsSuccess = createAction('FETCH_ACCOUNTS_GLOBAL_SUCCESS');
export const fetchAccountsFailure = createAction('FETCH_ACCOUNTS_GLOBAL_FAILURE');

export const fetchAccounts = (forceUpdate = true) => async (dispatch, getState) => {
  try {
    dispatch(fetchAccountsStart());
    const accounts = await dispatch(fetchAccountsReq(forceUpdate));

    if (Array.isArray(accounts)) {
      dispatch(fetchAccountsSuccess(accounts));
      return Promise.resolve(accounts);
    }

    return Promise.reject();
  } catch (err) {
    dispatch(fetchAccountsFailure());
    return Promise.reject(getErrorMessage(err));
  }
};
