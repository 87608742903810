import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import onClickOutside from 'react-onclickoutside';

import { Translation } from 'shared';
import { logout } from 'actions/auth/index';
import { goTo } from 'actions/routes';

import styles from './Header.module.scss';

import { labelMaker } from 'shared/utils';

const label = labelMaker('header');

class HeaderMenu extends Component {
  handleClickOutside = () => {
    this.props.outsideClick();
  };

  onMouseDown = () => {
    const { goTo } = this.props;

    goTo('/profile/security');
  };

  render() {
    const { Div } = Translation;

    return (
      <div className={styles.UserMenu}>
        <Div
          className={styles.UserMenuItem}
          onMouseDown={this.onMouseDown}
          translateId={label('profileBtn')}
        />
        <Div
          className={styles.UserMenuItem}
          onMouseDown={this.props.logout}
          translateId={label('exitBtn')}
        />
      </div>
    );
  }
}

HeaderMenu.propTypes = {
  logout: PropTypes.func.isRequired,
  outsideClick: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  goTo,
  logout,
};

export default connect(
  null,
  mapDispatchToProps
)(onClickOutside(HeaderMenu));
