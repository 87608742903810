import { createReducer } from 'redux-act';

import {
  newMessageStart,
  newMessageError,
  postMessageStart,
  postMessageSuccess,
  postMessageError,
  createSmsStart,
  createSmsSuccess,
  createSmsError,
  confirmMessageStart,
  confirmMessageSuccess,
  confirmMessageError,
  resetSignature,
  showSignature,
  hideSignature,
  saveSecuritySignatureSuccess,
  save2FactorSignatureSuccess,
  signatureValueChange,
  toggleSignatureLoading,
} from 'actions/signature';
import { confirm2factorLoginFailure } from 'actions/auth';
const defaultState = {
  value: '',
  showSignatureBlock: false,

  settings: null,

  sms: {
    blockDateTime: null,
    message: null,
    requestsLeft: null,
    status: null,
    timeOut: null,
    timeOutLeft: null,
    timeOutStart: null,
  },

  isLoading: false,
};

const signature = createReducer(
  {
    [newMessageStart]: state => ({ ...defaultState, isLoading: true }),
    [newMessageError]: state => ({ ...defaultState, isLoading: false }),

    [postMessageStart]: state => ({ ...defaultState, isLoading: true }),
    [postMessageSuccess]: (state, payload) => ({
      ...state,
      settings: {
        ...payload.settings,
      },
      isLoading: false,
    }),
    [postMessageError]: state => ({ ...defaultState }),
    [createSmsStart]: state => ({ ...state, sms: defaultState.sms, isLoading: true }),
    [createSmsSuccess]: (state, smsData) => {
      const timeOutEnd =
        smsData.timeOut || smsData.timeOutLeft
          ? new Date(
              smsData.timeOutStart.getTime() + (smsData.timeOut || smsData.timeOutLeft) * 1000
            )
          : smsData.timeOutStart;
      return { ...state, isLoading: false, sms: { ...smsData, timeOutEnd } };
    },
    [createSmsError]: state => ({ ...state, sms: defaultState.sms, isLoading: false }),
    [confirmMessageStart]: state => ({ ...state, isLoading: true }),
    [confirmMessageSuccess]: state => ({ ...state, isLoading: false }),
    [confirmMessageError]: state => ({ ...state, value: '', isLoading: false }),
    [confirm2factorLoginFailure]: state => ({ ...state, value: '' }),
    [showSignature]: state => ({ ...state, showSignatureBlock: true }),
    [hideSignature]: state => ({ ...state, showSignatureBlock: false }),
    [resetSignature]: state => ({ ...defaultState }),
    [saveSecuritySignatureSuccess]: (state, payload) => ({
      ...state,
      settings: {
        ...payload.settings,
      },
    }),
    [save2FactorSignatureSuccess]: (state, payload) => ({
      ...state,
      settings: {
        ...payload.settings,
      },
    }),
    [signatureValueChange]: (state, value) => ({ ...state, value: value }),
    [toggleSignatureLoading]: (state, isLoading) => ({ ...state, isLoading }),
  },
  defaultState
);

export default signature;
