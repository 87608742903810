import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Translation, InputError, InputStatus, InputAction } from '../../';

import styles from './Input.module.scss';

class Input extends Component {
  componentDidUpdate(prevProps) {
    const { meta: currMeta, disabled: currDisabled } = this.props;
    const { disabled: prevDisabled } = prevProps;

    // If we disable focused input and undisable it, meta active
    // is true, but focus is not on element. We need to focus it.
    if (prevDisabled && !currDisabled && currMeta.active && this.input) {
      this.input.focus();
    }
  }

  render() {
    const {
      disabled,
      labelId,
      meta,
      input,
      type,
      statusElement,
      onChange,
      className,
      actionId,
      handleAction,
    } = this.props;
    const { active, touched, error } = meta;
    const isInputFocused = active && !disabled;
    const isErrorVisible = touched && error && !disabled;
    const isStatusSuccess = statusElement && touched && !error && !!input.value;

    const externalBorderClassName = cn(styles.ExternalBorder, {
      [styles.DisabledExternalBorder]: disabled,
      [styles.FocusedExternalBorder]: isInputFocused,
      [styles.ErrorExternalBorder]: isErrorVisible,
    });
    const internalBorderClassName = cn(styles.InternalBorder, {
      [styles.FocusedInternalBorder]: isInputFocused,
      [styles.ErrorInternalBorder]: isErrorVisible,
    });
    const labelClassName = cn(
      styles.Label,
      { [styles.FocusedLabel]: active || input.value },
      { [styles.DisabledLabel]: disabled }
    );

    const err = touched ? error : null;

    const standardRightPadding = 42;

    return (
      <div className={cn(styles.Container, { className: className })}>
        <Translation.Div className={labelClassName} translateId={labelId} />
        <div className={externalBorderClassName}>
          <div className={internalBorderClassName}>
            <input
              ref={input => (this.input = input)}
              autoComplete="off"
              className={styles.Input}
              disabled={disabled}
              type={type}
              {...input}
              onChange={onChange ? onChange : input.onChange}
              style={{
                paddingRight: standardRightPadding,
              }}
            />
          </div>
        </div>
        <InputStatus success={isStatusSuccess} />
        <InputAction actionId={actionId} handleAction={handleAction} />
        <InputError errorId={err} />
      </div>
    );
  }
}

Input.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.object.isRequired,
  labelId: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  statusElement: PropTypes.bool,
  type: PropTypes.string,
  actionId: PropTypes.string,
  handleAction: PropTypes.func,
};

Input.defaultProps = {
  disabled: false,
  showStatus: false,
  type: 'text',
};

export default Input;
