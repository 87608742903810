import { createReducer } from 'redux-act';

import {
  fetchCardsStart,
  fetchCardsSuccess,
  fetchCardsFailure,
  fetchCardsCatalogStart,
  fetchCardsCatalogSuccess,
  fetchCardsCatalogFailure,
} from 'actions/cards';

const defaultState = {
  data: null,
  catalog: null,
  isLoading: false,
};

const cards = createReducer(
  {
    [fetchCardsStart]: state => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [fetchCardsSuccess]: (state, cards) => {
      return {
        ...state,

        data: cards,
        isLoading: false,
      };
    },
    [fetchCardsFailure]: state => {
      return {
        ...state,
        isLoading: false,
      };
    },
    [fetchCardsCatalogStart]: state => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [fetchCardsCatalogSuccess]: (state, catalog) => {
      return {
        ...state,

        catalog,
        isLoading: false,
      };
    },
    [fetchCardsCatalogFailure]: state => {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
  defaultState
);

export default cards;
