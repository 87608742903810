import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { Button, Translation, Captcha } from 'shared';
import { labelMaker } from 'shared/utils';

import { options } from '../../formOptions';

import styles from './Step2.module.scss';

const label = labelMaker('auth');

class Step2 extends Component {
  isEqualToNewPassword = (value, values) =>
    value !== values.newPassword ? 'shared.errors.notEqualPasswords' : undefined;

  renderCaptchaBlock = () => {
    const { captcha, auth } = this.props;
    const { isRequestPending } = auth;

    return (
      captcha.isCaptchaRequired && (
        <div className={styles.CaptchaContainer}>
          <div className={styles.CaptchaRow}>
            <Captcha />
          </div>
          <div className={styles.CaptchaRow}>
            <Field {...options.newPasswordCaptcha} disabled={isRequestPending} />
          </div>
        </div>
      )
    );
  };

  render() {
    const { auth } = this.props;
    const { Div } = Translation;

    const { isRequestPending } = auth;

    return (
      <div className={styles.Step}>
        <Div className={styles.TextRow} translateId={label('createNewPassword')} />
        <div className={styles.InputContainer}>
          <Field {...options.newPassword} disabled={isRequestPending} />
        </div>
        <div className={styles.InputContainer}>
          <Field
            {...options.confirmedNewPassword}
            disabled={isRequestPending}
            validate={[...options.confirmedNewPassword.validate, this.isEqualToNewPassword]}
          />
        </div>

        {this.renderCaptchaBlock()}

        <Div className={styles.PasswordNotification} translateId={label('characterConstraint')} />
        <div className={styles.ButtonContainer}>
          <Button type="submit" round showLoader={isRequestPending}>
            <Translate id="auth.signinBtn" />
          </Button>
        </div>
      </div>
    );
  }
}

Step2.propTypes = {
  auth: PropTypes.object.isRequired,
  captcha: PropTypes.object.isRequired,
};

const mapStateToProps = ({ auth, captcha }) => ({
  auth,
  captcha,
});

export default connect(
  mapStateToProps,
  null
)(Step2);
