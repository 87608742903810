import apiServicesConstructor from 'services/apiService';
import { buildUrlParams, encodeDataURI } from 'shared/utils';
// const isDevelopment = process.env.NODE_ENV === 'development';
// const useGlobalStubs = process.env.REACT_APP_USE_STUBS === 'true';

export const fetchHistoryListReq = ({ sync, ...reqData }, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  //   if ((useStub || useGlobalStubs) && isDevelopment) {
  //     return await fetchHistoryListReqStub(withError);
  //   }

  const urlParams = buildUrlParams(encodeDataURI(reqData));
  let reqParams;

  if (sync) {
    const { dateFrom, dateTo } = reqData;
    reqParams = {
      url: '/webapi-1.0/products/histories/payments/sync',
      data: { dateFrom, dateTo, withDeletion: false },
      headers: { 'Content-Type': 'application/json' }
    };
  } else {
    reqParams = {
      url: `/webapi-1.0/products/histories/payments?${urlParams}`,
    };
  }

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = sync ? await apiServices.post(reqParams) : await apiServices.get(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchNSPCHHistoryReq = (reqData) => async (dispatch, getState) => {
  const urlParams = buildUrlParams(encodeDataURI(reqData));

  const reqParams = {
    url: `/webapi-1.0/payments/phone/history?${urlParams}`,
  };
  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
}

export const fetchHistoryDetailsReq = (id, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  //   if ((useStub || useGlobalStubs) && isDevelopment) {
  //     return await fetchHistoryDetailsReqStub(withError);
  //   }
  const reqParams = {
    url: `/webapi-1.0/products/histories/payments/${id}`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};
