import { required, isZero } from 'shared/validators';

const firstStepOptions = {
  sum: {
    label: '',
    value: 0,
    validators: [{ required }, { isZero }],
  },
};

export default firstStepOptions;
