import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { isNumericCode, isBackspaceCode, isNumberInRange } from 'shared/utils';

import styles from './CodeInput.module.scss';

class CodeInput extends React.Component {
  inputs = {};

  onKeyDown = e => {
    const { keyCode: code } = e;
    const { value: prevValue, disabled, onChange, fields } = this.props;
    e.preventDefault();

    if (isBackspaceCode(code)) {
      onChange({ name: 'code', value: prevValue.slice(0, -1) });
      return;
    }

    if (isNumericCode(code) && !disabled) {
      const newVal = isNumberInRange({ from: 96, to: 105 })(code)
        ? `${prevValue}${String.fromCharCode(code - 48)}`
        : `${prevValue}${String.fromCharCode(code)}`;

      if (fields >= newVal.length) {
        onChange({ name: 'code', value: newVal });
      }
    }
  };

  getFocusedValue = (value, fields) => {
    if (typeof value === 'undefined' || value.length === 0) {
      return 0;
    } else if (value.length < fields) {
      return value.length;
    } else {
      return -1;
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
    if (!this.props.disabled) {
      this.inputs[0].focus();
    } else {
      this.inputs[0].blur();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  componentDidUpdate() {
    const { value, fields, error } = this.props;
    if (error) {
      this.inputs[0].focus();
    } else if (typeof value === 'undefined' || value.length < fields) {
      this.inputs[this.getFocusedValue(value, fields)].focus();
    } else if (value.length === fields) {
      this.inputs[fields - 1].blur();
    }
  }

  render() {
    const { value, error, disabled, fields } = this.props;

    return (
      <div className={styles.CodeInput}>
        {Array.from(Array(parseInt(fields, 10))).map((field, i) => {
          return (
            <input
              type="number"
              ref={input => {
                this.inputs[i] = input;
              }}
              className={cn(styles.Input, {
                [styles.FocusedInput]: !disabled && value.length <= fields && value.length === i,
                [styles.ErrorInput]: error,
                [styles.DisabledInput]: disabled,
              })}
              key={i}
              value={value[i] || ''}
              onFocus={() =>
                !disabled && this.getFocusedValue(value, fields) === i
                  ? this.inputs[i].focus()
                  : this.inputs[i].blur()
              }
              onChange={() => {}} // to prevent warning in console, because used global event listener
            />
          );
        })}
      </div>
    );
  }
}

CodeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  fields: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disabled: PropTypes.bool,
};

export default CodeInput;
