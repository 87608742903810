import React from 'react';
import { connect } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';
import PropTypes from 'prop-types';

import LangSwitch from 'shared/LangSwitch/LangSwitch';
import { transliterate } from 'shared/utils';

import { ReactComponent as ProfileIcon } from 'assets/icons/icon-profile.svg';

import styles from './User.module.scss';

const User = props => {
  const {
    user: { fullname = '' },
    locale,
  } = props;
  let localizedFullName = locale === 'en' ? transliterate(fullname) : fullname;

  const [patronymic = '', name = '', surname = ''] = localizedFullName.split(' ');

  const fullName = `${name[0].toUpperCase()}${name
    .slice(1)
    .toLowerCase()} ${surname[0].toUpperCase()}${surname
    .slice(1)
    .toLowerCase()} ${patronymic[0].toUpperCase()}${patronymic.slice(1).toLowerCase()}`;

  return (
    <div className={styles.User}>
      <div className={styles.InfoColumn}>
        <ProfileIcon className={styles.Photo} />
        <div className={styles.Name}>{fullName}</div>
      </div>
      <LangSwitch />
    </div>
  );
};

User.propTypes = {
  user: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = ({ auth, localize }) => ({
  user: auth.user,
  locale: getActiveLanguage(localize).code,
});

export default connect(mapStateToProps)(User);
