import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchCaptchaId, cancelCaptchaFetch } from 'actions/captcha';

import loaderBlue from '../../assets/icons/loader-blue.png';
import loaderArrow from '../../assets/icons/icon-repeat.svg';
import styles from './Captcha.module.scss';

class Captcha extends Component {
  componentDidMount() {
    this.props.fetchCaptcha();
  }
  componentWillUnmount() {
    this.props.cancelCaptchaFetch();
  }

  reloadCaptcha = () => {
    const { isLoading, fetchCaptcha } = this.props;

    if (!isLoading) {
      fetchCaptcha();
    }
  };

  render() {
    const { isLoading, captchaScr } = this.props;

    return (
      <div className={styles.ImageContainer}>
        {captchaScr ? (
          <img className={styles.RecaptchaImage} src={captchaScr} alt="Код" />
        ) : (
          <div className={styles.CaptchaLoading} />
        )}
        <div className={styles.Content} onClick={this.reloadCaptcha}>
          <img
            className={isLoading ? styles.Rotating : styles.NotRotating}
            src={isLoading ? loaderBlue : loaderArrow}
            alt=""
          />
        </div>
      </div>
    );
  }
}

Captcha.propTypes = {
  fetchCaptcha: PropTypes.func.isRequired,
  cancelCaptchaFetch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  captchaScr: PropTypes.string,
};

const mapState = ({ captcha }) => ({
  isLoading: captcha.isLoading,
  captchaScr: captcha.captchaScr,
});

const mapDispatch = dispatch => ({
  fetchCaptcha: () => dispatch(fetchCaptchaId()),
  cancelCaptchaFetch: () => dispatch(cancelCaptchaFetch()),
});

export default connect(
  mapState,
  mapDispatch
)(Captcha);
