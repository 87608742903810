import Bowser from 'bowser';

const DETECT_PATH = '/settings/sbp';

const AppStores = {
  iOS: 'https://apps.apple.com/ru/app/%D1%8E%D0%BD%D0%B8%D0%BA%D1%80%D0%B5%D0%B4%D0%B8%D1%82-%D0%B1%D0%B0%D0%BD%D0%BA/id1486800854',
  Android: 'https://play.google.com/store/apps/details?id=ru.unicredit.android',
  Huawei: 'https://appgallery.huawei.com/#/app/C103291991',
};

export const MobileApps = {
  iOS: 'ios',
  Android: 'android',
  Huawei: 'huawei',
  Unknown: 'unknown',
};

export class MobileDetector {
  static parseUA(path) {
    if (path !== DETECT_PATH) {
      return MobileApps.Unknown;
    }

    const parsedUserAgent = Bowser.getParser(window.navigator.userAgent);
    const os = parsedUserAgent.getOSName(true);
    const platform = parsedUserAgent.getPlatform();

    let redirectUri = '';

    if (os === MobileApps.Android && platform?.vendor?.toLowerCase() === MobileApps.Huawei) {
      redirectUri = AppStores.Huawei;
    } else if (os === MobileApps.Android) {
      redirectUri = AppStores.Android;
    } else if (os === MobileApps.iOS) {
      redirectUri = AppStores.iOS;
    } else {
      return MobileApps.Unknown;
    }

    window.location.href = redirectUri;
  }
}
