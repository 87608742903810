import { createReducer } from 'redux-act';

import {
  showWarnNotification,
  showKYCNotification,
  showInfoNotification,
  hideNotification,
  hideKYCNotification,
  expandNotification,
  expandKYCNotification,
  collapseNotification,
  collapseKYCNotification,
  clearQueue,
  setMessage,
  showAntifraudNotification,
} from 'actions/notification';

const notifyState = {
  visible: false,
  expanded: false,
  message: '',
  link: {
    translateId: '',
    route: '',
  },
}

const defaultState = {
  kyc: {
    ...notifyState,
    type: 'kyc',
  },
  notification: {
    ...notifyState,
    type: 'warn',
  },
  queue: [],
};

const setQueue = (state, { kyc, notification }) => {
  const currNotify = [kyc, notification].filter(Boolean)[0];

  return {
    ...state,
    queue: [...state.queue].some((q) => q.message === currNotify.message)
      ? state.queue
      : [...state.queue, currNotify],
  }
};

const notification = createReducer(
  {
    [showWarnNotification]: (state, data) => {
      const content = {};
      if (typeof data === 'string') {
        content.message = data;
      }
      if (typeof data === 'object') {
        const { message, link, displayTime, isSetImmediately } = data;
        Object.assign(content, { message, link, displayTime, isSetImmediately });
      }

      return setQueue(state, {
        notification: {
          ...state.notification,
          type: 'warn',
          visible: true,
          ...content,
        },
      });
    },
    [showAntifraudNotification]: (state, data) => {
      const content = {};
      if (typeof data === 'string') {
        content.message = data;
      }
      if (typeof data === 'object') {
        const { message, link, displayTime, isSetImmediately } = data;
        Object.assign(content, { message, link, displayTime, isSetImmediately });
      }

      return setQueue(state, {
        notification: {
          ...state.notification,
          type: 'Antifraud',
          visible: true,
          ...content,
        },
      });
    },

    [showKYCNotification]: (state, data) => {
      const content = {};
      if (typeof data === 'string') {
        content.message = data;
      }
      if (typeof data === 'object') {
        const { message, link, displayTime, isSetImmediately } = data;
        Object.assign(content, { message, link, displayTime, isSetImmediately });
      }

      return setQueue(state, {
        notification: {
          ...state.notification,
          visible: true,
          type: 'kyc',
          ...content,
        },
      })
    },

    [showInfoNotification]: (state, data) => {
      const content = {};
      if (typeof data === 'string') {
        content.message = data;
      }
      if (typeof data === 'object') {
        const { message, link, displayTime, isSetImmediately } = data;
        Object.assign(content, { message, link, displayTime, isSetImmediately });
      }

      return setQueue(state, {
        notification: {
          ...state.notification,
          visible: true,
          type: 'info',
          ...content,
        },
      })
    },

    [setMessage]: (state, notification) => ({
      ...state,
      notification,
    }),

    [hideNotification]: (state, notification) => {
      let queue = state.queue;
      if (notification) {
        queue = queue.reduce((acc, q) => {
          if (q.message === notification.message) {return acc;}
          return [...acc, q];
        }, []);
      }
      return {
        ...state,

        notification: {
          ...defaultState.notification,
          type: state.notification.type,
        },
        queue,
      };
    },

    [hideKYCNotification]: state => {
      return {
        ...state,

        kyc: {
          ...state.kyc,

          visible: false,
          expanded: false,
        },
      };
    },

    [expandNotification]: state => {
      return {
        ...state,

        notification: {
          ...state.notification,

          expanded: true,
        },
      };
    },

    [expandKYCNotification]: state => {
      return {
        ...state,

        kyc: {
          ...state.kyc,

          expanded: true,
        },
      };
    },

    [collapseNotification]: state => {
      return {
        ...state,

        notification: {
          ...state.notification,

          expanded: false,
        },
      };
    },

    [collapseKYCNotification]: state => {
      return {
        ...state,

        kyc: {
          ...state.kyc,

          expanded: false,
        },
      };
    },

    [clearQueue]: state => ({
      ...state,
      queue: [],
    })
  },
  defaultState
);

export default notification;
