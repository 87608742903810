import React from 'react';
import PropTypes from 'prop-types';

import styles from './Modal.module.scss';

const ModalActions = props => {
  return (
    <div className={styles.ModalActions}>
      {props.children
        ? props.children.map((child, i) => (
            <div key={i} className={styles.ActionContainer}>
              {child}
            </div>
          ))
        : null}
    </div>
  );
};

ModalActions.propTypes = {
  children: PropTypes.array,
};

export default ModalActions;
