import { createAction } from 'redux-act';

import { extendSessionReq } from './sessionReq';

export const prolongeSession = createAction('PROLONG_SESSION');
export const setProlongationRequire = createAction('SET_PROLONGATION_REQUIRE');
export const resetProlongationRequire = createAction('RESET_PROLONGATION_REQUIRE');

export const updateSession = () => async dispatch => {
  try {
    await dispatch(extendSessionReq());
    dispatch(prolongeSession());
  } catch (err) {
    dispatch(resetProlongationRequire());
  }
};
