import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Button, Translation } from 'shared';
import { labelMaker, formatNumber, getTranslatedField2 } from 'shared/utils';

import styles from './Step3.module.scss';

const label = labelMaker('transfers');

class Step3 extends PureComponent {
  render() {
    const { form, transfers, locale } = this.props;

    const { cards, payment, beneficiaryBank } = form.transfers.values;
    const bank = transfers.banks.banks.find(bank => bank.title === beneficiaryBank);
    const bankName = getTranslatedField2(bank, 'text', { locale });

    return (
      <Fragment>
        <div className={styles.CurrencyTable}>
          <div className={styles.ThRow}>
            <Translation.Div
              className={styles.ThColInfo}
              translateId={label('titles.chargeSource')}
            />
            <div className={styles.ThColResult}>{cards}</div>
          </div>
          <div className={styles.ThRow}>
            <Translation.Div
              className={styles.ThColInfo}
              translateId={label('titles.transferAmount')}
            />
            <div className={styles.ThColResult}>{formatNumber(payment, { currency: 'RUR' })}</div>
          </div>
          <div className={styles.TdRow}>
            <Translation.Div className={styles.TdColInfo} translateId={label('titles.recipient')} />
            <div className={styles.TdColResult}>{transfers.transferForm.beneficiaryPAM}</div>
          </div>
          <div className={styles.TdRow}>
            <Translation.Div className={styles.TdColInfo} translateId={label('titles.fee')} />
            <Translation.Div
              className={styles.TdColResult}
              translateId={label('titles.noCharge')}
            />
          </div>
          <div className={styles.TdRow}>
            <Translation.Div
              className={styles.TdColInfo}
              translateId={label('titles.beneficiaryBank')}
            />
            <div className={styles.TdColResult}>{bankName}</div>
          </div>
        </div>

        <div className={styles.ButtonContainer}>
          <Button
            type="submit"
            translateId={label('btns.continue')}
            disabled={transfers.transferForm.disabled}
            showLoader={transfers.transferForm.isLoading}
          />
        </div>
      </Fragment>
    );
  }
}

Step3.propTypes = {
  transfers: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
};

export default Step3;
