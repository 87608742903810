import React from 'react';
import { DateRange } from 'react-date-range';
import { ru, enGB } from 'react-date-range/dist/locale';
// import es from 'react-date-range/locale/es';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';

import './DateRange.scss';

const getLocale = language => {
  const locales = {
    ru,
    en: enGB,
  };
  return locales[language] || ru;
};

/* eslint-disable no-shadow */
const DateRangeWrapper = ({ range, onChange, language, ...rest }) => {
  const onWrappedChange = range => {
    onChange({ range: range.selection });
  };

  return (
    <DateRange locale={getLocale(language)} onChange={onWrappedChange} ranges={[range]} {...rest} />
  );
};
/* eslint-enable no-shadow */

DateRangeWrapper.propTypes = {
  language: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  range: PropTypes.object,
};

const mapStateToProps = ({ localize }) => ({
  language: getActiveLanguage(localize).code,
});

export default connect(mapStateToProps)(DateRangeWrapper);
