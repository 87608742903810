import React, { PureComponent } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import styles from './Transfers.module.scss';
import { Page404, withErrorBoundary } from 'shared';

import FastPay from './components/FastPay';
import CardTransfer from './components/CardTransfer';
import TabMenu from './components/TabMenu';

class Transfers extends PureComponent {
  render() {
    return (
      <div className={styles.Transfers}>
        <div className={styles.Menu}>
          <TabMenu />
        </div>
        <div className={styles.HeaderSeparator} />
        <div className={styles.Body}>
          <div className={styles.BodyContent}>
            <Switch>
              <Redirect exact from="/transfers" to="/transfers/fast" />
              <Route path="/transfers/fast" component={FastPay} />
              <Route path="/transfers/card" component={CardTransfer} />
              <Route path="/transfers/404" component={Page404} />
              <Redirect to={'/transfers/404'} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withLocalize(withErrorBoundary(Transfers, 'Transfers')));
