import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

const H6 = ({ children, translateId, data, ...rest }) => {
  return (
    <h6 {...rest} data-translate-id={translateId}>
      {translateId ? <Translate id={translateId} data={data} /> : children}
    </h6>
  );
};

H6.propTypes = {
  children: PropTypes.node,
  translateId: PropTypes.string.isRequired,
  data: PropTypes.object,
};

export default H6;
