import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { initializeTransfers, destroyTransfers } from 'actions/transfers';
import { resetSignature } from 'actions/signature';

import TransferForm from './TransferForm';

class FastPay extends Component {
  componentDidMount() {
    this.props.initializeTransfers();
  }

  componentWillUnmount() {
    this.props.destroyTransfers();
    this.props.resetSignature();
  }

  render() {
    return <TransferForm />;
  }
}

const mapDispatch = {
  initializeTransfers,
  destroyTransfers,
  resetSignature,
};

FastPay.propTypes = {
  destroyTransfers: PropTypes.func.isRequired,
  initializeTransfers: PropTypes.func.isRequired,
  resetSignature: PropTypes.func.isRequired,
};

export default connect(
  null,
  mapDispatch
)(FastPay);
