import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import cn from 'classnames';

import styles from './styles.module.scss';

class WithPopup extends Component {
  getPopupPositionCn() {
    const { bottom, left, right, top } = this.props;

    if (bottom) {
      return styles.BottomPopup;
    }
    if (left) {
      return styles.LeftPopup;
    }
    if (right) {
      return styles.RightPopup;
    }
    if (top) {
      return styles.TopPopup;
    }
  }

  render() {
    const { translate, translateId, description, children } = this.props;

    const info = description ? description : translate(translateId);

    return (
      <div className={styles.Container}>
        {children}
        <div className={cn(styles.PopupContainer, this.getPopupPositionCn())}>{info}</div>
      </div>
    );
  }
}

const mapStateToProps = ({ localize }) => ({
  translate: getTranslate(localize),
});

WithPopup.propTypes = {
  children: PropTypes.node,
  description: PropTypes.string,
  translateId: PropTypes.string,
  translate: PropTypes.func.isRequired,
  bottom: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  top: PropTypes.bool,
};

export default connect(mapStateToProps)(WithPopup);
