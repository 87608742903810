import apiServicesConstructor from 'services/apiService';
import Bowser from 'bowser';
import {
  changeLoginReqStub,
  changePasswordReqStub,
  fetchChangePasswordSettingsReqStub,
  fetchChangeScratchCardSettingsReqStub,
  fetchSecureSettingsReqStub,
  setProtectionReqStub,
  changeScratchCardReqStub,
} from './securityStubs';

const isDevelopment = process.env.NODE_ENV === 'development';
const useGlobalStubs = process.env.REACT_APP_USE_STUBS === 'true';

export const fetchSecureSettingsReq = (useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await fetchSecureSettingsReqStub(withError);
  }

  const reqParams = {
    url: `/webapi-1.0/bss/addProtection`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchChangePasswordSettingsReq = (useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await fetchChangePasswordSettingsReqStub(withError);
  }

  const reqParams = {
    url: '/webapi-1.0/bss/getChangePasswordConfig',
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const changeLoginReq = (data, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await changeLoginReqStub(withError);
  }

  const browser = Bowser.getParser(window.navigator.userAgent).getBrowser();
  const deviceInfo = encodeURI(JSON.stringify({
    browser: browser?.name,
    browserVersion:`${browser?.name} ${browser?.version}`,
    type: "web_lk"
  }))
  
  const reqParams = {
    url: '/webapi-1.0/bss/changeLogin',
    withCredentials: false,
    headers: { 'Content-Type': 'application/json',"Device-Info": deviceInfo },
    data,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const changePasswordReq = (data, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await changePasswordReqStub(withError);
  }

  const browser = Bowser.getParser(window.navigator.userAgent).getBrowser();
  const deviceInfo = encodeURI(JSON.stringify({
    browser: browser?.name,
    browserVersion:`${browser?.name} ${browser?.version}`,
    type: "web_lk"
  }))

  const reqParams = {
    url: '/webapi-1.0/bss/changePassword',
    withCredentials: false,
    headers: { 'Content-Type': 'application/json', "Device-Info": deviceInfo },
    data,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const setProtectionReq = (data, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await setProtectionReqStub(withError);
  }
  const reqParams = {
    url: '/webapi-1.0/bss/addProtectionSave',
    withCredentials: false,
    headers: { 'Content-Type': 'application/json' },
    data,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchChangeScratchCardSettingsReq = (useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await fetchChangeScratchCardSettingsReqStub(withError);
  }

  const reqParams = {
    url: '/webapi-1.0/bss/changeTan',
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const changeScratchCardReq = (data, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await changeScratchCardReqStub(withError);
  }

  const reqParams = {
    url: '/webapi-1.0/bss/changeTanWithPSW',
    headers: { 'Content-Type': 'application/json' },
    data,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};
