import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router';
import { getTranslate } from 'react-localize-redux';
import { Translation, Card, withErrorBoundary } from 'shared';
import { goTo } from 'actions/routes';
import CreditCard from './CreditCard/CreditCard';
import UfmBlockModal from "./UfmBlockModal"
import EmptyCreditsList from 'shared/EmptyList/EmptyCreditsList';
import styles from './Products.module.scss';

import { formatNumber, labelMaker } from 'shared/utils';

import HiddenCreditsDropdown from './Loans/components/HiddenCreditsDropdown';

const label = labelMaker('products.page');

class Products extends Component {
  state = { isHiddenDisplayed: false };

  toggleHiddenCredits = () => {
    this.setState({ isHiddenDisplayed: !this.state.isHiddenDisplayed });
  };

  getCreditsOutstanding = (credits, ccy) => {
    return credits.reduce((acc, credit) => {
      if (credit.ccy === ccy) {
        acc += parseFloat(credit.principal);
      }
      return acc;
    }, 0);
  };

  renderOutstandingAmount = credits => {
    const currencies = credits.reduce((acc, credit) => {
      if (!acc.some(ccy => credit.ccy === ccy)) {
        acc.push(credit.ccy);
        return acc;
      }
      return acc;
    }, []);

    const outstandings = currencies.reduce((acc, currency) => {
      const creditOutstanding = this.getCreditsOutstanding(credits, currency);
      acc.push(formatNumber(creditOutstanding, { currency: currency }));

      return acc;
    }, []);

    return (
      <div className={styles.OutstandingWrapper}>
        <div className={styles.Outstanding}>
          <span>{this.props.translate(label('outstanding'))}</span>
          &nbsp;
          {outstandings.map(outstanding => (
            <span key={outstanding}>
              {outstanding}
              &nbsp;
            </span>
          ))}
        </div>
      </div>
    );
  };

  renderCredits(credits) {
    const creditsList =
      Array.isArray(credits) &&
      credits
        .filter(loan => loan.visibilityRule >= 2)
        .map((loan, i) => <CreditCard key={loan.id} credit={loan} />);

    if (creditsList && creditsList.length > 0) {
      return creditsList;
    } else {
      return <EmptyCreditsList />;
    }
  }

  renderHiddenCredits(credits) {
    const hiddenCreditsList =
      Array.isArray(credits) &&
      credits
        .filter(loan => loan.visibilityRule < 2)
        .map((loan, i) => <CreditCard key={loan.id} credit={loan} />);

    if (hiddenCreditsList && hiddenCreditsList.length > 0) {
      return (
        <HiddenCreditsDropdown
          toggleHiddenCredits={this.toggleHiddenCredits}
          isHiddenDisplayed={this.state.isHiddenDisplayed}
          hiddenCredits={hiddenCreditsList}
        />
      );
    }
  }

  render() {
    const { products } = this.props;
    const { credits } = products;

    const isEmpty = !(Array.isArray(credits) && credits.length > 0);

    const { Div } = Translation;
    return (
      <>
        <UfmBlockModal />
        <Card>
          <div className={styles.CardHeader}>
            <Div className={styles.ProductsTitle} translateId={label('title')} />
            {!isEmpty && this.renderOutstandingAmount(credits)}
          </div>
          {}
          <div className={styles.CardSeparator} />

          <div className={styles.CardBody}>
            {this.renderCredits(credits)}
            {this.renderHiddenCredits(credits)}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.unicreditbank.ru/ru/personal.html#%D0%9A%D1%80%D0%B5%D0%B4%D0%B8%D1%82%D1%8B"
            >
              <div className={styles.AddCreditButton}>
                <div className={styles.AddCreditMask}>
                  <div className={styles.AddCreditImg} />
                </div>
                <Div className={styles.AddCreditTitle} translateId={label('loanApply')} />
              </div>
            </a>
          </div>
        </Card>
      </>
    );
  }
}

Products.propTypes = {
  translate: PropTypes.func.isRequired,
  products: PropTypes.object.isRequired,
  goTo: PropTypes.func.isRequired,
};

const mapState = ({ products, localize }) => ({
  products,
  translate: getTranslate(localize),
});

const mapDispatch = {
  goTo,
};

export default connect(
  mapState,
  mapDispatch
)(withRouter(withErrorBoundary(Products, 'Products')));
