import axios from 'axios';
import { push } from 'react-router-redux';
import { getTranslate } from 'react-localize-redux/lib/index';

import { logoutSuccess } from 'actions/auth';
import { showInfoNotification, showWarnNotification } from 'actions/notification';
import { prolongeSession } from 'actions/session';
// временно скрываем чат https://jira.intranet/browse/DC-5142
// import { resetChat } from 'actions/chat';
import settings from './serviceSettings';
import { labelMaker } from 'shared/utils';
import isObject from 'lodash/isObject';

// axios.defaults.xsrfCookieName = 'csrftoken'

const lErrors = labelMaker('errorsGeneral');

export default (dispatch, getState) => {
  const composeUrl = url => {
    if (url && url[0] !== '/') {
      return url;
    }
    return `${settings.baseURL}${url}`;
  };
  const checkSuccessfulHttpStatus = res => {
    return res.data;
  };

  const checkFailureHttpStatus = (res, throwFullError) => {
    try {
      if (res.response) {
        const { status, data } = res.response;
        const { message: error } = res;
        const otherError = {
          message: error,
          code: status,
        };
        if (status) {
          const { localize } = getState();
          const translate = getTranslate(localize);

          switch (status) {
            case 400:
              const error400 = {
                message: translate(lErrors('400')),
                code: status,
              };
              throw error400;

            case 401:
              const error401 = {
                message: translate(lErrors('401')),
                code: status,
              };

              const user = localStorage.getItem('auth');
              localStorage.removeItem('auth');

              dispatch(logoutSuccess());
              // dispatch(resetChat());
              dispatch(push('/login'));

              if (user) {
                dispatch(showInfoNotification(error401.message));
              }
              return;

            case 403:
              if (isObject(data)) {
                const { code, message } = data?.error;

                if (code === 103 || code === 104) {
                  const error103 = {
                    message: message,
                    code: code,
                  };
                  dispatch(showWarnNotification(error103.message));
                  throw error103;
                }
              }

              throw throwFullError ? res.response : otherError;

            case 500:
              if (data.error) {
                data.error.code = 500;
                data.error.message = translate(lErrors('500'));
              } else {
                data.error = {
                  code: 500,
                  message: translate(lErrors('500')),
                };
              }

              throw data.error;

            case 503:
              const error503 = {
                message: translate(lErrors('503')),
                code: status,
              };
              throw error503;

            default:
              throw throwFullError ? res.response : otherError;
          }
        }
      } else {
        throw res.message;
      }
    } catch (err) {
      throw err;
    }
  };

  const checkSessionToken = res => {
    //   debugger;
    return res;
  };

  const defaultSettings = {
    headers: settings.headers,
    withCredentials: true,
  };
  const { requireProlongation } = getState().session;

  return {
    post: ({ url, data, throwFullError, ...settings }) =>
      (() => {
        if (requireProlongation) {
          dispatch(prolongeSession());
        }

        return axios
          .post(composeUrl(url), data, { ...defaultSettings, ...settings })
          .then(checkSessionToken)
          .then(checkSuccessfulHttpStatus)
          .catch(error => checkFailureHttpStatus(error, throwFullError));
      })(),
    delete: ({ url, data, throwFullError, ...settings }) =>
      (() => {
        if (requireProlongation) {
          dispatch(prolongeSession());
        }

        return axios
          .delete(composeUrl(url), { ...defaultSettings, ...settings })
          .then(checkSessionToken)
          .then(checkSuccessfulHttpStatus)
          .catch(error => checkFailureHttpStatus(error, throwFullError));
      })(),
    get: ({ url, data, throwFullError, ...settings }) =>
      (() => {
        if (requireProlongation) {
          dispatch(prolongeSession());
        }

        return axios
          .get(composeUrl(url), { ...defaultSettings, ...settings })
          .then(checkSessionToken)
          .then(checkSuccessfulHttpStatus)
          .catch(error => checkFailureHttpStatus(error, throwFullError));
      })(),
    put: ({ url, data, throwFullError, ...settings }) =>
      (() => {
        if (requireProlongation) {
          dispatch(prolongeSession());
        }

        return axios
          .put(composeUrl(url), data, { ...defaultSettings, ...settings })
          .then(checkSessionToken)
          .then(checkSuccessfulHttpStatus)
          .catch(error => checkFailureHttpStatus(error, throwFullError));
      })(),
    patch: ({ url, data, throwFullError, ...settings }) =>
      (() => {
        if (requireProlongation) {
          dispatch(prolongeSession());
        }

        return axios
          .patch(composeUrl(url), data, { ...defaultSettings, ...settings })
          .then(checkSessionToken)
          .then(checkSuccessfulHttpStatus)
          .catch(error => checkFailureHttpStatus(error, throwFullError));
      })(),
  };
};
