import { createAction } from 'redux-act';
import { getTranslate } from 'react-localize-redux';

import { showWarnNotification, hideNotification } from '../notification';
import { bonusesReq, operationsReq } from './bonusesRequests';

import { getErrorMessage, labelMaker } from 'shared/utils';

export const initializeBonusesPageEnd = createAction('INITIALIZE_BONUSES_PAGE_END');
export const initializeBonusesPageStart = createAction('INITIALIZE_BONUSES_PAGE_START');
export const initializeOffersError = createAction('INITIALIZE_OFFERS_ERROR');
export const initializeOperationsError = createAction('INITIALIZE_OPERATIONS_HISTORY_ERROR');
export const selectOffer = createAction('SELECT_OFFER');
export const setOffers = createAction('SET_OFFERS');
export const setOperationsHistory = createAction('SET_OPERATIONS_HISTORY');

const lError = labelMaker('errorsGeneral');

export const initializeOffers = () => async (dispatch, getState) => {
  const { localize, bonuses } = getState();
  const translate = getTranslate(localize);
  const { offers } = bonuses;
  try {
    if (!offers) {
      const response = await dispatch(bonusesReq());
      if (Array.isArray(response)) {
        dispatch(hideNotification());
        dispatch(setOffers(response));
      } else {
        throw translate(lError('serviceUnavailable'));
      }
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(initializeOffersError(errMessage));
    dispatch(showWarnNotification(errMessage));
  }
};

export const initializeBonusesPage = () => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(initializeBonusesPageStart());

    const response = await dispatch(operationsReq());

    if (Array.isArray(response)) {
      dispatch(hideNotification());
      dispatch(setOperationsHistory(response));
      dispatch(initializeBonusesPageEnd());
    } else {
      throw translate(lError('serviceUnavailable'));
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(initializeOperationsError(errMessage));
    dispatch(showWarnNotification(errMessage));
    dispatch(initializeBonusesPageEnd());
  }
};
