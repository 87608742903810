import { reduxFieldPhone1, reduxFieldEmail } from 'shared/validators';
import { ReduxInput, ReduxPhoneInput1 } from 'shared';
import { phoneNormalizer, phoneFormatter } from 'shared/normalizers';
import { labelMaker } from 'shared/utils';

const label = labelMaker('profile.personal');

const fieldsOptions = {
  phone: {
    labelId: label('phoneLabel'),
    component: ReduxPhoneInput1,
    placeholder: '(564) 343-42-45',
    normalize: phoneNormalizer,
    format: phoneFormatter,
    name: 'phone',
    disabled: true,
  },
  newPhone: {
    labelId: label('newPhoneLabel'),
    component: ReduxPhoneInput1,
    placeholder: '(564) 343-42-45',
    normalize: phoneNormalizer,
    format: phoneFormatter,
    name: 'newPhone',
    statusElement: true,
    validate: [reduxFieldPhone1],
  },
  phoneCode: {
    labelId: label('phoneCodeLabel'),
    component: ReduxInput,
    name: 'phoneCode',
  },
  email: {
    labelId: label('emailLabel'),
    component: ReduxInput,
    name: 'email',
    disabled: true,
  },
  newEmail: {
    labelId: label('newEmailLabel'),
    component: ReduxInput,
    name: 'newEmail',
    statusElement: true,
    validate: [reduxFieldEmail],
  },
  emailCode: {
    labelId: label('emailCodeLabel'),
    component: ReduxInput,
    name: 'emailCode',
  },
};

export default fieldsOptions;
