import { createSelector } from 'reselect';

const getAccounts = state => state.accounts.data;

export const accountsSelector = createSelector(
  getAccounts,
  accounts => {
    if (!accounts) {
      return [];
    }
    return accounts;
  }
);
