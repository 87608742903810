import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './Timer.module.scss';

class Timer extends Component {
  constructor(props) {
    const { count } = props;
    super(props);

    this.state = {
      count,
    };
  }

  componentDidMount() {
    this.timer = setInterval(this.tick, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  tick = () => {
    const { onOver } = this.props;
    const { count } = this.state;

    if (count === 1) {
      clearInterval(this.timer);
      onOver();
    } else {
      this.setState({ count: count - 1 });
    }
  };

  render() {
    const { isOver } = this.props;
    const { count } = this.state;

    return !isOver ? <span className={styles.Timer}>{count}</span> : null;
  }
}

Timer.propTypes = {
  count: PropTypes.number.isRequired,
  onOver: PropTypes.func,
  isOver: PropTypes.bool,
};

Timer.defaultProps = {
  onOver: () => {},
};

export default Timer;
