import React, { Component } from 'react';
// import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';

import Header from 'components/Header/Header';

// import BonusesContentHeader from '../components/Bonuses/components/BonusesContentHeader/BonusesContentHeader';
// import Discounts from '../components/Bonuses/Discounts/Discounts';
// import Offers from '../components/Bonuses/Offers/Offers';
// import History from '../components/Bonuses/History/History';
import {
  // Page404,
  withNotification,
  withKYCNotification,
  //  Spinner
} from 'shared';
import { initializeBonusesPage } from 'actions/bonuses/index';

import bonusesTranslations from 'components/Bonuses/bonuses.json';
import HistoryTranslations from 'components/Bonuses/History/History.json';

// import DiscountDetails from '../components/Bonuses/components/DiscountDetails/DiscountDetails';

import styles from './index.module.scss';

class Bonuses extends Component {
  constructor(props) {
    super(props);

    props.addTranslation(bonusesTranslations);
    props.addTranslation(HistoryTranslations);
  }

  componentDidMount() {
    const { initializeBonusesPage } = this.props;

    initializeBonusesPage();
  }

  render() {
    const baseIframeUrl = `https://unicreditframe.sweetcard.com/offers?uri=unicredit&clientid=${
      this.props.auth.user.abscustid
    }`;

    return (
      <div className={styles.Layout}>
        <Header />
        <iframe className={styles.IFrame} title="best2pay" src={baseIframeUrl} />
      </div>
    );
  }
}

Bonuses.propTypes = {
  location: PropTypes.object.isRequired,
  initializeBonusesPage: PropTypes.func.isRequired,
  addTranslation: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  auth: PropTypes.object,
};

const mapStateToProps = ({ bonuses, auth }) => ({
  isLoading: bonuses.isLoading,
  isLoaded: bonuses.isLoaded,
  bonuses: bonuses,
  auth,
});

const mapDispatchToProps = {
  initializeBonusesPage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withKYCNotification(withNotification(withLocalize(Bonuses))));
