import apiServicesConstructor from 'services/apiService';
import { buildUrlParams, encodeDataURI } from 'shared/utils';
import {
  fetchKYCStatusReqStub,
  changeEmailReqStub,
  changePhoneReqStub,
  verifyEmailReqStub,
  verifyPhoneReqStub,
} from './profileStubs';

const isDevelopment = process.env.NODE_ENV === 'development';
const useGlobalStubs = process.env.REACT_APP_USE_STUBS === 'true';

export const fetchKYCStatusReq = (useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub && isDevelopment) || useGlobalStubs) {
    return await fetchKYCStatusReqStub(withError);
  }

  const reqParams = {
    url: '/webapi-1.0/kyc',
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const changeEmailReq = (email, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub && isDevelopment) || useGlobalStubs) {
    return await changeEmailReqStub(withError);
  }

  const urlParams = buildUrlParams(encodeDataURI({ email }));

  const reqParams = {
    url: `/webapi-1.0/kyc/email-changing?${urlParams}`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const verifyEmailReq = (code, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub && isDevelopment) || useGlobalStubs) {
    return await verifyEmailReqStub(withError);
  }

  const data = buildUrlParams(encodeDataURI({ code }));
  const reqParams = {
    url: `/webapi-1.0/kyc/verify-email`,
    data,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const changePhoneReq = (phone, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub && isDevelopment) || useGlobalStubs) {
    return await changePhoneReqStub(withError);
  }

  const urlParams = buildUrlParams(encodeDataURI({ phone }));

  const reqParams = {
    url: `/webapi-1.0/kyc/phone-changing?${urlParams}`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const verifyPhoneReq = (code, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub && isDevelopment) || useGlobalStubs) {
    return await verifyPhoneReqStub(withError);
  }

  const data = buildUrlParams(encodeDataURI({ code }));
  const reqParams = {
    url: `/webapi-1.0/kyc/verify-phone`,
    data,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};
