import apiServicesConstructor from 'services/apiService';
import moment from 'moment';
import Bowser from 'bowser';

import { updateKYCReqStub, customerListReqStub } from './personalStubs';

const isDevelopment = process.env.NODE_ENV === 'development';
const useGlobalStubs = process.env.REACT_APP_USE_STUBS === 'true';

export const updateKYCReq = (
  { confirmationType, isDirty, userData },
  useStub = false,
  withError = false
) => async (dispatch, getState) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await updateKYCReqStub(withError);
  }

  const data = isDirty
    ? {
      confirmationType,
      ...userData,
    }
    : {
      number: 'EUC234523452',
      applicationDate: moment().format('YYYY-MM-DD'),
      confirmationType,
      sourceChannelReferenceDto: {
        channelName: 'ENTERIMB',
        channelDocID: 'EUC1045656',
        receiveTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
        userData,
      },
    };

  const browser = Bowser.getParser(window.navigator.userAgent).getBrowser();
  const deviceInfo = encodeURI(JSON.stringify({
    browser: browser?.name,
    browserVersion: `${browser?.name} ${browser?.version}`,
    type: "web_lk"
  }))
  
  const reqParams = {
    url: '/webapi-1.0/kyc/customer-verification',
    data,
    headers: { 'Content-Type': 'application/json', "Device-Info": deviceInfo },
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const customerListReq = (forceUpdate, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await customerListReqStub(withError);
  }

  const reqParams = { url: `/webapi-1.0/kyc/customer-list?forceUpdate=${forceUpdate}` };
  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};
