import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './Toggle.module.scss';

const Toggle = props => {
  const onClick = (name, value) => () => {
    if (!props.disabled) {
      props.onChange({ name, value: !value });
    }
  };

  return (
    <div
      onClick={onClick(props.name, props.value)}
      className={cn([
        styles.Container,
        {
          [styles.ActiveContainer]: props.value && !props.small,
          [styles.DisabledContainer]: props.disabled,
          [styles.SmallContainer]: props.small,
          [styles.ActiveSmallContainer]: props.value && props.small,
          [styles.ActiveRedContainer]: props.value && props.red,
        },
      ])}
    >
      <div className={cn(styles.Slider, { [styles.SmallSlider]: props.small })} />
    </div>
  );
};

Toggle.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disabled: PropTypes.bool,
  name: PropTypes.string,
  small: PropTypes.bool,
  red: PropTypes.bool,
};

export default Toggle;
