import { reduxForm } from 'redux-form';

import { ReduxInput, ReduxDateInput, ReduxSelect } from 'shared';
import { date as dateMask } from 'shared/masks';

import { labelMaker } from 'shared/utils';
import { reduxFieldRequired } from 'shared/validators';
import { lessThan } from 'shared/normalizers';

const formLabel = labelMaker('pwdRecovery');

export const options = {
  passportSeries: {
    component: ReduxInput,
    labelId: formLabel('passportSeries'),
    name: 'passportSeries',
    validate: reduxFieldRequired,
  },
  passportNumber: {
    component: ReduxInput,
    labelId: formLabel('passportNumber'),
    name: 'passportNumber',
    validate: reduxFieldRequired,
  },
  birthday: {
    component: ReduxDateInput,
    labelId: formLabel('birthday'),
    name: 'birthday',
    placeholder: '01.04.1997',
    mask: dateMask,
  },
  captcha: {
    component: ReduxInput,
    labelId: formLabel('captcha'),
    name: 'captcha',
    normalize: lessThan(10),
    validate: reduxFieldRequired,
  },

  controlQuestion: {
    component: ReduxSelect,
    labelId: formLabel('controlQuestion'),
    name: 'controlQuestion',
  },
  answer: {
    component: ReduxInput,
    labelId: formLabel('answer'),
    name: 'answer',
    mask: dateMask,
    placeholder: '01.04.1997',
    validate: reduxFieldRequired,
  },
};

const initialValues = {
  passportSeries: '',
  passportNumber: '',
  birthday: '',
  captcha: '',
  controlQuestion: 1,
  answer: '',
};
const form = 'passwordRecovery';

const formInitializer = reduxForm({
  form,
  initialValues,
});

export default formInitializer;
