import React from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';

import { Modal, ModalHeader, ModalBody, ModalActions, Button } from 'shared';

import { labelMaker } from 'shared/utils';

const label = labelMaker('products.prepayment.withdrawModal');

class WithdrawModal extends React.Component {
  componentDidUpdate(prevProps) {
    const { isLoading: currIsLoading, onClose } = this.props;
    const { isLoading: prevIsLoading } = prevProps;

    if (prevIsLoading && !currIsLoading) {
      onClose();
    }
  }

  render() {
    const { onSubmit, onClose, isLoading } = this.props;

    return (
      <Modal onClose={isLoading ? onClose : () => {}}>
        <ModalHeader>
          <Translate id={label('title')} />
        </ModalHeader>
        <ModalBody>
          <Translate id={label('description')} />
        </ModalBody>
        <ModalActions>
          <Button
            theme="white"
            onClick={onSubmit}
            translateId={label('withdrawBtn')}
            showLoader={isLoading}
          />
          <Button onClick={onClose} translateId={label('keepBtn')} disabled={isLoading} />
        </ModalActions>
      </Modal>
    );
  }
}

WithdrawModal.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

WithdrawModal.defaultProps = {
  isLoading: false,
  onSubmit: () => {},
  onClose: () => {},
};

export default WithdrawModal;
