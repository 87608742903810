import React, { Component, Fragment } from 'react';
import { SmoothDropDown, Process, Translation } from 'shared';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';

import { ReactComponent as PaymentImage } from 'assets/icons/icon-operation-document.svg';

import styles from './TransitionTemplate.module.scss';

import { labelMaker } from 'shared/utils';

const label = labelMaker('shared.btns');

class TransitionTemplate extends Component {
  state = {
    isOpen: false,
  };

  toggleDetails = () => {
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
  };

  renderDetails = details =>
    details.map((detail, key) => (
      <div className={styles.Detail} key={key}>
        <div className={styles.DetailName}>{detail.name}</div>
        <div className={styles.DetailValue}>{detail.value}</div>
      </div>
    ));

  render() {
    const { transition } = this.props;
    const { isOpen } = this.state;
    const {
      title,
      date,
      amount,
      status,
      details,
      reverseIcon,
      // corAccountNo
    } = transition;

    return (
      <Fragment>
        <div
          className={cn(styles.PaymentCard, {
            [styles.ActiveCard]: isOpen && details,
            [styles.HoveredCard]: details,
          })}
          onClick={this.toggleDetails}
        >
          <div className={styles.InfoRowBlock}>
            <div className={styles.Mask}>
              <PaymentImage
                className={cn(styles.PaymentImage, { [styles.MirrorHorizontal]: reverseIcon })}
              />
            </div>
            <div className={styles.Info}>
              <div className={styles.Name}>{title}</div>
              {/* <div className={styles.Card}>{corAccountNo}</div> */}
              <div className={styles.Date}>{date}</div>
            </div>
          </div>
          <div className={styles.InfoColBlock}>
            <div className={styles.Sum}>{amount}</div>
            {status ? <Process status={status} /> : null}
          </div>
        </div>
        <SmoothDropDown isOpen={this.state.isOpen && details}>
          <div className={styles.Details}>
            {Array.isArray(details) && details.length ? this.renderDetails(details) : null}
            <div className={cn(styles.Detail, styles.DetailActions)}>
              <div className={styles.DetailName}>
                <div className={styles.OperationBtn}>
                  <div className={styles.OperationBtnImg} />
                  <Translation.Div
                    className={styles.OperationBtnText}
                    translateId={label('REPEAT')}
                  />
                </div>
              </div>
              <div className={cn(styles.DetailValue, styles.Actions)}>
                <div className={cn(styles.Action, styles.ActionPdf)} />
                <div className={cn(styles.Action, styles.ActionMail)} />
                <div className={cn(styles.Action, styles.ActionPrint)} />
              </div>
            </div>
          </div>
        </SmoothDropDown>
      </Fragment>
    );
  }
}

TransitionTemplate.propTypes = {
  transition: PropTypes.object.isRequired,
};

TransitionTemplate.defaultProps = {
  theme: 'grey',
};

const mapStateToProps = ({ localize }) => ({
  locale: getActiveLanguage(localize).code,
});

export default connect(mapStateToProps)(TransitionTemplate);
