import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Spinner } from '..';

import Translation from '../Translation';
import InfoComponent from '../InfoComponent/InfoComponent';

import styles from './Collapse.module.scss';

const { Div } = Translation;

class Collapse extends Component {
  state = {
    isOpen: false,
  };

  toggle = () => {
    if (!this.props.disabled) {
      this.setState({
        isOpen: !this.state.isOpen,
      });
    }
  };

  render() {
    const { tooltipId, isLoading, disabled, labelId, children } = this.props;
    const { isOpen } = this.state;

    const selectClassName = cn(styles.Select, {
      [styles.ClosedSelect]: !isOpen,
      [styles.Disabled]: disabled,
    });

    const arrowClassName = cn(styles.Arrow, { [styles.ArrowDown]: !isOpen });

    const DescriptionClassName = cn(styles.Description, {
      [styles.Hidden]: !isOpen,
      [styles.Disabled]: disabled,
    });

    return (
      <div className={styles.DropDownContainer}>
        <div className={styles.SelectContainer}>
          <div className={selectClassName} onClick={this.toggle}>
            <Div className={styles.Header} translateId={labelId} />
            {isLoading ? <Spinner /> : <div className={arrowClassName} />}
          </div>
          <div className={DescriptionClassName}>{children}</div>
        </div>
        {tooltipId && (
          <div className={styles.TooltipContainer}>
            <InfoComponent translateId={tooltipId} width={280} />
          </div>
        )}
      </div>
    );
  }
}

Collapse.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  labelId: PropTypes.string.isRequired,
  tooltipId: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default Collapse;
