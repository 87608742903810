import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'react-router-redux';

import rootReducer from './reducers';

const isDevelopment = process.env.NODE_ENV === 'development';

export const history = createBrowserHistory();

const composeSetup =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const loggerMiddleware = createLogger({ level: 'info', collapsed: true });

const middlewareEnhancer = applyMiddleware(
  ...[thunkMiddleware, routerMiddleware(history), isDevelopment && loggerMiddleware].filter(Boolean)
);

const enhancer = compose(
  composeSetup(middlewareEnhancer)
  // devToolsEnhancer
);

export default function initStore() {
  const store = createStore(rootReducer, enhancer);
  return store;
}
