import { createAction } from 'redux-act';
import moment from 'moment';
import { get } from 'shared/utils';
import isEmpty from 'lodash/isEmpty';

import { startChatReq, fetchMessagesReq, fetchEventsReq, sendMessageReq } from './chatRequests';

export const closeChatStart = createAction('CLOSE_CHAT');
export const minimizeChat = createAction('MINIMIZE_CHAT');
export const expandChat = createAction('EXPAND_CHAT');
export const openChatStart = createAction('OPEN_CHAT');
export const resetChat = createAction('RESET_CHAT');
export const disableChat = createAction('DISABLE_CHAT');

export const initializeChatStart = createAction('INITIALIZE_CHAT_START');
export const initializeChatWithGreetingSuccess = createAction(
  'INITIALIZE_CHAT_WITH_GREETING_SUCCESS'
);
export const initializeChatWithHistorySuccess = createAction(
  'INITIALIZE_CHAT_WITH_HISTORY_SUCCESS'
);
export const initializeChatFailure = createAction('INITIALIZE_CHAT_FAILURE');

export const getChatEventsStart = createAction('GET_CHAT_EVENTS_START');
export const getChatEventsSuccess = createAction('GET_CHAT_EVENTS_SUCCESS');

export const scheduleMessageStart = createAction('SCHEDULE_MESSAGE_START');
export const scheduleMessageSuccess = createAction('SCHEDULE_MESSAGE_SUCCESS');
export const scheduleMessageFailure = createAction('SCHEDULE_MESSAGE_FAILURE');

export const sendChatMessageStart = createAction('SEND_CHAT_MESSAGE_START');
export const sendChatMessageSuccess = createAction('SEND_CHAT_MESSAGE_SUCCESS');
export const sendChatMessageFailure = createAction('SEND_CHAT_MESSAGE_FAILURE');
export const abortScheduledMessage = createAction('ABORT_SCHEDULED_MESSAGE');

export const addMessagesToHistory = createAction('ADD_MESSAGES_TO_HISTORY');
export const addMessageToHistory = createAction('ADD_MESSAGE_TO_HISTORY');

export const setScrollToLastMessageFlag = createAction('SET_SCROLL_TO_LAST_MESSAGE_FLAG');
export const resetScrollToLastMessageFlag = createAction('RESET_SCROLL_TO_LAST_MESSAGE_FLAG');

export const loadHistoryPageStart = createAction('ADD_CHAT_HISTORY_PAGE_START');
export const loadHistoryPageSuccess = createAction('ADD_CHAT_HISTORY_PAGE_SUCCESS');
export const loadHistoryPageFailure = createAction('ADD_CHAT_HISTORY_PAGE_FAILURE');

export const incrementEventsNotifications = createAction('INCREMENT_EVENTS_NOTIFICATIONS');

const prepareTimestamps = (messages) => messages.map(({ message = {} }) => { message.timestamp = moment(message.timestamp).add(3, 'h').format('YYYY-MM-DDTHH:mm:ss.ms'); return message });

const composeAnswerToMessage = (data, type) => ({
  message: { ...data },
  type,
});

const composeEventToMessage = ({ event, type }) => ({
  message: {
    ...event,
  },
  type,
});

// initializeWithGreetings
const initializeChatWithGreeting = () => async (dispatch, getState) => {
  const { auth } = getState();
  try {
    dispatch(initializeChatStart());
    const response = await dispatch(startChatReq());
    if (!response || typeof response === 'string') {
      throw new Error();
    }

    const message = composeAnswerToMessage(response, 'botResponse');

    const usersString = localStorage.getItem('chat/greetings');
    const users = JSON.parse(usersString);

    const login = get(auth, 'persistedUser.login');
    const now = moment().format();

    const greetingUser = Array.isArray(users) && users.find(user => user.login === login && user.greetingUser);

    if (greetingUser) {
      const newUsersArray = [
        ...users.slice(0, greetingUser),
        { login, greetingDate: now },
        ...users.slice(greetingUser + 1),
      ];

      localStorage.setItem('chat/greetings', JSON.stringify(newUsersArray));
      dispatch(initializeChatWithGreetingSuccess({ message }));
      dispatch(setScrollToLastMessageFlag());
    } else {
      localStorage.setItem('chat/greetings', JSON.stringify([{ login, greetingDate: now }]));
      dispatch(initializeChatWithGreetingSuccess({ message }));
      dispatch(setScrollToLastMessageFlag());
    }
  } catch (err) {
    dispatch(initializeChatFailure());
  }
};

// initializeWithHistory
const initializeChatWithHistory = () => async (dispatch, getState) => {
  const { chat } = getState();
  try {
    dispatch(initializeChatStart());
    const history = await dispatch(
      fetchMessagesReq({ pageSize: 10, pageNum: 0, dateFrom: get(chat, 'history.startDate') })
    );
    if (Array.isArray(history.messages)) {
      prepareTimestamps(history.messages);
      dispatch(fetchChatEvents());
      dispatch(initializeChatWithHistorySuccess({ history, pageSize: 10, pageNum: 0 }));
    }
  } catch (err) {
    dispatch(initializeChatFailure());
  }
};

export const initializeChat = () => (dispatch, getState) => {
  dispatch(initializeChatWithHistory());
};

export const openChat = ({ requireGreetingMessage }) => (dispatch, getState) => {
  try {
    dispatch(openChatStart());
    const { auth } = getState();
    if (requireGreetingMessage) {
      const usersString = localStorage.getItem('chat/greetings');
      const users = JSON.parse(usersString);

      const login = get(auth, 'persistedUser.login');
      if (Array.isArray(users)) {
        const greetingUser = users.find(user => user.login === login && user.greetingDate);
        if (greetingUser) {
          const dayOfGreeting = moment(greetingUser.greetingDate)
            .startOf('day')
            .toDate();
          const today = moment()
            .startOf('day')
            .toDate();
          const isGreetingMessageRequired = moment(today).diff(dayOfGreeting) > 0;
          if (isGreetingMessageRequired) {
            dispatch(initializeChatWithGreeting());
          } else {
            return;
          }
        }
      }
    }
  } catch (err) {
    dispatch(initializeChatFailure());
  }
};

export const closeChat = () => (dispatch, getState) => dispatch(closeChatStart());

const fetchChatEvents = () => async (dispatch, getState) => {
  const { chat } = getState();
  try {
    const ts = moment()
      .toDate()
      .valueOf();

    const prevTs = get(chat, 'ts');

    const response = await dispatch(fetchEventsReq(prevTs));
    const events = response.events;
    if (Array.isArray(events)) {
      events.forEach(event => {
        if (event.type !== 'request') {
          let message = composeEventToMessage(event);

          if (!get(chat, 'settings.isOpen')) {
            dispatch(incrementEventsNotifications());
          }
          dispatch(addMessageToHistory(message));
          dispatch(setScrollToLastMessageFlag());
        }
      });

      dispatch(getChatEventsSuccess(ts));
      dispatch(fetchChatEvents());
    }
  } catch (err) {
    dispatch(disableChat());
  }
};

export const sendChatMessage = reqData => async (dispatch, getState) => {
  try {
    dispatch(sendChatMessageStart());
    const response = await dispatch(sendMessageReq(reqData));
    if (!response || typeof response === 'string') {
      throw new Error();
    }
    if (response && !isEmpty(response.data)) {
      dispatch(scheduleMessageSuccess({
        type: 'botResponse',
        message: response,
      }));
    }
  } catch (err) {
    dispatch(sendChatMessageFailure());
    dispatch(disableChat());
    dispatch(abortScheduledMessage(reqData));
  }
};

export const scheduleChatMessage = reqData => async (dispatch, getState) => {
  try {
    dispatch(scheduleMessageStart());
    const { auth } = getState();
    const { message } = reqData;

    const [, firstName, middleName] = auth.user.fullname.split(' ');
    const { abscustid: clientId } = auth.user;
    const timestamp = moment()
      .format('YYYY-MM-DDTHH:mm:ss.ms')
      .slice(0, -1);
    const requestParams = {
      firstName,
      middleName,
      query: message,
      clientId,
      timestamp,
    };

    const messageObj = {
      type: 'request',
      message: {
        clientId,
        questionId: '',
        query: message,
        timestamp,

        clientInfo: {
          firstName,
          middleName,
        },
      },
    };

    dispatch(scheduleMessageSuccess(messageObj));
    dispatch(setScrollToLastMessageFlag());
    dispatch(sendChatMessage(requestParams));
  } catch (err) {
    dispatch(scheduleMessageFailure());
  }
};

export const fetchChatHistory = reqData => async (dispatch, getState) => {
  const { chat } = getState();
  try {
    dispatch(loadHistoryPageStart());
    const response = await dispatch(
      fetchMessagesReq({ ...reqData, dateFrom: get(chat, 'history.startDate') })
    );
    prepareTimestamps(response.messages);
    dispatch(loadHistoryPageSuccess(response));
  } catch (err) {
    dispatch(loadHistoryPageFailure());
  }
};
