import { reducer as form } from 'redux-form';
import { localizeReducer as localize } from 'react-localize-redux';
import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';

import auth from './auth';
import dadata from './dadata';
import notification from './notification';
import overlay from './overlay';
import payment from './payment';
import pwdRecovery from './pwdRecovery';
import session from './session';
import operationsHistory from './operationsHistory';
import products from './products';
import profile from './profile';
import signup from './signup';
import transfers from './transfers';
import atms from './atms';
import bonuses from './bonuses';
import chat from './chat';
import captcha from './captcha';
import cards from './cards';
import accounts from './accounts';
import settings from './settings';
import signature from './signature';

const appReducer = combineReducers({
  atms,
  auth,
  accounts,
  bonuses,
  captcha,
  chat,
  cards,
  dadata,
  form,
  routing: routerReducer,
  localize,
  notification,
  session,
  operationsHistory,
  overlay,
  payment,
  products,
  profile,
  pwdRecovery,
  signup,
  transfers,
  settings,
  signature,
});

export default (state, action) => {
  if (action.type === 'LOGOUT_SUCCESS') {
    const store = appReducer({}, action);
    return {
      ...store,
      auth: {
        ...store.auth,
        banner: state.auth.banner,
        showDefaultBanner: state.auth.showDefaultBanner,
        persistedUser: state.auth.persistedUser,
      },
      notification: state.notification,
      captcha: state.captcha,
      form: state.form,
      localize: state.localize,
    };
  }
  return appReducer(state, action);
};
