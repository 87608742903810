import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as PaymentImage } from 'assets/icons/icon-operation-document.svg';
import { Process } from 'shared';
import { get } from 'shared/utils';

import styles from './TransitionTemplate.module.scss';

const TransitionTemplate = ({ transition, isDebit }) => {
  const amount = get(transition, 'amount');
  const date = get(transition, 'date');
  const status = get(transition, 'status');
  const title = get(transition, 'title');

  const paymentClassName = cn(styles.PaymentImage, { [styles.MirrorHorizontal]: isDebit });

  return (
    <div className={styles.TransitionCard}>
      <div className={styles.InfoRowBlock}>
        <div className={styles.Mask}>
          <PaymentImage className={paymentClassName} />
        </div>

        <div className={styles.Info}>
          <div className={styles.Name}>{title}</div>
          <div className={styles.Date}>{date}</div>
        </div>
      </div>

      <div className={styles.InfoColBlock}>
        <div className={styles.Amount}>{amount}</div>
        {status ? <Process status={status} /> : null}
      </div>
    </div>
  );
};

TransitionTemplate.propTypes = {
  transition: PropTypes.object.isRequired,
  isDebit: PropTypes.bool.isRequired,
};

export default TransitionTemplate;
