import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './TransactionCard.module.scss';

import { labelMaker } from 'shared/utils';
import { Translation } from 'shared';

const lCard = labelMaker('profile.security');

const TransactionCard = props => {
  const { activeValue, name } = props;
  const onClick = () => {
    props.onChange({ name, value: activeValue });
  };

  const active = props.value === activeValue;

  const isScratch = activeValue === '2';
  const isMobipass = activeValue === '3';
  const isSms = activeValue === '4';

  const textTranslate = isScratch
    ? 'scratchCard'
    : isSms
      ? 'smsCode'
      : isMobipass
        ? 'mobipass'
        : '';

  const CardClassName = cn(styles.Card, { [styles.CardActive]: active });
  const ToggleClassName = cn(styles.Toggle, { [styles.ToggleActive]: active });
  const NameClassName = cn(styles.Name, { [styles.NameActive]: active });
  const IconClassName = cn(styles.Icon, {
    [styles.ScratchIcon]: isScratch,
    [styles.ScratchIconActive]: isScratch && active,
    [styles.SmsIcon]: isSms,
    [styles.SmsIconActive]: isSms && active,
    [styles.MobipassIcon]: isMobipass,
    [styles.MobipassIconActive]: isMobipass && active,
  });

  return (
    <div className={CardClassName} onClick={onClick}>
      <div className={styles.RowBlock}>
        <div className={ToggleClassName} />
        <Translation.Div className={NameClassName} translateId={lCard(textTranslate)} />
      </div>
      <div className={IconClassName} />
    </div>
  );
};

TransactionCard.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  activeValue: PropTypes.string,
};

export default TransactionCard;
