import { createReducer } from 'redux-act';

import { showOverlay, hideOverlay } from 'actions/overlay';

const defaultState = {
  isActive: false,
};

const overlay = createReducer(
  {
    [showOverlay]: () => {
      return { isActive: true };
    },

    [hideOverlay]: () => {
      return { isActive: false };
    },
  },
  defaultState
);

export default overlay;
