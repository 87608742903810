import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import cn from 'classnames';

import styles from './InfoComponent.module.scss';

export default class InfoComponent extends PureComponent {
  state = { currentElementRef: null };

  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    translateId: PropTypes.string,
    theme: PropTypes.string,
    width: PropTypes.number,
  };

  static defaultProps = {
    theme: 'blue',
  };

  setRef = el => {
    this.setState({ currentElementRef: el });
  };

  offsetLeft = (x, windowWidth, descriptionWidth) => {
    // return value for css "left" property, if returned null - property does not apply
    // and description appears in the center under the InfoComponent
    if (!x || !windowWidth || !descriptionWidth) {
      return null;
    }
    const overScreenWidth = windowWidth - x - descriptionWidth / 2;

    if (overScreenWidth < 0) {
      return overScreenWidth - descriptionWidth / 2 - 10;
    } else {
      return !this.state.currentElementRef.getBoundingClientRect().x ? -descriptionWidth / 2 : null;
    }
  };

  render() {
    const { children, translateId, width = 240, theme } = this.props;
    const { currentElementRef } = this.state;
    // find the center of the element
    const elementXCoordinate =
      currentElementRef &&
      currentElementRef.getBoundingClientRect().left +
        currentElementRef.getBoundingClientRect().width / 2;
    const windowWidth = document.documentElement.clientWidth;

    return (
      <div className={styles.InfoComponent} ref={this.setRef}>
        <div
          className={cn(styles.InfoButton, {
            [styles.InfoButtonBlue]: theme === 'blue',
            [styles.InfoButtonWhite]: theme === 'white',
          })}
        />
        <div
          className={styles.Description}
          style={{
            width,
            left: this.offsetLeft(elementXCoordinate, windowWidth, width),
          }}
        >
          {translateId ? (
            <span>{translateId ? <Translate id={translateId} /> : children}</span>
          ) : (
            children
          )}
        </div>
      </div>
    );
  }
}
