import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import cn from 'classnames';

import styles from './InputError.module.scss';

class InputError extends Component {
  state = {
    errorHeight: 0,
  };

  componentDidUpdate(prevProps, prevState) {
    const { errorId: prevErrorId } = prevProps;
    const { errorId } = this.props;

    if (prevErrorId !== errorId) {
      if (errorId) {
        this.setState({
          errorHeight: this.error.scrollHeight,
        });
      } else {
        this.setState({
          errorHeight: 0,
        });
      }
    }
  }

  render() {
    const { errorId } = this.props;
    const { errorHeight } = this.state;

    return (
      <div
        ref={ref => (this.error = ref)}
        className={cn(styles.Error, { [styles.VisibleError]: errorId })}
        style={{ height: errorHeight }}
      >
        {errorId && typeof errorId === 'string' ? <Translate id={errorId} /> : null}
        {/* Added dynamic translation with value substitution, if an object is passed with id and data properties instead of a string */}
        {errorId && typeof errorId === 'object' && errorId.id && errorId.data ? (
          <Translate id={errorId.id} data={{ data: errorId.data }} />
        ) : null}
      </div>
    );
  }
}

InputError.propTypes = {
  isErrorVisible: PropTypes.bool,
  errorId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default InputError;
