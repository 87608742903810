import React, { Component } from 'react';
import Credit from '../Credit/Credit';
import LoansMenu from '../LoansMenu/LoansMenu';

import styles from './LoansContentHeader.module.scss';

class Header extends Component {
  render() {
    return (
      <div className={styles.Header}>
        <div className={styles.CreditContainer}>
          <Credit />
        </div>
        <div className={styles.Menu}>
          <LoansMenu />
        </div>
      </div>
    );
  }
}

export default Header;
