import { createAction } from 'redux-act';
import { getTranslate } from 'react-localize-redux';
import { change } from 'redux-form';
import { showWarnNotification, showInfoNotification } from '../notification';
import { showOverlay, hideOverlay } from '../overlay';

import { get, getErrorMessage, labelMaker, formPhone } from 'shared/utils';

import {
  transferReq,
  fetchBeneficiaryBankReq,
  fetchBanksReq,
  confirmTransferReq,
  setDefaultBankReq,
  confirmDefaultBankReq,
  fetchCard2CardSignatureReq,
  fetchCard2CardPageReq,
} from './transfersRequests';

import { newMessage, postMessage, confirmMessage } from '../signature';
import { fetchCards } from '../cards';
import { initializePersonal, fetchPhoneSuccess } from '../personal';

const lError = labelMaker('errorsGeneral');

export const completeTransfer = createAction('COMPLETE_TRANSFER');
export const confirmDefaultBankFailure = createAction('CONFIRM_DEFAULT_BANK_FAILURE');
export const confirmDefaultBankStart = createAction('CONFIRM_DEFAULT_BANK_START');
export const confirmDefaultBankSuccess = createAction('CONFIRM_DEFAULT_BANK_SUCCESS');
export const confirmTransferFailure = createAction('CONFIRM_TRANSFER_FAILURE');
export const confirmTransferStart = createAction('CONFIRM_TRANSFER_START');
export const confirmTransferSuccess = createAction('CONFIRM_TRANSFER_SUCCESS');
export const destroyDefaultBank = createAction('DESTROY_DEFAULT_BANK');
export const destroyTransfers = createAction('DESTROY_TRANSFERS');
export const fetchBanksFailure = createAction('FETCH_BANKS_FAILURE');
export const fetchBanksStart = createAction('FETCH_BANKS_START');
export const fetchBanksSuccess = createAction('FETCH_BANKS_SUCCESS');
export const fetchBeneficiaryBankFailure = createAction('FETCH_BENEFICIARY_BANK_FAILURE');
export const fetchBeneficiaryBankStart = createAction('FETCH_BENEFICIARY_BANK_START');
export const fetchBeneficiaryBankSuccess = createAction('FETCH_BENEFICIARY_BANK_SUCCESS');
export const initializeDefaultBankFailure = createAction('INITIALIZE_DEFAULT_BANK_FAILURE');
export const initializeDefaultBankStart = createAction('INITIALIZE_DEFAULT_BANK_START');
export const initializeDefaultBankSuccess = createAction('INITIALIZE_DEFAULT_BANK_SUCCESS');
export const initializeTransfersFailure = createAction('INITIALIZE_TRANSFERS_FAILURE');
export const initializeTransfersStart = createAction('INITIALIZE_TRANSFERS_START');
export const initializeTransfersSuccess = createAction('INITIALIZE_TRANSFERS_SUCCESS');
export const requestTransferOTPFailure = createAction('REQUEST_TRANSFER_OTP_FAILURE');
export const requestTransferOTPStart = createAction('REQUEST_TRANSFER_OTP_START');
export const requestTransferOTPSuccess = createAction('REQUEST_TRANSFER_OTP_SUCCESS');
export const setDefaultBankAccount = createAction('SET_DEFAULT_BANK_ACCOUNT');
export const setDefaultBankFailure = createAction('SET_DEFAULT_BANK_FAILURE');
export const setDefaultBankStart = createAction('SET_DEFAULT_BANK_START');
export const setDefaultBankSuccess = createAction('SET_DEFAULT_BANK_SUCCESS');
export const setTransferStep = createAction('SET_TRANSFER_STEP');
export const transferFailure = createAction('TRANSFER_FAILURE');
export const transferStart = createAction('TRANSFER_START');
export const transferSuccess = createAction('TRANSFER_SUCCESS');
export const fetchCard2CardSignatureStart = createAction('FETCH_CARD2CARD_SIGNATURE_START');
export const fetchCard2CardSignatureSuccess = createAction('FETCH_CARD2CARD_SIGNATURE_SUCCESS');
export const fetchCard2CardSignatureFailure = createAction('FETCH_CARD2CARD_SIGNATURE_FAILURE');
export const fetchCard2CardPageStart = createAction('FETCH_CARD2CARD_PAGE_START');
export const fetchCard2CardPageSuccess = createAction('FETCH_CARD2CARD_PAGE_SUCCESS');
export const fetchCard2CardPageFailure = createAction('FETCH_CARD2CARD_PAGE_FAILURE');
export const clearCard2CardSignature = createAction('CLEAR_CARD2CARD_SIGNATURE');

export const requestTransferOTP = () => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(requestTransferOTPStart());

    await dispatch(newMessage());
    const res = await dispatch(postMessage());
    if (parseFloat(res.sign) === 0) {
      return dispatch(transferSuccess());
    }

    dispatch(requestTransferOTPSuccess());
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(requestTransferOTPFailure());
    dispatch(hideOverlay());
  }
};

export const transfer = data => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(transferStart());

    const { cashToken, messageId, payerIdentity, confirmType, code, schemeId, hash, smsId } = data;

    const confirmMessageParams = {
      cashToken,
      confirmType,
      code,
      schemeId,
      hash,
      smsId,
    };

    await dispatch(confirmMessage(confirmMessageParams));

    const transferParams = {
      messageId,
      payerIdentity,
    };
    const response = await dispatch(transferReq(transferParams));

    const transferCode = get(response, 'code');
    if (transferCode === 'SUCCESS' || transferCode === 'PROCESSING') {
      const { businessMessageId: messageId } = response;

      dispatch(transferSuccess(messageId));
    } else {
      throw translate(lError('serviceUnavailable'));
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(transferFailure());
    dispatch(hideOverlay());
  }
};

export const initializeTransfers = () => async (dispatch, getState) => {
  const { localize, cards, profile } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(initializeTransfersStart());

    const initialCards = get(cards, 'data');
    const initialPhone = get(profile, 'phone.phone');

    const isCardsFetchRequired = !Array.isArray(initialCards);
    const isPhoneFetchRequire = !Boolean(initialPhone);

    if (!isCardsFetchRequired && !isPhoneFetchRequire) {
      if (initialCards.length > 0) {
        const defaultCard = initialCards.find(card => card.sdpDefault);
        if (defaultCard) {
          dispatch(change('transfers', 'cards', defaultCard.cardNumber));
        } else {
          dispatch(change('transfers', 'cards', initialCards[0].cardNumber));
        }

        dispatch(change('transfers', 'senderPhone', initialPhone.slice(1)));
      }
      return dispatch(initializeTransfersSuccess());
    }
    const forceUpdate = false;
    const requests = [
      isCardsFetchRequired ? dispatch(fetchCards()) : null,
      isPhoneFetchRequire ? dispatch(initializePersonal(forceUpdate)) : null,
    ];

    let [cardsData, phoneData] = await Promise.all(requests);

    const mobilePhone = get(phoneData, 'mobilePhones');
    const phones = get(phoneData, 'contactInfo.mobilePhone');
    const phoneNumber =
      mobilePhone && mobilePhone.length ? mobilePhone : (Array.isArray(phones) && phones[0]) || '';

    cardsData = !cardsData ? initialCards : cardsData;
    const formatedPhoneNumber = phoneNumber ? formPhone(phoneNumber) : formPhone(initialPhone);

    if (Array.isArray(cardsData)) {
      if (cardsData.length > 0) {
        const defaultCard = cardsData.find(card => card.sdpDefault);
        if (defaultCard) {
          dispatch(change('transfers', 'cards', defaultCard.cardNumber));
        } else {
          dispatch(change('transfers', 'cards', cardsData[0].cardNumber));
        }
        dispatch(change('transfers', 'cards', cardsData[0].cardNumber));
      }
    }
    if (isPhoneFetchRequire && formatedPhoneNumber) {
      dispatch(change('transfers', 'senderPhone', formatedPhoneNumber.slice(1)));
      dispatch(fetchPhoneSuccess(formatedPhoneNumber));
      dispatch(change('transfers', 'senderPhone', formatedPhoneNumber.slice(1)));
    }

    if (Array.isArray(cardsData) && formatedPhoneNumber) {
      return dispatch(initializeTransfersSuccess());
    }

    throw translate(lError('serviceUnavailable'));
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(initializeTransfersFailure());
    dispatch(hideOverlay());
  }
};

// fetchBeneficiaryBankStart
export const fetchBeneficiaryBank = reqData => async (dispatch, getState) => {
  const { localize, transfers } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(fetchBeneficiaryBankStart());
    const banks = get(transfers, 'banks.banks');
    const isBanksFetchRequired = !Array.isArray(banks);

    const { beneficiaryIdentity, payerIdentity } = reqData;
    const beneficiaryBankParams = {
      beneficiaryIdentity,
      payerIdentity,
    };

    const requests = [
      dispatch(fetchBeneficiaryBankReq(beneficiaryBankParams)),
      isBanksFetchRequired ? dispatch(fetchBanksReq()) : null,
    ];

    const [beneficiaryBankData, banksData] = await Promise.all(requests);
    const defaultBankCode = get(beneficiaryBankData, 'description');
    const banksList = banksData ? banksData : banks;

    if (defaultBankCode === 'I00000' && Array.isArray(banksList) && banksList.length) {
      let { defaultBeneficiaryBank, businessMessageId } = beneficiaryBankData;
      defaultBeneficiaryBank = defaultBeneficiaryBank ? defaultBeneficiaryBank : '100000000030';

      const isBeneficiaryDefaultBankInList = banksList.some(
        bank => bank.title === defaultBeneficiaryBank
      );
      if (isBeneficiaryDefaultBankInList) {
        dispatch(change('transfers', 'beneficiaryBank', defaultBeneficiaryBank));
      } else {
        dispatch(change('transfers', 'beneficiaryBank', banksList[0].title));
      }

      dispatch(fetchBanksSuccess(banksList));
      dispatch(
        fetchBeneficiaryBankSuccess({ defaultBeneficiaryBank, messageId: businessMessageId })
      );
      return;
    }

    throw translate(lError('serviceUnavailable'));
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(fetchBeneficiaryBankFailure());
    dispatch(hideOverlay());
  }
};

export const confirmTransfer = reqData => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(confirmTransferStart());

    const response = await dispatch(confirmTransferReq(reqData));

    const defaultBankCode = get(response, 'code');
    if (defaultBankCode === 'SUCCESS') {
      const { businessMessageId: messageId, beneficiaryPAM } = response;

      dispatch(confirmTransferSuccess({ messageId, beneficiaryPAM }));
      return;
    } else {
      throw translate(lError('serviceUnavailable'));
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(confirmTransferFailure());
    dispatch(hideOverlay());
  }
};

export const initializeDefaultBank = () => async (dispatch, getState) => {
  const { localize, cards, profile } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(initializeDefaultBankStart());

    const initialCards = get(cards, 'data');
    const initialPhone = get(profile, 'phone.phone');

    const isCardsFetchRequired = !Array.isArray(initialCards);
    const isPhoneFetchRequire = !Boolean(initialPhone);

    if (!isCardsFetchRequired && !isPhoneFetchRequire) {
      if (initialCards.length > 0) {
        const defaultCard = initialCards.find(card => card.sdpDefault);
        if (defaultCard) {
          dispatch(change('FPSSettings', 'cards', defaultCard.cardNumber));
        } else {
          dispatch(change('FPSSettings', 'cards', initialCards[0].cardNumber));
        }

        dispatch(change('FPSSettings', 'receiverPhone', initialPhone.slice(1)));
      }
      return dispatch(initializeDefaultBankSuccess());
    }

    const forceUpdate = false;
    const requests = [
      isCardsFetchRequired ? dispatch(fetchCards()) : null,
      isPhoneFetchRequire ? dispatch(initializePersonal(forceUpdate)) : null,
    ];

    let [cardsData, phoneData] = await Promise.all(requests);

    const mobilePhone = get(phoneData, 'mobilePhones');
    const phones = get(phoneData, 'contactInfo.mobilePhone');
    const phoneNumber =
      mobilePhone && mobilePhone.length ? mobilePhone : (Array.isArray(phones) && phones[0]) || '';
    const formatedPhoneNumber = formPhone(phoneNumber);

    if (Array.isArray(cardsData)) {
      if (cardsData.length > 0) {
        const defaultCard = cardsData.find(card => card.sdpDefault);
        if (defaultCard) {
          dispatch(change('FPSSettings', 'cards', defaultCard.cardNumber));
        } else {
          dispatch(change('FPSSettings', 'cards', cardsData[0].cardNumber));
        }
        dispatch(change('FPSSettings', 'cards', cardsData[0].cardNumber));
      }
    }
    if (formatedPhoneNumber) {
      dispatch(change('FPSSettings', 'receiverPhone', formatedPhoneNumber.slice(1)));
      dispatch(fetchPhoneSuccess(formatedPhoneNumber));
      dispatch(change('FPSSettings', 'receiverPhone', formatedPhoneNumber.slice(1)));
    }

    if (Array.isArray(cardsData) && formatedPhoneNumber) {
      return dispatch(initializeDefaultBankSuccess());
    }

    throw translate(lError('serviceUnavailable'));
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(initializeDefaultBankFailure());
    dispatch(hideOverlay());
  }
};

export const setDefaultBank = reqData => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(setDefaultBankStart());
    const response = await dispatch(setDefaultBankReq(reqData));

    const defaultBankCode = response.code;
    const messageId = response.businessMessageId;
    if (defaultBankCode === 'SUCCESS') {
      return dispatch(setDefaultBankSuccess({ messageId }));
    }

    throw translate(lError('serviceUnavailable'));
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(setDefaultBankFailure());
    dispatch(hideOverlay());
  }
};

export const confirmDefaultBank = reqData => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(confirmDefaultBankStart());

    const response = await dispatch(confirmDefaultBankReq(reqData));

    const defaultBankCode = response.code;
    if (defaultBankCode === 'SUCCESS') {
      if (response.description) {
        dispatch(showInfoNotification(response.description));
      }

      return dispatch(confirmDefaultBankSuccess());
    }

    throw translate(lError('serviceUnavailable'));
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(confirmDefaultBankFailure());
    dispatch(hideOverlay());
  }
};

export const fetchCard2CardSignature = () => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(fetchCard2CardSignatureStart());
    dispatch(showOverlay());

    const res = await dispatch(fetchCard2CardSignatureReq());
    dispatch(fetchCard2CardSignatureSuccess(res));
    dispatch(hideOverlay());
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(fetchCard2CardSignatureFailure());
    dispatch(hideOverlay());
  }
};

export const fetchCard2CardPage = () => (dispatch, getState) => {
  try {
    dispatch(fetchCard2CardPageStart());
    const { card2CardSignature: signature } = getState().transfers;
    const res = dispatch(fetchCard2CardPageReq(signature));
    console.info(res);
  } catch (err) {
    console.error(err);
  }
};
