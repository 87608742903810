import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize, getTranslate } from 'react-localize-redux';
import cn from 'classnames';

import { Select1, Translation, withErrorBoundary } from 'shared';
import { initPaymentData } from 'actions/newPayment';
import { resetSignature } from 'actions/signature';
import Form from './Form/Form';

import paymentsTranslation from './payments.json';
import styles from './Payments.module.scss';

import { formatNumber, labelMaker } from 'shared/utils';
import { clearPaymentParams } from 'actions/payment';
import { debetAccountsSelector } from 'store/selectors/products';

const { Div } = Translation;
const label = labelMaker('payments');
class Payments extends Component {
  constructor(props) {
    super(props);
    props.addTranslation(paymentsTranslation);

    this.state = {
      paymentAccount: 'iframe',
      options: [{ labelId: 'payments.anyBank', value: 'iframe' }],
    };
  }

  onChange = ({ value }) => {
    this.setState({
      paymentAccount: value,
    });
  };

  renderPaymentAccountOption = ({ handleOption, isActive, option }) => {
    const { translate } = this.props;

    if (option.value === 'iframe') {
      return (
        <div
          className={cn(styles.StandardOption, { [styles.StandardActiveOption]: isActive })}
          onClick={handleOption(option.value)}
          key="iframe"
        >
          <div className={styles.IframeOption}>{translate('payments.anyBank')}</div>
        </div>
      );
    }

    if (option.cardNumber) {
      const { id, name, rest, currencyIso } = option;

      const title = name ? name : 'Card';
      return (
        <div
          className={cn(styles.StandardOption, { [styles.StandardActiveOption]: isActive })}
          onClick={handleOption(id)}
          key={id}
        >
          <div className={styles.OptionInfo}>
            <span>{title}</span>
            <span className={styles.OptionCardNumber}>{option.cardNumber}</span>
          </div>
          <div className={styles.OptionBalance}>
            {formatNumber(rest, { currency: currencyIso })}
          </div>
        </div>
      );
    }

    const { value, rest, currencyIso, name } = option;
    const title = name ? name : translate('payments.currentAccount');
    return (
      <div
        className={cn(styles.AccountOption, { [styles.ActiveAccountOption]: isActive })}
        onClick={handleOption(value)}
        key={value}
      >
        <div className={styles.OptionInfo}>
          <span>{title}</span>
          <span className={styles.OptionCardNumber}>{value}</span>
        </div>
        <div className={styles.OptionBalance}>{formatNumber(rest, { currency: currencyIso })}</div>
      </div>
    );
  };

  renderPaymentAccountLabel = option => {
    const { translate } = this.props;

    if (option.value === 'iframe') {
      return <div className={cn(styles.AccountLabel)}>{translate('payments.anyBank')}</div>;
    }

    const { name, rest, currencyIso } = option;

    const title = name ? name : option.cardNumber ? 'Card' : translate('payments.currentAccount');

    return (
      <div className={cn(styles.AccountLabel)}>
        <div className={styles.AccountLabelBlock}>
          <div className={styles.AccountLabelTitle}>{title}</div>
        </div>
        <div className={styles.AccountOptionSum}>
          {formatNumber(rest, { currency: currencyIso })}
        </div>
      </div>
    );
  };

  componentDidUpdate(prevProps, prevState) {
    const currAccounts = this.props.debetAccounts;
    const prevAccounts = prevProps.debetAccounts;
    if (!Array.isArray(prevAccounts) && Array.isArray(currAccounts)) {
      const { paymentAccount, options } = this.state;
      this.setState({
        paymentAccount: currAccounts.length ? currAccounts[0].value : paymentAccount,
        options: [...currAccounts, ...options],
      });
    }
  }

  componentDidMount() {
    const { debetAccounts } = this.props;
    if (debetAccounts) {
      const { paymentAccount, options } = this.state;

      this.setState({
        paymentAccount: debetAccounts.length ? debetAccounts[0].value : paymentAccount,
        options: [...debetAccounts, ...options],
      });
    } else {
      this.props.initPaymentData();
    }
  }

  componentWillUnmount() {
    this.props.clearPaymentParams();
    this.props.resetSignature();
  }

  render() {
    const { paymentAccount, options } = this.state;
    const { payment, products, debetAccounts } = this.props;

    const { iframeUrl, baseIframeUrl } = payment;
    const { isLoading } = payment.accountToAccount;
    const { step } = products.payment;

    const account = Array.isArray(debetAccounts)
      ? debetAccounts.find(
          account => account.value === paymentAccount || account.account === paymentAccount
        )
      : '';

    return (
      !isLoading && (
        <Fragment>
          <div className={styles.Body}>
            <div className={styles.Content}>
              {step < 2 && (
                <div className={styles.Type}>
                  <Div className={styles.PageTitle} translateId={label('title')} />
                  <Div className={styles.InputTitle} translateId={label('selectPaymentObject')} />
                  <Select1
                    name="paymentAccount"
                    value={paymentAccount}
                    onChange={this.onChange}
                    options={options}
                    renderOption={this.renderPaymentAccountOption}
                    renderLabel={this.renderPaymentAccountLabel}
                  />
                </div>
              )}
              {paymentAccount === 'iframe' ? (
                <iframe
                  className={styles.Iframe}
                  title="best2pay"
                  src={`${baseIframeUrl}?` + iframeUrl}
                />
              ) : (
                <Form
                  maxSum={account ? account.rest : '0'}
                  paymentAccount={account ? account.value : ''}
                />
              )}
            </div>
          </div>
        </Fragment>
      )
    );
  }
}

Payments.propTypes = {
  addTranslation: PropTypes.func.isRequired,
  clearPaymentParams: PropTypes.func.isRequired,
  initPaymentData: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  payment: PropTypes.object.isRequired,
  products: PropTypes.object.isRequired,
  debetAccounts: PropTypes.array,
  resetSignature: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  payment: state.products.payment,
  products: state.products,
  translate: getTranslate(state.localize),
  debetAccounts: debetAccountsSelector(state),
});

const mapDispatchToProps = {
  clearPaymentParams,
  initPaymentData,
  resetSignature,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(withErrorBoundary(Payments, 'Products/Loans/Payments')));
