import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'nuka-carousel';

import CarouselPagination from './LoginCarouselPaginator/LoginCarouselPaginator';
import LoginCarouselSlide from './LoginCarouselSlide/LoginCarouselSlide';

class LoginCarousel extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.height !== this.props.height) {
      this.setDimensions();
    }
  }

  setDimensions = () => {
    this.carousel.setDimensions();
  };

  render() {
    const settings = {
      useTransform: false,
      adaptiveHeight: true,
    };

    const renderBottomCenterControls =
      this.props.banner.slides.length > 1 ? props => <CarouselPagination {...props} /> : null;

    return (
      <Carousel
        ref={c => (this.carousel = c)}
        {...settings}
        autoGenerateStyleTag={false}
        renderCenterRightControls={() => null}
        renderCenterLeftControls={() => null}
        renderBottomCenterControls={renderBottomCenterControls}
        renderBottomLeftControls={() => null}
        renderBottomRightControls={() => null}
        autoplayInterval={this.props.banner.slides.length > 1 ? 4000 : null}
        autoplay
        wrapAround={this.props.banner.slides.length > 1}
      >
        {this.props.banner.slides.map(slide => (
          <LoginCarouselSlide
            key={slide.id}
            slide={slide}
            height={this.props.height}
            locale={this.props.locale}
          />
        ))}
      </Carousel>
    );
  }
}

LoginCarousel.propTypes = {
  banner: PropTypes.object,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  locale: PropTypes.string,
};

export default LoginCarousel;
