import { buildUrlParams, encodeDataURI } from 'shared/utils';
import apiServicesConstructor from 'services/apiService';
import { fetchMessagesReqStub, fetchEventsReqStub } from './chatStubs';

const isDevelopment = process.env.NODE_ENV === 'development';
const useGlobalStubs = process.env.REACT_APP_USE_STUBS === 'true';

export const startChatReq = (args = { query: '/start' }) => async (dispatch, getState) => {
  const { query } = args;

  const urlParams = buildUrlParams(
    encodeDataURI({
      query,
    })
  );

  const reqParams = {
    url: `/webapi-1.0/chatapi?${urlParams}`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchMessagesReq = (
  args = { pageSize: 10, pageNum: 0 },
  useStub = false,
  withError = false
) => async (dispatch, getState) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await fetchMessagesReqStub(withError);
  }

  const { pageSize, pageNum, dateFrom } = args;
  const urlParams = buildUrlParams(
    encodeDataURI({
      pageSize,
      pageNum,
      dateFrom,
    })
  );

  const reqParams = {
    url: `/webapi-1.0/chatapi/history?${urlParams}`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchEventsReq = (ts, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await fetchEventsReqStub(withError);
  }

  const data = {
    all: false,
    ts,
  };

  const urlParams = buildUrlParams(data);

  const reqParams = {
    url: `/webapi-1.0/chatapi/events?${urlParams}`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const sendMessageReq = (args = {}) => async (dispatch, getState) => {
  const { questionId, query } = args;
  const data = {
    questionId,
    query,
  };

  const reqParams = {
    url: '/webapi-1.0/chatapi',
    headers: { 'Content-Type': 'application/json' },
    data,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};
