import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getTranslate } from 'react-localize-redux';
import cn from 'classnames';
import parse from 'html-react-parser';

import { ReactComponent as CloseIcon } from '../../assets/icons/icon-close-white.svg';
import { ReactComponent as IconInfo } from '../../assets/icons/icon-info.svg';
import styles from './Notification.module.scss';

class Notification extends Component {
  defaultLineHeight = 26;
  paddingTop = 26;
  paddingBottom = 28;
  showTimeout;

  toggleExpand = () => {
    const { collapseNotification, expandNotification, notification } = this.props;
    const { expanded } = notification;

    if (expanded) {
      collapseNotification();
    } else {
      expandNotification();
    }
  };

  isMessageOneLine = message => {
    const fontSize = 20;
    var test = document.getElementById('text-measure');
    test.innerHTML = message;
    test.style.fontSize = fontSize;
    const width = test.clientWidth;
    return width <= (this.message && this.message.clientWidth);
  };

  renderLink = () => {
    const { notification, translate } = this.props;
    const { translateId, route } = notification.link ? notification.link : {};

    return translateId ? (
      <Link
        to={{
          pathname: '/' + route,
          state: { referrer: window.location.host },
        }}
        className={styles.NotificationLink}
      >
        {translate(translateId)}
      </Link>
    ) : null;
  };

  renderIcon = () => {
    const { notification, hideNotification } = this.props;
    const { message, expanded } = notification;

    const InfoIconClassName = cn(styles.IconInfo, {
      [styles.Pulse]: !this.isMessageOneLine(message) && !expanded,
    });

    const hide = () => hideNotification(notification);

    return (
      <Fragment>
        <CloseIcon className={styles.CloseIcon} onClick={hide} />
        <IconInfo className={InfoIconClassName} onClick={this.toggleExpand} />
      </Fragment>
    );
  };

  calculateMessageHeight = () => {
    const { expanded, visible,type } = this.props.notification;

    const messageHeight =
      expanded && this.message ? this.message.scrollHeight : this.defaultLineHeight;
    const notificationHeight = visible
      ? type === "Antifraud" ? "auto" : messageHeight + this.paddingTop + this.paddingBottom + 4
      : 0;

    return notificationHeight;
  };

  componentDidUpdate(prevProps) {
    const showHandlers = {
      warn: this.props.showWarnNotification,
      info: this.props.showInfoNotification,
      kyc: this.props.showKYCNotification,
      undefined: (data) => this.props.showInfoNotification({ ...data, type: 'info' }),
    };

    const { updateErrorHeight, notification, queue, isAuthenticated } = this.props;
    const {
      visible: currVisible,
      expanded: currExpanded,
      message,
      type,
    } = notification;
    const { visible: prevVisible, expanded: prevExpanded } = prevProps.notification;

    if (isAuthenticated !== prevProps.isAuthenticated) {
      this.props.clearQueue();
    }

    if (queue.length && !this.showTimeout) {
      const firstToShow = queue.reduce((acc, q) => acc.displayTime < q.displayTime ? acc : q || q.isSetImmediately, {});

      if (message && firstToShow.displayTime) {
        // сохраняем текущую нотификацию что бы показать после таймера новой
        showHandlers[type](notification);
      }
      if (message && !firstToShow.displayTime) {
        this.props.hideNotification(notification);
      }

      this.props.setMessage(firstToShow);

      if (firstToShow.displayTime) {
        this.showTimeout = setTimeout(() => { clearTimeout(this.showTimeout); this.showTimeout = undefined; this.props.hideNotification(firstToShow); }, firstToShow.displayTime);
      }
    }

    if (currVisible !== prevVisible || currExpanded !== prevExpanded) {
      updateErrorHeight(this.calculateMessageHeight());
    }
  }

  render() {
    const { notification, warnRef } = this.props;
    const { expanded, message, type } = notification;

    const messageHeight = expanded && this.message ? this.message.scrollHeight - 4 : this.defaultLineHeight;

    const notifyClassName = cn(styles.Notification, {
      [styles.NotificationInfo]: type === 'info' || type === 'kyc',
    });
    const notificationStyle = {
      height: this.calculateMessageHeight(),
      maxHeight : this.calculateMessageHeight() === 0 ? 0 : 1000
    };

    const messageStyle = {
      height: type === "Antifraud" ? "auto" : messageHeight,
    };

    return (
      <div
        className={notifyClassName}
        style={notificationStyle}
        ref={ref => {
          this.notify = ref;
          if (warnRef) {
            warnRef(ref);
          }
        }}
      >
        <div className={styles.Content}>
          <div className={cn(styles.Message,{ [styles.Antifraud]: type === 'Antifraud' })} style={messageStyle} ref={ref => (this.message = ref)}>
            {parse(message)}
          </div>
          <div className={styles.Actions}>
            {this.renderLink()}
            {this.renderIcon()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ localize, notification, auth }) => ({
  translate: getTranslate(localize),
  queue: notification.queue,
  isAuthenticated: auth.isAuthenticated,
});

Notification.propTypes = {
  collapseNotification: PropTypes.func.isRequired,
  expandNotification: PropTypes.func.isRequired,
  hideNotification: PropTypes.func,
  notification: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  updateErrorHeight: PropTypes.func.isRequired,
  warnRef: PropTypes.func,
  queue: PropTypes.array.isRequired,
  showWarnNotification: PropTypes.func,
  showInfoNotification: PropTypes.func,
  showKYCNotification: PropTypes.func,
};

Notification.defaultProps = {
  message: 'Your session has expired',
  show: false,
  type: 'info',
  showWarnNotification: () => null,
  showInfoNotification: () => null,
  showKYCNotification: () => null,
  setMessage: () => null,
  clearQueue: () => null,
};

export default connect(mapStateToProps)(Notification);
