import { createAction } from 'redux-act';

export const showWarnNotification = createAction('SHOW_WARN_NOTIFICATION');
export const showKYCNotification = createAction('SHOW_KYC_NOTIFICATION');
export const showInfoNotification = createAction('SHOW_INFO_NOTIFICATION');
export const showAntifraudNotification = createAction('SHOW_ANTIFRAUD_NOTIFICATION');

export const hideNotification = createAction('HIDE_NOTIFICATION');
export const hideKYCNotification = createAction('HIDE_KYC_NOTIFICATION');

export const expandNotification = createAction('EXPAND_NOTIFICATION');
export const expandKYCNotification = createAction('EXPAND_KYC_NOTIFICATION');

export const collapseNotification = createAction('COLLAPSE_NOTIFICATION');
export const collapseKYCNotification = createAction('COLLAPSE_KYC_NOTIFICATION');

export const clearQueue = createAction('CLEAR_QUEUE');
export const setMessage = createAction('SET_MESSAGE');
