import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import moment from 'moment';

import { RoundSelect, DateRangeCalendar } from '../';

import styles from './DateRangeFilter.module.scss';

import { ReactComponent as IconCheck } from '../../assets/icons/icon-check.svg';

class DateRangeFilter extends Component {
  state = {
    isRenderCalendarRequired: false,
  };

  renderSelectList = (items, closeDropDown) => {
    return (
      <div className={styles.DropDownContainer}>
        {items.map((item, i) => {
          const shouldClose = item.value !== 'period';

          const selectHandler = this.selectItem(item, closeDropDown, shouldClose);

          return (
            <div className={styles.ListItem} onClick={selectHandler} key={i}>
              <Translate id={item.labelId} />
              {item.value === this.props.value ? <IconCheck /> : null}
            </div>
          );
        })}
      </div>
    );
  };

  selectItem = ({ value }, closeDropDown, shouldClose) => () => {
    this.props.onChange({ value, name: this.props.name });

    if (shouldClose) {
      closeDropDown();
    } else {
      this.showCalendar();
    }
  };

  showCalendar = () => {
    this.setState({
      isRenderCalendarRequired: true,
    });
  };

  renderCalendar = (items, closeSelect) => {
    const onChangeRange = ({ range }) => {
      this.setState({
        isRenderCalendarRequired: false,
      });
      this.props.onManualRangeChange(range);
      closeSelect();
    };

    return (
      <div className={styles.CalendarContainer}>
        <DateRangeCalendar onChangeRange={onChangeRange} range={this.props.range} />
      </div>
    );
  };

  onOutsideSelectClick = closeDropDown => {
    this.hideCalendar();

    closeDropDown();
  };

  hideCalendar = () => {
    this.setState({
      isRenderCalendarRequired: false,
    });
  };

  toggleDropDown = toggleDropDown => {
    this.setState({
      isRenderCalendarRequired: false,
    });
    toggleDropDown();
  };

  renderFormatRage(range, lang) {
    const formatter = { en: 'MM.DD.YYYY', ru: 'DD.MM.YYYY' }[lang];

    return `${moment(range.startDate).format(formatter)} - ${moment(range.endDate).format(
      formatter
    )}`;
  }

  render() {
    const { isRenderCalendarRequired } = this.state;
    const { data, onChange, value, name, range, lang } = this.props;

    const isCalendarRequired = value === 'period';
    const renderData =
      isRenderCalendarRequired && isCalendarRequired ? this.renderCalendar : this.renderSelectList;
    const label = isCalendarRequired ? this.renderFormatRage(range, lang) : null;

    return (
      <div className={styles.DateRangeFilter}>
        <RoundSelect
          onChange={onChange}
          toggleDropDown={this.toggleDropDown}
          value={value}
          label={label}
          data={data}
          name={name}
          renderData={renderData}
          onClickOutside={this.onOutsideSelectClick}
        />
      </div>
    );
  }
}

DateRangeFilter.propTypes = {
  range: PropTypes.object,
  credit: PropTypes.object,
  onManualRangeChange: PropTypes.func,
  onChange: PropTypes.func,
  data: PropTypes.array,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  name: PropTypes.string,
  lang: PropTypes.string,
};

DateRangeFilter.defaultProps = {
  disabled: false,
  data: [],
};

export default DateRangeFilter;
