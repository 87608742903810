import Div from './Div';
import H2 from './H2';
import H4 from './H4';
import H6 from './H6';
import Link from './Link';
import Td from './Td';
import Span from './Span';
import NavLink from './NavLink';

export default {
  Div,
  H2,
  H4,
  H6,
  Td,
  Link,
  Span,
  NavLink,
};
