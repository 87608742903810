import React from 'react';
import Translation from '../Translation';
import PropTypes from 'prop-types';
import { labelMaker } from 'shared/utils';

import styles from './EmptyCreditsList.module.scss';

const lEmptyCredits = labelMaker('shared.creditListItem');

const EmptyCreditsList = ({ hidden }) => (
  <div className={styles.Container}>
    <div className={styles.Mask}>
      <div className={styles.Image} />
    </div>
    <Translation.Div
      className={styles.Message}
      translateId={
        hidden ? lEmptyCredits('emptyHiddenCreditsList') : lEmptyCredits('emptyCreditsList')
      }
    />
  </div>
);

EmptyCreditsList.propTypes = {
  hidden: PropTypes.bool,
};

export default EmptyCreditsList;
