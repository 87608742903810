import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { Button, InfoComponent } from 'shared';

import { reduxAccount, reduxCard } from 'shared/validators';
import { card as cardMask, account as accountMask } from 'shared/masks';

import styles from './Step1.module.scss';

class Step1 extends React.Component {
  cardOrAccountValidator = value => {
    const pureValue = value.replace(/\s/g, '');
    const cardMaxLen = 16;

    if (pureValue.length > cardMaxLen) {
      return reduxAccount(value);
    }
    return reduxCard(value);
  };

  render() {
    const { signup, options } = this.props;

    const defineMask = value => {
      return value.split(' ').join('').length < 17 ? cardMask : accountMask;
    };

    const isFieldDisabled = signup.disabled || signup.isRequestPending;

    return (
      <Fragment>
        <div className={styles.InputRow}>
          <Field
            {...options.cardOrAccount}
            validate={[...options.cardOrAccount.validate, this.cardOrAccountValidator]}
            disabled={isFieldDisabled}
            mask={defineMask}
          />
          <div className={styles.InfoContainer}>
            <InfoComponent translateId="signup.cardOrAccountDescription" mask={defineMask} />
          </div>
        </div>
        <div className={styles.InputRow}>
          <Field {...options.phone} disabled={isFieldDisabled} />
          <div className={styles.InfoContainer}>
            <InfoComponent translateId="signup.phoneDescription" width={350} />
          </div>
        </div>
        <div className={styles.ButtonContainer}>
          <Button
            type="submit"
            translateId="signup.continueBtn"
            showLoader={isFieldDisabled}
            disabled={signup.disabled}
          />
        </div>
      </Fragment>
    );
  }
}

Step1.propTypes = {
  options: PropTypes.object.isRequired,
  signup: PropTypes.object.isRequired,
};

const mapState = ({ signup, auth }) => ({
  signup,
});

export default connect(mapState)(Step1);
