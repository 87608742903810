import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';

import { Layout, withNotification, requireAuthentication, withKYCNotification } from 'shared';
import Atms from 'components/Atms/Atms';

import atmsTranslations from 'components/Atms/atms.json';

class AtmsPage extends Component {
  constructor(props) {
    super(props);

    props.addTranslation(atmsTranslations);
  }

  render() {
    return (
      <Layout.Authenticated>
        <Atms />
      </Layout.Authenticated>
    );
  }
}

AtmsPage.propTypes = {
  addTranslation: PropTypes.func.isRequired,
};

export default requireAuthentication(withKYCNotification(withNotification(withLocalize(AtmsPage))));
