import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { getActiveLanguage } from 'react-localize-redux';

import { options } from '../../formOptions';

import iconSuccess from 'assets/icons/icon-security.svg';
import SecurityHeader from '../../../components/SecurityHeader/SecurityHeader';

import { date as dateMask } from 'shared/masks';
import { reduxFieldRequired, reduxFieldBirthdayRu, reduxFieldBirthdayEn } from 'shared/validators';
import { ReduxDateInput, Button, Translation } from 'shared';
import { labelMaker, getLocalizedControlQuestions } from 'shared/utils';

import styles from './styles.module.scss';

const label = labelMaker('pwdRecovery');

const isValidDateFunc = {
  ru: reduxFieldBirthdayRu,
  en: reduxFieldBirthdayEn,
};

const Step2 = ({ pwdRecovery, locale }) => {
  const { Div } = Translation;
  const { isRequestPending, conSecId, ConSecQuestTxt } = pwdRecovery;
  const controlQuestion = getLocalizedControlQuestions({
    question: ConSecQuestTxt,
    locale: locale,
  });

  const renderForm = () => {
    switch (conSecId) {
      case '1':
        return (
          <Fragment>
            <SecurityHeader titleId={label('controlQuestionInformation')} src={iconSuccess} />

            <Div
              className={styles.StepDescription}
              translateId={label('enterControlQuestionAnswer')}
            />
            <div className={styles.InputRow}>
              <Field
                {...options.controlQuestion}
                options={[{ label: controlQuestion, value: 1 }]}
                disabled
              />
            </div>
            <div className={styles.InputRow}>
              <Field {...options.answer} disabled={isRequestPending} />
            </div>
          </Fragment>
        );
      case '2':
        return (
          <Fragment>
            <SecurityHeader titleId={label('controlWord')} src={iconSuccess} />

            <Div className={styles.StepDescription} translateId={label('enterControlWord')} />
            <div className={styles.InputRow}>
              <Field {...options.answer} disabled={isRequestPending} />
            </div>
          </Fragment>
        );
      case '3':
        return (
          <Fragment>
            <SecurityHeader titleId={label('controlDate')} src={iconSuccess} />

            <div className={styles.StepDescription} />
            <div className={styles.InputRow}>
              <Field
                {...options.answer}
                mask={dateMask}
                placeholder="01.04.1997"
                validate={[reduxFieldRequired, isValidDateFunc[locale]]}
                component={ReduxDateInput}
                disabled={isRequestPending}
              />
            </div>
          </Fragment>
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.Step}>
      {renderForm()}
      <div className={styles.SubmitContainer}>
        <Button showLoader={isRequestPending} type="submit" translateId={label('continueBtn')} />
      </div>
    </div>
  );
};

Step2.propTypes = {
  pwdRecovery: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapState = ({ pwdRecovery, localize }) => ({
  pwdRecovery,
  locale: getActiveLanguage(localize).code,
});

export default connect(mapState)(Step2);
