import {
  activateLoan,
  calculateCreditError,
  calculateCreditStart,
  calculateCreditSuccess,
  cancelPrepayment,
  changeLoanNameFailure,
  changeLoanNameStart,
  changeLoanNameSuccess,
  confirmPrepayApplicationError,
  confirmPrepayApplicationStart,
  confirmPrepayApplicationSuccess,
  deleteApplicationsError,
  deleteApplicationsStart,
  deleteApplicationsSuccess,
  fetchApplicationsError,
  fetchApplicationsStart,
  fetchApplicationsStatusesError,
  fetchApplicationsStatusesSuccess,
  fetchApplicationsSuccess,
  fetchBicInfoSuccess,
  fetchLoanError,
  fetchLoanHistoryError,
  fetchLoanHistoryStart,
  fetchLoanHistorySuccess,
  fetchLoanStart,
  fetchLoanSuccess,
  fetchLoansError,
  fetchLoansStart,
  fetchLoansSuccess,
  fetchMoreApplicationsError,
  fetchMoreApplicationsStart,
  fetchMoreApplicationsSuccess,
  fetchNextPaymentError,
  fetchNextPaymentStart,
  fetchNextPaymentSuccess,
  fetchPrepaymentDaysOffFailure,
  fetchPrepaymentDaysOffStart,
  fetchPrepaymentDaysOffSuccess,
  fetchScheduleError,
  fetchScheduleStart,
  fetchScheduleSuccess,
  finishPrepaymentError,
  finishPrepaymentStart,
  finishPrepaymentSuccess,
  increaseApplicationsShowCount,
  newPrepayApplicationError,
  newPrepayApplicationStart,
  newPrepayApplicationSuccess,
  postPrepayApplicationError,
  postPrepayApplicationStart,
  postPrepayApplicationSuccess,
  prepayApplicationError,
  prepayApplicationStart,
  prepayApplicationSuccess,
  prepaymentBackStep,
  prepaymentNextStep,
  resetProductState,
  setCurrenciesModule,
  setProductVisibilityError,
  setProductVisibilityStart,
  setProductVisibilitySuccess,
  startPrepayment,
  resetReceiveTime,
  setDueDateOn,
} from 'actions/products';
import {
  confirmDocumentError,
  confirmDocumentStart,
  confirmDocumentSuccess,
  getAccountToAccountAccountsError,
  getAccountToAccountAccountsStart,
  getAccountToAccountAccountsSuccess,
  paymentBackStep,
  resetPaymentAmount,
  resetPaymentFormEnd,
  resetPaymentFormStart,
  saveDocumentOnPaymentError,
  saveDocumentOnPaymentStart,
  saveDocumentOnPaymentSuccess,
  setPaymentAmount,
  setPaymentIframeUrl,
  paymentNextStep,
} from 'actions/newPayment';

import { createReducer } from 'redux-act';

const defaultState = {
  isVisibilityPending: false,

  credits: null,

  credit: null,

  prepayment: {
    step: 1,
    currenciesModule: null,

    daysOff: {
      days: null,
      isLoading: false,
    },
    isRequestPending: false,
    isPrepaymentExercise: false,

    // State of incoming application
    incomingApplication: {
      isMessageSent: false,

      paymentDate: '',
      paymentSum: '',
      newAnnuity: '',
      total: '',
      expectedPaymentsCount: '',
    },

    // All applications for early repayment
    applications: [],
    applicationsNum: 0,
    applicationsPages: 1,
    applicationsPage: 0,
    applicationsPageSize: 5,
    showApplicationCount: 5,
  },
  payment: {
    step: 1,
    iframeUrl: '',
    baseIframeUrl: '',
    needReset: false,

    incomingPayment: {
      accountDebet: '',
      accountCredit: '',
      dateAndTime: '',
      currCode: '',
      amount: '',
    },

    accountToAccount: {
      debet: null,
      credit: null,
      isLoading: true,
    },
  },

  history: {
    isLoaded: false,
    isLoading: false,
  },
};

const products = createReducer(
  {
    [resetProductState]: () => ({
      ...defaultState,
    }),

    [setCurrenciesModule]: (state, currenciesModule) => {
      return {
        ...state,

        prepayment: {
          ...state.prepayment,
          currenciesModule,
        },
      };
    },

    [activateLoan]: (state, id) => {
      const { credits } = state;
      let credit = null;

      if (Array.isArray(credits) && credits.length) {
        const matchCredit = credits.find(c => c.contractRef === id && c.details);

        credit = matchCredit ? matchCredit.details : null;
      }

      return {
        ...state,
        credit,
      };
    },

    [fetchPrepaymentDaysOffStart]: state => {
      return {
        ...state,

        prepayment: {
          ...state.prepayment,

          daysOff: {
            ...state.prepayment.daysOff,
            isLoading: true,
          },
        },
      };
    },
    [fetchPrepaymentDaysOffSuccess]: (state, days) => {
      return {
        ...state,

        prepayment: {
          ...state.prepayment,

          daysOff: {
            ...state.prepayment.daysOff,

            days,
            isLoading: false,
          },
        },
      };
    },
    [fetchPrepaymentDaysOffFailure]: state => {
      return {
        ...state,

        prepayment: {
          ...state.prepayment,

          daysOff: {
            ...state.prepayment.daysOff,
            isLoading: false,
          },
        },
      };
    },

    [fetchLoansStart]: (state, loans) => {
      return {
        ...state,

        isRequestPending: true,
      };
    },
    [fetchLoansSuccess]: (state, loans) => {
      return {
        ...state,

        credits: loans,
        isRequestPending: false,
      };
    },
    [fetchLoansError]: (state, error) => {
      return {
        ...state,
        isRequestPending: false,
        error,
      };
    },

    [fetchLoanStart]: (state, id) => {
      let index;
      for (let item in state.credits) {
        if (state.credits[item].contractRef === id) {
          index = item;
          break;
        }
      }

      return {
        ...state,
        credits: [
          ...state.credits.slice(0, index),
          {
            ...state.credits[index],
            details: {
              isRequestPending: true,
            },
          },
          ...state.credits.slice(parseFloat(index) + 1),
        ],
      };
    },

    [fetchLoanSuccess]: (state, creditDetails) => {
      const { credits } = state;

      let newCredits = [];

      if (Array.isArray(credits) && credits.length) {
        newCredits = credits.map(credit => {
          const { payments } = creditDetails;

          if (creditDetails.id === credit.id) {
            const { originalPrincipal } =
              Array.isArray(payments) && payments.length ? payments[payments.length - 1] : {};

            return {
              ...credit,

              details: {
                ...creditDetails,

                originalPrincipal,
                isRequestPending: false,
              },
            };
          }
          return credit;
        });
      }

      return {
        ...state,

        credits: newCredits,
      };
    },

    [fetchLoanError]: (state, { id } = {}) => {
      const { credits } = state;

      const newCredits = Array.isArray(credits)
        ? credits.reduce((acc, credit) => {
          if (credit.contractRef !== id) {
            acc = [...acc, credit];
          }

          return acc;
        }, [])
        : [];

      return {
        ...state,

        credits: newCredits,
      };
    },

    [changeLoanNameStart]: (state, contractRef) => {
      let index;

      if (Array.isArray(state.credits)) {
        index = state.credits.findIndex(c => c.contractRef === contractRef);
      }

      const credit = state.credit
        ? {
          ...state.credit,

          isNameEditing:
            state.credit.contractRef === contractRef ? true : state.credit.isNameEditing,
        }
        : null;

      if (index >= 0) {
        return {
          ...state,

          credits: [
            ...state.credits.slice(0, index),
            {
              ...state.credits[index],
              isNameEditing: true,
            },
            ...state.credits.slice(index + 1),
          ],
          credit,
        };
      }
      return state;
    },
    [changeLoanNameSuccess]: (state, credit) => {
      let index;

      if (Array.isArray(state.credits)) {
        index = state.credits.findIndex(c => c.contractRef === credit.contractRef);
      }

      const newCredit = state.credit
        ? {
          ...state.credit,

          name: state.credit.contractRef === credit.contractRef ? credit.name : state.credit.name,
          isNameEditing:
            state.credit.contractRef === credit.contractRef ? false : state.credit.isNameEditing,
        }
        : null;

      if (index >= 0) {
        return {
          ...state,

          credits: [
            ...state.credits.slice(0, index),
            {
              ...state.credits[index],
              name: credit.name,
              isNameEditing: false,
              details: {
                ...state.credits[index].details,
                name: credit.name,
              },
            },
            ...state.credits.slice(index + 1),
          ],

          credit: newCredit,
        };
      }
      return state;
    },
    [changeLoanNameFailure]: (state, contractRef) => {
      let index;

      if (Array.isArray(state.credits)) {
        index = state.credits.findIndex(c => c.contractRef === contractRef);
      }

      const credit = state.credit
        ? {
          ...state.credit,

          isNameEditing:
            state.credit.contractRef === contractRef ? false : state.credit.isNameEditing,
        }
        : null;

      if (index >= 0) {
        return {
          ...state,

          credits: [
            ...state.credits.slice(0, index),
            {
              ...state.credits[index],
              isNameEditing: false,
            },
            ...state.credits.slice(index + 1),
          ],
          credit,
        };
      }
      return state;
    },

    [calculateCreditStart]: (state, payload) => {
      const { prepayment } = state;
      const { paymentDate, paymentSum, paymentType } = payload;

      return {
        ...state,
        prepayment: {
          ...prepayment,
          incomingApplication: {
            ...prepayment.incomingApplication,

            paymentType,
            repayment: {
              amount: paymentSum,
              date: paymentDate,
            },
          },

          isRequestPending: true,
        },
      };
    },
    [calculateCreditSuccess]: (state, payload) => {
      const { prepayment } = state;
      const {
        checkMessage,
        expectedPaymentsCount,
        newFullLoanAmount,
        lastPaymentCalc,
        loanAccount,
        newAnnuity,
        total,
        principal,
        interest,
        dueDateOn,
        penalty,
        interestOnOverduePrincipalAccrued,
      } = payload;

      return {
        ...state,
        prepayment: {
          ...prepayment,

          incomingApplication: {
            ...prepayment.incomingApplication,

            repayment: {
              ...prepayment.incomingApplication.repayment,
              amount:
                prepayment.incomingApplication.paymentType === 'PartRepayment'
                  ? prepayment.incomingApplication.repayment.amount
                  : total,
            },
            checkMessage,
            expectedPaymentsCount,
            newFullLoanAmount,
            lastPaymentCalc,
            loanAccount,
            newAnnuity,
            principal,
            interest,
            total,
            penalty,
            interestOnOverduePrincipalAccrued,
            ...(dueDateOn && { dueDateOn }),
          },

          isRequestPending: false,
          step: 2,
        },
      };
    },
    [setDueDateOn]: (state, payload) => {
      const { prepayment } = state;
      const { dueDateOn } = payload;

      return {
        ...state,
        prepayment: {
          ...prepayment,

          incomingApplication: {
            ...prepayment.incomingApplication,
            dueDateOn,
          },
        },
      };
    },
    [calculateCreditError]: (state, error) => {
      const { prepayment } = state;
      const { incomingApplication: defaultApplication } = defaultState;
      return {
        ...state,
        prepayment: {
          ...prepayment,

          incomingApplication: {
            ...defaultApplication,
          },
          isRequestPending: false,
          error,
        },
      };
    },

    [newPrepayApplicationStart]: state => {
      const { prepayment } = state;

      return {
        ...state,
        prepayment: {
          ...prepayment,

          isRequestPending: true,
        },
      };
    },
    [newPrepayApplicationSuccess]: (state, payload) => {
      const { prepayment } = state;

      return {
        ...state,
        prepayment: {
          ...prepayment,
          ...payload,

          isRequestPending: false,
          error: '',
        },
      };
    },
    [newPrepayApplicationError]: (state, error) => {
      const { prepayment } = state;

      return {
        ...state,
        prepayment: {
          ...prepayment,

          isRequestPending: false,
          error,
        },
      };
    },

    [postPrepayApplicationStart]: state => {
      const { prepayment } = state;
      return {
        ...state,
        prepayment: {
          ...prepayment,

          isRequestPending: true,
        },
      };
    },

    [postPrepayApplicationStart]: state => {
      const { prepayment } = state;
      return {
        ...state,
        prepayment: {
          ...prepayment,

          isRequestPending: true,
        },
      };
    },
    [postPrepayApplicationSuccess]: state => {
      const { prepayment } = state;

      return {
        ...state,
        prepayment: {
          ...prepayment,
          incomingApplication: {
            ...prepayment.incomingApplication,

            isMessageSent: true,
          },

          isRequestPending: false,
        },
      };
    },
    [postPrepayApplicationError]: (state, error) => {
      const { prepayment } = state;
      return {
        ...state,
        prepayment: {
          ...prepayment,

          isPostMessage: false,
          isRequestPending: false,
          error,
        },
      };
    },

    [confirmPrepayApplicationStart]: state => {
      const { prepayment } = state;
      return {
        ...state,
        prepayment: {
          ...prepayment,

          isRequestPending: true,
        },
      };
    },
    [confirmPrepayApplicationSuccess]: state => {
      const { prepayment } = state;
      return {
        ...state,
        prepayment: {
          ...prepayment,

          isRequestPending: false,
        },
      };
    },
    [confirmPrepayApplicationError]: (state, error) => {
      const { prepayment } = state;
      return {
        ...state,
        prepayment: {
          ...prepayment,

          isRequestPending: false,
          error,
        },
      };
    },

    [prepayApplicationStart]: state => {
      const { prepayment } = state;

      return {
        ...state,
        prepayment: {
          ...prepayment,

          isRequestPending: true,
        },
      };
    },
    [prepayApplicationSuccess]: (state, payload) => {
      const { prepayment } = state;
      const { loanAccount } = prepayment.incomingApplication;
      const {
        status,
        type,
        otpFlowState,
        receiveTime,
      } = payload;


      return {
        ...state,
        prepayment: {
          ...prepayment,

          incomingApplication: {
            ...state.prepayment.incomingApplication,

            operationType: type,
            loanAccount,
            status,
            otpFlowState,
            receiveTime,

            signdate: new Date(),
          },

          isRequestPending: false,
        },
      };
    },
    [resetReceiveTime]: (state) => {
      const { prepayment } = state;
      const { receiveTime, ...incomingApplication } = prepayment.incomingApplication;
      return {
        ...state,
        prepayment: {
          ...prepayment,
          incomingApplication,
        }
      }
    },
    [prepayApplicationError]: (state, error) => {
      const { prepayment } = state;

      return {
        ...state,
        prepayment: {
          ...prepayment,

          isRequestPending: false,
          error,
        },
      };
    },

    [startPrepayment]: state => {
      const { prepayment } = state;

      return {
        ...state,
        prepayment: {
          ...prepayment,

          isPrepaymentExercise: true,
        },
      };
    },

    [fetchNextPaymentStart]: state => {
      return {
        ...state,
        prepayment: {
          ...state.prepayment,
          isNextPaymentLoading: true,
          isNextPaymentError: false,
        },
      };
    },
    [fetchNextPaymentSuccess]: (state, payload) => {
      return {
        ...state,
        prepayment: {
          ...state.prepayment,
          nextPaymentSum: payload.nextPaymentSum,
          savedSum: payload.savedSum,
          isNextPaymentLoading: false,
          isNextPaymentError: false,
        },
      };
    },
    [fetchNextPaymentError]: state => {
      return {
        ...state,
        prepayment: {
          ...state.prepayment,
          isNextPaymentLoading: false,
          isNextPaymentError: true,
        },
      };
    },

    [finishPrepaymentStart]: state => {
      return {
        ...state,
        prepayment: {
          ...state.prepayment,

          isRequestPending: true,
        },
      };
    },
    [finishPrepaymentSuccess]: state => {
      const { incomingApplication: defaultApplication } = defaultState;

      return {
        ...state,
        prepayment: {
          ...state.prepayment,

          incomingApplication: { ...defaultApplication },

          isPrepaymentExercise: false,
          isRequestPending: false,
          step: 1,
        },
      };
    },
    [finishPrepaymentError]: state => {
      const { incomingApplication: defaultApplication } = defaultState;

      return {
        ...state,
        prepayment: {
          ...state.prepayment,

          incomingApplication: { ...defaultApplication },

          isPrepaymentExercise: false,
          isRequestPending: false,
          step: 1,
        },
      };
    },

    [cancelPrepayment]: state => {
      const { incomingApplication: defaultApplication } = defaultState;
      const { prepayment } = state;

      return {
        ...state,
        prepayment: {
          ...prepayment,

          incomingApplication: { ...defaultApplication },

          isPrepaymentExercise: false,
          step: 1,
        },
      };
    },
    [prepaymentBackStep]: state => {
      const { prepayment } = state;
      const { isPrepaymentExercise, step } = prepayment;

      const nextStep = step === 2 ? step - 1 : 1;
      const prepaymentExercise = step === 2 ? isPrepaymentExercise : false;

      return {
        ...state,
        prepayment: {
          ...prepayment,

          isPrepaymentExercise: prepaymentExercise,
          step: nextStep,
        },
      };
    },
    [prepaymentNextStep]: state => {
      const { prepayment } = state;
      const { step } = prepayment;

      const nextStep = step < 3 ? step + 1 : 1;

      return {
        ...state,
        prepayment: {
          ...prepayment,

          step: nextStep,
        },
      };
    },
    [fetchScheduleStart]: state => {
      return {
        ...state,

        isRequestPending: true,
      };
    },
    [fetchScheduleSuccess]: (state, payload) => {
      const { credits } = state;
      const { res } = payload;

      let newCredits = [];

      if (Array.isArray(credits) && credits.length) {
        credits.forEach(credit => {
          if (payload.id === credit.contractRef) {
            credit.details = { ...credit.details, schedule: res };
          }

          newCredits.push(credit);
        });

        return {
          ...state,

          credits: newCredits,
          credit: {
            ...state.credit,
            schedule: res,
          },
        };
      }

      return {
        ...state,
      };
    },
    [fetchScheduleError]: state => {
      return {
        ...state,

        isRequestPending: false,
      };
    },

    [fetchApplicationsStart]: state => {
      return {
        ...state,

        isRequestPending: true,
      };
    },
    [fetchApplicationsSuccess]: (state, payload) => {
      const { content: applications, totalPages, pageNumber, pageSize, totalElements } = payload;
      let newApplications;

      if (Array.isArray(applications) && applications.length) {
        newApplications = applications.map(app => ({ ...app, isStatusLoading: true }));
      } else {
        newApplications = [];
      }

      return {
        ...state,

        prepayment: {
          ...state.prepayment,

          applications: newApplications,
          applicationsPage: pageNumber,
          applicationsPages: totalPages,
          applicationsPageSize: pageSize,
          applicationsNum: totalElements,
        },
      };
    },
    [fetchApplicationsError]: state => {
      return {
        ...state,

        isRequestPending: false,
      };
    },

    [fetchMoreApplicationsStart]: state => {
      return {
        ...state,

        prepayment: {
          ...state.prepayment,

          isRequestPending: true,
        },
      };
    },
    [fetchMoreApplicationsSuccess]: (state, payload) => {
      const { content: applications, totalPages, pageSize, pageNumber, totalElements } = payload;

      const newApplications = applications.map(app => ({ ...app, isStatusLoading: true }));

      return {
        ...state,

        prepayment: {
          ...state.prepayment,

          applications: [...state.prepayment.applications, ...newApplications],
          applicationsPage: pageNumber,
          applicationsPages: totalPages,
          applicationsPageSize: pageSize,
          applicationsNum: totalElements,

          isRequestPending: false,
        },
      };
    },
    [fetchMoreApplicationsError]: state => {
      return {
        ...state,

        prepayment: {
          ...state.prepayment,

          isRequestPending: false,
        },
      };
    },

    [fetchApplicationsStatusesSuccess]: (state, { content: applications }) => {
      const { applications: currApplications } = state.prepayment;

      const newApplications = currApplications.reduce((acc, currApp) => {
        const match = applications.find(app => currApp.id === app.id);

        return match
          ? (acc = [...acc, { ...currApp, status: match.status, isStatusLoading: false }])
          : (acc = [...acc, currApp]);
      }, []);

      return {
        ...state,

        prepayment: {
          ...state.prepayment,

          applications: newApplications,
        },
      };
    },
    [fetchApplicationsStatusesError]: state => {
      return {
        ...state,

        prepayment: {
          ...state.prepayment,

          isRequestPending: false,
        },
      };
    },

    [deleteApplicationsStart]: (state, id) => {
      const applications = state.prepayment.applications.reduce((acc, app) => {
        if (acc.id === id) {
          return (acc = [...acc, { ...app, isLoading: true }]);
        }
        return (acc = [...acc, app]);
      }, []);

      return {
        ...state,

        prepayment: {
          ...state.prepayment,
          applications,
        },
      };
    },
    [deleteApplicationsSuccess]: (state, id) => {
      const applications = state.prepayment.applications.filter(app => app.id !== id);

      return {
        ...state,

        prepayment: {
          ...state.prepayment,
          applications,
        },
      };
    },
    [deleteApplicationsError]: (state, id) => {
      const applications = state.prepayment.applications.reduce((acc, app) => {
        if (acc.id === id) {
          return (acc = [...acc, { ...app, isLoading: false }]);
        }
        return (acc = [...acc, app]);
      }, []);

      return {
        ...state,

        prepayment: {
          ...state.prepayment,
          applications,
        },
      };
    },

    [increaseApplicationsShowCount]: (state, payload) => {
      return {
        ...state,

        prepayment: {
          ...state.prepayment,

          showApplicationCount: state.prepayment.showApplicationCount + payload.count,
        },
      };
    },

    [fetchBicInfoSuccess]: (state, requisites) => {
      return {
        ...state,

        requisites,
      };
    },

    [setPaymentAmount]: (state, amount) => {
      return {
        ...state,

        payment: {
          ...state.payment,
          amount,
        },
      };
    },
    [resetPaymentAmount]: state => {
      return {
        ...state,

        payment: {
          ...state.payment,
          amount: null,
        },
      };
    },

    [paymentBackStep]: state => {
      const { step } = state.payment;

      const nextStep = step > 1 ? step - 1 : 1;
      return {
        ...state,

        payment: {
          ...state.payment,
          step: nextStep,
        },
      };
    },
    [paymentNextStep]: state => {
      const { step } = state.payment;

      const nextStep = step < 3 ? step + 1 : 1;
      return {
        ...state,

        payment: {
          ...state.payment,
          step: nextStep,
        },
      };
    },

    [setPaymentIframeUrl]: (state, { urlParams, baseUrl }) => {
      return {
        ...state,

        payment: {
          ...state.payment,

          iframeUrl: urlParams,
          baseIframeUrl: baseUrl,
        },
      };
    },

    [getAccountToAccountAccountsStart]: state => {
      return {
        ...state,

        payment: {
          ...state.payment,

          accountToAccount: {
            ...state.payment.accountToAccount,
            isLoading: true,
          },
        },
      };
    },
    [getAccountToAccountAccountsSuccess]: (state, payload) => {
      const { credit, debet } = payload;
      let debetAccounts = Array.isArray(debet && debet.productitem) ? debet.productitem : [];
      const creditAccounts = Array.isArray(credit && credit.productitem) ? credit.productitem : [];

      return {
        ...state,

        payment: {
          ...state.payment,

          accountToAccount: {
            ...state.payment.accountToAccount,
            debet: debetAccounts,
            credit: creditAccounts,
            isLoading: false,
          },
        },
      };
    },
    [getAccountToAccountAccountsError]: state => {
      return {
        ...state,

        payment: {
          ...state.payment,

          accountToAccount: {
            ...defaultState.payment.accountToAccount,
          },
        },
      };
    },
    [saveDocumentOnPaymentStart]: (state, payload) => {
      return {
        ...state,
      };
    },
    [saveDocumentOnPaymentSuccess]: (state, settings) => {
      const { accountDebet, accountCredit, dateAndTime, currCode, amount } = settings;

      return {
        ...state,

        payment: {
          ...state.payment,

          incomingPayment: {
            accountDebet,
            accountCredit,
            dateAndTime,
            currCode,
            amount,
          },
        },
      };
    },
    [saveDocumentOnPaymentError]: (state, payload) => {
      return {
        ...state,
        payment: {
          ...state.payment,
        },
      };
    },

    [confirmDocumentStart]: (state, payload) => {
      return {
        ...state,

        payment: {
          ...state.payment,

          isLoading: true,
        },
      };
    },
    [confirmDocumentSuccess]: (state, payload) => {
      return {
        ...state,

        payment: {
          ...state.payment,

          step: 3,
          isLoading: false,
        },
      };
    },
    [confirmDocumentError]: (state, payload) => {
      return {
        ...state,

        payment: {
          ...state.payment,

          isLoading: false,
        },
      };
    },

    [resetPaymentFormStart]: (state, payload) => {
      return {
        ...state,

        payment: {
          ...state.payment,

          needReset: true,
        },
      };
    },

    [resetPaymentFormEnd]: (state, payload) => {
      return {
        ...state,

        payment: {
          ...state.payment,
          step: 1,
          needReset: false,
        },
      };
    },

    [fetchLoanHistoryStart]: state => {
      return {
        ...state,

        history: {
          ...state.history,

          isLoading: true,
        },
      };
    },
    [fetchLoanHistorySuccess]: (state, history) => {
      // sort history by bookingDate, desc
      const sortedHistory = history.movements.sort((a, b) => {
        const dateA = new Date(a.transaction.bookingDate);
        const dateB = new Date(b.transaction.bookingDate);
        return dateB - dateA;
      });

      return {
        ...state,

        history: {
          ...history,
          movements: [...sortedHistory],
          isLoading: false,
          isLoaded: true,
        },
      };
    },
    [fetchLoanHistoryError]: (state, payload) => {
      return {
        ...state,

        history: {
          ...state.history,

          isLoading: false,
          isLoaded: true,
        },
      };
    },
    [setProductVisibilityStart]: state => ({ ...state, isVisibilityPending: true }),
    [setProductVisibilitySuccess]: (state, payload) => {
      const { id, visibilityRule } = payload;
      // change visibility in credits
      const newCredits = state.credits.map(item => {
        if (item.id === id) {
          item.visibilityRule = visibilityRule;
          if (item.details) {
            item.details.visibilityRule = visibilityRule;
          }
        }
        return item;
      });

      return {
        ...state,
        isVisibilityPending: false,
        credit: {
          ...state.credit,
          visibilityRule: visibilityRule,
        },
        credits: newCredits,
      };
    },
    [setProductVisibilityError]: state => ({ ...state, isVisibilityPending: false }),
  },
  defaultState
);

export default products;
