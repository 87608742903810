/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react';
import { getActiveLanguage } from 'react-localize-redux';
import { connect } from 'react-redux';
import { getFormValues, reset } from 'redux-form';
import PropTypes from 'prop-types';

import ProfileContentHeader from '../components/ProfileContentHeader/ProfileContentHeader';
import TransactionCards from '../components/TransactionCards/TransactionCards';
import ChangeLogin from './components/ChangeLogin/ChangeLogin';
import ChangePassword from './components/ChangePassword/ChangePassword';
import ScratchCard from './components/ScratchCard/ScratchCard';
import Protection from './components/Protection/Protection';
import PreviousLoginNotification from './components/PreviousLoginNotification/PreviousLoginNotification';
import {
  hideChangePasswordSettings,
  fetchChangePasswordSettings,
  changePassword,
  changeLogin,
  toggleChangeLoginBlock,
  hideChangeBlocks,
  hideChangeScratchCardBlock,
  fetchChangeScratchCardSettings,
  changeScratchCard,
  fetchSecureSettings,
} from 'actions/security';

import {
  // Toggle,
  Translation,
  withErrorBoundary,
} from 'shared';

import fieldsOption from './options';

import styles from './Security.module.scss';

import {
  validateSubmit,
  shapeFields,
  getValidationErrorId,
  makeValidators,
  minLength,
  maxLength,
  required,
  isSubmitValid,
} from 'shared/validators';
import { getProps as buildGetProps, labelMaker } from 'shared/utils';

const lSecurity = labelMaker('profile.security');

class Security extends Component {
  state = {
    fields: shapeFields({
      ...fieldsOption,
    }),
  };

  componentDidMount() {
    this.props.fetchSecureSettings();
  }

  componentDidUpdate(prevProps) {
    const { showChangeScratchCardBlock } = this.props.security;
    if (
      showChangeScratchCardBlock === true &&
      showChangeScratchCardBlock !== prevProps.security.showChangeScratchCardBlock
    ) {
      this.addChangeScratchCardValidators();
    }

    // update settings if locale change
    const { locale: currLocale, fetchSecureSettings } = this.props;
    const { locale: prevLocale } = prevProps;

    if (prevLocale !== currLocale) {
      fetchSecureSettings();
    }
  }

  componentWillUnmount() {
    this.props.hideChangeBlocks();
  }

  onChange = ({ value, name }, showError) => {
    const { fields } = this.state;
    const field = fields[name];

    const { isError } = field;

    const newField = { ...field, value, touched: true, isError: showError || isError };
    const errorId = getValidationErrorId(newField);

    this.setState({
      fields: {
        ...fields,
        [name]: {
          ...newField,
          errorId,
        },
      },
    });
  };

  submitFields = newFields => {
    this.setState({
      fields: {
        ...this.state.fields,
        ...newFields,
      },
    });
  };

  handleChangePasswordSubmit = () => {
    const { changePassword, changePasswordFormValues } = this.props;

    changePassword({
      password: changePasswordFormValues.currentPassword,
      newPassword: changePasswordFormValues.newPassword,
      confirmNewPassword: changePasswordFormValues.confirmNewPassword,
    });
  };

  cancelChangePassword = () => {
    const { hideChangePasswordSettings, reset } = this.props;
    reset('securityChangePasswordForm');
    hideChangePasswordSettings();
  };

  handleChangeLoginSubmit = () => {
    const { changeLogin, changeLoginFormValues, login } = this.props;
    changeLogin({
      login: login,
      newLogin: changeLoginFormValues.newLogin,
    });
  };

  cancelChangeLogin = () => {
    const { toggleChangeLoginBlock, reset } = this.props;
    reset('securityChangeLoginForm');
    toggleChangeLoginBlock();
  };

  addChangeScratchCardValidators = () => {
    const { fields } = this.state;
    const {
      security: { scratchCardSettings },
    } = this.props;

    this.setState({
      fields: {
        ...fields,
        scratchCardIdToBind: {
          ...fields.scratchCardIdToBind,
          value: '',
          errorId: '',
          validators: makeValidators([
            { required },
            { fn: minLength(scratchCardSettings.tanMinLength || 8) },
            { fn: maxLength(scratchCardSettings.tanMaxLength || 10) },
          ]),
        },
        scratchCardIdNew: {
          ...fields.scratchCardIdNew,
          value: '',
          errorId: '',
          validators: makeValidators([
            { required },
            { fn: minLength(scratchCardSettings.tanMinLength || 8) },
            { fn: maxLength(scratchCardSettings.tanMaxLength || 10) },
          ]),
        },
      },
    });
  };

  cancelChangeScratchCard = () => {
    const { hideChangeScratchCardBlock } = this.props;
    const { fields } = this.state;

    this.setState({
      fields: {
        ...fields,
        scratchCardIdToBind: {
          ...fields.scratchCardIdToBind,
          value: '',
          touched: false,
          errorId: '',
        },
        scratchCardIdNew: {
          ...fields.scratchCardIdNew,
          value: '',
          touched: false,
          errorId: '',
        },
      },
    });

    hideChangeScratchCardBlock();
  };

  handleChangeScratchCard = () => {
    const { changeScratchCard } = this.props;
    const { fields } = this.state;
    const fieldsForValidation = ['scratchCardIdToBind', 'scratchCardIdNew'];

    const { scratchCardIdToBind, scratchCardIdNew } = fields;

    if (!isSubmitValid(fields, fieldsForValidation)) {
      validateSubmit(fields, fieldsForValidation, this.submitFields);
    } else {
      changeScratchCard({
        scratchCardIdToBind: scratchCardIdToBind.value,
        scratchCardIdNew: scratchCardIdNew.value,
      });
    }
  };

  render() {
    const {
      security,
      fetchChangePasswordSettings,
      fetchChangeScratchCardSettings,
      toggleChangeLoginBlock,
      sign,
      login,
    } = this.props;
    const {
      showChangePasswordBlock,
      showChangeLoginBlock,
      showChangeScratchCardBlock,
      passwordSettings,
    } = security;
    const { fields } = this.state;

    const getProps = buildGetProps({ onChange: this.onChange, fields });

    const actionId = lSecurity('CHANGE');

    const scratchCardProps = !showChangeScratchCardBlock
      ? {
          actionId,
          handleAction: fetchChangeScratchCardSettings,
        }
      : {};

    return (
      <Fragment>
        <ProfileContentHeader />
        <div className={styles.Body}>
          <div className={styles.Content}>
            <div className={styles.Block}>
              <ChangeLogin
                login={login}
                showChangeLoginBlock={showChangeLoginBlock}
                toggleChangeLoginBlock={toggleChangeLoginBlock}
                cancelChangeLogin={this.cancelChangeLogin}
                onSubmit={this.handleChangeLoginSubmit}
              />
            </div>
            <div className={styles.Block}>
              <ChangePassword
                {...{
                  showChangePasswordBlock,
                  fetchChangePasswordSettings,
                  cancelChangePassword: this.cancelChangePassword,
                  onSubmit: this.handleChangePasswordSubmit,
                  passwordSettings,
                }}
              />
            </div>
            {sign === '2' ? (
              <div className={styles.Block}>
                <ScratchCard
                  showChangeScratchCardBlock={showChangeScratchCardBlock}
                  handleChangeScratchCard={this.handleChangeScratchCard}
                  cancelChangeScratchCard={this.cancelChangeScratchCard}
                  scratchCardProps={scratchCardProps}
                  getProps={getProps}
                  security={security}
                />
              </div>
            ) : null}
            <div className={styles.Block}>
              <Translation.Div
                className={styles.BlockTitle}
                translateId={lSecurity('transactionConfirmation')}
              />
              <Translation.Div
                className={styles.InputLabel}
                translateId={lSecurity('transactionConfirmationInBank')}
              />
              <div className={styles.CardsContainer}>
                <TransactionCards {...getProps('confirmOperation')} value={sign} />
              </div>
            </div>
            {/* <div className={styles.Block}>
              <Translation.Div
                className={styles.BlockTitle}
                translateId={lSecurity('passwordConfirmation')}
              />
              <div className={styles.ToggleContainer}>
                <Translate id={lSecurity('on')} />
                <Toggle {...getProps('loginConfirm')} />
              </div>
              <Translation.Div
                className={styles.BlockInfo}
                translateId={lSecurity('additionalSmsProtection')}
              />
            </div> */}
            {/* <div className={styles.Block}>
              <Translation.Div
                className={styles.BlockTitle}
                translateId={lSecurity('distancePwdRecovery')}
              />
              <div className={styles.ToggleContainer}>
                <Translate id={lSecurity('on')} />
                <Toggle {...getProps('remotePwdRecovery')} />
              </div>
              <Translation.Div
                className={styles.BlockInfo}
                translateId={lSecurity('additionalSmsProtectionInfo')}
              />
            </div> */}
            <div className={styles.Block}>
              <Protection />
            </div>
            <div className={styles.Block}>
              <PreviousLoginNotification />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

Security.propTypes = {
  hideChangePasswordSettings: PropTypes.func.isRequired,
  fetchChangePasswordSettings: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  toggleChangeLoginBlock: PropTypes.func.isRequired,
  security: PropTypes.object.isRequired,
  login: PropTypes.string.isRequired,
  sign: PropTypes.string.isRequired,
  changeLogin: PropTypes.func.isRequired,
  hideChangeBlocks: PropTypes.func.isRequired,
  hideChangeScratchCardBlock: PropTypes.func.isRequired,
  fetchChangeScratchCardSettings: PropTypes.func.isRequired,
  changeScratchCard: PropTypes.func.isRequired,
  changePasswordFormValues: PropTypes.shape({
    password: PropTypes.string,
    newPassword: PropTypes.string,
    confirmNewPassword: PropTypes.string,
  }),
  changeLoginFormValues: PropTypes.shape({
    newLogin: PropTypes.string,
  }),
  reset: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  fetchSecureSettings: PropTypes.func.isRequired,
};

const mapStateToProps = ({ localize, security, login, sign, values, ...state }) => ({
  locale: getActiveLanguage(localize).code,
  security: state.profile.security,
  login: state.auth.persistedUser.login,
  sign: state.auth.user.sign,
  changePasswordFormValues: getFormValues('securityChangePasswordForm')(state),
  changeLoginFormValues: getFormValues('securityChangeLoginForm')(state),
});

const mapDispatchToProps = {
  hideChangePasswordSettings,
  fetchChangePasswordSettings,
  changePassword,
  changeLogin,
  toggleChangeLoginBlock,
  hideChangeBlocks,
  hideChangeScratchCardBlock,
  fetchChangeScratchCardSettings,
  changeScratchCard,
  reset,
  fetchSecureSettings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(Security, 'Profile/Security'));
