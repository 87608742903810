import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import styles from '../../Personal.module.scss';
import { labelMaker } from 'shared/utils';
import { Translation, Button } from 'shared';
const label = labelMaker('profile.personal');
const labelBtns = labelMaker('profile.btns');

const PhoneBlock = ({
  fieldsOptions,
  phoneProps,
  phoneData,
  showChangePhoneBlock,
  checkPhoneStart,
  checkPhoneCodeStart,
  cancelPhoneChanges,
  formErrors,
}) => {
  return (
    <Fragment>
      <Translation.H2 className={styles.Title} translateId={label('phone')} />
      <Translation.H4 className={styles.Description} translateId={label('phoneInfo')} />
      <div className={styles.InputContainer}>
        <Field {...fieldsOptions.phone} {...phoneProps} />
      </div>
      {showChangePhoneBlock && (
        <Fragment>
          <div className={styles.InputContainer}>
            <Field
              {...fieldsOptions.newPhone}
              disabled={phoneData.isLoading || phoneData.isCodeSend}
            />
          </div>
          {phoneData.isCodeSend && (
            <div className={styles.InputContainer}>
              <Field {...fieldsOptions.phoneCode} disabled={phoneData.isLoading} />
            </div>
          )}
          <div className={styles.ButtonsContainer}>
            <div className={styles.Button}>
              <Button
                round
                translateId={labelBtns('cancel')}
                theme="white"
                onClick={cancelPhoneChanges}
              />
            </div>
            <div className={styles.Button}>
              <Button
                round
                disabled={!!formErrors.newPhone}
                showLoader={phoneData.isLoading}
                translateId={!phoneData.isCodeSend ? labelBtns('save') : labelBtns('send')}
                onClick={!phoneData.isCodeSend ? checkPhoneStart : checkPhoneCodeStart}
              />
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

PhoneBlock.propTypes = {
  fieldsOptions: PropTypes.object.isRequired,
  phoneProps: PropTypes.object,
  phoneData: PropTypes.object.isRequired,
  showChangePhoneBlock: PropTypes.bool.isRequired,
  checkPhoneStart: PropTypes.func.isRequired,
  checkPhoneCodeStart: PropTypes.func.isRequired,
  cancelPhoneChanges: PropTypes.func.isRequired,
  formErrors: PropTypes.object.isRequired,
};

export default PhoneBlock;
