import React, { Component } from 'react';
import { YMaps, Map } from 'react-yandex-maps';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getTranslate } from 'react-localize-redux';
import cn from 'classnames';
import { fetchBranchesByCoordinates, fetchAtmsByCoordinates } from 'actions/atmsAndBranches';

import PinIconOffice from 'assets/icons/icon-pin-office.svg';
import PinIconAtm from 'assets/icons/icon-pin-atm.svg';

import { Translation } from 'shared';
import { labelMaker } from 'shared/utils';

import styles from './AtmsOnMap.module.scss';

import { getPlacemarkerBody, getPlacemarkerHeader } from './Placemarker';
import AtmsObjects from './AtmsObjects';
import BranchesObjects from './BranchesObjects';

const lAtms = labelMaker('atms');

class AtmsOnMap extends Component {
  /**
   * We check the status of the balloon, because after balloon opening the map changes its center to balloon
   * and new points are loaded, which leads to the automatic closing of the balloon
   */
  state = {
    isBalloonOpen: false,
    isOverlayActive: true,
  };

  saveBalloonState = state => this.setState({ isBalloonOpen: state });

  setOverlay = status => this.setState({ isOverlayActive: status });

  zoom = func => () => {
    // before execute zoom check overlay and remove it
    if (this.state.isOverlayActive) {
      this.setOverlay(false);
    }
    func();
  };

  mapState = {
    controls: [],
    behaviors: ['default', 'scrollZoom'],
  };

  getPointData = (atm, translate) => {
    return {
      balloonContentBody: getPlacemarkerBody(atm, translate),
      clusterCaption: getPlacemarkerHeader(atm, translate),
    };
  };

  getPointOptions = atm => {
    const icon = atm.atmId ? PinIconAtm : PinIconOffice;
    return {
      iconLayout: 'default#image',
      iconImageHref: icon,
      iconImageSize: [40, 52],
      iconImageOffset: [-18, -51],
    };
  };

  setOverlayOnClick = event => {
    /**
     * Remove overlay on click on overlay/zoom buttons
     * Show overlay when clicking on empty places on the page or on chat icon click
     */
    const target = event.target;
    if (typeof target.className !== 'string') {
      return;
    }
    if (
      (target.className.search(/AtmsOnMap_MapOverlay/g) !== -1 ||
        target.className.search(/ZoomBtn/g) !== -1) &&
      this.state.isOverlayActive
    ) {
      this.setOverlay(false);
    } else if (
      (target.className.search(/AtmsContentHeader_Header/g) !== -1 ||
      target.className.search(/AtmsContentHeader_Filters/g) !== -1 ||
      target.className.search(/Layout_Layout/g) !== -1 ||
      (target.parentNode && target.parentNode.className.search(/Footer_Footer/g) !== -1) || // overlay on any footer click
        target.tagName === 'IMG') && // overlay on chat icon click
      !this.state.isOverlayActive
    ) {
      this.setOverlay(true);
    }
  };

  componentDidMount() {
    window.addEventListener('click', this.setOverlayOnClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.setOverlayOnClick);
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (
      this.props.center !== nextProps.center ||
      this.props.zoom !== nextProps.zoom ||
      this.props.mapRef !== nextProps.mapRef ||
      this.state.isOverlayActive !== nextState.isOverlayActive
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const {
      center,
      zoom,
      minZoom,
      zoomIn,
      zoomOut,
      fetchBranchesByCoordinates,
      fetchAtmsByCoordinates,
      mapRef,
      setZoom,
      setMapRef,
    } = this.props;
    return (
      <div className={styles.MapContainer}>
        {this.state.isOverlayActive && (
          <div className={styles.MapOverlay}>
            <Translation.Div
              className={styles.MapOverlayText}
              translateId={lAtms('interactionStart')}
            />
          </div>
        )}
        <YMaps
          query={{
            ns: 'use-load-option',
            apikey: '267c1e0a-658c-4bdc-b9a5-639545aac308',
            lang: 'ru_RU',
          }}
        >
          <Map
            state={{ ...this.mapState, zoom, center }}
            options={{ minZoom }}
            width="100%"
            height="100%"
            instanceRef={ref => {
              if (!ref) {
                return;
              }
              setMapRef(ref);
              const coords = ref.getBounds();
              fetchBranchesByCoordinates(coords);
              fetchAtmsByCoordinates(coords);
            }}
            onBoundsChange={e => {
              if (this.state.isBalloonOpen) {
                return;
              }
              const oldZoom = e.get('oldZoom');
              const newZoom = e.get('newZoom');
              const oldCenter = e.get('oldCenter');
              const newCenter = e.get('newCenter');
              if (oldZoom !== newZoom) {
                setZoom(newZoom);
              }
              if (
                oldZoom !== newZoom &&
                oldCenter[0] === newCenter[0] &&
                oldCenter[1] === newCenter[1]
              ) {
                return;
              }
              const coords = mapRef.getBounds();

              fetchBranchesByCoordinates(coords);
              fetchAtmsByCoordinates(coords);
            }}
            onBalloonOpen={() => this.saveBalloonState(true)}
            onBalloonClose={() => this.saveBalloonState(false)}
          >
            <AtmsObjects getPointData={this.getPointData} getPointOptions={this.getPointOptions} />
            <BranchesObjects
              getPointData={this.getPointData}
              getPointOptions={this.getPointOptions}
            />
          </Map>
        </YMaps>
        <span className={styles.ZoomContainer}>
          <span className={cn(styles.ZoomBtn, styles.ZoomIconPlus)} onClick={this.zoom(zoomIn)}>
            +
          </span>
          <span className={cn(styles.ZoomBtn, styles.ZoomIconMinus)} onClick={this.zoom(zoomOut)}>
            -
          </span>
        </span>
      </div>
    );
  }
}

AtmsOnMap.propTypes = {
  center: PropTypes.array,
  translate: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  zoom: PropTypes.number.isRequired,
  minZoom: PropTypes.number.isRequired,
  zoomIn: PropTypes.func.isRequired,
  zoomOut: PropTypes.func.isRequired,
  setZoom: PropTypes.func.isRequired,
  fetchBranchesByCoordinates: PropTypes.func.isRequired,
  fetchAtmsByCoordinates: PropTypes.func.isRequired,
  mapRef: PropTypes.object,
  setMapRef: PropTypes.func.isRequired,
};

const mapStateToProps = ({ localize, form }) => ({
  translate: getTranslate(localize),
  form,
});

const mapDispatch = { fetchBranchesByCoordinates, fetchAtmsByCoordinates };

export default connect(
  mapStateToProps,
  mapDispatch
)(AtmsOnMap);
