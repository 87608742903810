import { createReducer } from 'redux-act';

import {
  initializeTransfersStart,
  initializeTransfersSuccess,
  initializeTransfersFailure,
  destroyTransfers,
  //
  fetchBeneficiaryBankStart,
  fetchBeneficiaryBankSuccess,
  fetchBeneficiaryBankFailure,
  //
  fetchBanksStart,
  fetchBanksSuccess,
  fetchBanksFailure,
  //
  confirmTransferStart,
  confirmTransferSuccess,
  confirmTransferFailure,
  //
  transferStart,
  transferSuccess,
  transferFailure,
  //
  completeTransfer,
  //
  requestTransferOTPStart,
  requestTransferOTPSuccess,
  requestTransferOTPFailure,
  //
  initializeDefaultBankStart,
  initializeDefaultBankSuccess,
  initializeDefaultBankFailure,
  destroyDefaultBank,
  //
  setDefaultBankStart,
  setDefaultBankSuccess,
  setDefaultBankFailure,
  //
  confirmDefaultBankStart,
  confirmDefaultBankSuccess,
  confirmDefaultBankFailure,
  //
  setTransferStep,
  setDefaultBankAccount,
  //
  fetchCard2CardSignatureSuccess,
  clearCard2CardSignature,
} from 'actions/transfers';

const initialState = {
  defaultBankAccountForm: {
    step: 1,

    disabled: false,
    isLoading: false,
  },
  transferForm: {
    step: 1,

    defaultBeneficiaryBank: null,
    businessMessageId: null,
    confirmation: null,

    isAccountLoading: false,
    disabled: false,
    isLoading: false,
    error: false,
  },
  banks: {
    banks: null,

    isLoading: false,
    error: false,
  },
  card2CardSignature: null,
  defaultBankAccount: null,
};

const transfers = createReducer(
  {
    [destroyTransfers]: state => ({
      ...state,

      transferForm: {
        ...initialState.transferForm,
      },
    }),

    [initializeTransfersStart]: state => ({
      ...state,

      transferForm: {
        ...state.transferForm,

        isAccountLoading: true,
        isLoading: true,
        disabled: false,
      },
    }),
    [initializeTransfersSuccess]: state => ({
      ...state,

      transferForm: {
        ...state.transferForm,

        isAccountLoading: false,
        isLoading: false,
        disabled: false,
      },
    }),
    [initializeTransfersFailure]: state => ({
      ...state,

      transferForm: {
        ...state.transferForm,

        isAccountLoading: false,
        isLoading: false,
        disabled: true,
        error: true,
      },
    }),

    [fetchBeneficiaryBankStart]: state => ({
      ...state,

      transferForm: {
        ...state.transferForm,

        isLoading: true,
      },
    }),
    [fetchBeneficiaryBankSuccess]: (state, payload = {}) => {
      const { defaultBeneficiaryBank, messageId } = payload;
      return {
        ...state,

        transferForm: {
          ...state.transferForm,

          defaultBeneficiaryBank,
          messageId,
          step: 2,
          isLoading: false,
        },
      };
    },
    [fetchBeneficiaryBankFailure]: state => ({
      ...state,

      transferForm: {
        ...state.transferForm,

        isLoading: false,
        error: true,
      },
    }),

    [fetchBanksStart]: state => ({
      ...state,

      banks: {
        ...state.banks,

        isLoading: true,
      },
    }),
    [fetchBanksSuccess]: (state, banks) => {
      return {
        ...state,

        banks: {
          ...state.banks,

          banks,
          isLoading: false,
        },
      };
    },
    [fetchBanksFailure]: state => ({
      ...state,

      banks: {
        ...state.banks,

        isLoading: false,
        error: true,
      },
    }),

    [confirmTransferStart]: state => ({
      ...state,

      transferForm: {
        ...state.transferForm,

        isLoading: true,
      },
    }),
    [confirmTransferSuccess]: (state, { messageId, beneficiaryPAM }) => {
      return {
        ...state,

        transferForm: {
          ...state.transferForm,

          messageId,
          beneficiaryPAM,

          step: 3,
          isLoading: false,
          error: false,
        },
      };
    },
    [confirmTransferFailure]: state => ({
      ...state,

      transferForm: {
        ...state.transferForm,

        isLoading: false,
        error: true,
      },
    }),

    [transferStart]: state => ({
      ...state,

      transferForm: {
        ...state.transferForm,

        isLoading: true,
      },
    }),
    [transferSuccess]: (state, messageId) => {
      return {
        ...state,

        transferForm: {
          ...state.transferForm,

          messageId,
          step: 5,
          isLoading: false,
        },
      };
    },
    [transferFailure]: state => ({
      ...state,

      transferForm: {
        ...state.transferForm,

        isLoading: false,
        error: true,
      },
    }),

    [completeTransfer]: state => {
      return {
        ...state,

        transferForm: {
          ...state.transferForm,

          step: 1,
        },
      };
    },

    [requestTransferOTPStart]: state => ({
      ...state,

      transferForm: {
        ...state.transferForm,

        isLoading: true,
      },
    }),
    [requestTransferOTPSuccess]: (state, confirmation) => ({
      ...state,

      transferForm: {
        ...state.transferForm,

        confirmation,
        step: 4,
        isLoading: false,
      },
    }),
    [requestTransferOTPFailure]: state => ({
      ...state,

      transferForm: {
        ...state.transferForm,

        isLoading: false,
        error: true,
      },
    }),

    [initializeDefaultBankStart]: state => ({
      ...state,

      defaultBankAccountForm: {
        ...state.defaultBankAccountForm,

        isDefaultAccountLoading: true,
        isLoading: true,
        disabled: false,
      },
    }),
    [initializeDefaultBankSuccess]: state => ({
      ...state,

      defaultBankAccountForm: {
        ...state.defaultBankAccountForm,

        isDefaultAccountLoading: false,
        isLoading: false,
        disabled: false,
      },
    }),
    [initializeDefaultBankFailure]: state => ({
      ...state,

      defaultBankAccountForm: {
        ...state.defaultBankAccountForm,

        isDefaultAccountLoading: false,
        isLoading: false,
        disabled: true,
      },
    }),

    [destroyDefaultBank]: state => ({
      ...state,

      defaultBankAccountForm: {
        ...initialState.defaultBankAccountForm,
      },
    }),

    [setDefaultBankStart]: state => ({
      ...state,

      defaultBankAccountForm: {
        ...state.defaultBankAccountForm,

        isLoading: true,
      },
    }),
    [setDefaultBankSuccess]: (state, payload) => ({
      ...state,

      defaultBankAccountForm: {
        ...state.defaultBankAccountForm,

        messageId: payload.messageId,
        step: 2,
        isLoading: false,
      },
    }),
    [setDefaultBankFailure]: state => ({
      ...state,

      defaultBankAccountForm: {
        ...state.defaultBankAccountForm,

        isLoading: false,
      },
    }),

    [confirmDefaultBankStart]: state => ({
      ...state,

      defaultBankAccountForm: {
        ...state.defaultBankAccountForm,

        isLoading: true,
      },
    }),
    [confirmDefaultBankSuccess]: (state, payload) => ({
      ...state,

      defaultBankAccountForm: {
        ...state.defaultBankAccountForm,

        step: 1,
        isLoading: false,
      },
      // defaultBankAccount: payload.account,
    }),
    [confirmDefaultBankFailure]: state => ({
      ...state,

      defaultBankAccountForm: {
        ...state.defaultBankAccountForm,

        isLoading: false,
      },
    }),

    [setTransferStep]: (state, step) => ({
      ...state,

      transferForm: {
        ...state.transferForm,
        step,
      },
    }),
    [setDefaultBankAccount]: (state, defaultBankAccount) => ({
      ...state,

      defaultBankAccount,
    }),
    [fetchCard2CardSignatureSuccess]: (state, signature) => ({
      ...state,
      card2CardSignature: signature,
    }),
    [clearCard2CardSignature]: state => ({ ...state, card2CardSignature: null }),
  },
  initialState
);

export default transfers;
