import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import loaderBlue from '../../assets/icons/loader-blue.png';
import styles from './Spinner.module.scss';

const Spinner = props => {
  const { className } = props;

  return (
    <div className={cn(styles.SpinnerContainer, { [className]: className })}>
      <img className={styles.Spinner} src={loaderBlue} alt="" />
    </div>
  );
};

Spinner.propTypes = {
  className: PropTypes.string,
};

export default Spinner;
