import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Translate } from 'react-localize-redux';
import onClickOutside from 'react-onclickoutside';
import { ReactComponent as IconCheck } from '../../assets/icons/icon-check.svg';
import { connect } from 'react-redux';

import { ReactComponent as Arrow } from '../../assets/icons/icon-arrow-down.svg';
import styles from './RoundSelect.module.scss';

class RoundSelect extends Component {
  state = { isOpen: false };

  handleClickOutside = () => {
    const { onClickOutside } = this.props;

    if (onClickOutside) {
      onClickOutside(this.closeDropDown);
    } else {
      this.closeDropDown();
    }
  };

  toggleDropDown = () => {
    const { disabled, toggleDropDown } = this.props;
    const { isOpen } = this.state;

    if (!disabled) {
      if (toggleDropDown) {
        toggleDropDown(() => this.setState({ isOpen: !isOpen }));
      } else {
        this.setState({ isOpen: !isOpen });
      }
    }
  };

  closeDropDown = () => {
    this.setState({ isOpen: false });
  };

  selectItem = item => () => {
    const { onChange, name } = this.props;
    const { value } = item;

    onChange({ name, value });
    this.closeDropDown();
  };

  renderData = items => {
    const { renderData } = this.props;
    const { isOpen } = this.state;

    const isRenderCustom = renderData;

    const dropDownClassName = cn(styles.DropDown, {
      [styles.OpenDropDown]: isOpen,
    });

    if (isRenderCustom) {
      return renderData(items, this.closeDropDown);
    }

    return (
      <div className={dropDownClassName}>
        {items.map((item, i) => {
          const { value } = this.props;

          return (
            <div className={styles.ListItem} onClick={this.selectItem(item)} key={i}>
              {item.label ? item.label : <Translate id={item.labelId} />}
              {item.value === value ? <IconCheck /> : null}
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const { data, value, disabled, label, noBorder } = this.props;
    const { isOpen } = this.state;

    const selectedItem = data.filter(item => item.value === value)[0];

    const selectClassName = cn(styles.Select, {
      [styles.DisabledSelect]: disabled,
      [styles.NoBorder]: noBorder,
    });
    const arrowClassName = cn(styles.Arrow, {
      [styles.DisabledArrow]: disabled,
      [styles.ArrowDown]: isOpen,
    });
    const dropDownContainerClassName = cn(styles.DropDownContainer, {
      [styles.OpenDropDownContainer]: isOpen,
    });

    const translateId = selectedItem.labelId;
    const selectedItemLabel = selectedItem.label;

    return (
      <div className={styles.RoundSelect}>
        <div className={selectClassName} onClick={this.toggleDropDown}>
          {label ? label : selectedItemLabel ? selectedItemLabel : <Translate id={translateId} />}
          <Arrow className={arrowClassName} />
        </div>
        <div className={dropDownContainerClassName}>{this.renderData(data)}</div>
      </div>
    );
  }
}

RoundSelect.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
  toggleDropDown: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onRangeChange: PropTypes.func,
  lang: PropTypes.string.isRequired,
  label: PropTypes.string,
  renderData: PropTypes.func,
  onClickOutside: PropTypes.func,
  noBorder: PropTypes.bool,
};

RoundSelect.defaultProps = {
  onChange: () => {},
  disabled: false,
  value: '',
  data: [],
  name: '',
  noBorder: false,
};

const mapStateToProps = state => {
  const lang = state.localize.languages.filter(l => l.active)[0].code;
  return {
    lang,
  };
};

export default connect(mapStateToProps)(onClickOutside(RoundSelect));
