import { createAction } from 'redux-act';
import moment from 'moment';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { get as lodashGet } from 'lodash';

import {
  buildUrlParams,
  encodeDataURI,
  get,
  getCurrencyFullName,
  getCurrencySymbol,
  getErrorMessage,
  getLocaleDateFormat,
  isEmpty,
  isObject,
  labelMaker,
  shaveNumber,
  statusMaster,
} from 'shared/utils';
import {
  newMessage,
  postMessage,
  resetSignature,
  postMessageSuccess,
  showSignature,
} from '../signature';

import { hideNotification, showWarnNotification } from '../notification';
import { hideOverlay, showOverlay } from '../overlay';

import apiServicesConstructor from 'services/apiService';
import { fetchAccounts } from 'actions/accounts';

import {
  fetchLoanReq,
  fetchLoansReq,
  fetchNextPaymentReq,
  sendFileReq,
  updateLoansReq,
  fetchScheduleReq,
  changeLoanNameReq,
  fetchPrepaymentDaysOffReq,
  fetchLoanHistoryReq,
  calculateCreditReq,
  addPrepayApplicationReq,
  fetchPrepayApplicationsReq,
  fetchApplicationsStatusesReq,
  deletePrepayApplicationReq,
  setProductVisibilityReq,
  fetchBicReq,
  fetchCurrenciesReq,
  senSignOperation,
  validateSignOperation,
} from './productsRequests';

const lError = labelMaker('errorsGeneral');
const label = labelMaker('products');
const lShared = labelMaker('shared');

export const activateLoan = createAction('SET_ACTIVE_LOAN');
export const calculateCreditError = createAction('CALCULATE_CREDIT_ERROR');
export const calculateCreditStart = createAction('CALCULATE_CREDIT_START');
export const calculateCreditSuccess = createAction('CALCULATE_CREDIT_SUCCESS');
export const calculateLoansPastDueStart = createAction('CALCULATE_LOANS_PAST_DUE_START');
export const cancelPrepayment = createAction('CANCEL_PREPAYMENT');
export const changeLoanNameFailure = createAction('CHANGE_LOAN_NAME_FAILURE');
export const changeLoanNameStart = createAction('CHANGE_LOAN_NAME_START');
export const changeLoanNameSuccess = createAction('CHANGE_LOAN_NAME_SUCCESS');
export const confirmPrepayApplicationError = createAction('CONFIRM_PREPAY_APPLICATION_ERROR');
export const confirmPrepayApplicationStart = createAction('CONFIRM_PREPAY_APPLICATION_START');
export const confirmPrepayApplicationSuccess = createAction('CONFIRM_PREPAY_APPLICATION_SUCCESS');
export const deleteApplicationsError = createAction('DELETE_APPLICATIONS_ERROR');
export const deleteApplicationsStart = createAction('DELETE_APPLICATIONS_START');
export const deleteApplicationsSuccess = createAction('DELETE_APPLICATIONS_SUCCESS');
export const fetchAccountsError = createAction('FETCH_ACCOUNTS_ERROR');
export const fetchAccountsStart = createAction('FETCH_ACCOUNTS_START');
export const fetchAccountsSuccess = createAction('FETCH_ACCOUNTS_SUCCESS');
export const fetchApplicationsError = createAction('FETCH_APPLICATIONS_ERROR');
export const fetchApplicationsStart = createAction('FETCH_APPLICATIONS_START');
export const fetchApplicationsStatusesError = createAction('FETCH_APPLICATIONS_STATUSES_ERROR');
export const fetchApplicationsStatusesStart = createAction('FETCH_APPLICATIONS_STATUSES_START');
export const fetchApplicationsStatusesSuccess = createAction('FETCH_APPLICATIONS_STATUSES_SUCCESS');
export const fetchApplicationsSuccess = createAction('FETCH_APPLICATIONS_SUCCESS');
export const fetchBicInfoSuccess = createAction('FETCH_BIC_INFO_SUCCESS');
export const fetchHistoryListError = createAction('FETCH_HISTORY_LIST_ERROR');
export const fetchHistoryListStart = createAction('FETCH_HISTORY_LIST_START');
export const fetchHistoryListSuccess = createAction('FETCH_HISTORY_LIST_SUCCESS');
export const fetchLoanError = createAction('FETCH_LOAN_ERROR');
export const fetchLoanHistoryError = createAction('FETCH_LOAN_HISTORY_ERROR');
export const fetchLoanHistoryStart = createAction('FETCH_LOAN_HISTORY_START');
export const fetchLoanHistorySuccess = createAction('FETCH_LOAN_HISTORY_SUCCESS');
export const fetchLoanStart = createAction('FETCH_LOAN_START');
export const fetchLoanSuccess = createAction('FETCH_LOAN_SUCCESS');
export const fetchLoansError = createAction('FETCH_LOANS_ERROR');
export const fetchLoansStart = createAction('FETCH_LOANS_START');
export const fetchLoansSuccess = createAction('FETCH_LOANS_SUCCESS');
export const fetchMoreApplicationsError = createAction('FETCH_MORE_APPLICATIONS_ERROR');
export const fetchMoreApplicationsStart = createAction('FETCH_MORE_APPLICATIONS_START');
export const fetchMoreApplicationsSuccess = createAction('FETCH_MORE_APPLICATIONS_SUCCESS');
export const fetchNextPaymentError = createAction('FETCH_NEXT_PAYMENT_ERROR');
export const fetchNextPaymentStart = createAction('FETCH_NEXT_PAYMENT_START');
export const fetchNextPaymentSuccess = createAction('FETCH_NEXT_PAYMENT_SUCCESS');
export const fetchPrepaymentDaysOffFailure = createAction('FETCH_PREPAYMENT_DAYS_OFF_FAILURE');
export const fetchPrepaymentDaysOffStart = createAction('FETCH_PREPAYMENT_DAYS_OFF_START');
export const fetchPrepaymentDaysOffSuccess = createAction('FETCH_PREPAYMENT_DAYS_OFF_SUCCESS');
export const fetchScheduleError = createAction('FETCH_SCHEDULE_ERROR');
export const fetchScheduleStart = createAction('FETCH_SCHEDULE_START');
export const fetchScheduleSuccess = createAction('FETCH_SCHEDULE_SUCCESS');
export const finishPrepaymentError = createAction('FINISH_PREPAYMENT_ERROR');
export const finishPrepaymentStart = createAction('FINISH_PREPAYMENT_START');
export const finishPrepaymentSuccess = createAction('FINISH_PREPAYMENT_SUCCESS');
export const increaseApplicationsShowCount = createAction('INCREASE_APPLICATIONS_SHOW_COUNT');
export const initializePrepaymentFailure = createAction('INITIALIZE_PREPAYMENT_FAILURE');
export const initializePrepaymentStart = createAction('INITIALIZE_PREPAYMENT_START');
export const initializePrepaymentSuccess = createAction('INITIALIZE_PREPAYMENT_SUCCESS');
export const newPrepayApplicationError = createAction('NEW_PREPAY_APPLICATION_ERROR');
export const newPrepayApplicationStart = createAction('NEW_PREPAY_APPLICATION_START');
export const newPrepayApplicationSuccess = createAction('NEW_PREPAY_APPLICATION_SUCCESS');
export const postPrepayApplicationError = createAction('POST_PREPAY_APPLICATION_ERROR');
export const postPrepayApplicationStart = createAction('POST_PREPAY_APPLICATION_START');
export const postPrepayApplicationSuccess = createAction('POST_PREPAY_APPLICATION_SUCCESS');
export const prepayApplicationError = createAction('PREPAY_APPLICATION_ERROR');
export const prepayApplicationStart = createAction('PREPAY_APPLICATION_START');
export const prepayApplicationSuccess = createAction('PREPAY_APPLICATION_SUCCESS');
export const prepaymentBackStep = createAction('PREPAYMENT_BACK_STEP');
export const prepaymentNextStep = createAction('PREPAYMENT_NEXT_STEP');
export const requestOTPOnPrepayApplicationError = createAction(
  'REQUEST_OTP_ON_PREPAY_APPLICATION_ERROR'
);
export const requestOTPOnPrepayApplicationStart = createAction(
  'REQUEST_OTP_ON_PREPAY_APPLICATION_START'
);
export const requestOTPOnPrepayApplicationSuccess = createAction(
  'REQUEST_OTP_ON_PREPAY_APPLICATION_SUCCESS'
);
export const resetProductState = createAction('RESET_PRODUCT_STATE');
export const sendFileToEmailError = createAction('SEND_FILE_TO_EMAIL_ERROR');
export const sendFileToEmailStart = createAction('SEND_FILE_TO_EMAIL_START');
export const sendFileToEmailSuccess = createAction('SEND_FILE_TO_EMAIL_SUCCESS');
export const setCurrenciesModule = createAction('SET_CURRENCIES_MODULE');
export const setProductVisibilityError = createAction('SET_PRODUCT_VISIBILITY_ERROR');
export const setProductVisibilityStart = createAction('SET_PRODUCT_VISIBILITY_START');
export const setProductVisibilitySuccess = createAction('SET_PRODUCT_VISIBILITY_SUCCESS');
export const startPrepayment = createAction('START_PREPAYMENT');
export const resetReceiveTime = createAction('RESET_RECEIVE_TIME');
export const setDueDateOn = createAction('SET_DUE_DATE_ON');

export const printDocument = url => (dispatch, getState) => {
  const apiServices = apiServicesConstructor(dispatch, getState);
  apiServices
    .get({ url })
    .then(res => {
      const WinPrint = window.open('', '_blank', 'left=50,top=50,width=800,height=640,toolbar=0,scrollbars=1,status=0');
      WinPrint.document.write(res);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.onload = () => {
        WinPrint.print();
      };
    })
};

export const resetState = () => dispatch => {
  dispatch(resetProductState());
};

export const fetchLoans = () => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);

  try {
    dispatch(fetchLoansStart());
    dispatch(showOverlay());
    const loans = await dispatch(fetchLoansReq());
    const error = get(loans, 'response.error');

    if (error) {
      throw error;
    }

    if (Array.isArray(loans)) {
      dispatch(hideOverlay());
      dispatch(fetchLoansSuccess(loans));
      dispatch(pastDueWarnMessage(loans));
      return;
    }

    throw new Error();
  } catch (err) {
    dispatch(hideOverlay());
    if (err.code === 500) {
      return dispatch(fetchLoansSuccess([]));
    }
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(fetchLoansError());
    dispatch(showWarnNotification(errMessage));
  }
};

// TODO: check after refactoring
export const updateLoans = ({ forceUpdate = true }) => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(updateLoansReq(forceUpdate));
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
  }
};

const pastDueWarnMessage = loans => (dispatch, getState) => {
  dispatch(calculateLoansPastDueStart());
  const { localize } = getState();
  const translate = getTranslate(localize);

  let contractRef = '',
    pastDueDays = 0;
  const pastDueSum = {
    RUB: 0,
    USD: 0,
    EUR: 0,
  };

  if (Array.isArray(loans)) {
    loans.forEach(loan => {
      // check arrears in loans
      if (
        loan.pastdue &&
        loan.pastdue[0] &&
        (loan.pastdue[0].pastdueAmount && loan.pastdue[0].numOfDaysPastDueAmount)
      ) {
        const loanPastDueDays = parseFloat(loan.pastdue[0].numOfDaysPastDueAmount);

        // if due days more than previous - save it with contractRef
        if (loanPastDueDays > pastDueDays) {
          contractRef = loan.contractRef;
          pastDueDays = loanPastDueDays;
        }
        // RUB and RUR is the same currency
        const ccy = loan.ccy === 'RUR' ? 'RUB' : loan.ccy;

        pastDueSum[ccy] = pastDueSum[ccy] + parseFloat(loan.pastdue[0].pastdueAmount);
      }
    });
  }
  //if arrears is found
  if (contractRef) {
    // combine all amounts of arrears in different currencies in one line
    let totalSumString = pastDueSum['RUB']
      ? `${pastDueSum['RUB']}${getCurrencySymbol('RUB')}; `
      : '';
    totalSumString += pastDueSum['USD'] ? `${pastDueSum['USD']}${getCurrencySymbol('USD')}; ` : '';
    totalSumString += pastDueSum['EUR'] ? `${pastDueSum['EUR']}${getCurrencySymbol('EUR')}; ` : '';

    //remove last semicolon and space
    totalSumString = totalSumString.slice(0, -2);

    // convert days number to text
    const daysString = moment.duration(pastDueDays, 'days').humanize();

    // create props for warn message
    const translatedMessage = translate(lShared('creditListItem.pastDue'), {
      // eslint-disable-next-line no-useless-escape
      sum: Math.trunc(totalSumString.replace(/[^\d\.]/g, '') * 100) / 100,
      days: daysString,
    });
    const linkData = {
      translateId: lShared('creditListItem.payPastDue'),
      route: `products/loans/${contractRef}/payments`,
    };

    // return the action to be performed in fetchLoans and fetchLoan
    dispatch(showWarnNotification({ message: translatedMessage, link: linkData }));
  }
};

export const fetchLoan = ({ id, forceUpdate = true }) => async (dispatch, getState) => {
  const { localize, products } = getState();
  const translate = getTranslate(localize);

  const loans = products.credits;
  try {
    dispatch(showOverlay());
    dispatch(fetchLoanStart(id));

    const credit = await dispatch(fetchLoanReq({ id, forceUpdate }));

    dispatch(hideNotification());
    dispatch(fetchLoanSuccess(credit));
    dispatch(pastDueWarnMessage(loans));
  } catch (err) {
    if (err.code === 500) {
      dispatch(showWarnNotification(translate(lError('500_getLoan'))));
    } else {
      const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
      dispatch(showWarnNotification(errMessage));
    }

    dispatch(fetchLoanError({ id }));
    dispatch(hideOverlay());
  }
};

export const fetchSchedule = ({ id, dateWith, dateOn }) => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(showOverlay());
    dispatch(fetchScheduleStart(id));
    const response = await dispatch(fetchScheduleReq({ id, dateWith, dateOn }));
    dispatch(hideNotification());
    dispatch(hideOverlay());
    dispatch(fetchScheduleSuccess({ res: response, id }));
  } catch (err) {
    dispatch(hideOverlay());
    if (err.code === 500) {
      const message = translate(lError('500_getLoan'));

      dispatch(showWarnNotification(message));
    } else {
      const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
      dispatch(showWarnNotification(errMessage));
    }
    dispatch(fetchScheduleError());
  }
};

// TODO: didn't work - 403
export const changeLoanName = (id, name) => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(changeLoanNameStart(id));

    const response = await dispatch(changeLoanNameReq({ id, name }));
    dispatch(changeLoanNameSuccess(response));
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(changeLoanNameFailure(id));
  }
};

export const fetchPrepaymentDaysOff = () => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    const response = await dispatch(fetchPrepaymentDaysOffReq());

    if (response.error) {
      throw response.error;
    }

    dispatch(fetchPrepaymentDaysOffSuccess(response));
    return Promise.resolve(response);
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(fetchPrepaymentDaysOffFailure(errMessage));
    return Promise.reject(errMessage);
  }
};

export const fetchLoanHistory = ({ dateWith, dateOn }) => async (dispatch, getState) => {
  const {
    localize,
    products,
    accounts: { data: accounts },
  } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(fetchLoanHistoryStart());
    dispatch(showOverlay());

    const { credit } = products;

    const creditAccount = accounts.find(acc => acc.accountNo === credit.drsettlementacc);
    const defaultCreditAccount = accounts[0];

    const requestData = {
      accountNumber: creditAccount ? creditAccount.accountNo : defaultCreditAccount.accountNo,
      branch: creditAccount ? creditAccount.branch : defaultCreditAccount.branch,
      fromDate: dateWith,
      toDate: dateOn,
    };

    const response = await dispatch(fetchLoanHistoryReq(requestData));

    if (isObject(response)) {
      dispatch(fetchLoanHistorySuccess(response));
      dispatch(hideOverlay());
    } else {
      throw translate(lError('unavailableService'));
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(hideOverlay());
    dispatch(fetchLoanHistoryError());
  }
};

export const initializeLoanHistory = args => async (dispatch, getState) => {
  const {
    localize,
    products,
    accounts: { data: currentAccounts },
  } = getState();
  const { credit } = products;
  const translate = getTranslate(localize);

  try {
    const { drsettlementacc } = credit;

    const { startDate, endDate } = args;

    const historyReqData = {
      accountNumber: '',
      branch: '',

      fromDate: startDate,
      toDate: endDate,
    };

    dispatch(fetchLoanHistoryStart());
    if (!Array.isArray(currentAccounts)) {
      dispatch(showOverlay());
      const accounts = await dispatch(fetchAccounts());

      const creditAccount = accounts.find(acc => acc.accountNo === drsettlementacc);
      const defaultAccount = accounts[0];

      historyReqData.accountNumber = creditAccount
        ? creditAccount.accountNo
        : defaultAccount.accountNo;
      historyReqData.branch = creditAccount ? creditAccount.branch : defaultAccount.branch;
    } else {
      const creditAccount = currentAccounts.find(acc => acc.accountNo === drsettlementacc);
      const defaultAccount = currentAccounts[0];

      historyReqData.accountNumber = creditAccount
        ? creditAccount.accountNo
        : defaultAccount.accountNo;
      historyReqData.branch = creditAccount ? creditAccount.branch : defaultAccount.branch;
    }

    const response = await dispatch(fetchLoanHistoryReq(historyReqData));

    if (isObject(response)) {
      dispatch(fetchLoanHistorySuccess(response));
      dispatch(hideOverlay());
    } else {
      throw translate(lError('unavailableService'));
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(fetchLoanHistoryError(errMessage));
  }
};

export const setActiveLoan = id => dispatch => {
  dispatch(activateLoan(id));
};

export const calculateCredit = reqData => async (dispatch, getState) => {
  const { localize, products } = getState();
  const { loanAccount, paymentSum, paymentDate, lastPaymentSum } = reqData;
  try {
    dispatch(showOverlay());

    const { principal } = products.credit;

    const paymentType =
      parseFloat(principal).toFixed(2) === parseFloat(paymentSum).toFixed(2)
        ? 'FullRepayment'
        : 'PartRepayment';
    const dateFormat = getLocaleDateFormat(getActiveLanguage(localize).code);

    dispatch(
      calculateCreditStart({
        paymentDate: moment(paymentDate, dateFormat).format('YYYY-MM-DD'),
        paymentType,
        paymentSum,
      })
    );
    const time = moment('12:00', 'HH:mm');

    const formattedDate = moment(paymentDate, dateFormat)
      .set({ hours: time.get('hours'), minutes: time.get('minutes') })
      .format();

    const data = {
      loanaccount: loanAccount,
    };

    if (paymentType === 'PartRepayment') {
      data.partpayment = {
        partpaymentplan: {
          paymentsum: paymentSum,
          paymentdate: formattedDate,
        },
        lastpaymentsum: lastPaymentSum,
      };
    } else {
      data.fullpayment = {
        paymentplandate: moment(paymentDate, dateFormat).format('YYYY-MM-DD'),
        ovdclosedate: moment(paymentDate, dateFormat).format('YYYY-MM-DD'),
      };
    }
    // TODO: временное решение
    // HOTFIX: https://jira.intranet/browse/DC-3892
    if (paymentType === 'FullRepayment') {
      const responseDueDateOn = await dispatch(calculateCreditReq({
        loanaccount: loanAccount,
        partpayment: {
          partpaymentplan: {
            paymentsum: paymentSum,
            paymentdate: formattedDate,
          },
          lastpaymentsum: lastPaymentSum,
        }}));

      if (responseDueDateOn.checkMessage === 'OK') {
        dispatch(hideNotification());
        dispatch(setDueDateOn(responseDueDateOn));
      } else if (responseDueDateOn.checkMessage) {
        dispatch(showWarnNotification(response.checkMessage));
        dispatch(hideOverlay());
        dispatch(calculateCreditError(response.checkMessage));
      } else {
        throw new Error();
      }
    }

    const response = await dispatch(calculateCreditReq(data));

    if (response.checkMessage === 'OK') {
      dispatch(hideNotification());
      dispatch(calculateCreditSuccess(response));
      dispatch(hideOverlay());
    } else if (response.checkMessage) {
      dispatch(showWarnNotification(response.checkMessage));
      dispatch(hideOverlay());
      dispatch(calculateCreditError(response.checkMessage));
    } else {
      throw new Error();
    }
  } catch (err) {

    // on calcterm error continue to create an application without calculation
    const withoutCalc = {
      loanAccount: loanAccount,
      total: parseFloat(paymentSum).toFixed(2),
      interest: { interestExpected: null, interestOverdue: null },
    }
    dispatch(calculateCreditSuccess(withoutCalc));

    dispatch(hideNotification());
    dispatch(hideOverlay());
  }
};

export const newPrepayApplication = () => async (dispatch, getState) => {
  const { localize, products } = getState();
  const translate = getTranslate(localize);

  try {
    dispatch(newPrepayApplicationStart());
    dispatch(showOverlay());

    await dispatch(newMessage());

    dispatch(newPrepayApplicationSuccess());

    const locale = getActiveLanguage(localize).code;

    const { contractRef, valueDate, ccy } = products.credit;
    const { repayment, paymentType } = products.prepayment.incomingApplication;
    const { amount, date } = repayment;

    const label = labelMaker('products.actions');

    const message = fromPostMessageMessage({
      contractRef,
      valueDate,
      amount,
      date,
      paymentType,
      translate,
      label,
      ccy,
    })[locale];

    const data = {
      docName: translate(label('repaymentTitle')),
      text: message,
    };
    dispatch(postPrepayApplicationStart());
    await dispatch(postMessage({ data }));
    dispatch(postPrepayApplicationSuccess());
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(newPrepayApplicationError(errMessage));
    dispatch(postPrepayApplicationError());
    dispatch(hideOverlay());
  }
};

const fromPostMessageMessage = (args = {}) => {
  const {
    contractRef,
    valueDate,
    amount,
    date,
    paymentType = 'partPayment',
    translate,
    label,
    ccy,
  } = args;

  const isUnderTheMortgage = contractRef.includes('M');
  const isCarLoan = contractRef.includes('A');
  const isConsumerLoan = contractRef.includes('C');

  const creditType = isUnderTheMortgage
    ? translate(label('underTheMortgage'))
    : isCarLoan
      ? translate(label('carLoan'))
      : isConsumerLoan
        ? translate(label('consumerLoan'))
        : '';

  const isFullPayment = paymentType === 'FullRepayment';
  const amountMessage = isFullPayment
    ? translate(label('fullPaymentAmount'))
    : translate(label('partPaymentAmount'))
      .replace('$amount', amount)
      .replace('$ccy', getCurrencyFullName(ccy));

  const messageRu = `Из личного кабинета отправлено заявление на досрочное погашение задолженности ${date} по номер кредитного договора ${contractRef},  о предоставлении кредита ${creditType}, дата заключения договора ${moment(
    valueDate
  ).format(
    'DD.MM.YYYY'
  )}, в размере ${amountMessage} по договору о предоставлении кредита, включая начисленные проценты, существующие на дату досрочного погашения`;
  const messageEn = `An application for repayment on ${date}, for a credit contract ${contractRef}, under the ${creditType}, agreement dated  ${moment(
    valueDate
  ).format(
    'DD.MM.YYYY'
  )}, has been sent from Enter.UC.Repayment amount is ${amountMessage}, and the interest accrued on the repayment date`;

  return {
    en: messageEn,
    ru: messageRu,
  };
};

export const confirmPrepayApplication = ({ token, sum, date, otpCode }) => async (dispatch, getState) => {
  const { localize, products } = getState();
  const translate = getTranslate(localize);
  try {
    const { applications } = products.prepayment;

    const newApp = Array.isArray(applications)
      ? applications.find(app => app.status === 'NEW')
      : false;

    if (newApp) {
      await dispatch(deleteApplication(newApp.id));
      dispatch(prepayApplication({ date, sum, token, otpCode }));
    } else {
      dispatch(prepayApplication({ date, sum, token, otpCode }));
    }
    return;
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(confirmPrepayApplicationError());
    dispatch(showWarnNotification(errMessage));
  }
};

export const prepayApplication = ({ date, sum, token, otpCode }) => async (dispatch, getState) => {
  const {
    localize,
    auth,
    products,
    accounts: { data: currentAccounts },
  } = getState();
  const { receiveTime: prevReceiveTime } = get(products, 'prepayment.incomingApplication', {});
  const translate = getTranslate(localize);
  try {
    dispatch(prepayApplicationStart());
    dispatch(showOverlay());

    const dateFormat = getLocaleDateFormat(getActiveLanguage(localize).code);

    const {
      contractRef,
      principal,
      payments,
      repayAmount,
      repayInterest,
      valueDate,
      schedulesCount,
      maturityDate,
      drsettlementacc,
      ccy,
      buyBack,
      pastdue,
    } = products.credit;

    const lastPayment = Array.isArray(payments) && payments[payments.length - 1];
    let creditAccount;
    if (currentAccounts) {
      creditAccount =
        Array.isArray(currentAccounts) &&
        currentAccounts.find(acc => acc.accountNo === drsettlementacc);
    } else {
      dispatch(showOverlay());
      const accounts = await dispatch(fetchAccounts());
      creditAccount =
        Array.isArray(accounts) && accounts.find(acc => acc.accountNo === drsettlementacc);
    }

    const { abscustid, fullname } = auth.user;

    // TODO: Remove hardcode
    const lastNewPayment = {
      interestRate: '19.9',
      toDate: moment(date, dateFormat).format('YYYY-MM-DD'),
    };

    const {
      newFullLoanAmount,
      lastPaymentCalc,
      loanAccount,
      newAnnuity,
      interest: { interestExpected = null, interestOverdue = null },
      dueDateOn,
      penalty,
      total,
      interestOnOverduePrincipalAccrued,
      principal: { principalOverdue = null }
    } = products.prepayment.incomingApplication;

    const currentAnnuity = parseFloat(repayAmount) + parseFloat(repayInterest);

    const notification = 'ENTERIMB';
    const status = !newAnnuity ? 'INCOMPLETE' : 'NEW';
    const channelDocID = `EUC${Math.floor(Math.random() * Math.floor(100000))}`;

    const agreementBuyBackFlag = buyBack ? 'Y' : 'N';
    const channelName = 'ENTERIMB';
    // Необходимо для успешной валидации повторного запроса prepayApplication после 2FA
    const receiveTime = prevReceiveTime || moment().format();

    const signdate = moment().format('YYYY-MM-DD');
    const lastPaymentControl = 11;

    const nextPaymentDate = lastPayment && moment(lastPayment.nextPaymentDate).format('YYYY-MM-DD');
    const paymentDate = moment(date, dateFormat).format('YYYY-MM-DD');

    const isPaymentBeforeNextPaymentDate = nextPaymentDate > paymentDate;
    const isPaymentAfterNextPaymentDate = nextPaymentDate < paymentDate;
    const isPaymentSameNextPaymentDate =
      !isPaymentAfterNextPaymentDate && !isPaymentBeforeNextPaymentDate;

    const paymentType =
      parseFloat(principal) === parseFloat(sum) ? 'FullRepayment' : 'PartRepayment';

    // Fix for UN-2424
    const modifiedMaturityDateWithTime = moment(maturityDate).set({ h: 12, m: 0, s: 0 }).format('YYYY-MM-DDTHH:mm:ss');

    const paymentData = {
      loanAccount,
      signdate,
      // signtype,
      status,
      notification,
      currency: ccy,
      customerName: fullname,
      customerNumber: abscustid,
      operationType: paymentType,

      loanContractDetails: {
        loanNumber: contractRef,
        agreementBuyBackFlag,
        monthlyPaymentDay: dueDateOn,
        issueDate: valueDate,
        new: {
          newAnnuity: paymentType === 'FullRepayment' ? 0 : shaveNumber(newAnnuity),
          newFullLoanAmount: paymentType === 'FullRepayment' ? 0 : shaveNumber(newFullLoanAmount),
          lastPaymentCalc: paymentType === 'FullRepayment' ? 0 : shaveNumber(lastPaymentCalc),
          newInterestRate: lastPayment && shaveNumber(lastPayment.currentInterestRate),
          newInterestRateDate: lastNewPayment.toDate,
        },
        current: {
          lastPaymentControl,
          principalExpected: shaveNumber(principal),
          interestRate: lastPayment && shaveNumber(lastPayment.interestRateVariableComponent),
          currentRemain: lastPayment && shaveNumber(lastPayment.currentOutstanding),
          currentAnnuity: shaveNumber(currentAnnuity),
          lastPaymentSum: shaveNumber(currentAnnuity),
          total: shaveNumber(total),
          expectedPaymentsCount: schedulesCount,
          nextPaymentDate: lastPayment && lastPayment.nextPaymentDate,
          lastPaymentDate: modifiedMaturityDateWithTime,
        },
      },
      sourceChannelReferenceDto: {
        channelName,
        channelDocID,
        receiveTime,
      },
      repayment: {
        account: contractRef,
        date: moment(date, dateFormat).format('YYYY-MM-DD'),
        amount: shaveNumber(sum),
      },
      branch: creditAccount && creditAccount.branch,
      accountNumber: creditAccount && creditAccount.cbAccountNo,
    };

    if (parseFloat(interestOverdue) !== 0 && !isEmpty(penalty)) {
      paymentData.overdue = {
        interest: {
          overdue: parseFloat(interestOverdue || 0),
          penalty: parseFloat(penalty.penaltyInterest || 0)
        },
        interestOnOverdue: parseFloat(interestOnOverduePrincipalAccrued || 0),
        overdueCloseDate: "",
        principal: {
          overdue: parseFloat(principalOverdue || 0),
          penalty: parseFloat(penalty.penaltyPrincipal || 0),
        }
      };
    }

    const creditPastdue = lodashGet(pastdue, '[0].numOfDaysPastDueAmount', '0') || lodashGet(pastdue, 'numOfDaysPastDueAmount', '0');

    if (parseFloat(creditPastdue) > 0) {
      if (!paymentData.overdue) {
        paymentData.overdue = {};
      }
      paymentData.overdue.overdueCloseDate = date;
    }

    if (paymentType === 'PartRepayment') {
      if (isPaymentSameNextPaymentDate) {
        paymentData.loanContractDetails.current = {
          ...paymentData.loanContractDetails.current,

          interestExpected: parseFloat(interestExpected),
        };
      } else {
        paymentData.loanContractDetails.current = {
          ...paymentData.loanContractDetails.current,

          interestExpected: 0,
        };
      }
    } else {
      paymentData.loanContractDetails.current = {
        ...paymentData.loanContractDetails.current,

        interestExpected: parseFloat(interestExpected),
      };
      paymentData.repayment = {
        ...paymentData.repayment,

        amount: parseFloat(total),
      };
    }
    if (token) {
      paymentData.token = token;
      paymentData.otpCode = otpCode;
    }

    const response = await dispatch(addPrepayApplicationReq(paymentData));

    if (get(response, 'code') === 108) {
      const signingRequestId = get(response, 'data.signingRequestId');
      const resp1 = await dispatch(senSignOperation(signingRequestId));
      const {
        status: newStatus,
        otpFlowState,
        extendedAttributes,
      } = resp1;
      const application = {
        type: paymentType,
        status: newStatus,
        otpFlowState,
        receiveTime,
      };
      const {
        get2FA: {
          tankeyParams,
          mobipassParams,
          smsParams,
        },
      } = extendedAttributes;

      let params = {};
      if (tankeyParams) {
        params.confirmType = 'tan';
        params.tankeyNumber = tankeyParams.tankeyNumber;
        params.codeLength = 6;
      }
      if (mobipassParams) {
        params.confirmType = 'mobipass';
        params.codeLength = 6;
        params.mobipassKey = mobipassParams.mobipassKey;
      }
      if (smsParams) {
        params.confirmType = 'sms_code';
        params = { ...params, ...smsParams };
      }
      dispatch(postMessageSuccess({ settings: params }));
      dispatch(prepayApplicationSuccess(application));
      dispatch(showSignature());
    } else {
      dispatch(resetReceiveTime());
      dispatch(prepaymentNextStep());
    }
    dispatch(hideOverlay());
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(prepayApplicationError(errMessage));
    dispatch(resetSignature());
    dispatch(hideOverlay());
  }
};

export const prepay2FA = ({ otpCode, date, sum }) => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(newPrepayApplicationStart());
    const { products } = getState();
    const { execution,sessionId } = get(products, 'prepayment.incomingApplication.otpFlowState', {});

    const { token } = await dispatch(validateSignOperation({ otpCode, execution, sessionId }));
    dispatch(confirmPrepayApplication({ date, sum, token, otpCode }));
    dispatch(postPrepayApplicationSuccess());
  } catch (error) {
    const errMessage = getErrorMessage(error, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(prepayApplicationError(errMessage));
    dispatch(resetSignature());
    dispatch(hideOverlay());
  }
}

export const initializePrepayment = () => async (dispatch, getState) => {
  const {
    localize,
    accounts: { data: accounts },
    applications,
  } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(initializePrepaymentStart());
    dispatch(showOverlay());

    let accountsFetchedStatus = Array.isArray(applications)
      ? statusMaster.getSuccess()
      : statusMaster.getInProgress();
    let applicationsFetcherStatus = Array.isArray(accounts)
      ? statusMaster.getSuccess()
      : statusMaster.getInProgress();

    const removeOverlay = () => {
      if (
        statusMaster.checkNoProgressStatus(accountsFetchedStatus) &&
        statusMaster.checkNoProgressStatus(applicationsFetcherStatus)
      ) {
        dispatch(hideOverlay());
      }
    };

    if (!Array.isArray(applications)) {
      await dispatch(fetchApplications());
      accountsFetchedStatus = statusMaster.getSuccess();
      removeOverlay();
    }

    if (!Array.isArray(accounts)) {
      await dispatch(fetchAccounts());
      applicationsFetcherStatus = statusMaster.getSuccess();
      removeOverlay();
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(initializePrepaymentFailure());
    dispatch(hideOverlay());
  }
};

export const fetchApplications = (reqData = {}) => async (dispatch, getState) => {
  const { localize, products } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(fetchApplicationsStart());
    const { credit = '' } = products;

    const { pageNumber = 0, pageSize = 50 } = reqData;
    const data = {
      pageNumber,
      pageSize,
      loanAccount: credit.contractRef,
      dateType: 'applicationDate',
      sort: 'desc',
    };

    const response = await dispatch(fetchPrepayApplicationsReq(data));

    dispatch(hideNotification());
    dispatch(fetchApplicationsSuccess({ ...response, pageNumber }));

    const applications = getState().products.prepayment.applications;

    if (Array.isArray(applications) && applications.length) {
      dispatch(fetchApplicationsStatuses({ pageNumber, pageSize }));
      return Promise.resolve();
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(fetchApplicationsError());
    return Promise.reject(errMessage);
  }
};

export const fetchApplicationsStatuses = (
  reqData = { pageNumber: 0, pageSize: 5, dateType: 'applicationDate', sort: 'asc' }
) => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    const response = await dispatch(fetchApplicationsStatusesReq(reqData));
    dispatch(hideNotification());
    dispatch(fetchApplicationsStatusesSuccess(response));
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(fetchApplicationsStatusesError());
  }
};

// TODO: check after refactoring
export const deleteApplication = id => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(deleteApplicationsStart());
    dispatch(showOverlay());
    await dispatch(deletePrepayApplicationReq(id));
    dispatch(deleteApplicationsSuccess(id));
    dispatch(hideNotification());
    dispatch(hideOverlay());
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(deleteApplicationsError());
    dispatch(hideOverlay());
  }
};

// TODO: check after refactoring
export const fetchMoreApplications = (reqData = {}) => async (dispatch, getState) => {
  const { localize, products } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(fetchMoreApplicationsStart());

    const { credit = '' } = products;

    const { pageNumber = 0, pageSize = 5 } = reqData;
    const data = {
      pageNumber,
      pageSize,
      loanAccount: credit.contractRef,
      dateType: 'applicationDate',
      sort: 'desc',
    };

    const response = await dispatch(fetchPrepayApplicationsReq(data));

    dispatch(hideNotification());
    dispatch(fetchMoreApplicationsSuccess({ ...response, pageNumber }));
    dispatch(increaseApplicationsShowCount({ count: pageSize }));
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(fetchMoreApplicationsError());
  }
};

export const finishPrepayment = (args = {}) => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(finishPrepaymentStart());
    await dispatch(fetchApplications());
    dispatch(finishPrepaymentSuccess());
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(finishPrepaymentError());
  }
};

export const downloadFile = id => (dispatch, getState) => {
  const params = {
    url: `/webapi-1.0/render/pdf/loans/${id}`,
    responseType: 'blob',
  };

  const { localize } = getState();
  const translate = getTranslate(localize);

  const apiServices = apiServicesConstructor(dispatch, getState);

  apiServices
    .get(params)
    .then(res => {
      const blob = new Blob([res], { type: 'text/csv;charset=utf-8' });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, 'schedule.pdf');
      } else {
        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(blob);
        link.download = 'schedule.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })
    .catch(() => {
      dispatch(showWarnNotification(translate(label('errors.fileDownloading'))));
    });
};

export const printSchedule = id => (dispatch, getState) => {
  dispatch(printDocument(`/webapi-1.0/render/html/loans/${id}`));
};

export const downloadPrepay = id => dispatch => {
  dispatch(
    downloadDocument(`/webapi-1.0/render/pdf/prepayApplication/${id}`, 'early-repayment.pdf')
  );
};

export const downloadHistory = args => dispatch => {
  dispatch(
    downloadDocument(
      `/webapi-1.0/account-operations/csv?${buildUrlParams(encodeDataURI(args))}`,
      'operations-history.csv'
    )
  );
};

export const printPrepay = id => (dispatch, getState) => {
  dispatch(printDocument(`/webapi-1.0/render/html/prepayApplication/${id}`));
};

export const downloadDocument = (url, documentName) => (dispatch, getState) => {
  const params = {
    url,
    responseType: 'blob',
  };

  const { localize } = getState();
  const translate = getTranslate(localize);

  const apiServices = apiServicesConstructor(dispatch, getState);

  apiServices
    .get(params)
    .then(res => {
      const blob = new Blob([res], { type: 'text/csv;charset=utf-8' });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, documentName);
      } else {
        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(blob);
        link.download = documentName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })
    .catch(() => {
      dispatch(showWarnNotification(translate(label('errors.fileDownloading'))));
    });
};

export const downloadRequisites = id => (dispatch, getState) => {
  const params = {
    url: `/webapi-1.0/render/pdf/requisites/${id}`,
    responseType: 'blob',
  };

  const { localize } = getState();
  const translate = getTranslate(localize);

  const apiServices = apiServicesConstructor(dispatch, getState);

  apiServices
    .get(params)
    .then(res => {
      const blob = new Blob([res], { type: 'text/csv;charset=utf-8' });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, 'Requisites.pdf');
      } else {
        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(blob);
        link.download = 'Requisites.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })
    .catch(() => {
      dispatch(showWarnNotification(translate(label('errors.fileDownloading'))));
    });
};

export const printRequisites = id => (dispatch, getState) => {
  dispatch(printDocument(`/webapi-1.0/render/html/requisites/${id}`))
};

export const downloadCreditInfo = id => (dispatch, getState) => {
  const params = {
    url: `/webapi-1.0/render/pdf/account/${id}`,
    responseType: 'blob',
  };

  const { localize } = getState();
  const translate = getTranslate(localize);

  const apiServices = apiServicesConstructor(dispatch, getState);

  apiServices
    .get(params)
    .then(res => {
      const blob = new Blob([res], { type: 'text/csv;charset=utf-8' });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, 'Details.pdf');
      } else {
        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(blob);
        link.download = 'Details.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })
    .catch(() => {
      dispatch(showWarnNotification(translate(label('errors.fileDownloading'))));
    });
};

export const printCreditInfo = id => (dispatch, getState) => {
  dispatch(printDocument(`/webapi-1.0/render/html/account/${id}`));
};

export const getRequisitesInfo = () => async (dispatch, getState) => {
  const {
    localize,
    products,
    accounts: { data: currentAccounts },
  } = getState();
  const translate = getTranslate(localize);
  const { credit, requisites: currentRequisites } = products;

  try {
    dispatch(showOverlay());
    let accounts = currentAccounts;
    if (!currentAccounts) {
      accounts = await dispatch(fetchAccounts());
    }
    if (Array.isArray(accounts) && accounts.length) {
      const account = accounts.find(account => account.accountNo === credit.drsettlementacc);
      let bic = '';
      if (account) {
        bic = account.CorBank;
      } else {
        bic = accounts[0].CorBank;
      }

      let requisites = currentRequisites;
      if (!requisites) {
        requisites = await dispatch(fetchBicReq(bic));
      }
      const currencies = await dispatch(fetchCurrenciesReq());
      let currenciesModule = [];

      currenciesModule = currencies.map(ccy => ({
        ...ccy,
        textEn: JSON.parse(ccy.textEn),
      }));

      dispatch(setCurrenciesModule(currenciesModule));
      dispatch(fetchBicInfoSuccess(requisites));
      dispatch(hideOverlay());
      dispatch(hideNotification());
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(fetchAccountsError());
    dispatch(showWarnNotification(errMessage));
    dispatch(hideOverlay());
  }
};

const getProductType = type => {
  if (typeof type !== 'string') {
    return 'LOAN';
  } else {
    return type.toUpperCase();
  }
};

const getVisibleMask = (visible, currentVisibilityRule) => {
  if (typeof visible !== 'boolean' || typeof currentVisibilityRule !== 'number') {
    throw new Error("Can't check current visibility of product");
  }
  return !visible ? currentVisibilityRule - 2 : currentVisibilityRule + 2;
};

// TODO: didn't work - 403
export const setProductVisibility = (id, visible, type) => async (dispatch, getState) => {
  dispatch(setProductVisibilityStart());
  const {
    localize,
    products: { credit },
  } = getState();
  const translate = getTranslate(localize);
  try {
    const visibilityRuleDto = {
      id: id,
      mask: getVisibleMask(visible, credit.visibilityRule),
    };

    const productType = { productType: getProductType(type) };

    await dispatch(setProductVisibilityReq(productType, visibilityRuleDto));

    dispatch(setProductVisibilitySuccess({ id, visibilityRule: visibilityRuleDto.mask }));
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(setProductVisibilityError());
    dispatch(showWarnNotification(errMessage));
  }
};

export const fetchNextPayment = reqData => async (dispatch, getState) => {
  const { localize, products } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(fetchNextPaymentStart());

    const { sum } = reqData;
    const { credit } = products;

    if (sum === 0) {
      return dispatch(fetchNextPaymentSuccess({ nextPaymentSum: credit.annuity, savedSum: 0 }));
    }

    if (sum >= parseFloat(credit.principal)) {
      return dispatch(fetchNextPaymentSuccess({ nextPaymentSum: 0, savedSum: credit.principal }));
    }

    const response = await dispatch(fetchNextPaymentReq(reqData));

    // calculate savings
    const nextPayment = parseFloat(response.nextPayment);
    const savedSum = parseFloat(response.savesum);

    return dispatch(
      fetchNextPaymentSuccess({
        nextPaymentSum: nextPayment,
        savedSum,
      })
    );
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(fetchNextPaymentError());
    dispatch(showWarnNotification(errMessage));
  }
};

export const sendPdfFileToEmail = reqData => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(sendFileToEmailStart());
    const { section = '', contractRef = '', id = '', ...query } = reqData;
    await dispatch(sendFileReq({ section, params: contractRef || id, query }));
    dispatch(sendFileToEmailSuccess());
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(sendFileToEmailError());
    dispatch(showWarnNotification(errMessage));
  }
};
