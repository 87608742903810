/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import { Button, Translation, withErrorBoundary } from 'shared';
import PaymentsList from '../components/PaymentsList/PaymentsList';
import WithdrawModal from './components/WithdrawModal/WithdrawModal';

import Form from './Form/Form';

import {
  startPrepayment,
  initializePrepayment,
  cancelPrepayment,
  fetchMoreApplications,
  increaseApplicationsShowCount,
  fetchApplicationsStatuses,
  deleteApplication,
} from 'actions/products';
import { resetSignature } from 'actions/signature';

import { labelMaker, isCreditHolidays as checkCreditHolidays } from 'shared/utils';

import styles from './Prepayment.module.scss';

const label = labelMaker('products.prepayment');
const holidayLabel = labelMaker('products.prepayment.creditHolidays');

class Prepayment extends Component {
  constructor(props) {
    super(props);

    this.timeoutIds = [];
    this.state = {
      showModal: false,
      instanceWithdraw: false,
      prepayId: null,
      isCreditHolidays: false,
    };
  }

  handleShow = () => {
    this.setState({ showModal: true });
  };

  handleInstanceWithdrawModal = id => {
    this.setState({
      showModal: true,
      instanceWithdraw: true,
      prepayId: id,
    });
  };

  handleHide = () => {
    this.setState({ showModal: false, instanceWithdraw: false, prepayId: null });
  };

  handleMoreApps = () => {
    const {
      applications,
      applicationsPage,
      applicationsPageSize,
      fetchMoreApplications,
      increaseApplicationsShowCount,
      showApplicationCount,
    } = this.props;
    const loadedCount = 5;
    const maxApplications = (applicationsPage + 1) * applicationsPageSize;
    if (maxApplications > loadedCount + showApplicationCount) {
      increaseApplicationsShowCount({ count: loadedCount });
    } else {
      const nextPage = Math.ceil(applications.length / applicationsPageSize);

      fetchMoreApplications({ pageNumber: nextPage, pageSize: loadedCount });
    }
  };

  handleStartApplication = () => {
    const { startPrepayment, applications } = this.props;

    const isAppsHasNewStatus = Array.isArray(applications)
      ? applications.find(app => app.status === 'NEW')
      : false;

    if (isAppsHasNewStatus) {
      this.handleShow();
    } else {
      startPrepayment();
    }
  };

  handleStartNewApplication = () => {
    const { startPrepayment } = this.props;

    this.handleHide();
    startPrepayment();
  };

  deletePrepay = id => () => {
    this.props.deleteApplication(id);
  };

  renderModal() {
    if (this.state.showModal) {
      const { instanceWithdraw, prepayId } = this.state;
      const { applications } = this.props;

      const onSubmit = instanceWithdraw
        ? this.deletePrepay(prepayId)
        : this.handleStartNewApplication;

      let isLoading = false;
      if (Array.isArray(applications) && applications.length) {
        const application = applications.find(app => app.id === prepayId);

        if (application) {
          isLoading = application.isLoading;
        }
      }

      return <WithdrawModal onSubmit={onSubmit} onClose={this.handleHide} isLoading={isLoading} />;
    }

    return null;
  }

  renderCreditHolidaysText = () => (
    <>
      <Translation.Span className={styles.Subtitle} translateId={holidayLabel('creditHolidaysDescription')} />
      <>&nbsp;</>
      <Link to="/atms/map">
        <Translation.Span className={styles.Link} translateId={holidayLabel('office')} />
      </Link>
      <>.</>
    </>
  )

  renderMenu() {
    const { isCreditHolidays } = this.state;
    const { prepayment, applications, applicationsNum, showApplicationCount, credit } = this.props;
    const { principal, /*pastdue*/ } = credit;
    const { isRequestPending } = prepayment;
    const isMoreAppsBtnShown = showApplicationCount < applicationsNum;

    const isCreditOverdue = false; // !!(Array.isArray(pastdue) && parseFloat(pastdue[0].numOfDaysPastDueAmount)) || false;

    return (
      <Fragment>
        {this.renderModal()}
        <Translation.Div className={styles.Title} translateId={label('earlyPaymentApplication')} />
        <div className={styles.ButtonApplicationContainer}>
          {isCreditOverdue && <Translation.Div className={styles.PrepaymentErrorMessage} translateId={label('prepaymentOverdueError')} />}
          {
            isCreditHolidays && principal === '0' // UN-3175
            ? this.renderCreditHolidaysText()
            : <Button
              className={styles.ButtonApplication}
              translateId={label('makePrepayment')}
              onClick={this.handleStartApplication}
            />
          }
        </div>
        {applications.slice(0, showApplicationCount).length > 0 ? (
          <Translation.H6 className={styles.Warning} translateId={label('warning')} />
        ) : null}
        <div className={styles.ApplicationsContainer}>
          <PaymentsList
            payments={applications.slice(0, showApplicationCount)}
            withdrawApplication={this.handleInstanceWithdrawModal}
          />
          {isMoreAppsBtnShown ? (
            <Button
              theme="white"
              translateId={label('showMore')}
              onClick={this.handleMoreApps}
              showLoader={isRequestPending}
            />
          ) : null}
        </div>
      </Fragment>
    );
  }

  checkCreditHolidays(udf) {
    const isCreditHolidays = udf.some(el => checkCreditHolidays(el));
    this.setState({ isCreditHolidays });
  }

  componentDidMount() {
    const { initializePrepayment, fetchApplicationsStatuses, prepayment, udf } = this.props;
    const statusesReqInterval = 70000;

    initializePrepayment();

    const resend = () => {
      const { applications } = this.props;
      const applicationsLength = Array.isArray(applications) ? applications.length : 0;
      const applicationPage = 0;

      if (applicationsLength && !prepayment.isPrepaymentExercise) {
        fetchApplicationsStatuses({ pageNumber: applicationPage, pageSize: applicationsLength });
      }

      const timeoutId = setTimeout(resend, statusesReqInterval);
      this.timeoutIds.push(timeoutId);
    };

    if (udf && udf.length) {
      this.checkCreditHolidays(udf);
    }

    this.statusesLoop = setTimeout(resend, statusesReqInterval);
  }

  componentWillReceiveProps(nextProps) {
    const { udf } = this.props;
    if (nextProps.error) {
      this.setState({ userDisabled: nextProps.error === 'too_many_wrong_code', seconds: 0 });
    }
    if (!isEqual(nextProps.udf, udf)) {
      this.checkCreditHolidays(nextProps.udf);
    }
  }

  componentWillUnmount() {
    const { cancelPrepayment, resetSignature } = this.props;

    clearInterval(this.statusesLoop);
    this.timeoutIds.forEach(timeoutId => clearInterval(timeoutId));

    cancelPrepayment();
    resetSignature();
  }

  render() {
    const { prepayment } = this.props;
    const { isCreditHolidays } = this.state;
    const { isPrepaymentExercise } = prepayment;

    return (
      <Fragment>
        <div className={styles.Body}>
          <div className={styles.Content}>
            {isPrepaymentExercise ? <Form isCreditHolidays={isCreditHolidays} /> : this.renderMenu()}
          </div>
        </div>
      </Fragment>
    );
  }
}

Prepayment.propTypes = {
  deleteApplication: PropTypes.func.isRequired,
  cancelPrepayment: PropTypes.func.isRequired,
  applications: PropTypes.array.isRequired,
  startPrepayment: PropTypes.func.isRequired,
  prepayment: PropTypes.object.isRequired,
  initializePrepayment: PropTypes.func.isRequired,
  fetchApplicationsStatuses: PropTypes.func.isRequired,
  fetchMoreApplications: PropTypes.func.isRequired,
  applicationsPages: PropTypes.number.isRequired,
  applicationsPage: PropTypes.number.isRequired,
  applicationsPageSize: PropTypes.number.isRequired,
  showApplicationCount: PropTypes.number.isRequired,
  applicationsNum: PropTypes.number.isRequired,
  increaseApplicationsShowCount: PropTypes.func.isRequired,
  resetSignature: PropTypes.func.isRequired,
  credit: PropTypes.shape({
    pastdue: PropTypes.arrayOf(
      PropTypes.shape({
        numOfDaysPastDueAmount: PropTypes.string,
      }),
    ),
  }).isRequired,
};

const mapStateToProps = ({ products }) => ({
  applications: products.prepayment.applications,
  applicationsPages: products.prepayment.applicationsPages,
  applicationsPage: products.prepayment.applicationsPage,
  applicationsPageSize: products.prepayment.applicationsPageSize,
  showApplicationCount: products.prepayment.showApplicationCount,
  applicationsNum: products.prepayment.applicationsNum,
  prepayment: products.prepayment,
  credit: products.credit,
  udf: (products.credit.udf || []),
});

const mapDispatchToProps = {
  cancelPrepayment,
  deleteApplication,
  startPrepayment,
  initializePrepayment,
  fetchMoreApplications,
  fetchApplicationsStatuses,
  increaseApplicationsShowCount,
  resetSignature,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withErrorBoundary(Prepayment, 'Products/Loans/Prepayment'))
);
