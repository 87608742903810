import Bowser from 'bowser';
import apiServicesConstructor from 'services/apiService';
import { getBrowserVer, buildUrlParams, encodeDataURI, getEncryptedStr } from 'shared/utils';
import {
  loginReqStub,
  loginBannersListReqStub,
  loginBannerReqStub,
  logoutReqStub,
  fetchChangePasswordSettingsReqStub,
  changePasswordReqStub,
  fetch2FactorSettingsReqStub,
  confirm2FactorReqStub,
  // newLoginFetchFormReqStub,
  newLoginFetchTokenReqStub,
  newLoginFetchTokenInfoReqStub,
} from './authStubs';

const isDevelopment = process.env.NODE_ENV === 'development';
const useGlobalStubs = process.env.REACT_APP_USE_STUBS === 'true';

export const loginReq =
  ({ username, password, language, captchaId, captchaValue }, useStub = false, withError = false) =>
  async (dispatch, getState) => {
    if ((useStub && isDevelopment) || useGlobalStubs) {
      return await loginReqStub(withError);
    }

    const { browser, browserVersion } = getBrowserVer();

    const params = {
      username,
      password,
      language,
      browserVersion,
      browser,
    };

    if (captchaValue) {
      params.captchaId = captchaId;
      params.captchaValue = captchaValue;
    }

    const data = buildUrlParams(encodeDataURI(params));
    const reqParams = {
      url: `/sso/customer-webapi-1.0/uni/authentication`,
      data,
    };

    try {
      const apiServices = apiServicesConstructor(dispatch, getState);
      const response = await apiServices.post(reqParams);

      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const logoutReq =
  (useStub = false, withError = false) =>
  async (dispatch, getState) => {
    if ((useStub && isDevelopment) || useGlobalStubs) {
      return await logoutReqStub(withError);
    }

    const reqParams = {
      url: '/sso/customer-webapi-1.0/uni/authentication',
    };

    try {
      const apiServices = apiServicesConstructor(dispatch, getState);
      const response = await apiServices.delete(reqParams);

      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const newLogoutReq = () => async (dispatch, getState) => {
  try {
    const browser = Bowser.getParser(window.navigator.userAgent).getBrowser();

    const reqData = {
      client_id: 'test_oauth2m2m',
      revocation: true,
      browser: browser?.name,
      browserVersion: `${browser?.name} ${browser?.version}`,
      type: 'web_lk',
    };

    const urlParams = buildUrlParams(encodeDataURI(reqData));

    const reqParams = {
      url: `/sso/oauth2-consumer/logout?${urlParams}`,
    };

    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getAntifraudTranslate = () => async (dispatch, getState) => {
  try {
    const reqData = {
      module: '1',
      filter: 'ANTIFRAUD',
    };

    const urlParams = buildUrlParams(encodeDataURI(reqData));

    const reqParams = {
      url: `/webapi-1.0/translate?${urlParams}`,
    };

    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const antifraudUfmBlock = () => async (dispatch, getState) => {
  try {
    const reqParams = {
      url: `/webapi-1.0/ufm-block`,
    };
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchLoginBannerListReq =
  (useStub = false, withError = false) =>
  async (dispatch, getState) => {
    if ((useStub && isDevelopment) || useGlobalStubs) {
      return await loginBannersListReqStub(withError);
    }

    const reqParams = {
      url: `/webapi-1.0/config/banners`,
    };

    try {
      const apiServices = apiServicesConstructor(dispatch, getState);
      const response = await apiServices.get(reqParams);

      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const fetchLoginBannerReq =
  (id, useStub = false, withError = false) =>
  async (dispatch, getState) => {
    if ((useStub && isDevelopment) || useGlobalStubs) {
      return await loginBannerReqStub(withError);
    }

    const reqParams = {
      url: `/webapi-1.0/config/banners/${id}`,
    };

    try {
      const apiServices = apiServicesConstructor(dispatch, getState);
      const response = await apiServices.get(reqParams);

      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const fetchChangePasswordSettingsReq =
  (useStub = false, withError = false) =>
  async (dispatch, getState) => {
    if ((useStub && isDevelopment) || useGlobalStubs) {
      return await fetchChangePasswordSettingsReqStub(withError);
    }

    const data = {
      T: 'RT_json_1Common.getchangePasswordConfig',
    };
    const urlParams = buildUrlParams(encodeDataURI(data));

    const reqParams = {
      url: `/webapi-1.0/bss/getChangePasswordConfig?${urlParams}`,
    };

    try {
      const apiServices = apiServicesConstructor(dispatch, getState);
      const response = await apiServices.get(reqParams);

      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const fetchChangePasswordSettingsReqNew = () => async (dispatch, getState) => {
  const browser = Bowser.getParser(window.navigator.userAgent).getBrowser();
  const reqData = {
    browser: browser?.name,
    browserVersion: `${browser?.name} ${browser?.version}`,
    type: 'web_lk',
  };
  const reqParams = {
    url: '/sso/oauth2/access_token',
    client_id: 'test_oauth2m2m',
    data: buildUrlParams(encodeDataURI(reqData)),
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const changePasswordReq =
  (
    {
      password,
      newPassword,
      confirmedNewPassword,
      MapIdCurrentpass = '',
      PassMapCurrentpass = '',
      MapIdNewpass,
      PassMapNewpass,
      MapIdConfirmnewpass,
      PassMapConfirmnewpass,
    },
    useStub = false,
    withError = false
  ) =>
  async (dispatch, getState) => {
    if ((useStub && isDevelopment) || useGlobalStubs) {
      return await changePasswordReqStub(withError);
    }

    const reqData = {
      console: 'JSON',
      usePassMap: 1,
      np: getEncryptedStr(newPassword, PassMapNewpass),
      cnp: getEncryptedStr(confirmedNewPassword, PassMapConfirmnewpass),
      mapIdNewPass: MapIdNewpass,
      mapIdConfirmnewpass: MapIdConfirmnewpass,
      xaction: 'AFTERLOGIN',
    };

    if (MapIdCurrentpass && PassMapCurrentpass) {
      Object.assign(reqData, {
        oldp: getEncryptedStr(password, PassMapCurrentpass),
        mapIdCurrentpass: MapIdCurrentpass,
      });
    }

    const browser = Bowser.getParser(window.navigator.userAgent).getBrowser();
    const deviceInfo = encodeURI(
      JSON.stringify({
        browser: browser?.name,
        browserVersion: `${browser?.name} ${browser?.version}`,
        type: 'web_lk',
      })
    );

    const reqParams = {
      url: '/webapi-1.0/bss/changePassword',
      headers: { 'Content-Type': 'application/json', 'Device-Info': deviceInfo },
      data: reqData,
    };

    try {
      const apiServices = apiServicesConstructor(dispatch, getState);
      const response = await apiServices.post(reqParams);

      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const changePasswordReqNew =
  ({ password, newPassword, confirmedNewPassword }, useStub = false, withError = false) =>
  async (dispatch, getState) => {
    if (newPassword !== confirmedNewPassword) {
      throw Error('invalid_credentials');
    }

    const state = getState();
    const { execution } = state.auth.resAPI;

    const browser = Bowser.getParser(window.navigator.userAgent).getBrowser();

    const reqData = {
      client_id: 'test_oauth2m2m',
      _eventId: 'next',
      newPasswordBody: newPassword,
      password,
      realm: '/customer',
      client_secret: 'password',
      service: 'dispatcher',
      grant_type: 'urn:roox:params:oauth:grant-type:m2m',
      execution,
      browser: browser?.name,
      browserVersion: `${browser?.name} ${browser?.version}`,
      type: 'web_lk',
    };

    const reqParams = {
      url: '/sso/oauth2/access_token',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
        Connection: 'keep-alive',
      },
      data: buildUrlParams(encodeDataURI(reqData)),
    };

    try {
      const apiServices = apiServicesConstructor(dispatch, getState);
      const response = await apiServices.post(reqParams);

      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const newLoginFetchFormReq = without2Fa => async (dispatch, getState) => {
  const browser = Bowser.getParser(window.navigator.userAgent).getBrowser();
  const reqData = {
    client_id: 'test_oauth2m2m',
    client_secret: 'password',
    service: 'dispatcher',
    realm: '/customer',
    grant_type: 'urn:roox:params:oauth:grant-type:m2m',
    browser: browser?.name,
    browserVersion: `${browser?.name} ${browser?.version}`,
    type: 'web_lk',
  };

  if (!without2Fa) {
    reqData.response_type = 'token cookie';
  }

  const data = buildUrlParams(encodeDataURI(reqData));

  const reqParams = {
    url: '/sso/oauth2/access_token',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const newLoginFetchTokenReq =
  (
    { execution, username, password, captchaValue, captchaId, type, isProduction },
    useStub = false,
    withError = false
  ) =>
  async (dispatch, getState) => {
    if ((useStub && isDevelopment) || useGlobalStubs) {
      return await newLoginFetchTokenReqStub(withError);
    }

    const state = getState();
    const { isChangePasswordRequired } = state.auth;

    const browser = Bowser.getParser(window.navigator.userAgent).getBrowser();
    const reqData = {
      client_id: 'test_oauth2m2m',
      client_secret: 'password',
      service: 'dispatcher',
      realm: '/customer',
      grant_type: 'urn:roox:params:oauth:grant-type:m2m',
      execution,
      _eventId: 'next',
      browser: browser?.name,
      browserVersion: `${browser?.name} ${browser?.version}`,
      username,
      password,
      redirect_uri: '/oauth2-consumer/authorize',
      response_type: 'token cookie',
      type: type,
    };

    if (captchaValue) {
      reqData.captchaId = captchaId;
      reqData.captchaValue = captchaValue;
    }

    const data = buildUrlParams(encodeDataURI(reqData));

    const isChangePasswordHeaders =
      !isProduction && isChangePasswordRequired
        ? { Connection: 'keep-alive', 'Accept-Encoding': 'gzip, deflate, br' }
        : {};

    const reqParams = {
      url: `/sso/oauth2/access_token`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
        ...isChangePasswordHeaders,
      },
      data,
    };

    try {
      const apiServices = apiServicesConstructor(dispatch, getState);
      const response = await apiServices.post(reqParams);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const newLoginSendOtpReq =
  (data, useStub = false, withError = false) =>
  async (dispatch, getState) => {
    if ((useStub || useGlobalStubs) && isDevelopment) {
      return await confirm2FactorReqStub(withError);
    }
    const browser = Bowser.getParser(window.navigator.userAgent).getBrowser();

    const reqData = {
      client_id: 'test_oauth2m2m',
      client_secret: 'password',
      realm: '/customer',
      grant_type: 'urn:roox:params:oauth:grant-type:m2m',
      response_type: 'token cookie',
      _eventId: 'start',
      execution: data.execution,
      browser: browser?.name,
      browserVersion: `${browser?.name} ${browser?.version}`,
      type: 'web_lk',
    };

    if (data.schemeName) {
      reqData.schemeName = data.schemeName;
    }

    switch (data.confirmType && data.confirmType.toLowerCase()) {
      case 'tan':
        reqData.otpCode = data.code;
        break;
      case 'mobipass':
        reqData.otpCode = data.code;
        break;
      case 'sms_code':
        reqData.otpCode = data.code;
        reqData.schemeId = data.schemeId;
        reqData.hash = data.hash;
        reqData.schemeName = 'ADDPROTECTION';

        if (reqData.smsId) {
          reqData.smsAutokey = data.smsId;
        }
        break;
      default:
    }

    const reqParams = {
      url: `/sso/oauth2/access_token`,
      data: buildUrlParams(encodeDataURI(reqData)),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept-Encoding': 'gzip, deflate',
        Connection: 'keep-alive',
      },
    };

    try {
      const apiServices = apiServicesConstructor(dispatch, getState);
      const response = await apiServices.post(reqParams);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const newLoginFetchTokenInfoReq =
  (access_token, useStub = false, withError = false) =>
  async (dispatch, getState) => {
    if ((useStub && isDevelopment) || useGlobalStubs) {
      return await newLoginFetchTokenInfoReqStub(withError);
    }

    const reqData = {
      access_token,
    };

    const data = buildUrlParams(encodeDataURI(reqData));

    const reqParams = {
      url: `/sso/oauth2/tokeninfo?${data}`,
    };

    try {
      const apiServices = apiServicesConstructor(dispatch, getState);
      const response = await apiServices.post(reqParams);

      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const fetch2FactorSettingsReq =
  (access_token, useStub = false, withError = false) =>
  async (dispatch, getState) => {
    if ((useStub && isDevelopment) || useGlobalStubs) {
      return await fetch2FactorSettingsReqStub(withError);
    }

    const reqParams = {
      url: `/webapi-1.0/bss/get2FA`,
    };

    try {
      const apiServices = apiServicesConstructor(dispatch, getState);
      const response = await apiServices.get(reqParams);

      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const confirm2FactorReq =
  (data, useStub = false, withError = false) =>
  async (dispatch, getState) => {
    if ((useStub || useGlobalStubs) && isDevelopment) {
      return await confirm2FactorReqStub(withError);
    }
    const reqData = {};

    if (data.schemeName) {
      reqData.schemeName = data.schemeName;
    }

    switch (data.confirmType && data.confirmType.toLowerCase()) {
      case 'tan':
        reqData.password = data.code;
        break;
      case 'mobipass':
        reqData.mbrResult = data.code;
        break;
      case 'sms_code':
        reqData.smsResult = data.code;
        reqData.schemeId = data.schemeId;
        reqData.hash = data.hash;
        reqData.schemeName = 'ADDPROTECTION';

        if (reqData.smsId) {
          reqData.smsAutokey = data.smsId;
        }
        break;
      default:
    }

    const reqParams = {
      url: `/webapi-1.0/bss/check2FA`,
      data: reqData,
      headers: { 'Content-Type': 'application/json' },
    };

    try {
      const apiServices = apiServicesConstructor(dispatch, getState);
      const response = await apiServices.post(reqParams);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const fetchTechNotifyReq = () => async (dispatch, getState) => {
  const reqParams = {
    url: `/webapi-1.0/translate?filter=TECH_NOTIFY&module=3`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};
