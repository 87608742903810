import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Translation, InfoComponent } from 'shared';

import loaderWhite from 'assets/icons/loader-white.png';
import styles from './PrepaymentInfo.module.scss';

import { labelMaker, getCurrencySymbol } from 'shared/utils';

const { Div } = Translation;
const label = labelMaker('products.productInfo');

const PrepaymentInfo = props => {
  const { monthPayment, reducedBalance, ccy, isLoading, isError, isFullPayment, isCreditHolidays } = props;
  const reduceBalanceWithCurrency = `${reducedBalance} ${getCurrencySymbol(ccy || 'RUR')}`;

  return (
    <div className={styles.Container}>
      {isLoading && (
        <div className={styles.LoaderOverlay}>
          <Div className={styles.LoaderInfo} translateId={label('calculating')} />
          <img className={styles.Rotating} src={loaderWhite} alt="" />
        </div>
      )}
      {isFullPayment || isCreditHolidays ? (
        <Div
          className={cn(styles.FullPaymentTitle, {
            [styles.newEarlyPaymentHildays]: isCreditHolidays && !isFullPayment,
            [styles.fullPayment]: isCreditHolidays && isFullPayment
          })}
          translateId={label(isCreditHolidays && !isFullPayment ? 'newEarlyPaymentHildays' : 'fullPayment')}
        />
      ) : (
        <Fragment>
          <div className={styles.LabelContainer}>
            <Div
              className={styles.Label}
              translateId={label('newEarlyPayment')}
            />
            <InfoComponent translateId={label('newEarlyPaymentInfo')} theme="white" width={280} />
          </div>
          <div className={styles.Value}>{!isError ? monthPayment : '--'}</div>

          <div className={styles.Separator} />

          <div className={styles.LabelContainer}>
            <Div className={styles.Label} translateId={label('reducedBalanceAmount')} />
            <InfoComponent
              translateId={label('reducedBalanceAmountInfo')}
              theme="white"
              width={280}
            />
          </div>
          <div className={styles.Value}>{!isError ? reduceBalanceWithCurrency : '--'}</div>
        </Fragment>
      )}
    </div>
  );
};

PrepaymentInfo.propTypes = {
  reducedBalance: PropTypes.string,
  monthPayment: PropTypes.string,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  isFullPayment: PropTypes.bool,
  ccy: PropTypes.string.isRequired,
  isCreditHolidays: PropTypes.bool,
};

export default PrepaymentInfo;
