import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  collapseNotification,
  expandNotification,
  hideNotification,
  showWarnNotification,
  showInfoNotification,
  showKYCNotification,
  setMessage,
  clearQueue,
} from 'actions/notification';
import { Notification } from '..';

import styles from './withNotification.module.scss';

const withNotification = Content => {
  class Page extends Component {
    scrollPos = window.pageYOffset || document.documentElement.scrollTop;
    state = {
      contentPaddingTop: 0,
    };

    handleScroll = () => {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const { contentPaddingTop } = this.state;

      if (this.scrollPos > scrollTop) {
        if (contentPaddingTop < this.calculateWarningHeight()) {
          this.setState({
            contentPaddingTop: this.calculateWarningHeight(),
          });
        }

        if (scrollTop <= contentPaddingTop - this.calculateWarningHeight()) {
          this.setState({
            contentPaddingTop: this.calculateWarningHeight(),
          });
        }
      }

      this.scrollPos = scrollTop;
    };

    calculateWarningHeight = () => {
      const { visible } = this.props;

      if (this.warn) {
        return visible ? this.warn.scrollHeight : 0;
      }
    };

    componentDidMount() {
      window.addEventListener('scroll', this.handleScroll);

      if (this.warn) {
        this.setState({
          contentPaddingTop: this.calculateWarningHeight(),
        });
      }
    }

    updateErrorHeight = notificationHeight => {
      const { contentPaddingTop } = this.state;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop === 0 && notificationHeight !== contentPaddingTop) {
        this.setState({
          contentPaddingTop: notificationHeight,
        });
      }

      if (scrollTop < contentPaddingTop - notificationHeight) {
        this.setState({
          contentPaddingTop: notificationHeight,
        });
      }
    };

    componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
      const { contentPaddingTop } = this.state;
      const {
        collapseNotification,
        expandNotification,
        hideNotification,
        notification,
        showWarnNotification,
        showInfoNotification,
        showKYCNotification,
        setMessage,
        clearQueue,
      } = this.props;

      return (
        <Fragment>
          <Notification
            warnRef={ref => (this.warn = ref)}
            updateErrorHeight={this.updateErrorHeight}
            collapseNotification={collapseNotification}
            expandNotification={expandNotification}
            hideNotification={hideNotification}
            notification={notification}
            showWarnNotification={showWarnNotification}
            showInfoNotification={showInfoNotification}
            showKYCNotification={showKYCNotification}
            setMessage={setMessage}
            clearQueue={clearQueue}
          />
          <div className={styles.ContentContainer} style={{ paddingTop: contentPaddingTop }}>
            <Content {...this.props} />
          </div>
        </Fragment>
      );
    }
  }

  Page.propTypes = {
    collapseNotification: PropTypes.func.isRequired,
    expandNotification: PropTypes.func.isRequired,
    hideNotification: PropTypes.func.isRequired,
    notification: PropTypes.object.isRequired,
    expanded: PropTypes.bool.isRequired,
    visible: PropTypes.bool.isRequired,
    content: PropTypes.node,
  };

  const mapState = ({ notification }) => ({
    notification: notification.notification,
    visible: notification.notification.visible,
    expanded: notification.notification.expanded,
  });

  const mapDispatch = dispatch => ({
    collapseNotification: () => dispatch(collapseNotification()),
    expandNotification: () => dispatch(expandNotification()),
    hideNotification: (payload) => dispatch(hideNotification(payload)),
    showWarnNotification: (payload) => dispatch(showWarnNotification(payload)),
    showInfoNotification: (payload) => dispatch(showInfoNotification(payload)),
    showKYCNotification: (payload) => dispatch(showKYCNotification(payload)),
    setMessage: (payload) => dispatch(setMessage(payload)),
    clearQueue: () => dispatch(clearQueue()),
  });

  return connect(
    mapState,
    mapDispatch
  )(Page);
};

export default withNotification;
