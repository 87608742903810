import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { LocalizeProvider } from 'react-localize-redux';
import axios from 'axios';

import App from './components/App/App';
import { loginSuccess, loginRecoverUser } from './actions/auth/index';
import initStore, { history } from './store';

import * as serviceWorker from './serviceWorker';
import { MobileApps, MobileDetector } from 'services/mobileDetector';

import './index.scss';

axios.defaults.headers.common['Cache-Control'] = 'no-cache';
axios.defaults.headers.common['Pragma'] = 'no-cache';

const store = initStore();

try {
  const unloadTime = localStorage.getItem('unloadTime');
  const auth = JSON.parse(localStorage.getItem('auth'));

  if (unloadTime && Math.abs(new Date().getTime() - new Date(unloadTime).getTime()) > 30000) {
    localStorage.removeItem('auth');
  } else if (auth) {
    store.dispatch(loginSuccess(auth));
  }
} catch (error) {
  console.error(error);
}

try {
  const login = JSON.parse(localStorage.getItem('auth/login'));
  if (login) {
    store.dispatch(loginRecoverUser(login));
  }
} catch (error) {
  console.error(error);
}

const renderApp = () =>
  ReactDOM.render(
    <Provider store={store}>
      <LocalizeProvider store={store}>
        <App history={history} />
      </LocalizeProvider>
    </Provider>,
    document.getElementById('root')
  );
  
if (MobileDetector.parseUA(window.location.pathname) === MobileApps.Unknown) {
  renderApp();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
