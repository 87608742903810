import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

import { Translation } from 'shared';

import styles from './Actions.module.scss';

import { labelMaker } from 'shared/utils';

const label = labelMaker('products.actions');

const Actions = props => {
  const { titleTranslateId, onSave, onSend, onPrint } = props;

  return (
    <div className={styles.PaymentActions}>
      <Translation.Div className={styles.PaymentTitle} translateId={titleTranslateId} />

      <div className={styles.SaveAnchor}>
        <div className={styles.PaymentAction} onClick={onSave}>
          <Translate id={label('saveBtn')} />
          <div className={styles.PdfIcon} />
        </div>
      </div>
      <div className={styles.PaymentAction} onClick={onSend}>
        <Translate id={label('sendBtn')} />
        <div className={styles.MailIcon} />
      </div>
      <div className={styles.PaymentAction} onClick={onPrint}>
        <Translate id={label('printBtn')} />
        <div className={styles.PrintIcon} />
      </div>
    </div>
  );
};

Actions.propTypes = {
  titleTranslateId: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onSend: PropTypes.func,
  onPrint: PropTypes.func,
  saveHref: PropTypes.string,
};

Actions.defaultProps = {
  onSave: () => {},
  onSend: () => {},
  onPrint: () => {},
};

export default Actions;
