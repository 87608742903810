import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';
import { reduxForm, change, formValueSelector, getFormSyncErrors } from 'redux-form';
import ProfileContentHeader from '../components/ProfileContentHeader/ProfileContentHeader';
import SocialCards from '../components/SocialCards/SocialCards';
import { initializePersonal } from 'actions/personal';
import {
  toggleChangeEmailBlock,
  changeEmail,
  changeEmailCancel,
  verifyEmail,
  toggleChangePhoneBlock,
  changePhone,
  changePhoneCancel,
  verifyPhone,
} from 'actions/profile';
import DefaultBankBlock from './components/DefaultBankBlock';
import { Translation, withErrorBoundary } from 'shared';
import { labelMaker, get } from 'shared/utils';
import { initializeDefaultBank, destroyDefaultBank } from 'actions/transfers';

import fieldsOptions from './fields';

import PhoneBlock from './components/PhoneBlock';
import EmailBlock from './components/EmailBlock';

import styles from './Personal.module.scss';
// import options from './components/DefaultBankBlock/DefaultBankForm/options';

const label = labelMaker('profile.personal');

class Personal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cards: [
        {
          name: 'facebook',
          active: true,
        },
        {
          name: 'vkontakte',
          active: false,
        },
        {
          name: 'instagram',
          active: false,
        },
      ],
    };
  }

  changeEmailStart = () => {
    const { toggleChangeEmailBlock } = this.props;
    toggleChangeEmailBlock();
  };

  checkEmailStart = () => {
    const { newEmail } = this.props.formValues;
    this.props.changeEmail(newEmail);
  };

  checkEmailCodeStart = () => {
    const { emailCode } = this.props.formValues;
    this.props.verifyEmail(emailCode);
  };

  cancelEmailChanges = () => {
    const { dispatch, changeEmailCancel, personal } = this.props;
    if (personal.showChangeEmailBlock) {
      dispatch(change('changePersonal', 'newEmail', ''));
      changeEmailCancel();
    }
  };

  changePhoneStart = () => {
    const { toggleChangePhoneBlock } = this.props;
    toggleChangePhoneBlock();
  };

  checkPhoneStart = () => {
    const { newPhone } = this.props.formValues;
    this.props.changePhone(newPhone);
  };

  checkPhoneCodeStart = () => {
    const { phoneCode } = this.props.formValues;
    this.props.verifyPhone(phoneCode);
  };

  cancelPhoneChanges = () => {
    const { dispatch, changePhoneCancel, personal } = this.props;
    if (personal.showChangePhoneBlock) {
      dispatch(change('changePersonal', 'newPhone', ''));
      changePhoneCancel();
    }
  };

  onCardChange = (name, active) => {
    const cards = this.state.cards.map(card => {
      if (card.name === name) {
        card.active = active;
      }

      return card;
    });

    this.setState({ cards: cards });
  };

  componentDidUpdate(prevProps) {
    const { dispatch } = this.props;
    if (!prevProps.personal.editableInitialValues && this.props.personal.editableInitialValues) {
      dispatch(change('changePersonal', 'email', get(this.props.personal, 'userData.email')));
      dispatch(
        change('changePersonal', 'phone', get(this.props.personal, 'userData.mobilePhones'))
      );
    }
  }

  componentDidMount() {
    const { initializePersonal, initializeDefaultBank, personal } = this.props;

    if (!personal.userData) {
      initializePersonal();
    }

    initializeDefaultBank();
  }

  componentWillUnmount() {
    this.props.destroyDefaultBank();
    this.props.changeEmailCancel();
    this.props.changePhoneCancel();
  }

  render() {
    const { personal, formErrors, settings, locale } = this.props;

    const { emailData, phoneData, showChangeEmailBlock, showChangePhoneBlock } = personal;

    const actionId = label('change');

    const emailProps = !showChangeEmailBlock
      ? {
          actionId,
          handleAction: this.changeEmailStart,
        }
      : {};

    const phoneProps = !showChangePhoneBlock
      ? {
          actionId,
          handleAction: this.changePhoneStart,
        }
      : {};

    return (
      <div className={styles.Body}>
        <ProfileContentHeader />
        <div className={styles.Container}>
          <div className={styles.ContentContainer}>
            <div className={styles.Content}>
              <div className={styles.Block}>
                <PhoneBlock
                  {...{
                    fieldsOptions,
                    phoneProps,
                    phoneData,
                    showChangePhoneBlock,
                    changePhoneStart: this.changePhoneStart,
                    checkPhoneStart: this.checkPhoneStart,
                    checkPhoneCodeStart: this.checkPhoneCodeStart,
                    cancelPhoneChanges: this.cancelPhoneChanges,
                    formErrors,
                  }}
                />
              </div>
              <div className={styles.Block}>
                <EmailBlock
                  {...{
                    fieldsOptions,
                    emailProps,
                    emailData,
                    showChangeEmailBlock,
                    changeEmailStart: this.changeEmailStart,
                    checkEmailStart: this.checkEmailStart,
                    checkEmailCodeStart: this.checkEmailCodeStart,
                    cancelEmailChanges: this.cancelEmailChanges,
                    formErrors,
                  }}
                />
              </div>
              <div className={styles.Block}>
                <Translation.H2 className={styles.Title} translateId={label('social')} />
                <Translation.H4 className={styles.Description} translateId={label('socialInfo')} />
                <SocialCards cards={this.state.cards} onChangeCard={this.onCardChange} />
              </div>
              <div className={styles.Block}>
                {settings.isModuleVisible[locale] && <DefaultBankBlock />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Personal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  initializeDefaultBank: PropTypes.func.isRequired,
  initializePersonal: PropTypes.func.isRequired,
  destroyDefaultBank: PropTypes.func.isRequired,
  personal: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  toggleChangeEmailBlock: PropTypes.func.isRequired,
  toggleChangePhoneBlock: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    newPhone: PropTypes.string,
    newEmail: PropTypes.string,
    emailCode: PropTypes.string,
    phoneCode: PropTypes.string,
  }),
  formErrors: PropTypes.shape({
    newPhone: PropTypes.string,
    newEmail: PropTypes.string,
  }),
  changeEmail: PropTypes.func.isRequired,
  changeEmailCancel: PropTypes.func.isRequired,
  verifyEmail: PropTypes.func.isRequired,
  changePhone: PropTypes.func.isRequired,
  changePhoneCancel: PropTypes.func.isRequired,
  verifyPhone: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
};

const mapStateToProps = ({ profile, auth, localize, settings, ...state }) => ({
  initialValues: {
    phone: get(profile, 'personal.userData.mobilePhones', ''),
    newPhone: '',
    phoneCode: '',
    email: get(profile, 'personal.userData.email', ''),
    newEmail: '',
    emailCode: '',
  },
  formValues: formValuesSelector(state, 'newPhone', 'newEmail', 'emailCode', 'phoneCode'),
  formErrors: getFormSyncErrors('changePersonal')(state),
  personal: profile.personal,
  auth,
  locale: getActiveLanguage(localize).code,
  settings,
});

const mapDispatchToProps = {
  initializePersonal,
  initializeDefaultBank,
  destroyDefaultBank,
  toggleChangeEmailBlock,
  changeEmail,
  changeEmailCancel,
  verifyEmail,
  toggleChangePhoneBlock,
  changePhone,
  changePhoneCancel,
  verifyPhone,
};

const form = 'changePersonal';
const formValuesSelector = formValueSelector(form);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form,
  })(withErrorBoundary(Personal, 'Profile/Personal'))
);
