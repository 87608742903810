import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';

import { Layout, withNotification, withKYCNotification, requireAuthentication } from 'shared';
import Transfers from 'components/Transfers/index';

import transfersTranslations from '../translations/transfers.json';

class TransfersPage extends PureComponent {
  constructor(props) {
    super(props);

    props.addTranslation(transfersTranslations);
  }

  render() {
    return (
      <Layout.Authenticated>
        <Transfers />
      </Layout.Authenticated>
    );
  }
}

TransfersPage.propTypes = {
  addTranslation: PropTypes.func.isRequired,
};

export default requireAuthentication(
  withKYCNotification(withNotification(withLocalize(TransfersPage)))
);
