import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Translation } from 'shared';

import { labelMaker } from 'shared/utils';
import styles from './ProfileMenu.module.scss';

const ProfileMenu = props => {
  const lMenuTabs = labelMaker('profile.profileMenu');
  const menuTabs = ['personal', 'security'];

  const route = '/profile';

  const renderMenuItem = (tab, i) => {
    return (
      <Translation.NavLink
        exact
        to={`${route}/${tab}`}
        className={styles.MenuTab}
        activeClassName={styles.ActiveMenuTab}
        translateId={lMenuTabs(tab)}
        key={i}
      />
    );
  };

  const { className } = props;

  return <div className={cn(styles.ProfileMenu, className)}>{menuTabs.map(renderMenuItem)}</div>;
};

ProfileMenu.propTypes = {
  match: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withRouter(ProfileMenu);
