import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';

import {
  Layout,
  Page404,
  withNotification,
  withKYCNotification,
  requireAuthentication,
} from 'shared';
import Profile from 'components/Profile/Profile';

import profileTranslations from 'components/Profile/profile.json';

class ProfilePage extends Component {
  constructor(props) {
    super(props);

    props.addTranslation(profileTranslations);
  }

  render() {
    return (
      <Layout.Authenticated>
        <Switch>
          <Route path="/profile" component={Profile} />
          <Route exact path="/profile/404" component={Page404} />
          <Redirect to="/profile/404" />
        </Switch>
      </Layout.Authenticated>
    );
  }
}

ProfilePage.propTypes = {
  addTranslation: PropTypes.func.isRequired,
};

export default withKYCNotification(
  requireAuthentication(withNotification(withLocalize(ProfilePage)))
);
