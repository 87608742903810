import { createSelector } from 'reselect';

const getBranches = state => state.atms.branches.data;
const getAtms = state => state.atms.atms.data;
const getFilters = state => state.atms.filters;
const getVisibleFilter = state => state.form['atmsAndBranches/mapItemsFilter'].values;

export const branchesFilterSelector = createSelector(
  getBranches,
  getFilters,
  getVisibleFilter,
  (branches, filters, isVisible) => {
    if (!isVisible.branches) {
      return [];
    }
    return applyFilters(branches, filters);
  }
);

export const atmsFilterSelector = createSelector(
  getAtms,
  getFilters,
  getVisibleFilter,
  (atms, filters, isVisible) => {
    if (!isVisible.atms) {
      return [];
    }
    return applyFilters(atms, filters);
  }
);

export const applyFilters = (items, filters) => {
  let filteredItems = null;
  if (!filters) {
    return items;
  }
  // depositary
  if (filters.depository) {
    const currentFilteredItems = items.filter(item => findAttribute(item, 'depositary'));
    if (!filteredItems) {
      filteredItems = currentFilteredItems;
    }
    filteredItems = filteredItems.concat(mergeArrays(filteredItems, currentFilteredItems));
  }

  // partner atms
  if (filters.partnersAtms) {
    const currentFilteredItems = items.filter(item => findAttribute(item, 'partner'));
    if (!filteredItems) {
      filteredItems = currentFilteredItems;
    }
    filteredItems = filteredItems.concat(mergeArrays(filteredItems, currentFilteredItems));
  }

  // prime club
  if (filters.unicreditPrimeClub) {
    const currentFilteredItems = items.filter(item => findAttribute(item, 'prime'));
    if (!filteredItems) {
      filteredItems = currentFilteredItems;
    }
    filteredItems = filteredItems.concat(mergeArrays(filteredItems, currentFilteredItems));
  }

  // unicredit branches
  if (filters.unicreditBranches) {
    const currentFilteredItems = items.filter(item => item.branchId);
    if (!filteredItems) {
      filteredItems = currentFilteredItems;
    }
    filteredItems = filteredItems.concat(mergeArrays(filteredItems, currentFilteredItems));
  }

  // unicredit atms
  if (filters.unicreditAtms) {
    const currentFilteredItems = items.filter(
      item => item.atmId && !findAttribute(item, 'partner')
    );
    if (!filteredItems) {
      filteredItems = currentFilteredItems;
    }
    filteredItems = filteredItems.concat(mergeArrays(filteredItems, currentFilteredItems));
  }

  // all day open
  if (filters.openAlways) {
    if (!filteredItems) {
      filteredItems = items.filter(item => item.allDay === true);
    } else {
      filteredItems = filteredItems.filter(item => item.allDay === true);
    }
  }

  // withdraw RUB
  if (filters.rubleWithdraw) {
    if (!filteredItems) {
      filteredItems = items.filter(
        item => findAttribute(item, 'RUR') || findAttribute(item, 'RUB')
      );
    } else {
      filteredItems = filteredItems.filter(
        item => findAttribute(item, 'RUR') || findAttribute(item, 'RUB')
      );
    }
  }

  // withdraw USD
  if (filters.dollarWithdraw) {
    if (!filteredItems) {
      filteredItems = items.filter(item => findAttribute(item, 'USD'));
    } else {
      filteredItems = filteredItems.filter(item => findAttribute(item, 'USD'));
    }
  }

  // withdraw EUR
  if (filters.euroWithdraw) {
    if (!filteredItems) {
      filteredItems = items.filter(item => findAttribute(item, 'EUR'));
    } else {
      filteredItems = filteredItems.filter(item => findAttribute(item, 'EUR'));
    }
  }
  return filteredItems;
};

const findAttribute = (item, attribute) => {
  if (item.attributes && item.attributes.indexOf(attribute) !== -1) {
    return true;
  } else {
    return false;
  }
};

function mergeArrays(arrA, arrB) {
  const indexObject = arrA.reduce((acc, obj) => {
    if (obj.branchId) {
      acc[obj.branchId] = obj;
    } else {
      acc[obj.atmId] = obj;
    }
    return acc;
  }, {});
  const uniques = arrB.filter(obj => {
    return !indexObject[obj.branchId] && !indexObject[obj.atmId];
  });
  return uniques;
}
