import { labelMaker } from 'shared/utils';

const labelRange = labelMaker('shared.period');

const schedule = {
  periodFilter: {
    value: 'year',
    data: [
      { labelId: labelRange('year'), value: 'year' },
      { labelId: labelRange('allPeriod'), value: 'all' },
    ],
  },
};

export default schedule;
