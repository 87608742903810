import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

export default function requireAuthentication(ProtectedComponent) {
  class Authenticated extends Component {
    UNSAFE_componentWillMount() {
      this.checkAuth(this.props.isAuthenticated);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      this.checkAuth(nextProps.isAuthenticated);
    }

    checkAuth(isAuthenticated) {
      if (!isAuthenticated) {
        const { pathname: redirectAfterLogin } = this.props.location;
        this.props.dispatch(replace(`/login?next=${redirectAfterLogin}`));
      }
    }

    render() {
      return this.props.isAuthenticated ? <ProtectedComponent {...this.props} /> : null;
    }
  }

  Authenticated.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
  });

  return withRouter(connect(mapStateToProps)(Authenticated));
}
