import { buildUrlParams, encodeDataURI } from 'shared/utils';
import apiServicesConstructor from 'services/apiService';
import { fetchCardsReqStub, fetchCardsCatalogReqStub } from './cardsStubs';

const isDevelopment = process.env.NODE_ENV === 'development';
const useGlobalStubs = process.env.REACT_APP_USE_STUBS === 'true';

export const fetchCardsReq = (useStub = false, withError = false) => async (dispatch, getState) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await fetchCardsReqStub(withError);
  }

  const urlParams = buildUrlParams(encodeDataURI({ forceUpdate: true, isIncludeBalance: true }));

  const reqParams = {
    url: `/webapi-1.0/card2/list?${urlParams}`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchCardsCatalogReq = (useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await fetchCardsCatalogReqStub(withError);
  }

  const module = 4;
  const reqParams = {
    url: `/webapi-1.0/translate?module=${module}`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};
