import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { atmsFilterSelector, branchesFilterSelector } from 'store/selectors/atms';
import { labelMaker } from 'shared/utils';

import AtmItem from '../AtmItem/AtmItem';
import { Translation } from 'shared';

import styles from './AtmsList.module.scss';

const label = labelMaker('atms.atm');

class AtmsList extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.atms !== nextProps.atms || this.props.branches !== nextProps.branches) {
      return true;
    } else {
      return false;
    }
  }

  getListItems = () => {
    const { atms, branches } = this.props;
    let mapItems = [];

    if (Array.isArray(atms)) {
      mapItems = [...atms];
    }
    if (Array.isArray(branches)) {
      mapItems = [...mapItems, ...branches];
    }
    return mapItems;
  };

  getId = atm => {
    if (atm.atmId) {
      return `atm-${atm.atmId}`;
    }

    if (atm.branchId) {
      return `branch-${atm.branchId}`;
    }
  };

  render() {
    const listItems = this.getListItems();

    return (
      <div className={styles.Body}>
        <div className={styles.Content}>
          {Array.isArray(listItems) &&
            (listItems.length ? (
              listItems.map(atm => <AtmItem key={this.getId(atm)} atm={atm} />)
            ) : (
              <Translation.Div className={styles.NoData} translateId={label('noData')} />
            ))}
        </div>
      </div>
    );
  }
}

AtmsList.propTypes = {
  atms: PropTypes.array,
  branches: PropTypes.array,
};

const mapStateToProps = state => ({
  atms: atmsFilterSelector(state),
  branches: branchesFilterSelector(state),
});

export default connect(mapStateToProps)(AtmsList);
