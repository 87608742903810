import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';

import { Button } from 'shared';
import { labelMaker } from 'shared/utils';
import { accountsSelector } from 'store/selectors/accounts';

import Requisites from './Requisites';
import Information from './Information';
import styles from './CreditDetails.module.scss';

const lDetails = labelMaker('products.creditDetails');

class CreditDetails extends Component {
  createRowData = (id, value) => ({ labelId: lDetails(id), value });

  render() {
    const {
      accounts,
      requisitesShown,
      handleInfo,
      handleRequisites,
      credit,
      auth,
      requisites,
      currenciesModule,
      locale,
    } = this.props;
    const infoButtonTheme = requisitesShown ? 'white' : 'blue';
    const requisitesButtonTheme = requisitesShown ? 'blue' : 'white';

    return (
      <Fragment>
        <div className={styles.NavigateActions}>
          <Button
            className={styles.infoBtn}
            translateId={lDetails('infoBtn')}
            onClick={handleInfo}
            theme={infoButtonTheme}
          />
          <Button
            className={styles.detailsBtn}
            translateId={lDetails('detailsBtn')}
            onClick={handleRequisites}
            theme={requisitesButtonTheme}
          />
        </div>
        {requisitesShown ? (
          <Requisites
            {...{
              accounts,
              credit,
              auth,
              requisites,
              currenciesModule,
              createRowData: this.createRowData,
            }}
          />
        ) : (
          <Information {...{ credit, locale, createRowData: this.createRowData }} />
        )}
      </Fragment>
    );
  }
}

CreditDetails.propTypes = {
  credit: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  requisites: PropTypes.object,
  accounts: PropTypes.array,
  currenciesModule: PropTypes.array,
  locale: PropTypes.string.isRequired,
  requisitesShown: PropTypes.bool.isRequired,
  handleInfo: PropTypes.func.isRequired,
  handleRequisites: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  credit: state.products.credit,
  accounts: accountsSelector(state),
  requisites: state.products.requisites,
  currenciesModule: state.products.prepayment.currenciesModule,
  locale: getActiveLanguage(state.localize).code,
});

export default connect(mapStateToProps)(CreditDetails);
