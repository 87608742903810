import apiServicesConstructor from 'services/apiService';
import { fetchAccountsReqStub } from './accountsStubs';

const isDevelopment = process.env.NODE_ENV === 'development';
const useGlobalStubs = process.env.REACT_APP_USE_STUBS === 'true';

export const fetchAccountsReq = (forceUpdate, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await fetchAccountsReqStub(withError);
  }

  const reqParams = {
    url: `/webapi-1.0/accounts?forceUpdate=${forceUpdate}`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};
