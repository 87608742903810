import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

import Spinner from '../../Spinner/Spinner';

import styles from './LocationInput.module.scss';

import { isObject } from 'shared/utils';

class Menu extends Component {
  handleClickOutside = () => {
    const { onClickOutside } = this.props;

    onClickOutside();
  };

  renderOption = option => {
    const { selectOption } = this.props;

    const optionValue = isObject(option) ? option.value : option;

    return (
      <div className={styles.Option} onClick={selectOption(option)} key={optionValue}>
        {optionValue}
      </div>
    );
  };

  render() {
    const { locations, isOpen, isLoading } = this.props;

    if (Array.isArray(locations) && isOpen) {
      return (
        <div className={styles.Menu}>
          {isLoading ? <Spinner className={styles.Spinner} /> : locations.map(this.renderOption)}
        </div>
      );
    }

    return null;
  }
}

Menu.propTypes = {
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  locations: PropTypes.array,
  onClickOutside: PropTypes.func.isRequired,
  selectOption: PropTypes.func.isRequired,
};

export default onClickOutside(Menu);
