import { CodeInput, Timer, Translation } from '..';
import React, { Component } from 'react';
import { Translate, getActiveLanguage, getTranslate } from 'react-localize-redux';
import { createSms, resetSignature, signatureValueChange } from 'actions/signature';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { labelMaker } from 'shared/utils';
import moment from 'moment';
import styles from './SmsInput.module.scss';

const label = labelMaker('shared.signature');

const Title = ({ sms, locale }) => {
  // different message based on status

  if (!sms.status) {
    return null;
  }
  const status = sms.status;
  if (status === 'ok' && typeof sms.requestsLeft === 'undefined') {
    return <Translate id={label('smsStatus.ok')} />;
  }
  if (status === 'ok' && typeof sms.requestsLeft !== 'undefined') {
    return (
      <Translate id={label('smsStatus.ok_repeat')} data={{ requestsLeft: sms.requestsLeft }} />
    );
  }
  if (status === 'error') {
    return sms.message;
  }
  if (status === 'send_timeout') {
    return (
      <Translate id={label('smsStatus.send_timeout')} data={{ timeOutLeft: sms.timeOutLeft }} />
    );
  }
  if (status === 'requests_exceeded') {
    return (
      <Translate
        id={label('smsStatus.requests_exceeded')}
        data={{ timeOutLeft: sms.timeOutLeft }}
      />
    );
  }
  if (status === 'temp_blocked') {
    const localizeDate =
      locale === 'en'
        ? moment(sms.blockDateTime, 'DD.MM.YYYY HH:mm:ss').format('MM.DD.YYYY hh:mm:ss A')
        : sms.blockDateTime;
    return (
      <Translate id={label('smsStatus.temp_blocked')} data={{ blockDateTime: localizeDate }} />
    );
  }
};

class SmsInput extends Component {
  state = { showTimer: true };

  onChange = field => {
    const { onChange } = this.props;
    const { value } = field;

    onChange(value);
  };

  clearTimer = () => {
    this.setState({ showTimer: false });
  };

  getCount = timeOutEnd => {
    if (timeOutEnd) {
      return timeOutEnd.getTime() - new Date().getTime() > 0
        ? Math.floor((timeOutEnd.getTime() - new Date().getTime()) / 1000)
        : 1;
    } else {
      return 60;
    }
  };

  componentDidMount() {
    const { signature } = this.props;
    const { sms } = signature;
    const { timeOutEnd } = sms;
    const count = this.getCount(timeOutEnd);
    this.setState({ count: count });
  }

  componentDidUpdate(prevProps, prevState) {
    // after sms resend reset time out
    if (
      (!prevProps.signature.sms.timeOutEnd && this.props.signature.sms.timeOutEnd) ||
      (prevProps.signature.sms.timeOutEnd &&
        this.props.signature.sms.timeOutEnd &&
        prevProps.signature.sms.timeOutEnd !== this.props.signature.sms.timeOutEnd)
    ) {
      const count = this.getCount(this.props.signature.sms.timeOutEnd);
      this.setState({ count: count, showTimer: true });
    }
  }

  componentWillUnmount() {
    const { resetSignature } = this.props;
    resetSignature();
  }

  isDisabled = () => {
    const { isLoading: isSignatureLoading } = this.props.signature;
    const { isLoading } = this.props;

    if (isSignatureLoading || isLoading) {
      return true;
    } else {
      return false;
    }
  };

  createNewSms = () => {
    const { createSms, signature, onResend } = this.props;
    const { settings } = signature;
    if (onResend) {
      onResend();
      this.setState({ showTimer: true, count: 60 });
    } else {
      createSms({ ...settings, schemeName: 'ADDPROTECTION' });
    }
  };

  render() {
    const { Div, Span } = Translation;
    const { phoneNumber, signature, hasResend, isLoading, smsLength: numOfFields, locale, children } = this.props;

    const { error, value, isLoading: isSignatureLoading, sms } = signature;
    const { timeOut, timeOutLeft } = sms;
    const { showTimer } = this.state;

    const isResendButton =
      !isSignatureLoading &&
      !isLoading &&
      hasResend &&
      (!showTimer || (!timeOut && !timeOutLeft && sms.status));

    return (
      <div className={styles.OTP}>
        <div className={styles.Text}>
          {phoneNumber && (
            <div>
              <Translate id={label('checkYourPhone')} data={{ phoneNumber: phoneNumber }} />
            </div>
          )}
          {sms.status && <Title sms={sms} locale={locale} />}
          {!sms.status && !phoneNumber && <Translate id={label('enterCode')} />}
        </div>
        {(!sms.status || sms.status === 'ok') && (
          <>
            <Div className={styles.CodeTitle} translateId={label('code')} />
            <div className={styles.CodeContainer}>
              <CodeInput
                disabled={this.isDisabled()}
                error={error}
                fields={numOfFields}
                onChange={this.onChange}
                value={value}
              />
              {children}
            </div>
          </>
        )}
        {isResendButton && (
          <div>
            <Span
              className={styles.ResentButton}
              translateId={label('resendCode')}
              onClick={this.createNewSms}
            />
          </div>
        )}
        {showTimer && this.state.count && (timeOut || timeOutLeft || !sms.status) && (
          <div className={styles.Text}>
            <Translate id={label('resendCodeAfter')} />
            <Timer onOver={this.clearTimer} count={this.state.count} />
            <Translate id={label('second')} />
          </div>
        )}
      </div>
    );
  }
}

SmsInput.propTypes = {
  hasResend: PropTypes.bool.isRequired,
  signature: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  resetSignature: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  createSms: PropTypes.func.isRequired,

  phoneNumber: PropTypes.string,
  onResend: PropTypes.func,
  isLoading: PropTypes.bool,
  smsLength: PropTypes.number,
  count: PropTypes.number,
  locale: PropTypes.string.isRequired,
};

SmsInput.defaultProps = {
  hasResend: true,
  smsLength: 4,
  count: 360,
};

const mapState = ({ signature, localize }) => ({
  locale: getActiveLanguage(localize).code,
  translate: getTranslate(localize),
  signature,
});

const mapDispatch = {
  onChange: signatureValueChange,
  resetSignature,
  createSms,
};

export default connect(
  mapState,
  mapDispatch
)(SmsInput);
