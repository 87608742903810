import React from 'react';
import { ObjectManager } from 'react-yandex-maps';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { atmsFilterSelector } from 'store/selectors/atms';
import PropTypes from 'prop-types';

class AtmsObjects extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.atms !== nextProps.atms || this.props.translate !== nextProps.translate) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const { translate, atms, getPointData, getPointOptions } = this.props;

    const atmsFeatures = [];

    for (let i = 0; i < atms.length; i++) {
      atmsFeatures.push({
        type: 'Feature',
        id: atms[i].atmId,
        geometry: {
          type: 'Point',
          coordinates: [atms[i].latitude, atms[i].longitude],
        },
        properties: getPointData(atms[i], translate),
        options: getPointOptions(atms[i]),
      });
    }

    return (
      <ObjectManager
        options={{
          clusterize: true,
          gridSize: 90,
          groupByCoordinates: false,
          clusterHideIconOnBalloonOpen: false,
          geoObjectHideIconOnBalloonOpen: false,
          clusterZoomMargin: 20,
        }}
        objects={{
          openBalloonOnClick: true,
          preset: 'islands#greenDotIcon',
        }}
        clusters={{
          preset: 'islands#redClusterIcons',
        }}
        features={atmsFeatures}
      />
    );
  }
}

AtmsObjects.propTypes = {
  translate: PropTypes.func.isRequired,
  atms: PropTypes.array,
  getPointData: PropTypes.func.isRequired,
  getPointOptions: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  atms: atmsFilterSelector(state),
  translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(AtmsObjects);
