import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setProtection } from 'actions/security';
import { SmsInput, MobipassInput, ScratchCardInput, Button } from 'shared';

import { isSubmitValid, validateSubmit } from 'shared/validators';
import { labelMaker, formatPhone } from 'shared/utils';

import styles from './Step3.module.scss';

const label = labelMaker('profile.security.protection');

class Step3 extends Component {
  handleChangeProtection = () => {
    const {
      setProtection,
      signature: { value: psw, hash, schemeId },
      fields,
      resetForm,
    } = this.props;
    const protectionValue = fields.protection.value;

    const answer =
      protectionValue === 'controlQuestion'
        ? fields.answer.value
        : protectionValue === 'controlWord'
          ? fields.controlWord.value
          : fields.controlDate.value;
    const question = fields.questions.value;

    const fieldsForValidation = [];

    if (protectionValue === 'controlQuestion') {
      fieldsForValidation.push('answer');
    }

    if (protectionValue === 'controlWord') {
      fieldsForValidation.push('controlWord');
    }

    if (protectionValue === 'controlDate') {
      fieldsForValidation.push('controlDate');
    }

    if (isSubmitValid(fields, fieldsForValidation)) {
      setProtection({
        type: protectionValue,
        answer,
        question,
        hash,
        schemeId,
        psw,
      }).then(data => {
        if (data && data.status === 'success') {
          resetForm();
        }
      });
    } else {
      validateSubmit(fields, fieldsForValidation, this.submitFields);
    }
  };

  renderSign = () => {
    const {
      auth: {
        user: { sign },
      },
      signature,
      resetForm,
      auth,
    } = this.props;

    switch (sign) {
      case '2':
        const { tankeyNumber } = signature.settings;
        return (
          <div>
            <ScratchCardInput
              onSubmit={this.handleChangeProtection}
              scratchCode={tankeyNumber}
              numOfFields={6}
            />
            <div className={styles.OTPSubmitButton}>
              <Button translateId={label('cancel')} theme="white" onClick={resetForm} />
              <Button onClick={this.handleChangeProtection} translateId={label('send')} />
            </div>
          </div>
        );
      case '3':
        const { mobipassKey } = signature.settings;
        return (
          <div>
            <MobipassInput docNumber={mobipassKey} />
            <div className={styles.OTPSubmitButton}>
              <Button translateId={label('cancel')} theme="white" onClick={resetForm} />
              <Button onClick={this.handleChangeProtection} translateId={label('send')} />
            </div>
          </div>
        );
      case '4':
        const { phone } = auth.user;
        return (
          <div>
            <SmsInput phoneNumber={phone ? formatPhone(phone) : null} />
            <div className={styles.OTPSubmitButton}>
              <Button translateId={label('cancel')} theme="white" onClick={resetForm} />
              <Button onClick={this.handleChangeProtection} translateId={label('send')} />
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  render() {
    return <Fragment>{this.renderSign()}</Fragment>;
  }
}

Step3.propTypes = {
  setProtection: PropTypes.func.isRequired,
  submitFields: PropTypes.func.isRequired,
  security: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  signature: PropTypes.object.isRequired,
  resetForm: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, profile, signature }) => ({
  auth,
  security: profile.security,
  signature,
});

const mapDispatchToProps = {
  setProtection,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step3);
