import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchAtmsByCoordinates, fetchBranchesByCoordinates } from 'actions/atmsAndBranches';

import AtmsContentHeader from './components/AtmsContentHeader/AtmsContentHeader';
import AtmsList from './components/AtmsList/AtmsList';
import AtmsOnMap from './components/AtmsOnMap/AtmsOnMap';
import { withErrorBoundary } from 'shared';

import styles from './Atms.module.scss';

class Atms extends Component {
  state = {
    center: ['55.753215', '37.622504'],
    zoom: 11,
    minZoom: 11,
    mapRef: null,
  };

  setMapRef = element => {
    this.setState({ mapRef: element });
  };

  moveAndZoom = (center, zoom) => {
    this.setState({
      center,
      zoom: zoom ? zoom : this.state.zoom,
    });
  };

  setZoom = zoom => {
    this.setState({ zoom: zoom });
  };

  zoomIn = () => {
    this.setState(
      {
        zoom: this.state.zoom + 1,
      },
      () => {
        const coords = this.state.mapRef.getBounds();
        this.props.fetchBranchesByCoordinates(coords);
        this.props.fetchAtmsByCoordinates(coords);
      }
    );
  };

  zoomOut = () => {
    if (this.state.zoom === this.state.minZoom) {
      return;
    }
    this.setState(
      {
        zoom: this.state.zoom - 1,
      },
      () => {
        const coords = this.state.mapRef.getBounds();
        this.props.fetchBranchesByCoordinates(coords);
        this.props.fetchAtmsByCoordinates(coords);
      }
    );
  };

  render() {
    const { center, zoom, minZoom, mapRef } = this.state;
    return (
      <div className={styles.Card}>
        <AtmsContentHeader moveAndZoom={this.moveAndZoom} />
        <Switch>
          <Route
            exact
            path="/atms/map"
            render={() => (
              <AtmsOnMap
                {...{ center, zoom, minZoom, mapRef }}
                moveAndZoom={this.moveAndZoom}
                zoomIn={this.zoomIn}
                zoomOut={this.zoomOut}
                setZoom={this.setZoom}
                setMapRef={this.setMapRef}
              />
            )}
          />
          <Route exact path="/atms/list" component={AtmsList} />
          <Redirect push to="/atms/map" />
        </Switch>
      </div>
    );
  }
}

Atms.propTypes = {
  location: PropTypes.object.isRequired,
  fetchAtmsByCoordinates: PropTypes.func.isRequired,
  fetchBranchesByCoordinates: PropTypes.func.isRequired,
};

const mapDispatch = { fetchBranchesByCoordinates, fetchAtmsByCoordinates };

export default withRouter(
  connect(
    null,
    mapDispatch
  )(withErrorBoundary(Atms, 'Atms'))
);
