import React, { PureComponent, Fragment } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { Button, Translation } from 'shared';
import { labelMaker } from 'shared/utils';

import styles from './Step1.module.scss';

const label = labelMaker('transfers');

class Step1 extends PureComponent {
  render() {
    const {
      transfers,
      cardsOptions,
      options,
      renderPaymentAccountLabel,
      renderPaymentAccountOption,
    } = this.props;

    const isFieldDisabled = transfers.transferForm.disabled || transfers.transferForm.isLoading;

    return (
      <Fragment>
        <div className={styles.InputRow}>
          <Field
            {...options.senderPhone}
            isLoading={transfers.transferForm.isAccountLoading}
            disabled
          />
        </div>
        <div className={styles.InputRow}>
          <Translation.Div
            className={styles.InputLabel}
            translateId={label('labels.transferFrom')}
          />
          <Field
            {...options.cards}
            renderLabel={renderPaymentAccountLabel}
            renderOption={renderPaymentAccountOption}
            options={cardsOptions}
            isLoading={transfers.transferForm.isAccountLoading}
            disabled={isFieldDisabled}
          />
        </div>
        <div className={styles.InputRow}>
          <Field {...options.phone} disabled={isFieldDisabled} />
        </div>
        <div className={styles.ButtonContainer}>
          <Button
            type="submit"
            translateId={label('btns.continue')}
            showLoader={transfers.transferForm.isLoading}
            disabled={isFieldDisabled}
          />
        </div>
      </Fragment>
    );
  }
}

Step1.propTypes = {
  cardsOptions: PropTypes.array.isRequired,
  transfers: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  renderPaymentAccountLabel: PropTypes.func.isRequired,
  renderPaymentAccountOption: PropTypes.func.isRequired,
};

export default Step1;
