import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouteLink } from 'react-router-dom';
import { Translate } from 'react-localize-redux';

const Link = ({ children, translateId, data, ...rest }) => {
  return (
    <RouteLink {...rest}>
      {translateId ? <Translate id={translateId} data={data} /> : children}
    </RouteLink>
  );
};

Link.propTypes = {
  children: PropTypes.node,
  translateId: PropTypes.string.isRequired,
  data: PropTypes.object,
};

export default Link;
