import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Step1 from './1';
import Step2 from './2';
import Step3 from './3';
import Step4 from './4';
import Step5 from './5';
import { Translation } from 'shared';
import { formatNumber, labelMaker } from 'shared/utils';
import styles from './Steps.module.scss';

const label = labelMaker('transfers');

class Steps extends PureComponent {
  formatCards = cards =>
    Array.isArray(cards)
      ? cards
          .map(card => ({
            label: card.cardNumber,
            value: card.cardNumber,
            payload: card,
          }))
          .filter(
            card =>
              card.payload.currency === '810' ||
              card.payload.currency === '643' ||
              card.payload.currency === 'RUR'
          )
          .filter(card => card.status !== 'BLCK')
          .filter(card => card.status !== 'CLOSE')
          .filter(card => !card.payload.drCr || card.payload.drCr !== 'C')
      : [];

  renderPaymentAccountLabel = option => {
    const { name, availableBalance, currency } = option.payload;

    const title = name ? name : 'Card';

    return (
      <div className={cn(styles.AccountLabel)}>
        <div className={styles.AccountLabelBlock}>
          <div className={styles.AccountLabelVale}>{title}</div>
        </div>
        <div className={styles.AccountOptionSum}>
          {formatNumber(availableBalance, { currCode: currency })}
        </div>
      </div>
    );
  };

  renderPaymentAccountOption = ({ handleOption, isActive, option }) => {
    const { name, availableBalance, currency, cardNumber } = option.payload;
    const { value } = option;

    const title = name ? name : 'Card';

    return (
      <div
        className={cn(styles.StandardOption, { [styles.StandardActiveOption]: isActive })}
        onClick={handleOption(value)}
        key={value}
      >
        <div className={styles.OptionInfo}>
          <span>{title}</span>
          <span className={styles.OptionCardNumber}>{cardNumber}</span>
        </div>
        <div className={styles.OptionBalance}>
          {formatNumber(availableBalance, { currCode: currency })}
        </div>
      </div>
    );
  };

  render() {
    const { step, cards, ...rest } = this.props;

    const cardsOptions = this.formatCards(cards.data);

    if (cards.isLoading && !cardsOptions.length) {
      return (
        <Translation.Div className={styles.NoInfoTitle} translateId={label('titles.noCards')} />
      );
    }

    switch (step) {
      case 1:
        return (
          <Step1
            {...rest}
            cardsOptions={cardsOptions}
            renderPaymentAccountLabel={this.renderPaymentAccountLabel}
            renderPaymentAccountOption={this.renderPaymentAccountOption}
          />
        );
      case 2:
        return (
          <Step2
            {...rest}
            cardsOptions={cardsOptions}
            renderPaymentAccountLabel={this.renderPaymentAccountLabel}
            renderPaymentAccountOption={this.renderPaymentAccountOption}
          />
        );
      case 3:
        return <Step3 {...rest} />;
      case 4:
        return <Step4 {...rest} />;
      case 5:
        return <Step5 {...rest} />;
      default:
        return null;
    }
  }
}

Steps.propTypes = {
  cards: PropTypes.object.isRequired,
  step: PropTypes.number.isRequired,
};

export default Steps;
