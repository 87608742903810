import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import DefaultBankForm from './DefaultBankForm';

class DefaultBankBlock extends Component {
  render() {
    const { transfers, accounts } = this.props;

    return <DefaultBankForm accounts={accounts} transfers={transfers} />;
  }
}

const mapState = ({ accounts, transfers }) => ({
  accounts,
  transfers,
});

DefaultBankBlock.propTypes = {
  accounts: PropTypes.object.isRequired,
  transfers: PropTypes.object.isRequired,
};

export default connect(mapState)(DefaultBankBlock);
