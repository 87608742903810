import { createAction } from 'redux-act';
import { getTranslate } from 'react-localize-redux';

import { fetchPayDocumentReq, fetchPaymentSignatureReq } from './paymentRequests';
import { fetchAccounts } from '../accounts';
import { fetchCards, fetchCardsCatalog } from 'actions/cards';
import { postMessage, confirmMessage } from '../signature';
import { initializePersonal } from '../personal';
import { hideNotification, showWarnNotification } from '../notification';

import { buildUrlParams, encodeDataURI, labelMaker, get, getErrorMessage } from 'shared/utils';
import { showOverlay, hideOverlay } from '../overlay';

const lError = labelMaker('errorsGeneral');

export const confirmDocumentError = createAction('CONFIRM_DOCUMENT_ERROR');
export const confirmDocumentStart = createAction('CONFIRM_DOCUMENT_START');
export const confirmDocumentSuccess = createAction('CONFIRM_DOCUMENT_SUCCESS');
export const getAccountToAccountAccountsError = createAction(
  'GET_ACCOUNT_TO_ACCOUNT_ACCOUNTS__ERROR'
);
export const getAccountToAccountAccountsStart = createAction(
  'GET_ACCOUNT_TO_ACCOUNT_ACCOUNTS_STARTS'
);
export const getAccountToAccountAccountsSuccess = createAction(
  'GET_ACCOUNT_TO_ACCOUNT_ACCOUNTS__SUCCESS'
);
export const paymentBackStep = createAction('PAYMENT_BACK_STEP');
export const paymentNextStep = createAction('PAYMENT_NEXT_STEP');
export const resetPaymentAmount = createAction('RESET_PAYMENT_SUM');
export const resetPaymentFormEnd = createAction('RESET_PAYMENT_FORM_END');
export const resetPaymentFormStart = createAction('RESET_PAYMENT_FORM_START');
export const saveDocumentOnPaymentError = createAction('SAVE_DOCUMENT_ON_PAYMENT_ERROR');
export const saveDocumentOnPaymentStart = createAction('SAVE_DOCUMENT_ON_PAYMENT_START');
export const saveDocumentOnPaymentSuccess = createAction('SAVE_DOCUMENT_ON_PAYMENT_SUCCESS');
export const setPaymentAmount = createAction('SET_PAYMENT_SUM');
export const setPaymentIframeUrl = createAction('SET_PAYMENT_IFRAME_URL');

export const initPaymentData = () => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(showOverlay());

    const accounts = await dispatch(fetchAccounts());
    if (Array.isArray(accounts)) {

      dispatch(getAccountToAccountAccountsStart());
      const response = await Promise.all([dispatch(fetchPayDocumentReq()), dispatch(fetchCards())]);

      const transferData = response[0] && response[0].data;
      const cards = response[1];

      if (!transferData || !Array.isArray(cards)) {
        throw translate(lError('serviceUnavailable'));
      }

      await dispatch(fetchCardsCatalog());
      dispatch(
        getAccountToAccountAccountsSuccess({
          ...transferData,
        })
      );

      dispatch(hideOverlay());
      dispatch(hideNotification());

      const forceUpdate = true;
      const personalData = await dispatch(initializePersonal(forceUpdate));

      const { payment, auth, localize, products } = getState();

      const { drsettlementacc } = products.credit;
      const { abscustid, fullname } = auth.user;
      const amount = parseFloat(payment.amount);

      const creditAccount = accounts.find(acc => acc.accountNo === drsettlementacc) || {};

      const creditNumber = creditAccount.cbAccountNo;
      const acc_number = creditAccount.cbAccountNo;

      const email = get(personalData, 'email');
      const paymentSignatureArgs = {
        amount,
        creditNumber,
        clientRef: abscustid,
        email,
        fio: fullname,
      };

      dispatch(fetchPaymentSignatureReq(paymentSignatureArgs))
        .then(paymentSignature => {
          const { code, sector, signature, paymentUrl } = paymentSignature;
          const lang = localize.languages.filter(lang => lang.active)[0].code;

          const urlParams = buildUrlParams(
            encodeDataURI({
              sector,
              code,
              acc_number,
              fio: fullname,
              amount: amount * 100,
              email,
              client_ref: abscustid,
              lang,
              signature,
            })
          );
          dispatch(setPaymentIframeUrl({ urlParams, baseUrl: paymentUrl }));
        })
        .catch(err => null);
    } else {
      dispatch(showWarnNotification(translate(lError('serviceUnavailable'))));
      return;
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(hideOverlay());
    dispatch(getAccountToAccountAccountsError(errMessage));
    dispatch(showWarnNotification(errMessage));
  }
};

export const saveDocumentOnPayment = args => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(saveDocumentOnPaymentStart());

    const reqData = {
      accountDebet: args.accountDebet,
      accountCredit: args.accountCredit,
      currCode: args.currCode,
      amount: args.amount,
      docId: 1126,
      console: 'json',
    };

    const response = await dispatch(
      postMessage({ url: '/webapi-1.0/bss/saveDocument', data: reqData })
    );
    if (!response) { return; }
    if (response && !response.signNeeded) {
      dispatch(paymentNextStep());
    }
    dispatch(saveDocumentOnPaymentSuccess({ ...reqData }));
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(resetPaymentForm());
    dispatch(saveDocumentOnPaymentError());
  }
};

export const confirmDocument = code => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(confirmDocumentStart());

    const { signature } = getState();

    const { cashToken, smsId, schemeId, hash, sign, confirmType } = signature.settings;
    const { value } = signature;
    const data = {
      cashToken: cashToken,
      smsId,
      code: code ? code : value,
      hash,
      schemeId,
      sign,
      confirmType,
      schemeName: 'PAYDOCUMENT',
    };

    await dispatch(confirmMessage(data));
    dispatch(confirmDocumentSuccess());
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(confirmDocumentError());
    dispatch(saveDocumentOnPaymentError());
  }
};

export const resetPaymentForm = () => dispatch => {
  dispatch(resetPaymentFormStart());
  dispatch(resetPaymentFormEnd());
};
