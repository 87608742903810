import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';

import Form from './Form/Form';

import pwdRecoveryTranslation from './pwdRecovery.json';

import styles from './PasswordRecovery.module.scss';

class PasswordRecovery extends Component {
  constructor(props) {
    super(props);
    props.addTranslation(pwdRecoveryTranslation);
  }

  render() {
    return (
      <div className={styles.Card}>
        <div className={styles.FormColumn}>
          <Form />
        </div>

        <div className={styles.ImageColumn}>
          <div className={styles.Image} />
          <div className={styles.ColumnFooter}>
            <div className={styles.TextLogo} />
          </div>
        </div>
      </div>
    );
  }
}

PasswordRecovery.propTypes = {
  addTranslation: PropTypes.func.isRequired,
};

export default withLocalize(PasswordRecovery);
