import { createAction } from 'redux-act';
import { getTranslate } from 'react-localize-redux';
import { showWarnNotification } from '../notification';
import { getErrorMessage, labelMaker } from 'shared/utils';
import { fetchAtmsByCoordinatesReq, fetchBranchesByCoordinatesReq } from './atmsRequests';

export const setAtmsAndBranchesView = createAction('SET_ATMS_AND_BRANCHES_VIEW');
export const fetchAtmsStart = createAction('FETCH_ATMS_START');
export const fetchAtmsSuccess = createAction('FETCH_ATMS_SUCCESS');
export const fetchAtmsError = createAction('FETCH_ATMS_ERROR');
export const fetchBranchesStart = createAction('FETCH_BRANCHES_START');
export const fetchBranchesSuccess = createAction('FETCH_BRANCHES_SUCCESS');
export const fetchBranchesError = createAction('FETCH_BRANCHES_ERROR');
export const setAtmsAndBranchesFilter = createAction('SET_ATMS_BRANCHES_FILTER');
export const clearAtmsAndBranchesFilter = createAction('CLEAR_ATMS_BRANCHES_FILTER');
export const clearAtms = createAction('CLEAR_ATMS');
export const clearBranches = createAction('CLEAR_BRANCHES');

const lError = labelMaker('errorsGeneral');

export const fetchBranchesByCoordinates = coordsArray => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(fetchBranchesStart());
    const coords = `${coordsArray[0].join()},${coordsArray[1].join()}`;
    const response = await dispatch(fetchBranchesByCoordinatesReq({ coords }));
    const branches = response.content;
    if (Array.isArray(branches)) {
      dispatch(fetchBranchesSuccess({ data: branches }));
    } else {
      dispatch(fetchBranchesSuccess({ data: [] }));
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(fetchBranchesError());
  }
};

export const fetchAtmsByCoordinates = coordsArray => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(fetchAtmsStart());
    const coords = `${coordsArray[0].join()},${coordsArray[1].join()}`;
    const response = await dispatch(fetchAtmsByCoordinatesReq({ coords }));

    const atms = response.content;
    if (Array.isArray(atms)) {
      dispatch(fetchAtmsSuccess({ data: atms }));
    } else {
      dispatch(fetchAtmsSuccess({ data: [] }));
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(fetchAtmsError());
  }
};
