import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Input from 'react-text-mask';
import { getActiveLanguage } from 'react-localize-redux';
import { connect } from 'react-redux';
import moment from 'moment';

import { Translation, InputError, Spinner } from '../../';

import CalendarContainer from './CalendarContainer';

import styles from './DateInput.module.scss';

class MaskedInput extends Component {
  state = {
    isCalendarOpen: false,
    hasFocus: false,
  };

  getLocaleDateFormat = locale =>
    ({
      en: 'MM.DD.YYYY',
      ru: 'DD.MM.YYYY',
    }[locale]);

  onFocus = () => this.setState({ hasFocus: true });

  onBlur = e => {
    this.setState({
      hasFocus: false,
      showError: true,
    });
    this.onChange(e, true);
  };

  toggleCalendar = () => this.setState({ isCalendarOpen: !this.state.isCalendarOpen });

  onChange = ({ target }, isError) => {
    const { name, value } = target;
    const { onChange } = this.props;

    onChange({ value, name }, isError);
  };

  onCalendarChange = value => {
    const { name, onChange, localize } = this.props;

    const dateFormat = this.getLocaleDateFormat(getActiveLanguage(localize).code);

    onChange({ value: moment(value).format(dateFormat), name });
    this.toggleCalendar();
  };

  outsideClick = () => {
    if (this.state.isCalendarOpen) {
      this.toggleCalendar();
    }
  };

  render() {
    const {
      disabled,
      errorId,
      isError,
      isLoading,
      labelId,
      mask,
      name,
      placeholder,
      tileDisabled,
      touched,
      value,
      localize,
      minDate,
    } = this.props;
    const { hasFocus, isCalendarOpen } = this.state;

    const isErrorVisible = touched && errorId && !disabled && !isLoading && isError;
    const isInputFocused = hasFocus && !disabled && !isLoading;

    const dateFormat = this.getLocaleDateFormat(
      localize.languages.filter(lang => lang.active)[0].code
    );

    const isDateValid = moment(value, dateFormat, true).isValid();
    const calValue = isDateValid ? moment(value, dateFormat).toDate() : new Date();
    const inpValue = isDateValid ? value.toLocaleString().slice(0, 10) : value;

    const error = isErrorVisible ? errorId : null;

    const newPlaceholder = !hasFocus || value ? '' : placeholder;

    const externalBorderClassName = cn(styles.ExternalBorder, {
      [styles.DisabledExternalBorder]: disabled || isLoading,
      [styles.FocusedExternalBorder]: isInputFocused,
      [styles.ErrorExternalBorder]: isErrorVisible,
    });
    const internalBorderClassName = cn(styles.InternalBorder, {
      [styles.FocusedInternalBorder]: isInputFocused,
      [styles.ErrorInternalBorder]: isErrorVisible,
    });
    const labelClassName = cn(styles.Label, {
      [styles.FocusedLabel]: (hasFocus || value) && !isLoading,
      [styles.DisabledLabel]: disabled || isLoading,
    });
    const calendarClassName = cn(styles.CalendarIcon, {
      [styles.DisabledCalendarIcon]: disabled || isLoading,
    });

    return (
      <div className={styles.Container}>
        <Translation.Div className={labelClassName} translateId={labelId} />
        <div className={externalBorderClassName}>
          <div className={internalBorderClassName}>
            <Input
              className={styles.Input}
              onChange={this.onChange}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              autoComplete="off"
              value={isLoading ? '' : inpValue}
              placeholder={newPlaceholder}
              guide={false}
              name={name}
              mask={mask}
              disabled={disabled || isLoading}
            />
          </div>
        </div>
        {isLoading ? (
          <Spinner className={styles.Loading} />
        ) : (
          <div onClick={this.toggleCalendar} className={calendarClassName} />
        )}
        {isCalendarOpen ? (
          <CalendarContainer
            onCalendarChange={this.onCalendarChange}
            tileDisabled={tileDisabled}
            outsideClick={this.outsideClick}
            isCalendarOpen={isCalendarOpen}
            minDate={minDate}
            value={calValue}
          />
        ) : null}
        <InputError errorId={error} />
      </div>
    );
  }
}

MaskedInput.propTypes = {
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  errorId: PropTypes.string,
  isError: PropTypes.bool,
  labelId: PropTypes.string,
  localize: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  tileDisabled: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  touched: PropTypes.bool,
  value: PropTypes.any,
  mask: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  minDate: PropTypes.instanceOf(Date),
};

MaskedInput.defaultProps = {
  onChange: () => {},
  value: '',
};

const mapStateToProps = ({ localize }) => ({
  localize,
});

export default connect(mapStateToProps)(MaskedInput);
