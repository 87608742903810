import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { initialize } from 'react-localize-redux';
import moment from 'moment';
import 'moment/locale/ru';

import Login from 'pages/Login';
// import Chat from '../Chat/Chat';
import PasswordRecovery from 'pages/PasswordRecovery';
import Profile from 'pages/Profile';
import Products from 'pages/Products';
import OperationsHistory from 'pages/OperationsHistory';
import Bonuses from 'pages/Bonuses';
import Signup from 'pages/Signup';
import Testing from 'pages/Testing';
import Atms from 'pages/Atms';
import Transfers from 'pages/Transfers';
import { rejectMobileDownload } from 'actions/auth';
// import { initializeChat } from 'actions/chat';
import { fetchSettings } from 'actions/settings';
import { fetchPrevAuthNotifyEnabled } from 'actions/security/prevAuthNotify';
import { clearQueue } from 'actions/notification';
import { fetchDadataApiKey } from 'actions/dadata';
import { Overlay, Button, Translation, withErrorBoundary } from 'shared';

import globalTranslations from 'translations/global.json';

import mobileBg from 'assets/background/image-banner.png';
import styles from './App.module.scss';

let defaultLanguage = 'ru';
try {
  const language = localStorage.getItem('profile/language');
  defaultLanguage = ['en', 'ru'].includes(language) ? language : defaultLanguage;
} catch (e) {}

class App extends Component {
  constructor(props) {
    super(props);

    moment.locale(defaultLanguage);

    this.props.initialize({
      languages: [
        { name: 'English', code: 'en' },
        { name: 'Русский', code: 'ru' },
      ],
      translation: globalTranslations,
      options: {
        defaultLanguage,
        ignoreTranslateChildren: true,
        renderToStaticMarkup,
        renderInnerHtml: true,
      },
    });
  }

  componentDidMount() {
    const { fetchDadataApiKey, isAuthenticated, fetchSettings } = this.props;

    window.addEventListener('beforeunload', ev => {
      ev.preventDefault();

      localStorage.setItem('unloadTime', new Date());
    });
    fetchSettings();
    if (isAuthenticated) {
      fetchDadataApiKey();
      // временно скрываем чат https://jira.intranet/browse/DC-5142
      // initializeChat();
    }
  }

  componentWillUnmount() {
    const { clearQueue } = this.props;
    clearQueue();
  }

  rejectMobileDownload = () => {
    const { rejectMobileDownload } = this.props;
    localStorage.setItem('isAskRequiredDownloadMobileApp', JSON.stringify('true'));

    rejectMobileDownload();
  };

  renderMobileScreen() {
    return (
      <div className={styles.MobileScreen}>
        <img src={mobileBg} alt="" className={styles.MobileImage} />
        <div className={styles.MobileContent}>
          <div className={styles.MobileTitle}>
            <Translation.Span translateId="auth.mobileTitle" />
          </div>
          <div className={styles.MobileDescription}>
            <Translation.Span translateId="auth.mobileDescription" />
          </div>
          <a href="https://app.unicredit.ru">
            <Button className={styles.MobileDownloadBtn} translateId="auth.download" />
          </a>
          <Translation.Div
            className={styles.MobileRejectBtn}
            translateId="auth.noThanks"
            onClick={this.rejectMobileDownload}
          />
        </div>
      </div>
    );
  }

  getProducts = () => {
    return Products;
  };

  render() {
    const { history, isAuthenticated, showMobiScreen } = this.props;

    const isMobile = /Mobi/.test(navigator.userAgent) && showMobiScreen;

    const isAskedDownloadMobileApp = JSON.parse(
      localStorage.getItem('isAskRequiredDownloadMobileApp')
    );

    return (
      <Router history={history}>
        <Fragment>
          {/* {isAuthenticated && <Chat />} */}
          {isMobile && !isAskedDownloadMobileApp ? (
            this.renderMobileScreen()
          ) : (
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/restore-password" component={PasswordRecovery} />
              <Route exact path="/signup" component={Signup} />
              <Route path="/profile" component={Profile} />
              <Route path="/products" component={Products} />
              <Route path="/bonuses" component={Bonuses} />
              <Route path="/history" component={OperationsHistory} />
              <Route path="/atms" component={Atms} />
              <Route path="/testing" component={Testing} />
              <Route path="/transfers" component={Transfers} />

              <Redirect to={!isAuthenticated ? '/login' : '/products/404'} />
            </Switch>
          )}
          <Overlay />
        </Fragment>
      </Router>
    );
  }
}

App.propTypes = {
  initialize: PropTypes.func.isRequired,
  rejectMobileDownload: PropTypes.func.isRequired,
  fetchDadataApiKey: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
  dadata: PropTypes.object.isRequired,
  // initializeChat: PropTypes.func.isRequired,
  fetchSettings: PropTypes.func.isRequired,
  showMobiScreen: PropTypes.bool,
  fetchPrevAuthNotifyEnabled: PropTypes.func.isRequired,
};
const mapStateToProps = ({ auth, dadata }) => ({
  isAuthenticated: auth.isAuthenticated,
  showMobiScreen: auth.showMobiScreen,
  dadata,
});
const mapDispatchToProps = {
  rejectMobileDownload,
  fetchDadataApiKey,
  // initializeChat,
  fetchSettings,
  initialize,
  fetchPrevAuthNotifyEnabled,
  clearQueue,
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorBoundary(App, 'App'));
