import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Button, Translation, MobipassInput, ScratchCardInput, SmsInput } from 'shared';
import { labelMaker, formatNumber, getTranslatedField2, formPhone } from 'shared/utils';

import styles from './Step4.module.scss';

const label = labelMaker('transfers');

class Step4 extends PureComponent {
  renderSecurity = () => {
    const { signature, transfers, profile } = this.props;

    switch (signature.settings.confirmType) {
      case 'mobipass': {
        return (
          <Fragment>
            <MobipassInput
              docNumber={1}
              numOfFields={6}
              disabled={transfers.transferForm.disabled}
            />
            <div className={styles.ButtonContainer}>
              <Button
                disabled={transfers.transferForm.disabled}
                showLoader={transfers.transferForm.isLoading}
                type="submit"
                translateId={label('btns.send')}
              />
            </div>
          </Fragment>
        );
      }
      case 'tan': {
        const { codeLength, tankeyNumber } = signature.settings;

        return (
          <Fragment>
            <ScratchCardInput
              scratchCode={tankeyNumber}
              numOfFields={parseInt(codeLength, 10)}
              disabled={transfers.transferForm.disabled}
            />
            <div className={styles.ButtonContainer}>
              <Button
                disabled={transfers.transferForm.disabled}
                showLoader={transfers.transferForm.isLoading}
                type="submit"
                translateId={label('btns.send')}
              />
            </div>
          </Fragment>
        );
      }
      case 'sms_code':
        const phone = formPhone(profile.phone.phone);

        return (
          <div>
            <SmsInput phoneNumber={phone ? phone : null} />
            <div className={styles.ButtonContainer}>
              <Button
                onClick={this.handleConfirmMessage}
                translateId={label('btns.send')}
                showLoader={transfers.transferForm.isLoading}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  render() {
    const { form, transfers, locale } = this.props;

    const { cards, payment, beneficiaryBank } = form.transfers.values;
    const bank = transfers.banks.banks.find(bank => bank.title === beneficiaryBank);
    const bankName = getTranslatedField2(bank, 'text', { locale });

    return (
      <Fragment>
        <div className={styles.CurrencyTable}>
          <div className={styles.ThRow}>
            <Translation.Div
              className={styles.ThColInfo}
              translateId={label('titles.chargeSource')}
            />
            <div className={styles.ThColResult}>{cards}</div>
          </div>
          <div className={styles.ThRow}>
            <Translation.Div
              className={styles.ThColInfo}
              translateId={label('titles.transferAmount')}
            />
            <div className={styles.ThColResult}>{formatNumber(payment, { currency: 'RUR' })}</div>
          </div>
          <div className={styles.TdRow}>
            <Translation.Div className={styles.TdColInfo} translateId={label('titles.recipient')} />
            <div className={styles.TdColResult}>{transfers.transferForm.beneficiaryPAM}</div>
          </div>
          <div className={styles.TdRow}>
            <Translation.Div className={styles.TdColInfo} translateId={label('titles.fee')} />
            <Translation.Div
              className={styles.TdColResult}
              translateId={label('titles.noCharge')}
            />
          </div>
          <div className={styles.TdRow}>
            <Translation.Div
              className={styles.TdColInfo}
              translateId={label('titles.beneficiaryBank')}
            />
            <div className={styles.TdColResult}>{bankName}</div>
          </div>
        </div>
        {this.renderSecurity()}
      </Fragment>
    );
  }
}

Step4.propTypes = {
  transfers: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  signature: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
};

export default Step4;
