import { getEncryptedStr } from 'shared/utils';
import Bowser from 'bowser';
import sha256 from 'crypto-js/sha256';
import apiServicesConstructor from 'services/apiService';
import {
  fetchRegistrationSettingsReqStub,
  sendRegistrationSmsCodeReqStub,
  checkRegistrationSmsCodeReqStub,
  registerUserReqStub,
  setUserProtectionReqStub,
} from './signupStubs';

const isDevelopment = process.env.NODE_ENV === 'development';
const useGlobalStubs = process.env.REACT_APP_USE_STUBS === 'true';

export const fetchRegistrationSettingsReq = (useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await fetchRegistrationSettingsReqStub(withError);
  }

  const reqParams = {
    url: `/webapi-1.0/bss/registration/firstStep`,
    headers: { 'Content-Type': 'application/json' },
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const sendRegistrationSmsCodeReq = (args = {}, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await sendRegistrationSmsCodeReqStub(withError);
  }
  const { phone, cardOrAccount } = args;

  const isCard = cardOrAccount && cardOrAccount.length <= 16;

  const cnHash = isCard && sha256(String(cardOrAccount).toUpperCase()).toString();
  const acc = !isCard && cardOrAccount;

  const data = {
    phoneNumberLastDigits: phone,
    cardNumberHash: cnHash || '',
    account: acc || '',
    email: '',
  };

  const reqParams = {
    url: '/webapi-1.0/bss/registration/secondStep',
    headers: { 'Content-Type': 'application/json' },
    data,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const checkRegistrationSmsCodeReq = (
  args = {},
  useStub = false,
  withError = false
) => async (dispatch, getState) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await checkRegistrationSmsCodeReqStub(withError);
  }

  const { cid, egp4, sms } = args;

  const data = {
    cid,
    egp4,
    smsCode: sms,
  };

  const reqParams = {
    url: '/webapi-1.0/bss/registration/thirdStep',
    headers: { 'Content-Type': 'application/json' },
    data,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const registerUserReq = (args = {}, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await registerUserReqStub(withError);
  }
  const {
    cid,
    egp4,
    cardOrAccount,
    sms,
    phone,
    login,
    password,
    confirmedPassword,
    passwordMapId,
    passwordMap,
    confirmedPasswordMapId,
    confirmedPasswordMap,
  } = args;

  const isCard = cardOrAccount.length > 15 && cardOrAccount.length < 19;

  const cnHash = isCard ? sha256(String(cardOrAccount).toUpperCase()).toString() : undefined;
  const acc = !isCard ? encodeURIComponent(cardOrAccount) : undefined;

  const data = {
    cid,
    egp4,
    smsCode: sms,
    phoneNumber: phone,
    newLogin: login,
    newPassword: getEncryptedStr(password, passwordMap),
    confirmPassword: getEncryptedStr(confirmedPassword, confirmedPasswordMap),
    newPasswordMapId: passwordMapId,
    confirmNewPasswordMapId: confirmedPasswordMapId,
    cardNumberHash: cnHash,
    account: acc,
    email: '',
  };

  const browser = Bowser.getParser(window.navigator.userAgent).getBrowser();
  const deviceInfo = encodeURI(JSON.stringify({
    browser: browser?.name,
    browserVersion:`${browser?.name} ${browser?.version}`,
    type: "web_lk"
  }))

  const reqParams = {
    url: '/webapi-1.0/bss/registration/fourthStep',
    headers: { 'Content-Type': 'application/json',"Device-Info": deviceInfo },
    data,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const setUserProtectionReq = (data, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await setUserProtectionReqStub(withError);
  }

  const reqParams = {
    url: '/webapi-1.0/bss/addProtectionSave',
    headers: { 'Content-Type': 'application/json' },
    data,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};
