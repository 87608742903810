import numeral from 'numeral';

import houseImg from '../assets/icons/icon-house.svg';
import carImg from '../assets/icons/icon-car.svg';
import moneyImg from '../assets/icons/icon-money.svg';

export const print = (template, ...args) => {
  const insertMark = /\${s}/;

  return args.reduce((template, value) => {
    const string = template.replace(insertMark, value);
    return string;
  }, template);
};

const getSuffix1 = locale =>
  ({
    en: '_en',
    ru: '_ru',
  }[locale]);

export const getTranslatedField1 = (obj, field, { locale }) => {
  if (!obj) {
    return '';
  }
  const suffixedField = `${field}${getSuffix1(locale)}`;

  if (obj.hasOwnProperty(suffixedField)) {
    return obj[suffixedField];
  } else {
    return obj[field];
  }
};

const getSuffix = locale =>
  ({
    en: 'Eng',
    ru: 'Rus',
  }[locale]);

export const getTranslatedField = (obj, field, { locale }) => {
  if (!obj) {
    return '';
  }
  const suffixedField = `${field}${getSuffix(locale)}`;

  if (obj.hasOwnProperty(suffixedField)) {
    return obj[suffixedField];
  } else {
    return obj[field];
  }
};

export const getSuffix2 = locale =>
  ({
    en: 'En',
    ru: 'Ru',
  }[locale]);

export const getTranslatedField2 = (obj, field, { locale }) => {
  if (!obj) {
    return '';
  }
  const suffixedField = `${field}${getSuffix2(locale)}`;

  if (obj.hasOwnProperty(suffixedField)) {
    return obj[suffixedField];
  } else {
    return obj[field];
  }
};

export const getNumOfDecimal = num =>
  num.toString().includes('.')
    ? num
      .toString()
      .split('.')
      .pop().length
    : 0;

export const shaveNumber = (num, settings = { fixed: 2 }) => {
  const { fixed } = settings;
  return parseFloat(
    parseFloat(num)
      .toFixed(fixed)
      .toString()
  );
};

export const isArrayEmpty = arr => !Array.isArray(arr) || !arr.length;

export const debounce = function (func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
};

export const timeout = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const throttle = (func, ms) => {
  let isThrottled = false,
    savedArgs,
    savedThis;

  function wrapper() {
    if (isThrottled) {
      savedArgs = arguments;
      savedThis = this;
      return;
    }

    func.apply(this, arguments);

    isThrottled = true;

    setTimeout(function () {
      isThrottled = false;
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedArgs = savedThis = null;
      }
    }, ms);
  }

  return wrapper;
};

export const encodeDataURI = data => {
  switch (defineType(data)) {
    case 'array':
      return data.map(item => encodeDataURI(item));
    case 'object':
      return Object.keys(data).reduce((acc, arg) => {
        acc[arg] = encodeDataURI(data[arg]);
        return acc;
      }, {});
    default:
      return encodeURIComponent(data);
  }
};

const defineType = data => {
  return Array.isArray(data)
    ? 'array'
    : data === null && typeof data === 'object'
      ? 'null'
      : typeof data;
};

export const getLocaleDateFormat = locale =>
  ({
    en: 'MM.DD.YYYY',
    ru: 'DD.MM.YYYY',
  }[locale]);

export const getValue = (obj, name) => {
  const number = get(obj, name, '');
  return numeral(number).value();
};

export const getBSSLanguage = locale => {
  return { en: 'ENGLISH', ru: 'RUSSIAN' }[locale];
};

export const getWebapiLanguage = locale => ({ en: 'EN', ru: 'RU' }[locale]);

export const isActiveButtonCode = code => {
  const codes = [
    8, // delete
    9, // tab
    13, // arrow
    16, // shift
    17, // control
    18, // option mac os
    20, // caps lock
    37, // left arrow
    39, // right arrow
    46, // delete
    48, // 0
    57, // 9
    91, // command mac os
  ];

  return codes.reduce((isActiveButton, item) => isActiveButton || item === code, false);
};

// Watch later
// const isPrimitive = obj => {
//   return obj !== Object(obj);
// };

// export const deepEqual = (obj1, obj2) => {
//   if (isPrimitive(obj1) && isPrimitive(obj2)) {
//     return obj1 == obj2;
//   }

//   if (Object.keys(obj1).length !== Object.keys(obj2).length) {
//     return false;
//   }

//   for (let key in obj1) {
//     if (!(key in obj2)) {
//       return false;
//     }
//     if (!deepEqual(obj1[key], obj2[key])) {
//       return false;
//     }
//   }

//   return true;
// };

export const isCode = code => verification => code === verification;

export const isNumberInRange = range => verification =>
  verification >= range.from && verification <= range.to;

export const isNumberInRanges = ranges => verification => {
  if (Array.isArray(ranges)) {
    return ranges.some(range => isNumberInRange(range)(verification));
  }
  return false;
};

export const isNumericCode = isNumberInRanges([{ from: 48, to: 57 }, { from: 96, to: 105 }]);
export const isDot = isNumberInRanges([{ from: 190, to: 191 }, { from: 110, to: 110 }]);

export const isEnterCode = isCode(13);
export const isBackspaceCode = isCode(8);

export const getCurrencySymbol = (currency = null) => {
  const symbols = {
    USD: '$',
    RUR: '₽',
    RUB: '₽',
    EUR: '€',
  };

  return symbols[currency ? currency.replace(/\s/g, '') : ''];
};

export const getCurrencyName = (currency = null) => {
  const symbols = {
    USD: '$',
    RUR: '₽',
    RUB: '₽',
    EUR: '€',
    810: '₽',
    643: '₽',
    978: '€',
  };

  return symbols[currency ? currency.replace(/\s/g, '') : ''];
};

export const getCurrencyFullName = (currency = null) => {
  const symbols = {
    USD: 'usd',
    RUR: 'руб',
    RUB: 'руб',
    EUR: 'eur',
    810: 'руб',
    643: 'руб',
    978: 'eur',
  };

  return symbols[currency ? currency.replace(/\s/g, '') : ''];
};

export const appendCurrencySymbol = (str, currency = null) => {
  const symbols = {
    USD: '$',
    RUR: '₽',
    RUB: '₽',
    EUR: '€',

    Рубли: '₽',
    РублиРФ: '₽',
    Доллары: '$',
    ДолларыСША: '$',
    Евро: '€',
  };

  const symbol = symbols[currency ? currency.replace(/\s/g, '') : ''];
  return currency ? `${str} ${symbol}` : str;
};

export const appendCurrencySymbolByCode = (str, currCode = null) => {
  const symbols = {
    USD: '$',
    RUR: '₽',
    EUR: '€',
    810: '₽',
    643: '₽',
    978: '€',
  };

  const symbol = symbols[currCode ? currCode.replace(/\s/g, '') : ''];
  return currCode ? `${str} ${symbol}` : str;
};

export const formatNumber = (val, { currency, currCode, round = 2 } = {}) => {
  const formattedString = numeral(val).format(`0,0.${'0'.repeat(round)}`);

  const number = formattedString.replace(/,/g, ' ');
  if (!currency && !currCode) {
    return number;
  }
  if (currCode) {
    return appendCurrencySymbolByCode(number, currCode);
  }
  return appendCurrencySymbol(number, currency);
};

export const capitalize = str => {
  if (!str || typeof str !== 'string') {
    return '';
  }
  return str.slice(0, 1).toUpperCase() + str.slice(1);
};

export const labelMaker = prefix => id => `${prefix ? prefix + '.' : ''}${id}`;

export const formPhone = phone => {
  if (!phone) {
    return '';
  }

  return `7${String(phone).substr(String(phone).length - 10)}`;
};

export const convertPhoneToInternationalFormat = phone => {
  if (!phone) {
    return '';
  }

  return `00${formPhone(phone)}`;
};

export const getProps = props => name => ({
  onChange: props.onChange,
  onBlur: props.onBlur,
  name,
  ...props.fields[name],
});

export const isOfType = type => obj =>
  Object.prototype.toString
    .call(obj)
    .split(' ')[1]
    .replace(/\]$/, '') === type;

export const isObject = isOfType('Object');
export const isError = isOfType('Error');
export const isNumber = isOfType('Number');

export const isString = isOfType('String');

export const isEmpty = val => {
  if (Array.isArray(val) || isString(val)) {
    return !val.length;
  }
  if (isObject(val)) {
    return !Object.keys(val).length;
  }
  if (val == null) {
    return true;
  }
  return false;
};

export const get = (obj, props, defaultValue = undefined) => {
  if (!isObject(obj) && !isError(obj)) {
    return defaultValue;
  }
  return props.split('.').reduce((acc, prop) => {
    return !isObject(acc) && !isError(obj)
      ? defaultValue
      : acc[prop] === undefined
        ? defaultValue
        : acc[prop];
  }, obj);
};

export const Box = x => ({
  map: f => Box(f(x)),
  fold: f => f(x),
  get: () => x,
  tap: f => {
    f(x);
    return Box(x);
  },
  inspect: () => `Box(${x})`,
  toString: () => `Box(${x})`,
});

export const getValidationErrorId = field => {
  const { validators } = field;
  return !validators
    ? null
    : validators.reduce((acc, validator) => {
      const { fn, getMessageId } = validator;
      if (fn(field.value)) {
        return acc;
      }
      return acc ? acc : acc + getMessageId(field);
    }, '');
};

export const getEncryptedStr = (source, mapping) => {
  const passMap = mapping.split(',');
  let encrypted = '';
  let tempPass = source;
  let ch = '';
  let k = 0;

  while (tempPass !== '' && mapping !== '') {
    ch = tempPass.substr(0, 1);
    k = ch.charCodeAt(0);
    /* eslint-disable curly */
    if (k > 0xff) k -= 0x350;
    if (k === 7622) k = 185;
    if (k === 257) k = 184;
    if (k === 177) k = 168;
    /* eslint-enable curly */
    tempPass = tempPass.length > 1 ? tempPass.substr(1, tempPass.length) : '';
    encrypted += encrypted !== '' ? ';' : '';
    encrypted += passMap[k];
  }

  return encrypted;
};

export const buildUrlParams = params =>
  Object.keys(params)
    .reduce((acc, key) => {
      const param = params[key];
      return param == null ? acc : acc.concat(`${key}=${param}`);
    }, [])
    .join('&');

export const isProduction = () => process.env.NODE_ENV === 'production';

// NOTE: keep this function at the very end
export const getBrowserVer = () => {
  const na = navigator.userAgent;
  const MS = na.indexOf('MSIE');
  var IsOpera = window.opera != null;
  var IsFirefox = na.indexOf('Firefox') >= 0;
  var IsNetscape = na.indexOf('Netscape') >= 0;
  var isIE = MS > 0 && !IsOpera && !IsFirefox;
  var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  var IsYandex = na.indexOf('YaBrowser') >= 0;
  /* eslint-disable no-unused-vars */
  var IEVer = 0;
  /* eslint-enable no-unused-vars */
  var IsSafari = false;
  try {
    IsSafari =
      na.indexOf('Apple') >= 0 &&
      na.indexOf('Safari') >= 0 &&
      na.indexOf('Chrome') === -1 &&
      na.indexOf('CriOS') === -1;
  } catch (e) { }
  var IsMozilla = na.indexOf('Mozilla') >= 0;
  var IsChrome =
    (na.indexOf('Chrome') >= 0 || na.indexOf('CriOS') >= 0) && na.indexOf('YaBrowser') === -1;
  var IsNavigator = na.indexOf('Navigator') >= 0;

  var browser, browserVer;
  var BrowserOk =
    IsOpera || IsFirefox || IsNetscape || isIE || IsMozilla || IsSafari || IsChrome || IsNavigator;
  if (IsMozilla) {
    browser = 'Mozilla';
    browserVer = 'Unknown';
  }
  if (IsOpera && BrowserOk) {
    browser = 'Opera';
    browserVer = window.opera.version();
  }
  /* eslint-disable no-useless-escape */
  if (isIE || isIE11) {
    browser = 'IE';
    if (!isIE11) {
      var re = new RegExp('(MSIE.{0,1})(.{0,4})', 'ig');
      var r = re.exec(na);
      if (r) {
        IEVer = RegExp.$2;
      }
      browserVer = na.replace(/^.*MSIE.([\d\.]+).*$/i, '$1');
    } else {
      browserVer = '11';
    }
  }
  if (IsFirefox) {
    browser = 'FireFox';
    browserVer = na.replace(/^.*Firefox\/([\d\.]+).*$/i, '$1');
  }
  if (IsChrome) {
    browser = 'Chrome';
    if (na.indexOf('Chrome') >= 0) {
      browserVer = na.replace(/^.*Chrome\/([\d\.]+).*$/i, '$1');
    } else if (na.toUpperCase().indexOf('CRIOS') >= 0) {
      browserVer = na.replace(/^.*CRIOS\/([\d\.]+).*$/i, '$1');
    } else {
      browserVer = '';
    }
  }
  if (IsNavigator) {
    browser = 'Navigator';
    browserVer = na.replace(/^.*Navigator\/([\d\.]+).*$/i, '$1');
  }
  if (IsSafari) {
    browser = 'Safari';
    browserVer = na.replace(/^.*Version\/([\d\.]+).*$/i, '$1');
  }
  if (IsNetscape) {
    browser = 'Netscape';
    browserVer = na.replace(/^.*Netscape\/([\d\.]+).*$/i, '$1');
  }
  if (IsYandex) {
    browser = 'Yandex';
    browserVer = na.replace(/^.*YaBrowser\/([\d\.]+).*$/i, '$1');
  }
  /* eslint-enable no-useless-escape */

  return {
    browser,
    browserVersion: browserVer,
  };
};

export const calculateWidth = (message, fontSize = 20) => {
  var test = document.getElementById('text-measure');
  test.innerHTML = message;
  test.style.fontSize = fontSize + 'px';
  return test.clientWidth;
};

export const replaceAll = (str, find, replacement) => {
  return str.replace(new RegExp(find, 'g'), replacement);
};

export const setCreditImage = type => {
  const types = {
    CONSUMER_LOANS: moneyImg,
    CAR_LOANS: carImg,
    MORTGAGE: houseImg,
  };

  const imgUrl = types[type] || moneyImg;
  return imgUrl ? { backgroundImage: `url('${imgUrl}')` } : {};
};

export const formatPhone = phone => {
  return !phone ? '' : phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
};

export const formatShortPhone = phone => {
  return phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4');
};

export const removeTags = str => (str ? str.replace(/<(?:.|\n)*?>/gm, '') : '');

export const statusMaster = new (function StatusMaster() {
  const statuses = {
    inProgress: 'inProgress',
    success: 'success',
    failure: 'error',
  };

  this.checkNoFailureStatus = status => status !== statuses.failure;
  this.checkNoProgressStatus = status => status !== statuses.inProgress;

  this.checkinProgress = status => status === statuses.inProgress;
  this.checkSuccess = status => status === statuses.success;
  this.checkFailure = status => status === statuses.failure;

  this.getInProgress = () => statuses.inProgress;
  this.getSuccess = () => statuses.success;
  this.getFailure = () => statuses.failure;
})();

export const getLocalizedControlQuestions = ({ question, locale = 'ru' }) => {
  if (typeof question === 'string') {
    if (locale === 'ru') {
      return question;
    }
    switch (question) {
      case 'Кличка домашнего питомца':
        return 'Pet name';
      case 'Любимый фильм или мультфильм':
        return 'Favorite movie or cartoon';
      case 'Любимая книга':
        return 'Favorite book';
      case 'В какой школе вы учились?':
        return 'What school did you go to?';
      case 'В какой школе вы учились':
        return 'What school did you go to?';
      case 'Имя лучшего друга':
        return "Best friend's name";
      case 'Имя первого учителя':
        return "First teacher's name";
      case 'Любимый актер или актриса':
        return 'Favorite actor or actress';
      case 'Девичья фамилия матери':
        return "Mother's maiden name";
      case 'Почтовый индекс родителей':
        return "Parents' zip code";
      case 'Любимая музыкальная группа':
        return 'Favorite music band';
      case 'Тестовый вопрос (ответ - да)':
        return 'Test question (answer is yes)';
      default:
        return question;
    }
  } else if (!question) {
    return locale !== 'en'
      ? [
        { label: 'Кличка домашнего питомца', value: '1' },
        { label: 'Любимый фильм или мультфильм', value: '2' },
        { label: 'Любимая книга', value: '3' },
        { label: 'В какой школе вы учились', value: '4' },
        { label: 'Имя лучшего друга', value: '5' },
        { label: 'Имя первого учителя', value: '6' },
        { label: 'Любимый актер или актриса', value: '7' },
        { label: 'Девичья фамилия матери', value: '8' },
        { label: 'Почтовый индекс родителей', value: '9' },
        { label: 'Любимая музыкальная группа', value: '10' },
      ]
      : [
        { label: 'Pet name', value: '1' },
        { label: 'Favorite movie or cartoon', value: '2' },
        { label: 'Favorite book', value: '3' },
        { label: 'What school did you go to?', value: '4' },
        { label: "Best friend's name", value: '5' },
        { label: "First teacher's name", value: '6' },
        { label: 'Favorite actor or actress', value: '7' },
        { label: "Mother's maiden name", value: '8' },
        { label: "Parents' zip code", value: '9' },
        { label: 'Favorite music band', value: '10' },
      ];
  }
};

export const transliterate = text => {
  if (typeof text !== 'string' || !text) {
    return '';
  }
  text = text
    .replace(/\u0401/g, 'YO')
    .replace(/\u0419/g, 'I')
    .replace(/\u0426/g, 'TS')
    .replace(/\u0423/g, 'U')
    .replace(/\u041A/g, 'K')
    .replace(/\u0415/g, 'E')
    .replace(/\u041D/g, 'N')
    .replace(/\u0413/g, 'G')
    .replace(/\u0428/g, 'SH')
    .replace(/\u0429/g, 'SCH')
    .replace(/\u0417/g, 'Z')
    .replace(/\u0425/g, 'H')
    .replace(/\u042A/g, '')
    .replace(/\u0451/g, 'yo')
    .replace(/\u0439/g, 'i')
    .replace(/\u0446/g, 'ts')
    .replace(/\u0443/g, 'u')
    .replace(/\u043A/g, 'k')
    .replace(/\u0435/g, 'e')
    .replace(/\u043D/g, 'n')
    .replace(/\u0433/g, 'g')
    .replace(/\u0448/g, 'sh')
    .replace(/\u0449/g, 'sch')
    .replace(/\u0437/g, 'z')
    .replace(/\u0445/g, 'h')
    .replace(/\u044A/g, "'")
    .replace(/\u0424/g, 'F')
    .replace(/\u042B/g, 'I')
    .replace(/\u0412/g, 'V')
    .replace(/\u0410/g, 'А')
    .replace(/\u041F/g, 'P')
    .replace(/\u0420/g, 'R')
    .replace(/\u041E/g, 'O')
    .replace(/\u041B/g, 'L')
    .replace(/\u0414/g, 'D')
    .replace(/\u0416/g, 'ZH')
    .replace(/\u042D/g, 'E')
    .replace(/\u0444/g, 'f')
    .replace(/\u044B/g, 'i')
    .replace(/\u0432/g, 'v')
    .replace(/\u0430/g, 'a')
    .replace(/\u043F/g, 'p')
    .replace(/\u0440/g, 'r')
    .replace(/\u043E/g, 'o')
    .replace(/\u043B/g, 'l')
    .replace(/\u0434/g, 'd')
    .replace(/\u0436/g, 'zh')
    .replace(/\u044D/g, 'e')
    .replace(/\u042F/g, 'YA')
    .replace(/\u0427/g, 'CH')
    .replace(/\u0421/g, 'S')
    .replace(/\u041C/g, 'M')
    .replace(/\u0418/g, 'I')
    .replace(/\u0422/g, 'T')
    .replace(/\u042C/g, "'")
    .replace(/\u0411/g, 'B')
    .replace(/\u042E/g, 'YU')
    .replace(/\u044F/g, 'ya')
    .replace(/\u0447/g, 'ch')
    .replace(/\u0441/g, 's')
    .replace(/\u043C/g, 'm')
    .replace(/\u0438/g, 'i')
    .replace(/\u0442/g, 't')
    .replace(/\u044C/g, "'")
    .replace(/\u0431/g, 'b')
    .replace(/\u044E/g, 'yu');

  return text;
};

export const getErrorMessage = (err, defaultText = '') => {
  const errMessage = isObject(err) || isError(err) ? err.message : err;
  return typeof errMessage === 'string' && errMessage.length > 0 ? errMessage : defaultText;
};

export const isCreditHolidays = ({ name, value }) => name === 'LoanHolidays' && value === 'Y';

export const isFullPayment = (sum, principal) => parseFloat(sum) >= parseFloat(principal);
