import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router';
import { withErrorBoundary } from 'shared';
import { cleanAntifraudUfmBlock } from 'actions/auth/index';
import UfmBlockModal from "./UfmBlockModal"


class UfmBlockModalWrapper extends Component {
 
  render() {
    return (
      <>
        {this.props.antifraudUfmBlock.blocked ? <UfmBlockModal onClose={this.props.cleanAntifraudUfmBlock} message={this.props.antifraudUfmBlock.message} /> : null}
      </>
    )
  }
}
UfmBlockModalWrapper.propTypes = {
  antifraudUfmBlock: PropTypes.object,
  cleanAntifraudUfmBlock:PropTypes.func
};

const mapState = ({  auth:{antifraudUfmBlock} }) => ({
  antifraudUfmBlock,
});

const mapDispatch = {
  cleanAntifraudUfmBlock
};

export default connect(
  mapState,
  mapDispatch
)(withRouter(withErrorBoundary(UfmBlockModalWrapper, 'UfmBlockModalWrapper')));
