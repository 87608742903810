import React, { Component } from 'react';
import { getActiveLanguage, Translate, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';

import { labelMaker, formatNumber, getLocaleDateFormat } from 'shared/utils';
import { Translation, Process, SmoothDropDown, WithPopup } from 'shared';
import {
  deleteApplication,
  downloadPrepay,
  printPrepay,
  sendPdfFileToEmail,
} from 'actions/products';
import { accountsSelector } from 'store/selectors/accounts';
import loaderBlue from 'assets/icons/loader-blue.png';

import styles from './Payment.module.scss';

const label = labelMaker('shared.paymentList');
const statusesLabel = labelMaker('shared.process');

class Payment extends Component {
  state = {
    isOpen: false,
  };

  onDelete = () => {
    const { deleteApplication, payment, withdrawApplication } = this.props;
    const { id } = payment;

    if (withdrawApplication) {
      withdrawApplication(id);
    } else {
      deleteApplication(id);
    }
  };

  onSave = id => () => {
    this.props.onSave(id);
  };

  onPrint = id => () => {
    this.props.onPrint(id);
  };

  onSend = id => () => {
    this.props.onSend({ section: 'prepayApplication', id });
  };

  togglePayment = () => {
    const { canDropDown } = this.props;
    const { isOpen } = this.state;

    if (canDropDown) {
      this.setState({
        isOpen: !isOpen,
      });
    }
  };

  formatPrepayDate = () => {
    const { locale, payment } = this.props;
    const { date } = payment.repayment;
    const dateFormat = getLocaleDateFormat(locale);

    return moment(date).format(dateFormat);
  };

  formatSignDate = () => {
    const { locale, payment } = this.props;
    const { signdate } = payment;

    const dateFormat = getLocaleDateFormat(locale);

    return moment(signdate).format(dateFormat);
  };

  isPaymentRejected = payment => {
    return payment.status.toLowerCase() === 'rjt';
  };
  isPaymentNew = payment => {
    return payment.status.toLowerCase() === 'new';
  };

  renderAction = () => {
    const { payment } = this.props;

    if (this.isPaymentNew(payment)) {
      return payment.isStatusLoading ? (
        <img className={styles.Rotating} src={loaderBlue} alt="" />
      ) : (
        <div className={styles.Action} onClick={this.onDelete}>
          <div className={styles.ActionIcon} />
          <Translate id={label('withdrawBtnCaps')} />
        </div>
      );
    }
  };

  render() {
    const { payment, translate, products, accounts } = this.props;
    const { isOpen } = this.state;
    const { Td } = Translation;
    const {
      currency,
      status,
      statusDesc,
      repayment: { amount },
      operationType,
      isStatusLoading,
      signdate,
    } = payment;

    const paymentName =
      operationType === 'PartRepayment'
        ? translate(label('partRepayment'))
        : translate(label('fullRepayment'));
    const paymentType =
      operationType === 'PartRepayment'
        ? translate(label('partRepayment'))
        : translate(label('fullRepayment'));

    const paymentStatus = status ? status.toLowerCase() : null;
    const popupDescription = this.isPaymentRejected(payment) ? statusDesc : null;

    const currentAccount =
      accounts && accounts.find(acc => acc.accountNo === products.credit.drsettlementacc);
    const defaultAccount = accounts && accounts[0];

    const bankAccountNumber =
      (currentAccount && currentAccount.cbAccountNo) ||
      (defaultAccount && defaultAccount.cbAccountNo) ||
      '';
    return (
      <div className={styles.Container} onClick={this.toggleApplication}>
        <div
          className={isOpen ? styles.PaymentOpen : styles.PaymentClose}
          onClick={this.togglePayment}
        >
          <div className={styles.InfoContainer}>
            <div className={styles.Mask}>
              <div
                className={cn(styles.Image, { [styles.MirrorHorizontal]: parseFloat(amount) < 0 })}
              />
            </div>
            <div className={styles.Info}>
              <span className={styles.Title}>{paymentName}</span>
              <span className={styles.Name}>{bankAccountNumber}</span>
              <span className={styles.Date}>{this.formatPrepayDate()}</span>
            </div>
          </div>
          <div className={styles.SumAndStatus}>
            <div className={styles.Sum}>{formatNumber(Math.abs(amount), { currency })}</div>
            <div className={styles.Status}>
              {paymentStatus &&
                (popupDescription ? (
                  <WithPopup description={popupDescription}>
                    <Process status={paymentStatus} />
                  </WithPopup>
                ) : (
                  <Process status={paymentStatus} />
                ))}
            </div>
          </div>
        </div>
        <SmoothDropDown isOpen={isOpen}>
          <table className={styles.DescriptionTable}>
            <tbody>
              <tr className={styles.Tr}>
                <Td className={styles.TdInfo} translateId={label('status')} />
                {isStatusLoading ? (
                  <td>
                    <img className={styles.Rotating} src={loaderBlue} alt="" />
                  </td>
                ) : (
                  <Td className={styles.TdResult} translateId={statusesLabel(paymentStatus)} />
                )}
              </tr>
              <tr className={styles.Tr}>
                <Td className={styles.TdInfo} translateId={label('operationType')} />
                <td className={styles.TdResult}>{paymentType}</td>
              </tr>
              <tr className={styles.Tr}>
                <Td className={styles.TdInfo} translateId={label('date')} />
                <td className={styles.TdResult}>{this.formatPrepayDate()}</td>
              </tr>
              {signdate && (
                <tr className={styles.Tr}>
                  <Td className={styles.TdInfo} translateId={label('createDate')} />
                  <td className={styles.TdResult}>{this.formatSignDate()}</td>
                </tr>
              )}
              <tr className={styles.Tr}>
                <Td className={styles.TdInfo} translateId={label('debitAccountNum')} />
                <td className={styles.TdResult}>{bankAccountNumber}</td>
              </tr>
              <tr className={styles.Tr}>
                <Td className={styles.TdInfo} translateId={label('earlyPaymentAmount')} />
                <td className={styles.TdResult}>{formatNumber(Math.abs(amount), { currency })}</td>
              </tr>
              {this.isPaymentRejected(payment) && (
                <tr className={styles.Tr}>
                  <Td className={styles.TdInfo} translateId={label('statusInfo')} />
                  <td className={styles.TdResult}>{statusDesc}</td>
                </tr>
              )}
              <tr className={styles.Tr}>
                <td className={styles.Td}>{this.renderAction()}</td>
                <td className={styles.TdResult}>
                  <div className={styles.Acts}>
                    <div
                      className={cn([styles.Act, styles.ActDocument])}
                      onClick={this.onSave(payment.id)}
                    />
                    <div
                      className={cn([styles.Act, styles.ActMail])}
                      onClick={this.onSend(payment.id)}
                    />
                    <div
                      className={cn([styles.Act, styles.ActPrint])}
                      onClick={this.onPrint(payment.id)}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </SmoothDropDown>
      </div>
    );
  }
}

Payment.propTypes = {
  onSave: PropTypes.func,
  onPrint: PropTypes.func,
  onSend: PropTypes.func.isRequired,
  withdrawApplication: PropTypes.func,
  deleteApplication: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  canDropDown: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  payment: PropTypes.object,
  products: PropTypes.object.isRequired,
  accounts: PropTypes.array,
};

Payment.defaultProps = {
  canDropDown: true,
};

const mapStateToProps = state => ({
  locale: getActiveLanguage(state.localize).code,
  translate: getTranslate(state.localize),
  products: state.products,
  accounts: accountsSelector(state),
});

const mapDispatchToProps = {
  deleteApplication,
  onSave: downloadPrepay,
  onPrint: printPrepay,
  onSend: sendPdfFileToEmail,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Payment);
