import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import styles from './Input.module.scss';

import { calculateWidth, isActiveButtonCode, isNumericCode } from 'shared/utils';

class Input extends Component {
  state = {
    showStatus: false,
    hasFocus: false,
  };

  onFocus = () => this.setState({ hasFocus: true, showStatus: false });

  onBlur = ({ target }) => {
    const { onBlur, onChange } = this.props;
    const { name, value } = target;

    if (onBlur) {
      this.setState({
        showStatus: true,
        hasFocus: false,
      });
      onBlur({ name, value }, true);
    } else {
      this.setState({
        showStatus: true,
        hasFocus: false,
      });
      onChange({ name, value }, true);
    }
  };

  onKeyDown = e => {
    if (this.props.onlyNumbers) {
      const { keyCode: code, shiftKey } = e;
      if ((!isActiveButtonCode(code) && !isNumericCode(code)) || shiftKey) {
        e.preventDefault();
      }
    }
  };

  onChange = ({ target }, isError) => {
    const { onChange } = this.props;
    const { name, value } = target;

    onChange({ value, name }, isError);
  };

  render() {
    const { actionId, disabled, name, translate, type, value } = this.props;

    const standardRightPadding = 16;
    const rightPadding = actionId
      ? calculateWidth(translate(actionId)) + standardRightPadding
      : standardRightPadding;

    return (
      <div className={styles.Container}>
        <input
          autoComplete="off"
          className={styles.Input}
          disabled={disabled}
          name={name}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onKeyDown={this.onKeyDown}
          style={{
            paddingRight: rightPadding,
          }}
          type={type}
          value={value}
        />
      </div>
    );
  }
}

Input.propTypes = {
  actionId: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  errorId: PropTypes.string,
  handleAction: PropTypes.func,
  isError: PropTypes.bool,
  labelId: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  statusElement: PropTypes.bool,
  touched: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  type: PropTypes.string,
  value: PropTypes.any,
  onlyNumbers: PropTypes.bool,
};

Input.defaultProps = {
  handleAction: () => {},
  onChange: () => {},
  type: 'text',
  value: '',
  onlyNumbers: false,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(Input);
