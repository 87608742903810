import axios from 'axios';

import { buildUrlParams, encodeDataURI } from 'shared/utils';
import apiServicesConstructor from 'services/apiService';

import { dadataApiKeyReqStub } from './dadataStubs';

const isDevelopment = process.env.NODE_ENV === 'development';
const useGlobalStubs = process.env.REACT_APP_USE_STUBS === 'true';

export const yMapsObjectsReq = ({ text, lang = 'ru' }) => (dispatch, getState) => {
  const url = 'https://geocode-maps.yandex.ru/1.x/';

  const urlParams = buildUrlParams(
    encodeDataURI({
      apikey: '267c1e0a-658c-4bdc-b9a5-639545aac308',
      format: 'json',
      geocode: text,
      lang: `${lang}_RU`,
    })
  );

  return axios.get(`${url}?${urlParams}`, {
    transformRequest: [
      (data, headers) => {
        // Need caching for yandex map requests
        delete headers.common['Pragma'];
        delete headers.common['Cache-Control'];
        return data;
      },
    ],
  });
};

/** chat initialize args
 * secret
 * token
 */

export const addressCompletionReq = query => (dispatch, getState) => {
  // const { secret, token, address } = args;
  const token = '35bae7d9c1d6e6ab711fdfa9ca2a95bdc7f51042';

  const params = {
    url: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    },
    data: {
      query,
    },
    withCredentials: false,
  };

  const apiServices = apiServicesConstructor(dispatch, getState);

  return apiServices
    .post(params)
    .then(data => {
      return {
        data,
      };
    })
    .catch(err => {
      return {
        err,
      };
    });
};

export const dadataApiKeyReq = (useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub && isDevelopment) || useGlobalStubs) {
    return await dadataApiKeyReqStub(withError);
  }

  const reqParams = {
    url: '/webapi-1.0/keys/dadata',
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};
