import React from 'react';
import { PropTypes } from 'prop-types';
import { Translation, SmoothDropDown } from 'shared';
import { labelMaker } from 'shared/utils';
import cn from 'classnames';
import styles from './HiddenCreditsDropdown.module.scss';
const label = labelMaker('products.page');

const HiddenCreditsDropdown = ({ toggleHiddenCredits, isHiddenDisplayed, hiddenCredits }) => {
  const { Span } = Translation;
  return (
    <>
      <div className={styles.ToggleLink} onClick={toggleHiddenCredits}>
        <Span
          translateId={isHiddenDisplayed ? label('doNotDisplayHidden') : label('displayHidden')}
        />
        <span
          className={cn(styles.ToggleArrow, {
            [styles.ToggleArrowOpen]: isHiddenDisplayed,
          })}
        />
      </div>
      <SmoothDropDown
        className={cn(styles.DropdownWrapper, { [styles.DropdownWrapperOpen]: true })}
        isOpen={isHiddenDisplayed}
      >
        {hiddenCredits}
      </SmoothDropDown>
    </>
  );
};

HiddenCreditsDropdown.propTypes = {
  toggleHiddenCredits: PropTypes.func.isRequired,
  isHiddenDisplayed: PropTypes.bool.isRequired,
  hiddenCredits: PropTypes.node,
};

export default HiddenCreditsDropdown;
