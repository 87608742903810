import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { Translate, getTranslate } from 'react-localize-redux';
import cn from 'classnames';

import { setPaymentAmount } from 'actions/newPayment/index';
import { goTo } from 'actions/routes';
import { changeLoanName } from 'actions/products';

import { ProgressBar, Button, InfoComponent, withErrorBoundary } from 'shared';
import { formatNumber, labelMaker, setCreditImage } from 'shared/utils';

import editIcon from 'assets/icons/edit-icon.png';
import disabledEditIcon from 'assets/icons/disabled-edit-icon.png';

import styles from './CreditCard.module.scss';

const label = labelMaker('shared.creditListItem');

const formatToLocale = (date, outTemp) => {
  return moment(date)
    .format(outTemp)
    .toLocaleString();
};

class CreditCard extends Component {
  state = {
    isNameEditing: false,
    name: this.props.credit.name,
  };
  nextLabelDateFormat = 'D MMMM, YYYY';

  onNameChange = e => {
    if (e.target.value && e.target.value.length > 80) {
      return this.setState({
        name: e.target.value.substr(0, 80),
      });
    }
    this.setState({
      name: e.target.value,
    });
  };

  toggleChangeNameModal = () => {
    this.setState({
      isNameEditing: false,
    });
  };

  handleEditBtn = credit => e => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    if (this.state.isNameEditing) {
      this.props.changeLoanName(credit.contractRef, this.state.name).then(() => {
        this.setState({
          isNameEditing: false,
        });
      });
    } else {
      this.setState({
        isNameEditing: true,
      });
    }
  };

  preventEventPropagation = event => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  preventTransition = event => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  onChooseCredit = credit => () => {
    this.props.goTo(`/products/loans/${credit.contractRef}/account`);
  };

  onPayment = (credit, amount) => e => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    setPaymentAmount(parseFloat(amount));
    this.props.goTo(`/products/loans/${credit.contractRef}/payments`);
  };

  renderName = credit => {
    if (this.state.isNameEditing) {
      return (
        <input
          value={this.state.name}
          onChange={this.onNameChange}
          disabled={credit.isNameEditing}
        />
      );
    }
    return <Fragment>{credit.name}</Fragment>;
  };

  renderOutstandingSum = credit => {
    const lastPayment =
      Array.isArray(credit.payments) && credit.payments.length
        ? credit.payments[credit.payments.length - 1]
        : null;

    if (lastPayment) {
      const payedAmount =
        parseFloat(lastPayment.originalPrincipal) > parseFloat(lastPayment.currentOutstanding)
          ? parseFloat(lastPayment.originalPrincipal) - parseFloat(lastPayment.currentOutstanding)
          : 0;

      const repaidPercent = (payedAmount * 100) / parseFloat(lastPayment.originalPrincipal);

      return (
        <div className={styles.RestContainer}>
          <div className={styles.Rest}>
            {formatNumber(credit.principal, { currency: credit.ccy })}
            &nbsp;
            <Translate id={label('outstanding')} />
            <div className={styles.CreditInfo}>
              <InfoComponent translateId={label('help')} />
            </div>
          </div>
          <ProgressBar className={styles.Progress} progress={repaidPercent} />
        </div>
      );
    }
    return null;
  };

  renderNextPayment = credit => {
    const { ccy, repayAmount, repayInterest, nextPaymentDate, pastdue } = credit;
    const { translate } = this.props;

    const pastDueDays = pastdue && pastdue[0] && parseFloat(pastdue[0].numOfDaysPastDueAmount);

    const minPayment = parseFloat(repayAmount) + parseFloat(repayInterest);

    const dateStr = pastDueDays
      ? translate(label('pastDueDays'), {
          date: formatToLocale(moment().subtract(pastDueDays, 'days'), this.nextLabelDateFormat),
        })
      : translate(label('until'), {
          date: formatToLocale(nextPaymentDate, this.nextLabelDateFormat),
        });

    return (
      <Fragment>
        <div className={styles.MinPaymentLabel}>{`${translate(label('minPayment'))} ${formatNumber(
          minPayment,
          {
            currency: ccy,
          }
        )}`}</div>
        <div className={pastDueDays && styles.PastDueDays}>{dateStr}</div>
      </Fragment>
    );
  };

  render() {
    const { credit, translate } = this.props;
    const { productCategory, repayAmount, repayInterest } = credit;
    const creditImageStyle = setCreditImage(productCategory);

    const minPayment = parseFloat(repayAmount) + parseFloat(repayInterest);

    return (
      <div
        className={cn(styles.Container, styles.ClickableContainer)}
        onClick={this.onChooseCredit(credit)}
      >
        <div className={styles.InfoContainer}>
          <div style={creditImageStyle} className={styles.Image} />
          <div className={styles.Info}>
            <div className={styles.NameContainer}>
              <div
                className={styles.Name}
                onClick={this.state.isNameEditing ? this.preventTransition : null}
              >
                {this.renderName(credit)}
              </div>
              <img
                className={styles.NameAction}
                onClick={
                  credit.isNameEditing ? this.preventEventPropagation : this.handleEditBtn(credit)
                }
                src={credit.isNameEditing ? disabledEditIcon : editIcon}
                alt=""
              />
            </div>
            {this.renderOutstandingSum(credit)}
          </div>
        </div>
        <div className={styles.PaymentContainer}>
          <div className={styles.Action}>
            <div className={styles.ActionContainer}>
              <Button
                round
                className={styles.MinPaymentBtn}
                onClick={this.onPayment(credit, minPayment)}
              >
                <div className={styles.ButtonContentWrapper}>
                  {translate(label('depositBtn'))}
                  &nbsp;
                  <span className={styles.ButtonMinPaymentSum}>
                    {formatNumber(minPayment, {
                      currency: credit.ccy,
                    })}
                  </span>
                </div>
              </Button>
            </div>
            <div className={styles.PaymentInfo}>{this.renderNextPayment(credit)}</div>
          </div>
          <div className={styles.TransitionIconWrapper}>
            <div className={styles.TransitionIcon} />
          </div>
        </div>
      </div>
    );
  }
}

CreditCard.propTypes = {
  credit: PropTypes.object,
  changeLoanName: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  setPaymentAmount: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapState = ({ localize }) => ({
  translate: getTranslate(localize),
});

const mapDispatch = {
  goTo,
  setPaymentAmount,
  changeLoanName,
};

export default connect(
  mapState,
  mapDispatch
)(withErrorBoundary(CreditCard, 'Products/CreditCard'));
