import { ReduxInput, ReduxNumericInput, ReduxPhoneInput1, ReduxSelect } from 'shared';
import { phoneFormatter, phoneNormalizer } from 'shared/normalizers';
import { reduxFieldPhone1, reduxFieldRequired } from 'shared/validators';

import { labelMaker } from 'shared/utils';

const formLabel = labelMaker('transfers.labels');

export const options = {
  senderPhone: {
    component: ReduxPhoneInput1,
    labelId: formLabel('senderPhone'),
    name: 'senderPhone',
    normalize: phoneNormalizer,
    format: phoneFormatter,
    validate: [reduxFieldRequired, reduxFieldPhone1],
  },
  phone: {
    component: ReduxPhoneInput1,
    labelId: formLabel('phone'),
    name: 'phone',
    normalize: phoneNormalizer,
    format: phoneFormatter,
    // validate: [reduxFieldRequired, reduxFieldPhone1],
  },
  cards: {
    component: ReduxSelect,
    name: 'cards',
    options: [],
    validate: [reduxFieldRequired],
  },
  beneficiaryBank: {
    component: ReduxSelect,
    name: 'beneficiaryBank',
    validate: [reduxFieldRequired],
  },
  payment: {
    component: ReduxNumericInput,
    name: 'payment',
    normalize: value => {
      value = `${value}`.replace(/[^0-9.]/g, ''); // Remove all chars except numbers and .

      const sections = value.split('.');

      if (sections[0] !== '0' && sections[0] !== '00') {
        sections[0] = sections[0].replace(/^0+/, '');
      } else {
        sections[0] = '0';
      }

      if (sections[1]) {
        return sections[0] + '.' + sections[1].slice(0, 2);
      } else if (value.indexOf('.') !== -1) {
        return sections[0] + '.';
      } else {
        return sections[0];
      }
    },
    format: value => {
      return `${value}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
    },
    validate: [reduxFieldRequired],
  },
  purpose: {
    component: ReduxInput,
    labelId: formLabel('purpose'),
    name: 'purpose',
    validate: [reduxFieldRequired],
  },
};

export default options;
