import { createReducer } from 'redux-act';

import { fetchAccountsStart, fetchAccountsSuccess, fetchAccountsFailure } from 'actions/accounts';

const defaultState = {
  data: null,
  isLoading: false,
};

const accounts = createReducer(
  {
    [fetchAccountsStart]: state => {
      return {
        ...state,

        isLoading: true,
      };
    },
    [fetchAccountsSuccess]: (state, accounts) => {
      return {
        ...state,

        data: accounts,
        isLoading: false,
      };
    },
    [fetchAccountsFailure]: state => {
      return {
        ...state,

        isLoading: false,
      };
    },
  },
  defaultState
);

export default accounts;
