import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

import Spinner from '../../Spinner/Spinner';

import styles from './ReduxYMapsLocationInput.module.scss';

import { get } from 'shared/utils';

class Menu extends Component {
  handleClickOutside = () => {
    const { onClickOutside } = this.props;

    onClickOutside();
  };

  renderOption = (option, index) => {
    const { selectOption } = this.props;

    const optionName = get(option, 'GeoObject.name');
    const optionSuggestionName = get(option, 'GeoObject.metaDataProperty.GeocoderMetaData.text');
    const optionCoords = get(option, 'GeoObject.Point.pos')
      .split(' ')
      .reverse();

    //find corners coordinates map
    const lowerCoordinates = get(option, 'GeoObject.boundedBy.Envelope.lowerCorner').split(' ');
    const upperCoordinates = get(option, 'GeoObject.boundedBy.Envelope.upperCorner').split(' ');

    const cornersCoordinates = [
      [lowerCoordinates[1], lowerCoordinates[0]],
      [upperCoordinates[1], upperCoordinates[0]],
    ];
    return (
      <div
        className={styles.Option}
        onClick={selectOption({
          name: optionName,
          coords: optionCoords,
          corners: cornersCoordinates,
        })}
        key={index}
      >
        {optionSuggestionName}
      </div>
    );
  };

  render() {
    const { locations, isOpen, isLoading } = this.props;

    if (Array.isArray(locations) && isOpen) {
      return (
        <div className={styles.Menu}>
          {isLoading ? <Spinner className={styles.Spinner} /> : locations.map(this.renderOption)}
        </div>
      );
    }

    return null;
  }
}

Menu.propTypes = {
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  locations: PropTypes.array,
  onClickOutside: PropTypes.func.isRequired,
  selectOption: PropTypes.func.isRequired,
};

export default onClickOutside(Menu);
