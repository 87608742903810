import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getTranslate } from 'react-localize-redux';

import styles from './Select.module.scss';

const CustomSelect = props => {
  const customStyles = {
    menu: (base, state) => ({
      ...base,
      marginTop: 2,
    }),
    container: (base, state) => {
      return {
        ...base,
        backgroundColor: 'red',
      };
    },
    singleValue: (base, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 200ms';

      return { ...base, opacity, transition };
    },
  };

  const onChange = option => {
    props.onChange({ ...option });
  };

  const { placeholder, name, disabled, value, translate } = props;

  const options = props.options.map(option => ({
    label: option.labelId ? translate(option.labelId) : option.label,
    value: option.value,
  }));

  /* eslint-disable react/prop-types*/
  return (
    <Select
      classNamePrefix={styles.Select}
      dropDownIndicator={() => <div className={styles.Dropdown} />}
      styles={customStyles}
      isDisabled={disabled}
      placeholder={placeholder}
      onChange={({ value }) => onChange({ value, name }, onChange)}
      value={{ ...options.filter(option => option.value === value)[0] }}
      components={{
        Option: ({ children, innerProps }) => {
          delete innerProps.innerRef;
          return (
            <div className={styles.Option} {...innerProps}>
              <div className={styles.Children}>
                {children}
                {innerProps['aria-selected'] ? <div className={styles.Status} /> : null}
              </div>
            </div>
          );
        },
      }}
      options={
        options
          ? options.map(option => ({
              ...option,
              className: `${styles.Option}`,
            }))
          : []
      }
      className={`${styles.Select} ${
        !props.disabled ? styles.SelectActive : styles.SelectDisabled
      }`}
    />
  );
  /* eslint-disable react/prop-types*/
};

CustomSelect.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(CustomSelect);
