/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { labelMaker, getLocaleDateFormat } from 'shared/utils';
import { Translation } from 'shared';

import { ReactComponent as PaymentImage } from 'assets/icons/icon-operation-document.svg';
import styles from './HistoryList.module.scss';

import HistoryListItem from '../HistoryListItem';
const lPaymentList = labelMaker('shared.paymentList');

const HistoryList = props => {
  const {
    locale,
    location,
    operations,
    operationDetails,
    operationsTotal,
    operationsLimit,
    fetchHistoryList,
    fetchHistoryDetails,
    dateRange,
    isLoading,
    detailsLoading,
    detailsError,
    periodType,
    typeParams,
    catalog,
    loadingDetailId,
    fetchMaskedCardNumber,
  } = props;

  const dateFormat = getLocaleDateFormat(locale);

  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setHasMore(true);
  }, [location.search]);

  const loadMore = page => {
    if (isLoading) {
      return;
    }
    if (operations.length >= operationsTotal) {
      setHasMore(false);
    } else {
      const dateParams = {
        dateFrom: dateRange.startDate,
        dateTo: dateRange.endDate,
      };
      fetchHistoryList({ ...dateParams, startAt: operations.length, limit: operationsLimit, ...typeParams });
    }
  };

  return operations.length ? (
    <InfiniteScroll className={styles.List} pageStart={0} loadMore={loadMore} hasMore={hasMore}>
      {operations.map(item => (
        <HistoryListItem
          key={item.id}
          {...{
            item,
            dateFormat,
            id: item.id,
            type: item.type,
            externalId: item.productExternalId,
            date: item.date,
            currency: item.currency,
            amount: item.amount,
            details: operationDetails[item.id],
            fetchHistoryDetails,
            detailsLoading: detailsLoading && loadingDetailId === item.id,
            detailsError,
            periodType,
            catalog,
            locale: locale[0].toUpperCase() + locale.slice(1),
            fetchMaskedCardNumber,
          }}
        />
      ))}
    </InfiniteScroll>
  ) : (
    <div className={styles.EmptyTransitionsBlock}>
      <div className={styles.EmptyMask}>
        <PaymentImage className={styles.PaymentImage} />
      </div>
      <Translation.Span
        className={styles.EmptyInfo}
        translateId={lPaymentList('emptyOperationsHistory')}
      />
    </div>
  );
};

HistoryList.propTypes = {
  locale: PropTypes.string,
  location: PropTypes.object,
  operations: PropTypes.array,
  operationDetails: PropTypes.object,
  operationsTotal: PropTypes.number,
  operationsLimit: PropTypes.number,
  fetchHistoryList: PropTypes.func.isRequired,
  fetchHistoryDetails: PropTypes.func.isRequired,
  dateRange: PropTypes.object,
  isLoading: PropTypes.bool,
  detailsLoading: PropTypes.bool,
  detailsError: PropTypes.bool,
};

export default HistoryList;
