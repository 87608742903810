import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { Translation, InputStatus, InputError, InputAction } from '../../';

import styles from '../Input/Input.module.scss';

import { isEnterCode, calculateWidth } from 'shared/utils';

class SubmitInput extends Component {
  state = {
    showError: false,
    hasFocus: false,
  };

  onFocus = () => this.setState({ hasFocus: true, showStatus: false });

  onBlur = e => {
    this.setState({
      showStatus: true,
      hasFocus: false,
    });
    this.onChange(e, true);
  };

  onChange = ({ target }, isError) => {
    const { onChange } = this.props;
    const { name, value } = target;

    onChange({ value, name }, isError);
  };

  onKeyDown = e => {
    const { keyCode: code } = e;
    const { disabled, onSubmit } = this.props;

    if (isEnterCode(code) && !disabled) {
      onSubmit();
    }
  };

  render() {
    const {
      actionId,
      disabled,
      errorId,
      handleAction,
      isError,
      labelId,
      name,
      statusElement,
      touched,
      translate,
      type,
      value,
    } = this.props;
    const { hasFocus, showStatus } = this.state;

    const isErrorVisible = touched && errorId && !disabled && isError;
    const isInputFocused = hasFocus && !disabled;
    const isStatusSuccess = statusElement && touched && !errorId && showStatus;

    const error = isErrorVisible ? errorId : null;

    const externalBorderClassName = cn(styles.ExternalBorder, {
      [styles.DisabledExternalBorder]: disabled,
      [styles.FocusedExternalBorder]: isInputFocused,
      [styles.ErrorExternalBorder]: isErrorVisible,
    });
    const internalBorderClassName = cn(styles.InternalBorder, {
      [styles.FocusedInternalBorder]: isInputFocused,
      [styles.ErrorInternalBorder]: isErrorVisible,
    });
    const labelClassName = cn(
      styles.Label,
      { [styles.FocusedLabel]: hasFocus || value },
      { [styles.DisabledLabel]: disabled }
    );

    const standardRightPadding = 16;
    const rightPadding = actionId
      ? calculateWidth(translate(actionId)) + standardRightPadding
      : standardRightPadding;

    return (
      <div className={styles.Container}>
        <Translation.Div className={labelClassName} translateId={labelId} />
        <div className={externalBorderClassName}>
          <div className={internalBorderClassName}>
            <input
              onKeyDown={this.onKeyDown}
              autoComplete="off"
              className={styles.Input}
              disabled={disabled}
              name={name}
              onChange={this.onChange}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              style={{
                paddingRight: rightPadding,
              }}
              type={type}
              value={value}
            />
          </div>
        </div>
        <InputStatus success={isStatusSuccess} />
        <InputAction actionId={actionId} handleAction={handleAction} />
        <InputError errorId={error} />
      </div>
    );
  }
}

SubmitInput.propTypes = {
  actionId: PropTypes.string,
  disabled: PropTypes.bool,
  errorId: PropTypes.string,
  handleAction: PropTypes.func,
  isError: PropTypes.bool,
  labelId: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  statusElement: PropTypes.bool,
  touched: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  type: PropTypes.string,
  value: PropTypes.any,
};

SubmitInput.defaultProps = {
  handleAction: () => {},
  onChange: () => {},
  type: 'text',
  value: '',
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(SubmitInput);
