import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Input from 'react-text-mask';
import { getActiveLanguage } from 'react-localize-redux';
import { connect } from 'react-redux';
import moment from 'moment';

import { Translation, InputError } from '../../';

import CalendarContainer from './CalendarContainer';

import styles from './DateInput.module.scss';

class MaskedInput extends Component {
  state = {
    isCalendarOpen: false,
  };

  getLocaleDateFormat = locale =>
    ({
      en: 'MM.DD.YYYY',
      ru: 'DD.MM.YYYY',
    }[locale]);

  toggleCalendar = () => this.setState({ isCalendarOpen: !this.state.isCalendarOpen });

  onCalendarChange = value => {
    const { input, localize } = this.props;

    const dateFormat = this.getLocaleDateFormat(getActiveLanguage(localize).code);

    input.onChange(moment(value).format(dateFormat));
    this.toggleCalendar();
  };

  outsideClick = () => {
    if (this.state.isCalendarOpen) {
      this.toggleCalendar();
    }
  };

  render() {
    const { disabled, labelId, localize, placeholder, meta, minDate, input, mask } = this.props;
    const { active, touched, error } = meta;
    const { value } = input;

    const { isCalendarOpen } = this.state;

    const isErrorVisible = touched && error && !disabled;
    const isInputFocused = active && !disabled;

    const dateFormat = this.getLocaleDateFormat(
      localize.languages.filter(lang => lang.active)[0].code
    );

    const isDateValid = moment(value, dateFormat, true).isValid();
    const calValue = isDateValid ? moment(value, dateFormat).toDate() : new Date();
    const inpValue = isDateValid ? value.toLocaleString().slice(0, 10) : value;

    const newPlaceholder = !active || value ? '' : placeholder;

    const err = touched ? error : null;

    const externalBorderClassName = cn(styles.ExternalBorder, {
      [styles.DisabledExternalBorder]: disabled,
      [styles.FocusedExternalBorder]: isInputFocused,
      [styles.ErrorExternalBorder]: isErrorVisible,
    });
    const internalBorderClassName = cn(styles.InternalBorder, {
      [styles.FocusedInternalBorder]: isInputFocused,
      [styles.ErrorInternalBorder]: isErrorVisible,
    });
    const labelClassName = cn(styles.Label, {
      [styles.FocusedLabel]: active || value,
      [styles.DisabledLabel]: disabled,
    });
    const calendarClassName = cn(styles.CalendarIcon, {
      [styles.DisabledCalendarIcon]: disabled,
    });

    return (
      <div className={styles.Container}>
        <Translation.Div className={labelClassName} translateId={labelId} />
        <div className={externalBorderClassName}>
          <div className={internalBorderClassName}>
            <Input
              className={styles.Input}
              {...input}
              placeholder={newPlaceholder}
              autoComplete="off"
              guide={false}
              disabled={disabled}
              mask={mask}
            />
          </div>
        </div>
        <div onClick={this.toggleCalendar} className={calendarClassName} />
        {isCalendarOpen ? (
          <CalendarContainer
            onCalendarChange={this.onCalendarChange}
            outsideClick={this.outsideClick}
            isCalendarOpen={isCalendarOpen}
            minDate={minDate}
            inpValue={inpValue}
            value={calValue}
          />
        ) : null}
        <InputError errorId={err} />
      </div>
    );
  }
}

MaskedInput.propTypes = {
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  labelId: PropTypes.string,
  localize: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  mask: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  minDate: PropTypes.instanceOf(Date),
};

MaskedInput.defaultProps = {
  onChange: () => {},
  value: '',
};

const mapStateToProps = ({ localize }) => ({
  localize,
});

export default connect(mapStateToProps)(MaskedInput);
