import { createReducer } from 'redux-act';

import {
  cancelChangePassword,
  cancelLogin,
  changePasswordFailure,
  changePasswordStart,
  changePasswordSuccess,
  clearUserCacheAction,
  confirm2factorLoginFailure,
  confirm2factorLoginStart,
  confirm2factorLoginSuccess,
  fetch2FactorSettingsSuccess,
  fetch2FactorSettingsError,
  fetchLoginBannerFailure,
  fetchLoginBannerStart,
  fetchLoginBannerSuccess,
  loginError,
  loginRecoverUser,
  loginStart,
  loginSuccess,
  logoutError,
  logoutStart,
  logoutSuccess,
  newLoginSuccess,
  rejectMobileDownload,
  resetTempPasswordRequired,
  set2FactorRequired,
  setLoginStep,
  setTempPasswordRequired,
  saveResAPI,
  setAntifraudUfmBlock,
  cleanAntifraudUfmBlock
} from 'actions/auth/index';
import { resetCaptcha } from 'actions/captcha';
import { changeLoginSuccess } from 'actions/security';

const defaultState = {
  isChangePasswordRequired: false,
  isPasswordChanged: false,
  isRequestPending: false,
  isAuthenticated: false,
  persistedUser: null,

  formControl: {
    // Step, which response for login form page
    loginStep: 1,
  },

  user: {
    fullname: '',
    phone: '',
    region: '',
    custid: '',
    abscustid: '',
    sign: '',
  },

  banner: null,
  showDefaultBanner: false,

  showMobiScreen: true,
  protection: {},
  error: '',
  antifraudUfmBlock: {
    blocked: false,
    message:""
  },

  resAPI: { execution: null },
};

const auth = createReducer(
  {
    [setAntifraudUfmBlock]: (state, {blocked = false, message = ""}) => {
      return {
        ...state,
        antifraudUfmBlock: {
          blocked,
          message,
        },
      };
    },
    [cleanAntifraudUfmBlock]: (state) => {
      return {
        ...state,
        antifraudUfmBlock: {
          blocked: false,
          message:""
        },
      };
    },
    [fetchLoginBannerStart]: state => {
      return {
        ...state,
        showDefaultBanner: false,
      };
    },
    [fetchLoginBannerSuccess]: (state, banner) => {
      return {
        ...state,
        banner,
        showDefaultBanner: false,
      };
    },
    [fetchLoginBannerFailure]: state => {
      return {
        ...state,
        showDefaultBanner: true,
      };
    },

    [loginStart]: state => {
      return {
        ...state,
        isRequestPending: true,
        error: '',
      };
    },

    [loginSuccess]: (state, { persistedUser, ...rest }) => {
      return {
        ...state,
        isRequestPending: false,
        isAuthenticated: true,
        formControl: {
          loginStep: 1,
        },
        persistedUser,
        error: '',
        user: { persistedUser, ...rest, sid: '' },
      };
    },

    [loginError]: state => {
      return {
        ...state,

        isRequestPending: false,
      };
    },

    [newLoginSuccess]: (state, { persistedUser, ...rest }) => {
      return {
        ...state,

        isRequestPending: false,
        isAuthenticated: true,
        persistedUser,
        error: '',
        user: {
          persistedUser,
          fullname: rest.displayName,
          abscustid: rest.cid,
          ...rest /*, sid: ''*/,
        },
      };
    },

    [cancelLogin]: state => {
      return {
        ...state,

        isRequestPending: false,
      };
    },

    [loginRecoverUser]: (state, user) => {
      return {
        ...state,
        persistedUser: user,
      };
    },

    [clearUserCacheAction]: state => {
      return {
        ...state,
        persistedUser: null,
      };
    },

    [resetCaptcha]: state => {
      return {
        ...state,
        captcha: '',
        captchaId: '',
      };
    },

    [logoutStart]: state => {
      return {
        ...state,
        // isRequestPending: true,
      };
    },

    [logoutSuccess]: state => {
      return {
        ...defaultState,
      };
    },

    [logoutError]: state => {
      return {
        ...state,
        isRequestPending: false,
      };
    },

    [changePasswordStart]: state => {
      return {
        ...state,

        isRequestPending: true,
      };
    },
    [changePasswordSuccess]: state => {
      return {
        ...state,

        formControl: {
          ...state.formControl,

          loginStep: 1,
        },
        isChangePasswordRequired: false,
        isRequestPending: false,
      };
    },
    [changePasswordFailure]: state => {
      return {
        ...state,
        isRequestPending: false,
      };
    },
    [cancelChangePassword]: state => {
      return {
        ...state,
        isRequestPending: false,
      };
    },

    [setLoginStep]: (state, step) => {
      return {
        ...state,

        formControl: {
          // Step, which response for login form page
          loginStep: step,
        },
      };
    },

    [setTempPasswordRequired]: state => {
      return {
        ...state,

        isChangePasswordRequired: true,
      };
    },

    [resetTempPasswordRequired]: state => {
      return {
        ...state,

        isChangePasswordRequired: false,
      };
    },

    [changeLoginSuccess]: (state, persistedUser) => {
      return {
        ...state,
        persistedUser,
        user: {
          ...state.user,
          persistedUser,
        },
      };
    },

    [rejectMobileDownload]: (state, persistedUser) => {
      return {
        ...state,

        showMobiScreen: false,
      };
    },
    [set2FactorRequired]: state => {
      return {
        ...state,
        formControl: {
          ...state.formControl,
          loginStep: 3,
        },
      };
    },
    [fetch2FactorSettingsSuccess]: state => ({ ...state, isRequestPending: false }),
    [fetch2FactorSettingsError]: state => ({ ...state, isRequestPending: false }),
    [confirm2factorLoginStart]: state => ({ ...state, isRequestPending: true }),
    [confirm2factorLoginSuccess]: state => ({ ...state, isRequestPending: false }),
    [confirm2factorLoginFailure]: state => ({ ...state, isRequestPending: false }),
    [saveResAPI]: (state, resAPI) => ({ ...state, resAPI }),
  },
  defaultState
);

export default auth;
