import React from 'react';
import PropTypes from 'prop-types';

import { Button, Translation, Modal, ModalHeader, ModalBody, ModalActions } from 'shared';
import { Translate } from 'react-localize-redux';
import { labelMaker } from 'shared/utils';
import Payment from '../Payment/Payment';

import styles from './PaymentsList.module.scss';

const label = labelMaker('shared.paymentList');

class PaymentsList extends React.Component {
  state = {
    isModalShown: false,
  };

  onOpenModal = () => {
    this.setState({ isModalShown: true });
  };

  onOpenModal = () => {
    this.setState({ isModalShown: false });
  };

  renderModal = () => {
    const { isModalShown } = this.state;

    const modal = isModalShown ? (
      <Modal onClose={this.handleHide}>
        <ModalHeader>
          <Translate id={label('withdrawApplicationQuest')} />
        </ModalHeader>
        <ModalBody>
          <Translate id={label('withdrawApplicationDescription')} />
        </ModalBody>
        <ModalActions>
          <Button theme="white" translateId={label('withdrawBtn')} />
          <Button onClick={this.onCloseModal} translateId={label('keepBtn')} />
        </ModalActions>
      </Modal>
    ) : null;

    return { modal };
  };

  renderPaymentsList = payments => {
    const { canDropDown, withdrawApplication } = this.props;

    return payments.length
      ? payments.map((payment, i) => (
          <Payment
            payment={payment}
            key={i}
            canDropDown={canDropDown}
            withdrawApplication={withdrawApplication}
          />
        ))
      : this.renderEmptyList();
  };

  renderEmptyList = () => {
    const { Span } = Translation;

    return (
      <div className={styles.PaymentEmpty}>
        <div className={styles.EmptyMask}>
          <div className={styles.Image} />
        </div>
        <Span className={styles.EmptyInfo} translateId={label('emptyListInfo')} />
      </div>
    );
  };

  render() {
    const { payments, canDropDown, withdrawApplication } = this.props;

    return (
      <div className={styles.List}>
        {Array.isArray(payments) ? (
          this.renderPaymentsList(payments)
        ) : (
          <Payment
            payment={payments}
            canDropDown={canDropDown}
            withdrawApplication={withdrawApplication}
          />
        )}
      </div>
    );
  }
}

PaymentsList.propTypes = {
  payments: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  withdrawApplication: PropTypes.func,
  canDropDown: PropTypes.bool,
};

PaymentsList.defaultProps = {
  canDropDown: true,
  payments: [],
};

export default PaymentsList;
