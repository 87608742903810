import React from 'react';
import { getTranslatedField1 } from 'shared/utils';
import { Button } from 'shared';
import styles from './LoginCarouselSlide.module.scss'
import PropTypes from 'prop-types';

const LoginCarouselSlide = ({ slide, height, locale }) => {
  let settings;
  try {
    settings = JSON.parse(
      slide.json_configuration.replace(/'/g, '"').replace(/font-size/g, 'fontSize')
    );
  } catch (error) {
    console.error(error);
  }

  const carouselStyle = settings
    ? {
        ...settings.title,
        visibility: settings.title.visible === 'true' ? 'visible' : 'hidden',
      }
    : {};
  const buttonStyle = settings
    ? {
        ...settings.button,
        visibility: settings.button.visible === 'true' ? 'visible' : 'hidden',
        minWidth: 120,
      }
    : {};
  const descStyle = settings
    ? {
        ...settings.desc,
        visibility: settings.desc.visible === 'true' ? 'visible' : 'hidden',
      }
    : {};
  // const imgStyle = settings
  //   ? {
  //       ...settings.picture,
  //     }
  //   : {};

  return (
    <div
      className={styles.CarouselItem}
      style={{
        backgroundImage: `url('${slide.image}')`,
        height: height,
        // ...imgStyle,
      }}
    >
      <div className={styles.CarouselTitle} style={carouselStyle}>
        {getTranslatedField1(slide, 'title', { locale: locale })}
      </div>

      <div className={styles.CarouselDescription} style={descStyle}>
        {getTranslatedField1(slide, 'content', { locale: locale })}
      </div>

      <a
        rel="noreferrer noopener"
        target="_blank"
        className={styles.LinkBtn}
        href={slide[`url_${locale}`]}
        style={buttonStyle}
      >
        <Button className={styles.CarouselButton} round>
          {getTranslatedField1(slide, 'button_text', { locale: locale })}
        </Button>
      </a>
    </div>
  );
};

LoginCarouselSlide.propTypes = {
  slide: PropTypes.object,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  locale: PropTypes.string,
};

export default LoginCarouselSlide;
