import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import Calendar from 'react-calendar';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import { connect } from 'react-redux';

import styles from './DateInput.module.scss';

class CalendarContainer extends Component {
  handleClickOutside = () => {
    this.props.outsideClick();
  };

  render() {
    const { onCalendarChange, value, localize, minDate, tileDisabled } = this.props;
    const locale = localize.languages.filter(lang => lang.active)[0].code;

    return (
      <div className={classNames([styles.CalendarContainer])}>
        <Calendar
          tileDisabled={tileDisabled}
          minDate={minDate}
          locale={locale}
          onChange={onCalendarChange}
          value={value}
        />
      </div>
    );
  }
}

CalendarContainer.propTypes = {
  tileDisabled: PropTypes.func.isRequired,
  onCalendarChange: PropTypes.func.isRequired,
  outsideClick: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  localize: PropTypes.object.isRequired,
  minDate: PropTypes.instanceOf(Date),
};

const mapStateToProps = state => ({
  localize: state.localize,
});

export default connect(mapStateToProps)(onClickOutside(CalendarContainer));
