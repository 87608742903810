import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CodeInput, Translation } from '..';
import { resetSignature, signatureValueChange } from 'actions/signature';

import styles from './MobipassInput.module.scss';

import { labelMaker } from 'shared/utils';

const label = labelMaker('shared.mobipass');
const numOfFields = 6;

class MobipassInput extends Component {
  onChange = field => {
    const { onChange } = this.props;
    const { value } = field;

    onChange(value);
  };

  componentWillUnmount() {
    this.props.resetSignature();
  }

  render() {
    const { Div } = Translation;
    const { docNumber, signature, children } = this.props;
    const { error, value, isLoading } = signature;

    return (
      <div className={styles.MobipassInput}>
        <Div className={styles.Title} translateId={label('enterCode')} />
        <Div className={styles.DocNumberTitle} translateId={label('docNumber')} />
        <div className={styles.DocNumber}>{docNumber}</div>
        <Div className={styles.CodeTitle} translateId={label('sessionKey')} />
        <div className={styles.CodeContainer}>
          <CodeInput
            disabled={isLoading}
            error={error}
            fields={numOfFields}
            onChange={this.onChange}
            value={value}
          />
          {children}
        </div>
      </div>
    );
  }
}

MobipassInput.propTypes = {
  docNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  error: PropTypes.string,
  signature: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  resetSignature: PropTypes.func.isRequired,
};

const mapStateToProps = ({ signature }) => ({
  signature,
});

const mapDispatchToProps = {
  onChange: signatureValueChange,
  resetSignature,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobipassInput);
