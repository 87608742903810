import React, { Component } from 'react';
import { addressCompletionReq } from 'actions/dadata/dadataRequests';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ReduxInput from '../ReduxInput/Input';
import ReduxSearchInput from '../ReduxSearchInput/ReduxSearchInput';
import Menu from './Menu';

import styles from './LocationInput.module.scss';

import { debounce } from 'shared/utils';

class LocationInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locations: [],

      isMenuOpen: false,
      isMenuLoading: false,
    };
    this.loadHints = debounce(this.asyncLocationLoad, 300);
  }

  toggleMenuView = option => {
    const { isMenuOpen } = this.state;

    this.setState({
      isMenuOpen: !isMenuOpen,
    });
  };
  openMenuView = () => {
    this.setState({
      isMenuOpen: true,
    });
  };
  hideMenuView = () => {
    this.setState({
      isMenuOpen: false,
    });
  };

  asyncLocationLoad = reqValue => {
    const { input, addressCompletionReq } = this.props;

    addressCompletionReq(reqValue).then(data => {
      const locations = data.data.suggestions;

      if (input.value === reqValue) {
        this.setState({
          isMenuLoading: false,
          locations,
        });
      }
    });
  };

  onChange = e => {
    const { input, value: currValue } = this.props;
    const { isMenuOpen } = this.state;

    if (currValue !== e.target.value) {
      this.loadHints(e.target.value);
      this.setState({
        isMenuLoading: true,
      });

      if (!isMenuOpen) {
        this.openMenuView();
      }
    } else if (currValue === e.target.value && isMenuOpen) {
      this.hideMenuView();
    }

    input.onChange(e);
  };

  selectOption = option => () => {
    const { input } = this.props;

    input.onChange(option.value);
    this.hideMenuView();
  };

  render() {
    const { locations, isMenuOpen, isMenuLoading } = this.state;
    const { dadata, round } = this.props;

    return (
      <div className={styles.LocationInputComponent}>
        {round ? (
          <ReduxSearchInput {...this.props} onChange={this.onChange} />
        ) : (
          <ReduxInput {...this.props} onChange={this.onChange} />
        )}
        {dadata.apiKey ? (
          <Menu
            isOpen={isMenuOpen}
            locations={locations}
            isLoading={isMenuLoading}
            selectOption={this.selectOption}
            onClickOutside={this.hideMenuView}
          />
        ) : null}
      </div>
    );
  }
}

LocationInput.propTypes = {
  addressCompletionReq: PropTypes.func.isRequired,
  input: PropTypes.object.isRequired,
  dadata: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  round: PropTypes.bool,
};

const mapStateToProps = ({ dadata }) => ({
  dadata,
});

const mapDispatchToProps = dispatch => ({
  addressCompletionReq: args => dispatch(addressCompletionReq(args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationInput);
