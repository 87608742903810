import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './SmoothDropDown.module.scss';

class SmoothDropDown extends Component {
  state = {
    height: 0,
  };

  componentDidUpdate(prevProps, prevState) {
    const { isOpen: prevIsOpen } = prevProps;
    const { isOpen } = this.props;

    if (!prevIsOpen && isOpen) {
      if (this.openTimer) {
        clearInterval(this.openTimer);
      }
      this.setState({
        height: this.ref.scrollHeight,
      });
      this.openTimer = setTimeout(
        () => this.setState({ overflow: 'initial', height: 'auto' }),
        500
      );
    }

    if (prevIsOpen && !isOpen) {
      if (this.openTimer) {
        clearInterval(this.openTimer);
      }
      this.setState({
        height: this.ref.scrollHeight,
        overflow: 'hidden',
      });
      this.openTimer = setTimeout(() => this.setState({ height: 0 }), 0);
    }
  }

  componentDidMount() {
    if (this.props.isOpen) {
      if (this.openTimer) {
        clearInterval(this.openTimer);
      }
      this.setState({
        height: this.ref.scrollHeight,
      });
      this.openTimer = setTimeout(
        () => this.setState({ overflow: 'initial', height: 'auto' }),
        500
      );
    }
  }

  componentWillUnmount() {
    if (this.openTimer) {
      clearInterval(this.openTimer);
    }
  }

  render() {
    const { children, className } = this.props;
    const { height, overflow } = this.state;

    return (
      <div
        className={cn(styles.DropDown, { [className]: className })}
        style={{ height, overflow }}
        ref={ref => (this.ref = ref)}
      >
        {children}
      </div>
    );
  }
}

SmoothDropDown.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
};

export default SmoothDropDown;
