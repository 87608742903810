import { createAction } from 'redux-act';
import { getTranslate } from 'react-localize-redux';
import moment from 'moment';

import apiServicesConstructor from 'services/apiService';
import { get, getErrorMessage, labelMaker } from 'shared/utils';
import { senSignOperation } from 'actions/products/productsRequests';

import {
  showWarnNotification,
  showInfoNotification,
} from '../notification';

export const setUiSetingsStart = createAction('SET_UI_SETTINGS_START');
export const setUiSetingsSuccess = createAction('SET_UI_SETTINGS_SUCCESS');
export const setUiSetingsError = createAction('SET_UI_SETTINGS_ERROR');

export const saveOtpSettings = createAction('SAVE_OTP_SETTINGS');
export const setOtpLoading = createAction('SET_OTP_LOADING');

const lError = labelMaker('errorsGeneral');
const label = labelMaker('profile.prevAuthNotify');

export const fetchUiSetings = ({ withPrevAuthData }) => async (dispatch, getState) => {
  const { localize } = getState();

  const translate = getTranslate(localize);
  try {
    let response = await dispatch(fetchUiSetingsReq());
    response = response.find((res) => get(res, 'type') === 'PREV_AUTH_NOTIFY');

    if (withPrevAuthData && get(response, 'enabled')) {
      dispatch(fetchPrevAuthData());
    }

    dispatch(setUiSetingsSuccess(response));
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
  }
};

export const fetchUiSetingsReq = (useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  try {
    const apiServices = apiServicesConstructor(dispatch, getState);

    const response = await apiServices.get({
      url: `/webapi-1.0/ui-settings`,
    });

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const sendCustomerVerification = (reqData) => async (dispatch, getState) => {
  const { otpCode, ...rest } = reqData;
  const { auth } = getState();
  const timestamp = Date.now();
  const data = [
    window.location.host,
    '/webapi-1.0/ui-settings',
    encodeURI(window.location.search),
    'application/json',
    'POST',
    JSON.stringify(rest),
    timestamp,
    get(auth, 'user.sub'),
    '/customer',
    '',
  ].join('\n');
  try {
    const enc = new TextEncoder();
    const algorithm = { name: "HMAC", hash: "SHA-256" };

    const key = await crypto.subtle.importKey(
      "raw",
      enc.encode(otpCode),
      algorithm,
      false,
      ["sign"],
    );

    const signature = await crypto.subtle.sign(
      algorithm.name,
      key,
      enc.encode(data),
    );

    // function toHexString(byteArray) {
    //   return Array.prototype.map.call(byteArray, function(byte) {
    //     return ('0' + (byte & 0xFF).toString(16)).slice(-2);
    //   }).join('');
    // }
    // console.log(toHexString(new Uint8Array(signature)), 'toHexString(signature)');

    const base64String = btoa(String.fromCharCode(...new Uint8Array(signature)));

    return { 'X-Request-Signature': `version=1; timestamp=${timestamp}; alg=hmac-sha256; signature=${base64String}` };
  } catch (err) {
    return { 'X-Request-Signature': err };
  }
}

export const patchUiSettings = (reqData = {}) => async (dispatch, getState) => {
  const { localize } = getState();
  const { profile } = getState();
  const { enabled } = profile.prevAuthNotify;

  const translate = getTranslate(localize);
  try {
    dispatch(setUiSetingsStart());

    let response = await dispatch(patchUiSetingsReq(reqData));

    if (get(response, 'code') === 108) {
      const signingRequestId = get(response, 'data.signingRequestId');
      const resp1 = await dispatch(senSignOperation(signingRequestId));

      const {
        status: newStatus,
        otpFlowState,
        extendedAttributes,
      } = resp1;

      const {
        get2FA: {
          tankeyParams,
          mobipassParams,
          smsParams,
        },
      } = extendedAttributes;

      let params = {};
      if (tankeyParams) {
        params.confirmType = 'tan';
        params.tankeyNumber = tankeyParams.tankeyNumber;
        params.codeLength = 6;
      }
      if (mobipassParams) {
        params.confirmType = 'mobipass';
        params.codeLength = 6;
        params.mobipassKey = mobipassParams.mobipassKey;
      }
      if (smsParams) {
        params.confirmType = 'sms_code';
        params = { ...params, ...smsParams };
      }

      dispatch(saveOtpSettings({
        settings: params,
        status: newStatus,
        otpFlowState,
      }));
    } else {
      response = response.find((res) => get(res, 'type') === 'PREV_AUTH_NOTIFY');

      if (get(response, 'enabled')) {
        dispatch(showInfoNotification({
          message: translate(label('patchSuccess.enabled')),
          isSetImmediately: true,
        }));
      } else {
        dispatch(showInfoNotification({
          message: translate(label('patchSuccess.disabled')),
          isSetImmediately: true,
        }));
      }

      dispatch(saveOtpSettings({}));
      dispatch(setOtpLoading(false));
      dispatch(setUiSetingsSuccess(response));
    }

  } catch (err) {
    dispatch(setUiSetingsError());
    dispatch(saveOtpSettings({}));
    dispatch(setOtpLoading(false));

    if (enabled) {
      dispatch(showWarnNotification({
        message: translate(label('patchError.enabled')),
        isSetImmediately: true,
      }));
    } else {
      dispatch(showWarnNotification({
        message: translate(label('patchError.disabled')),
        isSetImmediately: true,
      }));
    }
  }
}

export const patchUiSetingsReq = (reqData) => async (
  dispatch,
  getState
) => {
  try {
    const { token } = reqData;
    const { profile } = getState();
    const { enabled } = profile.prevAuthNotify;

    const apiServices = apiServicesConstructor(dispatch, getState);

    const headers = { 'Content-Type': 'application/json' };
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const response = await apiServices.patch({
      url: `/webapi-1.0/ui-settings`,
      headers,
      throwFullError: true,
      data: [{
        type: 'PREV_AUTH_NOTIFY',
        enabled: !enabled
      }],
    });

    return Promise.resolve(response);
  } catch (err) {
    if (get(err, 'data.error.code') === 108) {
      return Promise.resolve(get(err, 'data.error'));
    }
    return Promise.reject(err);
  }
};

export const fetchPrevAuthNotifyEnabled = (withPrevAuthData = false) => async (dispatch, getState) => {
  const { localize } = getState();

  const translate = getTranslate(localize);
  try {
    const response = await dispatch(fetchPrevAuthNotifyEnabledReq());
    const { textEn, textRu } = response;

    const prevAuthNotifyEnabled = textEn === 'TRUE' && textRu === 'TRUE';

    if (prevAuthNotifyEnabled) {
      dispatch(fetchUiSetings({ withPrevAuthData }));
    }

  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
  }
};

export const fetchPrevAuthNotifyEnabledReq = (useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  try {
    const apiServices = apiServicesConstructor(dispatch, getState);

    let response = await apiServices.get({
      url: `/webapi-1.0/translate?module=3&filter=PREV_AUTH_NOTIFY_ENABLED`,
    });

    // response = [{
    //   title: 'PREV_AUTH_NOTIFY_ENABLED',
    //   textEn: 'TRUE',
    //   textRu: 'TRUE',
    //   module: '3',
    //   code: 'PREV_AUTH_NOTIFY_ENABLED',
    // }];

    response = response.find((settings) => get(settings, 'title') === 'PREV_AUTH_NOTIFY_ENABLED');

    return Promise.resolve(response || {});
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchPrevAuthData = () => async (dispatch, getState) => {
  const { localize } = getState();

  const translate = getTranslate(localize);
  try {
    const response = await dispatch(fetchPrevAuthDataReq());
    // const response = {
    //   "displaySettings": {
    //       "message": "Последний вход $$$prevAuthDate, $$$prevDeviceInfo",
    //       "displayTime": 5000
    //   },
    //   "principalId": "bis_____1246772",
    //   "prevAuthDate": "2022-10-17T10:46:28",
    //   "prevDeviceInfo": "OnePlus 4"
    // };

    const prevAuthMessage = get(response, 'displaySettings.message');
    const displayTime = get(response, 'displaySettings.displayTime');
    const prevAuthDate = get(response, 'prevAuthDate');
    const prevDeviceInfo = get(response, 'prevDeviceInfo', '');

    const utcOffset = moment().utcOffset();

    if (prevAuthMessage) {
      dispatch(showInfoNotification({
        message: prevAuthMessage
          .replace('$$$prevAuthDate', moment.utc(prevAuthDate).utcOffset(utcOffset).format('DD.MM.YYYY HH:mm'))
          .replace('$$$prevDeviceInfo', prevDeviceInfo)
          .replace(/,\s*$/, ''),
        displayTime: displayTime
      }));
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
  }
};

export const fetchPrevAuthDataReq = (useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  try {
    const apiServices = apiServicesConstructor(dispatch, getState);

    const response = await apiServices.get({
      url: `/webapi-1.0/auth/prev`,
    });

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const send2FA = ({ otpCode }) => async (dispatch, getState) => {
  const { localize, profile } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(setOtpLoading(true));
    const { execution, sessionId } = get(profile, 'prevAuthNotify.otp.otpFlowState', {});

    const { token } = await dispatch(validateSignOperation({ otpCode, execution, sessionId }));
    dispatch(patchUiSettings({ token, otpCode }));
    dispatch(setOtpLoading(false));
  } catch (error) {
    dispatch(setOtpLoading(false));
    const errMessage = getErrorMessage(error, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
  }
}

const validateSignOperation = (reqData) => async (
  dispatch,
  getState
) => {
  const { otpCode, ...rest } = reqData;
  const reqParams = {
    url: `/webapi-1.0/sign-operation/validate?otpCode=${otpCode}`,
    headers: { 'Content-Type': 'application/json' },
    data: rest,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
}
