import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';
import PropTypes from 'prop-types';

import { cancelLogin, fetchLoginBanners } from 'actions/auth';
// import Form from './Form/Form';
import NewForm from './Form/NewForm';
import LoginCarousel from './LoginCarousel/LoginCarousel';
import DefaultSlide from './DefaultSlide';

import styles from './Login.module.scss';

import { throttle, labelMaker } from 'shared/utils';

const label = labelMaker('auth.carousel');

class Login extends Component {
  state = {
    carouselHeight: 0,
  };

  componentWillUnmount() {
    this.props.cancelLogin();
    window.removeEventListener('resize', this.throttledWatchCarouselHeight);
  }

  componentDidMount() {
    this.setState({
      carouselHeight: this.form.offsetHeight,
    });

    this.props.fetchLoginBanners();
    this.throttledWatchCarouselHeight = throttle(this.watchCarouselHeight, 20);
    window.addEventListener('resize', this.throttledWatchCarouselHeight);
  }

  watchCarouselHeight = () => {
    if (this.form) {
      this.setState({
        carouselHeight: this.form.offsetHeight,
      });
    }
  };

  render() {
    const { banner, locale, showDefaultBanner } = this.props;
    return (
      <div className={styles.Card}>
        <div className={styles.FormColumn} ref={ref => (this.form = ref)}>
          {/* <Form /> */}
          <NewForm />
        </div>
        <div className={styles.CarouselColumn}>
          {this.props.banner ? (
            <LoginCarousel {...{ banner, height: this.state.carouselHeight, locale }} />
          ) : (
            <DefaultSlide {...{ showDefaultBanner, label }} />
          )}
        </div>
      </div>
    );
  }
}

const mapState = ({ auth, localize }) => ({
  banner: auth.banner,
  showDefaultBanner: auth.showDefaultBanner,
  locale: getActiveLanguage(localize).code,
});

const mapDispatch = {
  cancelLogin,
  fetchLoginBanners,
};

Login.propTypes = {
  locale: PropTypes.string,
  banner: PropTypes.object,
  showDefaultBanner: PropTypes.bool.isRequired,
  cancelLogin: PropTypes.func.isRequired,
  fetchLoginBanners: PropTypes.func.isRequired,
};

export default connect(
  mapState,
  mapDispatch
)(Login);
