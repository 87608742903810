import { labelMaker } from 'shared/utils';

const label = labelMaker('shared');

const schedule = {
  periodFilter: {
    value: 'week',
    data: [
      { labelId: label('period.week'), value: 'week' },
      { labelId: label('period.month'), value: 'month' },
      { labelId: label('period.year'), value: 'year' },
      { labelId: label('period.period'), value: 'period' },
    ],
  },
  typeFilter: {
    value: '1',
    data: [
      {
        labelId: label('type.allOperationTypes'),
        value: '1',
      },
      // { labelId: label('type.payments'), value: '2' },
      // { labelId: label('type.transfers'), value: '3' },
    ],
  },
};

export default schedule;
