import React from 'react';
import { ObjectManager } from 'react-yandex-maps';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { branchesFilterSelector } from 'store/selectors/atms';
import PropTypes from 'prop-types';

class BranchesObjects extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.branches !== nextProps.branches ||
      this.props.translate !== nextProps.translate
    ) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const { translate, branches, getPointData, getPointOptions } = this.props;

    const branchesFeatures = [];

    for (let i = 0; i < branches.length; i++) {
      branchesFeatures.push({
        type: 'Feature',
        id: branches[i].branchId,
        geometry: {
          type: 'Point',
          coordinates: [branches[i].latitude, branches[i].longitude],
        },
        properties: getPointData(branches[i], translate),
        options: getPointOptions(branches[i]),
      });
    }

    return (
      <ObjectManager
        options={{
          clusterize: true,
          gridSize: 90,
          groupByCoordinates: false,
          clusterHideIconOnBalloonOpen: false,
          geoObjectHideIconOnBalloonOpen: false,
          clusterZoomMargin: 20,
        }}
        objects={{
          openBalloonOnClick: true,
          preset: 'islands#greenDotIcon',
        }}
        clusters={{
          preset: 'islands#redClusterIcons',
        }}
        features={branchesFeatures}
      />
    );
  }
}

BranchesObjects.propTypes = {
  translate: PropTypes.func.isRequired,
  branches: PropTypes.array,
  getPointData: PropTypes.func.isRequired,
  getPointOptions: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  branches: branchesFilterSelector(state),
  translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(BranchesObjects);
