import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { cancel2FactorLogin, skipChangeScratchCard } from 'actions/auth';
import { Button, Translation } from 'shared';
import { labelMaker } from 'shared/utils';

import { options } from '../../formOptions';

import styles from './Step4.module.scss';

const label = labelMaker('auth');
const lGeneral = labelMaker('general');

class Step4 extends Component {
  render() {
    const { auth, cancel2FactorLogin, skipChangeScratchCard } = this.props;
    const { Div } = Translation;

    const { isRequestPending } = auth;

    return (
      <div className={styles.Step}>
        <div className={styles.NavigationContainer}>
          <div className={styles.PrevArrow} />
          <Div
            className={styles.NavigationTitle}
            translateId={lGeneral('buttons.backBtn')}
            onClick={cancel2FactorLogin}
          />
        </div>
        <Div className={styles.TextRow} translateId={label('scratchCard')} />
        <div className={styles.InputContainer}>
          <Field
            {...options.scratchCardIdToBind}
            disabled={auth.isRequestPending}
            onFocus={this.hideNotificationMessage}
          />
        </div>
        <div className={styles.InputContainer}>
          <Field
            {...options.scratchCardIdNew}
            disabled={auth.isRequestPending}
            onFocus={this.hideNotificationMessage}
          />
        </div>

        {/* <Div className={styles.PasswordNotification} translateId={label('characterConstraint')} /> */}
        <div className={styles.ButtonContainer}>
          <Button theme="white" round showLoader={isRequestPending} onClick={skipChangeScratchCard}>
            <Translate id="auth.skip" />
          </Button>
          <Button type="submit" round showLoader={isRequestPending}>
            <Translate id="auth.send" />
          </Button>
        </div>
      </div>
    );
  }
}

Step4.propTypes = {
  auth: PropTypes.object.isRequired,
  cancel2FactorLogin: PropTypes.func.isRequired,
  skipChangeScratchCard: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => ({
  auth,
});

export default connect(
  mapStateToProps,
  { cancel2FactorLogin, skipChangeScratchCard }
)(Step4);
