import { createReducer } from 'redux-act';

import {
  fetchDadataApiKeyStart,
  fetchDadataApiKeySuccess,
  fetchDadataApiKeyFailure,
} from 'actions/dadata/index';

const defaultState = {
  apiKey: null,
};

const dadata = createReducer(
  {
    [fetchDadataApiKeyStart]: state => {
      return {
        ...state,

        isLoading: true,
      };
    },
    [fetchDadataApiKeySuccess]: (state, apiKey) => {
      return {
        ...state,

        apiKey,
        isLoading: false,
      };
    },
    [fetchDadataApiKeyFailure]: (state, apiKey) => {
      return {
        ...state,

        isLoading: false,
      };
    },
  },
  defaultState
);

export default dadata;
