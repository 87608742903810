import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';

import {
  Translation,
  Toggle,
  Spinner,
  Button,
  MobipassInput,
  ScratchCardInput,
  SmsInput,
} from 'shared';
import {
  labelMaker,
  // formatNumber,
  formatPhone,
  getProps as buildGetProps,
  // getLocaleDateFormat,
} from 'shared/utils';
import { shapeFields, getValidationErrorId } from 'shared/validators';

import { patchUiSettings, send2FA } from 'actions/security/prevAuthNotify';

import styles from './PreviousLoginNotification.module.scss';

const { Div } = Translation;
const label = labelMaker('profile.prevAuthNotify');

class PreviousLoginNotification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: shapeFields({}),
    };
  }

  renderToggle = () => {
    const {
      enabled,
      isLoading,
    } = this.props.profile.prevAuthNotify;
    const {
      patchUiSettings,
    } = this.props;
    return (
      <>
        <Div className={styles.Header} translateId={label('prevLoginTitle')} />
        <div className={styles.NoSignContainer}>
          {
            isLoading
              ? <Spinner className={styles.LoadingSpinner} />
              : <Div className={styles.ToggleLabel} translateId={enabled ? label('on') : label('off')} />
          }
          <Toggle
            value={enabled}
            onChange={patchUiSettings}
            disabled={isLoading}
          />
        </div>
        <Div className={styles.BlockInfo} translateId={label('prevLoginDesc')} />
      </>
    );
  }

  onChange = ({ value, name }, showError) => {
    const { fields } = this.state;
    const field = fields[name];

    const { isError } = field;

    const newField = { ...field, value, touched: true, isError: showError || isError };
    const errorId = getValidationErrorId(newField);

    this.setState({
      fields: {
        ...fields,
        [name]: {
          ...newField,
          errorId,
        },
      },
    });
  };

  confirmOtp = (e) => this.props.send2FA({ otpCode: this.props.signature.value });

  renderOTP = () => {
    const { fields } = this.state;
    const { auth } = this.props;
    const { settings, isLoading } = this.props.profile.prevAuthNotify.otp;
    const { tankeyNumber, codeLength: numOfFields, mobipassKey, confirmType = '' } = settings;
    const { phone } = auth.user;

    const codeInputProps = buildGetProps({ fields, onChange: this.onChange })('code');

    switch (confirmType.toLowerCase()) {
      case 'sms_code':
        return (
          <div className={styles.OTPContainer}>
            <SmsInput phoneNumber={phone ? formatPhone(phone) : null} />
            <div className={styles.SubmitContainer}>
              <Button
                onClick={this.confirmOtp}
                showLoader={isLoading}
                translateId={label('send')}
              />
            </div>
          </div>
        );
      case 'mobipass':
        return (
          <div className={styles.OTPContainer}>
            <MobipassInput
              codeInputProps={codeInputProps}
              docNumber={mobipassKey}
              numOfFields={parseInt(numOfFields, 10)}
            />
            <div className={styles.SubmitContainer}>
              <Button
                onClick={this.confirmOtp}
                showLoader={isLoading}
                translateId={label('send')}
              />
            </div>
          </div>
        );
      case 'tan':
        return (
          <div className={styles.OTPContainer}>
            <ScratchCardInput
              codeInputProps={codeInputProps}
              scratchCode={tankeyNumber}
              numOfFields={parseInt(numOfFields, 10)}
            />
            <div className={styles.SubmitContainer}>
              <Button
                onClick={this.confirmOtp}
                showLoader={isLoading}
                translateId={label('send')}
              />
            </div>
          </div>
        );
      default:
    }
  };

  render() {
    const { settings: { prevAuthNotifyEnabled } } = this.props;
    const { confirmType } = this.props.profile.prevAuthNotify.otp.settings;
    if (!prevAuthNotifyEnabled.ru && !prevAuthNotifyEnabled.en) {
      return null;
    }

    return (
      <>
        {
          confirmType
            ? this.renderOTP()
            : this.renderToggle()
        }
      </>
    )
  }
}

PreviousLoginNotification.propTypes = {
  fields: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  patchUiSettings: PropTypes.func.isRequired,
};

const mapStateToProps = ({ profile, localize, settings, auth, signature }) => ({
  profile,
  locale: getActiveLanguage(localize).code,
  settings,
  auth,
  signature,
});

const mapDispatchToProps = {
  patchUiSettings,
  send2FA,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviousLoginNotification);
