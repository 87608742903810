import apiServicesConstructor from 'services/apiService';
import { fetchSettingsReqStub } from './settingsStubs';

const isDevelopment = process.env.NODE_ENV === 'development';
const useGlobalStubs = process.env.REACT_APP_USE_STUBS === 'true';

/**
 * module = 3 - spd_show
 */
export const fetchSettingsReq = (useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await fetchSettingsReqStub(withError);
  }

  const reqParams = {
    url: `/webapi-1.0/translate?module=3`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};
