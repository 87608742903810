import React, { Fragment } from 'react';

import { withNotification, withKYCNotification } from 'shared';
import PasswordRecovery from 'components/PasswordRecovery/PasswordRecovery';
import styles from './index.module.scss';

const PasswordRecoveryPage = () => {
  return (
    <Fragment>
      <div className={styles.Page}>
        <PasswordRecovery />
      </div>
    </Fragment>
  );
};

export default withKYCNotification(withNotification(PasswordRecoveryPage));
