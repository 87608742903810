import { buildUrlParams, encodeDataURI } from 'shared/utils';
import apiServicesConstructor from 'services/apiService';

export const fetchBranchesByCoordinatesReq = ({ coords }) => async (dispatch, getState) => {
  const urlParams = buildUrlParams(encodeDataURI({ coords, pageSize: 10000 }));
  const reqParams = {
    url: `/webapi-1.0/branch/filter/position?${urlParams}`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchAtmsByCoordinatesReq = ({ coords }) => async (dispatch, getState) => {
  const urlParams = buildUrlParams(encodeDataURI({ coords, pageSize: 10000 }));
  const reqParams = {
    url: `/webapi-1.0/atm/filter/position?${urlParams}`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};
