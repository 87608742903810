import { Button, MobipassInput, ScratchCardInput, SmsInput, Translation } from 'shared';
import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';
import { formatNumber, formatPhone, getLocaleDateFormat, labelMaker } from 'shared/utils';
import {
  confirmPrepayApplication,
  prepay2FA,
} from 'actions/products';
import { accountsSelector } from 'store/selectors/accounts';
import styles from './Step2.module.scss';

const label = labelMaker('products.prepayment');
const labelPInfo = labelMaker('products.productInfo');

class Step2 extends Component {
  handleConfirmMessage = () => {
    const { confirmPrepayApplication, fields } = this.props;
    const { date, sum } = fields;

    confirmPrepayApplication({
      date: date.value,
      sum: sum.value,
    });
  };

  handleSubmitPayment = () => {
    const { prepay2FA, signature: { value }, fields: { date, sum } } = this.props;
    prepay2FA({ otpCode: value, date: date.value, sum: sum.value });
  };

  renderSecurity = () => {
    const { signature, auth } = this.props;
    const { tankeyNumber, codeLength: numOfFields, confirmType, mobipassKey } = signature.settings;

    const { phone } = auth.user;
    switch (confirmType) {
      case 'sms_code':
        return (
          <div>
            <SmsInput phoneNumber={phone ? formatPhone(phone) : null} />
            <div className={styles.SubmitContainer}>
              <Button onClick={this.handleSubmitPayment} translateId={label('send')} />
            </div>
          </div>
        );
      case 'mobipass':
        return (
          <div>
            <MobipassInput docNumber={mobipassKey} numOfFields={parseInt(numOfFields, 10)} />
            <div className={styles.SubmitContainer}>
              <Button onClick={this.handleSubmitPayment} translateId={label('send')} />
            </div>
          </div>
        );
      case 'tan':
        return (
          <div className={styles.SubmitContainer}>
            <ScratchCardInput scratchCode={tankeyNumber} numOfFields={parseInt(numOfFields, 10)} />
            <div className={styles.SubmitContainer}>
              <Button onClick={this.handleSubmitPayment} translateId={label('send')} />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  render() {
    const { accounts, products, incomingApplication, locale, signature, isCreditHolidays } = this.props;
    const dateFormat = getLocaleDateFormat(locale);
    const { Div } = Translation;
    const { ccy, drsettlementacc } = products.credit;

    const account = accounts.find(acc => acc.accountNo === drsettlementacc) || {};

    const {
      newAnnuity,
      expectedPaymentsCount,
      repayment: { amount, date },
      paymentType,
      checkMessage,
    } = incomingApplication;

    return (
      <div className={styles.Step}>
        <Div className={styles.StepTitle} translateId={label('earlyPaymentApplication')} />
        <Div className={styles.StepDescription} translateId={label('checkApplicationParams')} />
        <div className={styles.CurrencyTable}>
          <div className={styles.ThRow}>
            <Div className={styles.ThColInfo} translateId={label('earlyPaymentDate')} />
            <div className={styles.ThColResult}>{moment(date).format(dateFormat)}</div>
          </div>
          <div className={styles.ThRow}>
            <Div className={styles.ThColInfo} translateId={label('earlyPaymentSum')} />
            <div className={styles.ThColResult}>{formatNumber(amount, { currency: ccy })}</div>
          </div>
          {newAnnuity && <div className={styles.TdRow}>
            <Div className={styles.TdColInfo} translateId={label('newMonthlyPay')} />
            {
              !isCreditHolidays
              ? <div className={styles.TdColResult}>{newAnnuity && formatNumber(newAnnuity, { currency: ccy })}</div>
              : <Div className={styles.TdColResult} translateId={labelPInfo('newEarlyPaymentHildays')} />
            }
          </div>}
          {/* <div className={styles.TdRow}>
            <Div className={styles.TdColInfo} translateId={label('restSumToPay')} />
            <div className={styles.TdColResult}>{formatNumber(total, { ccy })}</div>
          </div> */}
          <div className={styles.TdRow}>
            <Div className={styles.TdColInfo} translateId={label('accountNumber')} />
            <div className={styles.TdColResult}>{account.cbAccountNo}</div>
          </div>
          {paymentType !== 'FullRepayment' && expectedPaymentsCount && (
            <div className={styles.TdRow}>
              <Div className={styles.TdColInfo} translateId={label('remainingPayments')} />
              <div className={styles.TdColResult}>{expectedPaymentsCount}</div>
            </div>
          )}
        </div>

        {paymentType !== 'FullRepayment' && !checkMessage && <Div className={styles.CalErrorDescription} translateId={label('calcError')} />}

        {signature.showSignatureBlock ? (
          this.renderSecurity()
        ) : (
            <div className={styles.CodeButtonContainer}>
              <Button onClick={this.handleConfirmMessage} translateId={label('continueBtn')} />
            </div>
          )}
      </div>
    );
  }
}

Step2.propTypes = {
  auth: PropTypes.object.isRequired,
  incomingApplication: PropTypes.object.isRequired,
  confirmPrepayApplication: PropTypes.func.isRequired,
  products: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  signature: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  accounts: PropTypes.array,
  isCreditHolidays: PropTypes.bool,
};

const mapStateToProps = ({ products, auth, signature, localize, ...state }) => ({
  incomingApplication: products.prepayment.incomingApplication,
  locale: getActiveLanguage(localize).code,
  products,
  auth,
  signature,
  accounts: accountsSelector(state),
});

const mapDispatchToProps = {
  confirmPrepayApplication,
  prepay2FA,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step2);
