import { createAction } from 'redux-act';
import { getTranslate } from 'react-localize-redux';
import sha256 from 'crypto-js/sha256';
import { hideNotification, showWarnNotification } from '../notification';

import { labelMaker, get, getErrorMessage } from 'shared/utils';
import { hideOverlay } from '../overlay';
import {
  fetchRegistrationSettingsReq,
  sendRegistrationSmsCodeReq,
  checkRegistrationSmsCodeReq,
  registerUserReq,
  setUserProtectionReq,
} from './signupRequests';

// import { newLogin } from 'actions/auth';
import { goTo } from 'actions/routes';

export const fetchRegistrationSettingsStart = createAction('FETCH_REGISTRATION_SETTINGS_START');
export const fetchRegistrationSettingsSuccess = createAction('FETCH_REGISTRATION_SETTINGS_SUCCESS');
export const fetchRegistrationSettingsFailure = createAction('FETCH_REGISTRATION_SETTINGS_FAILURE');

export const sendRegistrationSmsCodeStart = createAction('SEND_REGISTRATION_SMS_CODE_START');
export const sendRegistrationSmsCodeSuccess = createAction('SEND_REGISTRATION_SMS_CODE_SUCCESS');
export const sendRegistrationSmsCodeFailure = createAction('SEND_REGISTRATION_SMS_CODE_FAILURE');

export const checkRegistrationCodeStart = createAction('CHECK_REGISTRATION_CODE_START');
export const checkRegistrationCodeSuccess = createAction('CHECK_REGISTRATION_CODE_SUCCESS');
export const checkRegistrationCodeFailure = createAction('CHECK_REGISTRATION_CODE_FAILURE');

export const registerUserStart = createAction('REGISTER_USER_START');
export const registerUserSuccess = createAction('REGISTER_USER_SUCCESS');
export const registerUserFailure = createAction('REGISTER_USER_FAILURE');

export const setUserProtectionStart = createAction('SET_USER_PROTECTION_START');
export const setUserProtectionSuccess = createAction('SET_USER_PROTECTION_SUCCESS');
export const setUserProtectionFailure = createAction('SET_USER_PROTECTION_FAILURE');

export const cancelRegistration = createAction('CANCEL_REGISTRATION');

export const setStep = createAction('SET_STEP');
export const resetStep = createAction('RESET_STEP');

const lError = labelMaker('errorsGeneral');

// old getRegistrationOTP
export const fetchRegistrationSettings = () => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(fetchRegistrationSettingsStart());
    const response = await dispatch(fetchRegistrationSettingsReq());
    const error = get(response, 'error.message');
    if (error) {
      throw error;
    }
    const settings = get(response, 'response');

    if (settings) {
      dispatch(fetchRegistrationSettingsSuccess(settings));
      dispatch(hideNotification());
    } else {
      throw translate(lError('serviceUnavailable'));
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(fetchRegistrationSettingsFailure());
    dispatch(hideOverlay());
    dispatch(showWarnNotification(errMessage));
  }
};

// old getRegistrationOTP
export const sendRegistrationSmsCode = reqData => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(sendRegistrationSmsCodeStart());
    const response = await dispatch(sendRegistrationSmsCodeReq(reqData));
    const error = get(response, 'error.message');
    const settings = get(response, 'response');
    if (error) {
      throw error;
    }
    if (settings) {
      dispatch(sendRegistrationSmsCodeSuccess(settings));
      dispatch(hideNotification());
    } else if (response.error && response.error.message) {
      throw response.error.message;
    } else {
      throw translate(lError('serviceUnavailable'));
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(sendRegistrationSmsCodeFailure());
    dispatch(showWarnNotification(errMessage));
  }
};

export const checkRegistrationSmsCode = reqData => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(checkRegistrationCodeStart());
    const response = await dispatch(checkRegistrationSmsCodeReq(reqData));
    const error = get(response, 'error.message');
    const success = get(response, 'response.success');
    if (error) {
      throw error;
    }
    if (success) {
      dispatch(checkRegistrationCodeSuccess(success));
      dispatch(hideNotification());
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(checkRegistrationCodeFailure());
    dispatch(hideOverlay());
    dispatch(showWarnNotification(errMessage));
  }
};

export const registerUser = reqData => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(registerUserStart());
    const response = await dispatch(registerUserReq(reqData));
    const error = get(response, 'error.message');
    if (error) {
      throw error;
    }
    const success = get(response, 'response.success');
    if (success) {
      const { captchaId, captchaValue } = reqData;

      const authArgs = {
        login: reqData.login,
        password: reqData.password,
        goToProducts: false,
      };

      if (captchaId) {
        authArgs.captchaId = captchaId;
        authArgs.captchaValue = captchaValue;
      }

      dispatch(registerUserSuccess());
      dispatch(hideNotification());
      // dispatch(newLogin({ ...authArgs, without2Fa: true }));
      dispatch(goTo('/login'));

    } else {
      throw translate(lError('serviceUnavailable'));
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(registerUserFailure());
    dispatch(hideOverlay());
    dispatch(showWarnNotification(errMessage));
  }
};

export const setUserProtection = args => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(setUserProtectionStart());

    const { type, answer, question } = args;
    const isControlQuestion = type === 'controlQuestion';
    const isControlWord = type === 'controlWord';
    const isControlDate = type === 'controlDate';

    const reqData = {
      console: 'json',
    };

    const answerHashHex = sha256(answer.toUpperCase()).toString();

    if (isControlQuestion) {
      reqData.hashedAnswer = answerHashHex;
      reqData.securityQuestion = question;
      reqData.protectionType = '1';

      reqData.securityWord = '';
      reqData.date = '';
      reqData.tic = 0;
      reqData.schemeName = 'ADDPROTECTIONSIGN';
      reqData.action = 'save';
    }
    if (isControlWord) {
      reqData.securityWord = answer;
      reqData.protectionType = '2';

      reqData.date = '';
      reqData.securityQuestion = '';
      reqData.hashedAnswer = answerHashHex;
    }
    if (isControlDate) {
      reqData.date = answer;

      reqData.protectionType = '3';
      reqData.securityWord = '';
      reqData.securityQuestion = '';
      reqData.hashedAnswer = answerHashHex;
    }
    if (!isControlQuestion && !isControlWord && !isControlDate) {
      reqData.protectionType = '4';
      reqData.securityWord = '';
      reqData.securityQuestion = '';
      reqData.hashedAnswer = '';
    }

    const response = await dispatch(setUserProtectionReq(reqData));

    if (response.success) {
      dispatch(setUserProtectionSuccess());
      dispatch(goTo('/products'));
      return;
    }

    throw get(response, 'error', '');
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(setUserProtectionFailure());
    dispatch(hideOverlay());
    dispatch(showWarnNotification(errMessage));
  }
};
