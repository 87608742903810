import { push } from 'react-router-redux';

import { hideNotification } from './notification';

import { isObject } from 'shared/utils';

export const goTo = args => dispatch => {
  if (isObject(args)) {
    const { needHideNotification = true, to } = args;
    if (needHideNotification) {
      dispatch(hideNotification());
    }
    dispatch(push(to));
  } else {
    dispatch(hideNotification());
    dispatch(push(args));
  }
};
