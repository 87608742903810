import { createAction } from 'redux-act';
import { get } from 'shared/utils';
import { fetchCaptchaIdReq, fetchCaptchaImageReq } from './captchaRequests';

export const setCaptchaId = createAction('SET_CAPTCHA_ID');

export const fetchCaptchaIdStart = createAction('FETCH_CAPTCHA_ID_START');
export const fetchCaptchaIdSuccess = createAction('FETCH_CAPTCHA_ID_SUCCESS');
export const fetchCaptchaIdFailure = createAction('FETCH_CAPTCHA_ID_FAILURE');

export const fetchCaptchaImageStart = createAction('FETCH_CAPTCHA_IMAGE_START');
export const fetchCaptchaImageSuccess = createAction('FETCH_CAPTCHA_IMAGE_SUCCESS');
export const fetchCaptchaImageFailure = createAction('FETCH_CAPTCHA_IMAGE_FAILURE');

export const cancelCaptchaFetch = createAction('CANCEL_CAPTCHA_ID_FETCH');

export const setTempCaptchaRequire = createAction('SET_TEMP_CAPTCHA_REQUIRE');
export const resetTempCaptchaRequire = createAction('RESET_TEMP_CAPTCHA_REQUIRE');

export const resetCaptcha = createAction('RESET_CAPTCHA');

export const fetchCaptchaId = () => async (dispatch, getState) => {
  try {
    dispatch(fetchCaptchaIdStart());
    const response = await dispatch(fetchCaptchaIdReq());
    const captchaId = get(response, 'Captcha.IdCaptcha');
    if (captchaId) {
      dispatch(fetchCaptchaIdSuccess(captchaId));
      dispatch(fetchCaptchaImage(captchaId));
    } else {
      throw new Error();
    }
  } catch (err) {
    dispatch(fetchCaptchaIdFailure());
  }
};

export const fetchCaptchaImage = captchaId => async (dispatch, getState) => {
  try {
    dispatch(fetchCaptchaImageStart(captchaId));
    const response = await dispatch(fetchCaptchaImageReq(captchaId));
    const imageSrc = URL.createObjectURL(response);
    dispatch(fetchCaptchaImageSuccess(imageSrc));
  } catch (err) {
    dispatch(fetchCaptchaImageFailure());
  }
};
