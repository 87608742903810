import React from 'react';
import PropTypes from 'prop-types';

import styles from './Modal.module.scss';

const ModalHeader = ({ children,style = {} }) => <div style={style} className={styles.ModalHeader}>{children}</div>;


ModalHeader.propTypes = {
  children: PropTypes.node.isRequired,
  style:PropTypes.object
};

export default ModalHeader;
