import React from 'react';
import PropTypes from 'prop-types';

import styles from './LoginCarouselPaginator.module.scss';

const CarouselPagination = props => (
  <div className={styles.CarouselPageController}>
    <div onClick={props.previousSlide} className={styles.Arrow}>
      <div className={styles.ArrowLeft} />
    </div>
    <div className={styles.DotsContainer}>
      {Array.from(Array(props.slideCount)).map((_, i) => (
        <div
          onClick={() => props.goToSlide(i)}
          key={i}
          className={props.currentSlide === i ? styles.PaginationDotActive : styles.PaginationDot}
        />
      ))}
    </div>
    <div onClick={props.nextSlide} className={styles.Arrow}>
      <div className={styles.ArrowRight} />
    </div>
  </div>
);

CarouselPagination.propTypes = {
  currentSlide: PropTypes.number.isRequired,
  slideCount: PropTypes.number.isRequired,
  goToSlide: PropTypes.func.isRequired,
  nextSlide: PropTypes.func.isRequired,
  previousSlide: PropTypes.func.isRequired,
};

export default CarouselPagination;
