import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { showOverlay } from 'actions/overlay';
import styles from './index.module.scss';

class Testing extends React.Component {
  componentDidMount() {
    const { showOverlay } = this.props;
    showOverlay();
  }
  render() {
    return (
      <div>
        <div className={styles.Right} />
      </div>
    );
  }
}

const mapDispatchToProps = {
  showOverlay,
};

Testing.propTypes = {
  showOverlay: PropTypes.func.isRequired,
};
export default connect(
  null,
  mapDispatchToProps
)(Testing);
