import React, { Fragment } from 'react';

import { withNotification } from 'shared';
import Footer from 'components/Footer/Footer';
import Signup from 'components/Signup/Signup';

import styles from './index.module.scss';

const LoginPage = () => {
  return (
    <Fragment>
      <div className={styles.Page}>
        <Signup />
      </div>
      <Footer />
    </Fragment>
  );
};

export default withNotification(LoginPage);
