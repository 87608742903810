import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { push } from 'react-router-redux';
import { getActiveLanguage } from 'react-localize-redux';
import qs from 'qs';

import PaymentTable from './PaymentTable/PaymentTable';
import { Translation, DateRangeFilter, withErrorBoundary } from 'shared';
import Actions from '../components/Actions/Actions';
import {
  fetchSchedule,
  setActiveLoan,
  downloadFile,
  printSchedule,
  sendPdfFileToEmail,
} from 'actions/products';
import { goTo } from 'actions/routes';

import styles from './Schedule.module.scss';

import { shapeFields, getValidationErrorId } from 'shared/validators';
import { getProps as buildGetProps, labelMaker } from 'shared/utils';
import schedule from './options';

const lSchedule = labelMaker('products.schedule');

class Schedule extends Component {
  state = {
    fields: shapeFields({
      ...schedule,
    }),
  };

  queryDateFormat = 'YYYY-MM-DD';

  onChange = ({ value, name }, showError) => {
    const { fields } = this.state;
    const field = fields[name];

    const { isError } = field;

    const newField = { ...field, value, touched: true, isError: showError || isError };
    const errorId = getValidationErrorId(newField);

    this.setState({
      fields: {
        ...fields,
        [name]: {
          ...newField,
          errorId,
        },
      },
    });
  };

  isQueryParamsValid = ({ startDate, endDate }) => {
    const formattedStartDate = moment(startDate, this.queryDateFormat);
    const formattedEndDate = moment(endDate, this.queryDateFormat);

    return (
      formattedStartDate.isValid() &&
      formattedEndDate.isValid() &&
      formattedEndDate.diff(formattedStartDate, 'days' >= 0)
    );
  };

  // Changing by value
  onRangeChange = ({ name, value }, showError) => {
    this.onChange({ name, value }, showError);

    switch (value) {
      case 'year':
        const startDate = moment().format(this.queryDateFormat);
        const endDate = moment()
          .add(1, 'years')
          .format(this.queryDateFormat);

        this.reloadHistory({
          startDate,
          endDate,
        });
        this.props.history.push(`?${qs.stringify({ startDate, endDate })}`);
        break;
      case 'all':
        this.reloadHistory();
        this.props.history.push('?all');
        break;
      default:
        break;
    }
  };

  composeQueryParams = search => {
    const { startDate, endDate } =
      search[0] === '?' ? qs.parse(search.substr(1)) : qs.parse(search);

    return {
      startDate: moment(startDate, this.queryDateFormat).format(this.queryDateFormat),
      endDate: moment(endDate, this.queryDateFormat).format(this.queryDateFormat),
    };
  };

  composeDefaultQueryParams = () => {
    const startDate = moment().format(this.queryDateFormat);
    const endDate = moment()
      .add(1, 'years')
      .format(this.queryDateFormat);
    return {
      startDate: startDate,
      endDate: endDate,
    };
  };

  isParamsByYear = ({ startDate, endDate }) => {
    const startDateParam = moment().format(this.queryDateFormat);
    const endDateParam = moment()
      .add(1, 'years')
      .format(this.queryDateFormat);

    return startDateParam === startDate && endDateParam === endDate;
  };

  reloadHistory = ({ startDate, endDate } = {}) => {
    let params = {};

    if (startDate && startDate) {
      params = {
        dateWith: startDate,
        dateOn: endDate,
      };
    }

    this.props.fetchSchedule({ id: this.props.credit.contractRef, ...params });
  };

  componentDidMount() {
    const { location, history } = this.props;
    const { fields } = this.state;
    const queryParams = this.composeQueryParams(location.search);

    if (this.isQueryParamsValid(queryParams)) {
      // Setting filter in accordance with query params
      if (this.isParamsByYear(queryParams)) {
        this.setState({
          fields: {
            ...fields,
            periodFilter: {
              ...fields.periodFilter,
              value: 'year',
            },
          },
        });
      } else {
        this.setState({
          fields: {
            ...fields,
            periodFilter: {
              ...fields.periodFilter,
              value: 'period',
            },
          },
        });
      }
      this.reloadHistory(queryParams);
    } else {
      const defaultHistoryParams = this.composeDefaultQueryParams();
      this.setState({
        fields: {
          ...fields,
          periodFilter: {
            ...fields.periodFilter,
            value: 'year',
          },
        },
      });

      this.reloadHistory(defaultHistoryParams);

      history.push(`?${qs.stringify(defaultHistoryParams)}`);
    }
  }

  render() {
    const { credit, downloadFile, printSchedule, sendPdfFileToEmail, lang } = this.props;
    const { fields } = this.state;

    const getProps = buildGetProps({ onChange: this.onChange, fields });

    return (
      <Fragment>
        <div className={styles.Body}>
          <div className={styles.Content}>
            {credit && credit?.loanHolidaysInfo?.messageFull && (
              <div>{credit?.loanHolidaysInfo?.messageFull}</div>
            )}
            {credit && credit.schedule && !credit?.loanHolidaysInfo?.messageFull ? (
              <>
                <div className={styles.PaymentSchedule}>
                  <div className={styles.ScheduleHeader}>
                    <Translation.Div
                      className={styles.ScheduleTitle}
                      translateId={lSchedule('paymentSchedule')}
                    />
                    <DateRangeFilter
                      {...getProps('periodFilter')}
                      onChange={this.onRangeChange}
                      lang={lang}
                    />
                  </div>
                  <PaymentTable />
                </div>
                <div className={styles.PaymentActions}>
                  <Actions
                    titleTranslateId={lSchedule('paymentScheduleActions')}
                    onSave={() => downloadFile(credit.contractRef)}
                    onPrint={() => printSchedule(credit.contractRef)}
                    onSend={() =>
                      sendPdfFileToEmail({ section: 'loans', contractRef: credit.contractRef })
                    }
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </Fragment>
    );
  }
}

Schedule.propTypes = {
  goTo: PropTypes.func.isRequired,
  fetchSchedule: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  setActiveLoan: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  printSchedule: PropTypes.func.isRequired,
  sendPdfFileToEmail: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  credit: PropTypes.object,
  lang: PropTypes.string.isRequired,
};

const mapStateToProps = ({ products, localize }) => ({
  credit: products.credit,
  lang: getActiveLanguage(localize).code,
});

const mapDispatchToProps = {
  goTo,
  fetchSchedule,
  setActiveLoan,
  downloadFile,
  printSchedule,
  sendPdfFileToEmail,
  push,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withErrorBoundary(Schedule, 'Products/Loans/Schedule'))
);
