import { createReducer } from 'redux-act';

import {
  fetchRegistrationSettingsStart,
  fetchRegistrationSettingsSuccess,
  fetchRegistrationSettingsFailure,
  //
  sendRegistrationSmsCodeStart,
  sendRegistrationSmsCodeSuccess,
  sendRegistrationSmsCodeFailure,
  //
  checkRegistrationCodeStart,
  checkRegistrationCodeSuccess,
  checkRegistrationCodeFailure,
  //
  registerUserStart,
  registerUserSuccess,
  registerUserFailure,
  //
  setUserProtectionStart,
  setUserProtectionSuccess,
  setUserProtectionFailure,
  //
  setStep,
  resetStep,
} from 'actions/signup';

const defaultState = {
  step: 1,

  passwordMapId: '',
  passwordMap: '',
  confirmedPasswordMapId: '',
  confirmedPasswordMap: '',

  smsPhone: '',
  sms: '',
  cid: '',
  egp4: '',
  error: '',

  isRegistered: false,
  isRequestPending: false,
  disabled: false,
};

const signup = createReducer(
  {
    [setStep]: (state, step) => ({
      ...state,
      step,
    }),

    [resetStep]: state => ({
      ...state,
      step: 1,
    }),

    [fetchRegistrationSettingsStart]: (state, payload) => {
      return {
        ...state,

        isRequestPending: true,
      };
    },
    [fetchRegistrationSettingsSuccess]: (state, payload) => {
      const {
        MapID_newpass,
        PassMap_newpass,
        MapID_confirmnewpass,
        PassMap_confirmnewpass,
      } = payload;

      return {
        ...state,
        passwordMapId: MapID_newpass,
        passwordMap: PassMap_newpass,
        confirmedPasswordMapId: MapID_confirmnewpass,
        confirmedPasswordMap: PassMap_confirmnewpass,

        isRequestPending: false,
        disabled: false,
      };
    },
    [fetchRegistrationSettingsFailure]: state => {
      return {
        ...state,

        isRequestPending: false,
        disabled: true,
      };
    },

    [sendRegistrationSmsCodeStart]: state => {
      return {
        ...state,
        isRequestPending: true,
      };
    },
    [sendRegistrationSmsCodeSuccess]: (state, payload) => {
      const { smsPhone, cid, egp4 } = payload;

      return {
        ...state,

        smsPhone,
        cid,
        egp4,

        step: 2,
        isRequestPending: false,
      };
    },
    [sendRegistrationSmsCodeFailure]: state => {
      return {
        ...state,
        isRequestPending: false,
      };
    },

    [checkRegistrationCodeStart]: state => {
      return {
        ...state,
        isRequestPending: true,
      };
    },
    [checkRegistrationCodeSuccess]: (state, { sms }) => {
      return {
        ...state,

        sms,
        step: 3,
        isRequestPending: false,
      };
    },
    [checkRegistrationCodeFailure]: state => {
      return {
        ...state,
        isRequestPending: false,
      };
    },

    [registerUserStart]: state => {
      return {
        ...state,
        isRequestPending: true,
      };
    },
    [registerUserSuccess]: (state, payload) => {
      return {
        ...state,

        isRegistered: true,
        isRequestPending: false,
      };
    },
    [registerUserFailure]: state => {
      return {
        ...state,
        isRequestPending: false,
      };
    },
    //
    [setUserProtectionStart]: state => {
      return {
        ...state,
        isRequestPending: true,
      };
    },
    [setUserProtectionSuccess]: state => {
      return {
        ...state,

        isRequestPending: false,
      };
    },
    [setUserProtectionFailure]: state => {
      return {
        ...state,
        isRequestPending: false,
      };
    },
  },
  defaultState
);

export default signup;
