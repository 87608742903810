import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './Toggle.module.scss';

const Toggle = props => {
  const { disabled, input, small, red } = props;

  const onClick = () => {
    if (!disabled) {
      props.input.onChange(!input.value);
    }
  };

  return (
    <div
      onClick={onClick}
      className={cn([
        styles.Container,
        {
          [styles.ActiveContainer]: input.value,
          [styles.DisabledContainer]: disabled,
          [styles.SmallContainer]: small,
          [styles.ActiveSmallContainer]: input.value && small,
          [styles.ActiveRedContainer]: input.value && red,
        },
      ])}
    >
      <div className={cn(styles.Slider, { [styles.SmallSlider]: props.small })} />
    </div>
  );
};

Toggle.propTypes = {
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  red: PropTypes.bool,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default Toggle;
