import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import styles from '../../Personal.module.scss';
import { labelMaker } from 'shared/utils';
import { Translation, Button } from 'shared';
const label = labelMaker('profile.personal');
const labelBtns = labelMaker('profile.btns');

const EmailBlock = ({
  fieldsOptions,
  emailProps,
  emailData,
  showChangeEmailBlock,
  checkEmailStart,
  checkEmailCodeStart,
  cancelEmailChanges,
  formErrors,
}) => {
  return (
    <Fragment>
      <Translation.H2 className={styles.Title} translateId={label('email')} />
      <Translation.H4 className={styles.Description} translateId={label('emailInfo')} />
      <div className={styles.InputContainer}>
        <Field {...fieldsOptions.email} {...emailProps} />
      </div>
      {showChangeEmailBlock && (
        <Fragment>
          <div className={styles.InputContainer}>
            <Field
              {...fieldsOptions.newEmail}
              disabled={emailData.isLoading || emailData.isCodeSend}
            />
          </div>
          {emailData.isCodeSend && (
            <div className={styles.InputContainer}>
              <Field {...fieldsOptions.emailCode} disabled={emailData.isLoading} />
            </div>
          )}
          <div className={styles.ButtonsContainer}>
            <div className={styles.Button}>
              <Button
                round
                translateId={labelBtns('cancel')}
                theme="white"
                onClick={cancelEmailChanges}
              />
            </div>
            <div className={styles.Button}>
              <Button
                round
                disabled={!!formErrors.newEmail}
                showLoader={emailData.isLoading}
                translateId={!emailData.isCodeSend ? labelBtns('save') : labelBtns('send')}
                onClick={!emailData.isCodeSend ? checkEmailStart : checkEmailCodeStart}
              />
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

EmailBlock.propTypes = {
  fieldsOptions: PropTypes.object.isRequired,
  emailProps: PropTypes.object,
  emailData: PropTypes.object.isRequired,
  showChangeEmailBlock: PropTypes.bool.isRequired,
  checkEmailStart: PropTypes.func.isRequired,
  checkEmailCodeStart: PropTypes.func.isRequired,
  cancelEmailChanges: PropTypes.func.isRequired,
  formErrors: PropTypes.object.isRequired,
};

export default EmailBlock;
