import { timeout } from 'shared/utils';

export const dadataApiKeyReqStub = async withError => {
  const dadataApiKeyResponse = '35bae7d9c1d6e6ab711fdfa9ca2a95bdc7f51042';

  const dadataApiKeyError = {
    code: 500,
    message: 'Ошибка',
  };

  await timeout(200);
  if (!withError) {
    console.info('dadataApiKeyReqStub: ', dadataApiKeyResponse);
    return Promise.resolve(dadataApiKeyResponse);
  } else {
    console.info('dadataApiKeyReqStub: ', dadataApiKeyError);
    return Promise.resolve(dadataApiKeyError);
  }
};
