import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import qs from 'qs';
import { push } from 'react-router-redux';
import { withRouter } from 'react-router-dom';
import { getActiveLanguage } from 'react-localize-redux';

import { shapeFields, getValidationErrorId } from 'shared/validators';
import { /*RoundSelect,*/ Translation, DateRangeFilter, withErrorBoundary } from 'shared';
import PaymentsHistory from '../components/HistoryList/HistoryList';
import Actions from '../components/Actions/Actions';
import { accountsSelector } from 'store/selectors/accounts';

import styles from './History.module.scss';
import history from './options';

import { getProps as buildGetProps, labelMaker } from 'shared/utils';
import {
  initializeLoanHistory,
  fetchLoanHistory,
  downloadHistory,
  sendPdfFileToEmail,
} from 'actions/products';
import { printOperationsHistory } from 'actions/operationsHistory';

const label = labelMaker('products.history');

class History extends Component {
  state = {
    fields: shapeFields({
      ...history,
    }),
  };

  queryDateFormat = 'YYYY-MM-DD';

  onChange = ({ value, name }, showError) => {
    const { fields } = this.state;
    const field = fields[name];

    const { isError } = field;

    const newField = { ...field, value, touched: true, isError: showError || isError };
    const errorId = getValidationErrorId(newField);

    this.setState({
      fields: {
        ...fields,
        [name]: {
          ...newField,
          errorId,
        },
      },
    });
  };

  // Changing by value
  onRangeChange = ({ name, value }, showError) => {
    this.onChange({ name, value }, showError);

    // For period reloading responsible onManualRangeChange
    if (value === 'period') {
      return;
    }

    let startDate;
    let endDate = moment().format(this.queryDateFormat);

    switch (value) {
      case 'week':
        startDate = moment()
          .subtract(1, 'week')
          .format(this.queryDateFormat);
        break;
      case 'month':
        startDate = moment()
          .subtract(1, 'month')
          .format(this.queryDateFormat);
        break;
      case 'year':
        startDate = moment()
          .subtract(1, 'year')
          .format(this.queryDateFormat);
        break;
      default:
        break;
    }

    this.reloadHistory({
      startDate,
      endDate,
    });
    this.props.history.push(`?${qs.stringify({ startDate, endDate })}`);
  };

  // Changing by calendar period
  onManualRangeChange = ({ startDate, endDate }) => {
    const startDateValue = moment(startDate, this.queryDateFormat).format(this.queryDateFormat);
    const endDateValue = moment(endDate, this.queryDateFormat).format(this.queryDateFormat);

    const params = qs.stringify({
      startDate: startDateValue,
      endDate: endDateValue,
    });

    this.props.push(`?${params}`);
    this.reloadHistory({
      startDate: startDateValue,
      endDate: endDateValue,
    });
  };

  isQueryParamsValid = ({ startDate, endDate }) => {
    const formattedStartDate = moment(startDate, this.queryDateFormat);
    const formattedEndDate = moment(endDate, this.queryDateFormat);

    return (
      formattedStartDate.isValid() &&
      formattedEndDate.isValid() &&
      formattedEndDate.diff(formattedStartDate, 'days' >= 0)
    );
  };

  composeQueryParams = search => {
    const { startDate, endDate } =
      search[0] === '?' ? qs.parse(search.substr(1)) : qs.parse(search);

    return {
      startDate: moment(startDate, this.queryDateFormat).format(this.queryDateFormat),
      endDate: moment(endDate, this.queryDateFormat).format(this.queryDateFormat),
    };
  };

  isParamsByWeek = ({ startDate, endDate }) => {
    const lastWeekStartDate = moment()
      .subtract(1, 'week')
      .format(this.queryDateFormat);
    const today = moment().format(this.queryDateFormat);

    return lastWeekStartDate === startDate && today === endDate;
  };

  composeDefaultQueryParams = () => ({
    startDate: moment()
      .subtract(1, 'week')
      .format(this.queryDateFormat),
    endDate: moment().format(this.queryDateFormat),
  });

  componentDidMount() {
    const { location, history } = this.props;
    const { fields } = this.state;
    const queryParams = this.composeQueryParams(location.search);

    if (this.isQueryParamsValid(queryParams)) {
      // Setting filter in accordance with query params
      if (this.isParamsByWeek(queryParams)) {
        this.setState({
          fields: {
            ...fields,
            periodFilter: {
              ...fields.periodFilter,
              value: 'week',
            },
          },
        });
      } else {
        this.setState({
          fields: {
            ...fields,
            periodFilter: {
              ...fields.periodFilter,
              value: 'period',
            },
          },
        });
      }
      this.props.initializeLoanHistory(queryParams);
    } else {
      const defaultHistoryParams = this.composeDefaultQueryParams();
      this.setState({
        fields: {
          ...fields,
          periodFilter: {
            ...fields.periodFilter,
            value: 'week',
          },
        },
      });
      this.props.initializeLoanHistory(defaultHistoryParams);

      history.push(`?${qs.stringify(defaultHistoryParams)}`);
    }
  }

  reloadHistory = ({ startDate, endDate }) => {
    const { fetchLoanHistory } = this.props;

    fetchLoanHistory({ dateWith: startDate, dateOn: endDate });
  };

  onChange = ({ value, name }, showError) => {
    const { fields } = this.state;
    const field = fields[name];

    const { isError } = field;

    const newField = { ...field, value, touched: true, isError: showError || isError };
    const errorId = getValidationErrorId(newField);

    this.setState({
      fields: {
        ...fields,
        [name]: {
          ...newField,
          errorId,
        },
      },
    });
  };

  render() {
    const { fields } = this.state;
    const {
      historyList,
      downloadHistory,
      printOperationsHistory,
      sendPdfFileToEmail,
      products,
      location,
      accounts,
      lang,
    } = this.props;

    if (!Array.isArray(accounts)) {
      return null;
    }

    const creditAccount = accounts.find(acc => acc.accountNo === products.credit.drsettlementacc);
    const defaultAccount = accounts[0] ? accounts[0] : {};

    const queryParams = this.composeQueryParams(location.search);
    const range = this.isQueryParamsValid(queryParams)
      ? this.composeQueryParams(location.search)
      : this.composeDefaultQueryParams();

    const documentArgs = {
      accountNumber: creditAccount ? creditAccount.accountNo : defaultAccount.accountNo,
      fromDate: range.startDate,
      branch: creditAccount ? creditAccount.branch : defaultAccount.branch,
      toDate: range.endDate,
    };

    const transitions = historyList.movements ? historyList.movements : [];

    const getProps = buildGetProps({ onChange: this.onChange, fields });

    return historyList.isLoaded ? (
      <Fragment>
        <div className={styles.Body}>
          <div className={styles.Content}>
            <div className={styles.History}>
              <div className={styles.HistoryHeader}>
                <div>
                  <Translation.Div
                    className={styles.HistoryTitle}
                    translateId={label('historyTitle')}
                  />
                  <Translation.Div
                    className={styles.HistorySubtitle}
                    translateId={label('onAccount')}
                    data={{
                      account: creditAccount
                        ? creditAccount.cbAccountNo
                        : defaultAccount.cbAccountNo,
                    }}
                  />
                </div>
                <div className={styles.Filters}>
                  <div className={styles.ShortFilterContainer}>
                    <DateRangeFilter
                      {...getProps('periodFilter')}
                      onManualRangeChange={this.onManualRangeChange}
                      onChange={this.onRangeChange}
                      range={{
                        startDate: moment(range.startDate, this.queryDateFormat).toDate(),
                        endDate: moment(range.endDate, this.queryDateFormat).toDate(),
                      }}
                      lang={lang}
                    />
                  </div>
                  {/* <div className={styles.FilterContainer}>
                    <RoundSelect {...getProps('typeFilter')} data={history.typeFilter.data} />
                  </div> */}
                </div>
              </div>
              <PaymentsHistory transitions={transitions} />
            </div>
            <div className={styles.HistoryActions}>
              <Actions
                onSave={() => downloadHistory(documentArgs)}
                onPrint={() => printOperationsHistory(documentArgs)}
                onSend={() =>
                  sendPdfFileToEmail({ section: 'operations-history', ...documentArgs })
                }
                titleTranslateId={label('historyActionsTitle')}
              />
            </div>
          </div>
        </div>
      </Fragment>
    ) : null;
  }
}

History.propTypes = {
  historyList: PropTypes.object,
  products: PropTypes.object.isRequired,
  initializeLoanHistory: PropTypes.func.isRequired,
  downloadHistory: PropTypes.func.isRequired,
  printOperationsHistory: PropTypes.func.isRequired,
  sendPdfFileToEmail: PropTypes.func.isRequired,
  fetchLoanHistory: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  accounts: PropTypes.array,
};

const mapStateToProps = state => ({
  historyList: state.products.history,
  lang: getActiveLanguage(state.localize).code,
  products: state.products,
  accounts: accountsSelector(state),
});

const mapDispatchToProps = {
  initializeLoanHistory,
  downloadHistory,
  printOperationsHistory,
  sendPdfFileToEmail,
  fetchLoanHistory,
  push,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withErrorBoundary(History, 'Products/Loans/History'))
);
