import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { Translation, InputStatus, InputError, InputAction } from '../../';

import styles from './Input.module.scss';

import { calculateWidth, isActiveButtonCode, isNumericCode } from 'shared/utils';

class Input extends Component {
  state = {
    showStatus: false,
    hasFocus: false,
  };

  onFocus = () => this.setState({ hasFocus: true, showStatus: false });

  onBlur = ({ target }) => {
    const { onBlur, onChange } = this.props;
    const { name, value } = target;

    if (onBlur) {
      this.setState({
        showStatus: true,
        hasFocus: false,
      });
      onBlur({ name, value }, true);
    } else {
      this.setState({
        showStatus: true,
        hasFocus: false,
      });
      onChange({ name, value }, true);
    }
  };

  onKeyDown = e => {
    if (this.props.onlyNumbers) {
      const { keyCode: code, shiftKey } = e;
      if ((!isActiveButtonCode(code) && !isNumericCode(code)) || shiftKey) {
        e.preventDefault();
      }
    }
  };

  onChange = ({ target }, isError) => {
    const { onChange } = this.props;
    const { name, value } = target;

    onChange({ value, name }, isError);
  };

  render() {
    const {
      actionId,
      disabled,
      errorId,
      handleAction,
      isError,
      labelId,
      name,
      statusElement,
      touched,
      translate,
      type,
      value,
      label,
    } = this.props;
    const { hasFocus, showStatus } = this.state;

    const isErrorVisible = touched && errorId && !disabled && isError;
    const isInputFocused = hasFocus && !disabled;
    const isStatusSuccess = statusElement && touched && !errorId && showStatus;

    const error = isErrorVisible ? errorId : null;

    const externalBorderClassName = cn(styles.ExternalBorder, {
      [styles.DisabledExternalBorder]: disabled,
      [styles.FocusedExternalBorder]: isInputFocused,
      [styles.ErrorExternalBorder]: isErrorVisible,
    });
    const internalBorderClassName = cn(styles.InternalBorder, {
      [styles.FocusedInternalBorder]: isInputFocused,
      [styles.ErrorInternalBorder]: isErrorVisible,
    });
    const labelClassName = cn(
      styles.Label,
      { [styles.FocusedLabel]: hasFocus || value },
      { [styles.DisabledLabel]: disabled }
    );

    const standardRightPadding = 16;
    const rightPadding = actionId
      ? calculateWidth(translate(actionId)) + standardRightPadding
      : standardRightPadding;

    return (
      <div className={styles.Container}>
        {label ? (
          <div className={labelClassName}>{label}</div>
        ) : (
          <Translation.Div className={labelClassName} translateId={labelId} />
        )}
        <div className={externalBorderClassName}>
          <div className={internalBorderClassName}>
            <input
              autoComplete="off"
              className={styles.Input}
              disabled={disabled}
              name={name}
              onChange={this.onChange}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onKeyDown={this.onKeyDown}
              style={{
                paddingRight: rightPadding,
              }}
              type={type}
              value={value}
            />
          </div>
        </div>
        <InputStatus success={isStatusSuccess} />
        <InputAction actionId={actionId} handleAction={handleAction} />
        <InputError errorId={error} />
      </div>
    );
  }
}

Input.propTypes = {
  actionId: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  errorId: PropTypes.string,
  handleAction: PropTypes.func,
  isError: PropTypes.bool,
  labelId: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  statusElement: PropTypes.bool,
  touched: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  type: PropTypes.string,
  value: PropTypes.any,
  onlyNumbers: PropTypes.bool,
};

Input.defaultProps = {
  handleAction: () => {},
  onChange: () => {},
  type: 'text',
  value: '',
  onlyNumbers: false,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(Input);
