import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import moment from 'moment';

import { paymentNextStep, resetPaymentFormStart } from 'actions/newPayment';
import TransitionTemplate from '../../../../components/TransitionTemplate/TransitionTemplate';
import { Button, Translation } from 'shared';
import { labelMaker, formatNumber, getLocaleDateFormat } from 'shared/utils';

import styles from './Step3.module.scss';

const label = labelMaker('payments');

class Step3 extends Component {
  handleSubmit = () => {
    const { paymentNextStep, resetPaymentFormStart } = this.props;
    paymentNextStep();
    resetPaymentFormStart();
  };

  render() {
    const { Div } = Translation;
    const { products, translate, locale } = this.props;
    const {
      accountDebet,
      accountCredit,
      dateAndTime,
      currCode,
      amount,
    } = products.payment.incomingPayment;
    const dateFormat = getLocaleDateFormat(locale);

    const transition = {
      title: `${translate(label('transferTo'))} ${accountCredit}`,
      info: accountDebet,
      date: moment(dateAndTime).format(dateFormat),
      amount: formatNumber(amount, { currCode }),
    };

    return (
      <Fragment>
        <div className={styles.StepTitleContainer}>
          <Div className={styles.StepTitle} translateId={label('paymentAccepted')} />
          <div className={styles.StepImage} />
        </div>
        {/* <Div
          className={styles.PaymentListTitle}
          translateId={label('paymentAcceptedDescription')}
        /> */}
        <div className={styles.PaymentsListContainer}>
          <TransitionTemplate transition={transition} theme="white" />
        </div>
        <div className={styles.FillActionButton}>
          <Button onClick={this.handleSubmit} theme="white" translateId={label('completeBtn')} />
        </div>
      </Fragment>
    );
  }
}

Step3.propTypes = {
  paymentNextStep: PropTypes.func.isRequired,
  resetPaymentFormStart: PropTypes.func.isRequired,
  products: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = ({ products, localize }) => ({
  products,
  translate: getTranslate(localize),
  locale: getActiveLanguage(localize).code,
});

const mapDispatchToProps = {
  paymentNextStep,
  resetPaymentFormStart,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step3);
