import { createAction } from 'redux-act';
import { getTranslate } from 'react-localize-redux';
import { getErrorMessage, labelMaker, get } from 'shared/utils';
import { showWarnNotification } from '../notification';

import { fetchSettingsReq } from './settingsRequests';

const lError = labelMaker('errorsGeneral');

export const fetchSettingsStart = createAction('FETCH_SETTINGS_START');
export const fetchSettingsSuccess = createAction('FETCH_SETTINGS_SUCCESS');
export const fetchSettingsFailure = createAction('FETCH_SETTINGS_FAILURE');

export const fetchSettings = () => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(fetchSettingsStart());
    const response = await dispatch(fetchSettingsReq());
    const sdpSettings = response.find(setting => setting.title === 'SDP_SHOW');
    const minRepaySettings = response.find(setting => setting.title === 'MIN_REPAY');
    const workdayCutOffSettings = response.find(setting => setting.title === 'WORKDAY_CUTOFF');
    const weekendCutOffSettings = response.find(setting => setting.title === 'WEEKEND_CUTOFF');
    const envInfoSettings = response.find(setting => setting.title === 'ENV_INFO'); // one of: inta, intc, pp, prod; default: prod;
    const prevAuthNotifyEnabledSettings = response.find(setting => setting.title === 'PREV_AUTH_NOTIFY_ENABLED');
    if (Array.isArray(response)) {
      // settings for menu
      const isEngModuleVisible = get(sdpSettings, 'textEn', '').indexOf('"visible":"true"') > 0;
      const isRuModuleVisible = get(sdpSettings, 'textRu', '').indexOf('"visible":"true"') > 0;

      // settings for prepay application
      const isEngMinRepay = get(minRepaySettings, 'textEn', '0');
      const isRuMinRepay = get(minRepaySettings, 'textRu', '0');
      const engWorkdayCutOff = get(workdayCutOffSettings, 'textEn', '17:00+03:00');
      const ruWorkdayCutOff = get(workdayCutOffSettings, 'textRu', '17:00+03:00');
      const engWeekendCutOff = get(weekendCutOffSettings, 'textEn', '16:00+03:00');
      const ruWeekendCutOff = get(weekendCutOffSettings, 'textRu', '16:00+03:00');
      const engEnvInfo = get(envInfoSettings, 'textEn', 'prod');
      const ruEnvInfo = get(envInfoSettings, 'textRu', 'prod');
      const engPrevAuthNotifyEnabled = get(prevAuthNotifyEnabledSettings, 'textEn', 'FALSE');
      const ruPrevAuthNotifyEnabled = get(prevAuthNotifyEnabledSettings, 'textRu', 'FALSE');
      dispatch(
        fetchSettingsSuccess({
          isModuleVisible: {
            ru: isRuModuleVisible && isEngModuleVisible,
            en: isRuModuleVisible && isEngModuleVisible,
          },
          minRepay: {
            ru: Number(isRuMinRepay),
            en: Number(isEngMinRepay),
          },
          workdayCutOff: {
            ru: ruWorkdayCutOff,
            en: engWorkdayCutOff,
          },
          weekendCutOff: {
            ru: ruWeekendCutOff,
            en: engWeekendCutOff,
          },
          envInfo: {
            ru: ruEnvInfo,
            en: engEnvInfo,
          },
          prevAuthNotifyEnabled: {
            ru: ruPrevAuthNotifyEnabled === 'TRUE',
            en: engPrevAuthNotifyEnabled === 'TRUE',
          },
        })
      );
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(fetchSettingsFailure());
  }
};
