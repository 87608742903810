import { buildUrlParams, encodeDataURI } from 'shared/utils';
import apiServicesConstructor from 'services/apiService';
import { fetchPayDocumentReqStub, fetchPaymentSignatureReqStub } from './paymentsStubs';

const isDevelopment = process.env.NODE_ENV === 'development';
const useGlobalStubs = process.env.REACT_APP_USE_STUBS === 'true';

export const fetchPaymentSignatureReq = (reqData, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await fetchPaymentSignatureReqStub(withError);
  }

  const data = {
    amount: reqData.amount,
    credit_number: reqData.creditNumber,
    client_ref: reqData.clientRef,
    email: reqData.email,
    fio: reqData.fio,
  };

  const reqParams = {
    url: '/webapi-1.0/payment-signature',
    headers: { 'Content-Type': 'application/json' },
    data,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchPayDocumentReq = (useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await fetchPayDocumentReqStub(withError);
  }

  const urlParams = buildUrlParams(
    encodeDataURI({
      DOCID: 1126,
      console: 'json',
      pid: '',
      idr: '',
      isPGS: '',
      isPDR: '',
    })
  );

  const reqParams = {
    url: `/webapi-1.0/bss/payDocument?${urlParams}`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};
