import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Input, Translation, Button } from 'shared';

import fieldsOption from '../../options';

import {
  reduxFieldRequired,
  reduxFieldCustomMaxLength,
  reduxFieldCustomMinLength,
} from 'shared/validators';

import styles from '../../Security.module.scss';

import { labelMaker } from 'shared/utils';

const lSecurity = labelMaker('profile.security');
const actionId = lSecurity('CHANGE');

class ChangePassword extends React.Component {
  validateMax = reduxFieldCustomMaxLength(255);
  validateMin = reduxFieldCustomMinLength(8);
  validateEqualPasswords = (value, values) => {
    const { PassMaxLength, PassMinLength } = this.props.passwordSettings;
    if (
      values.newPassword &&
      values.confirmNewPassword &&
      ((values.newPassword.length <= PassMaxLength && values.newPassword.length >= PassMinLength) ||
        (values.confirmNewPassword.length <= PassMaxLength &&
          values.confirmNewPassword.length >= PassMinLength)) &&
      values.newPassword !== values.confirmNewPassword
    ) {
      return lSecurity('notEqualPasswords');
    }
  };

  componentDidUpdate(prevProps, prevState) {
    // renew validation rule on passwordSettings loading to prevent re-rendering
    if (prevProps.passwordSettings !== this.props.passwordSettings) {
      this.validateMax = reduxFieldCustomMaxLength(this.props.passwordSettings.PassMaxLength);
      this.validateMin = reduxFieldCustomMinLength(this.props.passwordSettings.PassMinLength);
    }
  }

  render() {
    const {
      showChangePasswordBlock,
      fetchChangePasswordSettings,
      cancelChangePassword,
      handleSubmit,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Translation.Div className={styles.BlockTitle} translateId={lSecurity('password')} />
        <Translation.Div
          className={styles.InputLabel}
          translateId={lSecurity('passwordToAccess')}
        />
        {!showChangePasswordBlock && (
          <div className={styles.InputContainer}>
            <Input
              type="password"
              {...fieldsOption.password}
              actionId={actionId}
              handleAction={fetchChangePasswordSettings}
            />
          </div>
        )}
        {showChangePasswordBlock && (
          <>
            <div className={styles.InputContainer}>
              <Field {...fieldsOption.currentPassword} validate={[reduxFieldRequired]} />
            </div>
            <div className={styles.InputContainer}>
              <Field
                {...fieldsOption.newPassword}
                validate={[
                  reduxFieldRequired,
                  this.validateEqualPasswords,
                  this.validateMax,
                  this.validateMin,
                ]}
              />
            </div>
            <div className={styles.InputContainer}>
              <Field
                {...fieldsOption.confirmNewPassword}
                validate={[
                  reduxFieldRequired,
                  this.validateEqualPasswords,
                  this.validateMax,
                  this.validateMin,
                ]}
              />
            </div>
            <div className={styles.ButtonsContainer}>
              <div className={styles.Button}>
                <Button
                  round
                  translateId={lSecurity('cancel')}
                  theme="white"
                  onClick={cancelChangePassword}
                />
              </div>
              <div className={styles.Button}>
                <Button type="submit" round translateId={lSecurity('save')} />
              </div>
            </div>
          </>
        )}
      </form>
    );
  }
}

ChangePassword.propTypes = {
  showChangePasswordBlock: PropTypes.bool.isRequired,
  fetchChangePasswordSettings: PropTypes.func.isRequired,
  cancelChangePassword: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  passwordSettings: PropTypes.shape({
    PassMaxLength: PropTypes.string,
    PassMinLength: PropTypes.string,
  }),
};

const form = 'securityChangePasswordForm';

export default reduxForm({
  form,
})(ChangePassword);
