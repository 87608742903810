import React from 'react';
import PropTypes from 'prop-types';

import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import Step3 from './Step3/Step3';
import Step4 from './Step4/Step4';

const Steps = ({ step, ...rest }) => {
  switch (step) {
    case 1:
      return <Step1 {...rest} />;
    case 2:
      return <Step2 {...rest} />;
    case 3:
      return <Step3 {...rest} />;
    case 4:
      return <Step4 {...rest} />;
    default:
      return null;
  }
};

Steps.propTypes = {
  step: PropTypes.number.isRequired,
};

export default Steps;
