import { createAction } from 'redux-act';
import { getTranslate } from 'react-localize-redux';
import { reset } from 'redux-form';

import { hideNotification, showWarnNotification } from '../notification';
import { showOverlay, hideOverlay } from '../overlay';
import { saveSecuritySignature, createSms } from '../signature';

import sha256 from 'crypto-js/sha256';

import { get, getEncryptedStr, getErrorMessage, labelMaker } from 'shared/utils';

import {
  fetchSecureSettingsReq,
  fetchChangePasswordSettingsReq,
  changeLoginReq,
  changePasswordReq,
  setProtectionReq,
  fetchChangeScratchCardSettingsReq,
  changeScratchCardReq,
} from './securityRequests';

const lError = labelMaker('errorsGeneral');

export const changeLoginError = createAction('CHANGE_LOGIN_ERROR');
export const changeLoginStart = createAction('CHANGE_LOGIN_START');
export const changeLoginSuccess = createAction('CHANGE_LOGIN_SUCCESS');
export const changePasswordError = createAction('PROFILE_CHANGE_PASSWORD_ERROR');
export const changePasswordStart = createAction('PROFILE_CHANGE_PASSWORD_START');
export const changePasswordSuccess = createAction('PROFILE_CHANGE_PASSWORD_SUCCESS');
export const changeScratchCardError = createAction('CHANGE_SCRATCH_CARD_ERROR');
export const changeScratchCardStart = createAction('CHANGE_SCRATCH_CARD_START');
export const changeScratchCardSuccess = createAction('CHANGE_SCRATCH_CARD_SUCCESS');
export const closeChangeProtectionSettings = createAction('HIDE_CHANGE_PROTECTION_SETTINGS');
export const confirmChangeProtection = createAction('CONFIRM_CHANGE_PROTECTION');
export const fetchSecureSettingsError = createAction('FETCH_SECURE_SETTINGS_ERROR');
export const fetchSecureSettingsStart = createAction('FETCH_SECURE_SETTINGS_START');
export const fetchSecureSettingsSuccess = createAction('FETCH_SECURE_SETTINGS_SUCCESS');
export const fetchChangePasswordSettingsError = createAction(
  'FETCH_CHANGE_PASSWORD_SETTINGS_ERROR'
);
export const fetchChangePasswordSettingsStart = createAction(
  'FETCH_CHANGE_PASSWORD_SETTINGS_START'
);
export const fetchChangePasswordSettingsSuccess = createAction(
  'FETCH_CHANGE_PASSWORD_SETTINGS_SUCCESS'
);
export const fetchChangeScratchCardSettingsError = createAction(
  'GET_CHANGE_SCRATCH_CARD_SETTINGS_ERROR'
);
export const fetchChangeScratchCardSettingsStart = createAction(
  'GET_CHANGE_SCRATCH_CARD_SETTINGS_START'
);
export const fetchChangeScratchCardSettingsSuccess = createAction(
  'GET_CHANGE_SCRATCH_CARD_SETTINGS_SUCCESS'
);
export const hideChangeBlocks = createAction('HIDE_CHANGE_BLOCKS');
export const hideChangePasswordSettings = createAction('HIDE_CHANGE_PASSWORD_SETTINGS');
export const hideChangeScratchCardBlock = createAction('HIDE_CHANGE_SCRATCH_CARD_BLOCK');
export const openChangeProtectionSettings = createAction('SHOW_CHANGE_PROTECTION_SETTINGS');
export const removeSign = createAction('REMOVE_SIGN');
export const reqOTPOnChangeProtectionError = createAction('REQUEST_OTP_ON_CHANGE_PROTECTION_ERROR');
export const reqOTPOnChangeProtectionStart = createAction('REQUEST_OTP_ON_CHANGE_PROTECTION_START');
export const reqOTPOnChangeProtectionSuccess = createAction(
  'REQUEST_OTP_ON_CHANGE_PROTECTION_SUCCESS'
);
export const setProtectionError = createAction('SET_PROTECTION_ERROR');
export const setProtectionStart = createAction('SET_PROTECTION_START');
export const setProtectionSuccess = createAction('SET_PROTECTION_SUCCESS');
export const showChangePasswordSettings = createAction('SHOW_CHANGE_PASSWORD_SETTINGS');
export const showChangeScratchCardBlock = createAction('SHOW_CHANGE_SCRATCH_CARD_BLOCK');
export const toggleChangeLoginBlock = createAction('TOGGLE_CHANGE_LOGIN_BLOCK');
export const updateScratchNum = createAction('UPDATE_SCRATCH_NUM');

export const disableSign = () => (dispatch, getState) => {
  const { auth, signature } = getState();
  const { localize } = getState();
  const translate = getTranslate(localize);
  if (!signature.settings) {
    return dispatch(showWarnNotification(translate(lError('serviceUnavailable'))));
  }

  try {
    dispatch(removeSign());

    if (auth.user.sign === '4') {
      dispatch(reqOTPOnChangeProtection());
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
  }
};

export const fetchChangePasswordSettings = () => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(fetchChangePasswordSettingsStart());
    dispatch(showOverlay());
    const settings = await dispatch(fetchChangePasswordSettingsReq());

    if (settings.config) {
      dispatch(fetchChangePasswordSettingsSuccess(settings.config));
      dispatch(showChangePasswordSettings());
      dispatch(hideNotification());
      dispatch(hideOverlay());
    } else if (settings.error) {
      const errMessage = get(settings, 'error.message');
      throw errMessage;
    } else {
      throw translate(lError('serviceUnavailable'));
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(fetchChangePasswordSettingsError());
    dispatch(hideOverlay());
  }
};

export const changePassword = args => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(fetchChangePasswordSettingsStart());
    dispatch(showOverlay());

    const { password, newPassword, confirmNewPassword } = args;
    const { profile } = getState();

    const settings = profile.security.passwordSettings;

    const {
      PassMapCurrentpass,
      MapIdCurrentpass,
      PassMapNewpass,
      MapIdNewpass,
      PassMapConfirmnewpass,
      MapIdConfirmnewpass,
    } = settings;

    const encrCurrentPass = getEncryptedStr(password, PassMapCurrentpass);
    const encrNewPass = getEncryptedStr(newPassword, PassMapNewpass);
    const encrConfirmNewPass = getEncryptedStr(confirmNewPassword, PassMapConfirmnewpass);

    const reqData = {
      console: 'JSON',
      usePassMap: 1,
      np: encrNewPass,
      cnp: encrConfirmNewPass,
      oldp: encrCurrentPass,
      mapIdNewPass: MapIdNewpass,
      mapIdConfirmnewpass: MapIdConfirmnewpass,
      mapIdCurrentpass: MapIdCurrentpass,
      xaction: 'AFTERLOGIN',
    };

    const response = await dispatch(changePasswordReq(reqData));

    if (response.error) {
      const errMessage = get(response, 'error.message');
      throw errMessage;
    }

    dispatch(changePasswordSuccess());
    dispatch(hideChangePasswordSettings());
    dispatch(reset('securityChangePasswordForm'));
    dispatch(hideNotification());
    dispatch(hideOverlay());
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(changePasswordError());
    dispatch(hideOverlay());
  }
};

export const changeLogin = data => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(changeLoginStart());
    dispatch(showOverlay());

    const { login, newLogin } = data;
    const { persistedUser } = getState().auth.user;

    const reqData = {
      np: newLogin,
      cmp: newLogin,
      oldp: login,
    };

    const response = await dispatch(changeLoginReq(reqData));

    if (response.error) {
      const errMessage = get(response, 'error.message');
      throw errMessage;
    }

    const newPersistedUser = {
      ...persistedUser,
      login: newLogin,
    };

    localStorage.setItem('auth/login', JSON.stringify(newPersistedUser));
    dispatch(changeLoginSuccess(newPersistedUser));
    dispatch(toggleChangeLoginBlock());
    dispatch(reset('securityChangeLoginForm'));
    dispatch(hideNotification());
    dispatch(hideOverlay());
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(changeLoginError());
    dispatch(hideOverlay());
  }
};

export const fetchSecureSettings = () => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(fetchSecureSettingsStart());
    dispatch(showOverlay());

    const response = await dispatch(fetchSecureSettingsReq());

    if (response.protectionType) {
      dispatch(fetchSecureSettingsSuccess(response));
      dispatch(saveSecuritySignature(response)); // save settings in signature
      dispatch(hideOverlay());
    } else {
      throw translate(lError('serviceUnavailable'));
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(fetchSecureSettingsError(errMessage));
    dispatch(hideOverlay());
  }
};

export const reqOTPOnChangeProtection = () => async (dispatch, getState) => {
  const { localize, signature } = getState();
  const { hash, schemeId, schemeName } = signature.settings;
  const translate = getTranslate(localize);
  try {
    dispatch(reqOTPOnChangeProtectionStart());
    dispatch(showOverlay());

    await dispatch(createSms({ hash, schemeId, schemeName }));
    dispatch(reqOTPOnChangeProtectionSuccess());
    dispatch(hideNotification());
    dispatch(hideOverlay());
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(fetchSecureSettingsError());
    dispatch(reqOTPOnChangeProtectionError());
    dispatch(showWarnNotification(errMessage));
    dispatch(hideOverlay());
  }
};

export const setProtection = args => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(setProtectionStart());
    dispatch(showOverlay());

    const { type, answer, question, psw } = args;
    const isControlQuestion = type === 'controlQuestion';
    const isControlWord = type === 'controlWord';
    const isControlDate = type === 'controlDate';

    const {
      auth: {
        user: { sign },
      },
      signature,
    } = getState();

    const reqData = {
      console: 'json',
    };

    const answerHashHex = sha256(answer.toUpperCase()).toString();

    if (isControlQuestion) {
      reqData.hashedAnswer = answerHashHex;
      reqData.securityQuestion = question;
      reqData.protectionType = '1';

      reqData.securityWord = '';
      reqData.date = '';
      reqData.tic = 0;
      reqData.schemeName = 'ADDPROTECTIONSIGN';
      reqData.action = 'save';
    }
    if (isControlWord) {
      reqData.securityWord = answer;
      reqData.protectionType = '2';

      reqData.date = '';
      reqData.securityQuestion = '';
      reqData.hashedAnswer = answerHashHex;
    }
    if (isControlDate) {
      reqData.date = answer;

      reqData.protectionType = '3';
      reqData.securityWord = '';
      reqData.securityQuestion = '';
      reqData.hashedAnswer = answerHashHex;
    }
    if (!isControlQuestion && !isControlWord && !isControlDate) {
      reqData.protectionType = '4';
      reqData.securityWord = '';
      reqData.securityQuestion = '';
      reqData.hashedAnswer = '';
    }

    if (sign === '4') {
      const { hash, schemeId } = signature.settings;

      reqData.smsResult = psw;
      reqData.hash = hash;
      reqData.schemeId = schemeId;
      reqData.schemeName = 'ADDPROTECTION';
    }
    if (sign === '2') {
      reqData.password = psw;
    }
    if (sign === '3') {
      reqData.mbpResult = psw;
    }

    const response = await dispatch(setProtectionReq(reqData));

    const error = get(response, 'error');

    if (error) {
      // ? updatedata
      // const { updatedata = [] } = response;
      // let newScratchNum;

      // updatedata.forEach(item => {
      //   if (item.name === 'A_TTanPassN') {
      //     newScratchNum = item.value;
      //     dispatch(updateScratchNum(newScratchNum));
      //   }
      // });
      throw error.message;
    } else {
      dispatch(setProtectionSuccess());
      dispatch(hideNotification());
      dispatch(hideOverlay());
      dispatch(fetchSecureSettings());

      return Promise.resolve({ status: 'success' });
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(setProtectionError());
    dispatch(hideOverlay());
  }
};

export const fetchChangeScratchCardSettings = () => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(fetchChangeScratchCardSettingsStart());
    dispatch(showOverlay());

    const settings = await dispatch(fetchChangeScratchCardSettingsReq());

    if (settings.config) {
      dispatch(fetchChangeScratchCardSettingsSuccess(settings.config));
      dispatch(showChangeScratchCardBlock());
      dispatch(hideNotification());
      dispatch(hideOverlay());
      return Promise.resolve(settings.config);
    } else if (settings.error) {
      const errMessage = get(settings, 'error.message');
      throw errMessage;
    } else {
      throw translate(lError('serviceUnavailable'));
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(fetchChangeScratchCardSettingsError());
    dispatch(hideOverlay());
    return Promise.reject(errMessage);
  }
};

export const changeScratchCard = args => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(changeScratchCardStart());
    dispatch(showOverlay());

    const { scratchCardIdToBind, scratchCardIdNew } = args;

    const reqData = {
      psw: scratchCardIdToBind,
      tn: scratchCardIdNew,
    };

    const response = await dispatch(changeScratchCardReq(reqData));

    if (response.error) {
      const errMessage = get(response, 'error.message');
      throw errMessage;
    }
    dispatch(changeScratchCardSuccess());
    dispatch(hideChangeScratchCardBlock());
    dispatch(hideNotification());
    dispatch(hideOverlay());
    return Promise.resolve();
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(changeScratchCardError());
    dispatch(hideOverlay());
    return Promise.reject(errMessage);
  }
};
