import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';

import { Translation } from 'shared/';
import { labelMaker } from 'shared/utils';

import { setAtmsAndBranchesView } from 'actions/atmsAndBranches';
import styles from './AtmsMenu.module.scss';
const label = labelMaker('atms.menu');

class AtmsMenu extends Component {
  route = '/atms/';

  componentDidMount() {
    const path = this.props.location.pathname;
    let component = path.substr(path.lastIndexOf('/') + 1);
    component = component === 'atms' ? 'map' : component;
    this.props.setAtmsAndBranchesView(component);
  }

  renderMenuItem = (tab, i) => (
    <Translation.Link
      to={`${this.route}${tab}`}
      className={cn(styles.MenuTab, {
        [styles.ActiveMenuTab]: tab === this.props.atms.view,
      })}
      translateId={label(tab)}
      key={i}
      onClick={() => this.props.setAtmsAndBranchesView(tab)}
    />
  );

  render() {
    const menuTabs = ['map', 'list'];
    return <div className={styles.AtmsMenu}>{menuTabs.map(this.renderMenuItem)}</div>;
  }
}

AtmsMenu.propTypes = {
  location: PropTypes.object.isRequired,
  atms: PropTypes.object.isRequired,
  setAtmsAndBranchesView: PropTypes.func.isRequired,
};

const mapState = ({ atms }) => ({ atms });
const mapDispatch = {
  setAtmsAndBranchesView,
};

export default withRouter(
  connect(
    mapState,
    mapDispatch
  )(AtmsMenu)
);
