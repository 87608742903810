import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import cn from 'classnames';

const NavLink = ({ children, translateId, data, activeClassName, ...rest }) => {
  return (
    <Link {...rest} activeClassName={cn('activeLink', activeClassName)}>
      {translateId ? <Translate id={translateId} data={data} /> : children}
    </Link>
  );
};

NavLink.propTypes = {
  children: PropTypes.node,
  translateId: PropTypes.string.isRequired,
  data: PropTypes.object,
  activeClassName: PropTypes.string,
  className: PropTypes.string,
};

export default NavLink;
