import { createReducer } from 'redux-act';

import {
  clearPaymentParams,
  getPaymentSignatureError,
  getPaymentSignatureSuccess,
  // setPaymentAmount,
} from 'actions/payment';

const defaultState = {
  amount: 0,
  urlParams: '',
  error: '',
};

const payment = createReducer(
  {
    [clearPaymentParams]: state => {
      return {
        ...defaultState,
      };
    },

    // [setPaymentAmount]: (state, amount) => {
    //   return {
    //     ...state,
    //     amount,
    //   };
    // },

    [getPaymentSignatureSuccess]: (state, urlParams) => {
      return {
        ...state,
        amount: 0,
        urlParams,
        error: '',
      };
    },

    [getPaymentSignatureError]: (state, error) => {
      return {
        ...state,
        error,
      };
    },
  },
  defaultState
);

export default payment;
