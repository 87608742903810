import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SocialCard from '../SocialCard/SocialCard';

import styles from './SocialCards.module.scss';

class SocialCards extends Component {
  render() {
    const { cards, onChangeCard } = this.props;

    return (
      <div className={styles.Cards}>
        {cards.map((card, i) => (
          <SocialCard key={i} name={card.name} active={card.active} onChange={onChangeCard} />
        ))}
      </div>
    );
  }
}

SocialCards.propTypes = {
  cards: PropTypes.array,
  onChangeCard: PropTypes.func,
};

export default SocialCards;
