import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import styles from './SearchInput.module.scss';

const ReduxSearchInput = props => {
  const { translate, placeholder, onChange, input, classname } = props;

  const handleChange = (...args) => {
    input.onChange(...args);
    onChange(...args);
  };

  return (
    <input
      autoComplete="off"
      className={classname ? classname : styles.Input}
      placeholder={placeholder ? translate(placeholder) : ''}
      {...input}
      onChange={handleChange}
    />
  );
};

ReduxSearchInput.propTypes = {
  classname: PropTypes.string,
  placeholder: PropTypes.string,
  translate: PropTypes.func.isRequired,
  input: PropTypes.object,
  onChange: PropTypes.func,
};

ReduxSearchInput.defaultProps = {
  placeholder: '',
  onChange: () => {},
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(ReduxSearchInput);
