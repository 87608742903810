import {
  reduxFieldRequired,
  reduxFieldCustomMaxLength,
  reduxFieldCustomMinLength,
} from 'shared/validators';
import { ReduxInput } from 'shared';
import { labelMaker } from 'shared/utils';

const label = labelMaker('profile.security');

const secondStepOptions = {
  login: {
    labelId: label('login'),
    disabled: true,
  },
  newLogin: {
    labelId: label('newLogin'),
    component: ReduxInput,
    name: 'newLogin',
    statusElement: true,
    validate: [reduxFieldRequired, reduxFieldCustomMaxLength(25), reduxFieldCustomMinLength(6)],
  },
  password: {
    labelId: label('password'),
    value: '********',
    disabled: true,
  },
  currentPassword: {
    labelId: label('currentPassword'),
    component: ReduxInput,
    name: 'currentPassword',
    statusElement: true,
    type: 'password',
  },
  newPassword: {
    labelId: label('newPassword'),
    component: ReduxInput,
    name: 'newPassword',
    statusElement: true,
    type: 'password',
  },
  confirmNewPassword: {
    labelId: label('confirmNewPassword'),
    component: ReduxInput,
    name: 'confirmNewPassword',
    statusElement: true,
    type: 'password',
  },
  scratchCard: {
    labelId: label('scratchCard'),
  },
  scratchCardIdToBind: {
    labelId: label('scratchCardIdToBind'),
  },
  scratchCardIdNew: {
    labelId: label('scratchCardIdNew'),
  },
  confirmOperation: {
    value: '1',
    // active: true,
  },
  loginConfirm: {
    value: true,
  },
  remotePwdRecovery: {
    value: true,
  },
  answer: {
    labelId: label('answer'),
  },
  question: {
    value: '1',
  },
};

export default secondStepOptions;
