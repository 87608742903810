import React, { Component } from 'react';

import User from '../User/User';
import ProfileMenu from '../ProfileMenu/ProfileMenu';

import styles from './ProfileContentHeader.module.scss';

class ProfileContentHeader extends Component {
  render() {
    return (
      <div className={styles.Header}>
        <div className={styles.UserContainer}>
          <User />
        </div>
        <div className={styles.Menu}>
          <ProfileMenu />
        </div>
      </div>
    );
  }
}

export default ProfileContentHeader;
