export const lessThan = length => value => {
  if (value.length > length) {
    return value.slice(0, length);
  } else {
    return value;
  }
};

export const phoneNormalizer = (value, prevValue) => {
  if (!value) {
    return '';
  }

  const onlyNumbers = value.replace(/[^\d]/g, '');
  if (prevValue && onlyNumbers.length >= prevValue.length) {
    if (onlyNumbers.length > 10) {
      return prevValue;
    }
    return onlyNumbers.replace(/[()-\s]/g, '');
  }

  if (onlyNumbers.length > 10) {
    return onlyNumbers.substr(0, 9);
  }

  return onlyNumbers.replace(/[()-\s]/g, '');
};

export const phoneFormatter = value => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length < 3) {
    return `(${onlyNums}`;
  }
  if (onlyNums.length === 3) {
    return `(${onlyNums}`;
  }
  if (onlyNums.length < 7) {
    return `(${onlyNums.substr(0, 3)}) ${onlyNums.substr(3, 3)}`;
  }
  if (onlyNums.length < 9) {
    return `(${onlyNums.substr(0, 3)}) ${onlyNums.substr(3, 3)}-${onlyNums.substr(6)}`;
  }
  return `(${onlyNums.substr(0, 3)}) ${onlyNums.substr(3, 3)}-${onlyNums.substr(
    6,
    2
  )}-${onlyNums.substr(8)}`;
};
