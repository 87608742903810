import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

const H2 = ({ children, translateId, data, ...rest }) => {
  return (
    <h2 {...rest} data-translate-id={translateId}>
      {translateId ? <Translate id={translateId} data={data} /> : children}
    </h2>
  );
};

H2.propTypes = {
  children: PropTypes.node,
  translateId: PropTypes.string.isRequired,
  data: PropTypes.object,
};

export default H2;
