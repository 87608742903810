import React, { PureComponent, Fragment } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { Button, Translation } from 'shared';
import { labelMaker } from 'shared/utils';

import styles from '../DefaultBankForm.module.scss';

const label = labelMaker('profile');

class Step1 extends PureComponent {
  render() {
    const {
      transfers,
      cards,
      options,
      renderPaymentAccountLabel,
      renderPaymentAccountOption,
      formatCards,
    } = this.props;

    const cardsOptions = formatCards(cards.data);

    return (
      <Fragment>
        <Translation.Div
          className={styles.Title}
          translateId={label('titles.receivingTransfersByPhone')}
        />
        <Translation.Div
          className={styles.Description}
          translateId={label('descriptions.chooseAccountForFPS')}
        />
        <Field
          className={styles.Input}
          {...options.receiverPhone}
          isLoading={transfers.transferForm.isAccountLoading}
          disabled
        />
        <Field
          className={styles.Input}
          {...options.cards}
          renderLabel={renderPaymentAccountLabel}
          renderOption={renderPaymentAccountOption}
          isLoading={transfers.defaultBankAccountForm.isDefaultAccountLoading}
          disabled={transfers.defaultBankAccountForm.disabled}
          options={cardsOptions}
        />

        <Button
          className={styles.Button}
          translateId={label('btns.set')}
          showLoader={transfers.defaultBankAccountForm.isLoading}
          disabled={transfers.defaultBankAccountForm.disabled}
        />
      </Fragment>
    );
  }
}

Step1.propTypes = {
  cards: PropTypes.object.isRequired,
  formatCards: PropTypes.func.isRequired,
  transfers: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  renderPaymentAccountLabel: PropTypes.func.isRequired,
  renderPaymentAccountOption: PropTypes.func.isRequired,
};

export default Step1;
