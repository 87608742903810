import React, {  memo, useEffect, useRef } from 'react';
import { Modal as ModalShared, ModalHeader, } from 'shared';
import styles from './UfmBlockModal.module.scss';
import { PropTypes } from 'prop-types';

const UfmBlockModal = ({message,onClose})=>{
  const modalRef = useRef()
  const handleClickOutside = (e) => {
    if (e.target !== modalRef?.current) {
      onClose()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <ModalShared onClose={onClose} >
      <ModalHeader style={{padding:"72px 72px 26px"}} >
        <div className={styles.Text} ref={modalRef} >{message} </div>
      </ModalHeader>
    </ModalShared> 
  );
}


UfmBlockModal.propTypes = {
  onClose: PropTypes.func,
  message:PropTypes.string
};

export default memo(UfmBlockModal)
