import React from 'react';
import PropTypes from 'prop-types';

import { get } from 'shared/utils';
import { Button } from '..';

import styles from './Promotion.module.scss';

const Promotion = props => {
  const title = get(props, 'title', '');
  const info = get(props, 'info', '');
  // const img = get(props, 'img', '');
  // const accountUrl = get(props, 'accountUrl', '');

  return (
    <div className={styles.Container}>
      <div className={styles.Image} />
      <div className={styles.Content}>
        <div className={styles.Title}>{title}</div>
        <div className={styles.Info}>{info}</div>
        <div className={styles.ActionContainer}>
          <Button disabled className={styles.ActionButton} style={{ height: '40px' }} round>
            Показать подробнее
          </Button>
        </div>
      </div>
    </div>
  );
};

Promotion.proTypes = {
  img: PropTypes.string,
  title: PropTypes.string,
  info: PropTypes.string,
  actionUrl: PropTypes.string,
};

export default Promotion;
