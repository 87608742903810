import React, { Fragment } from 'react';

import Login from 'components/Login/Login';
import Footer from 'components/Footer/Footer';
import { withNotification, withKYCNotification } from 'shared';

import styles from './index.module.scss';

class LoginPage extends React.Component {
  render() {
    return (
      <Fragment>
        <div className={styles.Page}>
          <Login {...this.props} />
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default withKYCNotification(withNotification(LoginPage));
