import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { SmsInput, Button } from 'shared';
import { labelMaker } from 'shared/utils';

import styles from './Step2.module.scss';

const label = labelMaker('signup');

const Step2 = ({ signup, resendOTPRequest }) => {
  return (
    <Fragment>
      <SmsInput
        onResend={resendOTPRequest}
        isLoading={signup.isRequestPending}
        phoneNumber={signup.smsPhone}
      />
      <div className={styles.SubmitContainer}>
        <Button type="submit" translateId={label('send')} showLoader={signup.isRequestPending} />
      </div>
    </Fragment>
  );
};

Step2.propTypes = {
  signup: PropTypes.object.isRequired,
  resendOTPRequest: PropTypes.func.isRequired,
};

const mapState = ({ signup }) => ({
  signup,
});

export default connect(mapState)(Step2);
