import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Card.module.scss';

const Card = ({ className, children, ...rest }) => {
  return <div className={cn(styles.Card, className)}>{children}</div>;
};

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.node,
};

export default Card;
