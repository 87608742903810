import React, { Component, Fragment } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';

import LoansContentHeader from './components/LoansContentHeader/LoansContentHeader';
import Prepayment from './Prepayment/Prepayment';
import Schedule from './Schedule/Schedule';
import Payments from './Payments/Payments';
import Account from './Account/Account';
import History from './History/History';
import { Page404, withErrorBoundary } from 'shared';

import { setActiveLoan, fetchLoan } from 'actions/products';

class ProductsPage extends Component {
  componentDidMount() {
    const { setActiveLoan, match } = this.props;

    setActiveLoan(match.params.id);
    this.props.fetchLoan({ id: match.params.id });
  }

  shouldComponentUpdate(nextProps) {
    const { setActiveLoan, match, products: currProducts } = this.props;
    const { credit: currCredit } = currProducts;

    const { credits } = nextProps.products;

    if (
      Array.isArray(credits) &&
      credits.length &&
      (currCredit === null || currCredit.contractRef !== match.params.id)
    ) {
      const credit = credits.find(
        credit =>
          credit.contractRef === match.params.id &&
          credit.details &&
          !credit.details.isRequestPending
      );

      if (credit) {
        setActiveLoan(credit.contractRef);
      }
    }

    return true;
  }

  render() {
    const { credit } = this.props.products;

    return credit ? (
      <Fragment>
        <LoansContentHeader />
        <Switch>
          <Route exact path="/products/loans/:id/prepayment" component={Prepayment} />
          <Route exact path="/products/loans/:id/payments" component={Payments} />
          <Route exact path="/products/loans/:id/schedule" component={Schedule} />
          <Route exact path="/products/loans/:id/account" component={Account} />
          <Route exact path="/products/loans/:id/history" component={History} />
          <Route exact path="/products/loans/404" component={Page404} />
          <Redirect to={'/products/loans/404'} />
        </Switch>
      </Fragment>
    ) : null;
  }
}

ProductsPage.propTypes = {
  products: PropTypes.object,
  setActiveLoan: PropTypes.func.isRequired,
  fetchLoan: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  products: state.products,
});

const mapDispatchToProps = {
  setActiveLoan,
  fetchLoan,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withLocalize(withErrorBoundary(ProductsPage, 'Products/Loans')))
);
