import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import InputNumber from 'rc-input-number/lib';
import cn from 'classnames';
import numeral from 'numeral';

import InputError from '../../InputError/InputError';

import {
  isActiveButtonCode,
  isNumericCode,
  isEmpty,
  calculateWidth,
  isDot,
  getCurrencySymbol,
} from 'shared/utils';

import styles from './NumericInput.module.scss';

class NumericInput extends Component {
  state = {
    showStatus: false,
    hasFocus: false,
  };

  onFocus = () => this.setState({ hasFocus: true });

  onChange = (e, isError) => {
    const value = e.target.value;
    const { onChange, name, max, value: prevValue } = this.props;

    if (isEmpty(value) || value < 0) {
      return onChange({ value: 0, name });
    }

    let newValue = value.toString();
    let stringArray = newValue.split('');

    let numOfDotsInValue = 0;

    for (let item in stringArray) {
      if (stringArray[item] === '.') {
        if (item === '0') {
          newValue = `0${newValue}`;
        }
        numOfDotsInValue++;

        if (numOfDotsInValue > 1) {
          newValue = prevValue;
        }
      }
    }

    if (numOfDotsInValue > 0) {
      stringArray = newValue.split('.');
      newValue = `${stringArray[0]}.${stringArray[1].substr(0, 2)}`;
    }

    // if first 0 - check next value and replace 0 to next value if needed
    if (
      prevValue.toString() === '0' &&
      value.toString()[0] === '0' &&
      value.toString()[1] !== '.'
    ) {
      newValue = parseFloat(value[1]);
    }

    newValue = Math.min(newValue, max) === parseFloat(max) ? max : newValue;
    if (newValue) {
      onChange({ value: newValue, name }, isError);
    }
  };

  onKeyDown = e => {
    const { keyCode: code } = e;

    if (!isActiveButtonCode(code) && !isNumericCode(code) && !isDot(code)) {
      e.preventDefault();
    }
  };

  onBlur = ({ target }) => {
    const { onChange, max } = this.props;

    const { name, value: unformattedValue } = target;
    const value = unformattedValue.replace(/\s/g, '');
    const nextValue = parseFloat(value) > parseFloat(max) ? max : value;

    this.setState({
      hasFocus: false,
      showError: true,
    });

    onChange({ value: nextValue, name }, true);
  };

  calculateValueWidth = value => {
    let hasFractionalValue = false;
    let fractional = '';

    if (value) {
      hasFractionalValue = value.toString().indexOf('.') > 0;

      if (hasFractionalValue) {
        fractional = value.toString().slice(value.toString().indexOf('.'));
      }
    }

    return calculateWidth(
      `${numeral(value)
        .format('0,0')
        .replace(/,/g, ' ')}${fractional}`,
      30
    );
  };

  render() {
    const {
      disabled,
      errorId,
      isError,
      // max: maxVal,
      name,
      touched,
      value,
      formatRender,
      currency,
    } = this.props;
    const { hasFocus } = this.state;

    const isErrorVisible = touched && errorId && !disabled && isError;
    const isInputFocused = hasFocus && !disabled;
    const error = isErrorVisible ? errorId : null;

    // const max = parseFloat(maxVal);

    const externalBorderClassName = cn(styles.ExternalBorder, {
      [styles.DisabledExternalBorder]: disabled,
      [styles.FocusedExternalBorder]: isInputFocused,
      [styles.ErrorExternalBorder]: isErrorVisible,
    });
    const internalBorderClassName = cn(styles.InternalBorder, {
      [styles.FocusedInternalBorder]: isInputFocused,
      [styles.ErrorInternalBorder]: isErrorVisible,
    });
    const CurrencyClassName = cn(styles.Currency, {
      [styles.DisabledCurrency]: disabled,
    });

    const defaultCurrencyLeftMargin = 10;
    const leftMargin = this.calculateValueWidth(value) + defaultCurrencyLeftMargin;

    // const props = {};
    // if (max) {
    //   props.max = max;
    // }

    // TODO: remove unused code if all ok
    // use standard input instead of rc-input-number
    return (
      <div className={styles.Container}>
        <div className={externalBorderClassName}>
          <div className={internalBorderClassName}>
            <div className={styles.InputContainer}>
              <input
                autoComplete="off"
                disabled={disabled}
                // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                name={name}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onKeyDown={this.onKeyDown}
                onBlur={this.onBlur}
                value={formatRender(value)}
                // {...props}
              />
              <div
                className={CurrencyClassName}
                style={{
                  marginLeft: leftMargin,
                }}
              >
                {getCurrencySymbol(currency)}
              </div>
            </div>
          </div>
        </div>
        <InputError errorId={error} />
      </div>
    );
  }
}

NumericInput.propTypes = {
  formatRender: PropTypes.func,
  actionId: PropTypes.string,
  disabled: PropTypes.bool,
  errorId: PropTypes.string,
  handleAction: PropTypes.func,
  isError: PropTypes.bool,
  labelId: PropTypes.string,
  // max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  touched: PropTypes.bool,
  value: PropTypes.any,
  currency: PropTypes.string.isRequired,
};

NumericInput.defaultProps = {
  onChange: () => {},
  formatRender: value => value,
  value: '',
};

export default NumericInput;
