import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Translation } from 'shared/';

import styles from './LoansMenu.module.scss';
import { labelMaker } from 'shared/utils';

const LoansMenu = props => {
  const lMenuTabs = labelMaker('products.loansMenu');
  const menuTabs = ['history', 'schedule', 'payments', 'prepayment', 'account'];

  const { params } = props.match;
  const route = '/products/loans/';

  const renderMenuItem = (tab, i) => (
    <Translation.NavLink
      exact
      to={`${route}${params.id}/${tab}`}
      className={styles.MenuTab}
      activeClassName={styles.ActiveMenuTab}
      translateId={lMenuTabs(tab)}
      key={i}
    />
  );

  const { className } = props;

  return <div className={cn(styles.LoansMenu, className)}>{menuTabs.map(renderMenuItem)}</div>;
};

LoansMenu.propTypes = {
  match: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withRouter(LoansMenu);
