import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Radio.module.scss';

const Radio = props => {
  const onChange = ({ target }) => {
    const { name } = target;
    props.onChange({ name, value: props.activeValue, checked: !props.checked, type: 'radio' });
  };

  const { title, value, activeValue, name, labelClassName, disabled, children } = props;
  return (
    <label
      title={title}
      className={cn([
        styles.Label,
        {
          [styles.DisabledLabel]: disabled,
        },
      ])}
    >
      <input
        type="radio"
        value={value}
        checked={activeValue === value}
        onChange={onChange}
        disabled={disabled}
        name={name}
      />
      <span className={styles.CheckMark} />
      <div
        className={cn([
          styles.LabelContainer,
          { [styles.DisabledLabelContainer]: disabled },
          { [labelClassName]: labelClassName },
        ])}
      >
        {children}
      </div>
    </label>
  );
};

Radio.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  activeValue: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.object,
};

export default Radio;
