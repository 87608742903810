import React, { Component } from 'react';
import { yMapsObjectsReq } from 'actions/dadata/dadataRequests';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ReduxInput from '../ReduxInput/Input';
import ReduxSearchInput from '../ReduxSearchInput/ReduxSearchInput';
import Menu from './Menu';

import { debounce, get } from 'shared/utils';

class LocationInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locations: [],

      isMenuOpen: false,
      isMenuLoading: false,
    };
    this.loadHints = debounce(this.asyncLocationLoad, 300);
  }

  toggleMenuView = option => {
    const { isMenuOpen } = this.state;

    this.setState({
      isMenuOpen: !isMenuOpen,
    });
  };
  openMenuView = () => {
    this.setState({
      isMenuOpen: true,
    });
  };
  hideMenuView = () => {
    this.setState({
      isMenuOpen: false,
    });
  };

  asyncLocationLoad = reqValue => {
    const { input, yMapsObjectsReq, kind } = this.props;

    yMapsObjectsReq(reqValue).then(data => {
      let locations = get(data, 'data.response.GeoObjectCollection.featureMember');
      if (kind === 'city') {
        locations = locations.filter(
          item =>
            item.GeoObject.metaDataProperty.GeocoderMetaData.Address.country_code === 'RU' &&
            (item.GeoObject.metaDataProperty.GeocoderMetaData.kind === 'locality' ||
              item.GeoObject.metaDataProperty.GeocoderMetaData.kind === 'province')
        );
      }

      if (kind === 'address') {
        locations = locations.filter(
          item =>
            item.GeoObject.metaDataProperty.GeocoderMetaData.Address.country_code === 'RU' &&
            (item.GeoObject.metaDataProperty.GeocoderMetaData.kind === 'metro' ||
              item.GeoObject.metaDataProperty.GeocoderMetaData.kind === 'street' ||
              item.GeoObject.metaDataProperty.GeocoderMetaData.kind === 'house')
        );
      }

      if (input.value === reqValue.text) {
        this.setState({
          isMenuLoading: false,
          locations,
        });
      }
    });
  };

  onChange = e => {
    const { input, value: currValue } = this.props;
    const { isMenuOpen } = this.state;

    if (!e.target.value) {
      return;
    }

    if (currValue !== e.target.value) {
      this.loadHints({ text: e.target.value });
      this.setState({
        isMenuLoading: true,
      });

      if (!isMenuOpen) {
        this.openMenuView();
      }
    } else if (currValue === e.target.value && isMenuOpen) {
      this.hideMenuView();
    }

    input.onChange(e);
  };

  selectOption = option => () => {
    const { input, moveOn } = this.props;
    input.onChange(option.name);
    moveOn({ coords: option.coords, corners: option.corners });
    this.hideMenuView();
  };

  render() {
    const { locations, isMenuOpen, isMenuLoading } = this.state;
    const { round } = this.props;

    return (
      <>
        {round ? (
          <ReduxSearchInput {...this.props} onChange={this.onChange} />
        ) : (
          <ReduxInput {...this.props} onChange={this.onChange} />
        )}
        <Menu
          isOpen={isMenuOpen}
          locations={locations}
          isLoading={isMenuLoading}
          selectOption={this.selectOption}
          onClickOutside={this.hideMenuView}
        />
      </>
    );
  }
}

LocationInput.propTypes = {
  yMapsObjectsReq: PropTypes.func.isRequired,
  input: PropTypes.object.isRequired,
  moveOn: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  round: PropTypes.bool,
  kind: PropTypes.string,
};

const mapStateToProps = ({ dadata }) => ({
  dadata,
});

const mapDispatchToProps = dispatch => ({
  yMapsObjectsReq: args => dispatch(yMapsObjectsReq(args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationInput);
