import styles from './Placemarker.module.scss';

import { appendCurrencySymbol, labelMaker } from 'shared/utils';

const label = labelMaker('atms.placemarker');

export const getPlacemarkerBody = (atm, translate) => {
  const { address, name, metro, workhours, currencies } = atm;

  return `<div class=${styles.InfoBox}>
      <div class=${styles.Title}>${name}</div>
      <div class=${styles.Address}>${address}</div>
      ${getMetroBody(metro)}
      <div class=${styles.Info}>
        ${getWorkhouseBody(workhours)}
        ${getCurrencyBody(currencies, translate)}
      </div>
      <div class=${styles.OpenStatus}>Открыто</div>
    </div>`;
};

export const getPlacemarkerHeader = ({ name }) => {
  return `<div class=${styles.Title}>${name}</div>`;
};

const getMetroBody = metro =>
  metro
    ? `<div class=${styles.MetroContainer}>
        <div class=${styles.MetroIcon}>M</div>
        <div class=${styles.Metro}>${metro}</div>
      </div>`
    : '';

const getWorkhouseBody = workhours =>
  workhours
    ? `<div class=${styles.Workhours}>
        ${workhours}
      </div>`
    : '';

const getCurrencyBody = (currencies, translate) =>
  Array.isArray(currencies) && currencies.length
    ? `<div class=${styles.CurrencyContainer}>
    <div class=${styles.CurrenciesTitle}>${translate(label('currency'))}</div>
      <div class=${styles.Currencies}>
        ${getCurrencies(currencies)}
      </div>
  </div>`
    : '';

const getCurrencies = currencies =>
  currencies.reduce(
    (acc, ccy) => (acc += `<div class=${styles.Currency}>${appendCurrencySymbol('', ccy)}</div>`),
    ''
  );
