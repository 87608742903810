/* eslint-disable no-mixed-operators */
import { createAction } from 'redux-act';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';

import {
  loginReq,
  // logoutReq,
  newLogoutReq,
  fetchLoginBannerListReq,
  fetchLoginBannerReq,
  fetchChangePasswordSettingsReq,
  changePasswordReq,
  changePasswordReqNew,
  newLoginFetchFormReq,
  newLoginFetchTokenReq,
  newLoginFetchTokenInfoReq,
  fetch2FactorSettingsReq,
  confirm2FactorReq,
  fetchTechNotifyReq,
  newLoginSendOtpReq,
  getAntifraudTranslate,
  antifraudUfmBlock,
} from './authRequests';
import { resetCaptcha, fetchCaptchaImage, setTempCaptchaRequire, fetchCaptchaId } from '../captcha';
import {
  save2FactorSignature,
  createSms,
  postMessageSuccess,
  createSmsSuccess,
} from '../signature';
import { fetchSettings } from '../settings';
import { fetchDadataApiKey } from '../dadata';
import { fetchKYCStatus } from '../profile';
import { changeScratchCard } from '../security';
// временно скрываем чат https://jira.intranet/browse/DC-5142
// import { initializeChat, resetChat } from '../chat';
import { reset, change, untouch } from 'redux-form';
import { showOverlay, hideOverlay } from '../overlay';
import { fetchPrevAuthNotifyEnabled } from '../security/prevAuthNotify';

import {
  hideNotification,
  hideKYCNotification,
  showInfoNotification,
  showWarnNotification,
  showAntifraudNotification,
} from '../notification';
import { goTo } from '../routes';

import { capitalize, getErrorMessage, labelMaker, get, transliterate } from 'shared/utils';

const label = labelMaker('auth');

export const cancelChangePassword = createAction('CANCEL_CHANGE_PASSWORD');
export const cancelLogin = createAction('CANCEL_LOGIN');
export const changePasswordFailure = createAction('CHANGE_PASSWORD_FAILURE');
export const changePasswordStart = createAction('CHANGE_PASSWORD_START');
export const changePasswordSuccess = createAction('CHANGE_PASSWORD_SUCCESS');
export const clearUserCacheAction = createAction('CLEAR_USER_CACHE');
export const fetchLoginBannerFailure = createAction('FETCH_LOGIN_BANNER_FAILURE');
export const fetchLoginBannerStart = createAction('FETCH_LOGIN_BANNER_START');
export const fetchLoginBannerSuccess = createAction('FETCH_LOGIN_BANNER_SUCCESS');
export const loginError = createAction('LOGIN_ERROR');
export const loginRecoverUser = createAction('LOGIN_RECOVER_USER');
export const loginStart = createAction('LOGIN_START');
export const loginSuccess = createAction('LOGIN_SUCCESS');
export const newLoginSuccess = createAction('NEW_LOGIN_SUCCESS');
export const logoutError = createAction('LOGOUT_ERROR');
export const logoutStart = createAction('LOGOUT_START');
export const logoutSuccess = createAction('LOGOUT_SUCCESS');
export const rejectMobileDownload = createAction('REJECT_MOBILE_DOWNLOAD');
export const resetTempPasswordRequired = createAction('RESET_TEMP_PASSWORD_REQUIRED');
export const setLoginStep = createAction('SET_LOGIN_STEP');
export const setTempPasswordRequired = createAction('SET_TEMP_PASSWORD_REQUIRED');
export const set2FactorRequired = createAction('SET_2FACTOR_REQUIRED');
export const confirm2factorLoginStart = createAction('CONFIRM_2FACTOR_LOGIN_START');
export const confirm2factorLoginSuccess = createAction('CONFIRM_2FACTOR_LOGIN_SUCCESS');
export const confirm2factorLoginFailure = createAction('CONFIRM_2FACTOR_LOGIN_FAILURE');
export const fetch2FactorSettingsStart = createAction('FETCH_2FACTOR_SETTINGS_START');
export const fetch2FactorSettingsSuccess = createAction('FETCH_2FACTOR_SETTINGS_SUCCESS');
export const fetch2FactorSettingsError = createAction('FETCH_2FACTOR_SETTINGS_ERROR');
export const setAntifraudUfmBlock = createAction('SET_ANTIFRAUD_UFM_BLOCK');
export const cleanAntifraudUfmBlock = createAction('CLEAN_ANTIFRAUD_UFM_BLOCK');

export const saveResAPI = createAction('SAVE_RES_API');

export const login = loginParams => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  const { username: login, captchaValue, goToProducts = true } = loginParams;

  const responseActions = {
    changePassword: () =>
      [
        hideNotification(),
        setLoginStep(2),
        captchaValue && resetCaptcha(),
        resetTempPasswordRequired(),
        loginError(),
      ]
        .filter(Boolean)
        .forEach(action => dispatch(action)),
    captcha: response =>
      [
        fetchCaptchaImage(response.view.captcha.id),
        !captchaValue && setTempCaptchaRequire(),
        change('authentication', 'captcha', ''),
        untouch('authentication', 'captcha'),
        showWarnNotification(translate(label('errors.duplicateCaptchaRequest'))),
        loginError(),
      ]
        .filter(Boolean)
        .forEach(action => dispatch(action)),
    wrongCredentials: response =>
      [
        captchaValue && fetchCaptchaId(),
        change('authentication', 'captcha', ''),
        untouch('authentication', 'captcha'),
        response.view.error.error && showWarnNotification(response.view.error.error),
        // error can be in error.error or in error.message, but just in case we check to not show both
        response.view.error.message &&
          !response.view.error.error &&
          showWarnNotification(response.view.error.message),
        loginError(),
      ]
        .filter(Boolean)
        .forEach(action => dispatch(action)),
    success: auth =>
      [
        hideNotification(),
        fetchDadataApiKey(),
        fetchKYCStatus(),
        fetchPrevAuthNotifyEnabled(true),
        loginSuccess(auth),
        fetchSettings(),
        // initializeChat({ requireGreetingMessage: true }),
        goToProducts && goTo('products'),
      ]
        .filter(Boolean)
        .forEach(action => dispatch(action)),
  };

  try {
    dispatch(loginStart());
    const response = await dispatch(loginReq(loginParams));

    // Change password is required
    // if (response.data.level === 'changetemppass' || response.data.level === 'changepass') {
    if (get(response, 'abscustid') === null) {
      return responseActions.changePassword();
      // Captcha input is required
    } else if (response.view) {
      if (get(response, 'view.captcha')) {
        return responseActions.captcha(response);
      }
      // Login or password is incorrect
      else if (get(response, 'view.error')) {
        return responseActions.wrongCredentials(response);
      }
      // Login and password is correct
    } else if (response.hasOwnProperty('abscustid')) {
      const { menu, sid, ...auth } = response;

      const username = (auth.fullname && auth.fullname.split(' ')[1]) || '';
      const persistedUser = { login, username: capitalize(username), userId: auth.abscustid };
      auth.persistedUser = persistedUser;
      const middleName = (auth.fullname && auth.fullname.split(' ')[2]) || '';

      // save user first name and middle name in different locales
      auth.firstName = {
        ru: capitalize(username.toLowerCase()),
        en: capitalize(transliterate(username.toLowerCase())),
      };

      auth.middleName = {
        ru: capitalize(middleName.toLowerCase()),
        en: capitalize(transliterate(middleName.toLowerCase())),
      };

      localStorage.setItem('auth', JSON.stringify(auth));
      localStorage.setItem('auth/login', JSON.stringify(persistedUser));

      return responseActions.success(auth);
    } else {
      throw new Error();
    }
  } catch (err) {
    dispatch(fetchCaptchaId());
    dispatch(change('authentication', 'captcha', ''));
    dispatch(untouch('authentication', 'captcha'));
    dispatch(showWarnNotification(translate(label('errors.cabinetNotAvailable'))));
    dispatch(loginError());
  }
};

const fetchTokenInfo =
  (access_token, refresh_token, goToProducts = true) =>
  async (dispatch, getState) => {
    const { captcha, localize, auth } = getState();
    const { login } = auth.persistedUser;
    const translate = getTranslate(localize);
    const responseActions = {
      success: auth =>
        [
          hideNotification(),
          fetchDadataApiKey(),
          fetchKYCStatus(),
          fetchPrevAuthNotifyEnabled(true),
          loginSuccess(auth),
          fetchSettings(),
          // initializeChat({ requireGreetingMessage: true }),
          goToProducts && goTo('products'),
        ]
          .filter(Boolean)
          .forEach(action => dispatch(action)),
      changePassword: () =>
        [
          hideNotification(),
          setLoginStep(2),
          (captcha.isCaptchaRequired || captcha.isTempCaptchaRequired) && resetCaptcha(),
          resetTempPasswordRequired(),
          loginError(),
        ]
          .filter(Boolean)
          .forEach(action => dispatch(action)),
    };
    try {
      const tokenInfo = await dispatch(newLoginFetchTokenInfoReq(access_token));
      if (
        get(tokenInfo, 'bssAuthLevel') === 'changetemppass' ||
        get(tokenInfo, 'bssAuthLevel') === 'changepass'
      ) {
        return responseActions.changePassword();
      }

      // success 2 factor authorization confirm
      if (tokenInfo.hasOwnProperty('cid')) {
        const {
          displayName: fullname,
          cid,
          cid: abscustid,
          sign,
          bssPasswordExpireDate,
          expires_in,
          roles,
          sub,
        } = tokenInfo;

        const auth = {
          login,
          fullname,
          cid,
          abscustid,
          sign,
          bssPasswordExpireDate,
          expires_in,
          roles,
          refreshToken: refresh_token,
          sub,
        };

        const username = (fullname && fullname.split(' ')[1]) || '';
        const persistedUser = {
          login,
          username: capitalize(username),
          userId: cid,
        };
        auth.persistedUser = persistedUser;

        const middleName = (fullname && fullname.split(' ')[2]) || '';

        // save user first name and middle name in different locales
        auth.firstName = {
          ru: capitalize(username.toLowerCase()),
          en: capitalize(transliterate(username.toLowerCase())),
        };

        auth.middleName = {
          ru: capitalize(middleName.toLowerCase()),
          en: capitalize(transliterate(middleName.toLowerCase())),
        };
        const antifraudUfmBlockReq = await dispatch(antifraudUfmBlock());
        dispatch(setAntifraudUfmBlock(antifraudUfmBlockReq));

        localStorage.setItem('auth', JSON.stringify(auth));
        localStorage.setItem('auth/login', JSON.stringify(persistedUser));
        return responseActions.success(auth);
      } else {
        throw new Error();
      }
    } catch (error) {
      if (captcha.isCaptchaRequired || captcha.isTempCaptchaRequired) {
        dispatch(fetchCaptchaId());
      }
      dispatch(showWarnNotification(translate(label('errors.cabinetNotAvailable'))));
      dispatch(loginError());
    }
  };

export const newLogin = loginParams => async (dispatch, getState) => {
  const { localize, captcha, settings, auth } = getState();
  const { persistedUser } = auth;
  const translate = getTranslate(localize);
  const {
    login,
    password,
    captchaValue,
    captchaId,
    goToProducts = true,
    without2Fa = false,
    type,
  } = loginParams;
  const responseActions = {
    changePassword: () =>
      [
        hideNotification(),
        setLoginStep(2),
        (captcha.isCaptchaRequired || captcha.isTempCaptchaRequired) && resetCaptcha(),
        resetTempPasswordRequired(),
        loginError(),
      ]
        .filter(Boolean)
        .forEach(action => dispatch(action)),
    wrongCredentials: errorMessage =>
      [
        (captcha.isCaptchaRequired || captcha.isTempCaptchaRequired) && fetchCaptchaId(),
        change('authentication', 'captcha', ''),
        untouch('authentication', 'captcha'),
        showWarnNotification(errorMessage),
        loginError(),
      ]
        .filter(Boolean)
        .forEach(action => dispatch(action)),
    captcha: response =>
      [
        //fetchCaptchaImage(response.view.externalAuthError.captcha.id),
        !captchaValue && setTempCaptchaRequire(),
        change('authentication', 'captcha', ''),
        untouch('authentication', 'captcha'),
        showWarnNotification(translate(label('errors.duplicateCaptchaRequest'))),
        loginError(),
      ]
        .filter(Boolean)
        .forEach(action => dispatch(action)),
    success: auth =>
      [
        hideNotification(),
        fetchDadataApiKey(),
        fetchKYCStatus(),
        fetchPrevAuthNotifyEnabled(true),
        loginSuccess(auth),
        fetchSettings(),
        // initializeChat({ requireGreetingMessage: true }),
        goToProducts && goTo('products'),
      ]
        .filter(Boolean)
        .forEach(action => dispatch(action)),
    enterOtp: settings =>
      [hideNotification(), postMessageSuccess({ settings }), setLoginStep(3), loginError()]
        .filter(Boolean)
        .forEach(action => dispatch(action)),
  };
  try {
    const isProduction = get(settings, 'envInfo.en', 'prod') === 'prod';
    dispatch(loginStart());
    dispatch(loginRecoverUser({ ...persistedUser, login }));

    // new authorization fetch login form settings
    const responseForm = await dispatch(newLoginFetchFormReq(without2Fa));

    if (responseForm.execution) {
      dispatch(saveResAPI(responseForm));
    }

    // new authorization fetch token
    let tokenResponse;
    if (responseForm.access_token) {
      tokenResponse = responseForm;
    } else {
      tokenResponse = await dispatch(
        newLoginFetchTokenReq({
          execution: responseForm.execution,
          username: login,
          password,
          captchaId,
          captchaValue,
          type,
          isProduction,
        })
      );
      dispatch(saveResAPI(tokenResponse));
    }

    if (!without2Fa && tokenResponse.step === 'enter_otp_form') {
      const tanParams = get(tokenResponse, 'view.extendedAttributes.get2FA.tankeyParams');
      const mobipassParams = get(tokenResponse, 'view.extendedAttributes.get2FA.mobipassParams');
      const smsParams = get(tokenResponse, 'view.extendedAttributes.get2FA.smsParams');
      const Create2FaSMSbyClientRequest = get(
        tokenResponse,
        'view.extendedAttributes.Create2FaSMSbyClientRequest'
      );

      if (smsParams) {
        const { TimeOut, message, status } = Create2FaSMSbyClientRequest;
        dispatch(
          createSmsSuccess({
            timeOut: TimeOut,
            message,
            status: status.toLowerCase(),
            timeOutStart: new Date(),
          })
        );
      }

      responseActions.enterOtp(
        (tanParams && { confirmType: 'tan', ...tanParams }) ||
          (mobipassParams && { confirmType: 'mobipass', ...mobipassParams }) ||
          (smsParams && { confirmType: 'sms_code', ...smsParams })
      );

      if (tanParams && parseFloat(tanParams.countOfKeys) <= getScratchChangeCount()) {
        return dispatch(setLoginStep(4));
      }
      return;
    }

    const loginError = get(tokenResponse, 'view.externalAuthError');
    const loginErrorMessage = get(loginError, 'error.message');
    const isBlocked = get(tokenResponse, 'view.isBlocked');

    // check if captcha required
    if (get(tokenResponse, 'view.externalAuthError.captcha')) {
      return responseActions.captcha(tokenResponse);
    }

    // wrong credentials or other form error message
    if (loginError || isBlocked) {
      if (isBlocked) {
        const isAntifraud = get(tokenResponse, 'view.reasonDescriptions', []).includes('ANTIFRAUD');
        if (isAntifraud) {
          try {
            const antifraudReq = await dispatch(getAntifraudTranslate());
            if (antifraudReq.length) {
              const lang = getActiveLanguage(localize).code;
              const antifraudMassege =
                lang === 'ru' ? antifraudReq[0]?.textRu : antifraudReq[0]?.textEn;
              if (captcha.isCaptchaRequired || captcha.isTempCaptchaRequired) {
                dispatch(fetchCaptchaId());
              }
              dispatch({ type: 'LOGIN_ERROR' });
              return dispatch(showAntifraudNotification(antifraudMassege));
            }
          } catch (e) {
            throw new Error();
          }
        }
      }
      if (loginErrorMessage) {
        return responseActions.wrongCredentials(loginErrorMessage);
      } else {
        throw new Error();
      }
    }

    if (tokenResponse.step === 'enter_credentials') {
      dispatch(saveResAPI(tokenResponse));
      return responseActions.changePassword();
    }

    // if no access token in response
    if (!tokenResponse.access_token) {
      throw new Error();
    }

    // if success fetch access token info
    dispatch(fetchTokenInfo(tokenResponse.access_token, tokenResponse.refresh_token, goToProducts));
  } catch (err) {
    if (captcha.isCaptchaRequired || captcha.isTempCaptchaRequired) {
      dispatch(fetchCaptchaId());
    }
    dispatch(showWarnNotification(translate(label('errors.cabinetNotAvailable'))));
    dispatch(loginError());
  }
};

export const newLoginSend2FA =
  (withRedirect = true, goToProducts = true) =>
  async (dispatch, getState) => {
    const { localize, signature, auth } = getState();
    const translate = getTranslate(localize);
    try {
      dispatch(confirm2factorLoginStart());
      const { cashToken, smsId, code, schemeId, hash, sign, confirmType } = signature.settings;
      const { value } = signature;
      const { execution } = auth.resAPI;

      const data = {
        cashToken: cashToken,
        smsId,
        code: code || value,
        hash,
        schemeId,
        sign,
        confirmType,
        execution,
      };

      const response = await dispatch(newLoginSendOtpReq(data));
      const errorMessage = get(response, 'form.errors');
      if (errorMessage) {
        throw Error(get(response, 'view.extendedAttributes.check2FA.message'));
        // something goes wrong
      }

      dispatch(fetchTokenInfo(response.access_token, response.refresh_token, goToProducts));
    } catch (err) {
      dispatch(confirm2factorLoginFailure());
      dispatch(setLoginStep(1));
      dispatch(resetCaptcha());
      dispatch(cancelLogin());
      dispatch(showWarnNotification(err || translate(label('errors.cabinetNotAvailable'))));
      dispatch(loginError());
    }
  };

export const cancel2FactorLogin = () => (dispatch, getState) => {
  const { captcha } = getState();
  dispatch(reset('authentication'));
  dispatch(setLoginStep(1));
  if (captcha.isCaptchaRequired || captcha.isTempCaptchaRequired) {
    dispatch(resetCaptcha());
  }
  dispatch(cancelLogin());
  const withoutNotification = true;
  dispatch(logout(withoutNotification));
};

const getScratchChangeCount = () => {
  return 3;
};

export const fetch2FactorSettings = sign => async (dispatch, getState) => {
  try {
    dispatch(fetch2FactorSettingsStart());
    const params = await dispatch(fetch2FactorSettingsReq());
    const tanParams = get(params, 'confirmationForm.tankeyParams');
    const mobipassParams = get(params, 'confirmationForm.mobipassParams');
    const smsParams = get(params, 'confirmationForm.smsParams');
    if (tanParams || mobipassParams || smsParams) {
      await dispatch(save2FactorSignature(params));

      if (smsParams) {
        const { signature } = getState();
        const { hash, schemeId } = signature.settings;
        await dispatch(createSms({ hash, schemeId, schemeName: 'ADVANCEDAUTH' }));
      }

      dispatch(fetch2FactorSettingsSuccess());
      if (tanParams && parseFloat(tanParams.countOfKeys) <= getScratchChangeCount()) {
        // await dispatch(fetchChangeScratchCardSettings());
        return Promise.resolve('change_tan');
      }
      return Promise.resolve(true);
    } else {
      if (params.error && params.error.message) {
        dispatch(showWarnNotification(params.error.message));
        if (
          params.error.message.search(/не обнаружено ни одного доступного сеансового ключа/g) !== -1
        ) {
          dispatch(fetch2FactorSettingsError());
          return Promise.resolve('change_tan');
        }
      }
      return Promise.resolve(false);
    }
  } catch (err) {
    dispatch(fetch2FactorSettingsError());
    if (err.code === 500 && sign === '2') {
      return Promise.resolve('change_tan');
    } else {
      return Promise.resolve(false);
    }
  }
};

export const confirm2FactorLogin =
  (withRedirect = true) =>
  async (dispatch, getState) => {
    const { localize, signature } = getState();
    const translate = getTranslate(localize);
    try {
      dispatch(confirm2factorLoginStart());
      const { cashToken, smsId, code, schemeId, hash, sign, confirmType } = signature.settings;
      const { value } = signature;

      const data = {
        cashToken: cashToken,
        smsId,
        code: code ? code : value,
        hash,
        schemeId,
        sign,
        confirmType,
      };

      const response = await dispatch(confirm2FactorReq(data));
      const errorMessage = get(response, 'error.message');
      // success 2 factor authorization confirm
      if (get(response, 'response.success')) {
        const authData = JSON.parse(localStorage.getItem('auth'));
        dispatch(hideNotification());
        dispatch(fetchDadataApiKey());
        dispatch(fetchKYCStatus());
        dispatch(fetchPrevAuthNotifyEnabled(true));
        dispatch(loginSuccess(authData));
        dispatch(fetchSettings());
        // dispatch(initializeChat({ requireGreetingMessage: true }));
        if (withRedirect) {
          dispatch(goTo('products'));
        }
        dispatch(confirm2factorLoginSuccess());
        return { error: false };
        // error in response
      } else if (errorMessage) {
        dispatch(confirm2factorLoginFailure());
        dispatch(showWarnNotification(errorMessage));
        return { error: true, message: errorMessage };
        // something goes wrong
      } else {
        throw new Error();
      }
    } catch (err) {
      dispatch(confirm2factorLoginFailure());
      dispatch(setLoginStep(1));
      dispatch(resetCaptcha());
      dispatch(cancelLogin());
      dispatch(showWarnNotification(translate(label('errors.cabinetNotAvailable'))));
      dispatch(loginError());
    }
  };

export const logout = withoutNotification => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(logoutStart());
    dispatch(hideNotification());
    dispatch(hideKYCNotification());
    dispatch(showOverlay());
    //await dispatch(logoutReq());
    // dispatch(resetChat());
    await dispatch(newLogoutReq());
    localStorage.removeItem('auth');
    if (typeof withoutNotification !== 'boolean' || withoutNotification === false) {
      dispatch(showInfoNotification(translate('errorsGeneral.401')));
    }
    dispatch(goTo({ needHideNotification: false, to: '/login' }));
    dispatch(hideOverlay());
    dispatch(logoutSuccess());
  } catch (err) {
    localStorage.removeItem('auth');
    if (typeof withoutNotification !== 'boolean' || withoutNotification === false) {
      dispatch(showInfoNotification(translate('errorsGeneral.401')));
    }
    dispatch(goTo({ needHideNotification: false, to: '/login' }));
    dispatch(hideOverlay());
    dispatch(logoutSuccess());
  }
};

export const clearUserCache = () => dispatch => {
  try {
    localStorage.removeItem('auth/login');

    dispatch(clearUserCacheAction());
  } catch (err) {
    dispatch(showWarnNotification(getErrorMessage(err)));
  }
};

export const fetchLoginBanners = () => async (dispatch, getState) => {
  try {
    dispatch(fetchLoginBannerStart());
    const banners = await dispatch(fetchLoginBannerListReq());
    const { error } = banners;
    if (error) {
      throw error;
    }
    const loginBanner = Array.isArray(banners)
      ? banners.find(banner => banner.title === 'Login_banner')
      : null;

    if (loginBanner) {
      const response = await dispatch(fetchLoginBannerReq(loginBanner.id));

      const slides = get(response, 'slides');

      if (Array.isArray(slides)) {
        dispatch(fetchLoginBannerSuccess(response));
      } else {
        dispatch(fetchLoginBannerFailure(response));
      }
    } else {
      dispatch(fetchLoginBannerFailure());
    }
  } catch (err) {
    if (err.code && (err.code === 503 || err.code === 502)) {
      dispatch(fetchLoginBannerFailure());
    } else {
      const { localize } = getState();
      const translate = getTranslate(localize);
      const errMessage = getErrorMessage(err, translate(label('errors.serviceUnavailable')));
      dispatch(showWarnNotification(errMessage));
      dispatch(fetchLoginBannerFailure());
    }
  }
};

export const changeLoginPagePassword = reqData => async (dispatch, getState) => {
  const { localize, settings } = getState();
  const translate = getTranslate(localize);
  try {
    const isProduction = get(settings, 'envInfo.en', 'prod') === 'prod';
    dispatch(changePasswordStart());
    if (isProduction) {
      const settings = await dispatch(fetchChangePasswordSettingsReq());
      if (settings.config) {
        const response = await dispatch(changePasswordReq({ ...settings.config, ...reqData }));
        const error = response.error;
        const success = get(response, 'success.result');
        if (success) {
          dispatch(changePasswordSuccess());
          dispatch(reset('authentication'));
          return;
        }

        if (error) {
          throw error.message;
        }
      }
    } else {
      const response = await dispatch(changePasswordReqNew({ ...reqData }));
      const error = response.error;
      const success = get(response, 'success.result');
      const formError = get(response, 'form.errors', []);
      if (
        success ||
        (formError && formError.length > 0 && formError[0].message === 'need_relogin')
      ) {
        dispatch(changePasswordSuccess());
        dispatch(reset('authentication'));
        return;
      }
      if (error) {
        throw error.message;
      }
    }

    throw translate(label('errors.serviceUnavailable'));
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(label('errors.serviceUnavailable')));
    dispatch(changePasswordFailure());
    dispatch(showWarnNotification(errMessage));
  }
};

const successAuth = authData => dispatch => {
  dispatch(hideNotification());
  dispatch(fetchDadataApiKey());
  dispatch(fetchKYCStatus());
  dispatch(fetchPrevAuthNotifyEnabled(true));
  dispatch(loginSuccess(authData));
  dispatch(fetchSettings());
  // dispatch(initializeChat({ requireGreetingMessage: true }));
  dispatch(goTo('products'));
};

export const authChangeScratchCard = reqData => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(showOverlay());
    await dispatch(changeScratchCard(reqData));
    const authData = JSON.parse(localStorage.getItem('auth'));
    dispatch(successAuth(authData));
    dispatch(confirm2factorLoginSuccess());
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(label('errors.serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
  }
};

export const skipChangeScratchCard = () => async (dispatch, getState) => {
  const { auth } = getState();
  const { resAPI = {} } = auth || {};
  const tanParams = get(resAPI, 'view.extendedAttributes.get2FA.tankeyParams');
  const mobipassParams = get(resAPI, 'view.extendedAttributes.get2FA.mobipassParams');
  const smsParams = get(resAPI, 'view.extendedAttributes.get2FA.smsParams');
  const settings =
    (tanParams && { confirmType: 'tan', ...tanParams }) ||
    (mobipassParams && { confirmType: 'mobipass', ...mobipassParams }) ||
    (smsParams && { confirmType: 'sms_code', ...smsParams });
  dispatch(postMessageSuccess({ settings }));
  dispatch(setLoginStep(3));
};

export const fetchTechNotify = () => async (dispatch, getState) => {
  await dispatch(fetchTechNotifyReq());
};
