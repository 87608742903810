import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';

import Security from './Security/Security';
// временно скрываем экран обновления анкетных данных клиента и ссылку на него https://jira.intranet/browse/DC-5139
// import KYC from './KYC/KYC';
import Personal from './Personal/Personal';
import { hideKYCNotification } from 'actions/notification';
import { fetchPrevAuthNotifyEnabled } from 'actions/security/prevAuthNotify';
import { Page404 } from 'shared';

class Profile extends Component {
  componentDidMount() {
    const { hideKYCNotification, fetchPrevAuthNotifyEnabled } = this.props;
    hideKYCNotification();
    fetchPrevAuthNotifyEnabled();
  }

  render() {
    return (
      <Switch>
        <Route exact path="/profile/security" component={Security} />
        {/* <Route exact path="/profile/kyc" component={KYC} /> */}
        <Route exact path="/profile/personal" component={Personal} />
        <Route exact path="/profile/404" component={Page404} />
        <Redirect to="/profile/404" />
      </Switch>
    );
  }
}

Profile.propTypes = {
  hideKYCNotification: PropTypes.func.isRequired,
  fetchPrevAuthNotifyEnabled: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  hideKYCNotification,
  fetchPrevAuthNotifyEnabled,
};

export default withRouter(connect(null, mapDispatchToProps)(Profile));
