import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import styles from './CalendarSmall.module.scss';

const CalendarSmall = props => {
  const dt = moment(props.date);

  return (
    <div className={styles.CalendarSmall}>
      <div className={styles.Month}>{dt.format('MMM')}</div>
      <div className={styles.Day}>{dt.format('DD')}</div>
      <div className={styles.Year}>{dt.format('YYYY')}</div>
    </div>
  );
};

CalendarSmall.propTypes = {
  date: PropTypes.string,
};

export default CalendarSmall;
