import apiServicesConstructor from 'services/apiService';
import sha256 from 'crypto-js/sha256';
import Bowser from 'bowser';

import {
  pwdRecoveryStep1ReqStub,
  pwdRecoveryStep2ReqStub,
  pwdRecoveryStep3ReqStub,
} from './pwdRecoveryStubs';

const isDevelopment = process.env.NODE_ENV === 'development';
const useGlobalStubs = process.env.REACT_APP_USE_STUBS === 'true';

export const pwdRecoveryStep1Req = (
  { passportNumber, passportSeries, birthDate, captchaId, captcha },
  useStub = false,
  withError = false
) => async (dispatch, getState) => {
  if ((useStub && isDevelopment) || useGlobalStubs) {
    return await pwdRecoveryStep1ReqStub(withError);
  }

  const data = {
    numberDocument: passportNumber,
    seriesDocument: passportSeries,
    birthDate: birthDate,
    captchaId: captchaId,
    captchaSymbols: captcha,
  };

  const reqParams = {
    url: '/webapi-1.0/bss/recovery-password/firstStep',
    headers: { 'Content-Type': 'application/json' },
    data,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const pwdRecoveryStep2Req = (
  { answer, cid, aid, conSecId },
  useStub = false,
  withError = false
) => async (dispatch, getState) => {
  if ((useStub && isDevelopment) || useGlobalStubs) {
    return await pwdRecoveryStep2ReqStub(withError);
  }

  const answerHashHex = sha256(answer.toUpperCase()).toString();

  const data = {
    codeHash: answerHashHex,
    cid,
    aid,
    conSecId,
  };
  
  const browser = Bowser.getParser(window.navigator.userAgent).getBrowser();
  const deviceInfo = encodeURI(JSON.stringify({
    browser: browser?.name,
    browserVersion:`${browser?.name} ${browser?.version}`,
    type: "web_lk"
  }))

  const reqParams = {
    url: '/webapi-1.0/bss/recovery-password/secondStep',
    headers: { 'Content-Type': 'application/json',"Device-Info": deviceInfo },
    data,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const pwdRecoveryStep3Req = (
  { sms, cid, aid, conSecId },
  useStub = false,
  withError = false
) => async (dispatch, getState) => {
  if ((useStub && isDevelopment) || useGlobalStubs) {
    return await pwdRecoveryStep3ReqStub(withError);
  }

  const data = {
    smsResult: sms,
    cid,
    aid,
    conSecId,
  };

  const browser = Bowser.getParser(window.navigator.userAgent).getBrowser();
  const deviceInfo = encodeURI(JSON.stringify({
    browser: browser?.name,
    browserVersion:`${browser?.name} ${browser?.version}`,
    type: "web_lk"
  }))

  const reqParams = {
    url: '/webapi-1.0/bss/recovery-password/thirdStep',
    headers: { 'Content-Type': 'application/json', "Device-Info": deviceInfo },
    data,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};
