import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import cn from 'classnames';
import { getActiveLanguage } from 'react-localize-redux';

import Steps from './Steps';

import {
  setTransferStep,
  fetchBeneficiaryBank,
  transfer,
  requestTransferOTP,
  confirmTransfer,
  completeTransfer,
  initializeTransfers,
} from 'actions/transfers';
import { Translation } from 'shared';
import { labelMaker, convertPhoneToInternationalFormat } from 'shared/utils';
import options from './options';

import styles from './TransferForm.module.scss';

const label = labelMaker('transfers');

class TransferForm extends Component {
  handleBackButton = () => {
    const { transfers, setStep } = this.props;

    if (transfers.transferForm.step > 1) {
      setStep(transfers.transferForm.step - 1);
    }
  };

  onSubmit = () => {
    const {
      transfers,
      transfer,
      requestTransferOTP,
      confirmTransfer,
      fetchBeneficiaryBank,
      profile,
      form,
      cards,
      signature,
      completeTransfer,
      reset,
      initializeTransfers,
    } = this.props;

    const payerIdentity = convertPhoneToInternationalFormat(profile.phone.phone);

    switch (transfers.transferForm.step) {
      case 1: {
        const { phone } = form.transfers.values;

        const beneficiaryIdentity = `007${phone}`;

        const params = {
          beneficiaryIdentity,
          payerIdentity,
        };
        return fetchBeneficiaryBank(params);
      }
      case 2: {
        const {
          phone: unformattedBeneficiaryPhone,
          payment: amount,
          beneficiaryBank: beneficiaryBankId,
          cards: cardNumber,
        } = form.transfers.values;
        const { messageId } = transfers.transferForm;
        const formattedPhone = unformattedBeneficiaryPhone
          .replace(/-/g, '')
          .replace(/\(/, '')
          .replace(/\)/, '')
          .replace(/ /, '');
        const beneficiaryIdentity = `007${formattedPhone}`;
        const card = cards.data.find(card => card.cardNumber === cardNumber);

        const params = {
          amount,
          beneficiaryBankId,
          beneficiaryIdentity,
          cardId: card.identifier,
          cbAccount: card.account,
          commissionAmount: 1,
          commissionCurrency: 'RUB',
          currencyValue: 'RUB',
          messageId,
          narrative: '1',
          payerIdentity,
          type: '1',
        };

        return confirmTransfer(params);
      }
      case 3: {
        return requestTransferOTP();
      }
      case 4: {
        const { messageId } = transfers.transferForm;

        const { confirmType, cashToken, smsLength, tankeyNumber, codeLength } = signature.settings;

        const params = {
          messageId,
          payerIdentity,
          confirmType,
          cashToken,
          smsLength,
          tankeyNumber,
          codeLength,
          code: signature.value,
        };
        return transfer(params);
      }
      case 5: {
        reset();
        initializeTransfers();
        return completeTransfer();
      }

      default:
        return;
    }
  };

  render() {
    const {
      transfers,
      handleSubmit,
      cards,
      locale,
      signature,
      requestTransferOTP,
      profile,
      form,
    } = this.props;

    return (
      <div className={styles.Form}>
        <div className={styles.Header}>
          <Translation.Div
            className={cn(styles.BackButton, {
              [styles.BackButtonHidden]:
                transfers.transferForm.step === 1 || transfers.transferForm.step === 5,
            })}
            onClick={this.handleBackButton}
            translateId={label('btns.back')}
          />
        </div>

        <form onSubmit={handleSubmit(this.onSubmit)}>
          <Steps
            step={transfers.transferForm.step}
            options={options}
            cards={cards}
            transfers={transfers}
            form={this.props.form}
            resendOTPRequest={this.resendOTPRequest}
            renderCaptchaBlock={this.renderCaptchaBlock}
            locale={locale}
            signature={signature}
            requestTransferOTP={requestTransferOTP}
            profile={profile}
          />
        </form>
      </div>
    );
  }
}

TransferForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  cards: PropTypes.object.isRequired,
  captcha: PropTypes.object.isRequired,
  transfers: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  signature: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  setStep: PropTypes.func.isRequired,
  transfer: PropTypes.func.isRequired,
  requestTransferOTP: PropTypes.func.isRequired,
  confirmTransfer: PropTypes.func.isRequired,
  fetchBeneficiaryBank: PropTypes.func.isRequired,
  completeTransfer: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  initializeTransfers: PropTypes.func.isRequired,
};

const mapState = ({ transfers, auth, form, signature, captcha, cards, localize, profile }) => ({
  transfers,
  auth,
  form,
  signature,
  cards,
  captcha,
  profile,
  locale: getActiveLanguage(localize).code,
});

const mapDispatch = {
  setStep: setTransferStep,
  transfer,
  requestTransferOTP: requestTransferOTP,
  confirmTransfer,
  fetchBeneficiaryBank,
  completeTransfer,
  initializeTransfers,
};

const form = 'transfers';

export default reduxForm({
  form,
  initialValues: {
    beneficiaryBank: '1crt88888881',
    payment: 0,
    senderPhone: '',
    phone: '',
  },
})(
  connect(
    mapState,
    mapDispatch
  )(TransferForm)
);
