import { createAction } from 'redux-act';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { showWarnNotification } from '../notification';
import { hideOverlay, showOverlay } from '../overlay';

import { formPhone, getErrorMessage, getTranslatedField, labelMaker } from 'shared/utils';

import { newMessage, postMessage, confirmMessage } from '../signature';
import { customerListReq, updateKYCReq } from './personalRequests';

const lError = labelMaker('errorsGeneral');

export const confirmKYCOTPFailure = createAction('CONFIRM_KYC_OTP_FAILURE');
export const confirmKYCOTPStart = createAction('CONFIRM_KYC_OTP_START');
export const confirmKYCOTPSuccess = createAction('CONFIRM_KYC_OTP_SUCCESS');
export const destroyKYC = createAction('DESTROY_KYC');
export const fetchPersonalDataError = createAction('FETCH_PERSONAL_DATA_ERROR');
export const fetchPersonalDataStart = createAction('FETCH_PERSONAL_DATA_START');
export const fetchPersonalDataSuccess = createAction('FETCH_PERSONAL_DATA_SUCCESS');
export const fetchPhoneFailure = createAction('FETCH_PHONE_FAILURE');
export const fetchPhoneStart = createAction('FETCH_PHONE_START');
export const fetchPhoneSuccess = createAction('FETCH_PHONE_SUCCESS');
export const requestKYCOTPFailure = createAction('REQUEST_KYC_OTP_FAILURE');
export const requestKYCOTPStart = createAction('REQUEST_KYC_OTP_START');
export const requestKYCOTPSuccess = createAction('REQUEST_KYC_OTP_SUCCESS');
export const setEditableInitialValues = createAction('SET_EDITABLE_INITIAL_VALUES');
export const updateKYCStatusFailure = createAction('UPDATE_KYC_STATUS_FAILURE');
export const updateKYCStatusStart = createAction('UPDATE_KYC_STATUS_START');
export const updateKYCStatusSuccess = createAction('UPDATE_KYC_STATUS_SUCCESS');

export const initializePersonal = (forceUpdate = true) => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(fetchPersonalDataStart());
    dispatch(showOverlay());
    const userData = await dispatch(customerListReq(forceUpdate));
    if (!userData) {
      throw new Error();
    }
    // remove white spaces from mobilePhones string
    userData.mobilePhones = userData.mobilePhones.replace(/\s/g, '').substr(1);

    dispatch(fetchPersonalDataSuccess(userData));
    const locale = getActiveLanguage(localize).code;

    const { addresses, contactInfo } = userData;

    const domicileAddress = addresses.find(address => address.type === 'DOMICILE');
    const workAddress = addresses.find(address => address.type === 'WORK');

    const domicileCountry = getTranslatedField(domicileAddress, 'countryName', {
      locale,
    });
    const domicileRegion = getTranslatedField(domicileAddress, 'regionName', {
      locale,
    });
    const domicileCity = getTranslatedField(domicileAddress, 'cityName', { locale });
    const domicileArea = getTranslatedField(domicileAddress, 'area', { locale });
    const domicileStreetType = getTranslatedField(domicileAddress, 'streetType', { locale });
    const domicileStreet = getTranslatedField(domicileAddress, 'streetName', { locale });
    // Area, house, building, apartment is required

    const workCompanyCountry = getTranslatedField(workAddress, 'countryName', { locale });
    const workCompanyRegion = getTranslatedField(workAddress, 'regionName', { locale });
    const workCompanyCity = getTranslatedField(workAddress, 'cityName', { locale });
    const workCompanyStreetType = getTranslatedField(workAddress, 'streetType', { locale });
    const workCompanyStreet = getTranslatedField(workAddress, 'streetName', { locale });
    const workCompanyArea = getTranslatedField(workAddress, 'area', { locale });

    let userContactEmail = '';
    let userContactMobilePhone = '';
    let userContactWorkPhone = '';

    if (contactInfo) {
      userContactEmail = contactInfo.email;
      userContactMobilePhone = Array.isArray(contactInfo.mobilePhone)
        ? contactInfo.mobilePhone.length
          ? formPhone(contactInfo.mobilePhone[0]).substr(1)
          : ''
        : formPhone(
          contactInfo.mobilePhone ? contactInfo.mobilePhone : contactInfo.mobilePhone
        ).substr(1);
      userContactWorkPhone = Array.isArray(contactInfo.workPhone)
        ? contactInfo.workPhone.length
          ? formPhone(contactInfo.workPhone[0]).substr(1)
          : ''
        : formPhone(contactInfo.workPhone ? contactInfo.workPhone : contactInfo.workPhone).substr(
          1
        );
    }

    const workCompanyITN = userData.inn;

    let workHouse = '';
    let workApartment = '';
    let workBuilding = '';

    if (workAddress) {
      workHouse = workAddress.house;
      workApartment = workAddress.apartment;
      workBuilding = workAddress.workBuilding;
    }

    const editableInitialValues = {
      domicileCountry,
      domicileRegion,
      domicileCity,
      domicileArea,
      domicileStreetType,
      domicileStreet,
      //
      userContactMobilePhone,
      userContactWorkPhone,
      userContactEmail,
      //
      workHouse,
      workApartment,
      workBuilding,
      //
      workCompanyCountry,
      workCompanyITN,
      workCompanyRegion,
      workCompanyCity,
      workCompanyStreetType,
      workCompanyStreet,
      workCompanyArea,
    };

    dispatch(setEditableInitialValues(editableInitialValues));
    dispatch(hideOverlay());
    return Promise.resolve(userData);
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(fetchPersonalDataError());
    dispatch(hideOverlay());
  }
};

export const requestKYCOTP = () => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(requestKYCOTPStart());
    await dispatch(newMessage());
    await dispatch(postMessage());
    dispatch(requestKYCOTPSuccess());
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(requestKYCOTPFailure());
    dispatch(hideOverlay());
  }
};

export const confirmKYC = data => async (dispatch, getState) => {
  const { localize, profile } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(confirmKYCOTPStart());
    const { cashToken, confirmType, code, schemeId, hash, smsId, isDirty, updateParams } = data;

    const confirmMessageParams = {
      cashToken,
      confirmType,
      code,
      schemeId,
      hash,
      smsId,
    };

    await dispatch(confirmMessage(confirmMessageParams));

    const kycParams = {
      confirmationType: isDirty ? 'CHANGE-SECONDARY' : 'ACTUAL', // secondary-update
      userData: isDirty ? updateParams : profile.personal.userData,
      isDirty,
    };

    const response = await dispatch(updateKYCReq(kycParams));

    if (response.status === 'ACK') {
      return dispatch(confirmKYCOTPSuccess());
    } else {
      throw translate(lError('serviceUnavailable'));
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(confirmKYCOTPFailure());
    dispatch(hideOverlay());
  }
};
