export const proxyUrl = 'https://sso-uni.demo.rooxintra.net';

// const baseURL = process.env.NODE_ENV === 'production' ? window.location.origin : proxyUrl;
const baseURL = window.location.origin;

const defaultHeaders = {
  'Content-Type': 'application/x-www-form-urlencoded',
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  withCredentials: true,
};

const settings = {
  headers: defaultHeaders,
  baseURL,
};

export default settings;
