import { createReducer } from 'redux-act';

import {
  passwordRecoverySendUserInfoStart,
  passwordRecoverySendUserInfoSuccess,
  passwordRecoverySendUserInfoFailure,
  //
  passwordRecoveryGetOTPStart,
  passwordRecoveryGetOTPSuccess,
  passwordRecoveryGetOTPFailure,
  //
  passwordRecoverySendOTPStart,
  passwordRecoverySendOTPSuccess,
  passwordRecoverySendOTPFailure,
  //
  passwordRecoveryCancel,
} from 'actions/pwdRecovery';

const defaultState = {
  step: 1,
  aid: '',
  cid: '',
  conSecId: '',
  ConSecQuestTxt: '',
  smsPhone: '',
  smsCodeNum: '',
  isRequestPending: false,
};

const pwdRecovery = createReducer(
  {
    [passwordRecoverySendUserInfoStart]: state => {
      return {
        ...state,
        isRequestPending: true,
      };
    },
    [passwordRecoverySendUserInfoSuccess]: (state, { aid, cid, conSecId, ConSecQuestTxt }) => {
      return {
        ...state,

        aid,
        cid,
        conSecId,
        ConSecQuestTxt,
        step: 2,
        isRequestPending: false,
      };
    },
    [passwordRecoverySendUserInfoFailure]: state => {
      return {
        ...state,

        aid: '',
        cid: '',
        conSecId: '',
        ConSecQuestTxt: '',
        isRequestPending: false,
      };
    },

    [passwordRecoveryGetOTPStart]: state => {
      return {
        ...state,

        isRequestPending: true,
      };
    },
    [passwordRecoveryGetOTPSuccess]: (state, { smsCodeLen, smsCodeNum, smsPhone }) => {
      return {
        ...state,

        smsCodeLen,
        smsCodeNum,
        smsPhone,
        step: 3,
        isRequestPending: false,
      };
    },
    [passwordRecoveryGetOTPFailure]: state => {
      return {
        ...state,

        smsCodeLen: '',
        smsCodeNum: '',
        smsPhone: '',
        isRequestPending: false,
      };
    },

    [passwordRecoveryCancel]: () => {
      return defaultState;
    },

    [passwordRecoverySendOTPStart]: state => {
      return {
        ...state,
        isRequestPending: true,
      };
    },

    [passwordRecoverySendOTPSuccess]: state => {
      return {
        ...state,
        isRequestPending: false,
      };
    },

    [passwordRecoverySendOTPFailure]: state => {
      return {
        ...state,
        isRequestPending: false,
      };
    },
  },
  defaultState
);

export default pwdRecovery;
