import {
  confirmMessageReq,
  createSmsReq,
  newMessageReq,
  postMessageReq,
} from './signatureRequests';
import { fetch2FactorSettings, confirm2FactorLogin } from 'actions/auth';
import { hideOverlay, showOverlay } from '../overlay';
import { isObject, getErrorMessage, labelMaker, get } from 'shared/utils';
import { requestKYCOTPSuccess, confirmKYCOTPSuccess } from 'actions/personal';
import { updateKYCReq } from 'actions/personal/personalRequests';

import { createAction } from 'redux-act';
import { getTranslate } from 'react-localize-redux';
import { showWarnNotification, showInfoNotification } from '../notification';

const lError = labelMaker('shared.errors');

export const newMessageStart = createAction('NEW_MESSAGE_START');
export const newMessageSuccess = createAction('NEW_MESSAGE_SUCCESS');
export const newMessageError = createAction('NEW_MESSAGE_ERROR');

export const postMessageStart = createAction('POST_MESSAGE_START');
export const postMessageSuccess = createAction('POST_MESSAGE_SUCCESS');
export const postMessageError = createAction('POST_MESSAGE_ERROR');

export const createSmsStart = createAction('CREATE_SMS_START');
export const createSmsSuccess = createAction('CREATE_SMS_SUCCESS');
export const createSmsError = createAction('CREATE_SMS_ERROR');

export const confirmMessageStart = createAction('CONFIRM_MESSAGE_START');
export const confirmMessageSuccess = createAction('CONFIRM_MESSAGE_SUCCESS');
export const confirmMessageError = createAction('CONFIRM_MESSAGE_ERROR');

export const hideSignature = createAction('HIDE_SIGNATURE');
export const showSignature = createAction('SHOW_SIGNATURE');
export const saveSecuritySignatureSuccess = createAction('SAVE_SECURITY_SIGNATURE_SUCCESS');
export const save2FactorSignatureSuccess = createAction('SAVE_2FACTOR_SIGNATURE_SUCCESS');

export const resetSignature = createAction('RESET_SIGNATURE');
export const signatureValueChange = createAction('SIGNATURE_VALUE_CHANGE');
export const toggleSignatureLoading = createAction('TOGGLE_SIGNATURE_LOADING');
/**
 * Some actions require signature confirmation
 * Some actions require a full new / post / confirm loop, but some only post / confirm
 *
 * If 'new message' returned property 'post', then the user can send information to the bank
 *
 * 'Post message' request return settings for signature
 * If 'post message' return smscode signType you need to send a 'create sms' request,
 * which will return the status “sent it or not” to the user
 * There may be different requests for different URLs that return the same signature settings,
 * that's why 'post message' request has the parameter 'url'
 *
 * With 'confirm message' entered signature code is sent to the bank
 * If 'confirm message' return 'success: true' you can make other requests
 */

export const newMessage = () => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);

  try {
    dispatch(newMessageStart());
    dispatch(showOverlay());
    const response = await dispatch(newMessageReq());
    if (get(response, 'data.post')) {
      dispatch(newMessageSuccess());
      return Promise.resolve(response.data.post);
    } else {
      throw translate(lError('unavailableService'));
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(hideOverlay());
    dispatch(newMessageError());
    return Promise.reject(errMessage);
  }
};

export const signKYC2FA = () => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(newMessageStart());
    dispatch(showOverlay());
    const response = await dispatch(fetch2FactorSettings());
    if (response) {
      dispatch(requestKYCOTPSuccess());
      dispatch(toggleSignatureLoading(false));
      dispatch(hideOverlay());
    } else {
      throw translate(lError('unavailableService'));
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(hideOverlay());
    dispatch(newMessageError());
    return Promise.reject(errMessage);
  }
}

export const confirmKYC2FA = data => async (dispatch, getState) => {
  const { localize, profile } = getState();
  const translate = getTranslate(localize);
  try {
    const { isDirty, updateParams } = data;
    const auth = await dispatch(confirm2FactorLogin(false));

    if (auth.error) {
      return;
    }

    const kycParams = {
      confirmationType: isDirty ? 'CHANGE-SECONDARY' : 'ACTUAL', // secondary-update
      userData: isDirty ? updateParams : profile.personal.userData,
      isDirty,
    };

    const response = await dispatch(updateKYCReq(kycParams));

    if (response.status === 'ACK') {
      return dispatch(confirmKYCOTPSuccess());
    } else {
      throw translate(lError('serviceUnavailable'));
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(showWarnNotification(errMessage));
    dispatch(hideOverlay());
  }
}

const checkSMSResponse = response => {
  if (!isObject(response)) {
    return false;
  }

  if (get(response, 'smsrespose.status') && get(response, 'smsrespose.message')) {
    return true;
  } else {
    return false;
  }
};

export const createSms = data => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);

  try {
    dispatch(showOverlay());
    dispatch(createSmsStart());

    const response = await dispatch(createSmsReq(data));
    if (checkSMSResponse(response)) {
      const {
        TimeOut: timeOut,
        message,
        status = '',
        RequestsLeft: requestsLeft,
        TimeOutLeft: timeOutLeft,
        BlockDateTime: blockDateTime,
      } = response.smsrespose;
      if (!['ERROR', 'OK', 'SEND_TIMEOUT', 'REQUESTS_EXCEEDED', 'TEMP_BLOCKED'].includes(status)) {
        throw translate(lError('unavailableService'));
      }
      dispatch(
        createSmsSuccess({
          timeOut,
          message,
          status: status.toLowerCase(),
          requestsLeft,
          timeOutLeft,
          blockDateTime,
          timeOutStart: new Date(),
        })
      );
      dispatch(hideOverlay());
      return Promise.resolve();
    } else {
      throw translate(lError('unavailableService'));
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(hideOverlay());
    dispatch(createSmsError());
    return Promise.reject(errMessage);
  }
};

export const postMessage = ({ url = '/webapi-1.0/bss/postMessage', data } = {}) => async (
  dispatch,
  getState
) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  try {
    dispatch(showOverlay());
    dispatch(postMessageStart());

    const response = await dispatch(postMessageReq({ url, data }));
    if (response) {
      await savePostMessageSettings(response.confirmationForm, dispatch, translate);
      await dispatch(checkPostMessageFormError(response));
      return Promise.resolve(response.confirmationForm);
    }
    throw translate(lError('unavailableService'));

  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(hideOverlay());
    dispatch(postMessageError());
    dispatch(hideSignature());
    return Promise.reject(errMessage);
  }
};

// check if the setting exists and throw error if not
const checkPostMessageFormError = response => (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);

  const confirmationForm = get(response, 'confirmationForm');
  const formError = get(response, 'errorList');

  // if confirmation and info errors - show error as info message and don't catch it
  if (confirmationForm && formError && Array.isArray(formError) && formError.length) {
    const errorsWithSuccess = formError.filter(error => parseFloat(error.errorCode) === 3);
    if (errorsWithSuccess.length && errorsWithSuccess[0].message) {
      dispatch(showInfoNotification(errorsWithSuccess[0].message));
      return Promise.resolve();
    } else {
      const errMessage = formError[0].message
        ? formError[0].message
        : translate(lError('unavailableService'));
      return Promise.reject(errMessage);
    }
  }

  // if no confirmation and errors
  if (!confirmationForm && Array.isArray(formError) && formError.length) {
    // throw first error
    const errMessage = formError[0].message
      ? formError[0].message
      : translate(lError('unavailableService'));
    return Promise.reject(errMessage);
  }

  if (confirmationForm) {
    return Promise.resolve();
  }
  // if no confirmation and no formError
  return Promise.reject(translate(lError('unavailableService')));
};

const savePostMessageSettings = async (confirmation, dispatch, translate) => {
  const {
    signType: confirmType = '',
    sign,
    cashToken,
    tankeyParams,
    mobipassParams,
    smsParams,
    signNeeded,
  } = confirmation;

  const params = {
    confirmType: confirmType.toLowerCase(),
    cashToken,
    sign,
  };

  // if sign don't needed
  if (!signNeeded) {
    dispatch(postMessageSuccess({ settings: params }));
    dispatch(confirmMessage(params));
  } else {
    // TAN - SCRATCH CARD
    if (confirmType.toLowerCase() === 'tan') {
      params.tankeyNumber = tankeyParams.tankeyNumber;
      params.codeLength = 6;
      dispatch(hideOverlay());
      dispatch(postMessageSuccess({ settings: params }));
      dispatch(showSignature());
    }
    // SMS
    if (confirmType.toLowerCase() === 'sms_code') {
      if (!smsParams) {
        throw translate(lError('unavailableService'));
      }
      const { hash, smsNumber: smsId, schemeId, smsLength } = smsParams;
      // if response has sms hash
      if (hash) {
        params.codeLength = smsLength;
        params.hash = hash;
        params.schemeId = schemeId;
        // get sms params
        await dispatch(postMessageSuccess({ settings: params }));

        await dispatch(createSms(params));
        dispatch(showSignature());
        dispatch(hideOverlay());
        // if response has sms id
      } else if (smsId) {
        params.smsId = smsId;
        dispatch(hideOverlay());
        dispatch(postMessageSuccess({ settings: params }));
        dispatch(showSignature());
      }
    }
    // MOBIPASS
    if (confirmType.toLowerCase() === 'mobipass') {
      params.codeLength = 6;
      params.mobipassKey = mobipassParams.mobipassKey;
      dispatch(hideOverlay());
      await dispatch(postMessageSuccess({ settings: params }));
      dispatch(showSignature());
    }
  }
};

export const saveSecuritySignature = data => (dispatch, getState) => {
  const {
    tankeyParams,
    smsParams,
    mobipassParams,
  } = data;

  let signatureSettings = {};

  if (tankeyParams) {
    signatureSettings = tankeyParams;
    signatureSettings.confirmType = 'tan';

  }
  if (smsParams) {
    signatureSettings = {
      hash: smsParams.hash,
      schemeId: smsParams.schemeId,
      schemeName: 'ADDPROTECTION',
      t: 'RT_1SMSCODE.CreateSMSbyClientRequest',
      confirmType: 'sms_code',
    };
  }
  if (mobipassParams) {
    signatureSettings = mobipassParams;
    signatureSettings.confirmType = 'mobipass';
  }

  dispatch(saveSecuritySignatureSuccess({ settings: signatureSettings }));
  return Promise.resolve();
};

export const save2FactorSignature = data => (dispatch, getState) => {
  const {
    tankeyParams: scratchCardSettings,
    smsParams: smsCodeSettings,
    mobipassParams: mobipassSettings,
  } = data.confirmationForm;

  let signatureSettings = {};

  if (scratchCardSettings) {
    signatureSettings = scratchCardSettings;
    signatureSettings.confirmType = 'tan';
  }
  if (smsCodeSettings) {
    signatureSettings = {
      hash: smsCodeSettings.hash,
      schemeId: smsCodeSettings.schemeId,
      confirmType: 'sms_code',
    };
  }
  if (mobipassSettings) {
    signatureSettings = mobipassSettings;
    signatureSettings.confirmType = 'mobipass';
  }

  dispatch(save2FactorSignatureSuccess({ settings: signatureSettings }));
  return Promise.resolve();
};

export const confirmMessage = data => async (dispatch, getState) => {
  const { localize } = getState();
  const translate = getTranslate(localize);
  dispatch(showOverlay());
  dispatch(confirmMessageStart());
  try {
    const response = await dispatch(confirmMessageReq(data));
    const { errorList } = response;
    if (response.success) {
      dispatch(hideOverlay());
      dispatch(confirmMessageSuccess());
      return Promise.resolve(response);
    } else if (errorList) {

      // here error comes in form objects as array of errors, we take the first
      const err = Array.isArray(errorList) && errorList.length ? errorList[0] : null;
      if (err) {
        const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
        dispatch(hideOverlay());
        dispatch(confirmMessageError());
        dispatch(showWarnNotification(errMessage));
        throw errMessage;
      } else {
        dispatch(hideOverlay());
        dispatch(confirmMessageError());
        throw translate(lError('unavailableService'));
      }
    }
  } catch (err) {
    const errMessage = getErrorMessage(err, translate(lError('serviceUnavailable')));
    dispatch(hideOverlay());
    dispatch(confirmMessageError());
    return Promise.reject(errMessage);
  }
};
