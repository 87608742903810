import { createReducer } from 'redux-act';

import {
  confirmKYCOTPFailure,
  confirmKYCOTPStart,
  confirmKYCOTPSuccess,
  fetchPersonalDataError,
  fetchPersonalDataStart,
  fetchPersonalDataSuccess,
  fetchPhoneFailure,
  fetchPhoneStart,
  fetchPhoneSuccess,
  requestKYCOTPFailure,
  requestKYCOTPStart,
  requestKYCOTPSuccess,
  setEditableInitialValues,
  updateKYCStatusFailure,
  updateKYCStatusStart,
  updateKYCStatusSuccess,
} from 'actions/personal';
import {
  changeLoginError,
  changeLoginStart,
  changeLoginSuccess,
  changePasswordError,
  changePasswordStart,
  changePasswordSuccess,
  changeScratchCardError,
  changeScratchCardStart,
  changeScratchCardSuccess,
  closeChangeProtectionSettings,
  confirmChangeProtection,
  fetchSecureSettingsError,
  fetchSecureSettingsStart,
  fetchSecureSettingsSuccess,
  fetchChangePasswordSettingsError,
  fetchChangePasswordSettingsStart,
  fetchChangePasswordSettingsSuccess,
  fetchChangeScratchCardSettingsError,
  fetchChangeScratchCardSettingsStart,
  fetchChangeScratchCardSettingsSuccess,
  hideChangeBlocks,
  hideChangePasswordSettings,
  hideChangeScratchCardBlock,
  openChangeProtectionSettings,
  removeSign,
  reqOTPOnChangeProtectionError,
  reqOTPOnChangeProtectionStart,
  reqOTPOnChangeProtectionSuccess,
  setProtectionError,
  setProtectionStart,
  setProtectionSuccess,
  showChangePasswordSettings,
  showChangeScratchCardBlock,
  toggleChangeLoginBlock,
  updateScratchNum,
} from 'actions/security';

import {
  toggleChangeEmailBlock,
  toggleChangePhoneBlock,
  changeEmailCancel,
  changeEmailCheckCodeError,
  changeEmailCheckCodeStart,
  changeEmailCheckStart,
  changeEmailCheckSuccess,
  changeEmailError,
  changeEmailSuccess,
  changePhoneCancel,
  changePhoneCheckCodeError,
  changePhoneCheckCodeStart,
  changePhoneCheckStart,
  changePhoneCheckSuccess,
  changePhoneError,
  changePhoneSuccess,
} from 'actions/profile';

import {
  setUiSetingsStart,
  setUiSetingsSuccess,
  setUiSetingsError,
  saveOtpSettings,
  setOtpLoading,
} from 'actions/security/prevAuthNotify';

const defaultState = {
  personal: {
    userData: null,
    editableInitialValues: null,
    showChangeEmailBlock: false,
    showChangePhoneBlock: false,

    kycForm: {
      step: 1,

      disabled: false,
      isLoading: false,
      error: false,
    },

    emailData: {
      isLoading: false,
    },
    phoneData: {
      isLoading: false,
    },

    isLoading: false,
    isLoaded: false,
    err: '',
  },
  security: {
    showChangePasswordBlock: false,
    showChangeLoginBlock: false,
    showChangeScratchCardBlock: false,
    passwordSettings: {},

    protection: {
      step: 1,
    },
    scratchCardSettings: {},
    isSettingsLoading: false,
  },
  phone: {
    phone: null,
    isLoaded: false,
  },
  prevAuthNotify: {
    enabled: true,
    isLoading: false,
    otp: {
      isLoading: false,
      settings: {
        tankeyNumber: '',
        codeLength: '',
        mobipassKey: '',
        confirmType: '',
        otpFlowState: {
          execution: '',
          sessionId: '',
        },
      },
    }
  },
  error: '',
};

const profile = createReducer(
  {
    [requestKYCOTPStart]: state => {
      return {
        ...state,

        personal: {
          ...state.personal,

          kycForm: {
            ...state.personal.kycForm,

            isLoading: true,
          },
        },
      };
    },
    [requestKYCOTPSuccess]: (state, phone) => {
      return {
        ...state,

        personal: {
          ...state.personal,

          kycForm: {
            ...state.personal.kycForm,
            step: 2,

            isLoading: false,
          },
        },
      };
    },
    [requestKYCOTPFailure]: state => {
      return {
        ...state,

        personal: {
          ...state.personal,

          kycForm: {
            ...state.personal.kycForm,

            isLoading: false,
          },
        },
      };
    },

    [confirmKYCOTPStart]: state => {
      return {
        ...state,

        personal: {
          ...state.personal,

          kycForm: {
            ...state.personal.kycForm,

            isLoading: true,
          },
        },
      };
    },
    [confirmKYCOTPSuccess]: (state, phone) => {
      return {
        ...state,

        personal: {
          ...state.personal,

          kycForm: {
            ...state.personal.kycForm,
            step: 1,

            isLoading: false,
          },
        },
      };
    },
    [confirmKYCOTPFailure]: state => {
      return {
        ...state,

        personal: {
          ...state.personal,

          kycForm: {
            ...state.personal.kycForm,
            isLoading: false,
          },
        },
      };
    },

    [fetchPhoneStart]: state => {
      return {
        ...state,

        phone: {
          ...state.phone,
          isLoading: true,
        },
      };
    },
    [fetchPhoneSuccess]: (state, phone) => {
      return {
        ...state,

        phone: {
          ...state.phone,

          phone,
          isLoading: false,
        },
      };
    },
    [fetchPhoneFailure]: state => {
      return {
        ...state,

        phone: {
          ...state.phone,
          isLoading: false,
        },
      };
    },

    [updateKYCStatusStart]: state => {
      return {
        ...state,

        personal: {
          ...state.personal,

          kycData: {
            ...state.personal.kycData,
            isLoading: true,
          },
        },
      };
    },
    [updateKYCStatusSuccess]: state => {
      return {
        ...state,

        kycData: {
          ...state.personal.kycData,
          isLoading: false,
          isLoaded: true,
        },
      };
    },
    [updateKYCStatusFailure]: state => {
      return {
        ...state,

        personal: {
          ...state.personal,

          kycData: {
            ...state.personal.kycData,
            isLoading: false,
            isLoaded: true,
          },
        },
      };
    },

    [fetchPersonalDataStart]: state => {
      return {
        ...state,

        personal: {
          ...state.personal,

          isLoading: true,
        },
      };
    },
    [fetchPersonalDataSuccess]: (state, userData) => {
      return {
        ...state,

        personal: {
          ...state.personal,

          userData,

          isLoading: false,
          isLoaded: true,
        },
      };
    },
    [fetchPersonalDataError]: (state, err) => {
      return {
        ...state,

        personal: {
          ...state.personal,

          isLoading: false,
          isLoaded: false,
          err,
        },
      };
    },

    [setEditableInitialValues]: (state, initialValues) => {
      return {
        ...state,

        personal: {
          ...state.personal,

          editableInitialValues: initialValues,
        },
      };
    },

    [toggleChangeEmailBlock]: state => {
      return {
        ...state,
        personal: {
          ...state.personal,
          showChangeEmailBlock: !state.personal.showChangeEmailBlock,
        },
      };
    },
    [changeEmailCheckStart]: (state, payload) => {
      return {
        ...state,
        personal: {
          ...state.personal,
          emailData: {
            isLoading: true,
            newEmail: payload,
          },
        },
      };
    },
    [changeEmailCheckSuccess]: state => {
      return {
        ...state,
        personal: {
          ...state.personal,
          emailData: {
            isLoading: false,
            isCodeSend: true,
          },
        },
      };
    },
    [changeEmailCheckCodeStart]: state => {
      return {
        ...state,
        personal: {
          ...state.personal,
          emailData: {
            isLoading: true,
            isCodeSend: true,
          },
        },
      };
    },
    [changeEmailCheckCodeError]: state => {
      return {
        ...state,
        personal: {
          ...state.personal,
          emailData: {
            isLoading: false,
            isCodeSend: true,
          },
        },
      };
    },
    [changeEmailError]: state => {
      return {
        ...state,
        personal: {
          ...state.personal,
          emailData: {
            isLoading: false,
            isCodeSend: false,
          },
        },
      };
    },
    [changeEmailSuccess]: (state, payload) => {
      return {
        ...state,
        personal: {
          ...state.personal,
          showChangeEmailBlock: false,
          emailData: {
            isLoading: false,
            isCodeSend: false,
          },
        },
      };
    },
    [changeEmailCancel]: state => {
      return {
        ...state,
        personal: {
          ...state.personal,
          showChangeEmailBlock: false,
          emailData: {
            isLoading: false,
            isCodeSend: false,
          },
        },
      };
    },

    [toggleChangePhoneBlock]: state => {
      return {
        ...state,
        personal: {
          ...state.personal,
          showChangePhoneBlock: !state.personal.showChangePhoneBlock,
        },
      };
    },
    [changePhoneCheckStart]: (state, payload) => {
      return {
        ...state,
        personal: {
          ...state.personal,
          phoneData: {
            isLoading: true,
            newPhone: payload,
          },
        },
      };
    },
    [changePhoneCheckSuccess]: state => {
      return {
        ...state,
        personal: {
          ...state.personal,
          phoneData: {
            isLoading: false,
            isCodeSend: true,
          },
        },
      };
    },
    [changePhoneCheckCodeStart]: state => {
      return {
        ...state,
        personal: {
          ...state.personal,
          phoneData: {
            isLoading: true,
            isCodeSend: true,
          },
        },
      };
    },
    [changePhoneCheckCodeError]: state => {
      return {
        ...state,
        personal: {
          ...state.personal,
          phoneData: {
            isLoading: false,
            isCodeSend: true,
          },
        },
      };
    },
    [changePhoneError]: state => {
      return {
        ...state,
        personal: {
          ...state.personal,
          phoneData: {
            isLoading: false,
            isCodeSend: false,
          },
        },
      };
    },
    [changePhoneSuccess]: (state, payload) => {
      return {
        ...state,
        personal: {
          ...state.personal,
          showChangePhoneBlock: false,
          phoneData: {
            isLoading: false,
            isCodeSend: false,
          },
        },
      };
    },
    [changePhoneCancel]: state => {
      return {
        ...state,
        personal: {
          ...state.personal,
          showChangePhoneBlock: false,
          phoneData: {
            isLoading: false,
            isCodeSend: false,
          },
        },
      };
    },

    [fetchChangePasswordSettingsStart]: state => {
      return {
        ...state,
        isRequestPending: true,
      };
    },

    [fetchChangePasswordSettingsSuccess]: (state, config) => {
      return {
        ...state,
        isRequestPending: false,
        security: {
          ...state.security,
          passwordSettings: config,
        },
      };
    },

    [fetchChangePasswordSettingsError]: (state, error) => {
      return {
        ...state,
        isRequestPending: false,
        error,
      };
    },

    [showChangePasswordSettings]: state => {
      return {
        ...state,
        security: {
          ...state.security,
          showChangePasswordBlock: true,
        },
      };
    },

    [hideChangePasswordSettings]: state => {
      return {
        ...state,
        security: {
          ...state.security,
          showChangePasswordBlock: false,
        },
      };
    },

    [changePasswordStart]: state => {
      return {
        ...state,
        isRequestPending: true,
      };
    },

    [changePasswordSuccess]: state => {
      return {
        ...state,
        isRequestPending: false,
      };
    },

    [changePasswordError]: state => {
      return {
        ...state,
        isRequestPending: false,
      };
    },

    [toggleChangeLoginBlock]: state => {
      return {
        ...state,
        security: {
          ...state.security,
          showChangeLoginBlock: !state.security.showChangeLoginBlock,
        },
      };
    },

    [changeLoginStart]: state => {
      return {
        ...state,
        isRequestPending: true,
      };
    },

    [changeLoginSuccess]: state => {
      return {
        ...state,
        isRequestPending: false,
      };
    },

    [changeLoginError]: state => {
      return {
        ...state,
        isRequestPending: false,
      };
    },

    [hideChangeBlocks]: state => {
      return {
        ...state,
        security: {
          ...state.security,
          showChangePasswordBlock: false,
          showChangeLoginBlock: false,
          showChangeScratchCardBlock: false,
        },
      };
    },

    [fetchSecureSettingsStart]: state => {
      return {
        ...state,
        security: {
          ...state.security,

          isSettingsLoading: true,
        },
      };
    },
    [fetchSecureSettingsSuccess]: (state, payload) => {
      const {
        questions,
        securityQuestion,
        protectionType,
        tankeyParams,
        smsParams,
        mobipassParams,
      } = payload;

      let OTPSettings = {};

      if (tankeyParams) {
        OTPSettings = tankeyParams;
      }
      if (smsParams) {
        OTPSettings = smsParams;
      }
      if (mobipassParams) {
        OTPSettings = mobipassParams;
      }

      const controlQuestion = {
        questions,
      };

      const type =
        protectionType === '1'
          ? 'controlQuestion'
          : protectionType === '2'
            ? 'controlWord'
            : protectionType === '3'
              ? 'controlDate'
              : 'noSign';

      if (type === 'controlQuestion') {
        controlQuestion.questionId = securityQuestion.id;
      }

      return {
        ...state,
        security: {
          ...state.security,
          OTPSettings,

          protection: {
            ...state.security.protection,

            type,
          },
          controlQuestion,

          isSettingsLoading: false,
        },
      };
    },
    [fetchSecureSettingsError]: (state, err) => {
      return {
        ...state,
        security: {
          ...state.security,

          isSettingsLoading: false,
        },

        error: err,
      };
    },

    [updateScratchNum]: (state, newScratchNum) => {
      return {
        ...state,
        security: {
          ...state.security,

          OTPSettings: {
            ...state.security.OTPSettings,
            N: newScratchNum,
          },
        },
      };
    },

    [openChangeProtectionSettings]: state => {
      return {
        ...state,
        security: {
          ...state.security,

          protection: {
            ...state.security.protection,

            step: 2,
          },
        },
      };
    },

    [removeSign]: state => {
      return {
        ...state,
        security: {
          ...state.security,

          protection: {
            ...state.security.protection,

            isRemoving: true,
            step: 3,
          },
        },
      };
    },

    [closeChangeProtectionSettings]: state => {
      return {
        ...state,
        security: {
          ...state.security,

          protection: {
            ...state.security.protection,

            isRemoving: false,
            step: 1,
          },
        },
      };
    },

    [showChangeScratchCardBlock]: state => {
      return {
        ...state,
        security: {
          ...state.security,
          showChangeScratchCardBlock: true,
        },
      };
    },

    [hideChangeScratchCardBlock]: state => {
      return {
        ...state,
        security: {
          ...state.security,
          showChangeScratchCardBlock: false,
        },
      };
    },

    [fetchChangeScratchCardSettingsStart]: state => {
      return {
        ...state,
        isRequestPending: true,
      };
    },
    [fetchChangeScratchCardSettingsSuccess]: (state, config) => {
      return {
        ...state,
        isRequestPending: false,
        security: {
          ...state.security,
          scratchCardSettings: config,
        },
      };
    },
    [fetchChangeScratchCardSettingsError]: state => {
      return {
        ...state,
        isRequestPending: false,
      };
    },

    [reqOTPOnChangeProtectionStart]: state => {
      return {
        ...state,

        security: {
          ...state.security,

          isLoading: true,
        },
      };
    },
    [reqOTPOnChangeProtectionSuccess]: state => {
      return {
        ...state,

        security: {
          ...state.security,

          protection: {
            ...state.security.protection,

            step: 3,
          },

          isLoading: false,
        },
      };
    },
    [reqOTPOnChangeProtectionError]: state => {
      return {
        ...state,

        security: {
          ...state.security,

          isLoading: false,
        },
      };
    },

    [setProtectionStart]: state => {
      return {
        ...state,

        security: {
          ...state.security,

          isLoading: true,
        },
      };
    },
    [setProtectionSuccess]: state => {
      return {
        ...state,

        security: {
          ...state.security,

          protection: {
            ...state.security.protection,

            step: 1,
          },
          isLoading: false,
        },
      };
    },
    [setProtectionError]: state => {
      return {
        ...state,
        security: {
          ...state.security,
          isLoading: false,
        },
      };
    },

    [confirmChangeProtection]: state => {
      return {
        ...state,
        security: {
          ...state.security,

          protection: {
            ...state.security.protection,

            step: 3,
          },
        },
      };
    },

    [changeScratchCardStart]: state => {
      return {
        ...state,
        isRequestPending: true,
      };
    },

    [changeScratchCardSuccess]: state => {
      return {
        ...state,
        isRequestPending: false,
      };
    },

    [changeScratchCardError]: state => {
      return {
        ...state,
        isRequestPending: false,
      };
    },

    [setUiSetingsStart]: state => ({
      ...state,
      prevAuthNotify: {
        ...state.prevAuthNotify,
        isLoading: true,
      }
    }),
    [setUiSetingsSuccess]: (state, { enabled }) => ({
      ...state,
      prevAuthNotify: {
        ...state.prevAuthNotify,
        enabled,
        isLoading: false,
      }
    }),
    [setUiSetingsError]: state => ({
      ...state,
      prevAuthNotify: {
        ...state.prevAuthNotify,
        isLoading: false,
      }
    }),

    [saveOtpSettings]: (state, {
      settings = defaultState.prevAuthNotify.otp.settings,
      status = '',
      otpFlowState = defaultState.prevAuthNotify.otp.otpFlowState,
    }) => ({
      ...state,
      prevAuthNotify: {
        ...state.prevAuthNotify,
        isLoading: false,
        otp: {
          ...state.prevAuthNotify.otp,
          settings,
          status,
          otpFlowState,
        }
      },
    }),
    [setOtpLoading]: (state, isLoading = false) => ({
      ...state,
      prevAuthNotify: {
        ...state.prevAuthNotify,
        otp: {
          ...state.prevAuthNotify.otp,
          isLoading,
        },
      },
    }),
  },

  defaultState
);

export default profile;
