import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './SocialCard.module.scss';

import { labelMaker } from 'shared/utils';
import { Translation } from 'shared';

const lCard = labelMaker('profile.personal');

const SocialCard = props => {
  const { name, active, onChange } = props;

  const onClick = () => {
    onChange(name, !active);
  };

  const isFacebook = name === 'facebook';
  const isVkontakte = name === 'vkontakte';
  const isInstagram = name === 'instagram';

  const CardClassName = cn(styles.Card, { [styles.CardActive]: active });
  const IconClassName = cn(styles.Icon, {
    [styles.FacebookIcon]: isFacebook,
    [styles.FacebookIconActive]: isFacebook && active,
    [styles.VkontakteIcon]: isVkontakte,
    [styles.VkontakteIconActive]: isVkontakte && active,
    [styles.InstagramIcon]: isInstagram,
    [styles.InstagramIconActive]: isInstagram && active,
  });
  const ActionClassName = cn(styles.Action, { [styles.ActionActive]: active });

  const ActionTranslate = active ? lCard('untie') : lCard('tie');

  return (
    <div className={CardClassName} onClick={active ? null : onClick}>
      <div className={styles.Row}>
        <Translation.H4 className={styles.Name} translateId={lCard(name)} />
        <div className={IconClassName} />
      </div>
      <div className={styles.Row}>
        <Translation.Div
          className={ActionClassName}
          translateId={ActionTranslate}
          onClick={active ? onClick : null}
        />
      </div>
    </div>
  );
};

SocialCard.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  active: PropTypes.bool,
};

export default SocialCard;
