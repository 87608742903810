import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getActiveLanguage, getLanguages } from 'react-localize-redux';

import { changeLanguage } from 'actions/profile';

import flagEn from '../../assets/icons/icon-flag-en.png';
import flagRu from '../../assets/icons/icon-flag-ru.svg';

import styles from './LangSwitch.module.scss';

const LangSwitch = props => {
  const changeLanguage = () => {
    const { changeLanguage, activeLanguage } = props;

    switch (activeLanguage.code) {
      case 'ru':
        return changeLanguage('en');
      case 'en':
        return changeLanguage('ru');
      default:
        changeLanguage('ru');
    }
  };

  const { activeLanguage, languages, flag = true } = props;

  return (
    <div className={styles.LanguageContainer} onClick={changeLanguage}>
      {flag ? (
        activeLanguage.code === 'ru' ? (
          <img src={flagEn} className={styles.FlagIcon} alt="English" />
        ) : (
          <img src={flagRu} className={styles.FlagIcon} alt="Russian" />
        )
      ) : null}
      {languages.find(lang => lang.code !== activeLanguage.code).name.toUpperCase()}
    </div>
  );
};

LangSwitch.propTypes = {
  changeLanguage: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  languages: PropTypes.array.isRequired,
  flag: PropTypes.bool,
};

const mapStateToProps = ({ auth, localize }) => ({
  activeLanguage: getActiveLanguage(localize),
  languages: getLanguages(localize),
});

const mapDispatchToProps = dispatch => ({
  changeLanguage: code => dispatch(changeLanguage(code)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LangSwitch);
