import { buildUrlParams, encodeDataURI } from 'shared/utils';
import apiServicesConstructor from 'services/apiService';
import {
  transferReqStub,
  fetchBeneficiaryBankReqStub,
  confirmTransferReqStub,
  fetchDefaultBankReqStub,
  setDefaultBankReqStub,
  confirmDefaultBankReqStub,
  fetchBanksReqStub,
} from './transfersStubs';

const isDevelopment = process.env.NODE_ENV === 'development';
const useGlobalStubs = process.env.REACT_APP_USE_STUBS === 'true';

export const transferReq = (reqData, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await transferReqStub(withError);
  }

  const { messageId, payerIdentity } = reqData;

  const data = buildUrlParams(encodeDataURI({ messageId, payerIdentity }));

  const reqParams = {
    url: '/webapi-1.0/payments/phone/pay/transfer',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchBeneficiaryBankReq = (reqData, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await fetchBeneficiaryBankReqStub(withError);
  }

  const { beneficiaryIdentity, payerIdentity } = reqData;

  const urlParams = buildUrlParams(encodeDataURI({ beneficiaryIdentity, payerIdentity }));

  const reqParams = {
    url: `/webapi-1.0/payments/phone/pay/bank?${urlParams}`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const confirmTransferReq = (reqData, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await confirmTransferReqStub(withError);
  }

  const {
    amount,
    beneficiaryBankId,
    beneficiaryIdentity,
    cardId,
    cbAccount,
    commissionAmount,
    commissionCurrency,
    currencyValue,
    messageId,
    narrative,
    payerIdentity,
    type,
  } = reqData;

  const data = {
    amount:Number(amount),
    beneficiaryBankId,
    beneficiaryIdentity,
    cardId,
    cbAccount,
    commissionAmount,
    commissionCurrency,
    currencyValue,
    businessMessageId:messageId,
    narrative,
    payerIdentity,
    type,
  }

  const reqParams = {
    url: '/webapi-1.0/payments/phone/pay/confirm',
    headers: { 'Content-Type': 'application/json' },
    data,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchDefaultBankReq = (reqData, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await fetchDefaultBankReqStub(withError);
  }

  const { beneficiaryIdentity, payerIdentity } = reqData;

  const urlParams = buildUrlParams(
    encodeDataURI({
      beneficiaryIdentity,
      payerIdentity,
    })
  );

  const reqParams = {
    url: `/webapi-1.0/phone/payments/pay/bank?${urlParams}`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const setDefaultBankReq = (reqData, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await setDefaultBankReqStub(withError);
  }

  const { cbAccount, payerIdentity, cardId } = reqData;

  const data = buildUrlParams(
    encodeDataURI({
      cbAccount,
      payerIdentity,
      cardId,
    })
  );

  const reqParams = {
    url: '/webapi-1.0/payments/phone/beneficiary',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const confirmDefaultBankReq = (reqData, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await confirmDefaultBankReqStub(withError);
  }

  const { payerIdentity, otp, messageId } = reqData;

  const urlParams = buildUrlParams(
    encodeDataURI({
      payerIdentity,
      otp,
      messageId,
    })
  );

  const reqParams = {
    url: `/webapi-1.0/payments/phone/beneficiary/confirmation?${urlParams}`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchBanksReq = (useStub = false, withError = false) => async (dispatch, getState) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await fetchBanksReqStub(withError);
  }

  const reqParams = {
    url: `/webapi-1.0/payments/phone/pay/bank/list?isReceiver=true&memberOptions=C2CPush`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchCard2CardSignatureReq = (useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  // if ((useStub || useGlobalStubs) && isDevelopment) {
  //   return await fetchCard2CardReqStub(withError);
  // }

  const reqParams = {
    url: `/webapi-1.0/payment-signature/card2card-signature`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchCard2CardPageReq = (reqData, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  // if ((useStub || useGlobalStubs) && isDevelopment) {
  //   return await fetchCard2CardPageReqStub(withError);
  // }

  const reqParams = {
    url: `https://unicreditbank-p2p-test.intervale.ru`,
    data: { ...reqData, ISMOBILE: 0 },
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};
