import React from 'react';
import PropTypes from 'prop-types';
import Translation from '../Translation';

import styles from './InputAction.module.scss';

const InputAction = props => {
  const { actionId, handleAction } = props;

  return actionId ? (
    <Translation.Div className={styles.Action} onClick={handleAction} translateId={actionId} />
  ) : null;
};

InputAction.propTypes = {
  actionId: PropTypes.string,
  handleAction: PropTypes.func,
};

export default InputAction;
