import get from 'lodash/get';
import { buildUrlParams, encodeDataURI } from 'shared/utils';
import apiServicesConstructor from 'services/apiService';
import {
  fetchLoanReqStub,
  fetchLoansReqStub,
  fetchNextPaymentReqStub,
  sendFileReqStub,
  fetchPrepayApplicationsReqStub,
  calculateCreditReqStub,
} from './productsStubs';

const isDevelopment = process.env.NODE_ENV === 'development';
const useGlobalStubs = process.env.REACT_APP_USE_STUBS === 'true';

export const fetchLoanReq = (
  { id, forceUpdate = true },
  useStub = false,
  withError = false
) => async (dispatch, getState) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await fetchLoanReqStub(withError);
  }

  const reqParams = {
    url: `/webapi-1.0/loans/${id}?forceUpdate=${forceUpdate}`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchLoansReq = (useStub = false, withError = false) => async (dispatch, getState) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await fetchLoansReqStub(withError);
  }

  const reqParams = {
    url: `/webapi-1.0/loans`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateLoansReq = (forceUpdate = true) => async (dispatch, getState) => {
  const reqParams = {
    url: `/webapi-1.0/loans?forceUpdate=${forceUpdate}`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchNextPaymentReq = (
  { date, sum, contractRef },
  useStub = false,
  withError = false
) => async (dispatch, getState) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await fetchNextPaymentReqStub(withError);
  }

  const reqData = {
    contractRef,
    nextPaymentDate: date,
    repayAmount: sum,
  };

  const urlParams = buildUrlParams(encodeDataURI(reqData));
  let reqParams = {
    url: `/webapi-1.0/loans/calculateNextPayment?${urlParams}`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const sendFileReq = (
  { section, params, query },
  useStub = false,
  withError = false
) => async (dispatch, getState) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await sendFileReqStub(withError);
  }

  const urlParams = query ? buildUrlParams(encodeDataURI(query)) : '';

  let url = `/webapi-1.0/render/email/${section}`;
  url = url + (params && `/${params}`);
  url = url + (query && `?${urlParams}`);

  const reqParams = {
    url,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchScheduleReq = (
  { id, dateWith, dateOn },
  useStub = false,
  withError = false
) => async (dispatch, getState) => {
  // if ((useStub || useGlobalStubs) && isDevelopment) {
  //   return await fetchScheduleReqStub(withError);
  // }

  let url;
  if (dateWith || dateOn) {
    const urlParams = buildUrlParams(
      encodeDataURI({
        fromDate: dateWith,
        toDate: dateOn,
      })
    );
    url = `/webapi-1.0/loans/${id}/schedule?${urlParams}`;
  } else {
    url = `/webapi-1.0/loans/${id}/schedule`;
  }

  const reqParams = {
    url,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const changeLoanNameReq = ({ id, name }, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  // if ((useStub || useGlobalStubs) && isDevelopment) {
  //   return await changeLoanNameReqStub(withError);
  // }

  const reqParams = {
    url: `/webapi-1.0/loans/${id}`,
    headers: { 'Content-Type': 'application/json' },
    data: { name },
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.put(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchPrepaymentDaysOffReq = (useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  // if ((useStub || useGlobalStubs) && isDevelopment) {
  //   return await fetchPrepaymentDaysOffReqStub(withError);
  // }

  const reqParams = {
    url: `/webapi-1.0/calendar`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchLoanHistoryReq = (reqData, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  // if ((useStub || useGlobalStubs) && isDevelopment) {
  //   return await fetchLoanHistoryReqStub(withError);
  // }

  const urlParams = buildUrlParams(encodeDataURI(reqData));

  const reqParams = {
    url: `/webapi-1.0/account-operations?${urlParams}`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const calculateCreditReq = (reqData, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await calculateCreditReqStub(withError);
  }

  const reqParams = {
    url: `/webapi-1.0/calcterm`,
    headers: { 'Content-Type': 'application/json' },
    data: reqData,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

const CONTENT_TYPE = 'application/json;charset=UTF-8';
const PREPAY_APPLICATION_URL = '/webapi-1.0/prepayApplication';

export const addPrepayApplicationReq = (reqData, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  const { token, otpCode, ...data } = reqData;
  let virificationHeader = {};

  // при повторном вызове запроса передается введенные ранее otpCode и формируется
  // заголовок с подписью
  if (otpCode) {
    virificationHeader = await dispatch(sendCustomerVerification(
      { otpCode, ...data },
      { contentType: CONTENT_TYPE, url: PREPAY_APPLICATION_URL }),
    );
  }

  const reqParams = {
    url: PREPAY_APPLICATION_URL,
    headers: { 'Content-Type': CONTENT_TYPE },
    data,
    throwFullError: true,
  };

  if (token) {
    reqParams.headers.Authorization = `Bearer ${token}`;
  }
  if (virificationHeader) {
    reqParams.headers = { ...reqParams.headers, ...virificationHeader };
  }

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    if (get(err, 'data.error.code') === 108) {
      return Promise.resolve(get(err, 'data.error'));
    }
    return Promise.reject(err);
  }
};

export const senSignOperation = (signingRequestId) => async (dispatch, getState) => {
  const reqParams = {
    url: `/webapi-1.0/sign-operation/send?signingRequestId=${signingRequestId}`,
    headers: {
      'Content-Type': 'application/json',
      'Accept-Encoding': 'gzip, deflate',
      Accept: '*/*',
      Connection: 'keep-alive'
    }
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
}

export const validateSignOperation = (reqData) => async (dispatch, getState) => {
  const { otpCode, ...rest } = reqData;
  const reqParams = {
    url: `/webapi-1.0/sign-operation/validate?otpCode=${otpCode}`,
    headers: { 'Content-Type': 'application/json' },
    data: rest,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
}

export const sendCustomerVerification = (reqData, reqOptions) => async (dispatch, getState) => {
  const { otpCode, ...rest } = reqData;
  const {
    contentType = 'application/json',
    method = 'POST',
    url = '',
  } = reqOptions;
  const { auth } = getState();
  const timestamp = Math.floor(Date.now() / 1000);

  const data = [
    window.location.host,
    url,
    encodeURI(window.location.search.replace(/\?/, '')),
    contentType,
    method,
    JSON.stringify(rest),
    timestamp,
    get(auth, 'user.sub'),
    '/customer',
    '',
  ].join('\n');

  try {
    const enc = new TextEncoder();
    const algorithm = { name: 'HMAC', hash: 'SHA-256' };

    const key = await crypto.subtle.importKey(
      'raw',
      enc.encode(otpCode),
      algorithm,
      false,
      ['sign'],
    );

    // const blob = new Blob([data], {type: 'text/plain'});
    // const link = document.createElement('a');
    // link.setAttribute('href', URL.createObjectURL(blob));
    // link.setAttribute('download', 'signData.txt');
    // link.click();

    const signature = await crypto.subtle.sign(
      algorithm.name,
      key,
      enc.encode(data),
    );

    // function toHexString(byteArray) {
    //   return Array.prototype.map.call(byteArray, function(byte) {
    //     return ('0' + (byte & 0xFF).toString(16)).slice(-2);
    //   }).join('');
    // }
    // console.log(toHexString(new Uint8Array(signature)), 'toHexString(signature)');

    const base64String = btoa(String.fromCharCode(...new Uint8Array(signature)));

    return {
      'X-Request-Signature': `version=1; timestamp=${timestamp}; alg=hmac-sha256; signature=${base64String}`,
      'content-type': 'application/json;charset=UTF-8',
    };
  } catch (err) {
    console.error('Error X-Request-Signature:', err);
    return { 'X-Request-Signature': err };
  }
}  

export const fetchPrepayApplicationsReq = (reqData, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await fetchPrepayApplicationsReqStub(withError);
  }

  const urlParams = buildUrlParams(encodeDataURI(reqData));

  const reqParams = {
    url: `/webapi-1.0/prepayApplication?${urlParams}`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchApplicationsStatusesReq = (reqData, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  // if ((useStub || useGlobalStubs) && isDevelopment) {
  //   return await fetchApplicationsStatusesReqStub(withError);
  // }

  const urlParams = buildUrlParams(encodeDataURI(reqData));

  const reqParams = {
    url: `/webapi-1.0/prepayApplication/status?${urlParams}`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deletePrepayApplicationReq = (id, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  // if ((useStub || useGlobalStubs) && isDevelopment) {
  //   return await deletePrepayApplicationReqStub(withError);
  // }

  const reqParams = {
    url: `/webapi-1.0/prepayApplication/${id}`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.delete(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const setProductVisibilityReq = (
  productType,
  reqData,
  useStub = false,
  withError = false
) => async (dispatch, getState) => {
  // if ((useStub || useGlobalStubs) && isDevelopment) {
  //   return await setProductVisibilityReqStub(withError);
  // }

  const urlParams = buildUrlParams(encodeDataURI(productType));

  const reqParams = {
    url: `/webapi-1.0/products/visibility?${urlParams}`,
    headers: { 'Content-Type': 'application/json' },
    data: reqData,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchBicReq = (bic, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  // if ((useStub || useGlobalStubs) && isDevelopment) {
  //   return await fetchBicReqStub(withError);
  // }

  const reqParams = {
    url: `/webapi-1.0/bic?bic=${bic}`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchCurrenciesReq = (bic, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  // if ((useStub || useGlobalStubs) && isDevelopment) {
  //   return await fetchCurrenciesReqStub(withError);
  // }

  const reqParams = {
    url: `/webapi-1.0/translate?module=2`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};
