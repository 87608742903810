// import { buildUrlParams, encodeDataURI } from 'shared/utils';
import apiServicesConstructor from 'services/apiService';

export const bonusesReq = () => async (dispatch, getState) => {
  const reqParams = {
    url: `/webapi-1.0/bonus/offers`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const operationsReq = () => async (dispatch, getState) => {
  const reqParams = { url: '/webapi-1.0/bonus/operations' };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};
