import { createSelector } from 'reselect';
import { getActiveLanguage } from 'react-localize-redux';
import moment from 'moment';

const getAccounts = state => state.accounts.data;
const getAccountsDebet = state => state.products.payment.accountToAccount.debet;
const getAccountsCredit = state => state.products.payment.accountToAccount.credit;
const getCards = state => state.cards.data;
const getCardsCatalog = state => state.cards.catalog;
const getCurrentCredit = state => state.products.credit;

export const debetAccountsSelector = createSelector(
  getAccounts,
  getCurrentCredit,
  getAccountsDebet,
  getAccountsCredit,
  getCards,
  getCardsCatalog,
  (accounts, currCredit, debet, credit, cards, catalog) => {
    if (!accounts || !debet || !credit || !cards) {
      return null;
    }
    const accountCredit = accounts.find(acc => acc.accountNo === currCredit.drsettlementacc);
    const defaultAccountCredit = accounts[0];

    const accountNo = accountCredit ? accountCredit.cbAccountNo : defaultAccountCredit.cbAccountNo;

    let debetAccounts = Array.isArray(debet)
      ? debet.filter(item => item && item.value !== accountNo)
      : [];

    debetAccounts = debetAccounts.filter(account => account.currencyIso === currCredit.ccy);

    // compare payDocument cards with cards/list and add name and availableBalance from cards list
    debetAccounts = debetAccounts.map(item => {
      if (item.iscard === '1') {
        const card = cards.filter(i => item.value === i.cardNumber || item.value === i.account);
        let updatedItem = item;
        if (card[0]) {
          // if card in cards/list
          if (
            // if there is no card name in cards/list
            !card[0].name &&
            catalog &&
            catalog.length > 0
          ) {
            // search card in cards catalog
            const catalogCard = catalog.filter(
              cardItem => cardItem.code === updatedItem.value.substring(0, 6)
            );
            if (catalogCard.length > 0) {
              updatedItem.name = catalogCard[0].textEn;
            }
          } else {
            updatedItem.name = card[0].name || item.name || '';
          }
          updatedItem.cardNumber = card[0].cardNumber;
          updatedItem.id = card[0].id;
          updatedItem.account = card[0].account;
          updatedItem.value = card[0].id;
          updatedItem.rest = card[0].availableBalance.toString();
          return updatedItem;
        } else {
          // if no card in cards/list
          // search card in cards catalog
          const catalogCard = catalog.filter(
            cardItem => cardItem.code === updatedItem.value.substring(0, 6)
          );
          if (catalogCard.length > 0) {
            updatedItem.name = catalogCard[0].textEn;
          }
          return updatedItem;
        }
      } else {
        // not card - return item as is
        return item;
      }
    });

    const sortDebetAccountsByRest = (a1, a2) => {
      return parseFloat(a2.rest) - parseFloat(a1.rest);
    };
    const sortedDebetAccounts = debetAccounts.sort(sortDebetAccountsByRest);

    return sortedDebetAccounts;
  }
);

const getRepaySettings = state => state.settings.minRepay[getActiveLanguage(state.localize).code];

export const minRepaySelector = createSelector(getRepaySettings, (minRepay) => {
  if (typeof minRepay === 'undefined') {
    return 0
  } else {
    return minRepay
  }
})

const getWorkdayCutOffSettings = state => state.settings.workdayCutOff[getActiveLanguage(state.localize).code];
const getWeekendCutOffSettings = state => state.settings.weekendCutOff[getActiveLanguage(state.localize).code];
const getCalendar = state => state.products.prepayment.daysOff.days;

export const getMinPrepayDateSelector = createSelector(getWorkdayCutOffSettings, getWeekendCutOffSettings, getCalendar,
  (workdayCutOff, weekendCutOff, daysOff) => {

    if (!daysOff) { return new Date(); }

    try {
      const tomorrow = moment().add(1, 'days');

      const checkDayIsOff = (date) => {
        return !!daysOff.find(day => day.date === date.format('YYYY-MM-DD'));
      }
      const getNextWorkingDay = () => {
        let days = 1;
        while (true) {
          const nextDate = moment().add(days, 'days');
          if (!checkDayIsOff(nextDate)) {
            return moment(nextDate).toDate();
          } else {
            if (days > 10000) {
              console.error("next working day not found");
              return tomorrow;
            }
            days++;
          }
        }
      }

      // parse timezone from settings based on next day is working or not
      let moscowDate = new Date();
      const MOSCOW_TIMEZONE = checkDayIsOff(tomorrow)
        ? parseFloat(weekendCutOff.match(/\+(\d{2})/)[1])
        : parseFloat(workdayCutOff.match(/\+(\d{2})/)[1]);
      const CUT_OFF_HOUR = checkDayIsOff(tomorrow)
        ? parseFloat(weekendCutOff.match(/^\d{2}/)[0])
        : parseFloat(workdayCutOff.match(/^\d{2}/)[0]);

      moscowDate.setHours(moscowDate.getHours() + MOSCOW_TIMEZONE, moscowDate.getMinutes() + moscowDate.getTimezoneOffset());
      if (moscowDate.getHours() >= CUT_OFF_HOUR) {
        return getNextWorkingDay();
      } else {
        return new Date();
      }
    } catch (err) {
      return new Date();
    }
  })
