import { labelMaker } from 'shared/utils';
import { date, required } from 'shared/validators';
import { date as dateMask } from 'shared/masks';

const label = labelMaker('profile.security.protection');

const secondStepOptions = {
  answer: {
    labelId: label('answer'),
    validators: [{ required }],
  },
  controlDate: {
    labelId: label('controlDate'),
    validators: [{ required }, { date }],
    mask: dateMask,
  },
  controlWord: {
    labelId: label('controlWord'),
    validators: [{ required }],
  },
  questions: {
    value: '1',
    options: [],
  },
  protection: {},
};

export default secondStepOptions;
