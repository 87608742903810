import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import styles from './Modal.module.scss';

const modalContainer = document.createElement('div');
modalContainer.setAttribute('id', 'modal');

document.body.appendChild(modalContainer);

const modalRoot = document.getElementById('modal');

class Modal extends Component {
  constructor(props) {
    super(props);

    this.el = document.createElement('div');
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      <div className={styles.Modal}>
        {
          <div className={styles.Container}>
            <div className={styles.CloseButton} onClick={this.props.onClose} />
            {this.props.children}
          </div>
        }
      </div>,
      this.el
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Modal;
