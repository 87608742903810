import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TransactionCard from '../TransactionCard/TransactionCard';

import styles from './TransactionCards.module.scss';

class TransactionCards extends Component {
  render() {
    const { onChange, name, value } = this.props;
    return (
      <div className={styles.Cards}>
        <div className={styles.CardContainer} key="scratch">
          <TransactionCard name={name} value={value} activeValue="2" onChange={onChange} />
        </div>
        <div className={styles.CardContainer} key="sms">
          <TransactionCard name={name} value={value} activeValue="4" onChange={onChange} />
        </div>
        <div className={styles.CardContainer} key="mobipass">
          <TransactionCard name={name} value={value} activeValue="3" onChange={onChange} />
        </div>
      </div>
    );
  }
}

TransactionCards.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
};

export default TransactionCards;
