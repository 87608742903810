import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './ProgressBar.module.scss';

const ProgressBar = ({className, progress = 0}) => (
  <div className={classNames(styles.ProgressBar, className)}>
    <div
      className={styles.Progress}
      style={{
        width: `${progress > 100 ? 100 : progress}%`,
      }}
    />
  </div>
);

ProgressBar.propTypes = {
  className: PropTypes.string,
  progress: PropTypes.number,
};

export default ProgressBar;
