import { required } from 'shared/validators';

const secondStepOptions = {
  code: {
    label: 'Код',
    validators: [{ required }],
  },
};

export default secondStepOptions;
