import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { isBackspaceCode } from 'shared/utils';

import styles from './CodeInput1.module.scss';

class CodeInput extends Component {
  state = {
    inputs: [],
  };

  onChange = index => event => {
    const { onChange, name, code } = this.props;
    const { inputs } = this.state;

    const newCode = [...code];

    if (code[index]) {
      newCode[index] = event.target.value.replace(code[index], '');
    } else {
      newCode[index] = event.target.value;
    }

    onChange({ name, code: newCode });

    if (inputs[index + 1]) {
      inputs[index + 1].focus();
    } else {
      inputs[index].blur();
    }
  };

  onKeyDown = index => event => {
    if (isBackspaceCode(event.keyCode)) {
      const { onChange, code, name } = this.props;
      const { inputs } = this.state;
      const newCode = [...code];

      if (newCode[index]) {
        newCode[index] = '';
        inputs[index].value = '';
      } else {
        if (inputs[index - 1]) {
          newCode[index - 1] = '';
          inputs[index - 1].value = '';
        }
      }

      if (inputs[index - 1]) {
        inputs[index - 1].focus();
      }

      onChange({ name, code: newCode });

      event.preventDefault();
    }
  };

  renderCodeField = (_, index) => {
    const { disabled, error, code } = this.props;
    const { inputs } = this.state;

    const inputClassName = cn(styles.CodeInput, {
      [styles.DisabledCodeInput]: disabled,
      [styles.ErrorCodeInput]: error,
    });

    return (
      <input
        ref={input => (inputs[index] = input)}
        className={inputClassName}
        disabled={disabled}
        value={code[index]}
        onKeyDown={this.onKeyDown(index)}
        onChange={this.onChange(index)}
        type="number"
      />
    );
  };

  render() {
    const { fields } = this.props;

    return <form>{Array.from(Array(fields)).map(this.renderCodeField)}</form>;
  }
}

CodeInput.propTypes = {
  fields: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  code: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
};

export default CodeInput;
