import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';

import Loans from 'components/Products/Loans/Loans';
import Products from 'components/Products/Products';
import {
  Layout,
  Page404,
  withNotification,
  requireAuthentication,
  withSessionProlongation,
} from 'shared';

import { resetState, fetchLoans } from 'actions/products';

import productTranslations from 'components/Products/products.json';

class ProductsPage extends Component {
  constructor(props) {
    super(props);

    props.addTranslation(productTranslations);
  }

  componentDidMount() {
    const { resetState, fetchLoans } = this.props;
    resetState();
    fetchLoans();
  }

  render() {
    const { credits } = this.props.products;

    return (
      <Layout.Authenticated>
        {credits && (
          <Switch>
            <Route exact path="/products" component={Products} />
            <Route exact path="/products/404" component={Page404} />
            <Route path="/products/loans/:id" component={Loans} />
            <Redirect to={'/products/404'} />
          </Switch>
        )}
      </Layout.Authenticated>
    );
  }
}

ProductsPage.propTypes = {
  addTranslation: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
  fetchLoans: PropTypes.func.isRequired,
  products: PropTypes.object.isRequired,
};

const mapStateToProps = ({ products }) => ({
  products,
});

const mapDispatchToProps = {
  resetState,
  fetchLoans,
};

export default withSessionProlongation(
  requireAuthentication(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(withNotification(withLocalize(ProductsPage)))
  )
);
