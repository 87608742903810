import apiServicesConstructor from 'services/apiService';

import { buildUrlParams, encodeDataURI } from 'shared/utils';

export const extendSessionReq = filter => async (dispatch, getState) => {
  const apiServices = apiServicesConstructor(dispatch, getState);

  const data = buildUrlParams(
    encodeDataURI({
      client_id: 'test_oauth2m2m',
    })
  );

  const params = { url: '/sso/oauth2-consumer/refresh', data };

  try {
    const response = await apiServices.post(params);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};
