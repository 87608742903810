import React from 'react';
import PropTypes from 'prop-types';

import styles from './InputStatus.module.scss';

const InputStatus = props => {
  const { success } = props;

  return success ? <div className={styles.SuccessIcon} /> : null;
};

InputStatus.propTypes = {
  success: PropTypes.bool,
};

export default InputStatus;
