import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import moment from 'moment';

import { paymentBackStep, saveDocumentOnPayment, confirmDocument } from 'actions/newPayment/index';
import { Button, Translation, SmsInput, MobipassInput, ScratchCardInput } from 'shared';

import {
  labelMaker,
  formatNumber,
  formatPhone,
  getProps as buildGetProps,
  getLocaleDateFormat,
} from 'shared/utils';
import { accountsSelector } from 'store/selectors/accounts';

import styles from './Step2.module.scss';

const label = labelMaker('payments');

class Step2 extends Component {
  handleSubmit = () => {
    const { fields } = this.props;
    const { value: amount } = fields.sum;
    const {
      saveDocumentOnPayment,
      accountToAccount,
      products,
      paymentAccount,
      accounts,
    } = this.props;

    const { credit } = products;

    const accountCredit = accounts.find(acc => acc.accountNo === credit.drsettlementacc)
      .cbAccountNo;

    const { debet } = accountToAccount;

    const account = debet.find(acc => acc.value === paymentAccount);

    saveDocumentOnPayment({
      accountDebet: account.cardNumber ? account.account : account.value,
      accountCredit,
      currCode: account.currencyCode,
      amount,
      dateAndTime: moment().format(),
    });
  };

  handleConfirmDocument = () => {
    const { confirmDocument, signature } = this.props;
    const { value } = signature;

    confirmDocument(value);
  };

  renderOTP = () => {
    const { fields, onChange, auth, signature } = this.props;
    const { isLoading, settings } = signature;
    const { tankeyNumber, codeLength: numOfFields, mobipassKey, confirmType = '' } = settings;
    const { phone } = auth.user;

    const codeInputProps = buildGetProps({ fields, onChange })('code');

    switch (confirmType.toLowerCase()) {
      case 'sms_code':
        return (
          <div className={styles.OTPContainer}>
            <SmsInput phoneNumber={phone ? formatPhone(phone) : null} />
            <div className={styles.SubmitContainer}>
              <Button
                onClick={this.handleConfirmDocument}
                showLoader={isLoading}
                translateId={label('send')}
              />
            </div>
          </div>
        );
      case 'mobipass':
        return (
          <div className={styles.OTPContainer}>
            <MobipassInput
              codeInputProps={codeInputProps}
              docNumber={mobipassKey}
              numOfFields={parseInt(numOfFields, 10)}
            />
            <div className={styles.SubmitContainer}>
              <Button
                onClick={this.handleConfirmDocument}
                showLoader={isLoading}
                translateId={label('send')}
              />
            </div>
          </div>
        );
      case 'tan':
        return (
          <div className={styles.OTPContainer}>
            <ScratchCardInput
              codeInputProps={codeInputProps}
              scratchCode={tankeyNumber}
              numOfFields={parseInt(numOfFields, 10)}
            />
            <div className={styles.SubmitContainer}>
              <Button
                onClick={this.handleConfirmDocument}
                showLoader={isLoading}
                translateId={label('send')}
              />
            </div>
          </div>
        );
      default:
    }
  };

  render() {
    const {
      fields,
      paymentAccount,
      accountToAccount,
      products,
      translate,
      locale,
      signature,
      accounts,
    } = this.props;
    const { value: amount } = fields.sum;
    const { credit } = products;
    const { debet, isLoading } = accountToAccount;

    const account = debet.find(acc => acc.value === paymentAccount);
    const accountDebet = account.cardNumber ? account.account : account.value;
    const accountCredit = accounts.find(acc => acc.accountNo === credit.drsettlementacc)
      .cbAccountNo;
    const currCode = account.currencyCode;

    const { showSignatureBlock } = signature;

    const dateFormat = getLocaleDateFormat(locale);
    const { Div } = Translation;

    return (
      <Fragment>
        <Translation.Div className={styles.PageTitle} translateId="payments.title" />
        <div className={styles.CurrencyTable}>
          <div className={styles.ThRow}>
            <Div className={styles.ThColInfo} translateId={label('paymentSum')} />
            <div className={styles.ThColResult}>{formatNumber(amount, { currCode })}</div>
          </div>
          <div className={styles.TdRow}>
            <Div className={styles.TdColInfo} translateId={label('operationDescription')} />
            <div className={styles.TdColResult}>{`${translate(
              label('transferTo')
            )} ${accountCredit}`}</div>
          </div>
          <div className={styles.TdRow}>
            <Div className={styles.TdColInfo} translateId={label('accountDebet')} />
            <div className={styles.TdColResult}>{accountDebet}</div>
          </div>
          <div className={styles.TdRow}>
            <Div className={styles.TdColInfo} translateId={label('dateAndTime')} />
            <div className={styles.TdColResult}>{moment().format(`${dateFormat} HH:mm`)}</div>
          </div>
          {/* <div className={styles.TdRow}>
            <Div className={styles.TdColInfo} translateId={label('type')} />
            <div className={styles.TdColResult}>Оплата</div>
          </div> */}
        </div>

        {!showSignatureBlock && (
          <div className={styles.SubmitContainer}>
            <Button
              translateId={label('continueBtn')}
              onClick={this.handleSubmit}
              showLoader={isLoading}
            />
          </div>
        )}

        {showSignatureBlock ? this.renderOTP() : null}
      </Fragment>
    );
  }
}

Step2.propTypes = {
  fields: PropTypes.shape({
    sum: PropTypes.object.isRequired,
  }).isRequired,
  locale: PropTypes.string.isRequired,
  paymentBackStep: PropTypes.func.isRequired,
  products: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  accountToAccount: PropTypes.object.isRequired,
  saveDocumentOnPayment: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  paymentAccount: PropTypes.string,
  confirmDocument: PropTypes.func.isRequired,
  signature: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  accounts: PropTypes.array,
};

const mapStateToProps = ({ localize, products, auth, signature, ...state }) => ({
  locale: getActiveLanguage(localize).code,
  translate: getTranslate(localize),
  accountToAccount: products.payment.accountToAccount,
  products: products,
  auth: auth,
  signature: signature,
  accounts: accountsSelector(state),
});

const mapDispatchToProps = {
  paymentBackStep,
  saveDocumentOnPayment,
  confirmDocument,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step2);
