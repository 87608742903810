import { isObject, labelMaker } from './utils';
import moment from 'moment';

/**
 * NOTE: validators should be functions because they provide maximum flexibility.
 */

/**
 * Submit validation start
 */

export const validateSubmit = (fields, names, submit) => {
  const newFields = names.reduce((acc, name) => {
    const field = { ...fields[name], value: fields[name].value, touched: true };
    const errorId = getValidationErrorId(field);

    return {
      ...acc,
      [name]: {
        ...field,
        touched: true,
        isError: true,
        errorId,
      },
    };
  }, '');

  submit(newFields);
};

export const isSubmitValid = (fields, names) => {
  return !names.some(name => {
    return !!getValidationErrorId(fields[name] || {});
  });
};

export const getValidationErrorId = field => {
  const { validators } = field;
  return !validators
    ? null
    : validators.reduce((acc, validator) => {
        const { fn, getMessageId } = validator;
        if (fn(field.value)) {
          return acc;
        }
        return acc ? acc : acc + getMessageId(field);
      }, '');
};

/*
 * Submit validation end
 */

export const errorMessages = (messages => ({
  ...messages,
  min: messages.gte,
  max: messages.lte,
  passport: messages.pattern,
}))({
  passportSeries: 'Неверный формат',
  birthday: 'Неверный формат',
  date: 'Неверный формат даты',
  passportNumber: 'Неверный формат',
  number: 'Не число',
  required: 'Обязательно для заполнения',
  maxLength: 'Должно быть не более {max} символов',
  minLength: 'Должно быть не менее {min} символов',
  email: 'Неверный email адрес',
  pattern: 'Неверный формат',
  gt: 'Должно быть больше {num}',
  gte: 'Должно быть больше или равно {num}',
  lt: 'Должно быть меньше {num}',
  lte: 'Должно быть меньше или равно {num}',
  isNumeric: 'Должно быть числом',
  ruLetters: 'Используйте символы русского алфавита',
  phone: 'Неверный формат номера телефона',
  checkPassword: 'Пароли не совпадают',
  isZero: 'Значение должно быть больше 0',
});

const lError = labelMaker('shared.errors');

/**
 * Validators array example:
 *  validators: [
      { required }, // no params
      { minLength: { fn: minLength(2), min: 2 }} // with a `min` param
    ],
 */
const getValidatorMessageIdFn = (name, params) => {
  return field => {
    const messageId = lError(name);

    if (!params) {
      return messageId;
    }

    const paramKeys = Object.keys(params);
    return paramKeys.reduce((res, key) => {
      const re = new RegExp(`{${key}}`, 'i');
      return res.replace(re, params[key]);
    }, messageId);
  };
};

export const makeValidators = config => {
  return config.map(obj => {
    const name = Object.keys(obj)[0];
    if (typeof obj[name] === 'function') {
      return { fn: obj[name], getMessageId: getValidatorMessageIdFn(name) };
    }
    const { fn, ...params } = obj[name];
    return { fn, getMessageId: getValidatorMessageIdFn(name, params) };
  });
};

export const setFieldValidators = (fields, name, validators) => {
  const field = fields[name];

  const unvalidateField = { ...field, validators: makeValidators(validators) };

  if (unvalidateField.touched) {
    const errorId = getValidationErrorId(unvalidateField);
    return { ...unvalidateField, errorId };
  }

  return unvalidateField;
};

export const shapeFields = fields => {
  return Object.keys(fields).reduce((result, name) => {
    const field = fields[name];
    const { validators } = field;
    return !isObject(field)
      ? { ...result, [name]: { value: field, touched: false, errorId: null } }
      : !validators
        ? { ...result, [name]: { value: '', ...field, touched: false, errorId: null } }
        : {
            ...result,
            [name]: {
              value: '',
              ...field,
              touched: false,
              errorId: null,
              validators: makeValidators(validators),
            },
          };
  }, {});
};

export const reduxFieldRequired = value => (value ? undefined : 'shared.errors.required');

export const reduxFieldmaxLength = max => value =>
  value && value.length < max ? undefined : 'shared.errors.maxLength1024';

export const reduxFieldminLength = min => value =>
  value && value.length >= min ? undefined : 'shared.errors.minLength8';

export const reduxFieldCustomMaxLength = max => value =>
  value && value.length < max ? undefined : { id: 'shared.errors.maxCustomLength', data: max };

export const reduxFieldCustomMinLength = min => value =>
  value && value.length >= min ? undefined : { id: 'shared.errors.minCustomLength', data: min };

export const reduxFieldBirthday = value =>
  !value || /^(?:0[1-9]|[12]\d|3[01])([/.-])(?:0[1-9]|1[12])\1(?:19|20)\d\d$/im.test(value)
    ? undefined
    : 'shared.errors.birthday';

export const reduxFieldBirthdayEn = value =>
  !value || moment(value, 'MM.DD.YYYY').isValid() ? undefined : 'shared.errors.birthday';
export const reduxFieldBirthdayRu = value =>
  !value || moment(value, 'DD.MM.YYYY').isValid() ? undefined : 'shared.errors.birthday';

export const reduxFieldPhone = value =>
  !value || /^\([0-9]{3}\)[\s]?[0-9]{3}-[0-9]{2}-[0-9]{2}$/im.test(value)
    ? undefined
    : 'shared.errors.phone';
export const reduxFieldPhone1 = value =>
  !value || /^\d{10}$/.test(value) ? undefined : 'shared.errors.phone';
export const reduxFieldEmail = value =>
  !value || /^[.-_a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(value)
    ? undefined
    : 'shared.errors.email';
export const reduxCard = value =>
  !value || /^(\d{4}([ ]|)\d{4}([ ]|)\d{4}([ ]|)\d{4})$/.test(value)
    ? undefined
    : 'shared.errors.card';

export const reduxAccount = value =>
  !value || /^(\d{3}([ ]|)\d{2}([ ]|)\d{3}([ ]|)\d{1}([ ]|)\d{4}([ ]|)\d{7})$/.test(value)
    ? undefined
    : 'shared.errors.account';

export const required = value => (value == null || value === '' ? false : true);

export const isZero = value => (value === 0 || value === '0' ? false : true);

export const requiredTrue = value => value === true;

export const minLength = (length = 0) => value => (value.length < length ? false : true);

export const maxLength = (length = Infinity) => value => (value.length > length ? false : true);

export const pattern = re => value => re.test(value);

export const email = value => /^[.-_a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(value);

export const phone = value =>
  /^\+[7-8][\s]?\([0-9]{3}\)[\s]?[0-9]{3}-[0-9]{2}-[0-9]{2}$/im.test(value);

export const shortPhone = value => /^\([0-9]{3}\)[\s]?[0-9]{3}-[0-9]{2}-[0-9]{2}$/im.test(value);

export const passportSeries = value => /^[0-9]{2}[\s][0-9]{2}$/im.test(value);

export const passportNumber = value => /^[0-9]{6}$/im.test(value);

export const number = value => /^\d+$/.test(value);

export const float = value => /^-?\d*(\.\d*)?$/.test(value);

export const checkPassword = (those, route) => value => {
  return (
    route.reduce((value, name) => {
      return value[name];
    }, those) === value
  );
};

export const birthday = value =>
  /^(?:0[1-9]|[12]\d|3[01])([/.-])(?:0[1-9]|1[12])\1(?:19|20)\d\d$/im.test(value);

export const date = value =>
  /^(?:0[1-9]|[12]\d|3[01])([/.-])(?:\d\d|1[12])\1(?:\d\d)\d\d$/im.test(value);

export const ruDate = value => moment(value, 'DD.MM.YYYY', true).isValid();

export const enDate = value => moment(value, 'MM.DD.YYYY', true).isValid();

export const card = value => /^(\d{4}([ ]|)\d{4}([ ]|)\d{4}([ ]|)\d{4})$/.test(value);

export const account = value =>
  /^(\d{3}([ ]|)\d{2}([ ]|)\d{3}([ ]|)\d{1}([ ]|)\d{4}([ ]|)\d{7})$/.test(value);

// greaterThan
export const gt = (param = 0) => value => {
  return Number.isFinite(value) && value > param;
};

// greaterThanOrEqual
export const gte = (param = 0) => value => {
  return Number.isFinite(value) && value >= param;
};

// alias as `min`
export const min = gte;

// lessThan
export const lt = (param = 0) => value => {
  return Number.isFinite(value) && value < param;
};

// lessThanOrEqual
export const lte = (param = 0) => value => {
  return Number.isFinite(value) && value <= param;
};

// alias as `max`
export const max = lte;

export const ruLetters = value => /^[а-яА-Я\s-]+$/.test(value);

export const passport = value => /^[0-9]{4}\s[0-9]{6}$/.test(value);

export function fieldTracker(field) {
  return {
    composeTasks: payload =>
      field.tasks ? field.tasks.reduce((acc, task) => task(acc, payload), { ...field }) : field,
  };
}
