import { labelMaker } from 'shared/utils';

const labelRange = labelMaker('shared.period');
const labelType = labelMaker('shared.periodType');

const history = {
  typeFilter: {
    value: '1',
  },
  periodFilter: {
    data: [
      { labelId: labelRange('year'), value: 'year' },
      { labelId: labelRange('period'), value: 'period' },
    ],
  },
  periodType: {
    data: [
      { labelId: labelType('all'), value: 1 },
      { labelId: labelType('online'), value: 2 },
      { labelId: labelType('NSPC'), value: 3 },
    ],
  },
};

export default history;
