import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';

import { openChangeProtectionSettings, disableSign } from 'actions/security';
import { Select, Input, Translation, Toggle } from 'shared';

import styles from './Step1.module.scss';
import loaderBlue from 'assets/icons/loader-blue.png';

import { labelMaker, getLocalizedControlQuestions } from 'shared/utils';

const { Div } = Translation;
const label = labelMaker('profile.security.protection');

class Step1 extends Component {
  renderControlQuestion = () => {
    return (
      <Fragment>
        {this.renderControlQuestionHeader()}
        {this.isSettingsLoading()
          ? this.renderSpinner()
          : this.isErrorOnLoad()
            ? this.renderErrorOnLoad()
            : this.renderControlQuestionContent()}
      </Fragment>
    );
  };

  renderControlQuestionHeader = () => {
    return (
      <Translation.Div className={styles.Header} translateId={label('controlQuestionHeader')} />
    );
  };
  renderControlQuestionContent = () => {
    const { profile, openChangeProtectionSettings, locale } = this.props;

    const { questions, questionId } = profile.security.controlQuestion;
    const formattedQuestions = questions
      ? questions.map(question => ({
        label: getLocalizedControlQuestions({ question: question.question, locale }),
        value: question.id,
      }))
      : [];

    return (
      <Fragment>
        <Div className={styles.InputLabel} translateId={label('additionalPwdRecoveryProtection')} />
        <div className={styles.InputContainer}>
          <Select options={formattedQuestions} value={questionId} disabled />
        </div>
        <div className={styles.InputContainer}>
          <Input
            labelId={label('answer')}
            actionId={label('change')}
            handleAction={openChangeProtectionSettings}
            value="secret"
            type="password"
            disabled
          />
          {/* <Translation.Div
            className={styles.SubDescription}
            translateId={label('protectionRecommendation')}
          /> */}
        </div>
      </Fragment>
    );
  };

  renderControlWord = () => {
    return (
      <Fragment>
        {this.renderControlWordHeader()}
        {this.isSettingsLoading()
          ? this.renderSpinner()
          : this.isErrorOnLoad()
            ? this.renderErrorOnLoad()
            : this.renderControlWordContent()}
      </Fragment>
    );
  };

  renderControlWordHeader = () => {
    return <Translation.Div className={styles.Header} translateId={label('controlWordHeader')} />;
  };
  renderControlWordContent = () => {
    const { openChangeProtectionSettings } = this.props;

    return (
      <Fragment>
        <Div className={styles.InputLabel} translateId={label('additionalPwdRecoveryProtection')} />
        <div className={styles.InputContainer}>
          <Input
            labelId={label('secretWord')}
            actionId={label('change')}
            handleAction={openChangeProtectionSettings}
            value="secret"
            type="password"
            disabled
          />
          {/* <Translation.Div
            className={styles.SubDescription}
            translateId={label('protectionRecommendation')}
          /> */}
        </div>
      </Fragment>
    );
  };

  renderControlDate = () => {
    return (
      <Fragment>
        {this.renderControlDateHeader()}
        {this.isSettingsLoading()
          ? this.renderSpinner()
          : this.isErrorOnLoad()
            ? this.renderErrorOnLoad()
            : this.renderControlDateContent()}
      </Fragment>
    );
  };

  renderControlDateHeader = () => {
    return <Translation.Div className={styles.Header} translateId={label('controlDateHeader')} />;
  };
  renderControlDateContent = () => {
    const { openChangeProtectionSettings } = this.props;

    return (
      <Fragment>
        <Div className={styles.InputLabel} translateId={label('additionalPwdRecoveryProtection')} />
        <div className={styles.InputContainer}>
          <Input
            labelId={label('secretDate')}
            actionId={label('change')}
            handleAction={openChangeProtectionSettings}
            value="secret"
            type="password"
            disabled
          />
          {/* <Translation.Div
            className={styles.SubDescription}
            translateId={label('protectionRecommendation')}
          /> */}
        </div>
      </Fragment>
    );
  };

  renderNoSign = () => {
    return (
      <Fragment>
        {this.renderNoSignHeader()}
        {this.isSettingsLoading()
          ? this.renderSpinner()
          : this.isErrorOnLoad()
            ? this.renderErrorOnLoad()
            : this.renderNoSignContent()}
      </Fragment>
    );
  };

  renderNoSignHeader = () => {
    return <Translation.Div className={styles.Header} translateId={styles.noSignHeader} />;
  };
  renderNoSignContent = () => {
    const { openChangeProtectionSettings, profile } = this.props;

    const toggleProps = { onChange: openChangeProtectionSettings, value: '' };

    return (
      <Fragment>
        <div className={styles.NoSignContainer}>
          <Div className={styles.ToggleLabel} translateId={label('off')} />
          <Toggle {...toggleProps} disabled={!profile.security.OTPSettings} />
        </div>
        <Div className={styles.BlockInfo} translateId={label('additionalPwdRecoveryProtection')} />
      </Fragment>
    );
  };

  renderErrorOnLoad = () => {
    return <Div className={styles.ErrorBlock} translateId={label('errorOnLoad')} />;
  };

  renderSpinner = () => {
    return (
      <div className={styles.RotatingContainer}>
        <img className={styles.Rotating} src={loaderBlue} alt="" />
      </div>
    );
  };

  isErrorOnLoad = () => {
    const { error, security } = this.props.profile;
    const { type } = security.protection;

    return error && !type;
  };
  isSettingsLoading = () => this.props.profile.security.isSettingsLoading;

  render() {
    const { type } = this.props.profile.security.protection;

    switch (type) {
      case 'controlQuestion':
        return this.renderControlQuestion();
      case 'controlWord':
        return this.renderControlWord();
      case 'controlDate':
        return this.renderControlDate();
      case 'noSign':
        return this.renderNoSign();
      default:
        return this.renderNoSign();
    }
  }
}

Step1.propTypes = {
  fields: PropTypes.object.isRequired,
  disableSign: PropTypes.func.isRequired,
  openChangeProtectionSettings: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = ({ profile, localize }) => ({
  profile,
  locale: getActiveLanguage(localize).code,
});

const mapDispatchToProps = {
  openChangeProtectionSettings,
  disableSign,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step1);
