import React, { Component, Fragment } from 'react';
import { PropTypes } from 'prop-types';
import { Translate } from 'react-localize-redux';

import metroIcon from 'assets/icons/icon-station-red.svg';
import styles from './AtmItem.module.scss';

import { labelMaker, appendCurrencySymbol } from 'shared/utils';

const label = labelMaker('atms.atm');

class AtmItem extends Component {
  getAtmGetId = ccy => {
    const { atm } = this.props;

    if (atm.atmId) {
      return `atm-${atm.atmId}-get-ccy-${ccy}`;
    }

    if (atm.branchId) {
      return `branch-${atm.branchId}-get-ccy-${ccy}`;
    }
  };

  getAtmSetId = ccy => {
    const { atm } = this.props;

    if (atm.atmId) {
      return `atm-${atm.atmId}-set-ccy-${ccy}`;
    }

    if (atm.branchId) {
      return `branch-${atm.branchId}-set-ccy-${ccy}`;
    }
  };

  render() {
    const { name, address, metro, workhours, currencies } = this.props.atm;

    return (
      <div className={styles.AtmItem}>
        <div className={styles.NameCol}>
          <div className={styles.Name}>{name}</div>
          <div className={styles.Address}>{address}</div>
          <div className={styles.OpenStatus}>
            {metro && (
              <Fragment>
                <img src={metroIcon} alt="metro" />
                {metro}
              </Fragment>
            )}
          </div>
        </div>
        <div className={styles.OpeningHoursCol}>
          <Translate id={label('openingHours')} />
          {Array.isArray(workhours) &&
            workhours
              .filter(workhour => workhour !== ' ')
              .map((workhour, index) => <div key={index}>{workhour}</div>)}
        </div>
        <div className={styles.ActionsCol}>
          {currencies && (
            <Fragment>
              <div className={styles.ActionLine}>
                <div className={styles.ActionName}>
                  <Translate id={label('withdrawal')} />
                </div>
                <div>
                  {currencies.map(ccy => (
                    <span key={this.getAtmGetId(ccy)} className={styles.CurrencyIcon}>
                      {appendCurrencySymbol('', ccy)}
                    </span>
                  ))}
                </div>
              </div>
              <div className={styles.ActionLine}>
                <div className={styles.ActionName}>
                  <Translate id={label('cash')} />
                </div>
                <div>
                  {currencies.map(ccy => (
                    <span key={this.getAtmSetId(ccy)} className={styles.CurrencyIcon}>
                      {appendCurrencySymbol('', ccy)}
                    </span>
                  ))}
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

AtmItem.propTypes = {
  atm: PropTypes.object.isRequired,
};

export default AtmItem;
