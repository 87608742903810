import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translation, SmsInput, MobipassInput, ScratchCardInput, Button } from 'shared';
import { cancel2FactorLogin } from 'actions/auth';
import { labelMaker, formatPhone } from 'shared/utils';

import styles from './Step3.module.scss';

const label = labelMaker('auth');
const lGeneral = labelMaker('general');

class Step3 extends Component {

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    const ENTER_KEY = 13;
    if (event.keyCode === ENTER_KEY) {
      this.props.submit();
    }
  }


  renderSign = () => {
    const { auth, signature } = this.props;
    const isLoading = auth.isRequestPending;
    switch (signature.settings.confirmType) {
      case 'tan':
        const { tankeyNumber } = signature.settings;
        return (
          <div className={styles.InputWrapper}>
            <ScratchCardInput scratchCode={tankeyNumber} numOfFields={6}>
              <Button type="submit" translateId={label('send')} showLoader={isLoading} />
            </ScratchCardInput>
          </div>
        );
      case 'mobipass':
        const { mobipassKey } = signature.settings;
        return (
          <div className={styles.InputWrapper}>
            <MobipassInput docNumber={mobipassKey}>
              <Button type="submit" translateId={label('send')} showLoader={isLoading} />
            </MobipassInput>
          </div>
        );
      case 'sms_code':
        const { phoneNumber } = signature.settings;
        return (
          <div className={styles.InputWrapper}>
            <SmsInput phoneNumber={phoneNumber ? formatPhone(phoneNumber) : null} />
            <Button type="submit" translateId={label('send')} showLoader={isLoading} />
          </div>
        );

      default:
        return null;
    }
  };

  render() {
    const { Div } = Translation;
    return (
      <>
        <div className={styles.NavigationContainer}>
          <div className={styles.PrevArrow} />
          <Div
            className={styles.NavigationTitle}
            translateId={lGeneral('buttons.backBtn')}
            onClick={this.props.cancel2FactorLogin}
          />
        </div>
        <div className={styles.ButtonContainer}>{this.renderSign()}</div>
      </>
    );
  }
}

Step3.propTypes = {
  auth: PropTypes.object.isRequired,
  signature: PropTypes.object.isRequired,
  cancel2FactorLogin: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

const mapStateToProps = ({ signature, auth }) => ({
  auth,
  signature,
});

export default connect(
  mapStateToProps,
  { cancel2FactorLogin }
)(Step3);
