import { createReducer } from 'redux-act';

import {
  clearAtms,
  clearBranches,
  fetchBranchesStart,
  fetchBranchesSuccess,
  fetchBranchesError,
  fetchAtmsStart,
  fetchAtmsSuccess,
  fetchAtmsError,
  setAtmsAndBranchesView,
  setAtmsAndBranchesFilter,
  clearAtmsAndBranchesFilter,
} from 'actions/atmsAndBranches/index';

const defaultState = {
  atms: {
    data: [],
  },
  branches: {
    data: [],
  },
  filters: null,
  view: 'map',
};

const atms = createReducer(
  {
    [fetchBranchesStart]: state => ({ ...state, isLoading: true }),
    [fetchBranchesSuccess]: (state, branches) => ({
      ...state,
      isLoading: false,
      branches: { data: branches.data },
    }),
    [fetchBranchesError]: state => ({ ...state, isLoading: false }),

    [fetchAtmsStart]: state => ({ ...state, isLoading: true }),
    [fetchAtmsSuccess]: (state, atms) => ({
      ...state,
      isLoading: false,
      atms: { data: atms.data },
    }),
    [fetchAtmsError]: state => ({ ...state, isLoading: false }),

    [setAtmsAndBranchesView]: (state, view) => {
      return {
        ...state,

        view,
      };
    },

    [clearAtms]: state => {
      return {
        ...state,

        atms: {
          data: [],
        },
      };
    },
    [clearBranches]: state => {
      return {
        ...state,

        branches: {
          data: [],
        },
      };
    },
    [setAtmsAndBranchesFilter]: (state, filters) => {
      return {
        ...state,
        filters,
      };
    },
    [clearAtmsAndBranchesFilter]: state => {
      return {
        ...state,
        filters: null,
      };
    },
  },
  defaultState
);

export default atms;
