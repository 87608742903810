import React from 'react';
import { Translation } from 'shared';

import defaultSlider from 'assets/slider/image-promo.png';
import styles from './DefaultSlide.module.scss';

const DefaultSlide = ({ showDefaultBanner, label }) => {
  return (
    showDefaultBanner && (
      <div className={styles.Slider} style={{ backgroundImage: `url(${defaultSlider})` }}>
        <Translation.Div className={styles.SliderTitle} translateId={label('securityRules')} />

        <Translation.Div
          className={styles.SliderDescription}
          translateId={label('securityWarning')}
        />
      </div>
    )
  );
};

export default DefaultSlide;
