import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import Steps from './Steps';

import { setDefaultBank, confirmDefaultBank, destroyDefaultBank } from 'actions/transfers';
import { convertPhoneToInternationalFormat } from 'shared/utils';
import options from './options';

class TransferForm extends Component {
  onSubmit = () => {
    const {
      transfers,
      confirmDefaultBank,
      setDefaultBank,
      signature,
      profile,
      cards,
      form,
    } = this.props;

    const payerIdentity = convertPhoneToInternationalFormat(profile.phone.phone);
    const { cards: activeCard } = form.FPSSettings.values;
    const card = cards.data.find(card => card.cardNumber === activeCard);

    switch (transfers.defaultBankAccountForm.step) {
      case 1: {
        const params = {
          cbAccount: card.account,
          payerIdentity,
          cardId: card.identifier,
        };
        return setDefaultBank(params);
      }
      case 2: {
        const params = {
          payerIdentity,
          otp: signature.value,
          messageId: transfers.defaultBankAccountForm.messageId,
        };
        return confirmDefaultBank(params);
      }
      default:
        return null;
    }
  };

  render() {
    const { transfers, handleSubmit, cards, setDefaultBank, destroyDefaultBank } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <Steps
          step={transfers.defaultBankAccountForm.step}
          options={options}
          cards={cards}
          transfers={transfers}
          form={this.props.form}
          setDefaultBank={setDefaultBank}
          destroyDefaultBank={destroyDefaultBank}
        />
      </form>
    );
  }
}

TransferForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  transfers: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  cards: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  setDefaultBank: PropTypes.func.isRequired,
  destroyDefaultBank: PropTypes.func.isRequired,
  confirmDefaultBank: PropTypes.func.isRequired,
  signature: PropTypes.object.isRequired,
};

const mapState = ({ transfers, form, cards, signature, profile }) => ({
  form,
  cards,
  transfers,
  signature,
  profile,
});

const mapDispatch = {
  setDefaultBank,
  confirmDefaultBank,
  destroyDefaultBank,
};

const form = 'FPSSettings';

export default reduxForm({
  form,
  initialValues: {
    beneficiaryBank: 1,
    payment: 0,
    receiverPhone: '',
  },
})(
  connect(
    mapState,
    mapDispatch
  )(TransferForm)
);
