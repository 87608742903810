import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Translation } from '../../';

import styles from './ReduxCheckbox.module.scss';

const CheckBox = props => {
  const { disabled, input, labelId } = props;

  const onClick = () => {
    if (!disabled) {
      props.input.onChange(!input.value);
    }
  };

  const CheckmarkClassName = cn(styles.Checkmark, {
    [styles.CheckmarkActive]: input.value,
    [styles.CheckmarkDisabled]: disabled && !input.value,
    [styles.CheckmarkActiveDisabled]: disabled && input.value,
  });

  const LabelContainerClassName = cn(styles.LabelContainer, {
    [styles.DisabledLabelContainer]: disabled,
  });

  return (
    <label className={styles.Label}>
      <input
        type="checkbox"
        value={input.value}
        checked={input.value}
        onChange={onClick}
        name={input.name}
      />
      <span className={CheckmarkClassName} />
      <Translation.Div className={LabelContainerClassName} translateId={labelId} />
    </label>
  );
};

CheckBox.propTypes = {
  labelId: PropTypes.string,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  red: PropTypes.bool,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default CheckBox;
