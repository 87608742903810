import { createSelector } from 'reselect';

const getHistory = state => state.operationsHistory.data;

export const operationsHistorySelector = createSelector(
  getHistory,
  history => {
    if (!history) {
      return [];
    }
    return history;
  }
);
