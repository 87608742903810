import React from 'react';
import axios from 'axios';
import { showWarnNotification } from 'actions/notification';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { labelMaker } from 'shared/utils';

const withErrorBoundary = (Content, componentName) => {
  class ErrorBoundary extends React.Component {
    state = { hasError: false };
    lError = labelMaker('errorsGeneral');
    sendError = error => {
      const data = {
        errorMessage: error.toString(),
        componentName: componentName,
      };
      this.props.showWarnNotification(this.props.translate(this.lError('serviceUnavailable')));

      axios
        .post('/webapi-1.0/errors', data)
        .then(() => console.info('Error sent successfully'))
        .catch(() => console.error('Error sending failed'));
    };

    componentDidCatch(error, info) {
      this.setState({ hasError: true }, () => this.sendError(error));
    }

    render() {
      if (this.state.hasError) {
        return <div />;
      } else {
        return <Content {...this.props} />;
      }
    }
  }

  ErrorBoundary.propTypes = {
    auth: PropTypes.object,
    component: PropTypes.string,
    children: PropTypes.node,
    showWarnNotification: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
  };

  const mapStateToProps = ({ localize }) => ({
    translate: getTranslate(localize),
  });

  return connect(
    mapStateToProps,
    { showWarnNotification }
  )(ErrorBoundary);
};

export default withErrorBoundary;
