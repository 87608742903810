import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { labelMaker } from 'shared/utils';

import styles from './Process.module.scss';

const label = labelMaker('shared.process');

const Process = props => {
  const { translate, status } = props;

  const isStatusOrange = status => status === 'hold';
  const isStatusGrey = status =>
    status === 'cart' ||
    status === 'ord-ack' ||
    status === 'wait-cc' ||
    status === 'acc-abs';
  const isStatusRed = status =>
    status === 'err' ||
    status === 'rjt' ||
    status === 'rjtc' ||
    status === 'no-funds' ||
    status === 'rjto';
  const isStatusGreen = status => props.status === 'done';

  const isGrey = isStatusGrey(status);
  const isOrange = isStatusOrange(status);
  const isGreen = isStatusGreen(status);
  const isRed = isStatusRed(status);

  return (
    <div
      className={classNames(styles.Process, {
        [styles.Grey]: isGrey,
        [styles.Orange]: isOrange,
        [styles.Green]: isGreen,
        [styles.Red]: isRed,
        [styles.Simple]: !isGrey && !isOrange && !isGreen && !isRed,
      })}
    >
      <span>{translate(label(status), null, { onMissingTranslation: () => status })}</span>
    </div>
  );
};

Process.propTypes = {
  status: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(Process);
