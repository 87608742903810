import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate, getActiveLanguage } from 'react-localize-redux';
import { Field, change, untouch } from 'redux-form';

import { goTo } from 'actions/routes';

import { Button } from 'shared';
import { getLocalizedControlQuestions } from 'shared/utils';

import styles from './Step4.module.scss';

class Step4 extends React.Component {
  renderControlQuestionMenu({ visible, options, disabled }) {
    if (visible) {
      const controlQuestions = getLocalizedControlQuestions({ locale: this.props.locale });
      return (
        <Fragment>
          <div className={styles.InputRow}>
            <Field
              {...options.controlQuestion}
              options={controlQuestions}
              validate={disabled ? null : options.controlQuestion.validate}
              disabled={disabled}
            />
          </div>
          <div className={styles.InputRow}>
            <Field
              {...options.controlQuestionAnswer}
              validate={disabled ? null : options.controlQuestionAnswer.validate}
              disabled={disabled}
            />
          </div>
          <div className={styles.Recommendation}>
            <Translate id="signup.protectionRecommendation" />
          </div>
        </Fragment>
      );
    }
  }
  renderControlWordMenu({ visible, options, disabled }) {
    if (visible) {
      return (
        <Fragment>
          <div className={styles.InputRow}>
            <Field
              {...options.controlWord}
              validate={disabled ? null : options.controlWord.validate}
              disabled={disabled}
            />
          </div>
          <div className={styles.Recommendation}>
            <Translate id="signup.protectionRecommendation" />
          </div>
        </Fragment>
      );
    }
  }
  renderControlDateMenu({ visible, options, disabled }) {
    if (visible) {
      return (
        <Fragment>
          <div className={styles.InputRow}>
            <Field
              {...options.controlDate}
              validate={disabled ? null : options.controlDate.validate}
              disabled={disabled}
            />
          </div>
          <div className={styles.Recommendation}>
            <Translate id="signup.protectionRecommendation" />
          </div>
        </Fragment>
      );
    }
  }

  clearFields = () => {
    const { change, untouch } = this.props;

    change('registration', 'controlQuestionAnswer', null);
    untouch('registration', 'controlQuestionAnswer', null);
    change('registration', 'controlWord', null);
    untouch('registration', 'controlWord', null);
    change('registration', 'controlDate', null);
    untouch('registration', 'controlDate', null);
  };

  render() {
    const { options, form, signup } = this.props;

    const { protection, protectionStatus } = form.registration.values;

    const isFieldDisabled = signup.isRequestPending || !protectionStatus;

    return (
      <Fragment>
        <div className={styles.Text}>
          <Translate id="signup.protectionChoice" />
        </div>

        <div className={styles.RadioContainer}>
          <Field
            {...options.protection}
            activeValue="controlQuestion"
            disabled={isFieldDisabled}
            labelClassName={styles.RadioLabel}
            onChangeWrapper={this.clearFields}
          >
            <Translate id="signup.controlQuestion" />
          </Field>
        </div>
        {this.renderControlQuestionMenu({
          visible: protection === 'controlQuestion',
          options,
          disabled: isFieldDisabled,
        })}
        <div className={styles.RadioContainer}>
          <Field
            {...options.protection}
            activeValue="controlWord"
            disabled={isFieldDisabled}
            labelClassName={styles.RadioLabel}
          >
            <Translate id="signup.controlWord" />
          </Field>
        </div>
        {this.renderControlWordMenu({
          visible: protection === 'controlWord',
          options,
          disabled: isFieldDisabled,
        })}

        <div className={styles.RadioContainer}>
          <Field
            {...options.protection}
            activeValue="controlDate"
            disabled={isFieldDisabled}
            labelClassName={styles.RadioLabel}
          >
            <Translate id="signup.controlDate" />
          </Field>
        </div>

        {this.renderControlDateMenu({
          visible: protection === 'controlDate',
          options,
          disabled: isFieldDisabled,
        })}

        <div className={styles.RecoveryContainer}>
          <Translate id="signup.remotePasswordRecovery" />
          <Field {...options.protectionStatus} />
        </div>
        <div className={styles.RecoveryDescription}>
          <Translate id="signup.remotePasswordRecoveryDescription" />
        </div>
        <Button type="submit" showLoader={signup.isRequestPending} translateId="signup.continueBtn" />
      </Fragment>
    );
  }
}

Step4.propTypes = {
  form: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  signup: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  goTo: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  signup: state.signup,
  auth: state.auth,
  locale: getActiveLanguage(state.localize).code,
});

const mapDispatchToProps = {
  // setUserProtection: args => dispatch(setUserProtection(args)),
  goTo,
  change,
  untouch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step4);
