import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import numeral from 'numeral';

import InputError from '../../InputError/InputError';

import { calculateWidth } from 'shared/utils';

import styles from './NumericInput.module.scss';

class Input extends Component {
  calculateValueWidth = value => {
    let hasFractionalValue = false;
    let fractional = '';

    if (value) {
      hasFractionalValue = value.toString().indexOf('.') > 0;

      if (hasFractionalValue) {
        fractional = value.toString().slice(value.toString().indexOf('.'));
      }
    }

    return calculateWidth(
      `${numeral(value)
        .format('0,0')
        .replace(/,/g, ' ')}${fractional}`,
      30
    );
  };

  render() {
    const { disabled, meta, input, type } = this.props;
    const { active, touched, error } = meta;
    const isInputFocused = active && !disabled;
    const isErrorVisible = touched && error && !disabled;

    const externalBorderClassName = cn(styles.ExternalBorder, {
      [styles.DisabledExternalBorder]: disabled,
      [styles.FocusedExternalBorder]: isInputFocused,
      [styles.ErrorExternalBorder]: isErrorVisible,
    });
    const internalBorderClassName = cn(styles.InternalBorder, {
      [styles.FocusedInternalBorder]: isInputFocused,
      [styles.ErrorInternalBorder]: isErrorVisible,
    });
    const CurrencyClassName = cn(styles.Currency, {
      [styles.DisabledCurrency]: disabled,
    });

    const defaultCurrencyLeftMargin = 10;
    const leftMargin = this.calculateValueWidth(input.value) + defaultCurrencyLeftMargin;

    const err = touched ? error : null;

    return (
      <div className={styles.Container}>
        <div className={externalBorderClassName}>
          <div className={internalBorderClassName}>
            <span className={CurrencyClassName} style={{ marginLeft: leftMargin }}>
              ₽
            </span>
            <div className={styles.InputContainer}>
              <input
                autoComplete="off"
                className={styles.Input}
                disabled={disabled}
                type={type}
                {...input}
              />
            </div>
          </div>
        </div>
        <InputError errorId={err} />
      </div>
    );
  }
}

Input.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  statusElement: PropTypes.bool,
  type: PropTypes.string,
};

Input.defaultProps = {
  onChange: () => {},
  formatRender: value => value,
  value: '',
};

export default Input;
