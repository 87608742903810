import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Radio.module.scss';

const Radio = props => {
  const { activeValue, labelClassName, children, input, disabled, onChangeWrapper } = props;

  const onChange = () => {
    onChangeWrapper();
    input.onChange(activeValue);
  };

  return (
    <label
      className={cn(styles.Label, {
        [styles.DisabledLabel]: disabled,
      })}
    >
      <input
        type="radio"
        disabled={disabled}
        {...input}
        value={activeValue}
        onChange={onChangeWrapper ? onChange : input.onChange}
      />
      <span className={styles.CheckMark} />
      <div
        className={cn([
          styles.LabelContainer,
          { [styles.DisabledLabelContainer]: disabled },
          { [labelClassName]: labelClassName },
        ])}
      >
        {children}
      </div>
    </label>
  );
};

Radio.propTypes = {
  onChangeWrapper: PropTypes.func,
  input: PropTypes.object.isRequired,
  labelClassName: PropTypes.string,
  activeValue: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.object,
};

export default Radio;
