import { ReduxToggle, ReduxYMapsLocationInput } from 'shared';

export const options = {
  atms: {
    component: ReduxToggle,
    name: 'atms',
    defaultValue: true,
  },
  branches: {
    component: ReduxToggle,
    name: 'branches',
    defaultValue: true,
  },
  metro: {
    component: ReduxYMapsLocationInput,
    name: 'metro',
    kind: 'address',
  },
  city: {
    component: ReduxYMapsLocationInput,
    name: 'city',
    kind: 'city',
  },
};

export const initialValues = Object.keys(options).reduce((values, key) => {
  values[key] = options[key].defaultValue;
  return values;
}, {});

export default options;
