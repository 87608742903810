import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Steps from './Steps';
import fieldOptions from './Steps/options';

import { Translation, Toggle } from 'shared';
import { labelMaker } from 'shared/utils';
import { shapeFields, getValidationErrorId } from 'shared/validators';
import { closeChangeProtectionSettings, disableSign } from 'actions/security';

import styles from './Form.module.scss';

const label = labelMaker('profile.security.protection');

class SecurityForm extends Component {
  state = {
    fields: shapeFields({ ...fieldOptions }),
  };

  resetForm = () => {
    const { closeChangeProtectionSettings } = this.props;

    this.setState({ fields: shapeFields({ ...fieldOptions }) });
    closeChangeProtectionSettings();
  };

  onChange = ({ value, name }, showError) => {
    const { fields } = this.state;
    const field = fields[name];

    const { isError } = field;

    const newField = { ...field, value, touched: true, isError: showError || isError };
    const errorId = getValidationErrorId(newField);

    this.setState({
      fields: {
        ...fields,
        [name]: {
          ...newField,
          errorId,
        },
      },
    });
  };

  onRadioChange = ({ value, name }, showError) => {
    const { fields } = this.state;
    const field = fields[name];

    const { isError } = field;

    const newField = { ...field, value, touched: true, isError: showError || isError };
    const errorId = getValidationErrorId(newField);

    const protectionFieldsKeys = ['answer', 'controlDate', 'controlWord'];

    const protectionFieldsWithoutErrors = protectionFieldsKeys.reduce((acc, key) => {
      acc = {
        ...acc,
        [key]: {
          ...fields[key],
          isError: false,
          errorId: '',
        },
      };
      return acc;
    }, {});

    this.setState({
      fields: {
        ...fields,
        ...protectionFieldsWithoutErrors,
        [name]: {
          ...newField,
          errorId,
        },
      },
    });
  };

  submitFields = newFields => {
    const { fields } = this.state;

    this.setState({
      fields: {
        ...fields,
        ...newFields,
      },
    });
  };

  handleToggle = () => {
    const {
      security: {
        protection: { step },
      },
      closeChangeProtectionSettings,
      disableSign,
    } = this.props;
    if (step === 3) {
      closeChangeProtectionSettings();
    } else {
      disableSign();
    }
  };

  render() {
    const {
      security: {
        protection: { step, type, isRemoving },
        isSettingsLoading,
      },
    } = this.props;
    const { fields } = this.state;

    const toggleProps = {
      onChange: this.handleToggle,
      value: isRemoving ? false : !(type === 'noSign'),
    };

    return (
      <Fragment>
        <div className={styles.BlockTitleContainer}>
          <Translation.Div className={styles.BlockTitle} translateId={label('digitalSign')} />

          {!isSettingsLoading &&
            type !== 'noSign' && (
              <div className={styles.SubtitleContainer}>
                <div>
                  <Translation.Div className={styles.Subtitle} translateId={label('included')} />
                  <Translation.Div
                    className={styles.SubDescription}
                    translateId={label('digitalSignDescription')}
                  />
                </div>
                <Toggle {...toggleProps} />
              </div>
            )}
        </div>
        <Steps
          fields={fields}
          submitFields={this.submitFields}
          step={step}
          onChange={this.onChange}
          onRadioChange={this.onRadioChange}
          resetForm={this.resetForm}
        />
      </Fragment>
    );
  }
}

SecurityForm.propTypes = {
  security: PropTypes.object.isRequired,
  closeChangeProtectionSettings: PropTypes.func.isRequired,
  disableSign: PropTypes.func.isRequired,
};

const mapStateToProps = ({ profile }) => ({
  security: profile.security,
});

const mapDispatchToProps = {
  closeChangeProtectionSettings,
  disableSign,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecurityForm);
