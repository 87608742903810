import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getTranslate } from 'react-localize-redux';
import loaderBlue from '../../assets/icons/loader-blue.png';
import cn from 'classnames';

import styles from './Select.module.scss';

class Select extends Component {
  state = {
    isOpen: false,
  };

  toggleSelect = () => {
    const { isLoading, disabled } = this.props;
    const { isOpen } = this.state;

    if (!isLoading && !disabled) {
      this.setState({
        isOpen: !isOpen,
      });
    }
  };

  renderOption = option => {
    const { value: activeValue, renderOption, translate } = this.props;
    const { label, labelId, value } = option;

    const isActive = activeValue && value ? activeValue.toString() === value.toString() : false;

    if (renderOption) {
      return renderOption({ handleOption: this.handleOption, isActive: isActive, option });
    }

    const text = labelId ? translate(labelId) : label;

    return (
      <div
        className={cn(styles.Option, { [styles.ActiveOption]: isActive })}
        onClick={this.handleOption(value)}
        key={`${label}, ${value}`}
      >
        {text}
      </div>
    );
  };

  renderLabel = () => {
    const { isLoading, value, renderLabel, options, translate } = this.props;
    if (isLoading) {
      return this.renderSpinner();
    }

    if (value !== null) {
      const activeOption =
        Array.isArray(options) && options.length
          ? options.find(option => option.value === value)
          : '';

      if (activeOption) {
        if (renderLabel) {
          return renderLabel(activeOption);
        }

        const label = activeOption
          ? activeOption.labelId
            ? translate(activeOption.labelId)
            : activeOption.label
          : '';

        return label;
      }
    }
  };

  handleOption = value => () => {
    const { name, onChange } = this.props;

    onChange({ name, value });
    this.toggleSelect();
  };

  renderSpinner = () => {
    return <img className={styles.Rotating} src={loaderBlue} alt="loader" />;
  };

  render() {
    const { options, isLoading, disabled, menuClassName } = this.props;
    const { isOpen } = this.state;

    const SelectClassName = cn(styles.Select, {
      [styles.SelectOpen]: isOpen,
      [styles.DisabledSelect]: disabled,
    });
    const ExternalBorderClassName = cn(styles.ExternalBorder, {
      [styles.ExternalBorderOpen]: isOpen,
      [styles.DisabledExternalBorder]: disabled,
    });
    const IconClassName = cn(styles.IconDown, { [styles.IconUp]: isOpen });
    const MenuClassName = cn(styles.Menu, {
      [styles.MenuOpen]: isOpen,
      [menuClassName]: menuClassName,
    });

    return (
      <div className={styles.Container}>
        <div className={ExternalBorderClassName}>
          <div className={SelectClassName} onClick={this.toggleSelect}>
            {this.renderLabel()}
          </div>
          <div className={IconClassName} />
        </div>
        {Array.isArray(options) && options.length && !isLoading ? (
          <div className={MenuClassName}>{options.map(this.renderOption)}</div>
        ) : null}
      </div>
    );
  }
}

Select.propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  renderOption: PropTypes.func,
  renderLabel: PropTypes.func,
  translate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  menuClassName: PropTypes.string,
};

Select.defaultProps = {
  isLoading: false,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(Select);
