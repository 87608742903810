/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import moment from 'moment';

import { DateInput, NumericInput, InputRange, Button, Translation, InfoComponent } from 'shared';
import { calculateCredit, fetchPrepaymentDaysOff, fetchNextPayment } from 'actions/products';

import { accountsSelector } from 'store/selectors/accounts';
import { minRepaySelector } from 'store/selectors/products';

import {
  formatNumber,
  getProps as buildGetProps,
  labelMaker,
  debounce,
  getNumOfDecimal,
  print,
  get,
  isFullPayment as isFullPaymentCheck,
} from 'shared/utils';
import { validateSubmit, isSubmitValid } from 'shared/validators';
import PrepaymentInfo from '../../../../components/PrepaymentInfo/PrepaymentInfo';
import styles from './Step1.module.scss';

const label = labelMaker('products.prepayment');
// const holidayLabel = labelMaker('products.prepayment.creditHolidays');

const FullEarlyRepaymentInOffice = () => (
  <>
    <Translation.Span className={styles.Subtitle} translateId={label('fullEarlyRepaymentInOffice')} />
    <Link to="/atms/map">
      <Translation.Span className={styles.Link} translateId={label('fullEarlyRepayment_office')} />
    </Link>
    <>.</>
  </>
)

class Step1 extends Component {
  constructor(props) {
    super(props);
    this.debouncedCalc = debounce(this.calcterm, 500);
    this.state = {
      accountLinkedCredit: { name: '', cbAccountNo: '', CurrentBalance: '', ccy: '' }
    }
  }

  calcterm = () => {
    const { fields, credit, dateFormat } = this.props;
    const sum = parseFloat(fields.sum.value);
    const date = moment(fields.date.value, dateFormat).format('YYYY-MM-DD');

    if (sum < credit.principal && !fields.date.errorId) {
      this.props.fetchNextPayment({
        contractRef: credit.contractRef,
        sum,
        date,
      });
    }
  };

  handleFirstStepSubmit = () => {
    const { calculateCredit, fields, submitFields, credit } = this.props;
    const fieldsForValidation = ['date', 'sum'];

    const date = fields.date.value;
    const sum = fields.sum.value;

    const { contractRef: loanAccount, annuity } = credit;

    if (isSubmitValid(fields, fieldsForValidation)) {
      calculateCredit({
        paymentDate: date,
        paymentSum: sum,
        lastPaymentSum: annuity,
        loanAccount,
      });
    } else {
      validateSubmit(fields, fieldsForValidation, submitFields);
    }
  };

  componentDidUpdate(prevProps) {
    const { fields: currFields, credit: currCredit, accounts, isCreditHolidays } = this.props;
    const { fields: prevFields, credit: prevCredit } = prevProps;

    const currSum = currFields.sum.value;
    const prevSum = prevFields.sum.value;
    const currDate = currFields.date.value;
    const prevDate = prevFields.date.value;

    if (currDate && (currSum !== prevSum || currDate !== prevDate) && !isCreditHolidays) {
      this.debouncedCalc();
    }

    if (currCredit !== prevCredit) {
      const { name, cbAccountNo, CurrentBalance, ccy } = (accounts.find(account => account.accountNo === currCredit.drsettlementacc) || {});
      this.setState({ accountLinkedCredit: { name, cbAccountNo, CurrentBalance, ccy } });
    }
  }

  componentDidMount() {
    this.props.fetchPrepaymentDaysOff();
    this.calcterm();
    const { accounts, credit } = this.props;
    const { name, cbAccountNo, CurrentBalance, ccy } = (accounts.find(account => account.accountNo === credit.drsettlementacc) || {});
    this.setState({ accountLinkedCredit: { name, cbAccountNo, CurrentBalance, ccy } });
  }

  tileDisabled = date => {
    const { days } = this.props.products.prepayment.daysOff;

    if (date.view === 'month') {
      if (days) {
        const checkingValue = moment(date.date);
        const isDayOff = days.some(dayOff => dayOff.date === checkingValue.format('YYYY-MM-DD'));
        // const isInRange =
        //   checkingValue.isSame(moment(), 'day') ||
        //   (checkingValue.isAfter(moment()) && checkingValue.isBefore(moment().add(65, 'days')));

        // return isDayOff || !isInRange;
        return isDayOff;
      }
    } else {
      return false;
    }

    return true;
  };

  formatValue = value => {
    if (getNumOfDecimal(value) > 2) {
      return parseFloat(value).toFixed(2);
    }
    return value;
  };

  renderFootNote = () => {
    return <InfoComponent translateId={label('maxRangeDescription')} theme="blue" width={280} />;
  };

  render() {
    const {
      onSumInputChange,
      onSumRangeChange,
      fields,
      credit,
      products,
      minRepay,
      minDate,
      translate,
      envInfo,
      isCreditHolidays,
    } = this.props;
    const { accountLinkedCredit: { cbAccountNo, name, CurrentBalance, ccy: accountCcy } } = this.state;
    const {
      nextPaymentSum,
      isNextPaymentLoading,
      isNextPaymentError,
      savedSum,
    } = products.prepayment;

    const { Div } = Translation;

    const getProps = buildGetProps(this.props);

    const sumInputProps = buildGetProps({ onChange: onSumInputChange, fields })('sum');
    const sumRangeProps = buildGetProps({ onChange: onSumRangeChange, fields })('sum');
    const { ccy, principal } = credit;
    const isFullPayment = isFullPaymentCheck(this.props.fields.sum.value, principal);

    const { isLoading: isDateInputLoading } = this.props.products.prepayment.daysOff;

    const earlyPaymentFoundsTemplate = translate(label('earlyPaymentFounds'))
    const earlyPaymentFoundsTitle = print(
      earlyPaymentFoundsTemplate,
      `${name}(${cbAccountNo})`,
      `<b>${formatNumber(CurrentBalance, { currency: accountCcy })}</b>`
    );

    return (
      <div className={styles.Step}>
        <Div className={styles.Title} translateId={label('earlyPaymentApplication')} />
        <Div className={styles.Info} translateId={label('earlyPaymentInfo')} />
        <div className={styles.Founds} dangerouslySetInnerHTML={{ __html: earlyPaymentFoundsTitle }} />
        <Div className={styles.FieldsDescription} translateId={label('earlyPaymentDateAndSum')} />
        <div className={styles.CurrencyForm}>
          <div className={styles.InputsContainer}>
            <div className={styles.InputContainer}>
              <DateInput
                {...getProps('date')}
                isLoading={isDateInputLoading}
                tileDisabled={this.tileDisabled}
                minDate={envInfo === 'prod' && minDate}
              />
            </div>
            <div className={styles.InputContainer}>
              <NumericInput
                min={minRepay}
                max={parseFloat(principal).toFixed(2)}
                {...sumInputProps}
                formatRender={this.formatValue}
                currency={ccy}
              />
            </div>
            <div className={styles.InputContainer}>
              <InputRange
                step={0.01}
                min={minRepay}
                max={parseFloat(principal).toFixed(2)}
                {...sumRangeProps}
                afterMax={this.renderFootNote}
                currency={ccy}
              />
            </div>
          </div>
          <div className={styles.InfoBlock}>
            <PrepaymentInfo
              isLoading={isNextPaymentLoading}
              isError={isNextPaymentError}
              monthPayment={formatNumber(Math.ceil(nextPaymentSum), { currency: ccy })}
              isFullPayment={isFullPayment}
              reducedBalance={formatNumber(savedSum, { ccy })}
              ccy={ccy}
              isCreditHolidays={isCreditHolidays}
            />
          </div>
        </div>
        <div className={styles.SubmitContainer}>
          {
            isFullPayment && isCreditHolidays
            ? <FullEarlyRepaymentInOffice />
            : <Button onClick={this.handleFirstStepSubmit} translateId={label('continueBtn')} />
          }
        </div>
      </div>
    );
  }
}

Step1.propTypes = {
  products: PropTypes.object,
  resetDate: PropTypes.func.isRequired,
  calculateCredit: PropTypes.func.isRequired,
  credit: PropTypes.object.isRequired,
  fetchPrepaymentDaysOff: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  localize: PropTypes.object.isRequired,
  submitFields: PropTypes.func.isRequired,
  onSumInputChange: PropTypes.func.isRequired,
  onSumRangeChange: PropTypes.func.isRequired,
  dateFormat: PropTypes.string.isRequired,
  fetchNextPayment: PropTypes.func.isRequired,
  minRepay: PropTypes.number.isRequired,
  minDate: PropTypes.instanceOf(Date),
  accounts: PropTypes.array,
  isCreditHolidays: PropTypes.bool,
};

const mapStateToProps = ({ products, ...state }) => ({
  credit: products.credit,
  products,
  localize: state.localize,
  minRepay: minRepaySelector(state),
  translate: getTranslate(state.localize),
  accounts: accountsSelector(state),
  envInfo: get(state, `settings.envInfo[${getActiveLanguage(state.localize).code}]`, 'prod'),
});

const mapDispatchToProps = {
  calculateCredit,
  fetchPrepaymentDaysOff,
  fetchNextPayment,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step1);
