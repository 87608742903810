import { buildUrlParams, encodeDataURI } from 'shared/utils';
import apiServicesConstructor from 'services/apiService';
import {
  newMessageReqStub,
  postMessageReqStub,
  confirmMessageReqStub,
  createSmsReqStub,
} from './signatureStubs';

const isDevelopment = process.env.NODE_ENV === 'development';
const useGlobalStubs = process.env.REACT_APP_USE_STUBS === 'true';

export const newMessageReq = (useStub = false, withError = false) => async (dispatch, getState) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await newMessageReqStub(withError);
  }

  const reqParams = {
    url: `/webapi-1.0/bss/newMessage`,
    headers: { 'Content-Type': 'application/json' },
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const postMessageReq = ({
  url = '/webapi-1.0/bss/postMessage',
  data = {
    docName: '',
    text: '',
  },
  useStub = false,
  withError = false,
}) => async (dispatch, getState) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await postMessageReqStub(withError);
  }

  const reqParams = {
    url,
    data,
    headers: { 'Content-Type': 'application/json' },
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const confirmMessageReq = (data, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await confirmMessageReqStub(withError);
  }

  const reqData = {
    cash: data.cashToken,
  };

  if (data.schemeName) {
    reqData.schemeName = data.schemeName;
  }

  switch (data.confirmType && data.confirmType.toLowerCase()) {
    case 'tan':
      reqData.password = data.code;
      break;
    case 'mobipass':
      reqData.mbrResult = data.code;
      break;
    case 'sms_code':
      reqData.smsResult = data.code;
      reqData.schemeId = data.schemeId;
      reqData.hash = data.hash;

      if (reqData.smsId) {
        reqData.smsAutokey = data.smsId;
      }
      break;
    default:
  }

  const reqParams = {
    url: `/webapi-1.0/bss/confirmMessage`,
    data: reqData,
    headers: { 'Content-Type': 'application/json' },
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.post(reqParams);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const createSmsReq = (data, useStub = false, withError = false) => async (
  dispatch,
  getState
) => {
  if ((useStub || useGlobalStubs) && isDevelopment) {
    return await createSmsReqStub(withError);
  }
  const { hash, schemeId, schemeName } = data;

  const reqData = {
    SCHEMEID: schemeId,
    HASH: hash,
    SCHEMENAME: schemeName ? schemeName : 'POST',
  };

  const urlParams = buildUrlParams(encodeDataURI(reqData));

  const params = {
    url: `/webapi-1.0/bss/createSMSbyClientRequest?${urlParams}`,
  };

  try {
    const apiServices = apiServicesConstructor(dispatch, getState);
    const response = await apiServices.get(params);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};
