import { timeout } from 'shared/utils';

export const pwdRecoveryStep1ReqStub = async withError => {
  const pwdRecoveryStep1Response = {
    response: {
      cid: 'aca7e45caf48441bab1dea57408bee9c',
      aid: '',
      conSecId: '1',
      ConSecQuestTxt: 'Кличка домашнего питомца',
    },
  };

  const pwdRecoveryStep1Error = {
    response: {
      error: {
        level: 'other',
        message:
          'Выполнение операции невозможно, пожалуйста, обратитесь в отделение Банка или позвоните в Информационный Центр Банка 8 800 700 10 20',
      },
      updatedata: { name: 'IdCaptcha', value: '{04D83580-43E3-4E08-A2DD-F8FDB1772382}' },
    },
  };

  await timeout(1000);
  if (!withError) {
    console.info('pwdRecoveryStep1ReqStub: ', pwdRecoveryStep1Response);
    return Promise.resolve(pwdRecoveryStep1Response);
  } else {
    console.info('pwdRecoveryStep1ReqStub: ', pwdRecoveryStep1Error);
    return Promise.reject(pwdRecoveryStep1Error);
  }
};

export const pwdRecoveryStep2ReqStub = async withError => {
  const pwdRecoveryStep2Response = {
    response: { smsPhone: '+7*******3990', smsCodeNum: 'UniCredit', smsCodeLen: '4' },
  };

  const pwdRecoveryStep2Error = {
    error: { level: 'other', message: 'Вы ввели неправильный ответ.' },
  };

  await timeout(1000);
  if (!withError) {
    console.info('pwdRecoveryStep2ReqStub: ', pwdRecoveryStep2Response);
    return Promise.resolve(pwdRecoveryStep2Response);
  } else {
    console.info('pwdRecoveryStep1ReqStub: ', pwdRecoveryStep2Error);
    return Promise.reject(pwdRecoveryStep2Error);
  }
};

export const pwdRecoveryStep3ReqStub = async withError => {
  const pwdRecoveryStep3Response = { response: { success: true } };

  const pwdRecoveryStep3Error = {
    error: { level: 'other', message: 'Введен неверный код для подтверждения сброса пароля' },
  };

  await timeout(1000);
  if (!withError) {
    console.info('pwdRecoveryStep2ReqStub: ', pwdRecoveryStep3Response);
    return Promise.resolve(pwdRecoveryStep3Response);
  } else {
    console.info('pwdRecoveryStep1ReqStub: ', pwdRecoveryStep3Error);
    return Promise.reject(pwdRecoveryStep3Error);
  }
};
