import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';

import Form from './Form/Form';

import signupTranslation from './signup.json';

import styles from './Signup.module.scss';

class Signup extends Component {
  constructor(props) {
    super(props);
    props.addTranslation(signupTranslation);
  }

  render() {
    return (
      <div className={styles.Card}>
        <div className={styles.FormColumn}>
          <Form />
        </div>
        <div className={styles.ImageColumn}>
          <div className={styles.Image} />
          <div className={styles.ImageFooter}>
            <div className={styles.ImageLogo} />
          </div>
        </div>
      </div>
    );
  }
}

Signup.propTypes = {
  addTranslation: PropTypes.func.isRequired,
};

export default withLocalize(Signup);
