import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

const Td = ({ children, translateId, data, ...rest }) => {
  return (
    <td {...rest} data-translate-id={translateId}>
      {translateId ? <Translate id={translateId} data={data} /> : children}
    </td>
  );
};

Td.propTypes = {
  children: PropTypes.node,
  translateId: PropTypes.string.isRequired,
  data: PropTypes.object,
};

export default Td;
