import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Input, Translation, Button } from 'shared';
import { labelMaker } from 'shared/utils';

import styles from './ScratchCard.module.scss';

const lSecurity = labelMaker('profile.security');

const ScratchCard = props => {
  const { scratchCardProps, showChangeScratchCardBlock, getProps, security } = props;

  let tankeyCardId = '';
  if (security.OTPSettings) {
    tankeyCardId = security.OTPSettings.tankeyCardId;
  }

  return (
    <Fragment>
      <Translation.Div className={styles.BlockTitle} translateId={lSecurity('scratchCard')} />
      <div className={styles.InputContainer}>
        <Input {...getProps('scratchCard')} {...scratchCardProps} value={tankeyCardId} disabled />
      </div>
      {showChangeScratchCardBlock && (
        <Fragment>
          <div className={styles.InputContainer}>
            <Input {...getProps('scratchCardIdToBind')} statusElement onlyNumbers />
          </div>
          <div className={styles.InputContainer}>
            <Input {...getProps('scratchCardIdNew')} statusElement onlyNumbers />
          </div>
          <div className={styles.ButtonsContainer}>
            <div className={styles.Button}>
              <Button
                round
                translateId={lSecurity('cancel')}
                theme="white"
                onClick={props.cancelChangeScratchCard}
              />
            </div>
            <div className={styles.Button}>
              <Button
                round
                translateId={lSecurity('save')}
                onClick={props.handleChangeScratchCard}
              />
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

ScratchCard.propTypes = {
  scratchCardProps: PropTypes.object,
  showChangeScratchCardBlock: PropTypes.bool,
  getProps: PropTypes.func,
  handleChangeScratchCard: PropTypes.func,
  cancelChangeScratchCard: PropTypes.func,
  security: PropTypes.object,
};

export default ScratchCard;
