import { createReducer } from 'redux-act';

import { fetchSettingsStart, fetchSettingsSuccess, fetchSettingsFailure } from 'actions/settings';

const initialState = {
  isModuleVisible: { ru: false, en: false },
  prevAuthNotifyEnabled: { ru: false, en: false }
};

const settings = createReducer(
  {
    [fetchSettingsStart]: state => ({
      ...state,
      isLoading: true,
    }),
    [fetchSettingsSuccess]: (state, settings) => ({
      ...settings,
      isLoading: false,
    }),
    [fetchSettingsFailure]: state => ({
      ...state,
      isLoading: false,
    }),
  },
  initialState
);

export default settings;
