import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Translation } from 'shared';

import styles from './TabMenu.module.scss';
import { labelMaker } from 'shared/utils';

const TransfersTabMenu = props => {
  const lMenuTabs = labelMaker('transfers.tabs');
  const menuTabs = ['fast', 'card'];
  const route = '/transfers';

  const renderMenuItem = (tab, i) => {
    return (
      <Translation.NavLink
        exact
        to={`${route}/${tab}`}
        className={styles.MenuTab}
        activeClassName={styles.ActiveMenuTab}
        translateId={lMenuTabs(tab)}
        key={i}
      />
    );
  };

  const { className } = props;

  return <div className={cn(styles.TransfersMenu, className)}>{menuTabs.map(renderMenuItem)}</div>;
};

TransfersTabMenu.propTypes = {
  match: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withRouter(TransfersTabMenu);
