import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

const Div = ({ children, translateId, data, ...rest }) => {
  return (
    <div {...rest} data-translate-id={translateId}>
      {translateId ? <Translate id={translateId} data={data}/> : children}
    </div>
  );
};

Div.propTypes = {
  children: PropTypes.node,
  translateId: PropTypes.string,
  data: PropTypes.object,
};

export default Div;
