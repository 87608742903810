import {
  ReduxMaskedInput,
  ReduxPhoneInput,
  ReduxInput,
  ReduxRadio,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
} from 'shared';
import { labelMaker } from 'shared/utils';
import { shortPhone as phoneMask, date as dateMask } from 'shared/masks';
import {
  reduxFieldRequired,
  reduxFieldPhone,
  reduxFieldmaxLength,
  reduxFieldminLength,
} from 'shared/validators';
import { lessThan } from 'shared/normalizers';

const formLabel = labelMaker('registration.form');

export const options = {
  cardOrAccount: {
    component: ReduxMaskedInput,
    labelId: formLabel('cardOrAccount'),
    name: 'cardOrAccount',
    validate: [reduxFieldRequired],
  },
  phone: {
    component: ReduxPhoneInput,
    labelId: formLabel('phone'),
    name: 'phone',
    mask: phoneMask,
    validate: [reduxFieldRequired, reduxFieldPhone],
  },
  login: {
    component: ReduxInput,
    labelId: formLabel('login'),
    name: 'login',
    validate: reduxFieldRequired,
  },
  password: {
    component: ReduxInput,
    labelId: formLabel('password'),
    name: 'password',
    type: 'password',
    validate: [reduxFieldRequired, reduxFieldmaxLength(1024), reduxFieldminLength(8)],
    statusElement: true,
  },
  confirmedPassword: {
    component: ReduxInput,
    labelId: formLabel('confirmedPassword'),
    name: 'confirmedPassword',
    type: 'password',
    validate: [reduxFieldRequired, reduxFieldmaxLength(1024), reduxFieldminLength(8)],
    statusElement: true,
  },
  protection: {
    name: 'protection',
    component: ReduxRadio,
  },
  controlQuestion: {
    component: ReduxSelect,
    labelId: formLabel('controlQuestion'),
    name: 'controlQuestion',
  },
  controlQuestionAnswer: {
    component: ReduxInput,
    labelId: formLabel('answer'),
    name: 'controlQuestionAnswer',
    validate: reduxFieldRequired,
  },
  controlWord: {
    component: ReduxInput,
    labelId: formLabel('controlWord'),
    name: 'controlWord',
    validate: reduxFieldRequired,
  },
  controlDate: {
    component: ReduxDateInput,
    labelId: formLabel('controlDate'),
    name: 'controlDate',
    mask: dateMask,
    validate: reduxFieldRequired,
  },
  protectionStatus: {
    component: ReduxToggle,
    name: 'protectionStatus',
  },
  captcha: {
    component: ReduxInput,
    labelId: formLabel('captcha'),
    name: 'captcha',
    normalize: lessThan(10),
    validate: reduxFieldRequired,
  },
};

export default options;
